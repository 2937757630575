import { Component, OnInit, EventEmitter } from '@angular/core';
import { ClientNewAddModel } from 'src/app/shared/models/client.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ClientsService } from '../clients.service';
import { Utility } from '../../utility/utility';
import { ToastrService } from 'ngx-toastr';
import { ClientAddResponse } from 'src/app/shared/models/response.model';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientComponent implements OnInit {
  clientModel : ClientNewAddModel ;
  Isvalid:boolean;
  data;
  errors;
  event: EventEmitter<any> = new EventEmitter();

  constructor(
    public modalRef: BsModalRef, 
    private cookieService: CookieService, 
    private ngxService: NgxUiLoaderService, 
    private clientsService: ClientsService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.Isvalid = true;
    this.clientModel = new ClientNewAddModel();

      if(this.data.agent !=null){
        Object.assign(this.clientModel, this.data.agent);
      }
          let fortressAdminId = this.cookieService.get('CurrentUserId');
          this.clientModel.userType = 2;
          this.clientModel.fortressAdminId = fortressAdminId;
          this.clientModel.companyCode = this.cookieService.get('companyCode');
          //this.userTypeArray = [{"id":null,"value":"Please select"},{"id":"1","value":"Admin"},{"id":"3","value":"Staff member"}]; 
          // ,{"id":"2","value":"Client"}
  }

  onSubmit(form:NgForm){
    if(form.invalid) {
    
      if(form.pristine){
      }
      this.Isvalid = false;
    }
  
    else{
      this.Isvalid = true;
      if(this.data.agent !=null){

      }else{
        console.log("Add");
        console.log(this.clientModel);

        this.ngxService.start();
        this.clientsService.clientAdd(this.clientModel)
        .subscribe((data:ClientAddResponse)=>{
          this.ngxService.stop();
          if(data.error && !data.success){
            if(data.error.message){
              this.errors = data.error.message;
            }
            else{
              this.errors = Utility.GetLocalErrorString(data.error.errorCode);
            }
          }
          else{
            this.toastr.success('Client added successfully.');
            this.event.emit('OK');
            this.modalRef.hide();
          }
          
          },err=>{   
            this.ngxService.stop();
            this.toastr.error('Error on saving client.');
          });
        }
    
      
    
    }
  
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }
  
  ClosePopup(){
    this.modalRef.hide();
  }

}
