import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AudienceMemberSearchItemModel, AudienceMemberSearchListDataModel } from 'src/app/shared/models/audience-member-search.model';
import { NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AudienceService } from '../../audience.service';
import { AudienceMemberSearchDataResponse } from 'src/app/shared/models/response.model';
import { Utility } from 'src/app/utility/utility';
import { ToastrService } from 'ngx-toastr';
import { AudienceMemberAddModel } from 'src/app/shared/models/audience-member.model';


@Component({
  selector: 'app-add-people',
  templateUrl: './add-people.component.html',
  styleUrls: ['./add-people.component.css']
})
export class AddPeopleComponent implements OnInit {

    audience: any;
    genderRole: any;
    ageNum: any;
    staffMember: any;
    audienceMemberSearchModel: AudienceMemberSearchItemModel;
    audienceMemberSearchListData: AudienceMemberSearchListDataModel[];
    audienceMemberList: AudienceMemberAddModel[];
    errors;
    numbers:Array<any> = [];
    memberCount: number = 0;
    event: EventEmitter<any> = new EventEmitter();
    data;
    clicked: boolean = false;
    isLoading: boolean = false;
    
  constructor(
    public modalRef: BsModalRef,
    private ngxService: NgxUiLoaderService,
    private audienceService: AudienceService,
    private toastr: ToastrService,
  ) {
    this.numbers = Array.from({length:99},(v,k)=>k+1);
   }

  ngOnInit() {
    this.audienceMemberSearchModel = new AudienceMemberSearchItemModel();
    // this.searchaudienceMember(this.audienceMemberSearchModel);

    this.audienceMemberSearchModel.gender = null;
    this.audienceMemberSearchModel.isStaffMember = null;
    this.audienceMemberSearchModel.ageMin = null;
    this.audienceMemberSearchModel.ageMax = null;

    this.genderRole = [{id:1, value:'Male'}, {id:2, value:'Female'}];
    this.ageNum = [{id:20, value:20}, {id:21, value:21}, {id:22, value:22}, {id:23, value:23}, {id:24, value:24}, {id:25, value:25}, {id:26, value:26}, {id:27, value:27}, {id:28, value:28}, {id:29, value:29}, {id:30, value:30}];
    this.staffMember = [{id:true, value:'Yes'}, {id:false, value:'No'}];
  }

  onSubmit(form:NgForm){
    this.searchaudienceMember(this.audienceMemberSearchModel);
  }

  searchaudienceMember(audienceMemberSearchModel) {
    // let name = this.audienceMemberSearchModel.name;
    // let description = this.audienceMemberSearchModel.description;  

    // if(form.invalid || (name && name.trim()=="") || (description && description.trim()=="") ) {
  
    //   if(name && name.trim()==""){
    //     form.controls['name'].setErrors({'required': true});
    //   }
    //   if(description && description.trim()==""){
    //     form.controls['description'].setErrors({'required': true});
    //   }
  
    //   if(form.pristine){
    //   }
    //   this.Isvalid = false;
    //   // console.log(form.invalid);  
    //   //console.log(this.Isvalid);
    // }

    // else{
      this.ngxService.start();
      this.isLoading = true;
      this.memberCount = 0;
      // this.Isvalid = true;

        this.audienceService.audienceMemberSearchList(audienceMemberSearchModel).subscribe((data:AudienceMemberSearchDataResponse)=>{
          this.ngxService.stop();
          if(data.error && !data.success){
            this.errors = Utility.GetLocalErrorString(data.error.errorCode);
            //console.log(this.errors);
          }
          else{
            this.toastr.success('Search completed.');
            this.audienceMemberSearchListData = data.response;
            this.formatArray(this.audienceMemberSearchListData);
            console.log('dddd-',this.audienceMemberList);
            this.memberCount = this.audienceMemberSearchListData.length;
            this.isLoading = false;
            // this.event.emit('OK');
            // this.modalRef.hide();
          }
          
        },err=>{   
          this.ngxService.stop();
          this.toastr.error('Error on search.');
        });    
    // }
  }

  addClients()
  {
    // this.insertedMembersCount = 0;
    // this.unInsertedMembersCount = 0;

    this.ngxService.start();
    this.audienceService.addAudienceMember(this.audienceMemberList).subscribe((data:any)=>{
      this.ngxService.stop();
      if(data.error && !data.success){
        this.errors = Utility.GetLocalErrorString(data.error.errorCode);
        //console.log(this.errors);
      }
      else{
        this.clicked=true;
        let insertedMembersCount = data.response.insertedMembers.length;
        let unInsertedMembersCount = data.response.unInsertedMembers.length;
        this.toastr.success('Inserted Client Count: '+ insertedMembersCount + ', Uninserted Client Count: '+ unInsertedMembersCount );
        this.event.emit('OK');
        this.modalRef.hide();
      }
      
    },err=>{   
      this.ngxService.stop();
      this.toastr.error('Error on saving clients.');
    });
      
  }

  formatArray(audienceMemberSearchListData)
  {
    this.audienceMemberList = [];
    audienceMemberSearchListData.forEach(element => {
      // if(choice && choice[0]) {
        this.audienceMemberList.push({xeppoCode: element.xeppoCode, clientCode: element.clientCode, audianceId: this.data.audience_id });
      // }
    });
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }
  
  ClosePopup(){
    this.modalRef.hide();
  }

  clearSearch(){
    this.audienceMemberSearchModel.searchName = ''
    this.audienceMemberSearchModel.xeppoCode = ''
    this.audienceMemberSearchModel.gender = null
    this.audienceMemberSearchModel.postalCode = ''
    this.audienceMemberSearchModel.isStaffMember = null
    this.audienceMemberSearchModel.ageMin = null
    this.audienceMemberSearchModel.ageMax = null
  }

}


