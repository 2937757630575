
    <div class="modal-header border-bottom-0">
        <h5 class="modal-title factfinderheader" id="exampleModalLabel">{{ title }} Contact Details</h5>
        <button type="button" class="close" (click)="modalRehide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
  
      <form [formGroup]="addAddressForm" novalidate class="form " (ngSubmit)="onSubmit()" >
      <div class="modal-body factfinderbody">
        
        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Address Type</label>
          <div class="inputwidth"> <span>:</span>
            <!-- <input type="text" class="form-control form-control-sm" placeholder="Mobile Phone"> -->
            <select formControlName="addressType" required [(ngModel)]="addressAddModel.addressType" class="form-control form-control-sm" placeholder="Contact Type" >
                <option value="000">Please select</option>
                
                <ng-container *ngFor="let addressTypeOne of addressTypeList; let i = index">
                    <option value="{{addressTypeOne.key}}">{{addressTypeOne.value}}</option>
                </ng-container>
                
              </select>
                <div class="form-group">
                  <div *ngIf="submitted && f.addressType.errors"   class="form_alert">
                          <span  class="ft-alert-circle"></span>
                      <div *ngIf="f.addressType.errors.required">Address type is required.</div>
                </div>
                </div>
          </div>
        </div>
        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Street</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            <input formControlName="street" required [(ngModel)]="addressAddModel.street" type="text" class="form-control form-control-sm" placeholder="Street">
            <div class="form-group">
              <div *ngIf="submitted && f.street.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.street.errors.required">Street is required.</div>
              </div>
            </div>  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Suburb</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            <input formControlName="suburb" required [(ngModel)]="addressAddModel.suburb" type="text" class="form-control form-control-sm" placeholder="Suburb">
            <div class="form-group">
              <div *ngIf="submitted && f.suburb.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.suburb.errors.required">Suburb is required.</div>
              </div>
            </div>
  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">State</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            <input formControlName="state" required [(ngModel)]="addressAddModel.state" type="text" class="form-control form-control-sm" placeholder="State">
            <div class="form-group">
              <div *ngIf="submitted && f.state.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.state.errors.required">State is required.</div>
              </div>
            </div>  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
            <label for="postcode" class="labelwidth">Postcode</label>
            <div class="inputwidth"> <span>:</span> 
              <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
              <input formControlName="postcode" required [(ngModel)]="addressAddModel.postcode" type="text" class="form-control form-control-sm" placeholder="Postcode">
              <div class="form-group">
                <div *ngIf="submitted && f.postcode.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.postcode.errors.required">Postcode is required.</div>
                </div>
              </div>  
            </div>
          </div>
        
      </div>
      <div class="modal-footer border-top-0 pt-0">
        <button class="btn btn-primary factfinderbtn">Save</button>
      </div>
  </form>
      