<div class="app-content content_">
    <div class="content-wrapper">
        <div class="content-header row"> </div>
        <div class="content-body">
            <section class="flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-md-4 col-10 box-shadow-2 p-0 login_width">
                        <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                            <div class="card-header border-0" style="padding-bottom: 5px;">
                                <div class="card-title text-center"> <img src="assets/img/logo_login.png" alt="branding logo" style="width: 75%;"> </div>
                                <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2 logh2" >
                                    <span style="font-size: 10px;">RESET PASSWORD</span>
                                </h6> </div>
                            <div class="card-content">
                                <div class="card-body">
                                    <form  [formGroup]="passwordResetForm" (ngSubmit)="onSubmit()" class="form-horizontal" action="index.html" novalidate>
                                        <div *ngIf="invalid" class="alert alert-danger">
                                            Username does not exist.
                                        </div>
                                        <div *ngIf="valid" class="alert alert-success">
                                            Password successfully send. Please use new password.
                                        </div>
                                        <!-- <fieldset class="form-group position-relative has-icon-right mar_field">
                                            <input name="userName" type="text" class="form-control" id="user-name" formControlName="userName" placeholder="UserName" required >
                                            <div class="form-control-position"> <i class="la la-user"></i> </div>

                                            <div class="form-group">
                                            <div *ngIf="submitted && inputs.userName.errors" class="alert alert-danger">
                                                <div *ngIf="inputs.userName.errors.required">User name is required</div>                                                
                                            </div>
                                            </div>

                                        </fieldset> -->

                                        <fieldset class="form-group position-relative has-icon-right mar_field">
                                                <input name="userName" type="text" class="form-control" id="user-name" formControlName="userName" [ngClass]="{ 'is-invalid': submitted && inputs.userName.errors }" placeholder="UserName"  >
                                                <div class="form-control-position"> <i class="la la-user"></i> </div>
                                                <div *ngIf="inputs.userName.errors" class="invalid-feedback"></div>  
                                        </fieldset>

                                        <button type="submit" class="btn btn-block signbtn">Reset Password</button>
                                    </form>
                                </div>
                                <div class="for_p">
                                    <p class="text-center m-0"><a href="/login" class="card-link">Back to Login</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
  </div>
  
  <ng-template #template>
        
        <div class="modal-header border-bottom-0 pb-0">                   
            <button type="button" class="close" data-dismiss="modal" (click)="modalRehide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="editPersonalDetails" novalidate class="form " (ngSubmit)="onSubmitPersonalDetails()" >
            <div class="modal-body pt-0 pb-0 pl-4 pr-4">
                <h4 class="modal-title d-flex font-weight-normal" id="exampleModalLabel">Change Password</h4>
                
                <div class="form-group">
                    <label for="recipient-name" class="col-form-label d-flex font-weight-normal">Current Password</label>
                    <!-- <input type="password" required formControlName="oldPassword" class="form-control" id="name" placeholder="Type here"> -->
                    <input autocomplete="off" maxlength="75" formControlName="oldPassword" id="oldPassword" required type="password" [(ngModel)]="passwordModel.email" class="form-control" name="oldPassword" placeholder="Current Password">
                    <div *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback">
                        <div *ngIf="f.oldPassword.errors.required">New password is required</div>
                        <!-- <div *ngIf="f.oldPassword.errors.minlength">New password must be at least 8 characters in length</div>                         -->
                    </div>
                </div>

                <div class="form-group">
                    <label for="recipient-name" class="col-form-label d-flex font-weight-normal">New Password</label>
                    <!-- <input type="password" required formControlName="oldPassword" class="form-control" id="name" placeholder="Type here"> -->
                    <input autocomplete="off" maxlength="75" formControlName="newPassword" minlength="8" id="newPassword" required type="password" [(ngModel)]="passwordModel.newPassword" class="form-control" name="newPassword" placeholder="New Password">
                    <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                        <div *ngIf="f.newPassword.errors.required">New password is required</div>
                        <div *ngIf="f.newPassword.errors.minlength">New password must be at least 8 characters in length</div>                        
                    </div>
                </div>

                <div class="form-group">
                    <label for="recipient-name" class="col-form-label d-flex font-weight-normal">Confirm New Password</label>
                    <input type="password" required formControlName="passwordConfirm" class="form-control" id="name" placeholder="Confirm Password">
                    <div *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback">
                            <div *ngIf="f.passwordConfirm.errors.required">Password confirmation is required</div>
                            <div *ngIf="f.passwordConfirm.errors.mustmatch">Password confirmation must be equal to password</div>
                            <div *ngIf="f.passwordConfirm.errors.minlength">New password must be at least 8 characters in length</div>                        
                        </div>
                </div>
                
            </div>

            <div class="modal-footer justify-content-start border-top-0 nb-footer pb-4">
                <button class="btn clr_button upload_button font-weight-normal" style="font-size: 12px;">Change Password</button> &nbsp; OR &nbsp;                    
                <button type="button" class="btn pl-0 pr-0 nb_can_btn" data-dismiss="modal">CANCEL</button>
            </div>  
        </form>       
               
    </ng-template>

  <ngx-ui-loader ></ngx-ui-loader>