export class ChangepasswordModel {
   public userId: string;
   // public oldPassword: string;
   public newPassword: string;
   // public loggedInUserType: number;
}

export class PasswordChangeModel extends ChangepasswordModel{
   
}
