import { Injectable } from '@angular/core';
import { AudienceResponse, AudienceDataResponse, AudienceMemberResponse, AudienceMemberSearchDataResponse } from '../shared/models/response.model';
import { AppConstants } from '../utility/app-constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AudienceAddModel } from '../shared/models/audience.model';

@Injectable({
  providedIn: 'root'
})
export class AudienceService {

  baseUrl:String;
  
  constructor(private http:HttpClient, private cookieService: CookieService) {
    this.baseUrl = AppConstants.getBaseURL();
   }

  public audienceList(){       
    return this.http.get<AudienceResponse>(this.baseUrl+'/Audiences?take=200');    
  }

  public deleteAudience(id:string){
    const params = new HttpParams().set('id', id); 
    return this.http.delete<Response>(this.baseUrl +'/Audience?id='+id+'&isArchived=true').pipe(
      catchError(this.handleError)
      );     
  }

  public handleError(error) {
    return throwError(error);
  }

  public addAudience(audienceModel:AudienceAddModel){
    return this.http.post<AudienceResponse>(this.baseUrl +'/Audience',audienceModel).pipe(
     catchError(this.handleError)
     );    
  }

  public audienceMemberList(id:string) {  
    const params = new HttpParams().set('audienceId', id).set('take', '3000'); 
    //appointment.sheduledDate = new Date(appointment.sheduledDate+' '+appointment.hour+':'+appointment.minute+':'+appointment.amPm);   //sheduledDate  hour  minute amPm
    return this.http.get<AudienceMemberResponse>(this.baseUrl+'/AudienceMember/getByAudienceId',{params}).pipe(
      catchError(this.handleError)
      );
  }

  public getAudience(id:string) {  
    const params = new HttpParams().set('audienceId', id); 
    //appointment.sheduledDate = new Date(appointment.sheduledDate+' '+appointment.hour+':'+appointment.minute+':'+appointment.amPm);   //sheduledDate  hour  minute amPm
    return this.http.get<AudienceDataResponse>(this.baseUrl+'/Audience/getByAudienceId',{params}).pipe(
      catchError(this.handleError)
      );
  }

  public deleteAudienceMember(id:string){
    const params = new HttpParams().set('id', id); 
    return this.http.delete<Response>(this.baseUrl +'/AudienceMember?id='+id+'&isArchived=true').pipe(
      catchError(this.handleError)
      );     
  }

  public updateAudienceStatus(id:string, isEnabled: any) {  
    const params = new HttpParams().set('id', id).set('isEnable', isEnabled); 
    //appointment.sheduledDate = new Date(appointment.sheduledDate+' '+appointment.hour+':'+appointment.minute+':'+appointment.amPm);   //sheduledDate  hour  minute amPm
    return this.http.put<AudienceResponse>(this.baseUrl+'/Audience/UpdateActiveStatus?isEnable='+isEnabled+'&id='+id,{params}).pipe(
      catchError(this.handleError)
      );
  }

  public audienceEdit(id:string,audienceModel:AudienceAddModel) {  
    const params = new HttpParams().set('id', id); 
    //appointment.sheduledDate = new Date(appointment.sheduledDate+' '+appointment.hour+':'+appointment.minute+':'+appointment.amPm);   //sheduledDate  hour  minute amPm
    return this.http.put<AudienceResponse>(this.baseUrl+'/Audience',audienceModel,{params}).pipe(
      catchError(this.handleError)
      );
  }

  public audienceMemberSearchList(data) {  
    let params = new HttpParams();
    
    // data.xeppoCode = null;
    // data.gender = null;
    // data.postalCode = null;
    // data.isStaffMember = null;
    // data.ageMin = null;
    // data.ageMax = null;
    // data.companyCode = null;
    
    if(data && data.searchName){ params = params.set('searchName', data.searchName) }
    if(data && data.xeppoCode){ params = params.set('xeppoCode', data.xeppoCode) }
    if(data && data.gender){ params = params.set('gender', data.gender) }
    if(data && data.postalCode){ params = params.set('postalCode', data.postalCode) }
    if(data && data.isStaffMember){ params = params.set('isStaffMember', data.isStaffMember) }
    if(data && data.ageMin){ params = params.set('ageMin', data.ageMin) }
    if(data && data.ageMax){ params = params.set('ageMax', data.ageMax) }
    if(data && data.companyCode){ params = params.set('companyCode', data.companyCode) }
    

    // const params = new HttpParams().set('searchName', data.searchName).set('xeppoCode', data.xeppoCode).set('gender', data.gender)
    // .set('postalCode', data.postalCode).set('isStaffMember', data.isStaffMember).set('ageMin', data.ageMin).set('ageMax', data.ageMax)
    // .set('companyCode', data.companyCode); 
    //appointment.sheduledDate = new Date(appointment.sheduledDate+' '+appointment.hour+':'+appointment.minute+':'+appointment.amPm);   //sheduledDate  hour  minute amPm
    return this.http.get<AudienceMemberSearchDataResponse>(this.baseUrl+'/Dashboard/AudienceSearch',{params}).pipe(
      catchError(this.handleError)
      );
  }

  public addAudienceMember(audienceMemberList:any){
    return this.http.post<AudienceResponse>(this.baseUrl +'/AudienceMember/AddList',audienceMemberList).pipe(
     catchError(this.handleError)
     );    
  }

  public getAudienceByXeppoCode(xeppoCodeList:any) {  
    let params = new HttpParams();
    let isCom = false;

    xeppoCodeList.forEach((element, index) => {      
      params = params.append('xeppoCodeList', element.XeppoCode); 
      if(xeppoCodeList.length == index +1) {
        isCom = true;
      }      
    });

    if(isCom) {
      //appointment.sheduledDate = new Date(appointment.sheduledDate+' '+appointment.hour+':'+appointment.minute+':'+appointment.amPm);   //sheduledDate  hour  minute amPm
      return this.http.get<any>(this.baseUrl+'/Client/GetSelectedClients',{params}).pipe(
        catchError(this.handleError)
        );
    }
  }
}
