import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo, first } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'
import { AuthService } from '../auth/auth.service';

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private authService: AuthService,
    private angularFireMessaging: AngularFireMessaging) {
    // this.angularFireMessaging.messaging.subscribe( // 
    //   (_messaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }
    // )
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    console.log(" kkkkkkkkkkkk update  ");
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        console.log(" kkkkkkkkkkkk data = {}  ");
        const data = {};
        data[userId] = token
        console.log(" kkkkkkkkkkkk start  ");
        this.angularFireDB.object('fcmTokens/').update(data)
        console.log(" kkkkkkkkkkkk end  ");
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {
    console.log(" ex mmmmmmmmmmm  "+userId);
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        localStorage.setItem('fireBaseToken', token);
        console.log(" kkkkkkkkkkkk  ");
        this.updateToken(userId, token);

        this.authService.appInstallation(token,userId).pipe(first()).subscribe(data=>{          
        });
        console.log(1000000000000000001111111111111);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }
}