export class XFacDependentBaseMOdel {
    public  relation : string;
    public  relatedTo : string;
    // public  financiallyDependent : Boolean;
    // public  dependantUntilAge : number;
    public  facFinderId : string;
    public  clientCode : string;

    public  firstName : string;
    public  surname : string;
    public  gender : string;
    public  dateOfBirth : Date;
}

export class XFacDependentAddModel extends XFacDependentBaseMOdel{    
       
}