<div class="input-group mr-2">
  <input (keydown)="$event.preventDefault();" [ngClass]="ngControl?.valid ? 'ng-valid' : 'ng-invalid'" class="form-control" (blur)="inputBlur($event)" [ngModel]="dateString | date:inputDatetimeFormat" (change)="onInputChange($event)" [disabled]='disabled' />

  <div class="input-group-append">
      <button class="btn btn-outline-secondary" [ngbPopover]="calendarContent" [disabled]='disabled' type="button">
          <!-- <fa-icon [icon]="['far', 'calendar']"></fa-icon> -->
          <i class="ficon ft-calendar"></i>
      </button>
  </div>
</div>

<ng-template #calendarContent>
  <div>
      <div *ngIf="!showTimePickerToggle" >
          <ngb-datepicker [minDate]="{year: todayYear, month: todayMonth, day: todayDay}" #dp name="datepicker" [ngModel]="datetime"  (ngModelChange)="onDateChange($event)"></ngb-datepicker>
          <button class="btn btn-block btn-outline-secondary" [disabled]="!datetime?.day" [ngbPopover]="timePickerContent" type="button" (click)="toggleDateTimeState($event)">
              <!-- <fa-icon [icon]="['far', 'clock']"></fa-icon> -->
              <i class="ficon ft-clock"></i>
          </button>
      </div>
      <div *ngIf="showTimePickerToggle" >
              <button class="btn btn-block btn-outline-secondary" [ngbPopover]="calendarContent" type="button" (click)="toggleDateTimeState($event)">
                  <!-- <fa-icon [icon]="['far', 'calendar']"></fa-icon> -->
                  <i class="ficon ft-calendar"></i>
              </button>
          <div class="mt-auto">
          <ngb-timepicker #tp name="timepicker" [ngModel]="datetime" (ngModelChange)="onTimeChange($event)" [seconds]="seconds"
          [hourStep]="hourStep" [minuteStep]="minuteStep" [secondStep]="secondStep"></ngb-timepicker>
          </div>
      </div>
  </div>
</ng-template>

