import { ClientBaseModel, ClientViewModel, ClientListModel, WebClientAddModel, ClientSearchModel } from "./client.model";
import { AgentBaseMOdel, AgentViewModel, UserAgentViewModel } from "./agent.model";
import { InvoiceAddModel } from './invoice.model';
import { TransactionViewModel } from './transaction.model';
import { AppointmentAddModel } from './appointment.model';
import { NewsFeedAddModel } from './news-feed.model';
import { FileUploadAddModel } from './file-upload.model';
import { MessagecenterModel, ChatModel } from './messagecenter.model';
import { UnVerifiedUsersListModel } from './un-verified-users.model';
import { UserDocumentsAddModel } from './user-documents.model';
import { ContainerAddModel } from './container.model';
import { FactFinderCategoriesView, FactFinder, FactFinderNew, GetFactFinderNewModel } from './fact-finder-categories.model';
import { RolePermissionAddModel } from "./role-permission.model";
import { CompanyAddModel } from "./company.model";
import { RoleAddModel } from './role.model';
import { PermissionAddModel, ClientGetDataModel } from './permission.model';
import { EmailSetupAddModel } from './email-setup.model';
import { DepartmentViewModel } from './department.model';

import { XFacAddressAddModel } from './xfac-address.model';
import { XFacAssetAddModel } from './xfac-asset.model';
import { XFacContactAddModel } from './xfac-contact.model';
import { XFacDependentAddModel } from './xfac-dependent.model';
import { XFacEmploymentAddModel } from './xfac-employment.model';
import { XFacEstatePlanningAddModel } from './xfac-estatePlanning.model';
import { XFacExecutorAddModel } from './xfac-executor.model';
import { XFacExpensesAddModel } from './xfac-expenses.model';
import { XFacGoalAddModel } from './xfac-goal.model';
import { XFacIncomeAddModel } from './xfac-income.model';
import { XFacInsuranceLifeAddModel } from './xfac-InsuranceLife.model';
import { XFacLiabilityAddModel } from './xfac-liability.model';
import { XFacPersonalInfoAddModel } from './xfac-personalInfo.model';
import { XFacPowerOfAttorneyAddModel } from './xfac-powerOfAttorney.model';
import { XFacRetirementIncomeAddModel } from './xfac-retirementIncome.model';
import { XFacSuperannuationAddModel } from './xfac-superannuation.model';
import { XFacSuperannuationBeneficiaryAddModel } from './xfac-superannuationBeneficiary.model';
import { XFacSuperAnnuationContributionAddModel } from './xfac-superAnnuationContribution.model';
import { XplanChoices } from './xplan-choices.model';
import { XplanCoverAddModel } from './xplan-cover.model';
import { XFacInsuranceMedicalAddModel } from './xplan-insuranceMedical.model';
import { XplanVersionAddModel } from './xplan-version.model';
import { XplanStatusView } from './xplan-status.model';
import { User } from './user.model';
import { PasswordChangeModel } from './changepassword.model';
import { AudienceAddModel } from './audience.model';
import { AudienceMemberAddModel } from './audience-member.model';
import { AudienceMemberSearchListDataModel } from './audience-member-search.model';
import { NetworthHistoryModel } from './networth-history.model';
import { ServiceAgreementAddModel, ServiceAgreementListModel } from "./service-agreement.model";
import { UserGroupAddModel } from "./user-group.model";
import { SyncMonitoringListModel } from "./sync-monitoring.model";
import { Item } from "./networth";


export class Response {
    public success: boolean;
    public error: object;
    public response: object;
}

// export class ClientViewModel extends ClientBaseModel{

// }

export class ErrorObject {
    public errorCode: number;
    public statusCode: number;
    public message: string;
}

export class ClentResponsex extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: ClientViewModel[];
}

export class ClentResponsexNew extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: { data: ClientViewModel[], totalRecoreds: number };
}

export class ClentProfileResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: ClientViewModel;
}

export class ClentSearchResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: ClientSearchModel[];
}


export class WebClentResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: ClientViewModel;
}

export class AgentResponse extends Response {
    public success: boolean;
    public isSuccess: boolean;
    public error: ErrorObject;
    public data: AgentViewModel[];
}

export class AgentResponse2 extends Response {
    public success: boolean;
    public error: ErrorObject;
    //public response :AgentViewModel[];
    public data: UserAgentViewModel[];
}

export class AgentProfileResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: AgentViewModel;
}

export class ClentListResponse extends Response {
    public success: boolean;
    public error: {};
    public response: ClientListModel[];
}

export class InvoiceResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: InvoiceAddModel[];
}

export class TransactionResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: TransactionViewModel[];
}

export class AppointmentResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: AppointmentAddModel[];
}

export class NewsFeedResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: {
        newsFeedList: NewsFeedAddModel[]
        totalRecordsCount: number
    };
}

export class NewsFeedResponseProfile extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: NewsFeedAddModel[];
}

export class NewsFeedResponseByID extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: any;
}

export class FileUploadResponseResponse {
    public success: boolean;
    public s3Key: string;
}

export class FileUploadResponse {
    public success: boolean;
    public error: ErrorObject;
    public response: FileUploadResponseResponse;
}

export class StartConversationResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: MessagecenterModel[];
}

export class ChatListResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: ChatModel[];
}

export class ChatResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: ChatModel;
}

export class DocumentUrlResponse {
    public success: boolean;
    public error: ErrorObject;
    public response: string;
}

export class UnVerifiedUsersResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: { data: UnVerifiedUsersListModel[], totalRecoreds: number };
}

export class UnVerifiedUsersResponse2 extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: { data: UnVerifiedUsersListModel };
}

export class VerifiedUsersResponse {
    public success: boolean;
    public error: ErrorObject;
    public response: boolean;
}

export class WebClientResponse {
    public success: boolean;
    public error: ErrorObject;
    public response: WebClientAddModel;
}

export class UserDocumentResponse {
    public success: boolean;
    public error: ErrorObject;
    public response: UserDocumentsAddModel[];
}

export class UserDocumentReorderResponse {
    public success: boolean;
    public error: ErrorObject;
    public response: [];
}

export class WebAdminResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: ContainerAddModel;
}

export class FactFinderCategoriesResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: FactFinderCategoriesView[];
}

export class FactFinderResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: FactFinder[];
}

export class FactFinderNewResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: FactFinderNew[];
}

export class GetFactFinderNewResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: GetFactFinderNewModel[];
}

export class PermissionGroup extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: RolePermissionAddModel[];
}

export class CompanyResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: CompanyAddModel[];
}

export class Company_Response2 extends Response {
    public success: boolean;
    public error: ErrorObject;
    public data: CompanyAddModel[];
}

export class RoleResponse extends Response {
    public isSuccess: boolean;
    public error: ErrorObject;
    public data: RoleAddModel[];
    public message: string;
}

export class PermissionResponse extends Response {
    public isSuccess: boolean;
    public error: ErrorObject;
    public data: PermissionAddModel[];
    public message: string;
}

export class PermissionGetResponse extends Response {
    public isSuccess: boolean;
    public error: ErrorObject;
    public data: ClientGetDataModel[];
    public message: string;
}

export class RolePermissionResponse extends Response {
    public isSuccess: boolean;
    public error: ErrorObject;
    public data: RolePermissionAddModel[];
    public message: string;
}

export class EmailSetupResponse extends Response {
    public isSuccess: boolean;
    public error: ErrorObject;
    public data: EmailSetupAddModel[];
}
export class DepartmentResponsex extends Response {
    public isSuccess: boolean;
    public error: ErrorObject;
    public data: DepartmentViewModel[];
    public message: string;
}

export class DepartmentUserEmailResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: EmailSetupAddModel[];
}

export class EmailUpdateResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: EmailSetupAddModel[];
}




export class XFacAddressResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacAddressAddModel[];
}

export class XFacAssetResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacAssetAddModel[];
}

export class XFacContactResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacContactAddModel[];
}

export class XFacDependentResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacDependentAddModel[];
}

export class XFacEmploymentResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacEmploymentAddModel[];
}

export class XFacEstatePlanningResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacEstatePlanningAddModel;
}

export class XFacExecutorResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacExecutorAddModel[];
}

export class XFacExpensesResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacExpensesAddModel[];
}

export class XFacGoalResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacGoalAddModel[];
}

export class XFacIncomeResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacIncomeAddModel[];
}

export class XFacInsuranceLifeResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacInsuranceLifeAddModel[];
}

export class XFacLiabilityResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacLiabilityAddModel[];
}

export class XFacPersonalInfoResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacPersonalInfoAddModel;
}

export class XplanChoicesResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XplanChoices[];
}

export class XFacPowerOfAttorneyResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacPowerOfAttorneyAddModel[];
}

export class XFacRetirementIncomeResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacRetirementIncomeAddModel[];
}

export class XFacSuperannuationResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacSuperannuationAddModel[];
}

export class XFacSuperannuationBeneficiaryResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacSuperannuationBeneficiaryAddModel[];
}

export class XFacSuperAnnuationContributionResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacSuperAnnuationContributionAddModel[];
}

export class XPlanSubmitResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: {};
}

export class XPlanCoverResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XplanCoverAddModel[];
}

export class XFacInsuranceMedicalResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XFacInsuranceMedicalAddModel[];
}

export class XFacVersionResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XplanVersionAddModel;
}

export class XFacStatusListResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: XplanStatusView[];
}

export class XFacStatusUserList extends Response {
    public success: boolean;
    public isSuccess: boolean;
    public error: ErrorObject;
    public response: User[];
}

export class UserVisibilityResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    //public data :User[];
}

export class ClientAddResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: ClientViewModel;
}

export class ClientDeleteResponse {
    public success: boolean;
    public error: ErrorObject;
    public response: boolean;
}

export class ChangePasswordResponse extends Response {
    public isSuccess: boolean;
    public validationIssues: ErrorObject;
    public data: PasswordChangeModel[];
    public message: string;
}

export class AudienceResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: AudienceAddModel[];
}

export class AudienceDataResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: AudienceAddModel;
}

export class AudienceDeleteResponse {
    public success: boolean;
    public error: ErrorObject;
    public response: boolean;
}

export class AudienceMemberResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: AudienceMemberAddModel[];
}

export class AudienceMemberSearchDataResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: AudienceMemberSearchListDataModel[];
}

export class NetworthSnapshotHistoryResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public data: NetworthHistoryModel[];
}

export class ServiceAgreementResponseProfile extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: ServiceAgreementAddModel;
}

export class ServiceAgreementResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: {
        fdsList: ServiceAgreementListModel[]
        totalCount: number
    };
}
export class ResponseMessage {

    public messageCode: string;
    public messageDescription: string;

}

export class UserGroupResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: UserGroupAddModel[];
}

export class ServiceAgreementProfileResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: ServiceAgreementListModel[];
}

export class SyncMonitoringResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: {
        result: SyncMonitoringListModel[]
        totalRecords: number
    };
}

export class netWorthResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: Item[];
}
export class clientMigrateResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: any;
}

export class clientRevertResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: any;
}

export class UnallocatedAccountsResponse extends Response {
    public success: boolean;
    public error: ErrorObject;
    public response: any;
}