import { Component, OnInit, TemplateRef} from '@angular/core';
import { ClientsService } from '../clients/clients.service';
import { AppointmentService } from './appointment.service';
import { ClientListModel,ClientViewModel } from '../shared/models/client.model';
import { AppointmentAddModel } from '../shared/models/appointment.model';
import { ClentListResponse, ClentResponsex, AppointmentResponse, AgentResponse, ClentResponsexNew } from '../shared/models/response.model';
import { Response } from 'selenium-webdriver/http';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddAppointmentComponent } from './add-appointment/add-appointment.component';
import { EditAppointmentComponent } from './edit-appointment/edit-appointment.component';
import * as moment from "moment";
import { AgentsService } from '../agents/agents.service';
import { AgentViewModel } from '../shared/models/agent.model';
import { CallComponent } from '../video/call/call.component';
import { CallService } from '../video/call/call.service';
import { first } from 'rxjs/operators';
//import { DatetimePopupModule } from 'ngx-bootstrap-datetime-popup';
import { Router } from '@angular/router';
//import { SidebarService } from '../sidebar/sidebar.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css']
})
export class AppointmentsComponent implements OnInit {

  //private sidebarService: SidebarService,
  constructor(private toastrService: ToastrService,private ngxService: NgxUiLoaderService, private router : Router ,private videoChatService: CallService,private agentService: AgentsService,private clientsService: ClientsService ,private appointmentService: AppointmentService,private modalService: BsModalService ) { }
  clientList:ClientViewModel[];
  appointmentList:AppointmentAddModel[];
  pendingAppointmentList:AppointmentAddModel[];
  pendingAppointmentOne:AppointmentAddModel;
  modalRef: BsModalRef;
  modalRefVideo: BsModalRef;
  isLoaded :boolean = false;
  isLoadedPendingAppointments :boolean = false;
  client : ClientViewModel; 
  agentList :AgentViewModel[];
  agent : AgentViewModel; 
  message;
  isLoadedAgents :boolean = false;
  dtOptions: DataTables.Settings = {}; 

  ngOnInit() {
    //console.log(" spinner.show ");   
    //this.ngxService.start();
    
    //this.getClientList();
    this.getAppointmentList(); // comment by thilina, request by DevT (uncomment, DevT remove the service)
    this.getPendingAppointments(); // comment by thilina, request by DevT (uncomment, DevT remove the service)
    this.getAgentList();

    this.dtOptions = {
      //pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      order:[]
    };

    // if(this.isLoadedPendingAppointments && this.isLoaded && this.isLoadedAgents){
    //   console.log(12345678);
    //   this.ngxService.stop();
    // }
    //this.toastrService.error("Success","Successfully saved");
  }

  getAppointmentList(){
    //this.sidebarService.preloaderAction(true);
    this.appointmentService.appointmentList().subscribe((data:AppointmentResponse)=>{   
      this.isLoaded = true;  
      this.appointmentList = data.response;
      
      // .filter(function(appointment){
      //   return appointment.sheduledDate == new Date(appointment.sheduledDate+'Z');;
      // });;      

      // console.log('Appointment list for ---------');
      // console.log(this.appointmentList);
      
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });      
  }

  getPendingAppointments(){
    this.appointmentService.getPendingAppointments().subscribe((data:AppointmentResponse)=>{     
      this.isLoadedPendingAppointments = true;
      this.pendingAppointmentList = data.response;      
      this.pendingAppointmentOne = data.response[0]; 
      //console.log('Appointment list pending ---------');
      //console.log(this.pendingAppointmentList);
      // console.log(this.pendingAppointmentOne);
      
      
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });   
    //Appoinment/getPending
  }

  openModal() {

    this.modalRef = this.modalService.show(AddAppointmentComponent,  {
      initialState: {
        title: 'Add Appointment',     
        data: {},
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-dialog modal-lg modal_lg_width"
    });
  
    this.modalRef.content.event.subscribe(result => {      
      if (result == 'OK') {
        //console.log("Success");
          this.getAppointmentList();
      
      }

    });
  }

  openEditModal(appointment:any) {  
    //console.log(1111111111);  
    //console.log(appointment);  


    let scheduledDat = appointment.sheduledDate;
      //appointment.sheduledDate = this.formatLocalTime(scheduledDat);//.format('YYYY-MM-DD'); // hh:mm a
      //appointment.hour = moment(scheduledDat).format('hh');
      //appointment.minute = moment(scheduledDat).format('mm');
      //appointment.amPm = moment(scheduledDat).format('A');

      //console.log(appointment.sheduledDate);
      
    this.modalRef = this.modalService.show(EditAppointmentComponent,  {
      initialState: {
        title: 'Edit Appointment',    
        data: {
          appointment:appointment
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-dialog modal-lg modal_lg_width"
    });
  
    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        // this.getAppointmentList();    
        // this.getPendingAppointments();
        // window.location.reload();
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['appointments']);
      }
    });

  }

  openCallModal(appointment:any) {
    //this.modalRef.hide();
    // this.videoChatService.createVideoCallToken(appointment).pipe(first())
    // .subscribe(
    //   data => {
    //     if(data.success){
       
    //      let twilio_token:String = JSON.parse( localStorage.getItem('twilio_token'));
    //      let roomName = data.response.twilioRoom.unique_name;
    //     console.log(11111111);
    //     console.log(twilio_token);
    //      this.videoChatService.joinRoom(twilio_token, roomName).then(value => {
    //       this.activeRoom = value;
    //       //this.participants.initialize(this.activeRoom.participants);
    //       this.attachLocalVideo();
    //       this.registerRoomEvents();
    //       this.attachExistingParticipants(this.activeRoom);
    //      });

    //     }else{
    //      //// this.loading =false;
    //      // this.toastr.error(Utility.GetLocalErrorString(data.errorCode));
    //     }
    //   },
    //   error => {
    //     //this.alertService.error(error);
    //     console.log(error);
    //   }        
    // );

    let initialState = {
      title: 'Call with user',
      data: {
        appointment:appointment
      },
    };

    this.modalRef = this.modalService.show(CallComponent,  {
      initialState,
      class: "video_modal",      
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true
    });
  
    this.modalRef.content.event.subscribe(result => {      
      if (result == 'call_disconnected') {
        //console.log("8888888");
        this.modalRef.hide();
        
        //console.log("Success");
        //this.appointmentService.disconnectCall(appointment);
        window.location = window.location;
        //this.router.navigate(['/appointments']);
         // this.getAppointmentList();      
      }
    });
  }

  public openConfirmationDialog(template: TemplateRef<any>,agent:AgentViewModel,status:boolean) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });    
    this.message = 'Are you sure that you want to start call?'    
  }

  decline(): void {
    this.modalRef.hide();
  }


  getClientList(){
    // this.clientsService.clientList().subscribe((data:ClentResponsex)=>{ 
      this.clientsService.clientList().subscribe((data:ClentResponsexNew)=>{    
      this.clientList = data.response.data.filter(function(client){
        return client.client !== null;
      });            
      //console.log('Client list for appointments ---------');
      //console.log(this.clientList);
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });      
  }


getAgentList(){
  this.agentService.agentList().subscribe((data:AgentResponse)=>{  
    this.isLoadedAgents = true;   
    this.agentList = data.data.filter(function(client){
      return client.fortressAdmin !== null;
    });;
    //this.agentsCount = this.agentList.length;
    //this.assignCopy();
    //console.log('Agent list--------------------------');
    //console.log(this.agentList);

    
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });      
}
  
  getUserName(clientId : string){
    // if (this.clientList.find((x) => x.client.id == clientId)) {
    //   return this.client;
    // }

    this.client = this.clientList.find(x => x.id == clientId);
    //console.log(this.client);
    return this.client;
  }

  getAdvisorName(advisorId : string){
    this.agent = this.agentList.find(x => x.fortressAdmin.id == advisorId);
    //console.log(this.agent);
    return this.agent;
  }

  formatLocalTime(date){
    return new Date(date+'Z');
  }

}
