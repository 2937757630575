import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as jsPDF  from 'jspdf'
import html2canvas from 'html2canvas'; 
import { XFacPersonalInfoAddModel } from 'src/app/shared/models/xfac-personalInfo.model';
import { XFacAssetResponse, XFacPersonalInfoResponse, XplanChoicesResponse, XFacContactResponse, XFacAddressResponse, XFacDependentResponse, XFacEmploymentResponse, XFacEstatePlanningResponse, XFacLiabilityResponse, XFacIncomeResponse, XFacExpensesResponse, XFacGoalResponse, XFacRetirementIncomeResponse, XFacInsuranceMedicalResponse, XFacInsuranceLifeResponse } from 'src/app/shared/models/response.model';
import { XFacContactAddModel } from 'src/app/shared/models/xfac-contact.model';
import { XFacAddressAddModel } from 'src/app/shared/models/xfac-address.model';
import { XFacDependentAddModel } from 'src/app/shared/models/xfac-dependent.model';
import { XFacEmploymentAddModel } from 'src/app/shared/models/xfac-employment.model';
import { XFacInsuranceLifeAddModel } from 'src/app/shared/models/xfac-InsuranceLife.model';
import { XFacEstatePlanningAddModel } from 'src/app/shared/models/xfac-estatePlanning.model';
import { XFacLiabilityAddModel } from 'src/app/shared/models/xfac-liability.model';
import { XFacAssetAddModel } from 'src/app/shared/models/xfac-asset.model';
import { XFacIncomeAddModel } from 'src/app/shared/models/xfac-income.model';
import { XFacExpensesAddModel } from 'src/app/shared/models/xfac-expenses.model';
import { XFacGoalAddModel } from 'src/app/shared/models/xfac-goal.model';
import { XFacRetirementIncomeAddModel } from 'src/app/shared/models/xfac-retirementIncome.model';
import { XFacInsuranceMedicalAddModel } from 'src/app/shared/models/xplan-insuranceMedical.model';
import { FacFinderService } from '../fac-finder.service';
import { XplanChoices } from 'src/app/shared/models/xplan-choices.model';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { XplanCoverGrouped } from 'src/app/shared/models/xplan-cover-grouped.model';

@Component({
  selector: 'app-download-pdf',
  templateUrl: './download-pdf.component.html',
  styleUrls: ['./download-pdf.component.css']
})
export class DownloadPdfComponent implements OnInit {

  personalInfo:XFacPersonalInfoAddModel;
  personalData: XFacPersonalInfoAddModel;
  contactInfo:XFacContactAddModel[];
  addressInfo:XFacAddressAddModel[];
  dependentInfo:XFacDependentAddModel[];
  employmentInfo:XFacEmploymentAddModel[];
  estatePlanninInfo:XFacEstatePlanningAddModel;
  estatePlanninData:XFacEstatePlanningAddModel;
  assetInfo:XFacAssetAddModel[];
  liabilityInfo:XFacLiabilityAddModel[];
  incomeData:XFacIncomeAddModel[];
  expenseData:XFacExpensesAddModel[];
  goalData:XFacGoalAddModel[];
  insuranceData:XFacInsuranceLifeAddModel[];
  retirementIncomeInfo:XFacRetirementIncomeAddModel[];
  medicalInsuranceInfo : XFacInsuranceMedicalAddModel[];
  choicesList : XplanChoices[];
  // personalInfo:any;
  // contactInfo:any;
  // addressInfo:any;
  // dependentInfo:any;
  // employmentInfo:any;
  // estatePlanninInfo:any;
  // assetInfo:any;
  // liabilityInfo:any;
  // incomeData:any;
  // expenseData:any;
  // goalData:any;
  // insuranceData:any;
  // retirementIncomeInfo:any;
  // medicalInsuranceInfo : any;
  // estatePlanninData:any;

  personalData_title_list : any;
  personalInfo_gender_list : any;
  personalInfo_marital_list : any;
  contact_type_list : any;
  address_type_list:any;
  dependant_gender_list:any;
  employment_status_list: any;
  goal_owner_list: any;
  income_cashflow_list:any;
  income_cashflow_subtype_list:any;
  income_expense_cashflow_owner_list:any;
  income_expense_cashflow_frequency_list:any;
  expense_cashflow_list: any;
  expense_cashflow_subtype_list: any;
  asset_owner_type_list: any;
  insurance_type_list: any;
  insurance_benefit_period_type_list: any;
  insurance_waiting_period_type_list: any;
  insurance_type_list_filtered: any=[];
  asset_owner_list :any;
  asset_type: any;
  asset_sub_type: any;
  liability_owner_list :any;
  liability_type_list: any;
  liability_sub_type_list: any;
  liability_repayment_frequency_list:any;
  liability_interest_rate_type_list:any;
  liability_lender_supplier_list:any;
  retirement_income_plan_provider_list:any;
  retirement_plan_selection_list:any;
  insurance_medical_type_list:any;
  employment_occupation_list:any;

  isLoaded1: boolean;
  isLoaded2: boolean;
  isLoaded3: boolean;
  isLoaded4: boolean;
  isLoaded5: boolean;
  isLoaded6: boolean;
  isLoaded7: boolean;
  isLoaded8: boolean;
  isLoaded9: boolean;
  isLoaded10: boolean;
  isLoaded11: boolean;
  isLoaded12: boolean;
  isLoaded13: boolean;
  isLoaded14: boolean;
  isLoaded15: boolean;

  liabilityLength: number = 0;
  contactInfoLength:number = 0;
  addressInfoLength:number = 0;
  dependentInfoLength: number = 0;
  employmentInfoLength: number =0;
  estatePlanninInfoLength: number =0;
  assetInfoLength: number =0;
  incomeDataLength: number =0;
  expenseDataLength: number =0;
  goalDataLength: number =0;
  insuranceDataLength: number =0;
  factFindersNewLength: number = 0;
  //personalInfoLength: number = 0;
  retirementIncomeInfoLength: number = 0;
  medicalInsuranceInfoLength: number = 0;

  facFinderId: any;
  clientCode: any;
  versionNumber: any;
  data: any;
  nowDate: any;
  docummentName: any;

  

  constructor(
    public modalRef: BsModalRef,
    private facFinderService: FacFinderService,
    private ngxService: NgxUiLoaderService,
  ) {
    this.ngxService.start();
   }

  ngOnInit() {
    this.getChoiceList();
    // this.personalInfo = this.data.getPersonalInfo;
    // this.contactInfo = this.data.getContact;
    // this.addressInfo = this.data.getAddress;
    // this.dependentInfo = this.data.getDependent;
    // this.employmentInfo = this.data.getEmploymen;
    // this.estatePlanninInfo = this.data.getEstatePlanning;
    // this.assetInfo = this.data.getAsset;
    // this.liabilityInfo = this.data.getLiability;
    // this.incomeData = this.data.getIncome;
    // this.expenseData = this.data.getExpenses;
    // this.goalData = this.data.getGoals;
    // this.insuranceData = this.data.getInsurance;
    // this.retirementIncomeInfo = this.data.getRetirementIncome;
    // this.medicalInsuranceInfo = this.data.getInsuranceMedical;
    this.facFinderId = this.data.facFinderId;  
    this.clientCode = this.data.clientCode; 
    this.versionNumber = this.data.versionNumber;
    this.docummentName = 'version_'+this.data.versionNumber;
      
    

    
  }

  getChoiceList(){
    // this.ngxService.start();
    this.facFinderService.getChoiceList().subscribe((clientData:XplanChoicesResponse)=>{
      if(clientData.success){
        this.choicesList = clientData.response;  
        this.personalData_title_list = this.choicesList.filter(choice => choice.choiceType === 20);
        this.personalInfo_gender_list = this.choicesList.filter(choice => choice.choiceType === 21);
        this.personalInfo_marital_list = this.choicesList.filter(choice => choice.choiceType === 22);
        this.contact_type_list = this.choicesList.filter(choice => choice.choiceType === 6);
        this.address_type_list = this.choicesList.filter(choice => choice.choiceType === 1);
        this.dependant_gender_list = this.choicesList.filter(choice => choice.choiceType === 3);
        this.employment_status_list = this.choicesList.filter(choice => choice.choiceType === 4);
        this.goal_owner_list = this.choicesList.filter(choice => choice.choiceType === 7);
        this.income_cashflow_list = this.choicesList.filter(choice => choice.choiceType === 12);
        this.income_cashflow_subtype_list = this.choicesList.filter(choice => choice.choiceType === 10);
        this.income_expense_cashflow_owner_list = this.choicesList.filter(choice => choice.choiceType === 13);
        this.income_expense_cashflow_frequency_list = this.choicesList.filter(choice => choice.choiceType === 14);
        this.expense_cashflow_list = this.choicesList.filter(choice => choice.choiceType === 11);
        this.expense_cashflow_subtype_list = this.choicesList.filter(choice => choice.choiceType === 5);
        this.asset_owner_type_list = this.choicesList.filter(choice => choice.choiceType === 8);
        this.insurance_benefit_period_type_list = this.choicesList.filter(choice => choice.choiceType === 19);
        this.insurance_waiting_period_type_list = this.choicesList.filter(choice => choice.choiceType === 18);
        this.insurance_type_list = this.choicesList.filter(choice => choice.choiceType === 17);

        this.filterInsuranceTypeList(this.insurance_type_list);    
        this.asset_owner_list = this.choicesList.filter(choice => choice.choiceType === 8);
        this.asset_type = this.choicesList.filter(choice => choice.choiceType === 9);
        this.asset_sub_type = this.choicesList.filter(choice => choice.choiceType === 2);   

        this.liability_owner_list = this.choicesList.filter(choice => choice.choiceType === 8);
        this.liability_type_list = this.choicesList.filter(choice => choice.choiceType === 24);
        this.liability_sub_type_list = this.choicesList.filter(choice => choice.choiceType === 25);
        this.liability_repayment_frequency_list = this.choicesList.filter(choice => choice.choiceType === 26);
        this.liability_interest_rate_type_list = this.choicesList.filter(choice => choice.choiceType === 27);
        this.liability_lender_supplier_list = this.choicesList.filter(choice => choice.choiceType === 28);
        this.retirement_income_plan_provider_list = this.choicesList.filter(choice => choice.choiceType === 15);
        this.retirement_plan_selection_list = this.choicesList.filter(choice => choice.choiceType === 16);
        this.insurance_medical_type_list = this.choicesList.filter(choice => choice.choiceType === 23);
        this.employment_occupation_list = this.choicesList.filter(choice => choice.choiceType === 29);  
      } 
      this.nowDate = new Date();
      this.isLoaded1 = true;  

      
      this.getPersonalInfo();
      this.getContact();
      this.getAddress();
      this.getDependent();
      this.getEmploymen();
      this.getEstatePlanning();
      this.getAsset();
      this.getLiability();
      this.getIncome();
      this.getExpenses();
      this.getGoals();
      this.getRetirementIncome();
      this.getInsuranceMedical();
      this.getInsurance();

      if(this.isLoaded1 && this.isLoaded2 && this.isLoaded3 && this.isLoaded4 && this.isLoaded5 && this.isLoaded6 && this.isLoaded7 && this.isLoaded8 && this.isLoaded9 && this.isLoaded10 && this.isLoaded11 && this.isLoaded12 && this.isLoaded13 && this.isLoaded14 && this.isLoaded15) {
        this.ngxService.stop();
      }
      
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message); 
      });
  }

  filterInsuranceTypeList(insurance_type_list){
    insurance_type_list.forEach(element => {
      if(element.key.indexOf("l")==0){
        element.insurance_type = "life";
        element.insurance_value = "Life Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("d")==0){
        element.insurance_type = "tpd";
        element.insurance_value = "TPD Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("t")==0){
        element.insurance_type = "trauma";
        element.insurance_value = "Trauma Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("i")==0){
        element.insurance_type = "income";
        element.insurance_value = "Income Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("b")==0){
        element.insurance_type = "business";
        element.insurance_value = "Business Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("o")==0){
        element.insurance_type = "additional";
        element.insurance_value = "Additional Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("x")==0){
        element.insurance_type = "severity";
        element.insurance_value = "Severity Cover";
        this.insurance_type_list_filtered.push(element);
      }
      // insurance_type_list_filtered
    });
  }

  getPersonalInfo(){
    this.ngxService.start();
    this.facFinderService.getPersonalInfo(this.facFinderId).subscribe((clientData:XFacPersonalInfoResponse)=>{
      if(clientData.success){
        this.personalInfo = clientData.response;
        this.personalData = this.personalInfo;
        
        if(this.personalData) {
          this.personalData.dateOfBirth = new Date(this.personalData.dateOfBirth+'Z');
        }
        
        this.isLoaded2 = true;
      } 
      this.ngxService.stop(); 
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });
  }

  getContact(){
    this.ngxService.start();    
    this.facFinderService.getContact(this.facFinderId).subscribe((contactData:XFacContactResponse)=>{
      if(contactData.success){  
          this.contactInfo = contactData.response;
          // console.log('contactInfo');
          // console.log(this.contact_type_list);
          this.contactInfo.forEach(element => {            
            let choice = this.contact_type_list.filter(choice => choice.key === element.contactType);
            if(choice && choice[0]) {
              element.contactTypeName = choice[0].value;
            }
          });
         
          if(this.contactInfo.length>0){
            this.contactInfoLength = this.contactInfo.length;
          }
          // console.log('contactInfoLength');
          // console.log(this.contactInfoLength);
          this.isLoaded3 = true; 
        } 
        this.ngxService.stop();
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  getAddress(){
    this.ngxService.start();
    this.facFinderService.getAddress(this.facFinderId).subscribe((addressData:XFacAddressResponse)=>{
        this.addressInfo = addressData.response;

        this.addressInfo.forEach(element => {            
          let choice = this.address_type_list.filter(choice => choice.key === element.addressType);
          if(choice && choice[0]) {
            element.addressTypeName = choice[0].value;
          }
        });

        if(this.addressInfo.length>0){
          this.addressInfoLength = this.addressInfo.length;
        }
        this.isLoaded4 = true; 
        this.ngxService.stop();
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  getDependent(){
    this.ngxService.start();
    this.facFinderService.getDependent(this.facFinderId).subscribe((dependentData:XFacDependentResponse)=>{
      if(dependentData.success){  
          this.dependentInfo = dependentData.response;
          if(this.dependentInfo.length>0){
            this.dependentInfoLength = this.dependentInfo.length;
          }
          this.isLoaded5 = true; 
        } 
        this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  getEmploymen(){
    this.ngxService.start();
    this.facFinderService.getEmploymen(this.facFinderId).subscribe((employmentData:XFacEmploymentResponse)=>{
      if(employmentData.success){
          this.employmentInfo = employmentData.response;

          this.employmentInfo.forEach(element => {            
            let choice = this.employment_occupation_list.filter(choice => choice.key === element.occupation);
            if(choice && choice[0]) {
              element.occupationName = choice[0].value;
            }
          });

          if(this.employmentInfo.length>0){
            this.employmentInfoLength = this.employmentInfo.length;
          }
          this.isLoaded6 = true; 
        } 
        this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  getEstatePlanning(){
    this.ngxService.start();
    this.facFinderService.getEstatePlanning(this.facFinderId).subscribe((estatePlanninData:XFacEstatePlanningResponse)=>{
        if(estatePlanninData.success){
          this.estatePlanninInfo = estatePlanninData.response;
          //if(this.estatePlanninInfo.length>0){
            this.estatePlanninData = this.estatePlanninInfo;
            //this.estatePlanninInfoLength = this.estatePlanninInfo.length;
          //}  
          this.isLoaded7 = true; 
        } 
        this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  getAsset(){
    this.ngxService.start();
    this.facFinderService.getAsset(this.facFinderId).subscribe((assetData:XFacAssetResponse)=>{
      if(assetData.success){
        this.assetInfo = assetData.response;

        this.assetInfo.forEach(element => {            
          let choice = this.asset_type.filter(choice => choice.key === element.type);
          if(choice && choice[0]) {
            element.assetTypeName = choice[0].value;
          }
        });

        this.assetInfo.forEach(element => {            
          let choice = this.asset_sub_type.filter(choice => choice.key === element.subType);
          if(choice && choice[0]) {
            element.assetSubTypeName = choice[0].value;
          }
        });

        if(this.assetInfo.length>0){
          this.assetInfoLength = this.assetInfo.length;
        }
        this.isLoaded8 = true; 
      } 
      this.ngxService.stop(); 
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });      
  }

  getLiability(){
    this.ngxService.start();
    this.facFinderService.getLiability(this.facFinderId).subscribe((liabilityData:XFacLiabilityResponse)=>{
      if(liabilityData.success){
        this.liabilityInfo = liabilityData.response;

        this.liabilityInfo.forEach(element => {            
          let choice = this.liability_owner_list.filter(choice => choice.key === element.owner);
          if(choice && choice[0]) {
            element.ownerTypeName = choice[0].value;
          }
          
        });

        this.liabilityInfo.forEach(element => {            
          let choice = this.liability_type_list.filter(choice => choice.key === element.type);
          if(choice && choice[0]) {
            element.typeName = choice[0].value;
          }
          
        });

        this.liabilityInfo.forEach(element => {            
          let choice = this.liability_sub_type_list.filter(choice => choice.key === element.subType);
          if(choice && choice[0]) {
            element.subTypeName = choice[0].value;
          }
          
        });
        

        this.liabilityInfo.forEach(element => {            
          let choice = this.liability_repayment_frequency_list.filter(choice => choice.key === element.repaymentFrequency);
          if(choice && choice[0]) {
            element.repaymentFrequencyName = choice[0].value;
          }
          
        });

        this.liabilityInfo.forEach(element => {            
          let choice = this.liability_interest_rate_type_list.filter(choice => choice.key === element.interestRateType);
          if(choice && choice[0]) {
            element.interestRateTypeName = choice[0].value;
          }
          
        });

        this.liabilityInfo.forEach(element => {            
          let choice = this.liability_lender_supplier_list.filter(choice => choice.key === element.lenderSupplier);
          if(choice && choice[0]) {
            element.lenderSupplierName = choice[0].value;
          }
          
        });

        if(this.liabilityInfo.length>0){
          this.liabilityLength = this.liabilityInfo.length;
        } 
        this.isLoaded9 = true; 
      }
      this.ngxService.stop(); 
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });      
  }

  getIncome(){
    this.ngxService.start();
    this.facFinderService.getIncome(this.facFinderId).subscribe((incomeData:XFacIncomeResponse)=>{
      if(incomeData.success){
        this.incomeData = incomeData.response;

        this.incomeData.forEach(element => {            
          let choice = this.income_cashflow_list.filter(choice => choice.key === element.typeGroup);
          if(choice && choice[0]) {
            element.incomeTypeName = choice[0].value;
          }
        });

        this.incomeData.forEach(element => {            
          let choice = this.income_cashflow_subtype_list.filter(choice => choice.key === element.type);
          if(choice && choice[0]) {
            element.incomeSubTypeName = choice[0].value;
          }
        });

        this.incomeData.forEach(element => {            
          let choice = this.income_expense_cashflow_frequency_list.filter(choice => choice.key === element.frequency);
          if(choice && choice[0]) {
            element.incomeFrequencyName = choice[0].value;
          }
        });

        if(this.incomeData.length>0){
          this.incomeDataLength = this.incomeData.length;
        }
        this.isLoaded10 = true; 
      } 
      this.ngxService.stop();
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });      
  }

  getExpenses(){
    this.ngxService.start();
    this.facFinderService.getExpenses(this.facFinderId).subscribe((expensesData:XFacExpensesResponse)=>{
      if(expensesData.success){
        this.expenseData = expensesData.response;

        this.expenseData.forEach(element => {            
          let choice = this.expense_cashflow_list.filter(choice => choice.key === element.typeGroup);
          if(choice && choice[0]) {
            element.expenseTypeName = choice[0].value;
          }
        });

        this.expenseData.forEach(element => {            
          let choice = this.expense_cashflow_subtype_list.filter(choice => choice.key === element.type);
          if(choice && choice[0]) {
            element.expenseSubTypeName = choice[0].value;
          }
        });

        this.expenseData.forEach(element => {            
          let choice = this.income_expense_cashflow_frequency_list.filter(choice => choice.key === element.frequency);
          if(choice && choice[0]) {
            element.expenseFrequencyName = choice[0].value;
          }
        });

        if(this.expenseData.length>0){
          this.expenseDataLength = this.expenseData.length;
        }
        this.isLoaded11 = true; 
      } 
      this.ngxService.stop();
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });    
  }

  getGoals(){
    this.ngxService.start();
    this.facFinderService.getGoals(this.facFinderId).subscribe((goalData:XFacGoalResponse)=>{
      if(goalData.success){
        this.goalData = goalData.response;
        
        if(this.goalData.length>0){
          this.goalDataLength = this.goalData.length;
        }
        this.isLoaded12 = true; 
      } 
      this.ngxService.stop();
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });    
  }

  getRetirementIncome(){
    this.ngxService.start();
    this.facFinderService.getRetirementIncome(this.facFinderId).subscribe((retirementIncomeData:XFacRetirementIncomeResponse)=>{
      if(retirementIncomeData.success){
        this.retirementIncomeInfo = retirementIncomeData.response;

        this.retirementIncomeInfo.forEach(element => {            
          let choice = this.retirement_income_plan_provider_list.filter(choice => choice.key === element.retirementPlan);
          if(choice && choice[0]) {
            element.retirementPlanName = choice[0].value;
          }
        });

        this.retirementIncomeInfo.forEach(element => {            
          let choice = this.retirement_plan_selection_list.filter(choice => choice.key === element.retirementPlanSelection);
          if(choice && choice[0]) {
            element.retirementPlanSelectionName = choice[0].value;
          }
        });

        if(this.retirementIncomeInfo.length>0){
          this.retirementIncomeInfoLength = this.retirementIncomeInfo.length;
        }
        this.isLoaded14 = true; 
      } 
      this.ngxService.stop(); 
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });      
  }

  getInsuranceMedical(){
    this.ngxService.start();
    this.facFinderService.getInsuranceMedical(this.facFinderId).subscribe((medicalInsuranceInfo:XFacInsuranceMedicalResponse)=>{
      if(medicalInsuranceInfo.success){
        this.medicalInsuranceInfo = medicalInsuranceInfo.response;

        this.medicalInsuranceInfo.forEach(element => {            
          let choice = this.insurance_medical_type_list.filter(choice => choice.key === element.type);
          if(choice && choice[0]) {
            element.insuranceMedicalTypeName = choice[0].value;
          }
        });

        this.medicalInsuranceInfo.forEach(element => {            
          let choice = this.asset_owner_list.filter(choice => choice.key === element.owners);
          if(choice && choice[0]) {
            element.ownerName = choice[0].value;
          }
        });

        if(this.medicalInsuranceInfo.length>0){
          this.medicalInsuranceInfoLength = this.medicalInsuranceInfo.length;
        }
        this.isLoaded15 = true; 
      } 
      this.ngxService.stop(); 
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });      
  }

  getInsurance(){
    this.ngxService.start();
    this.facFinderService.getInsurance(this.facFinderId).subscribe((insuranceLifeData:XFacInsuranceLifeResponse)=>{
      if(insuranceLifeData.success){
        this.insuranceData = insuranceLifeData.response;
        
        if(this.insuranceData.length>0){
          this.insuranceDataLength = this.insuranceData.length;

          this.insuranceData.forEach(element => {            
            let choice = this.liability_owner_list.filter(choice => choice.key === element.policyOwners);
            if(choice && choice[0]) {
              element.ownerTypeName = choice[0].value;
            }
            
          });

          this.insuranceData.forEach(element => {
            if(element.xFacInsuranceCovers){
              element.groupedCovers =  this.groupCovers(element.xFacInsuranceCovers);
            }
          });  
          // console.log('getInsurance');
          // console.log(this.insuranceData);
          //this.insuranceData.forEach()
          
        }
        
        this.isLoaded13 = true; 
      } 
      this.ngxService.stop();
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });    
  }

  groupCovers(xFacInsuranceCovers){
    this.ngxService.start();
    let grouped = new XplanCoverGrouped();
    xFacInsuranceCovers.forEach(element => {
      if(element.insuranceCoverType){
        if(element.insuranceCoverType.indexOf("l")==0){

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.life.push(element);
        }
        else if(element.insuranceCoverType.indexOf("d")==0){
          // grouped['tpd'].push(element);

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.tpd.push(element);
          // element.insurance_type = "tpd";
          // element.insurance_value = "TPD Cover";
          // this.insurance_type_list_filtered.push(element);
        }
        else if(element.insuranceCoverType.indexOf("t")==0){
          //grouped['trauma'].push(element);

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.trauma.push(element);
          // element.insurance_type = "trauma";
          // element.insurance_value = "Trauma Cover";
          // this.insurance_type_list_filtered.push(element);
        }
        else if(element.insuranceCoverType.indexOf("i")==0){
          // grouped['income'].push(element);

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.income.push(element);
          // element.insurance_type = "income";
          // element.insurance_value = "Income Cover";
          // this.insurance_type_list_filtered.push(element);
        }
        else if(element.insuranceCoverType.indexOf("b")==0){
          // grouped['business'].push(element);

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.business.push(element);
          // element.insurance_type = "business";
          // element.insurance_value = "Business Cover";
          // this.insurance_type_list_filtered.push(element);
        }
        else if(element.insuranceCoverType.indexOf("o")==0){
          // grouped['additional'].push(element);

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.additional.push(element);
          // element.insurance_type = "additional";
          // element.insurance_value = "Additional Cover";
          // this.insurance_type_list_filtered.push(element);
        }
        else if(element.insuranceCoverType.indexOf("x")==0){
          // grouped['severity'].push(element);

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }
          
          grouped.severity.push(element);
          // element.insurance_type = "severity";
          // element.insurance_value = "Severity Cover";
          // this.insurance_type_list_filtered.push(element);
        }
        //element.groupedCovers =  this.groupCovers(element.xFacInsuranceCovers);
        this.ngxService.stop();
      }
    });  
    return grouped;
  }
  






  




  public captureScreen()  
  {  
    var data = document.getElementById('contentToConvert');  
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      var imgWidth = 208;   
      var pageHeight = 295;    
      var imgHeight = canvas.height * imgWidth / canvas.width;  
      var heightLeft = imgHeight;  
  
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save('MYPdf.pdf'); // Generated PDF   
    });  
  }

  @ViewChild('pdfTable') pdfTable: ElementRef;

  public downloadAsPDF() {
    const doc = new jsPDF('p', 'pt', 'a4');
    //doc.setFontSize(18);
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };

    const pdfTable = this.pdfTable.nativeElement;

    doc.fromHTML(pdfTable.innerHTML, 10, 10, {
      width: 208,
      'elementHandlers': specialElementHandlers
    });
    
    setTimeout(function () {
      doc.save('tableToPdf.pdf');
    }, 1000); 
  }


  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

}
