import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConstants } from '../utility/app-constants';
import { Response,ClentResponsex,ClentProfileResponse,InvoiceResponse, TransactionResponse } from '../shared/models/response.model';
import { InvoiceAddModel } from '../shared/models/invoice.model';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { TransactionViewModel } from '../shared/models/transaction.model';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  baseUrl:String;
  
  constructor(private http:HttpClient) {
    this.baseUrl = AppConstants.getBaseURL();
  }

  public handleError(error) {
    return throwError(error);
  }
  
  public invoiceList(){    
    //const params = new HttpParams().set('userType','2');
    return this.http.get<InvoiceResponse>(this.baseUrl+'/FortressInvoices');    
  }

  public clientInvoiceList(clientId){
    const params = new HttpParams().set('id',clientId);
    return this.http.get<InvoiceResponse>(this.baseUrl+'/FortressInvoice/UserInvoices',{params});    
  }
  
  public addInvoice(invoiceModel:InvoiceAddModel){
    //const params = new HttpParams().set('userType', "1"); 
    //invoiceModel.userType = 1; 
    let inv = Object.assign({}, invoiceModel); 
    inv.amount = invoiceModel.amount*100;
    inv.gst = invoiceModel.gst*100;
    //console.log(inv);
    return this.http.post<InvoiceResponse>(this.baseUrl +'/FortressInvoice',inv).pipe(
     catchError(this.handleError)
     );    
  }

  public invoiceEdit(id:string,invoice:InvoiceAddModel) {  
    const params = new HttpParams().set('id', id); 
    let inv = Object.assign({}, invoice); 
    inv.amount = invoice.amount*100;
    //inv.dueDate = new Date(invoice.dueDate);
    inv.gst = invoice.gst*100;
    return this.http.put<InvoiceResponse>(this.baseUrl+'/FortressInvoice',inv,{params}).pipe(
      catchError(this.handleError)
      );
  }

  public transactiontList() {
    return this.http.get<TransactionResponse>(this.baseUrl +'/FortressTransactions').pipe(
      catchError(this.handleError)
      );
  }
  
}
