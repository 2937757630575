
    <div class="modal-header border-bottom-0">
      <h5 class="modal-title factfinderheader" id="exampleModalLabel">{{title}} Insurance Cover Type</h5>
      <button type="button" class="close" (click)="modalRehide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <form [formGroup]="addCoverForm" novalidate class="form " (ngSubmit)="onSubmit()" >
    <div class="modal-body factfinderbody">
      
        <div class="form-group row factfinderpopup">
            <label for="categoryList" class="labelwidth">Cover Category</label>
            <div class="inputwidth"> <span>:</span>           
              <select formControlName="categoryList" (ngModelChange)="onChange($event)" required [(ngModel)]="coverAddModel.categoryList" class="form-control form-control-sm" placeholder="Insurance Category" >
                  <option value="000">Please select</option>
                  <option value="life">Life Insurance</option>
                  <option value="tpd">TPD Insurance</option>
                  <option value="trauma">Trauma Insurance</option>
                  <option value="income">Income Insurance</option>
                  <option value="business">Business Insurance</option>
                  <option value="additional">Additional Insurance</option>
                  <option value="severity">Severity Insurance</option>                 
                </select>
    
              <div class="form-group">
                <div *ngIf="submitted && f.categoryList.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.categoryList.errors.required">Insurance category is required.</div>
                </div>
              </div>  
            </div>
          </div>

      <div class="form-group row factfinderpopup">
        <label for="insuranceCoverType" class="labelwidth">Cover Type</label>
        <div class="inputwidth"> <span>:</span>           
          <select formControlName="insuranceCoverType" required [(ngModel)]="coverAddModel.insuranceCoverType" class="form-control form-control-sm" placeholder="Insurance Cover Type" >
              <option value="000">Please select</option>

              <ng-container *ngFor="let insuranceType of insuranceTypeListFiltered; let i = index">
                  <option value="{{insuranceType.key}}">{{insuranceType.value}} </option>
              </ng-container>
              
            </select>

          <div class="form-group">
            <div *ngIf="submitted && f.insuranceCoverType.errors"   class="form_alert">
                    <span  class="ft-alert-circle"></span>
                <div *ngIf="f.insuranceCoverType.errors.required">Cover type is required.</div>
            </div>
          </div>  
        </div>
      </div>

      <div class="form-group row factfinderpopup">
        <label for="sumInsured" class="labelwidth">Sum Insured</label>
        <div class="inputwidth"> <span>:</span>
          <!-- <input type="text" class="form-control form-control-sm" placeholder="Mobile Phone"> -->
          <input formControlName="sumInsured" required [(ngModel)]="coverAddModel.sumInsured" type="number" pattern="^[1-9]\d*$" class="form-control form-control-sm" placeholder="Sum Insured">
              <div class="form-group">
                <div *ngIf="submitted && f.sumInsured.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.sumInsured.errors.required">Sum insured is required.</div>
                    <div *ngIf="f.sumInsured.errors.pattern">Only numbers.</div>
              </div>
              </div>
        </div>
      </div>

      <!-- <div class="form-group row factfinderpopup">
        <label for="lifeInsured" class="labelwidth">Life Insured</label>
        <div class="inputwidth"> <span>:</span> 
          <input formControlName="lifeInsured" required [(ngModel)]="coverAddModel.lifeInsured" type="text" class="form-control form-control-sm" placeholder="Life Insured">
          <div class="form-group">
            <div *ngIf="submitted && f.lifeInsured.errors"   class="form_alert">
                    <span  class="ft-alert-circle"></span>
                <div *ngIf="f.lifeInsured.errors.required">Life insured is required.</div>
            </div>
          </div>  
        </div>
      </div> -->

      <!-- <div class="form-group row factfinderpopup">
        <label for="totalPremium" class="labelwidth">Total Premium</label>
        <div class="inputwidth"> <span>:</span> 
          <input formControlName="totalPremium" required [(ngModel)]="coverAddModel.totalPremium" type="text" class="form-control form-control-sm" placeholder="Total Premium">
          <div class="form-group">
            <div *ngIf="submitted && f.totalPremium.errors"   class="form_alert">
                    <span  class="ft-alert-circle"></span>
                <div *ngIf="f.totalPremium.errors.required">Total premium is required.</div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="form-group row factfinderpopup">
        <label for="waitingPeriod" class="labelwidth">Waiting Period</label>
        <div class="inputwidth"> <span>:</span> 
          <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
          <!-- <input formControlName="policyOwners" required [(ngModel)]="coverAddModel.policyOwners" type="text" class="form-control form-control-sm" placeholder="Policy Owners"> -->
          
          <select formControlName="waitingPeriod" required [(ngModel)]="coverAddModel.waitingPeriod" class="form-control form-control-sm" placeholder="Waiting Period" >
              <option value="000">Please select</option>
              
              <ng-container *ngFor="let waitingPeriodType of waitingPeriodTypeList; let i = index">
                  <option value="{{waitingPeriodType.key}}">{{waitingPeriodType.value}}</option>
              </ng-container>
              
            </select>

          <div class="form-group">
            <div *ngIf="submitted && f.waitingPeriod.errors"   class="form_alert">
                    <span  class="ft-alert-circle"></span>
                <div *ngIf="f.waitingPeriod.errors.required">Waiting period is required.</div>
            </div>
          </div>  
        </div>
      </div>     

      <div class="form-group row factfinderpopup">
        <label for="waitingPeriod" class="labelwidth">Benefit Period</label>
        <div class="inputwidth"> <span>:</span>           
          <select formControlName="benefitPeriod" required [(ngModel)]="coverAddModel.benefitPeriod" class="form-control form-control-sm" placeholder="Benefit Period" >
              <option value="000">Please select</option>
              
              <ng-container *ngFor="let benefitPeriodType of benefitPeriodTypeList; let i = index">
                  <option value="{{benefitPeriodType.key}}">{{benefitPeriodType.value}}</option>
              </ng-container>
              
            </select>

          <div class="form-group">
            <div *ngIf="submitted && f.benefitPeriod.errors"   class="form_alert">
                    <span  class="ft-alert-circle"></span>
                <div *ngIf="f.benefitPeriod.errors.required">Benefit period is required.</div>
            </div>
          </div>  
        </div>
      </div> 
      
    </div>
    <div class="modal-footer border-top-0 pt-0">
      <button class="btn btn-primary factfinderbtn">Save</button>
    </div>
</form>
    