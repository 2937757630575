<div class="modal-header a_modal">
  <h4 class="modal-title" id="myModalLabel1">Update User Name</h4>
  <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
</div>

<form #f="ngForm" novalidate class="form" (ngSubmit)="onSubmit(f)" autocomplete="false" >
  <div class="modal-body dallecort_modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-body">
          
          <div class="col-md-12">
            <div class="form-group">
                <label for="userName" class="c_n_fs">User Name*</label>
                <input autocomplete="off" type="text" required minlength="3" class="form-control" id="userName" name="userName"  placeholder="User Name" [(ngModel)]="clientModel.userName" #userName = "ngModel">
                <div class="form-group">
                    <div *ngIf="userName.invalid && (userName.dirty || userName.touched || f.submitted)"
                    class="form_alert">
                    <span  class="ft-alert-circle"></span>
                      <div *ngIf="userName.errors.required">
                          User name is required.
                      </div> 
                      <div *ngIf="userName.errors.minlength">
                        User name must contain at least 3 characters.
                      </div>                                                  
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer a-footer" >
    
    <div *ngIf="errors" class="form_alert">
          <span  class="ft-alert-circle"></span>
        {{errors}}
    </div>
    <!-- <button type="button" class="btn popup_delete_b">Delete</button> -->
    <button type="submit" class="btn popup_save_b">Save</button>
  </div>
</form>
