import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RollbackInsurancePopupComponent } from './rollback-insurance-popup/rollback-insurance-popup.component';
import { RollbackPopupComponent } from './rollback-popup/rollback-popup.component';

@Component({
  selector: 'app-rollback-data',
  templateUrl: './rollback-data.component.html',
  styleUrls: ['./rollback-data.component.css']
})
export class RollbackDataComponent implements OnInit {

  modalRef: BsModalRef;
  
  constructor(
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
  }

  rollbackData(){   
    this.modalRef = this.modalService.show(RollbackPopupComponent,  {
      initialState: {
        title: 'View Pop up',
        data: {
          // 
        },      
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
      },
      class: "modal-s"
      
    });
  
    // this.modalRef.content.event.subscribe(result => {
    //   if (result == 'OK') {
      
    //   }
    // });
  }


  rollbackInsuranceData(){   
    this.modalRef = this.modalService.show(RollbackInsurancePopupComponent,  {
      initialState: {
        title: 'View Pop up',
        data: {
          // 
        },      
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
      },
      class: "modal-s"
      
    });
  
    // this.modalRef.content.event.subscribe(result => {
    //   if (result == 'OK') {
      
    //   }
    // });
  }

}
