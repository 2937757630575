
<ng-container *ngIf="clientIdfromProfile == null">
    <app-header ></app-header>
    <app-sidebar ></app-sidebar>
    
    
    <!-- <div class="app-content content">
        <div class="content-wrapper">
            <div class="content-header row mb-1">
                <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                    <h3 class="content-header-title mb-0 d-inline-block">ALL INVOICES</h3>
                    <div class="row breadcrumbs-top d-inline-block">
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a> </li>
                                <li class="breadcrumb-item active">All Invoices </li>
                            </ol>
                        </div>

                    </div>
                </div>
                <div class="content-header-right col-md-6 col-12">
                    <div *ngIf="clientId" class="btn-group float-md-right">
                        <button class="btn btn-info appointment_btn" type="button" (click)="openModal()" >Create New</button>
                    </div>
                    
                </div>
            </div>
            <div class="content-body">
                <section id="configuration">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Invoices 
                                        <ng-container *ngIf="clientName !== null">
                                            {{'- '+clientName}} 
                                        </ng-container>                                
                                    </h4> 
                                </div>
                                <div class="card-content collapse show">
                                    <div *ngIf="isLoaded" class="card-body1 card-dashboard">
                                        <table  datatable [dtOptions]="dtOptions" class="table table-striped table-bordered zero-configuration t_dt" style="margin-top: 20px !important;">
                                            <thead>
                                                <tr>
                                                    <th *ngIf="!clientId">Client Name</th>
                                                    <th>Invoice No</th>
                                                    <th>Amount ($)</th>
                                                    <th>Gst ($)</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
    
                                                <tr *ngFor="let invoice of invoiceList; let i = index" >
                                                    <td *ngIf="!clientId"> 
                                                        <ng-container *ngIf="!clientId">
                                                            <ng-container *ngIf="invoice.user != null">
                                                                <ng-container *ngIf="invoice.user.client != null">
                                                                    {{invoice.user.client.firstName}} {{invoice.user.client.lastName}}
                                                                </ng-container>
                                                                <ng-container *ngIf="invoice.user.client == null">
                                                                    {{invoice.user.userName}}
                                                                </ng-container>
                                                            </ng-container>
        
                                                            <ng-container *ngIf="clientName != null">
                                                                    {{clientName}}    
                                                            </ng-container>
        
                                                        </ng-container>
                                                    </td>
                                                    <td>
                                                        <ng-container *ngIf="invoice.invoiceNumber">
                                                            {{invoice.invoiceNumber}}
                                                        </ng-container>
                                                    </td>
                                                    <td>
                                                        <ng-container *ngIf="invoice.amount">
                                                            {{invoice.amount/100}}
                                                        </ng-container>
                                                        <ng-container *ngIf="invoice.amount == null || invoice.amount == ''">
                                                            -
                                                        </ng-container>
                                                    </td>
                                                    <td>
                                                        <ng-container *ngIf="invoice.gst">
                                                            {{invoice.gst/100}}
                                                        </ng-container>
                                                        <ng-container *ngIf="invoice.gst == null || invoice.gst == ''">
                                                            -
                                                        </ng-container>
                                                    </td>
                                                    <td>
                                                        <ng-container *ngIf="invoice.id">
                                                            <div class="btn-group float-right">
                                                                <button (click)="openEditModal(invoice)" type="button" class="btn btn-icon btn-success mr-1  c_btntable" data-toggle="modal" data-target="#edit1"><i class="la la-pencil c_icon_s"></i></button>
                                                                
                                                            </div>
                                                        </ng-container>
                                                    </td>
                                                </tr>
    
                                            </tbody>
                                            
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>  -->

    <div class="app-content content h-100">
        <div class="row h-100 justify-content-center align-items-center">
         <img src="../../assets/img/coming_soon.png" class="cs_img">
         <h2 class="cs_1">This feature</h2>
         <h2 class="cs_2">will be here soon.</h2>
        </div>
      </div>






    <app-footer ></app-footer>
</ng-container> 

<!-- <ng-container *ngIf="clientIdfromProfile != null">
    <div class="content-body">
        <section id="configuration">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            
                        </div>
                        <div class="card-content collapse show">
                            <div *ngIf="isLoaded" class="card-body1 card-dashboard">
                                <table  datatable [dtOptions]="dtOptions" class="table table-striped table-bordered zero-configuration t_dt" style="margin-top: 20px !important;">
                                    <thead>
                                        <tr>
                                            <th *ngIf="!clientId">Client Name</th>
                                            <th>Invoice No</th>
                                            <th>Amount ($)</th>
                                            <th>Gst ($)</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr *ngFor="let invoice of invoiceList; let i = index" >
                                            <td *ngIf="!clientId">   
                                                <ng-container *ngIf="!clientId">
                                                    <ng-container *ngIf="invoice.user != null">
                                                        <ng-container *ngIf="invoice.user.client != null">
                                                            {{invoice.user.client.firstName | limitTo : 20}} {{invoice.user.client.lastName | limitTo : 20}}
                                                        </ng-container>
                                                        <ng-container *ngIf="invoice.user.client == null">
                                                            {{invoice.user.userName | limitTo : 20}}
                                                        </ng-container>
                                                    </ng-container>
    
                                                    <ng-container *ngIf="clientName != null">
                                                            {{clientName}}    
                                                    </ng-container>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="invoice.invoiceNumber">
                                                    {{invoice.invoiceNumber}}
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="invoice.amount">
                                                    {{invoice.amount/100}}
                                                </ng-container>
                                                <ng-container *ngIf="invoice.amount == null || invoice.amount == ''">
                                                    -
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="invoice.gst">
                                                    {{invoice.gst/100}}
                                                </ng-container>
                                                <ng-container *ngIf="invoice.gst == null || invoice.gst == ''">
                                                    -
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="invoice.id"> 
                                                    <div class="btn-group float-right">
                                                        <button (click)="openEditModal(invoice)" type="button" class="btn btn-icon btn-success mr-1  c_btntable" data-toggle="modal" data-target="#edit1"><i class="la la-pencil c_icon_s"></i></button>
                                                    </div>
                                                </ng-container>
                                            </td>
                                        </tr>

                                    </tbody>
                                    
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</ng-container> -->

<!-- <ng-template #template>
    <div class="modal-body text-center">
      <p>{{message}}</p>
      <button type="button" class="btn btn-default" (click)="confirm(agent,status)" >Yes</button>
      <button type="button" class="btn btn-primary" (click)="decline(agent,status)" >No</button>
    </div>
  </ng-template>

   -->

