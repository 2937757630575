<div class="modal-header a_modal px-1" tabindex="1">
    <!-- <h4 class="modal-title" id="myModalLabel1">Audience : {{newsFeedByIdModel.newsFeed.audience.name}} </h4> -->
    <h4 class="modal-title" id="myModalLabel1">{{newsFeedByIdModel.newsFeed.title}} </h4>
    <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span
            aria-hidden="true">&times;</span> </button>
</div>

<div class="modal-body px-1">
    <ol>
        <ng-container *ngFor="let recipient of newsFeedByIdModel.newsAudianceRecipients; let i = index">
            <li class="mb-2">
                <div class="d-flex justify-content-between">
                    <p class="mb-0">{{recipient.firstName}} {{recipient.lastName}}</p>
                    <ng-container *ngIf="recipient.isRead">
                        <span class="content-wr read-wr d-flex justify-content-center">
                            Read
                        </span>
                    </ng-container>
                    <ng-container *ngIf="!recipient.isRead">
                        <span class="content-wr unread-wr  d-flex justify-content-center">
                            Unread
                        </span>
                    </ng-container>
                    
                </div>
            </li>
        </ng-container>
        <ng-container *ngIf="newsFeedByIdModel.newsAudianceRecipients.length == 0">
            
                <div class="d-flex justify-content-between">
                    <p class="mb-0">No recipient</p>
                </div>
            
        </ng-container>
        <!-- <li class="mb-2">
            <div class="d-flex justify-content-between">
                <p class="mb-0">Benjamin Humphries</p>
                <span class="content-wr read-wr d-flex justify-content-center">
                    Read
                </span>
            </div>
        </li>
        <li  class="mb-2">
            <div class="d-flex justify-content-between">
                <p class="mb-0">Skye Bloom</p>
                <span class="content-wr unread-wr  d-flex justify-content-center">
                    Unread
                </span>
            </div>
        </li> -->
    </ol>
   
</div>

<ngx-ui-loader></ngx-ui-loader>