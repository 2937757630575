import { Component, OnInit, Input } from '@angular/core';
import { PermissionGroupsService } from '../permission-groups.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.css']
})
export class PermissionListComponent implements OnInit {

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  @Input() permission: any; 
  @Input() selectedPermissions: any;
  @Input() roleId: any;
  saving : boolean = false;
  saved : boolean = false;

  constructor(
    private permissionGroupsService: PermissionGroupsService,
  ) { }

  ngOnInit() {

    let bizList = JSON.parse(localStorage.getItem('businessList'));
    for(let i=0;i<bizList.length;i++ ){
      this.dropdownList.push({"id":bizList[i].id,"itemName":bizList[i].name,"business_code":bizList[i].code});
      //selectedPermissions
    }

    this.selectedPermissions.forEach(element => {
      if(element.id == this.permission.id){
        let selected = bizList.find(d => d.id === element.businessId)
        if(selected && selected.name && selected.code){
          this.selectedItems.push({"id":element.businessId,"itemName":selected.name,"business_code":selected.code});
        }
      }      
    });
    // let selected = this.selectedPermissions.find(d => d.id === this.permission.id);
    // console.log(selected);

    
        // drop
        // this.dropdownList = [
        //   {"id":1,"itemName":"India"},
        //   {"id":2,"itemName":"Singapore"},
        //   {"id":3,"itemName":"Australia"},
        //   {"id":4,"itemName":"Canada"},
        //   {"id":5,"itemName":"South Korea"},
        //   {"id":6,"itemName":"Germany"},
        //   {"id":7,"itemName":"France"},
        //   {"id":8,"itemName":"Russia"},
        //   {"id":9,"itemName":"Italy"},
        //   {"id":10,"itemName":"Sweden"}
        // ];
// this.selectedItems = [
//             {"id":2,"itemName":"Singapore"},
//             {"id":3,"itemName":"Australia"},
//             {"id":4,"itemName":"Canada"},
//             {"id":5,"itemName":"South Korea"}
//         ];

    this.dropdownSettings = { 
      singleSelection: false, 
      text:"Select Businesses",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class"
    };
  }

  onItemSelect(item:any){
    // console.log(item);
    // console.log(this.selectedItems);
    this.changePermission(true,item);
  }
  OnItemDeSelect(item:any){
      // console.log(item);
      // console.log(this.selectedItems);
      this.changePermission(false,item);   
  }
  onSelectAll(items: any){
      console.log(items);
  }
  onDeSelectAll(items: any){
      console.log(items);
  }

  changePermission(status,item){
    this.saving = true;
    this.saved = false;
    this.permissionGroupsService.changePermission(this.roleId, this.permission, status,item).subscribe(()=>{     
      this.saving = false;
      this.saved = true;
    },
    (err: HttpErrorResponse)=>{
      this.saving = false;
      this.saved = false;
      //console.log("Error"+ err.message);
    });
  }

}
