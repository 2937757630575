<!-- <app-header></app-header>
<app-sidebar></app-sidebar>
<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                <h3 class="content-header-title mb-0 d-inline-block">SERVICE AGREEMENT</h3>
                <div class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/dashboard">HOME</a></li>
                            <li class="breadcrumb-item">Service Agreement</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <h4 class="card-title">Documents To Action</h4>
                <div class="btn-group float-md-right app_document">
                    <button class="btn btn-sm app_document_btn" (click)="uploadnewServiceAgreement()" type="button">Upload New</button>
                </div>
            </div>
            <div class="card-content">
                <div class="card-body tabcbody">
                    <div class="tab-content">
                        <div class="card">
                            <div class="card-content collapse show">
                            <div *ngIf="isLoaded" class="card-body1 card-dashboard agreement-card">
                                <table class="table table-striped table-bordered zero-configuration t_dt dataTable no-footer"
                                            datatable [dtOptions]="dtOptions">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="fixed-side">Name</th>
                                            <th scope="col" class="">Signature Required Before</th>
                                            <th scope="col" class="">Status</th>
                                            <th scope="col" class="text-right"></th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="serviceAgreementList.length>0">
                                        <tr *ngFor="let serviceAgreement of serviceAgreementList; let i = index">
                                            <td>{{serviceAgreement.title}}</td>
                                            <td>{{serviceAgreement.expireDate+'Z' | date:'dd/MM/yyyy'}}</td>
        
                                            <ng-container *ngIf="!isExpirySign(serviceAgreement.expireDate+'Z')">
                                                <ng-container *ngIf="serviceAgreement.fdsAgreementStatus=='1'">
                                                    <td>Draft</td>
                                                </ng-container>
                                                <ng-container *ngIf="serviceAgreement.fdsAgreementStatus=='2'">
                                                    <td>Draft</td>
                                                </ng-container>
                                                <ng-container *ngIf="serviceAgreement.fdsAgreementStatus=='3'">
                                                    <td class="send-to-clients">Send To Clients</td>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="(serviceAgreement.fdsAgreementStatus=='4' )">
                                                    <td class="waiting-for-client">Waiting For Clients</td>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="(serviceAgreement.fdsAgreementStatus=='5')">
                                                    <td class="waiting-for-other">Waiting For Others</td>
                                                </ng-container>
                                                <ng-container
                                                *ngIf="(serviceAgreement.fdsAgreementStatus=='9')">
                                                <td class="scheduled">Scheduled</td>
                                            </ng-container>
                                               
                                            </ng-container>                          
                                            <ng-container
                                                *ngIf="(serviceAgreement.fdsAgreementStatus=='6' )">
                                                <td class="signed">Signed</td>
                                            </ng-container>
                                            <ng-container
                                            *ngIf="(serviceAgreement.fdsAgreementStatus=='8' )">
                                            <td class="signed">Correcting</td>
                                        </ng-container>
                                            <ng-container
                                                *ngIf="(serviceAgreement.fdsAgreementStatus=='7')">
                                                <td class="waiting-for-client">Voided</td>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="isExpirySign(serviceAgreement.expireDate+'Z') && !(serviceAgreement.fdsAgreementStatus=='6' || serviceAgreement.fdsAgreementStatus=='7' )">
                                                <td class="expired">Expired</td>
                                            </ng-container>
        
        
                                            <td class="text-right">
                                                <img class="arrow-icon float-right cursor-pointer" src="assets/img/menu.svg"
                                                    id="serviceAgreement" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false" />
        
                                                <div class="dropdown-menu shadow-lg" aria-labelledby="serviceAgreement">
                                                    <ng-container *ngIf="serviceAgreement.fdsAgreementStatus=='1'">
                                                        <ng-container *ngIf="!isExpirySign(serviceAgreement.expireDate+'Z')">
                                                            <a class="dropdown-item text-center" target="_blank" (click)="createEnvelop(serviceAgreement)">Send
                                                                to clients</a>
                                                        </ng-container>
                                                        <a class="dropdown-item text-center" target="_blank" (click)="editServiceAgreement(serviceAgreement)">Edit</a>
                                                        <a class="dropdown-item expired text-center"
                                                            (click)="openDeleteDialog(template,serviceAgreement.id, serviceAgreement.fdsAgreementStatus)"
                                                            target="_blank" style="color: red;">Delete</a> 
                                                    </ng-container>
                                                    <ng-container *ngIf="serviceAgreement.fdsAgreementStatus=='4' ||  serviceAgreement.fdsAgreementStatus=='2'">
                                                        <a class="dropdown-item text-center" target="_blank" (click)="editServiceAgreement(serviceAgreement)">Edit</a>
                                                    </ng-container>
                                                    <ng-container *ngIf="serviceAgreement.fdsAgreementStatus=='8'">
                                                        <a class="dropdown-item text-center" target="_blank" (click)="GetEnvelopeCorrectViewByFdsId(serviceAgreement)">Continue</a>
                                                    </ng-container>
                                                    <ng-container *ngIf="serviceAgreement.fdsAgreementStatus=='2'">
                                                            <ng-container *ngIf="!isExpirySign(serviceAgreement.expireDate+'Z')">
                                                                <a class="dropdown-item text-center" target="_blank"
                                                                    (click)="sendToClients(serviceAgreement)">Send
                                                                    to clients</a>
                                                            </ng-container>       
                                                            <!-- <a class="dropdown-item text-center" target="_blank" (click)="editServiceAgreement(serviceAgreement)">Edit</a> -->
                                                            <a class="dropdown-item expired text-center"
                                                            (click)="openDeleteDialog(template,serviceAgreement.id, serviceAgreement.fdsAgreementStatus)"
                                                            target="_blank" style="color: red;">Delete</a> 
                                                    </ng-container>
        
                                                    <ng-container *ngIf="serviceAgreement.fdsAgreementStatus=='3' || serviceAgreement.fdsAgreementStatus=='4'">
                                                            <a class="dropdown-item expired text-center"
                                                            (click)="openDeleteDialog(template,serviceAgreement.id, serviceAgreement.fdsAgreementStatus)"
                                                            target="_blank" style="color: red;">Delete</a> 
                                                    </ng-container>

                                                    <ng-container *ngIf="serviceAgreement.fdsAgreementStatus=='9'">
                                                        <a class="dropdown-item text-center" target="_blank" (click)="editServiceAgreement(serviceAgreement)">Edit</a>
                                                        <a class="dropdown-item expired text-center"
                                                            (click)="openDeleteDialog(template,serviceAgreement.id, serviceAgreement.fdsAgreementStatus)"
                                                            target="_blank" style="color: red;">Delete</a> 
                                                    </ng-container>
        
                                                    <a class="dropdown-item text-center " target="_blank"
                                                        (click)="viewServiceAgreement(serviceAgreement)">Preview</a>
                                                    <ng-container *ngIf="serviceAgreement.fdsServiceDocuments.length > 0 && serviceAgreement.fdsAgreementStatus != '1' && serviceAgreement.fdsAgreementStatus != '2'">
                                                        <a class="dropdown-item text-center" target="_blank"
                                                            (click)="viewDocument(serviceAgreement, serviceAgreement.envelopeID)">Download
                                                            PDF</a>
                                                    </ng-container>
                                                </div>
                                            </td>
                                        </tr>
                                        <!-- <tr>
                                <td>FDS 2021 - 2022</td>
                                <td>01/06/2021</td>
                                <td class="signed">Signed</td>
                                <td class="text-right">
                                    <img class="arrow-icon float-right cursor-pointer"
                                        src="assets/img/menu.svg" id="serviceAgreement"
                                        data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false" />
        
                                    <div class="dropdown-menu shadow-lg"
                                        aria-labelledby="serviceAgreement">
                                        <a class="dropdown-item" target="_blank">Download PDF</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>FDS 2021 - 2022</td>
                                <td>01/06/2021</td>
                                <td class="waiting-for-client">Waiting For Client</td>
                                <td class="text-right">
                                    <img class="arrow-icon float-right cursor-pointer"
                                        src="assets/img/menu.svg" id="serviceAgreement"
                                        data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false" />
        
                                    <div class="dropdown-menu shadow-lg"
                                        aria-labelledby="serviceAgreement">
                                        <a class="dropdown-item" target="_blank">Download PDF</a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>FDS 2021 - 2022</td>
                                <td>01/06/2021</td>
                                <td class="waiting-for-other">Waiting For Others</td>
                                <td class="text-right">
                                    <img class="arrow-icon float-right cursor-pointer"
                                        src="assets/img/menu.svg" id="serviceAgreement"
                                        data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false" />
        
                                    <div class="dropdown-menu shadow-lg"
                                        aria-labelledby="serviceAgreement">
                                        <a class="dropdown-item" target="_blank">Download PDF</a>
                                    </div>
                                </td>
                            </tr> -->
                                    </tbody>
                                    <tbody *ngIf="serviceAgreementList.length == 0"
                                        style=" width: 100% !important; text-align: center;">
                                        <tr>
                                            <td colspan="3">No records to show.</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
        
                            </div>
                        </div>
                    </div>
        
                    <ng-template #template>
                        <div class="modal-body text-center">
        
                            <img src="assets/img/warning.png" alt="" class="alert_img">
                            <p>{{message}}</p>
        
                            <div class="form-actions center mb-1">
                                <button type="button" class="btn alert_no_b mr-1" (click)="decline()">
                                    No
                                </button>
                                <button type="button" class="btn alert_yes_b" (click)="confirm()">
                                    Yes
                                </button>
                            </div>
                        </div>
                    </ng-template>
        
                    <ng-template #templateSendToClients>
                        <div class="modal-body text-center">
        
                            <img src="assets/img/warning.png" alt="" class="alert_img">
                            <p>{{message}}</p>
        
                            <div class="form-actions center mb-1">
                                <button type="button" class="btn alert_no_b mr-1" (click)="decline()">
                                    No
                                </button>
                                <button type="button" class="btn alert_yes_b" (click)="confirmSendToClients()">
                                    Yes
                                </button>
                            </div>
                        </div>
                    </ng-template>
                </div>
                </div>
            </div>
        </div>
            <!-- </div>
        </div> -->
        <ng-container *ngIf="loaded">
            <ngx-ui-loader></ngx-ui-loader>
        </ng-container>
        