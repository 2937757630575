<!-- post preview -->
<div class="container" [ngStyle]="{'width': mobileW + 'px' , 'height' : mobileH + 'px'}" [ngClass]="{'show-block': post, 'hide-block': !post}">
    <div class="d-flex justify-content-center">
        <div class="mobile-header" [ngStyle]="{'width': mobileW - 200 + 'px'}"></div>
    </div>
    <div class="inner mt-3">
        <!-- Title -->
        <div class="title-heading p-1" id="news-title">
            <div class="title-overflow">
                {{title? title : 'Title'}}
            </div>
        </div>
        <!-- Cover image -->
        <ng-container *ngIf="imgUrl">
            <div class="m-1">
                <img [ngStyle]="{'height': ((mobileH - 118) * 0.3) + 'px'}" src="{{imgUrl}}" alt="" />
            </div>
        </ng-container>
        <ng-container *ngIf="!imgUrl">
            <div class="cover-img m-1 d-flex align-items-center justify-content-center gray-text" [ngStyle]="{'height': ((mobileH - 118) * 0.3) + 'px'}" [class.gray-bg]="!imgUrl">
                Cover Image
            </div>
        </ng-container>
        <!-- Description -->
        <div class="scroll-content pl-1 pr-1 pb-2"
            [ngStyle]="{'height' : documentType != 1 ? (mobileH - 166 - ((mobileH - 139) * 0.3)  + 'px') : ((mobileH - (159 + ((mobileH - 139) * 0.3))) + 'px')}">
            <div [ngClass]="message ? 'text-left' : 'gray-text'"
                [innerHTML]="message ? message: 'Description here' | safeHtml"></div>
        </div>
        <!-- Attachment -->
        <div *ngIf="documentType != 1" class="footer-attachement p-1 text-left">
            <span class="type-img" [ngClass]="'img-'+ uploadType"></span>
            <div class="pl-2">{{mobileLink}}</div>
        </div>
    </div>
</div>


<!-- notification preview -->
<div class="container container-push mb-1" [ngClass]="{'show-block': !post, 'hide-block': post}"
    [ngStyle]="{'width': mobileW + 'px' , 'height' : this.halfMH + 'px'}">

    <div class="d-flex justify-content-center">
        <div class="mobile-header" [ngStyle]="{'width': mobileW - 200 + 'px'}"></div>
    </div>
    <div class="inner inner-push mt-3 mb-0 p-1 text-center">
        <div class="device-title">{{deviceTitle}}</div>

        <div *ngIf="!silentPush" class="content-push mt-1">
            <div class="push-header">
                <div class="push-logo"></div>
                <div class="logo-content pl-1">MyFortress</div>
                <div *ngIf="title && type === 'A'" class="show-hide-text" (click)="showNotify()">
                    <i [ngClass]="showFull ? 'fa fa-angle-up' : 'fa fa-angle-down'"></i>
                </div>
            </div>
            <div class="push-content text-left">
                <div *ngIf="type === 'A'" class="logo-content">News Feed</div>
                <div [class.push-content-overflow]="!showFull">Your news feed updated about {{title?
                    title : 'Title'}},
                    Check now?</div>
            </div>
        </div>
        <div *ngIf="silentPush" class="mt-1">
            <div class="p-1 no-push">
                No push notifications will be sent
            </div>
        </div>
    </div>
</div>