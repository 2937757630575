<div class="grid-container">
  <div class="grid-bottom-right">
      <a href="https://twitter.com/davidpine7" target="_blank"><i class="fab fa-twitter"></i> @davidpine7</a>
  </div>
  <div class="grid-left">
      <app-rooms #rooms (roomChanged)="onRoomChanged($event)"
                 [activeRoomName]="!!activeRoom ? activeRoom.name : null"></app-rooms>
  </div>
  <div class="grid-content">
      <app-camera #camera [style.display]="!!activeRoom ? 'none' : 'block'"></app-camera>
      <app-participants #participants
                        (leaveRoom)="onLeaveRoom($event)"
                        (participantsChanged)="onParticipantsChanged($event)"
                        [style.display]="!!activeRoom ? 'block' : 'none'"
                        [activeRoomName]="!!activeRoom ? activeRoom.name : null"></app-participants>
  </div>
  <div class="grid-right">
      <app-settings #settings (settingsChanged)="onSettingsChanged($event)"></app-settings>
  </div>
  <div class="grid-top-left">
      <a href="https://www.twilio.com/video" target="_blank">
          Powered by Twilio
      </a>
  </div>
</div>