
    <div class="modal-header border-bottom-0">
        <h5 class="modal-title factfinderheader" id="exampleModalLabel">{{ title }} Estate Details</h5>
        <button type="button" class="close" (click)="modalRehide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
  
      <form [formGroup]="addEstatePlanningForm" novalidate class="form " (ngSubmit)="onSubmit()" >
      <div class="modal-body factfinderbody">
        
          <!-- <div class="form-group row factfinderpopup">
            <label for="willExists" class="labelwidth">Will Exists</label>                
            <div class="input-group">
                <div class="d-inline-block custom-control custom-radio mr-1">
                    <input type="radio" class="custom-control-input invoice_radio_input" [value]="true" formControlName="willExists" [(ngModel)]="estatePlanningModel.willExists" >
                    <label class="custom-control-label invoice_radio" for="status1">Yes</label>
                </div>    
                <div class="d-inline-block custom-control custom-radio mr-1">
                    <input type="radio" class="custom-control-input invoice_radio_input" [value]="false" formControlName="willExists" [(ngModel)]="estatePlanningModel.willExists">
                    <label class="custom-control-label invoice_radio" for="status2">No</label>
                </div>            
            </div>
            <div class="form-group">
                <div *ngIf="f.submitted && f.willExists.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.willExists.errors.required">Will status is required.</div>
                </div>
              </div>     
          </div> -->

          <!-- <div class="form-group row factfinderpopup">
            <label for="willExists" class="labelwidth">Do you have Will?</label>
            
            <div class="custom-control custom-radio custom-control-inline radioinputss" style="margin-left: 17px;">
              <input type="radio" id="status1" [value]="true" formControlName="willExists" [(ngModel)]="estatePlanningModel.willExists" class="custom-control-input radioinputss-input">
              <label class="custom-control-label radiolabels" for="status1">Yes</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline radioinputss">
              <input type="radio" id="status2" [value]="false" formControlName="willExists" [(ngModel)]="estatePlanningModel.willExists" class="custom-control-input radioinputss-input">
              <label class="custom-control-label radiolabels" for="status2">No</label>
            </div>

            <div class="form-group">
              <div *ngIf="f.submitted && f.willExists.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.willExists.errors.required">Will status is required.</div>
              </div>
            </div> 
    
          </div> -->


          <div class="form-group row factfinderpopup">
            <label for="willExists" class="labelwidth">Do you have Will?</label>
            <div class="inputwidth"> <span>:</span> 

              <div class="input-group prepend_group">
                <select formControlName="willExists" [(ngModel)]="estatePlanningModel.willExists" class="form-control form-control-sm" placeholder="Will Exists" >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>

              </div>

              <div class="form-group">
                <div *ngIf="f.submitted && f.willExists.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.willExists.errors.required">Will status is required.</div>
                </div>
              </div> 
              
            </div>
          </div>





          <div class="form-group row factfinderpopup">
              <label for="willDate" class="labelwidth">Date of Will</label>
              <div class="inputwidth"> <span>:</span> 
                <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->

                <div class="input-group prepend_group">
                  <span class="input-group-prepend" style="top: 0px;     border-color: #32a3ca;">
                      <div class="input-group-text border-right-0 prepend_new_class new_clock">
                      <span class="prepend_span_goals" > 
                    
                              <i class="ficon ft-clock" style="    position: absolute; margin-top: -11px;"></i>
      
                      </span></div>
                  </span>
                  
                  <input type="text" formControlName="willDate" [(ngModel)]="estatePlanningModel.willDate" class="form-control form-control-sm"  
                  
                  placement="top"
                  bsDatepicker                                      
                  [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"  placeholder="Date of Will" readonly style="width: unset; background-color: #fff;">
                </div>
                <div class="form-group">
                  <div *ngIf="submitted && f.willDate.errors"   class="form_alert">
                          <span  class="ft-alert-circle"></span>
                      <div *ngIf="f.willDate.errors.required">Date of will is required.</div>
                  </div>
                </div>  
              </div>
            </div>
                
        <div class="form-group row factfinderpopup">
          <label for="willLocation" class="labelwidth">Location</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            <input formControlName="willLocation" required [(ngModel)]="estatePlanningModel.willLocation" type="text" class="form-control form-control-sm" placeholder="Location">
            <div class="form-group">
              <div *ngIf="submitted && f.willLocation.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.willLocation.errors.required">Location is required.</div>
              </div>
            </div>  
          </div>
        </div>
        
      </div>
      <div class="modal-footer border-top-0 pt-0">
        <button class="btn btn-primary factfinderbtn">Save</button>
      </div>
  </form>
      