
    <div class="modal-header border-bottom-0">
      <h5 class="modal-title factfinderheader" id="exampleModalLabel">{{title}} Insurance Details</h5>
      <button type="button" class="close" (click)="modalRehide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <form [formGroup]="addInsuranceForm" novalidate class="form " (ngSubmit)="onSubmit()" >
    <div class="modal-body factfinderbody">
      
      <div class="form-group row factfinderpopup">
        <label for="policyNumber" class="labelwidth">Policy Number</label>
        <div class="inputwidth"> <span>:</span> 
          <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
          <input formControlName="policyNumber" required [(ngModel)]="insuranceAddModel.policyNumber" type="text" class="form-control form-control-sm" placeholder="Policy Number">
          <div class="form-group">
            <div *ngIf="submitted && f.policyNumber.errors"   class="form_alert">
                    <span  class="ft-alert-circle"></span>
                <div *ngIf="f.policyNumber.errors.required">Policy number is required.</div>
            </div>
          </div>  
        </div>
      </div>

      <div class="form-group row factfinderpopup">
        <label for="planName" class="labelwidth">Plan Name</label>
        <div class="inputwidth"> <span>:</span>
          <!-- <input type="text" class="form-control form-control-sm" placeholder="Mobile Phone"> -->
          <input formControlName="planName" required [(ngModel)]="insuranceAddModel.planName" type="text" class="form-control form-control-sm" placeholder="Plan Name">
              <div class="form-group">
                <div *ngIf="submitted && f.planName.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.planName.errors.required">Plan name is required.</div>
              </div>
              </div>
        </div>
      </div>
      
      <div class="form-group row factfinderpopup">
        <label for="totalPremium" class="labelwidth">Total Premium</label>
        <div class="inputwidth"> <span>:</span> 
          <div class="input-group prepend_group">
              <span class="input-group-prepend" style="top: 0px;">
                  <div class="input-group-text border-right-0 prepend_new_class"><span class="prepend_span"> $ </span></div>
              </span>
              <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
              <input formControlName="totalPremium" required [(ngModel)]="insuranceAddModel.totalPremium" type="number" pattern="^[1-9]\d*$" class="form-control form-control-sm" style="width: unset;" placeholder="Total Premium">
          </div>
          <div class="form-group">
            <div *ngIf="submitted && f.totalPremium.errors"   class="form_alert aud_errormsg">
                    <span  class="ft-alert-circle"></span>
                <div *ngIf="f.totalPremium.errors.required">Total premium is required.</div>
                <div *ngIf="f.totalPremium.errors.pattern">Only numbers.</div>
            </div>
          </div>

        </div>
      </div>

      <div class="form-group row factfinderpopup">
        <label for="policyOwners" class="labelwidth">Policy Owners</label>
        <div class="inputwidth"> <span>:</span> 
          <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
          <!-- <input formControlName="policyOwners" required [(ngModel)]="insuranceAddModel.policyOwners" type="text" class="form-control form-control-sm" placeholder="Policy Owners"> -->
          
          <select formControlName="policyOwners" required [(ngModel)]="insuranceAddModel.policyOwners" class="form-control form-control-sm" placeholder="Policy Owners" >
              <option value="000">Please select</option>
              
              <ng-container *ngFor="let assetOwnerType of assetOwnerTypeList; let i = index">
                  <option value="{{assetOwnerType.key}}">{{assetOwnerType.value}}</option>
              </ng-container>
              
            </select>

          <div class="form-group">
            <div *ngIf="submitted && f.policyOwners.errors"   class="form_alert">
                    <span  class="ft-alert-circle"></span>
                <div *ngIf="f.policyOwners.errors.required">Policy owners is required.</div>
            </div>
          </div>  
        </div>
      </div>     
      
    </div>
    <div class="modal-footer border-top-0 pt-0">
      <button class="btn btn-primary factfinderbtn">Save</button>
    </div>
</form>
    