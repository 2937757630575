import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FacFinderService } from '../fac-finder.service';
import { XFacContactAddModel } from 'src/app/shared/models/xfac-contact.model';
import { XFacContactResponse, XFacAddressResponse, XFacEmploymentResponse, XFacEstatePlanningResponse } from 'src/app/shared/models/response.model';
import { XFacAddressAddModel } from 'src/app/shared/models/xfac-address.model';
import { XFacEmploymentAddModel } from 'src/app/shared/models/xfac-employment.model';
import { XFacEstatePlanningAddModel } from 'src/app/shared/models/xfac-estatePlanning.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-estate-details',
  templateUrl: './add-estate-details.component.html',
  styleUrls: ['./add-estate-details.component.css']
})
export class AddEstateDetailsComponent implements OnInit {

  title;
  data;
  errors;
  addEstatePlanningForm: FormGroup;
  estatePlanningModel : XFacEstatePlanningAddModel;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  buttonText: string ="Add New Contact Details";
  employmentStatusList : any = [];
  facFinderId : any;
  clientCode : any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private facFinderService: FacFinderService,
    public modalRef: BsModalRef,
    private toastr: ToastrService
    ) { }
 
  ngOnInit() {
    this.Isvalid = true;    
    this.estatePlanningModel = new XFacEstatePlanningAddModel(); 
    //this.employmentAddModel.status ="000"; 

    if(this.data.estate !=null){
      //console.log(this.data.library);
      this.buttonText = "Update Now";
      Object.assign(this.estatePlanningModel, this.data.estate);
      this.estatePlanningModel.willDate = new Date(this.estatePlanningModel.willDate+'Z');

    }

    // if(this.data.employment_status_list !=null){
    //   Object.assign(this.employmentStatusList, this.data.employment_status_list);
    // }

    if(this.data.facFinderId !=null){
      this.facFinderId = this.data.facFinderId;
    }

    if(this.data.clientCode !=null){
      this.clientCode = this.data.clientCode;
    }

    this.title = this.data.title;
    
    // console.log(this.clientCode);
    // if(this.data.business_id !=null){
    //   this.business_id = this.data.business_id;
    // }

    this.addEstatePlanningForm = this.formBuilder.group({
      willExists: ['', Validators.required],
      willDate: ['', Validators.required],
      willLocation: ['', Validators.required],
    });

  }

  get f() { return this.addEstatePlanningForm.controls; }

onSubmit(){
 this.submitted = true;

 //let status = this.employmentAddModel.status;

 if (this.addEstatePlanningForm.invalid ) { //|| (status && status.trim()=="000")

  // if(status && status.trim()=="000"){
  //   this.addEstatePlanningForm.controls['status'].setErrors({'required': true});
  // }
  console.log(this.f);
  console.log(this.addEstatePlanningForm.invalid);
  return;
}

else{
  //this.folderModel.name = title.trim();
  // let pipe = new DatePipe('en-US'); // Use your own locale
  // this.estatePlanningModel.willDate = pipe.transform(this.estatePlanningModel.willDate, 'yyyy-MM-dd');

  

  // console.log('estatePlanningModel');
  // console.log(this.estatePlanningModel.willDate);

if(this.data.estate !=null){
//   this.ngxService.start();
// //console.log("Edditre");

  this.ngxService.start();
  this.estatePlanningModel.clientCode = this.clientCode;
  this.facFinderService.editEstatePlanning(this.estatePlanningModel, this.facFinderId)
  .subscribe((data:XFacEstatePlanningResponse)=>{
  this.ngxService.stop();
  if(data.error && !data.success){      
    this.toastr.error('Error on updating estate details.');      
  }
  else{
    this.toastr.success('Estate details updated successfully.');
    this.event.emit('OK');
   // console.log(this.event);
    this.modalRef.hide();
  }
  
  },err=>{   
    console.log(err);
    this.ngxService.stop();
    this.toastr.error('Error on updating estate details.');
  });
    
    //this.router.navigate(['agents']);
    

}else{
  
  this.ngxService.start();
  this.estatePlanningModel.clientCode = this.clientCode;
  this.facFinderService.addEstatePlanning(this.estatePlanningModel, this.facFinderId)
  .subscribe((data:XFacEstatePlanningResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){      
      this.toastr.error('Error on saving estate details.');      
    }
    else{
      this.toastr.success('Estate details added successfully.');
      this.event.emit('OK');
     // console.log(this.event);
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on saving estate details.');
    });

  }

}

}

modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

onReset() {
  this.submitted = false;
  this.addEstatePlanningForm.reset();
}

}
