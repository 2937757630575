
    <div class="modal-header border-bottom-0">
        <h5 class="modal-title factfinderheader" id="exampleModalLabel">{{ title }} Employment Details</h5>
        <button type="button" class="close" (click)="modalRehide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
  
      <form [formGroup]="addEmploymentsForm" novalidate class="form " (ngSubmit)="onSubmit()" >
      <div class="modal-body factfinderbody">
        
        <div class="form-group row factfinderpopup">
          <label for="occupation" class="labelwidth">Occupation</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            <!-- <input formControlName="occupation" required [(ngModel)]="employmentAddModel.occupation" type="text" class="form-control form-control-sm" placeholder="Occupation"> -->
            <select formControlName="occupation" required [(ngModel)]="employmentAddModel.occupation" class="form-control form-control-sm" placeholder="Occupation" >
              <option value="000">Please select</option>
              
              <ng-container *ngFor="let employmentOccupationOne of employmentOccupationList; let i = index">
                  <option value="{{employmentOccupationOne.key}}">{{employmentOccupationOne.value}}</option>
              </ng-container>
              
            </select>
            <div class="form-group">
              <div *ngIf="submitted && f.occupation.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.occupation.errors.required">Occupation is required.</div>
              </div>
            </div>  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
          <label for="employer" class="labelwidth">Employer</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            <input formControlName="employer" required [(ngModel)]="employmentAddModel.employer" type="text" class="form-control form-control-sm" placeholder="Employer">
            <div class="form-group">
              <div *ngIf="submitted && f.employer.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.employer.errors.required">Employer is required.</div>
              </div>
            </div>  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
          <label for="status" class="labelwidth">Status</label>
          <div class="inputwidth"> <span>:</span>
            <!-- <input type="text" class="form-control form-control-sm" placeholder="Mobile Phone"> -->
            <select formControlName="status" required [(ngModel)]="employmentAddModel.status" class="form-control form-control-sm" placeholder="Status" >
                <option value="000">Please select</option>
                
                <ng-container *ngFor="let employmentStatus of employmentStatusList; let i = index">
                    <option value="{{employmentStatus.key}}">{{employmentStatus.value}}</option>
                </ng-container>
                
              </select>
                <div class="form-group">
                  <div *ngIf="submitted && f.status.errors"   class="form_alert">
                          <span  class="ft-alert-circle"></span>
                      <div *ngIf="f.status.errors.required">Status type is required.</div>
                </div>
                </div>
          </div>
        </div>
        
        <!-- <div class="form-group row factfinderpopup">
          <label for="employerStreet" class="labelwidth">Employer Street</label>
          <div class="inputwidth"> <span>:</span> 
            <input formControlName="employerStreet" required [(ngModel)]="employmentAddModel.employerStreet" type="text" class="form-control form-control-sm" placeholder="Employer Street">
            <div class="form-group">
              <div *ngIf="submitted && f.employerStreet.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.employerStreet.errors.required">Employer street is required.</div>
              </div>
            </div>
  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
          <label for="employerSuburb" class="labelwidth">Employer Suburb</label>
          <div class="inputwidth"> <span>:</span> 
            <input formControlName="employerSuburb" required [(ngModel)]="employmentAddModel.employerSuburb" type="text" class="form-control form-control-sm" placeholder="Employer Suburb">
            <div class="form-group">
              <div *ngIf="submitted && f.employerSuburb.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.employerSuburb.errors.required">Employer suburb is required.</div>
              </div>
            </div>  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
            <label for="employerState" class="labelwidth">Employer State</label>
            <div class="inputwidth"> <span>:</span> 
              <input formControlName="employerState" required [(ngModel)]="employmentAddModel.employerState" type="text" class="form-control form-control-sm" placeholder="Employer State">
              <div class="form-group">
                <div *ngIf="submitted && f.employerState.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.employerState.errors.required">Employer state is required.</div>
                </div>
              </div>  
            </div>
          </div>

        <div class="form-group row factfinderpopup">
            <label for="employerPostcode" class="labelwidth">Employer Postcode</label>
            <div class="inputwidth"> <span>:</span> 
              <input formControlName="employerPostcode" required [(ngModel)]="employmentAddModel.employerPostcode" type="text" class="form-control form-control-sm" placeholder="Employer Postcode">
              <div class="form-group">
                <div *ngIf="submitted && f.employerPostcode.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.employerPostcode.errors.required">Employer postcode is required.</div>
                </div>
              </div>  
            </div>
          </div>

        <div class="form-group row factfinderpopup">
            <label for="employerCountry" class="labelwidth">Employer Country</label>
            <div class="inputwidth"> <span>:</span> 
              <input formControlName="employerCountry" required [(ngModel)]="employmentAddModel.employerCountry" type="text" class="form-control form-control-sm" placeholder="Employer Country">
              <div class="form-group">
                <div *ngIf="submitted && f.employerCountry.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.employerCountry.errors.required">Employer country is required.</div>
                </div>
              </div>  
            </div>
          </div>   -->
        
      </div>
      <div class="modal-footer border-top-0 pt-0">
        <button class="btn btn-primary factfinderbtn">Save</button>
      </div>
  </form>
      