import { Component, OnInit, EventEmitter } from '@angular/core';
import { ClientsService } from 'src/app/clients/clients.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.css']
})
export class UserHistoryComponent implements OnInit {

  data;
  loginHistoryData:any ='';
  isLoaded : boolean = false;
  event: EventEmitter<any> = new EventEmitter();

  constructor(
    private clientsService: ClientsService,
    public modalRef: BsModalRef, 
  ) { }

  ngOnInit(): void {

    if(this.data.clientId !=null){
      this.getLoginHistory(this.data.clientId)
    }

  }

  getLoginHistory(clientId){
    this.clientsService.getLoginHistory(clientId).subscribe((loginHistoryData:any)=>{

      this.loginHistoryData = loginHistoryData.data;
      this.loginHistoryData.sort(this.sortFunction);​
      this.isLoaded = true;

    });
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

  sortFunction(a,b){  
    var dateA = new Date(a.lastActiveDate).getTime();
    var dateB = new Date(b.lastActiveDate).getTime();
    return dateA < dateB ? 1 : -1;  
  }; 


}
