import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConstants } from 'src/app/utility/app-constants';

@Injectable({
  providedIn: 'root'
})
export class FamilyTreeService {

  baseUrl: String;
  
  private family_tree = new BehaviorSubject<any>([]);
  familyTree = this.family_tree.asObservable()
  
  constructor(private http: HttpClient) {
    this.baseUrl = AppConstants.getFamilyTreeApiURL();
  }

  changFamilyTree(currentTree: any) {
    this.family_tree.next(currentTree)
  }

  public getFamilyData(family_tree_id) {
    const params = new HttpParams().set('familyTreeId', family_tree_id);
    return this.http.get(this.baseUrl + '/FamilyDependent/getByFamilyTreeId', { params });
  }

  public getFamilyTreeExist(clientId, clientCode){
    const params = new HttpParams().set('ClientCode',clientCode);
    return this.http.get(this.baseUrl+'/FamilyTree/GetByClientCode',{params});
  }
}
