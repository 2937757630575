import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';

import { AgentViewModel,AgentBaseMOdel, UserAgentViewModel } from '../shared/models/agent.model';
import { AgentResponse, AgentResponse2, CompanyResponse, Company_Response2 } from '../shared/models/response.model';
import { CompaniesService } from './companies.service';
import { HttpErrorResponse } from '@angular/common/http';
// import { ClientResponse } from 'http';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddAgentComponent } from '../agents/add-agent/add-agent.component';
import { ConfirmationDialogService } from '../confirmation/confirmation.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AppConstants } from '../utility/app-constants';
import { CompanyAddModel } from '../shared/models/company.model';
import { EditCompaniesComponent } from './edit-companies/edit-companies.component';
import { AddCompaniesComponent } from './add-companies/add-companies.component';


@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  companyList: CompanyAddModel[];
  isLoaded: boolean;
  modalRef: BsModalRef;

  constructor(private companiesService: CompaniesService, private modalService: BsModalService, private router: Router) { }

  ngOnInit() {
    this.getAgentList(); 
    this.dtOptions = {
      //pagingType: 'full_numbers',
      //ajax: this.baseUrl+'/FortressAdmins',
      pageLength: 10,
      processing: true,
      order : []
    };   
  }

  getAgentList(){
    this.companiesService.companyList().subscribe((data:Company_Response2)=>{     
      this.companyList = data.data;
      //this.agentsCount = this.agentList.length;
      //this.assignCopy();
      //  console.log('Agent list--------------------------');
      //  console.log(data.data);

      this.isLoaded = true;
      
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });      
  }

  openEditModal(company:any) {  
    //console.log(agent);  
    this.modalRef = this.modalService.show(EditCompaniesComponent,  {
      initialState: {
        title: 'Edit Company',
        data: {
          company:company
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        this.router.navigate(['companies']);
        //  this.getAgentList();
      
      }
    });
  }


  openModal() {
    
    this.modalRef = this.modalService.show(AddCompaniesComponent,  {
      initialState: {
        title: 'Add Company',
        data: {},
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      
      if (result == 'OK') {
        //console.log("Success");
        this.router.navigate(['companies']);
         // this.getAgentList();
      
      }

    });
  }

}
