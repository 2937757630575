
     
     <div class="modal-header a_modal">
          <h4 class="modal-title" id="myModalLabel1">Upload Document</h4>
          <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
      </div>

      <form #f="ngForm" novalidate class="form" enctype="multipart/form-data" (ngSubmit)="onSubmit(f)" >
      <div class="modal-body">
          <div class="row">
              <div class="col-md-12">
                 
                      <div class="form-body">
                          <div class="row">
                              

                              <div class="col-md-12">
                                  <div class="form-group">
                                      <label for="projectinput8" class="c_n_fs">Document Name *</label>
                                      <input type="text" id="documentName" maxlength="100" required minlength="4" class="form-control" name="documentName" [(ngModel)]="userDocumentsAddModel.documentName" #documentName = "ngModel" placeholder="Document Name">

                                      <div class="form-group">
                                        <div *ngIf="documentName.invalid && (documentName.dirty || documentName.touched)"
                                        class="form_alert">
                                        <span  class="ft-alert-circle"></span>
                                          <div *ngIf="documentName.errors.required">
                                              Document name is required.
                                          </div> 
                                          <div *ngIf="documentName.errors.minlength">
                                              Document name must be at least 4 characters long.
                                          </div>                                                  
                                        </div>
                                      </div>

                                  </div>
                              </div>

                              <div class="col-md-12">
                                  <div class="form-group">
                                      <label for="documentType" class="c_n_fs">Type *</label>
                                      <select [disabled]="disableDocumentType" required id="documentType" (change)="onDocumentTypeChange($event.target.value)"  [ngModelOptions]="{standalone: true}" [(ngModel)]='userDocumentsAddModel.documentType' #documentType = "ngModel" name="documentType" class="form-control com_drop" data-toggle="tooltip" data-trigger="hover" data-placement="top" data-title="Priority" data-original-title="" title="">
                                          <option *ngFor="let doct of documentType1"  [value]="doct.id">                                      
                                              {{doct.value}}
                                          </option>                                        
                                      </select>

                                      <div class="form-group">
                                        <div *ngIf="documentType.invalid && (documentType.dirty || documentType.touched)"
                                        class="form_alert">
                                        <span  class="ft-alert-circle"></span>
                                          <div *ngIf="documentType.errors.required">
                                            Document type is required.
                                          </div>                                                  
                                        </div>
                                      </div>

                                  </div>
                              </div>
                              <div class="col-md-12">
                                  <fieldset class="form-group">
                                      <label for="projectinput8" class="c_n_fs">Upload Document *</label>
                                      <!-- <div class="">
                                              <input required name="document" [disabled]="uploadDocumentEnable" accept="{{acceptDocType}}" (change)="onFileChange($event)" type="file" [(ngModel)]="document1" #document = "ngModel"  id="inputGroupFile01"> 
                                      </div> -->


                                      <div class="custom-file">
                                        <input required name="document" [disabled]="uploadDocumentEnable" accept="{{acceptDocType}}" (change)="onFileChange($event)" type="file" [(ngModel)]="document1" #document = "ngModel"  id="inputGroupFile01" class="custom-file-input">
                                        <label class="custom-file-label"  for="inputGroupFile01">{{documentLabel}}</label>
                                      </div>      




                                      <div class="form-group" style="margin-bottom: -15px;">
                                        <div *ngIf="document.invalid && (document.dirty || document.touched)"
                                        class="form_alert">
                                        <span  class="ft-alert-circle"></span>
                                          <div *ngIf="document.errors.required">
                                            Document is required.
                                          </div>                                                  
                                        </div>
                                      </div>

                                  </fieldset>
                              </div>
                              <div class="col-md-6">
                                  <div class="form-group mb-1" style="padding-top: 4px;">
                                      <label for="status" class="c_n_fs"></label>
                                      <div class="input-group">
                                          <label class="container"> 
                                            <input id="adminView" name="adminView" type="checkbox" [(ngModel)]="userDocumentsAddModel.adminView" #adminView = "ngModel"  data-md-icheck  class="check_mt"/>Admin only
                                            <span class="checkmark"></span>
                                          </label>   
                                      </div>
                                  </div>
                              </div>
                              
                          </div>
                      </div>
                  
              </div>
              
          </div>
          <!--                                    <hr class="c_hr">-->
      </div>
      
      <div class="modal-footer a-footer">
          <div *ngIf="!Isvalid"   class="form_alert">
                <span  class="ft-alert-circle"></span>
              Please fill in all the required fields correctly.
          </div>
          <!-- <div *ngIf="errors"   class="form_alert">
                <span  class="ft-alert-circle"></span>
              {{errors}}
          </div> -->
          <!-- <button type="button" class="btn popup_delete_b">Delete</button> -->
          <button type="submit" class="btn popup_save_b">Save</button>
      </div>
      </form>  

      <ngx-ui-loader ></ngx-ui-loader>

    
      