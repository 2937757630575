<app-header></app-header>
<app-sidebar></app-sidebar>


<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                <h3 class="content-header-title mb-0 d-inline-block">Permission Groups</h3>
                <div class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/dashboard">Home</a> </li>
                            <li class="breadcrumb-item active">Permission Groups </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12 resc_btn">
                <div class="btn-group float-md-right">
                    <button class="btn appointment_btn btn-sm border_all_radius"  data-toggle="modal" (click)="openModal()" data-target="#new_business">Create New</button>
                </div>
            </div>
        </div>        

        <div class="content-body">
            <div class="row">
                    <div class="col-12 col-md-12 ">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Permission Groups</h4>
                                </div>
                                
                                <div class="card-content">
                                    <div *ngIf="isLoaded" class="table-responsive scroll-tbl_newsfeed-">
                                            <!-- <div *ngIf="isLoaded" class="card-body1 card-dashboard"> -->
                                        <!-- <table id="recent-orders" class="table table-hover table-xl mb-0"> -->
                                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="trigger" class="table table-striped table-bordered zero-configuration t_dt" style="margin-top: 20px !important;">
                                            <thead>
                                                <tr>
                                                    <th class="border-top-0">Name</th>
                                                    <th class="border-top-0">Description</th>
                                                    <th class="border-top-0"></th>
                                                    <th class="border-top-0"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let roleData of roleList; let i = index">
                                                  <td class="text-truncate" *ngIf="roleData" >{{roleData.name}}</td>
                                                  <td class="text-truncate">{{roleData.description}}</td>
                                                  <td class="sa_btns">
                                                    <div class="btn-group float-right">
                                                        <button type="button" (click)="openConfirmationDialog(template,roleData,roleData.isArchived)" [ngClass]="[roleData.isArchived ? 'd_tog_btn_r' : 'd_tog_btn_rr']" class="btn d_tog_btn"><i class="la la-toggle-on c_icon_s"></i></button>
                                                        <!-- <button type="button"  class="btn d_tog_btn"><i class="la la-toggle-on c_icon_s"></i></button> -->
                                                    </div>
                                                  </td>
                                                  <td class="sa_btns">
                                                    <div class="btn-group float-right">
                                                        <button (click)="openEditModal(roleData)" type="button" class="btn btn-icon btn-success mr-1  c_btntable" data-toggle="modal" data-target="#edit1"><i class="la la-pencil c_icon_s"></i></button>
                                                    </div>
                                                  </td>
                                                </tr>
                                  
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>






                
            </div>
            
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-body text-center" >

    <img *ngIf="status==true" src="assets/img/warning.png" alt="" class="alert_img">
    <img *ngIf="status==false" src="assets/img/verified.png" alt="" class="alert_img">
    <p>{{message}}</p>
            
        <div class="form-actions center mb-1">
            <button type="button" class="btn alert_no_b mr-1" (click)="decline(roleData,status)"> 
                No
            </button>
            <button type="button" class="btn alert_yes_b" (click)="confirm(roleData,status)" >
                    Yes
            </button>
        </div>
    </div>
</ng-template>

<app-footer></app-footer>