
    <div class="modal-header border-bottom-0">
        <h5 class="modal-title factfinderheader" id="exampleModalLabel">{{ title }} Dependent Details</h5>
        <button type="button" class="close" (click)="modalRehide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
  
      <form [formGroup]="addDependantForm" novalidate class="form " (ngSubmit)="onSubmit()" >
      <div class="modal-body factfinderbody">
        
        
        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">First Name</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            <input formControlName="firstName" required [(ngModel)]="dependantAddModel.firstName" type="text" class="form-control form-control-sm" placeholder="First Name">
            <div class="form-group">
              <div *ngIf="submitted && f.firstName.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.firstName.errors.required">First name is required.</div>
              </div>
            </div>  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Surname</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            <input formControlName="surname" required [(ngModel)]="dependantAddModel.surname" type="text" class="form-control form-control-sm" placeholder="surname">
            <div class="form-group">
              <div *ngIf="submitted && f.surname.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.surname.errors.required">Surname is required.</div>
              </div>
            </div>
  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
            <label for="inputPassword" class="labelwidth">Gender</label>
            <div class="inputwidth"> <span>:</span>
              <!-- <input type="text" class="form-control form-control-sm" placeholder="Mobile Phone"> -->
              <select formControlName="gender" required [(ngModel)]="dependantAddModel.gender" class="form-control form-control-sm" placeholder="Gender" >
                  <option value="000">Please select</option>
                  
                  <ng-container *ngFor="let dependantGender of dependantGenderList; let i = index">
                      <option value="{{dependantGender.key}}">{{dependantGender.value}}</option>
                  </ng-container>
                  
                </select>
                  <div class="form-group">
                    <div *ngIf="submitted && f.gender.errors"   class="form_alert">
                            <span  class="ft-alert-circle"></span>
                        <div *ngIf="f.gender.errors.required">Gender is required.</div>
                  </div>
                  </div>
            </div>
          </div>

        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Date Of Birth</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> --> 
             <!-- pattern="^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$" -->
          
          <div class="input-group prepend_group">
            <span class="input-group-prepend" style="top: 0px;     border-color: #32a3ca;">
                <div class="input-group-text border-right-0 prepend_new_class new_clock">
                <span class="prepend_span_goals" > 
              
                        <i class="ficon ft-clock" style="    position: absolute; margin-top: -11px;"></i>

                </span></div>
            </span>

            <input type="text" formControlName="dateOfBirth" [(ngModel)]="dependantAddModel.dateOfBirth" class="form-control form-control-sm"  
            
            placement="top"
            bsDatepicker                                      
            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"  placeholder="Date of Birth"  readonly style="width: unset; background-color: #fff;">
          </div>

            <div class="form-group">
              <div *ngIf="submitted && f.dateOfBirth.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.dateOfBirth.errors.required">Date of birth is required.</div>
                  <!-- <div *ngIf="f.dateOfBirth.errors.pattern">Only valid Date.</div> -->
              </div>
            </div>  
          </div>
        </div>

        
      </div>
      <div class="modal-footer border-top-0 pt-0">
        <button class="btn btn-primary factfinderbtn">Save</button>
      </div>
  </form>
      