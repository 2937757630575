<app-header></app-header>
<app-sidebar></app-sidebar>


<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                <h3 class="content-header-title mb-0 d-inline-block">Documents To Action</h3>
                <div class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/dashboard">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Documents To Action
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <!-- <div class="btn-group float-md-right">
            <button class="btn btn-info appointment_btn btn-sm border_all_radius" type="button" (click)="openModal()" >Create New</button>
            
        </div> -->

            <!-- <div class="content-header-right col-md-6 col-12">
                <input class="form-control form-control-lg input-lg search_in nav_search" type="text"
                    placeholder="Search Clients" id="userIdFirstWay" list="dynmicUserIds" />
                <div class="form-control-position nav_i"> <i class="ft-search font-medium-4"
                        style="color: #91929a;"></i> </div>
            </div> -->
        </div>

        <div class="content-body">
            <!-- Zero configuration table -->
            <section id="">
                <div class="row">
                    <div class="col-12">

                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Documents To Action List</h4>
                            </div>
                            <div class="card-content collapse show">
                                <div *ngIf="isLoaded" class="card-body1 card-dashboard">
                                    <table datatable [dtOptions]="dtOptions"
                                        class="t_dt table table-striped table-bordered table-sm row-border hover"
                                        style="margin-top: 20px !important;">
                                        <thead>
                                            <tr>
                                                <th>Created On</th>
                                                <th>Client Name</th>
                                                <!-- <th>Partner Name</th> -->
                                                <th>Xeppo Code</th>
                                                <th>Title</th>
                                                <th>Status</th>
                                                <th>Signature Required Before</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="serviceAgreementList.length>0"> <!-- *ngIf="serviceAgreementList.length>0" -->
                                            <tr *ngFor="let serviceAgreement of serviceAgreementList; let i = index"> <!-- *ngFor="let serviceAgreement of serviceAgreementList; let i = index" -->
                                                <td>{{serviceAgreement.createdDate+'Z' | date:'dd/MM/yyyy h:mm a'}}</td> <!--09/06/2021 04:00PM -->
                                                <td>{{findUser(serviceAgreement)}}</td>
                                                <!-- <td>Jill Demo</td> -->
                                                <td>{{serviceAgreement.xeppoCode}}</td>
                                                <td>{{serviceAgreement.title}}</td>

                                                <ng-container *ngIf="!isExpirySign(serviceAgreement.expireDate+'Z')">
                                                    <ng-container *ngIf="serviceAgreement.fdsAgreementStatus=='1'">
                                                        <td>Draft</td>
                                                    </ng-container>
                                                    <ng-container *ngIf="serviceAgreement.fdsAgreementStatus=='2'">
                                                        <td>Draft</td>
                                                    </ng-container>
                                                    <ng-container *ngIf="serviceAgreement.fdsAgreementStatus=='3'">
                                                        <td class="send-to-clients">Send To Clients</td>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="(serviceAgreement.fdsAgreementStatus=='4' )">
                                                        <td class="waiting-for-client">Waiting For Clients</td>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="(serviceAgreement.fdsAgreementStatus=='5')">
                                                        <td class="waiting-for-other">Waiting For Others</td>
                                                    </ng-container>
                                                    <ng-container
                                                    *ngIf="(serviceAgreement.fdsAgreementStatus=='9')">
                                                    <td class="scheduled">Scheduled</td>
                                                </ng-container>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="(serviceAgreement.fdsAgreementStatus=='6' )">
                                                    <td class="signed">Signed</td>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="(serviceAgreement.fdsAgreementStatus=='7')">
                                                    <td class="waiting-for-client">Voided</td>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="isExpirySign(serviceAgreement.expireDate+'Z') && !(serviceAgreement.fdsAgreementStatus=='6' || serviceAgreement.fdsAgreementStatus=='7')">
                                                    <td class="expired">Expired</td>
                                                </ng-container>
                                                
                                                <td>{{serviceAgreement.expireDate+'Z'  | date:'dd/MM/yyyy'}}</td> <!-- 09/07/2021 -->
                                                <td class="text-right">
                                                    <img class="arrow-icon float-right cursor-pointer"
                                                        src="assets/img/menu.svg" id="serviceAgreement"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false" />

                                                    <div class="dropdown-menu shadow-lg" aria-labelledby="serviceAgreement">
                                                        <ng-container *ngIf="serviceAgreement.fdsAgreementStatus=='1'">
                                                            <ng-container *ngIf="!isExpirySign(serviceAgreement.expireDate+'Z')">
                                                                <a class="dropdown-item text-center" target="_blank" (click)="createEnvelop(serviceAgreement)">Send
                                                                    to clients</a>
                                                            </ng-container>
                                                            <a class="dropdown-item text-center" target="_blank" (click)="editServiceAgreement(serviceAgreement)">Edit</a>
                                                            <a class="dropdown-item expired text-center"
                                                                (click)="openDeleteDialog(template,serviceAgreement.id, serviceAgreement.fdsAgreementStatus)"
                                                                target="_blank" style="color: red;">Delete</a> 
                                                        </ng-container>
                                                        <ng-container *ngIf="serviceAgreement.fdsAgreementStatus=='2'">
                                                            <ng-container *ngIf="!isExpirySign(serviceAgreement.expireDate+'Z')">
                                                                <a class="dropdown-item text-center" target="_blank"
                                                                    (click)="sendToClients(serviceAgreement)">Send
                                                                    to clients</a>
                                                            </ng-container>
                                                            <a class="dropdown-item text-center" target="_blank" (click)="editServiceAgreement(serviceAgreement)">Edit</a>
                                                            <a class="dropdown-item expired text-center"
                                                                (click)="openDeleteDialog(template,serviceAgreement.id, serviceAgreement.fdsAgreementStatus)"
                                                                target="_blank" style="color: red;">Delete</a> 
                                                        </ng-container>
                                                        <ng-container *ngIf="serviceAgreement.fdsAgreementStatus=='3' || serviceAgreement.fdsAgreementStatus=='4'">
                                                            <a class="dropdown-item expired text-center"
                                                            (click)="openDeleteDialog(template,serviceAgreement.id, serviceAgreement.fdsAgreementStatus)"
                                                            target="_blank" style="color: red;">Delete</a> 
                                                        </ng-container>
                                                        <a class="dropdown-item text-center " target="_blank"
                                                            (click)="viewServiceAgreement(serviceAgreement)">Preview</a>
                                                        <!-- <ng-container *ngIf="serviceAgreement.fdsServiceDocuments.length>1 && serviceAgreement.fdsAgreementStatus !='1'">
                                                            <a class="dropdown-item text-center" target="_blank"
                                                                (click)="viewDocumentList(serviceAgreement)">Download PDF</a>
                                                        </ng-container> -->
                                                        <ng-container *ngIf="serviceAgreement.fdsServiceDocuments.length > 0">
                                                            <a class="dropdown-item text-center" target="_blank"
                                                                (click)="viewDocument(serviceAgreement, serviceAgreement.envelopeID)">Download
                                                                PDF</a>
                                                        </ng-container>

                                                    
                                                        <!-- <a class="dropdown-item expired text-center" [routerLink]="['/preview-service-agreement']" target="_blank">Preview FDS</a> -->
                                                        
                                                    </div>
                                                    
                                                </td>
                                            </tr>
                                            
                                        </tbody>
                                        <tbody *ngIf="serviceAgreementList.length == 0"
                                            style=" width: 100% !important; text-align: center;">
                                            <tr>
                                                <td colspan="3">No records to show.</td>
                                                <td></td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div [hidden]="!isLoaded" class="grid-pagination-wr col-12">
                        <div class="bg-white d-flex justify-content-between align-items-center mb-3 px-2 rounded-bottom">
                          <span>Showing
                            {{ (this.currentPage == 1) ? this.currentPage : ((this.currentPage * this.itemsPerPage) -  this.itemsPerPage + 1)}}  to
                            {{ (this.itemsPerPage == this.serviceAgreementList?.length) ? (this.currentPage * this.itemsPerPage) :  this.totalRecordsCount }} of
                            {{ this.totalRecordsCount }} entries
                          </span>
                          <pagination
                            [boundaryLinks]="showBoundaryLinks"
                            [directionLinks]="showDirectionLinks"
                            [totalItems]="this.totalRecordsCount"
                            [itemsPerPage]="this.itemsPerPage"
                            (pageChanged)="getServiceAgreementList($event)"
                            [maxSize]="10">
                          </pagination>
                        </div>
                    </div>

                </div>
            </section>
            <!--/ Zero configuration table -->
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-body text-center">

        <img src="assets/img/warning.png" alt="" class="alert_img">
        <p>{{message}}</p>

        <div class="form-actions center mb-1">
            <button type="button" class="btn alert_no_b mr-1" (click)="decline()">
                No
            </button>
            <button type="button" class="btn alert_yes_b" (click)="confirm()">
                Yes
            </button>
        </div>
    </div>
</ng-template>

<ng-template #templateSendToClients>
    <div class="modal-body text-center">

        <img src="assets/img/warning.png" alt="" class="alert_img">
        <p>{{message}}</p>

        <div class="form-actions center mb-1">
            <button type="button" class="btn alert_no_b mr-1" (click)="decline()">
                No
            </button>
            <button type="button" class="btn alert_yes_b" (click)="confirmSendToClients()">
                Yes
            </button>
        </div>
    </div>
</ng-template>
<ng-container *ngIf="loaded">
    <ngx-ui-loader></ngx-ui-loader>
</ng-container>
<app-footer></app-footer>