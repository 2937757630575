<div class="app-content content_">
  <div class="content-wrapper">
      <div class="content-header row"> </div>
      <div class="content-body">
          <section class="flexbox-container">
              <div class="col-12 d-flex align-items-center justify-content-center">
                  <div class="col-md-4 col-10 box-shadow-2 p-0 login_width">
                      <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                          <div class="card-header border-0" style="padding-bottom: 5px;">
                              <div class="card-title text-center"> <img src="assets/img/logo_login.png" alt="branding logo" style="width: 75%;"> </div>
                              <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2 logh" >
                                  <span>SIGN IN</span>
                              </h6> </div>
                          <div class="card-content">
                              <div class="card-body">
                                  <form  [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form-horizontal" action="index.html" novalidate>
                                      <div *ngIf="invalid" class="alert alert-danger">
                                        <!-- Username or Password mismatch. -->
                                        The username or password does not match for the admin account.
                                      </div>
                                      <!-- <fieldset class="form-group position-relative has-icon-right mar_field">
                                          <input type="text" class="form-control" id="user-name" formControlName="userName" placeholder="UserName" required >
                                          <div class="form-control-position"> <i class="la la-user"></i> </div>

                                          <div *ngIf="submitted && inputs.userName.errors" class="invalid-feedback">
                                            <div *ngIf="inputs.userName.errors.required">User name is required</div>
                                          </div>

                                          <div class="form-group">
                                            <div *ngIf="submitted && inputs.userName.errors" class="alert alert-danger">
                                                <div *ngIf="inputs.userName.errors.required">User name is required</div>                                                
                                            </div>
                                          </div>

                                      </fieldset>

                                      <fieldset class="form-group position-relative has-icon-right mar_field">
                                          <input type="password" class="form-control" formControlName="password" id="user-password" placeholder="Password" required >
                                          <div class="form-control-position"> <i class="la la-unlock-alt"></i> </div>                                         

                                          <div class="form-group">
                                            <div *ngIf="submitted && inputs.password.errors" class="alert alert-danger">
                                                <div *ngIf="inputs.password.errors.required">Password is required</div>
                                            </div>
                                          </div>

                                      </fieldset> -->

                                        <fieldset class="form-group position-relative has-icon-right mar_field">
                                            <input type="text" class="form-control" id="user-name" formControlName="userName" [ngClass]="{ 'is-invalid': submitted && inputs.userName.errors }" placeholder="UserName" required >
                                            <div class="form-control-position"> <i class="la la-user"></i> </div>
                                            <div *ngIf="submitted && inputs.userName.errors" class="invalid-feedback"></div>
                                        </fieldset>

                                        <fieldset class="form-group position-relative has-icon-right mar_field">
                                            <input type="password" class="form-control" formControlName="password" [ngClass]="{ 'is-invalid': submitted && inputs.password.errors }" id="user-password" placeholder="Password" required >
                                            <div class="form-control-position"> <i class="la la-unlock-alt"></i> </div>                                         
                                            <div *ngIf="submitted && inputs.password.errors" class="invalid-feedback"></div>
                                        </fieldset>
                                        
                                      <button type="submit" class="btn btn-block signbtn">SIGN IN</button>
                                  </form>
                              </div>
                              <div class="for_p">
                                  <p class="text-center m-0"><a href="/forget-password" class="card-link">Forgot Password?</a></p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
  </div>
</div>

<!-- <ngx-ui-loader  ></ngx-ui-loader> -->
<ngx-ui-loader *ngIf="!logged" [loaderId]="'loader-01'"></ngx-ui-loader>