import { Component, OnInit, EventEmitter } from '@angular/core';

import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { RolePermissionAddModel } from '../../shared/models/role-permission.model';
import { RoleAddModel } from '../../shared/models/role.model';
import { PermissionAddModel } from '../../shared/models/permission.model';
import { Router } from '@angular/router';
import { PermissionGroupsService } from '../../permission-groups/permission-groups.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { PermissionGroup, RoleResponse, PermissionResponse, RolePermissionResponse } from '../../shared/models/response.model';
import { Utility } from '../../utility/utility';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-add-permission-groups',
  templateUrl: './add-permission-groups.component.html',
  styleUrls: ['./add-permission-groups.component.css']
})
export class AddPermissionGroupsComponent implements OnInit {

  Isvalid: boolean;
  roleAddModel: RoleAddModel;
  rolePermissionModel: RolePermissionAddModel;
  permissionAddModel: PermissionAddModel;
  event: EventEmitter<any> = new EventEmitter();
  errors;
  data;
  permissionList:PermissionAddModel[];
  isLoaded: boolean;
  roleData;

  constructor(private permissionGroupsService: PermissionGroupsService ,private toastr: ToastrService, private ngxService: NgxUiLoaderService, public modalRef: BsModalRef, private router: Router, private cookieService: CookieService) { 
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit() {
    this.Isvalid = true;
    this.rolePermissionModel = new RolePermissionAddModel();
    this.roleAddModel = new RoleAddModel();
    this.permissionAddModel = new PermissionAddModel();

    this.permissionGroupsService.permissionList().subscribe((data:PermissionResponse)=>{  
      this.permissionList = data.data;  
      this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      }); 
  }

  onSubmit(form:NgForm){
    let name = this.roleAddModel.name;
    let description = this.roleAddModel.description;    

    if(form.invalid || !this.roleAddModel.name || !this.roleAddModel.description ) {
  
      if(name && name.trim()==""){
        form.controls['name'].setErrors({'required': true});
      }
      if(description && description.trim()==""){
        form.controls['description'].setErrors({'required': true});
      }

      if(form.pristine){
      }
      this.Isvalid = false;
      // console.log(form.invalid);  
      //console.log(this.Isvalid);
    }
  
  else{ 
    // let companyId  = this.cookieService.get('companyId');
    // console.log(companyId);
    // console.log(JSON.parse(localStorage.getItem('businessId')));

    this.ngxService.start();
    this.roleAddModel.companyId = this.cookieService.get('companyId');
    // console.log(form.invalid);
    const selectedPermissionList = this.permissionList.filter( (permission) => permission.checked );
    
    this.permissionGroupsService.addRole(this.roleAddModel).subscribe((data:RoleResponse)=>{ 
      this.roleData = data.data;
      
      // if(data.error && !data.success){
      if(!data.isSuccess){
        this.ngxService.stop();         
        //this.errors = Utility.GetLocalErrorString(data.error.errorCode);
        this.toastr.error('Permission group name already exists.');
        //console.log(this.errors);
      }
      else{
          // for(let selectedPermission of selectedPermissionList) {
          //   // console.log('selectedPermission');
          //   // console.log(selectedPermission);
          //   this.rolePermissionModel.roleId = this.roleData.id;
          //   this.rolePermissionModel.permissionId = selectedPermission.id;
          //   // console.log('save data');
          //   // console.log(this.rolePermissionModel);
          //   this.permissionGroupsService.addRolePermission(this.rolePermissionModel).subscribe((data:RolePermissionResponse)=>{  
          //     if(data.error && !data.success){
                
          //       //this.toastr.error('Error on saving permission group.');
          //     }
          //     else{
          //       // this.ngxService.stop();
          //       // this.toastr.success('Permission group added successfully.');
          //       // this.event.emit('OK');
          //       // this.modalRef.hide();
          //     }      
          //     },err=>{   
          //       // this.ngxService.stop();
          //       // this.toastr.error('Error on saving permission group.');
          //     });
          // }
          this.router.navigate(['permission-groups']);
          this.ngxService.stop();
          this.toastr.success('Permission group added successfully.');
          this.event.emit('OK');
          this.modalRef.hide();
          
          
      }
      
      },err=>{ 
        this.ngxService.stop();  
        this.toastr.error('Error on saving permission group.');
      });


    
    
    }
    
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

}
