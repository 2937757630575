<div class="modal-header a_modal">
  <h4 class="modal-title" id="myModalLabel1">Update Primary Mobile Number</h4>
  <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
</div>


<form #f="ngForm" novalidate class="form" (ngSubmit)="onSubmit(f)" autocomplete="false" >
  <div class="modal-body dallecort_modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-body">
          <!-- <label for="firstName" class="c_n_fs">
            Mobile Number
          </label>
          <input autocomplete="off" type="text" pattern="^(\+[0-9]{11})$" required [(ngModel)]="unVerifiedUsersAddModel.primaryMobile" class="form-control"  id="primaryMobile" 
          name="primaryMobile" placeholder="Mobile Number"  #primaryMobile="ngModel" maxlength="15">

          <div class="form-group">
            <div *ngIf="primaryMobile.invalid && (primaryMobile.dirty || primaryMobile.touched || f.submitted)"
            class="form_alert">
            <span  class="ft-alert-circle"></span>
              <div *ngIf="primaryMobile.errors.required">
                  Mobile number is required.
              </div> 
              <div *ngIf="primaryMobile.errors.pattern">
                Mobile number must be length 11 and type of +61445678956.
              </div>                                                  
            </div>
          </div>

          <div class="modal-footer a-footer  " style="padding-right: 0px;">
            <button type="submit" class="btn popup_cancel_b_small cancelbtn ff_ins_btn" (click)="addMobileNumber()">Cancel</button>
            <button type="submit" class="btn popup_save_b_small factfinderbtn ff_ins_btn">Update</button>
          </div> -->


          <div class="col-md-12">
            <div class="form-group">
                <label for="primaryMobile" class="c_n_fs">Mobile Number*</label>
                <input autocomplete="off" type="text" pattern="^(\+)?\d{11}$" required class="form-control" id="primaryMobile" name="primaryMobile"  placeholder="Mobile Number" [(ngModel)]="clientModel.primaryMobile" #primaryMobile = "ngModel">
                <div class="form-group">
                    <div *ngIf="primaryMobile.invalid && (primaryMobile.dirty || primaryMobile.touched || f.submitted)"
                    class="form_alert">
                    <span  class="ft-alert-circle"></span>
                      <div *ngIf="primaryMobile.errors.required">
                          Mobile number is required.
                      </div> 
                      <div *ngIf="primaryMobile.errors.pattern">
                            Mobile number must be length 11 and type of +61445678956.
                          </div>                                                  
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer a-footer" >
    <!-- <div *ngIf="!Isvalid" class="form_alert">
          <span  class="ft-alert-circle"></span>
        Please fill in the required field correctly.
    </div> -->
    <div *ngIf="errors" class="form_alert">
          <span  class="ft-alert-circle"></span>
        {{errors}}
    </div>
    <!-- <button type="button" class="btn popup_delete_b">Delete</button> -->
    <button type="submit" class="btn popup_save_b">Save</button>
  </div>
</form>
