import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maintenace-mode',
  templateUrl: './maintenace-mode.component.html',
  styleUrls: ['./maintenace-mode.component.css']
})
export class MaintenaceModeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
