import { User } from "./user.model";


export class MessagecenterBaseModel {
    public  id: string;
    public  reciverID: [];
    public  createdDate : Date;
    public  initiator: User;
    public  initiatorId : string;
    public  isRead: boolean;
    public  title : string;
    public recivers : User[];
    public  reciverSingleID: string;

}

export class MessagecenterModel extends MessagecenterBaseModel{    
     
}

export class ChatModel{
    public  id: string;
    public  createdDate : Date;
    public  senderId: string;
    public  documentURL : string;
    public  text : string;
    public  version : string;
    public  messageType : string;
}

export class SendChatModel{
    public  conversationId: string;
    public  senderId: string;
    public  documentURL : string;
    public  text : string;
    public  version : string;
    public  messageType : string;
}

export class ChatFileModel{
    public  documentType : string;
    public  document : File;
    public  s3Key : string;
}

