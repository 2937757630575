import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FacFinderService } from '../fac-finder.service';
import { XFacContactAddModel } from 'src/app/shared/models/xfac-contact.model';
import { XFacContactResponse, XFacAddressResponse, XFacEmploymentResponse, XFacGoalResponse } from 'src/app/shared/models/response.model';
import { XFacAddressAddModel } from 'src/app/shared/models/xfac-address.model';
import { XFacEmploymentAddModel } from 'src/app/shared/models/xfac-employment.model';
import { XFacGoalAddModel } from 'src/app/shared/models/xfac-goal.model';

@Component({
  selector: 'app-add-goal',
  templateUrl: './add-goal.component.html',
  styleUrls: ['./add-goal.component.css']
})
export class AddGoalComponent implements OnInit {

  data;
  addGoalForm: FormGroup;
  goalAddModel : XFacGoalAddModel;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  buttonText: string ="Add New Contact Details";
  goalOwnerList : any = [];
  facFinderId : any;
  clientCode : any;
  currentDate = new Date();
  title: any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private facFinderService: FacFinderService,
    public modalRef: BsModalRef,
    private toastr: ToastrService
    ) { }
 
  ngOnInit() {
    this.Isvalid = true;    
    this.goalAddModel = new XFacGoalAddModel(); 
    this.goalAddModel.ownerType ="000"; 

    if(this.data.goal !=null){
      //console.log(this.data.library);
      this.buttonText = "Update Now";
      Object.assign(this.goalAddModel, this.data.goal);
      this.goalAddModel.targetDate = new Date(this.goalAddModel.targetDate+'Z');
    }

    if(this.data.goal_owner_list !=null){
      Object.assign(this.goalOwnerList, this.data.goal_owner_list);
    }

    if(this.data.facFinderId !=null){
      this.facFinderId = this.data.facFinderId;
    }

    if(this.data.clientCode !=null){
      this.clientCode = this.data.clientCode;
    }

    this.title = this.data.title;
    
    //console.log(this.goalAddModel);
    // if(this.data.business_id !=null){
    //   this.business_id = this.data.business_id;
    // }

    this.addGoalForm = this.formBuilder.group({
      ownerType: ['', Validators.required],
      description: ['', Validators.required],
      targetAmountValue: ['', Validators.required],
      comments: ['', Validators.required],
      targetDate: ['', Validators.required],
    });

  }

  get f() { return this.addGoalForm.controls; }

onSubmit(){
 this.submitted = true;

 let ownerType = this.goalAddModel.ownerType;

 if (this.addGoalForm.invalid || (ownerType && ownerType.trim()=="000") ) { //|| (title && title.trim()=="")

  if(ownerType && ownerType.trim()=="000"){
    this.addGoalForm.controls['ownerType'].setErrors({'required': true});
  }
  console.log(this.f);
  console.log(this.addGoalForm.invalid);
  return;
}

else{
  //this.folderModel.name = title.trim();
  this.goalAddModel.targetAmountCode = 'AUD';
  this.goalAddModel.category = 0;

  if(this.data.goal !=null){
  //   this.ngxService.start();
  // //console.log("Edditre");

    this.ngxService.start();
    this.goalAddModel.clientCode = this.clientCode;
    this.facFinderService.editGoal(this.goalAddModel, this.facFinderId)
    .subscribe((data:XFacGoalResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){      
      this.toastr.error('Error on updating goal.');      
    }
    else{
      this.toastr.success('Goal updated successfully.');
      this.event.emit('OK');
    // console.log(this.event);
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on updating goal.');
    });
      
    //this.router.navigate(['agents']);    

  }else{
    
    this.ngxService.start();
    this.goalAddModel.clientCode = this.clientCode;
    this.facFinderService.addGoal(this.goalAddModel, this.facFinderId)
    .subscribe((data:XFacGoalResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){      
      this.toastr.error('Error on saving goal.');      
    }
    else{
      this.toastr.success('Goal added successfully.');
      this.event.emit('OK');
     // console.log(this.event);
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on saving goal.');
    });

  }

}

}

modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

onReset() {
  this.submitted = false;
  this.addGoalForm.reset();
}

}
