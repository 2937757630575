import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FacFinderService } from '../fac-finder.service';
import { XFacInsuranceMedicalResponse } from 'src/app/shared/models/response.model';
import { XFacInsuranceMedicalAddModel } from 'src/app/shared/models/xplan-insuranceMedical.model';

@Component({
  selector: 'app-add-medical-insurance',
  templateUrl: './add-medical-insurance.component.html',
  styleUrls: ['./add-medical-insurance.component.css']
})
export class AddMedicalInsuranceComponent implements OnInit {

  data;
  errors;
  addMedicalInsuranceForm: FormGroup;
  insuranceMedicalAddModel : XFacInsuranceMedicalAddModel;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  buttonText: string ="Add New Asset";
  assetOwnerList : any = [];
  insuranceMedicalTypeList : any = [];
  assetSubTypeList : any = [];
  facFinderId : any;
  clientCode : any;
  title: any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private facFinderService: FacFinderService,
    public modalRef: BsModalRef,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.Isvalid = true;    
    this.insuranceMedicalAddModel = new XFacInsuranceMedicalAddModel(); 
    this.insuranceMedicalAddModel.type ="000"; 
    this.insuranceMedicalAddModel.owners ="000"; 

    if(this.data.medicalInsurance !=null){
      //console.log(this.data.library);
      this.buttonText = "Update Now";
      Object.assign(this.insuranceMedicalAddModel, this.data.medicalInsurance);
    }

    // asset_owner_list : this.asset_owner_list,
    //     asset_type : this.asset_type,
    //     asset_sub_type : this.asset_sub_type,

    if(this.data.asset_owner_list !=null){
      Object.assign(this.assetOwnerList, this.data.asset_owner_list);
    }

    if(this.data.insurance_medical_type_list !=null){
      Object.assign(this.insuranceMedicalTypeList, this.data.insurance_medical_type_list);
    }

    if(this.data.facFinderId !=null){
      this.facFinderId = this.data.facFinderId;
    }

    if(this.data.clientCode !=null){
      this.clientCode = this.data.clientCode;
    }

    this.title = this.data.title;
    
    // console.log(this.clientCode);
    // if(this.data.business_id !=null){
    //   this.business_id = this.data.business_id;
    // }

    this.addMedicalInsuranceForm = this.formBuilder.group({
      type: ['', Validators.required],
      policyNumber: ['', Validators.required],
      planName: ['', Validators.required],
      totalPremiumValue: ['', Validators.required],
      owners: ['', Validators.required],
    });
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

  get f() { return this.addMedicalInsuranceForm.controls; }

  onSubmit(){
    this.submitted = true;
   
    let type = this.insuranceMedicalAddModel.type;
    let owners = this.insuranceMedicalAddModel.owners;
   
    if (this.addMedicalInsuranceForm.invalid || (type && type.trim()=="000") || (owners && owners.trim()=="000") ) { //|| (title && title.trim()=="")
   
      if(type && type.trim()=="000"){
        this.addMedicalInsuranceForm.controls['type'].setErrors({'required': true});
      }

      if(owners && owners.trim()=="000"){
        this.addMedicalInsuranceForm.controls['owners'].setErrors({'required': true});
      }
      console.log(this.f);
      console.log(this.addMedicalInsuranceForm.invalid);
      return;
    }
   
    else{
      //this.folderModel.name = title.trim();
      this.insuranceMedicalAddModel.totalPremiumCode = 'AUD';

      if(this.data.medicalInsurance !=null){
      //   this.ngxService.start();
      // console.log("Edditre");
      // console.log(this.insuranceMedicalAddModel);
      
        this.ngxService.start();
        this.insuranceMedicalAddModel.clientCode = this.clientCode;
        this.facFinderService.editMedicalInsurance(this.insuranceMedicalAddModel, this.facFinderId)
        .subscribe((data:XFacInsuranceMedicalResponse)=>{
        this.ngxService.stop();
        if(data.error && !data.success){      
          this.toastr.error('Error on updating medical insurance.');      
        }
        else{
          this.toastr.success('Medical insurance updated successfully.');
          this.event.emit('OK');
          // console.log(this.event);
          this.modalRef.hide();
        }
        
        },err=>{   
          console.log(err);
          this.ngxService.stop();
          this.toastr.error('Error on updating medical insurance.');
        });
          
          //this.router.navigate(['agents']);
          
      
      }else{
        
        this.ngxService.start();
        this.insuranceMedicalAddModel.clientCode = this.clientCode;
        this.facFinderService.addMedicalInsurance(this.insuranceMedicalAddModel, this.facFinderId)
        .subscribe((data:XFacInsuranceMedicalResponse)=>{
        this.ngxService.stop();
        if(data.error && !data.success){      
          this.toastr.error('Error on saving medical insurance.');      
        }
        else{
          this.toastr.success('Medical insurance added successfully.');
          this.event.emit('OK');
          // console.log(this.event);
          this.modalRef.hide();
        }
        
        },err=>{   
          console.log(err);
          this.ngxService.stop();
          this.toastr.error('Error on saving medical insurance.');
        });
    
      }
    
    }
   
  }

}
