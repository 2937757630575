import { connect, ConnectOptions, LocalTrack, Room, createLocalTracks } from 'twilio-video';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
//import { ReplaySubject , Observable } from 'rxjs';
import { AppConstants } from '../../utility/app-constants';
//import { map } from 'rxjs/internal/operators/map';

import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
// import { element } from '@angular/core/src/render3';
import { strictEqual } from 'assert';
import { BehaviorSubject, Observable, throwError, ReplaySubject } from 'rxjs';
import { RoomCreate , User, VerifiedUser } from '../../shared/models/user.model';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';
import { Response } from '../../shared/common/response';
import { AppointmentAddModel } from 'src/app/shared/models/appointment.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CallService {
  baseUrl: String;
  RoomCreate : RoomCreate;
  private roomBroadcast = new ReplaySubject<boolean>();

  constructor(private readonly http: HttpClient, private cookieService: CookieService) {
    this.baseUrl = AppConstants.getBaseURL();
    this.RoomCreate = new RoomCreate();
  }

  createVideoCallToken( appointment: AppointmentAddModel) {         
    //var userId = localStorage.getItem('CurrentUserId');
    var userId:String = this.cookieService.get('CurrentUserId'); //JSON.parse( localStorage.getItem('CurrentUserId'));
    //var roomName:String = JSON.parse( roomName);
    //console.log(userId);
    //console.log(roomName);
    //console.log(this.baseUrl + '/VideoCall/CreatRoom');
    
    //let dat = {userId: userId,roomName:roomName};

    // this.RoomCreate.userId = userId;
    // this.RoomCreate.roomName = userId;

    let data = {userId: userId,reciverId:appointment.clientId};
    //let RoomCreate = new RoomCreate();
    // {params: data}

    return this.http.post<any>(this.baseUrl + '/VideoCall/CreatRoom',data).pipe(map(data => {

      //console.log("inside post");
      //console.log(data);
        if (data.success) {
          if(data.response.twilioRoom.unique_name)              
          localStorage.setItem('twilio_token', JSON.stringify(data.response.token));
          localStorage.setItem('room_name', JSON.stringify(data.response.twilioRoom.unique_name)); 
          //console.log(data.response.token);            
          //console.log(data);            
          //console.log("twilio data setup");            
          return data;  //data.response.sid  

        } else{
          return data;
        }
      return data;
      }));
      
  }

  disconnectCall(appointment){
    var userId:string = this.cookieService.get('CurrentUserId'); //JSON.parse( localStorage.getItem('CurrentUserId'));
  let data = {userId: userId,notifyToId:appointment.clientId}; 
  
    return this.http.post<any>(this.baseUrl + '/VideoCall/DisconnectCall',data).pipe(map(data => {
      //console.log(data);
        if (data.success) {                   
          return data;  //data.response.sid 
        } else{
          return data;
        }
      return data;
      }));
  }

  getVideoCallToken( roomName: string) {         
    //var userId = localStorage.getItem('CurrentUserId');
    var userId:string = this.cookieService.get('CurrentUserId'); //JSON.parse( localStorage.getItem('CurrentUserId'));
    //var roomName:String = JSON.parse( roomName);
    //console.log(userId);
    //console.log(roomName);
    //console.log(this.baseUrl + '/VideoCall/JoinRoom');
    
    //let dat = {userId: userId,roomName:roomName};

    // this.RoomCreate.userId = userId;
    // this.RoomCreate.roomName = userId;

   // let data = {userId: userId,roomName:roomName};
    //let RoomCreate = new RoomCreate();
    // {params: data}

    let params = new HttpParams().set("userId",userId).set("roomName", roomName); 

    //return this.http.post<any>(this.baseUrl+'/VideoCall/JoinRoom',{params}).pipe(map(data => {
    return this.http.get<any>(this.baseUrl+'/VideoCall/JoinRoom',{params}).pipe(map(data => {
  //return this.http.post<any>(this.baseUrl + '/VideoCall/JoinRoom',{params}).pipe(map(data => {

      //console.log("inside post");
      //console.log(data);
        if (data.success) {
          if(data.response.token)              
          localStorage.setItem('twilio_token', JSON.stringify(data.response.token));
          localStorage.setItem('room_name', JSON.stringify(roomName)); 
          //console.log(data.response.token);            
          //console.log(data);            
          //console.log("twilio data setup");            
          return data;  //data.response.sid  

        } else{
          return data;
        }
      return data;
      }));
      
  }

  joinRoom(token: string, name: string ) {
    let room: Room = null;
    let tracks: LocalTrack[];
    try {
      
      // createLocalTracks({
      //   audio: true,
      //   video: { width: 640 }
      // }).then(localTracks => {
      //   room = connect(token, {
      //     name: name,
      //     tracks: localTracks
      //   });
      // });
    

        room =
             connect(
                token, {
                    name,
                    tracks,
                    dominantSpeaker: true
                } as ConnectOptions);

    } catch (error) {
        console.error(`Unable to connect to Room: ${error.message}`);
    } finally {
        if (room) {
            this.roomBroadcast.next(true);
            
        }
    }

    return room;
}

nudge() {
  this.roomBroadcast.next(true);
}

}
