
      <div class="modal-header a_modal">
        <h4 class="modal-title" id="myModalLabel1">{{title}}</h4>
        <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
    </div>

    <form #f="ngForm" novalidate class="form" enctype="multipart/form-data" (ngSubmit)="onSubmit(f)" >
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
               
                    <div class="form-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="projectinput8" class="c_n_fs">Name *</label>
                                    <input type="text" maxlength="100" id="name" required  class="form-control" name="name"  placeholder="Name" #name = "ngModel" [(ngModel)]="audienceModel.name">
                                    <!-- <input id="summary" required  type="text"  class="form-control" name="summary" placeholder="" [(ngModel)]="newsFeedModel.summary" #summary = "ngModel"> -->

                                    <div class="form-group">
                                      <div *ngIf="name.invalid && (name.dirty || name.touched)" class="form_alert">
                                        <span  class="ft-alert-circle"></span>
                                            <div *ngIf="name.errors.required">
                                              Name is required.
                                            </div>                                                  
                                      </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="projectinput8" class="c_n_fs">Description *</label><span
                                    class="float-right word-count">{{audienceModel.description.length}}/{{maxChars}}</span>
                                    <textarea style="resize: none;" id="description" required rows="4" maxlength="300" class="form-control c_n_form" name="description"  placeholder="Description only 300 characters allowed." #description = "ngModel" [(ngModel)]="audienceModel.description"></textarea>
                                    <!-- <input id="summary" required  type="text"  class="form-control" name="summary" placeholder="" [(ngModel)]="newsFeedModel.summary" #summary = "ngModel"> -->

                                    <div class="form-group">
                                      <div *ngIf="description.invalid && (description.dirty || description.touched)" class="form_alert">
                                        <span  class="ft-alert-circle"></span>
                                        <div *ngIf="description.errors.required">
                                          Description is required.
                                        </div>                                               
                                      </div>
                                    </div>

                                </div>
                            </div>
                            
                        </div>
                    </div>
                
            </div>
            
        </div>
    </div>
    
    <div class="modal-footer a-footer">
        <div *ngIf="!Isvalid" class="form_alert">
              <span  class="ft-alert-circle"></span>
            Please fill in all the required fields correctly.
        </div>
        <div *ngIf="errors" class="form_alert">
              <span  class="ft-alert-circle"></span>
            {{errors}}
        </div>
        <!-- <button type="button" class="btn popup_delete_b">Delete</button> -->
        <button type="submit" class="btn popup_save_b">{{buttonTitle}}</button>
    </div>
    </form> 
    <ngx-ui-loader></ngx-ui-loader> 

