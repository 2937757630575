import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FacFinderService } from '../fac-finder.service';
import { XFacAssetResponse } from 'src/app/shared/models/response.model';
import { XFacAssetAddModel } from 'src/app/shared/models/xfac-asset.model';

@Component({
  selector: 'app-add-assets',
  templateUrl: './add-assets.component.html',
  styleUrls: ['./add-assets.component.css']
})
export class AddAssetsComponent implements OnInit {

  data;
  errors;
  addAssetForm: FormGroup;
  assetAddModel : XFacAssetAddModel;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  buttonText: string ="Add New Asset";
  assetOwnerList : any = [];
  assetTypeList : any = [];
  assetSubTypeList : any = [];
  facFinderId : any;
  clientCode : any;
  title: any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private facFinderService: FacFinderService,
    public modalRef: BsModalRef,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.Isvalid = true;    
    this.assetAddModel = new XFacAssetAddModel(); 
    this.assetAddModel.type ="000"; 
    this.assetAddModel.subType ="000"; 
    this.assetAddModel.ownerType ="000"; 

    if(this.data.assets !=null){
      //console.log(this.data.library);
      this.buttonText = "Update Now";
      Object.assign(this.assetAddModel, this.data.assets);
    }

    // asset_owner_list : this.asset_owner_list,
    //     asset_type : this.asset_type,
    //     asset_sub_type : this.asset_sub_type,

    if(this.data.asset_owner_list !=null){
      Object.assign(this.assetOwnerList, this.data.asset_owner_list);
      let choice = this.assetOwnerList.filter(choice => choice.key == this.assetAddModel.ownerType);
      if(choice && choice[0]) {
        this.assetAddModel.ownerType = choice[0].key;
        // this.selectedChoice = choice[0].key;
      }
      else{
        this.assetAddModel.ownerType = "000";
      }
    }

    if(this.data.asset_type !=null){
      Object.assign(this.assetTypeList, this.data.asset_type);
    }

    if(this.data.asset_sub_type !=null){
      Object.assign(this.assetSubTypeList, this.data.asset_sub_type);
    }

    if(this.data.facFinderId !=null){
      this.facFinderId = this.data.facFinderId;
    }

    if(this.data.clientCode !=null){
      this.clientCode = this.data.clientCode;
    }

    this.title = this.data.title;

    // if(this.data.business_id !=null){
    //   this.business_id = this.data.business_id;
    // }

    this.addAssetForm = this.formBuilder.group({
      type: ['', Validators.required],
      subType: ['', Validators.required],
      currentValue: ['', Validators.required],
      description: ['', Validators.required],
      ownerType: ['', Validators.required],
    });
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

  get f() { return this.addAssetForm.controls; }

  onSubmit(){
    this.submitted = true;
   
    let type = this.assetAddModel.type;
    let subType = this.assetAddModel.subType;
    let ownerType = this.assetAddModel.ownerType;
   
    if (this.addAssetForm.invalid || (type && type.trim()=="000") || (subType && subType.trim()=="000") || (ownerType && ownerType.trim()=="000") ) { //|| (title && title.trim()=="")
   
      if(type && type.trim()=="000"){
        this.addAssetForm.controls['type'].setErrors({'required': true});
      }

      if(subType && subType.trim()=="000"){
        this.addAssetForm.controls['subType'].setErrors({'required': true});
      }

      if(ownerType && ownerType.trim()=="000"){
        this.addAssetForm.controls['ownerType'].setErrors({'required': true});
      }
      // console.log(this.f);
      // console.log(this.addAssetForm.invalid);
      return;
    }
   
    else{
      //this.folderModel.name = title.trim();
      this.assetAddModel.currentValueCode = 'AUD';
    
      if(this.data.assets !=null){
      //   this.ngxService.start();
      // console.log("Edditre");
      // console.log(this.assetAddModel);
      
        this.ngxService.start();
        
        this.assetAddModel.clientCode = this.clientCode;
        this.facFinderService.editAsseet(this.assetAddModel, this.facFinderId)
        .subscribe((data:XFacAssetResponse)=>{
        this.ngxService.stop();
        if(data.error && !data.success){      
          this.toastr.error('Error on updating asset.');      
        }
        else{
          this.toastr.success('Asset updated successfully.');
          this.event.emit('OK');
          // console.log(this.event);
          this.modalRef.hide();
        }
        
        },err=>{   
          console.log(err);
          this.ngxService.stop();
          this.toastr.error('Error on updating asset.');
        });
          
          //this.router.navigate(['agents']);
          
      
      }else{
      
        this.ngxService.start();
        this.assetAddModel.clientCode = this.clientCode;
        this.facFinderService.addAsseet(this.assetAddModel, this.facFinderId)
        .subscribe((data:XFacAssetResponse)=>{
        this.ngxService.stop();
        if(data.error && !data.success){      
          this.toastr.error('Error on saving asset.');      
        }
        else{
          this.toastr.success('Asset added successfully.');
          this.event.emit('OK');
          // console.log(this.event);
          this.modalRef.hide();
        }
        
        },err=>{   
          console.log(err);
          this.ngxService.stop();
          this.toastr.error('Error on saving asset.');
        });
    
      }
    
    }
   
  }

  onChange(value){
    let subtypeList = [];
    // console.log(value);
    this.data.asset_sub_type.forEach(device => {
      if(device.key.indexOf(value)==0){
        subtypeList.push(device);
      }
    })

    this.assetSubTypeList = subtypeList;//this.data.asset_sub_type.filter(device => device.key === value);
    //this.assetAddModel.subType ="000"; 
    let choice = this.assetSubTypeList.filter(choice => choice.key == this.assetAddModel.subType);
    if(choice && choice[0]) {
      this.assetAddModel.subType = choice[0].key;
      // this.selectedChoice = choice[0].key;
    }
    else{
      this.assetAddModel.subType = "000";
    }

    // console.log(this.assetSubTypeList);
  }

}
