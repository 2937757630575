import { AgentAddModel } from './agent.model';
import { ClientViewModel } from './client.model';

export class AppointmentBaseModel {
    public  createdDate?: Date;
    public  createdBy : string;
    public  sheduledDate?: Date;
    public  appoinmentWith : string;
    public  clientId : string;
    public  canceled : boolean ;
    public  reason : string;
    public  hour : string;
    public  duration : string;
    public  minute : string;
    public  amPm : string; 
    public  appoinmentWith_ : AgentAddModel;
    public  client : ClientViewModel;
    public  appoinmentStatus : string;

}

export class AppointmentAddModel extends AppointmentBaseModel{    
       
}