import { Component, OnInit, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { MessagecenterService } from '../message-center/messagecenter.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { StartConversationComponent } from './start-conversation/start-conversation.component';
import { StartConversationResponse, ClentProfileResponse } from '../shared/models/response.model';
import { MessagecenterModel } from '../shared/models/messagecenter.model';
import {UserConversation} from '../shared/models/user-conversation.model';
import { ChatComponent } from '../chat/chat.component';
import { map } from 'rxjs/operators/map';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from '../clients/clients.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-message-center',
  templateUrl: './message-center.component.html',
  styleUrls: ['./message-center.component.css']
})
export class MessageCenterComponent implements OnInit {

  @ViewChild('chatcontainer', { read: ViewContainerRef }) entry: ViewContainerRef;
  //@ViewChild('chatcontainer')   primarySampleComponent: ColorSampleComponent;
    //constructor(private resolver: ComponentFactoryResolver) { }
  constructor(
    private clientsService: ClientsService ,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private messagecenterService: MessagecenterService,
    private resolver: ComponentFactoryResolver,
    private router: Router,
    private cookieService: CookieService
    ) { 
   
    // this.messagecenterService.conversation_message.pipe(
    //   map(function(response) {
    //     console.log(3333333333);
    //     console.log(response);
    //   })
    // );

    this.messagecenterService.getConversationNewMsg().subscribe(conv => { 
      //console.log(566565656665);
      this.showConv(conv); 
    });

    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };

  }

  modalRef: BsModalRef;
  skip = "0";
  take = "1000";
  conversationList : MessagecenterModel[];
  conversationListNotFiltered : MessagecenterModel[];
  userConversation : UserConversation;
  componentRef: any;
  newMsgConvId : any;
  clickedConvId: any;
  searchText;
  showEmptyChat: boolean = true;
  clientId : string;
  listLength: number = 5;
  clientIds = [];
  clientName : string = null;
  client : any;
  filterText:string;
  isLoaded : boolean = false;
  clickedConversation : MessagecenterModel;
  userId :string;
  
  ngOnInit() {
    //console.log(888888888888);

    this.clientId = this.route.snapshot.queryParams['id'];     
    //if(this.clientId==null){
      // this.getConversationList(); // comment by thilina, request by DevT (uncomment, DevT remove the service)
    // }
    // else{
    //   this.getClientConversationList(this.clientId);
    // }
    this.userId = this.cookieService.get('CurrentUserId');//JSON.parse( localStorage.getItem('CurrentUserId'));


    if(this.clientId!=null){
      this.getClient(this.clientId);
    }

  }

  showConv(conv){
    let convLiOne = this.conversationList.find(x => x.id == conv.conversationId);
    //console.log("  convLiOne ==== ");
    //console.log(convLiOne);
    if(convLiOne===null || !convLiOne){
      this.getConversationList();
    }
    else{      
      this.newMsgConvId = conv.conversationId;
      convLiOne.isRead = false;
      this.sortList(this.conversationList);
    }
    
    //console.log(conv);
  }

  openModal() {
    let clientIds = [];
    if(this.clientId!=null){
      clientIds.push(this.clientId);
      //messageCenter.reciverID = this.clientIds;
    }

    this.modalRef = this.modalService.show(StartConversationComponent,  {
      initialState: {
        title: 'Add Invoice',
        data: {
          clientIds : clientIds
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      
      if (result == 'OK') {
        //console.log("Success");
        this.getConversationList();
      
      }

    });
  }

  getClient(clientId :string){
    this.clientsService.getClient(clientId).subscribe((clientData:ClentProfileResponse)=>{
   
      this.client = clientData.response;
      //console.log("client details ==========");
      //console.log(this.client.client);
      //this.gender = this.GetPatientGender.GetGender(this.patient.Gender);
      
      if(this.client !=null && this.client.client!=null){
        this.clientName = this.client.client.firstName+" "+this.client.client.lastName;
      }
      else{
        this.clientName = this.client.userName;
      }

      //return this.clientName;
  
      //console.log(this.clientName);
      //this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
        
      });
  }

  getConversationList() {
    let id = this.cookieService.get('CurrentUserId'); //JSON.parse(localStorage.getItem('CurrentUserId'));
    this.messagecenterService.getMyConversations(id,this.skip,this.take).subscribe((data:StartConversationResponse)=>{    
      

      
      //this.conversationList.find(x => x.id == conv.conversationId)
      if(this.clientId !=null){
        let clNt = this.clientId;
        //console.log(clNt);
        this.conversationList = data.response.filter(function(conversation){ 
          return conversation.recivers.find(x => x.id == clNt);      
        });

      }
      else{
        this.conversationList = data.response;
      }

      // if( this.conversationList && this.conversationList[0] ){
      // this.getChatList(this.conversationList[0], this.conversationList[0].id)
      // }

      this.isLoaded = true;
      this.sortList(this.conversationList);
      this.conversationListNotFiltered = this.conversationList;
      this.listLength = this.conversationList.length;
      //console.log(conv_list);
      //this.conversationList = conv_list;

      // .filter(function(client){
      //   return client.client !== null;
      // })

      //console.log('conversationList list  ---------');
      //console.log(this.conversationList);
    });
  }

  // getClientConversationList(clientId){
  //   let senderId = JSON.parse(localStorage.getItem('CurrentUserId'));
  //   let userConversation = new UserConversation();
  //     userConversation.reciverID = clientId;
  //     userConversation.senderID = senderId;
  //   this.messagecenterService.getClientConversations(userConversation).subscribe((data:StartConversationResponse)=>{ 
  //     this.conversationList = data.response;
  //     this.sortList(this.conversationList);
  //     console.log(this.conversationList);
  //     console.log(this.conversationList.length);
  //     this.listLength = this.conversationList.length;
  //     console.log('conversationList list user '+clientId+' ---------');
  //   });
  // }

  sortList(conv_list){
    conv_list.sort(function(x, y) {
      // false values first
      return (x.isRead === y.isRead)? 0 : x.isRead? 1 : -1;
      // false values first
      // return (x === y)? 0 : x? -1 : 1;
    });
  }

  // getChatList(id : string){
  //   this.messagecenterService.getMyConversations(id).subscribe((data:ChatListResponse)=>{     
  //     this.chatList = data.response;

  //     // .filter(function(client){
  //     //   return client.client !== null;
  //     // })

  //     console.log('chat list for  ---------');
  //     console.log(this.chatList);
  //   });
  // }

  getChatList(conversation : MessagecenterModel, conversation_id) {
    this.clickedConversation = conversation;
    this.showEmptyChat = false;
    conversation.isRead = true;
    this.newMsgConvId = null;
    this.clickedConvId = conversation_id;
   
    console.log(conversation);
    
    if(this.entry){
      this.entry.clear();
      const factory = this.resolver.resolveComponentFactory(ChatComponent);
      this.componentRef = this.entry.createComponent(factory);
      this.componentRef.instance.conversation = conversation;
    } 
    // else{
    //   console.log('oooooooooo');
    //   let element = document.getElementById(conversation_id);
    //   element.click();
    //   //this.getChatList(conversation , conversation_id);
    // }       
  }

  destroyComponent() {
      this.componentRef.destroy();
  }

  search(searchText : string){
    //let term = this.searchText;
    this.conversationList = this.conversationListNotFiltered.filter(function(conversation) {
      //console.log(conversation.title);
      if(conversation.title != null){
        return conversation.title.indexOf(searchText) >= 0;
      }
    }); 
  }

}
