<app-header></app-header>
<app-sidebar></app-sidebar>

<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                <h3 class="content-header-title mb-0 d-inline-block"> UNALLOCATED ACCOUNTS</h3>
                <div class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/dashboard">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Unallocated Accounts
                            </li>
                        </ol>
                    </div>
                </div>

            </div>
            <div class="content-header-right col-md-6 col-12 mb-2">
                <div class="form-group">
                    <div style="float: right;">
                        <form [formGroup]="searchUnallocatedForm" novalidate autocomplete="false">
                            <input autocomplete="off" id="xeppoGroupCode" type="text" formControlName="xeppoGroupCode"
                                style="width: 250px !important; border: none;" class="form-control"
                                name="xeppoGroupCode" placeholder="Search by Xeppo group code">
                            <i class="fas fa-search position-absolute" style="right: 25px; top: 12px"></i>
                            <!-- <div class="row" class="float-right">
                            <div *ngIf="errorMessage" class="form_alert float-right">
                                <span class="ft-alert-circle"></span>
                                {{errorMessage}}
                            </div>
                        </div> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section id="">
                <div class="row">
                    <div class="col-12">

                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Unallocated Account List</h4>
                            </div>
                            <div class="card-content collapse show">
                                <div class="card-body1 card-dashboard">
                                    <ng-container *ngIf="isLoaded">
                                        <table datatable [dtOptions]="dtOptions"
                                            class="t_dt table table-striped table-bordered table-sm row-border hover mt-20">
                                            <thead>
                                                <tr>
                                                    <th>Group Name</th>
                                                    <th>Xeppo Group Code</th>
                                                    <th>Current Networth</th>
                                                    <th>Total Assets</th>
                                                    <th>Total Liabilities</th>
                                                    <th>Unallocated</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngIf="unAllocatedAccounts.length > 0">
                                                    <tr *ngFor="let account of unAllocatedAccounts; let i = index">
                                                        <td>{{account?.clientGroupName}}</td>
                                                        <td>{{account?.xeppoGroupCode}}</td>
                                                        <td>{{account?.totalNetWorth | currency}}</td>
                                                        <td>{{account?.totalNetworthAssets | currency}}</td>
                                                        <td>{{account?.totalNetwortLiabilities | currency}}</td>
                                                        <td>{{account?.totalUnAllocated | currency}}</td>
                                                        <td class="sa_btns">
                                                            <a (click)="GetClient(account?.userId)">
                                                                <i class="la la-arrow-right la_icon"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </ng-container>
                                    <div [hidden]="!isLoaded || unAllocatedAccounts.length == 0" class="grid-pagination-wr col-12">
                                        <div
                                            class="bg-white d-flex justify-content-between align-items-center px-2 rounded-bottom">
                                            <span>Showing
                                                {{ (this.currentPage == 1) ? this.currentPage : ((this.currentPage *
                                                this.itemsPerPage)
                                                - this.itemsPerPage + 1)}} to
                                                {{ (this.itemsPerPage == this.unAllocatedAccounts?.length) ?
                                                (this.currentPage *
                                                this.itemsPerPage) : this.totalRecordsCount }} of
                                                {{ this.totalRecordsCount }} entries
                                            </span>
                                            <pagination [boundaryLinks]="showBoundaryLinks"
                                                [directionLinks]="showDirectionLinks" [totalItems]="totalRecordsCount"
                                                [itemsPerPage]="itemsPerPage" (pageChanged)="getUnallocatedList($event)"
                                                [maxSize]="maxSize">
                                            </pagination>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="unAllocatedAccounts.length == 0">
                                        <div style=" width: 100% !important; text-align: center; padding-bottom: 10px;">
                                            No records to show.
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>
<app-footer></app-footer>