<div class="modal-content">
        
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Error Logs</h5>
        <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding: 15px 0px;">

        
                            <table class="t_dt table table-striped table-bordered table-sm row-border hover" style="margin-top: 20px !important;">
                                <thead>
                                    <tr>
                                        <th style="width: 8%;" >#</th>
                                        <th style="width: 20%;">Table Name</th>
                                        <th style="width: 20%;">HTTP Code</th>
                                        <th>Message</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="errorLogs.length>0">
                                    <tr *ngFor="let errorLog of errorLogs; let i = index"> 
                                        <td>{{i}}</td>
                                        <td>{{errorLog.tableName}}</td>
                                        <td>{{errorLog.httpCode}}</td>
                                        <td>{{errorLog.message}}</td>
                                    </tr>
                                    
                                    <!-- <tr *ngFor="let errorLog of groupErrorLogs; let i = index"> 
                                        {{errorLog | json}}
                                    </tr> -->
                                    
                                </tbody>
                                <tbody *ngIf="errorLogs.length == 0"
                                    style=" width: 100% !important; text-align: center;">
                                    <tr>
                                        <td colspan="4">No records to show.</td>
                                    </tr>
                                </tbody>

                            </table>
                        



        <div class="spacer"></div>
    </div>

</div>

<ngx-ui-loader></ngx-ui-loader>
