import { Component, OnInit, EventEmitter } from '@angular/core';
import { RoleAddModel } from 'src/app/shared/models/role.model';
import { RolePermissionAddModel } from 'src/app/shared/models/role-permission.model';
import { PermissionAddModel, ClientGetDataModel } from 'src/app/shared/models/permission.model';
import { PermissionGroupsService } from '../permission-groups.service';
import { PermissionResponse, RoleResponse, PermissionGetResponse, RolePermissionResponse } from 'src/app/shared/models/response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { first } from 'rxjs/operators';
import { Utility } from '../../utility/utility';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-edit-permission-groups',
  templateUrl: './edit-permission-groups.component.html',
  styleUrls: ['./edit-permission-groups.component.css']
})
export class EditPermissionGroupsComponent implements OnInit {

  roleAddModel: RoleAddModel;
  rolePermissionModel: RolePermissionAddModel;
  permissionAddModel: PermissionAddModel;
  permissionList:PermissionAddModel[];
  getPermissionByRole:ClientGetDataModel[];
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  data: any;
  isLoaded: boolean;
  roleData2;
  errors;
  editablePermissionStatus: boolean;

 

  constructor(private permissionGroupsService: PermissionGroupsService, private ngxService: NgxUiLoaderService, private toastr: ToastrService, public modalRef: BsModalRef, private modalService: BsModalService, private router: Router, private cookieService: CookieService) { 
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit() {
    this.Isvalid = true;
    this.isLoaded = false;
    
    // let companyId  = this.cookieService.get('companyId');
    // console.log(companyId);
    // console.log(JSON.parse(localStorage.getItem('businessId')));

    this.roleAddModel = new RoleAddModel();
    this.rolePermissionModel = new RolePermissionAddModel();
    this.permissionAddModel = new PermissionAddModel();
    
      if(this.data.roleData !=null){
        //console.log("edit role 3333obj assign -- ");
        //this.agentModel = Object.assign({}, this.data.agent);
        this.roleAddModel = JSON.parse(JSON.stringify(this.data.roleData)); // Object.assign({}, this.data.agent);
        //Object.assign(this.agentModel, this.data.agent);
        // console.log(this.roleAddModel);
        //Object.assign(this.agentModel, this.data.agent);
      }

      this.permissionGroupsService.permissionList().subscribe((data:PermissionResponse)=>{  
        this.permissionList = data.data; 
        this.checkPermssions(this.permissionList, this.roleAddModel.id);
        //this.isLoaded = true;
        },
        (err: HttpErrorResponse)=>{
          //console.log("Error"+ err.message);
        }); 





  }

  checkPermssions(permissionList, roleId){
    
    this.permissionGroupsService.getPermissionByRole(roleId).subscribe((data:PermissionGetResponse)=>{  
      this.getPermissionByRole = data.data;  

      // console.log(222222222222222222);
      //   console.log(this.getPermissionByRole);
      //   console.log(permissionList);

      // this.getPermissionByRole.forEach(function(element){
        
      //   let item = permissionList.find(x => x.id == element.id);
      //   if(item){
      //     item.checked = true;
      //   }
      // });
      
      console.log(this.getPermissionByRole);
      this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      }); 
  }

  // onSubmit3(form:NgForm){
    
  //   if(form.invalid) {
      
  //     if(form.pristine){
  //     }
  //     this.Isvalid = false;
  //   }
  
  //   else{
  //     this.ngxService.start();
  //     if(this.data.agent !=null){

  //       this.permissionGroupsService.editRole(this.data.agent.id,this.roleAddModel).pipe(first())
  //       .subscribe((data:RoleResponse)=>{
  //         this.ngxService.stop();
  //         if(data.error && !data.success){      
  //           this.toastr.error('Error on updating role.');
  //         }
  //         else{
  //           this.toastr.success('Role updated successfully.');
  //           this.event.emit('OK');
  //           this.modalRef.hide();
  //         }
  //       },err=>{   
  //         this.ngxService.stop();
  //         this.toastr.error('Error on updating role.');
  //       });
  //     }  
  
  //   }
  // }


  onSubmit(form:NgForm){
    let name = this.roleAddModel.name;
    let description = this.roleAddModel.description;    

    if(form.invalid || !this.roleAddModel.name || !this.roleAddModel.description ) {
  
      if(name && name.trim()==""){
        form.controls['name'].setErrors({'required': true});
      }
      if(description && description.trim()==""){
        form.controls['description'].setErrors({'required': true});
      }

      if(form.pristine){
      }
      this.Isvalid = false;
      // console.log(form.invalid);  
      //console.log(this.Isvalid);
    }
  
  else{ 
    this.ngxService.start();
    const selectedPermissionList = this.permissionList.filter( (permission) => permission.checked );
    this.roleAddModel.companyId = this.cookieService.get('companyId');
    
    this.permissionGroupsService.editRole(this.roleAddModel.id,this.roleAddModel).pipe(first()).subscribe((data:RoleResponse)=>{
      this.roleData2 = data.data;
      
      if(data.error && !data.success){
        this.ngxService.stop();         
        this.errors = Utility.GetLocalErrorString(data.error.errorCode);
        //console.log(this.errors);
      }
      else{
          // for(let selectedPermission of selectedPermissionList) {
          //   console.log('selectedPermission');
          //   console.log(selectedPermission);
          //   this.rolePermissionModel.roleId = this.roleAddModel.id;
          //   this.rolePermissionModel.permissionId = selectedPermission.id;
          //   console.log('save data');
          //   console.log(this.rolePermissionModel);
          //   this.permissionGroupsService.updateRolePermission(this.rolePermissionModel).subscribe((data:RolePermissionResponse)=>{  
          //     if(data.error && !data.success){
          //       //this.ngxService.stop();
          //       console.log('response2 data');
          //       console.log(this.data);
          //       this.toastr.error('Error on update permission group.');
          //     }
          //     else{
          //       this.ngxService.stop();
          //       this.toastr.success('Permission group updated successfully.');
          //       this.event.emit('OK');
          //       this.modalRef.hide();
          //     }      
          //     },err=>{   
          //       this.ngxService.stop();
          //       this.toastr.error('Error on update permission group.');
          //     });
          // }

          this.router.navigate(['permission-groups']);
          this.ngxService.stop();
          this.toastr.success('Permission group updated successfully.');
          this.event.emit('OK');
          this.modalRef.hide();
          
      }
      
      },err=>{ 
        this.ngxService.stop();  
        this.toastr.error('Error on update permission group.');
      });


    
    
    }
    
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

  openChangeModal(roleId, permissionId, permission): void {
    // roleData.isArchived = status;
    
    // this.permissionGroupsService.changePermission(roleId, permissionId, permission.checked).subscribe(()=>{     
      
    //   },
    //   (err: HttpErrorResponse)=>{
    //     //console.log("Error"+ err.message);
    //   });  
    
    //this.delete();
  }



  


}
