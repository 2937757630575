<div class="card">
    <!-- <div class="card-header profile_border">
        <div id="expand-box-header" class="clearfix"> -->
            <!-- <span style="float: left;">
                <h4 class="card-title">Family Tree</h4>
            </span> -->
            <!-- <span style="float: right; font-size: 12px; font-weight: bold;">
                <img style="width: 25px; height: 25px;" src="assets/img/men.svg"><span class="pr-1"
                    style="color: #2cbad3;">{{owner ? owner.firstName : '-'}}({{ownerAge ? ownerAge : '-'}}Y)</span>
                <img style="width: 25px; height: 25px;" src="assets/img/women.svg"><span
                    style="color: #d73753;">{{spouse ?  spouse.firstName : '-'}}({{spouseAge ? spouseAge : '-'}}Y)</span>
            </span> -->
        <!-- </div>
    </div> -->
    <div class="card-body">
        <section class="distribution-wr" [ngClass]="{'full-screen': isFullScreen}">
            <div  *ngIf="hasTree" class="graph-header row align-items-center m-0" [ngClass]="{'full-screen-padding': isFullScreen}">
                <div class="col-md-12 p-0">
                    <div class="d-flex align-items-center justify-content-end">
                        <button
                            class="screen-toggle-btn btn shadow-sm p-2 d-flex ml-3 align-items-center justify-content-center"
                            (click)="toggleScreen()">
                            <img *ngIf="!isFullScreen" src="assets/img/expand-square.svg" alt="Open Fullscreen">
                            <img *ngIf="isFullScreen" src="assets/img/collapse-square.svg" alt="Close Fullscreen">
                        </button>
                    </div>
                </div>
            </div>

            <div class="content-body" [class.show]="hasTree" [class.hide]="!hasTree">
                <div class="row justify-content-center">
                  <div class="col-md-9" [class.col-md-12]="isFullScreen">
                    <div class="graph-wr"[ngClass]="{'full-screen': isFullScreen}">
                      <div id="svg-container" class="svg-wr top-space-fix" [ngClass]="{'full-screen': isFullScreen}" [ngStyle]="{'height': containerHeight}"></div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="text-center" *ngIf="!hasTree">
              Family tree data not available
            </div>
        </section>
    </div>
</div>