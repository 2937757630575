
<div class="modal-header a_modal">
    <h4 class="modal-title" id="myModalLabel1">{{title}}</h4>
    <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
  </div>
  <form #f="ngForm" novalidate class="form" (ngSubmit)="onSubmit(f)" >
  
  <div class="modal-body dallecort_modal-body">
      <div class="row">
          <div class="col-md-12">
              
                  <div class="form-body">
                      <div class="row">
                          <div class="col-md-12" style="display: none" >
                              <div class="form-group">
                                  <label for="userId" class="c_n_fs">User*</label>
  
                                   <input id="userId" required  type="text"  class="form-control" name="userId" placeholder="" [(ngModel)]="invoiceModel.userId" #userId = "ngModel"> 
  
                                  <!-- <select required class="form-control" name="userId" [(ngModel)]='invoiceModel.userId' #userId = "ngModel">
                                      <option *ngFor="let client of clientList"  [value]="client.id">                                       
                                        <ng-container *ngIf="client.client != null">
                                            {{client.client.firstName}} {{client.client.lastName}}
                                          </ng-container>
                                          <ng-container *ngIf="client.client == null">
                                              {{client.userName}}
                                            </ng-container>
                                      </option>
                                  </select>
  
                                    <div class="form-group">
                                      <div *ngIf="userId.invalid && (userId.dirty || userId.touched)"
                                          class="alert alert-danger">
                                        <div *ngIf="userId.errors.required">
                                          Please select is required.
                                        </div>
                                        <div *ngIf="userId.errors.minlength">
                                          First name must be at least 4 characters long.
                                        </div>
                                        <div *ngIf="userId.errors.forbiddenName">
                                          First name cannot be Bob.
                                        </div>
                                      </div>
                                    </div> -->

                              </div>
                          </div>
                          
                          <div class="col-md-12">
                              <div class="form-group">
                                  <label for="invoiceNumber" class="c_n_fs">Invoice Number</label>
                                  <!-- <input type="text" id="invoiceNumber" class="form-control" placeholder="Invoice Number" name="invoiceNumber">  -->
                                  <input id="invoiceNumber" maxlength="15" required type="text"  class="form-control" name="invoiceNumber" placeholder="Invoice Number" [(ngModel)]="invoiceModel.invoiceNumber" #invoiceNumber = "ngModel">
                                  <div class="form-group">
                                    <div *ngIf="invoiceNumber.invalid && (invoiceNumber.dirty || invoiceNumber.touched)" class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                      <div *ngIf="invoiceNumber.errors.required">
                                        Invoice number is required.
                                      </div>  
                                      <div *ngIf="invoiceNumber.errors.maxlength">Invoice number max length must not greater than 15 characters.</div>
                                                
                                    </div>
                                  </div>
  
                              </div>
                          </div>

                          <div class="col-md-12">
                            <div class="form-group">
                                <label for="summary" class="c_n_fs">Description*</label>
                                <!-- <input type="text" id="summary" class="form-control" placeholder="Summary" name="summary"> -->
                                <input id="summary" maxlength="100" required  type="text"  class="form-control" name="summary" placeholder="Description" [(ngModel)]="invoiceModel.summary" #summary = "ngModel">

                                <div class="form-group">
                                  <div *ngIf="summary.invalid && (summary.dirty || summary.touched)"
                                  class="form_alert">
                                  <span  class="ft-alert-circle"></span>
                                    <div *ngIf="summary.errors.required">
                                      Description is required.
                                    </div>                                                  
                                  </div>
                                </div>

                            </div>
                        </div>

                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="amount" class="c_n_fs">Amount ($)*</label>
                                  <!-- <input type="number" id="amount" class="form-control" placeholder="Amount" name="amount">  -->
                                  <input id="amount" required  type="number" pattern="^[0-9]+$" class="form-control" name="amount" placeholder="Amount" [(ngModel)]="invoiceModel.amount" #amount = "ngModel">
                                  <div class="form-group">
                                    <div *ngIf="amount.invalid && (amount.dirty || amount.touched)"
                                    class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                      <div *ngIf="amount.errors.required">
                                        Amount is required.
                                      </div>   
                                      <div *ngIf="amount.errors.pattern">
                                          Amount must be a number (greater than 0 and not greater than 99999999999999999999) without decimals.
                                        </div>                                                
                                    </div>
                                  </div>
  
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="gst" class="c_n_fs">Gst ($)*</label>
                                  <!-- <input type="text" id="gst" class="form-control" placeholder="gst" name="Gst">  -->
                                  <input id="gst" required  type="number" pattern="^[0-9]+$" class="form-control" name="gst" placeholder="Gst" [(ngModel)]="invoiceModel.gst" #gst = "ngModel">
                                  <div class="form-group">
                                    <div *ngIf="gst.invalid && (gst.dirty || gst.touched)"
                                    class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                      <div *ngIf="gst.errors.required">
                                        Gst value is required.
                                      </div> 
                                      <div *ngIf="gst.errors.pattern">
                                          Gst must be a number (greater than 0 and not greater than 99999999999999999999) without decimals.
                                        </div>                                                 
                                    </div>
                                  </div>
  
                              </div>
                          </div>
                          
                          <div class="col-md-12">
                              <div class="form-group">
                                  <label for="dueDate" class="c_n_fs">Invoice Date *</label>
                                  <!-- <input type="date" id="dueDate" class="form-control" placeholder="Due Date" name="dueDate">  -->

                                  <div class="input-group">
                                      <!-- <input class="form-control" type="date" readonly="true" data-date-format="MM/d/yyyy" placeholder="yyyy-mm-dd"
                                             name="dueDate" ngbDatepicker [(ngModel)]="invoiceModel.dueDate" name="dueDate" #dueDate = "ngbDatepicker">
                                      <div class="input-group-append">                                     
                                        <button class="btn btn-block btn-outline-secondary"  type="button" (click)="dueDate.toggle()">
                                            <i class="ficon ft-clock"></i>
                                        </button>
                                      </div> -->
                                      <input type="hidden" required name="dueDate1" [(ngModel)]="dueDate2" #dueDate1="ngModel">
                                      <input [required]="true" readonly="true" placeholder="Invoice Date"
                                      class="form-control"
                                      name="dueDate" 
                                      (ngModelChange)="onChange($event)"
                                      [(ngModel)]="invoiceModel.dueDate"
                                      #dueDate="bsDatepicker" 
                                      placement="top"
                                      [bsValue]="currentDate"
                                      [minDate]="currentDate"
                                      bsDatepicker                                      
                                      [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }">
                                      <div class="input-group-append">                                     
                                          <button class="btn btn-block btn-outline-secondary" type="button"  (click)="dueDate.toggle()" [attr.aria-expanded]="dueDate.isOpen">
                                              <i class="ficon ft-clock"></i>
                                          </button>
                                        </div>

                                  </div>

                                  

                                  <!-- <input id="dueDate" required  type="date"  class="form-control" name="dueDate" placeholder="Due Date" [(ngModel)]="invoiceModel.dueDate" #dueDate = "ngModel"> -->
                                  <div class="form-group">
                                    <!-- <div *ngIf="dueDate.invalid && (dueDate.dirty || dueDate.touched)"
                                        class="alert alert-danger">
                                      <div *ngIf="dueDate.errors.required">
                                        Due date is required.
                                      </div>                                                  
                                    </div> -->
                                    <div *ngIf="f.submitted && dueDate1.invalid || (dueDate1.invalid && (dueDate1.dirty || dueDate1.touched)) "   class="form_alert">
                                            <span  class="ft-alert-circle"></span>
                                        <br/>
                                        <div *ngIf="dueDate1.errors.required">Appointment Date is required.</div>
                                          <!-- <div *ngIf="dueDate.invalid">Due date format is invalid.</div> -->
                                      </div>

                                    <div *ngIf="f.submitted && dueDate.invalid || (dueDate.invalid && (dueDate.dirty || dueDate.touched))"   class="form_alert">
                                            <span  class="ft-alert-circle"></span>
                                        <div *ngIf="dueDate.errors.required">Due date is required.</div>
                                        <div *ngIf="dueDate.invalid">Due date format is invalid.</div>
                                    </div>

                                  </div>
  
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group">
                                  <label for="status" class="c_n_fs">Status*</label>
                                  <!-- <input type="text" id="status" class="form-control" placeholder="Status" name="status">  -->
                                    
                                   <div class="input-group">
                                      <div class="d-inline-block custom-control custom-radio mr-1">
                                          <input required type="radio" class="custom-control-input invoice_radio_input" id="status1" name="status" [value]="1" [(ngModel)]="invoiceModel.status" #status = "ngModel" >
                                          <label class="custom-control-label invoice_radio" for="status1">Void</label>
                                      </div>
  
                                      <div class="d-inline-block custom-control custom-radio mr-1">
                                          <input required type="radio" class="custom-control-input invoice_radio_input" id="status2" name="status" [value]="2" [(ngModel)]="invoiceModel.status" #status = "ngModel">
                                          <label class="custom-control-label invoice_radio" for="status2">Unpaid</label>
                                      </div>
  
                                      <div class="d-inline-block custom-control custom-radio">
                                          <input required type="radio" class="custom-control-input invoice_radio_input" id="status3" name="status" [value]="3" [(ngModel)]="invoiceModel.status" #status = "ngModel">
                                          <label class="custom-control-label invoice_radio" for="status3">Paid</label>
                                      </div>
  
                                      <div class="form-group">
                                          <!-- <div *ngIf="status.invalid && (status.dirty || status.touched)"
                                              class="alert alert-danger">
                                            <div *ngIf="status.errors.required">
                                              Status is required.
                                            </div>                                          
                                          </div> -->

                                          <div *ngIf="f.submitted && status.invalid || (status.invalid && (status.dirty || status.touched)) "   class="form_alert">
                                                <span  class="ft-alert-circle"></span>
                                              <div *ngIf="status.errors.required">Status is required.</div>
                                          </div>

                                        </div>
                                  </div>
      
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group" style="padding-top: 4px;">
                                  <label for="status" class="c_n_fs"></label>
                                  <div class="input-group">
                                      <!-- <div class="d-inline-block custom-control custom-radio mr-1">
                                          <label>
                                            <input id="IsRecurringEnabled" class="switchery-sm" type="checkbox" data-toggle="toggle" name="IsRecurringEnabled" [(ngModel)]="invoiceModel.IsRecurringEnabled" #IsRecurringEnabled = "ngModel">
                                            Recurring invoice
                                          </label>
                                      </div> -->

                                      <label class="container"> 
                                        <input id="IsRecurringEnabled" name="IsRecurringEnabled" type="checkbox" [(ngModel)]="invoiceModel.IsRecurringEnabled" #IsRecurringEnabled = "ngModel"  data-md-icheck (change)="toggleVisibility($event)"  class="check_mt"/>Enable Recurring Invoice (Monthly)
                                        <!-- <span *ngIf="marked">checked</span>
                                        <span *ngIf="!marked">unchecked</span> -->
                                        <span class="checkmark"></span>
                                      </label>
                                        
                                  </div>
  
                                  <!-- <div class="form-group">
                                      <div *ngIf="f.submitted && IsRecurringEnabled.invalid || (IsRecurringEnabled.invalid && (IsRecurringEnabled.dirty || IsRecurringEnabled.touched)) "   class="form_alert">
                                              <span  class="ft-alert-circle"></span>
                                          <div *ngIf="IsRecurringEnabled.errors.required">Recurring is required.</div>
                                      </div>
                                  </div> -->
                              </div>
                          </div>
                          <!-- <div class="col-md-12">
                              <div class="form-group">
                                  <label for="fortressTransactionId" class="c_n_fs">Transaction Id</label>
                                  <input id="fortressTransactionId"  type="text"  class="form-control" name="fortressTransactionId" placeholder="" [(ngModel)]="invoiceModel.fortressTransactionId" #fortressTransactionId = "ngModel">
                                  <div class="form-group">
                                    <div *ngIf="fortressTransactionId.invalid && (fortressTransactionId.dirty || fortressTransactionId.touched)"
                                        class="alert alert-danger">
                                      <div *ngIf="fortressTransactionId.errors.required">
                                        Transacton id is required.
                                      </div>                                                  
                                    </div>
                                  </div>
  
                              </div>
                          </div> -->
                          
                          <!-- <div class="col-md-12">
                              <div class="form-group">
                                  <label for="documentURL" class="c_n_fs">Document Url</label>
                                  
                                  <input id="documentURL" type="text"  class="form-control" name="documentURL" placeholder="https://" [(ngModel)]="invoiceModel.documentURL" #documentURL = "ngModel">
                                  <div class="form-group">
                                    <div *ngIf="documentURL.invalid && (documentURL.dirty || documentURL.touched)"
                                        class="alert alert-danger">
                                      <div *ngIf="documentURL.errors.required">
                                        Document url is required.
                                      </div>                                                  
                                    </div>
                                  </div>
  
                              </div>
                          </div> -->
                          
                          
                      </div>
                  </div>
              
          </div>
      </div>
  </div>
  <div class="modal-footer a-footer">
      <div *ngIf="!Isvalid"   class="form_alert">
            <span  class="ft-alert-circle"></span>
          Please fill in all the required fields correctly.
      </div>
      <div *ngIf="errors"   class="form_alert">
            <span  class="ft-alert-circle"></span>
          {{errors}}
      </div>
      <button type="submit" class="btn popup_save_b">Save</button>
  </div>
  </form>     
  <ngx-ui-loader ></ngx-ui-loader>
