import { Component, OnInit, EventEmitter } from '@angular/core';
import { InvoiceAddModel } from '../../shared/models/invoice.model' 
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { InvoicesService } from '../invoices.service';
import { InvoiceResponse, ClentResponsex, TransactionResponse, ClentResponsexNew } from 'src/app/shared/models/response.model';
import { Utility } from 'src/app/utility/utility';
import { ClientsService } from 'src/app/clients/clients.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ClientViewModel } from 'src/app/shared/models/client.model';
import { InvoiceType } from 'src/app/shared/models/db-enums.model';
import { TransactionViewModel } from 'src/app/shared/models/transaction.model';
import { first } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-edit-invoice',
  templateUrl: './edit-invoice.component.html',
  styleUrls: ['./edit-invoice.component.css']
})
export class EditInvoiceComponent implements OnInit {
  invoiceModel : InvoiceAddModel ;
  title;
  data;
  errors;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  maxdate :any;
  Isvalid:boolean;

  //public utility: utility;
  clientList:ClientViewModel[];
  transactionList:TransactionViewModel[];
  status = InvoiceType;
  currentDate = new Date();
  dueDate2 :any;
  dueDate3 :any;
  marked = false;
  
  constructor(private ngxService: NgxUiLoaderService, private clientsService: ClientsService,private formBuilder: FormBuilder,private router: Router, private invoiceService: InvoicesService,public modalRef: BsModalRef,private toastr: ToastrService) { }

  ngOnInit() {
    this.Isvalid = true;
      this.invoiceModel = new InvoiceAddModel();
    //console.log(this.modalRef.content);

      if(this.data.invoice !=null){
        this.dueDate2 = this.data.invoice.dueDate;
        // console.log(this.data.invoice);
        // console.log(this.data.invoice.dueDate);
        this.dueDate3 = this.FormatDate(this.data.invoice.dueDate);
        
        //console.log(this.data.invoice);
        Object.assign(this.invoiceModel, this.data.invoice);
        this.invoiceModel.amount = this.invoiceModel.amount/100;
        this.invoiceModel.gst = this.invoiceModel.gst/100;
        this.invoiceModel.dueDate = this.dueDate3;
        
        if(this.invoiceModel.isRecurringEnabled) {
          this.invoiceModel.IsRecurringEnabled = true;
        }
        else{
          this.invoiceModel.IsRecurringEnabled = false;
        }
        
        //console.log(this.invoiceModel);
      }

      //this.getClientList();
    }

onSubmit(form:NgForm){
  
if(form.invalid) {

  if(form.pristine){
  }
  this.Isvalid = false;
}

else{
  if(this.data.invoice !=null){
    this.ngxService.start();
    //console.log("Edditre ============");
    //console.log(this.invoiceModel);
    //console.log("Edditre -----------");

    this.invoiceModel.dueDate = this.dueDate2;
    this.invoiceService.invoiceEdit(this.data.invoice.id,this.invoiceModel).pipe(first())
    .subscribe((data:InvoiceResponse)=>{
      this.ngxService.stop();
      if(data.error && !data.success){
        //this.errors = Utility.GetLocalErrorString(data.error.errorCode);
        this.toastr.error('Error on updating invoice.');

        // if(data.error.message){
        //   this.errors = data.error.message;
        // }
        // else{
        //   this.errors = Utility.GetLocalErrorString(data.error.errorCode);
        // }

        //console.log(this.errors);
      }
      else{
        this.toastr.success('Invoice updated successfully.');
        this.event.emit('OK');
        this.modalRef.hide();
      }

      },err=>{  
        this.ngxService.stop(); 
        this.toastr.error('Error on updating invoice.');
      });
      
      //this.router.navigate(['invoices']);
      
  }
}

}

getClientList(){
  this.clientsService.clientList().subscribe((data:ClentResponsexNew)=>{     
    this.clientList = data.response.data;      
    //console.log('Client list for invoices ---------');
    //console.log(this.clientList);
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });      
}

modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

onChange(newVal){
  //console.log(123456);
  this.dueDate2 = newVal;
}

FormatDate(iDate: Date) {
  var inputDate = new Date(iDate);
  //var formattedDate = inputDate.getFullYear()+'-'+ (inputDate.getMonth() + 1)+'-'+ inputDate.getDate();

  var datePipe = new DatePipe("en-US");
  let formattedDate = datePipe.transform(inputDate, 'dd/MM/yyyy');
  return formattedDate;
}

toggleVisibility(e){
  this.marked= e.target.checked;
}


}
