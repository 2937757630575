export class XFacLiabilityBaseMOdel {
    public  facFinderId : string;
    public  type : string;
    public  productName : string;
    public  policyNumber : string;
    public  status : string;
    public  outstandingBalanceValue : number;
    public  outstandingBalanceCode : string;
    public  repaymentAmountValue : number;
    public  repaymentAmountCode : string;
    public  repaymentFrequency : number;
    public  interestRatePerAnnum : number;
    public  interestRateType : string;
    public  startDate : Date;
    public  endDate : Date;
    public  outstandingBalanceLastUpdatedOn : string;
    public  ownerClientId : string;
    public  ownerName : string;
    public  clientCode : string;
    
    public  isArchived : boolean;
    public  lastUpdatedDate : Date;
    public  facFinderViewModel : string;
    public  subType : string;
    public  interestRate : number;
    public  lenderSupplier : string;
    public  owner : string;

    public typeName : string;
    public subTypeName : string;
    public ownerTypeName : string;
    public interestRateTypeName : string;
    public lenderSupplierName : string;
    public repaymentFrequencyName  : string;
}

export class XFacLiabilityAddModel extends XFacLiabilityBaseMOdel{    
       
}