<div class="modal-header a_modal">
    <h4 class="modal-title" id="myModalLabel1">User History</h4>
    <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
  </div>
  
  
  <div class="card-content mt-1">
    <div class="table-responsive scroll-tbl_appointment">
        <table id="recent-orders"
            class="table table-hover table-xl mb-0 gp_app_table">
            <tbody>
                <ng-container *ngIf="isLoaded">
                    <tr *ngFor="let loginHistory of loginHistoryData; let i = index">
                        <td class="gp_app_com">
                            <span class="">
                                <ng-container *ngIf="loginHistory.operatingSystem == 1">
                                    <img class="avatar_img_h" src="assets/img/iphone.svg" alt="avatar" />
                                </ng-container>
                                <ng-container *ngIf="loginHistory.operatingSystem == 2">
                                    <img src="assets/img/andr.svg" alt="avatar">
                                </ng-container>
                            </span>
                        </td>
                        <td class="text-truncate">
                            <span class="bord_line appoint_date" style="display: block; border-width: inherit;">
                                <ng-container *ngIf="loginHistory.operatingSystem == 1">IOS</ng-container>
                                <ng-container *ngIf="loginHistory.operatingSystem == 2">Android</ng-container>
                                <ng-container *ngIf="loginHistory.operatingSystem == 3">Web</ng-container>
                                - {{loginHistory.deviceModel}}
                            </span>
                            <span class="appoint_time" style="display: block; padding-left: 0px !important; border-width: inherit;">
                                App {{loginHistory.version}} 
                                <ng-container *ngIf="loginHistory.lastActiveDate != '0001-01-01T00:00:00'">
                                    {{loginHistory.lastActiveDate + 'Z' | date:'dd/MM/yyyy hh:mm a' : loginHistory.timeZone}} 
                                </ng-container>
                                <ng-container *ngIf="loginHistory.lastActiveDate == '0001-01-01T00:00:00'">
                                    {{loginHistory.loginDate + 'Z' | date:'dd/MM/yyyy hh:mm a' : loginHistory.timeZone}} 
                                </ng-container>
                                <ng-container *ngIf="loginHistory.timeZone">
                                    ({{loginHistory.lastActiveDate + loginHistory.timeZone | date:'ZZZZ' : loginHistory.timeZone}})
                                </ng-container>
                            </span>
                        </td>
                        
                    </tr>
                </ng-container>
                <tr *ngIf="!isLoaded">
                    <td>Loading...</td>
                </tr>

            </tbody>
        </table>
    </div>
</div>
  