import { InvoiceType } from "./db-enums.model";

export class InvoiceBaseMOdel {
    public  dueDate?: any;
    public  status : InvoiceType;
    public  summary : string;
    //public  userId : string;
    public  fortressTransactionId : string;
    public  gst : number;
    public  documentURL : string;
    public  amount : number;
    public  invoiceNumber : string;
    public IsRecurringEnabled : boolean; 
}

export class InvoiceAddModel extends InvoiceBaseMOdel{
  isRecurringEnabled(isRecurringEnabled: any) {
    throw new Error("Method not implemented.");
  }
  isArchived(isArchived: any) {
    throw new Error("Method not implemented.");
  }    
    public  userId : string;    
}