export class XFacRetirementIncomeBaseMOdel {
    public  facFinderId : string;
    public  retirementPlan : string;
    public  referenceNumber : string;
    public  pensionBalance : string;
    public  retirementPlanSelection : string;
    public  clientCode : string;
    public  retirementPlanName : string;
    public  retirementPlanSelectionName : string;
    public  pensionBalanceCode : string;
}

export class XFacRetirementIncomeAddModel extends XFacRetirementIncomeBaseMOdel{    
       
}