import { ViewChild, ElementRef, Output, Input, EventEmitter, Renderer2, Component, OnInit, ɵConsole } from '@angular/core';
import { Participant, RemoteTrack, RemoteAudioTrack, RemoteVideoTrack, RemoteTrackPublication,createLocalVideoTrack, Room, LocalTrack, LocalVideoTrack, LocalAudioTrack, RemoteParticipant } from 'twilio-video';
import { CallService } from './call.service';
import { first } from 'rxjs/operators';
import { ParticipantsComponent } from '../participants/participants.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

// import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
// import { AppointmentBaseModel,AppointmentAddModel } from '../../shared/models/appointment.model';
// import { Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
// import { HttpErrorResponse } from '@angular/common/http';
// import { AppointmentResponse, ClentResponsex, AgentResponse } from '../../shared/models/response.model';
// import { Utility } from '../../utility/utility';
// import { ClientsService } from 'src/app/clients/clients.service';
// import { AgentsService } from 'src/app/agents/agents.service';
// import { AgentViewModel } from 'src/app/shared/models/agent.model';
// import { ClientViewModel } from 'src/app/shared/models/client.model';
// import * as moment from 'moment'

@Component({
  selector: 'app-call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.css']
})
export class CallComponent implements OnInit {

  @ViewChild('list', { static: true }) listRef: ElementRef;
  //@ViewChild('participants') participants: ParticipantsComponent;
  @ViewChild('preview', { static: true }) previewElement: ElementRef;
  private participants: Map<Participant.SID, RemoteParticipant>;
  private dominantSpeaker: RemoteParticipant;
  private event: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private videoChatService: CallService,
    public modalRef: BsModalRef,
    private readonly renderer: Renderer2
    ) { }

  roomName: string;
  activeRoom: Room;
  data;
  appointment : any;
  hidePreview : boolean = false;
  mutemic : boolean = true;
  muteVideo : boolean = true; 
  

  ngOnInit() {    
    //await this.rooms.updateRooms(); 
    //console.log(3333333333333);
    //console.log(this.modalRef.content);

    //console.log(this.data.appointment);
    if(this.data.appointment !=null){
      //console.log(2222222);
      this.appointment = this.data.appointment;
      //console.log(this.data.appointment); 
      //this.onAddRoom(this.data.appointment); 
    }
  }

  onTryAddRoom() {
    if (this.roomName) {
        this.onAddRoom();
    }
  }

  onAddRoom() {
      //this.roomName = null;
      //console.log(roomName);
     // this.videoChatService.getAuthToken(roomName);

     this.videoChatService.createVideoCallToken(this.appointment).pipe(first())
      .subscribe(
        data => {
          if(data.success){
         
           let twilio_token:string = JSON.parse( localStorage.getItem('twilio_token'));
           let roomName = data.response.twilioRoom.unique_name;
           this.roomName = roomName;

          //console.log(11111111);
          //console.log(twilio_token);
           this.videoChatService.joinRoom(twilio_token, roomName).then(value => {
            this.activeRoom = value;
            //this.participants.initialize(this.activeRoom.participants);
            this.attachLocalVideo();
            this.registerRoomEvents();
            this.attachExistingParticipants(this.activeRoom);
           });

          }else{
           //// this.loading =false;
           // this.toastr.error(Utility.GetLocalErrorString(data.errorCode));
          }
        },
        error => {
          //this.alertService.error(error);
          //console.log(error);
        }        
      );

  }

  joinRoom(roomName: string) {
    //this.roomName = null;
    //console.log(roomName);
   // this.videoChatService.getAuthToken(roomName);

   this.videoChatService.getVideoCallToken(roomName).pipe(first())
    .subscribe(
      data => {
        if(data.success){
          let twilio_token:string = JSON.parse( localStorage.getItem('twilio_token'));
          //let token = data.token;
          // let tracks = LocalTrack;
          //this.videoChatService.joinRoom(twilio_token, roomName); 
         
          this.videoChatService.joinRoom(twilio_token, roomName).then(value => {
            this.activeRoom = value;
            //console.log(value.name);
            //this.participants.initialize(this.activeRoom.participants);
            this.attachLocalVideo();
            this.registerRoomEvents();
            this.attachExistingParticipants(this.activeRoom);
           });

         // this.router.navigate(['/send_code'] );
        }else{
         //// this.loading =false;
         // this.toastr.error(Utility.GetLocalErrorString(data.errorCode));
        }
      },
      error => {
        //this.alertService.error(error);
        //console.log(error);
      }        
    );

}

async onLeaveRoom(_: boolean) {
  if (this.activeRoom) {
      this.activeRoom.disconnect();
      this.activeRoom = null;
  }

  this.participants.clear();
}

private registerRoomEvents() {
  this.activeRoom
      .on('disconnected',
          (room: Room) => room.localParticipant.tracks.forEach(publication => this.detachLocalTrack(publication.track)))
      .on('participantConnected',
          //(participant: RemoteParticipant) => this.participants.add(participant))
          (participant: RemoteParticipant) => this.attachRemoteParticipant(participant))
      .on('participantDisconnected',
          (participant: RemoteParticipant) => this.remove(participant))
      .on('dominantSpeakerChanged',
          (dominantSpeaker: RemoteParticipant) => this.loudest(dominantSpeaker));
}

private detachLocalTrack(track: LocalTrack) {
  if (this.isDetachable(track)) {
      track.detach().forEach(el => el.remove());
  }
}

private isDetachable(track: LocalTrack): track is LocalAudioTrack | LocalVideoTrack {
  return !!track
      && ((track as LocalAudioTrack).detach !== undefined
      || (track as LocalVideoTrack).detach !== undefined);
}

private attachExistingParticipants(room){
  room.participants.forEach(participant => {
    this.attachRemoteParticipant(participant);
  });
}

private attachRemoteParticipant(participant: RemoteParticipant) {
  setTimeout(() => {
    console.log(9999999666666, this.listRef);
  }, 1000);
  console.log(33333333);
  const childElements = this.listRef.nativeElement.childNodes;
    for (let child of childElements) {
      this.renderer.removeChild(this.listRef.nativeElement, child);
    }

  participant.tracks.forEach(publication => {
    if (publication.isSubscribed) {
      const track = publication.track;
      //document.getElementById('remote-media-div').appendChild(track.attach());
      //console.log(77777);
      const element = track.attach();
          this.renderer.data.id = track.sid;
          this.renderer.setStyle(element, 'width', '25%');
          this.renderer.setStyle(element, 'height', '25%');
          this.renderer.setStyle(element, 'margin-left', '2.5%');
          //console.log(12121212);
          this.renderer.appendChild(this.listRef.nativeElement, element);
         // this.participants.set(participant.sid, participant);
    }
  });

  participant.on('trackSubscribed', track => {
    const element = track.attach();
    this.renderer.data.id = track.sid;
    this.renderer.setStyle(element, 'width', '484px');
    this.renderer.setStyle(element, 'height', '550px');
    this.renderer.setStyle(element, 'margin-left', '90px');
    this.renderer.addClass(element, 'mmm');  
    //console.log(131313);
    this.renderer.appendChild(this.listRef.nativeElement, element);
    this.hidePreview = true;
    //this.participants.set(participant.sid, participant);
    //document.getElementById('remote-media-div').appendChild(track.attach());
  });
}

private attachLocalVideo() {
  const childElements = this.previewElement.nativeElement.childNodes;
    for (let child of childElements) {
      this.renderer.removeChild(this.previewElement.nativeElement, child);
    }

  createLocalVideoTrack().then(track => {

    const videoElement = track.attach();
    this.renderer.setStyle(videoElement, 'height', '130px');
            this.renderer.setStyle(videoElement, 'width', '290px');
            this.renderer.addClass(videoElement, 'nnn');

            this.renderer.appendChild(this.previewElement.nativeElement, videoElement);
    
  });
}

toggleVideo(){
  console.log("toggle video clicked ==== ", this.activeRoom.localParticipant);
  var localParticipant = this.activeRoom.localParticipant;

  if(this.muteVideo){
    localParticipant.videoTracks.forEach(function (videoTrack) {
      videoTrack.disable();
   });
    this.muteVideo = false;
  }
  else{
    localParticipant.videoTracks.forEach(function (videoTrack) {
      videoTrack.enable();
    });
    this.muteVideo = true;
  }
  
}

toggleMic(){ 
  // console.log("toggle mic clicked ==== ");
  var localMedia = this.activeRoom.localParticipant;

    if(this.mutemic){
      localMedia.audioTracks.forEach(function (audioTrack) {
        audioTrack.disable();
     });
      this.mutemic = false;
    }
    else{
      localMedia.audioTracks.forEach(function (audioTrack) {
        audioTrack.enable();
     });
      this.mutemic = true;
    }

  // localMedia.tracks.forEach(function (track) {
  //   if (track.isEnabled) {
  //     track.disable();
  //     this.mutemic = true;
  //   } else {
  //     track.enable();
  //     this.mutemic = false;
  //   }
  // });
}

disconnectCall(){
  this.videoChatService.disconnectCall(this.appointment).pipe(first()).subscribe(
    data => {
      if(data.success){    
      }else{
      }
    },
    error => {
      //console.log(error);
    }        
  );
  
  this.activeRoom.localParticipant.tracks.forEach(publication => this.detachLocalTrack(publication.track));

  // this.activeRoom.on('disconnected', room => {
  //   // Detach the local media elements
  //   room.localParticipant.tracks.forEach(publication => {
  //     const attachedElements = publication.track.detach();
  //     attachedElements.forEach(element => element.remove());
  //   });
  // });
 
  this.activeRoom.disconnect();

    const previewEle = this.previewElement.nativeElement.childNodes;
    for (let child of previewEle) {
      this.renderer.removeChild(this.previewElement.nativeElement, child);
    }

    const childElements = this.listRef.nativeElement.childNodes;
    for (let child of childElements) {
      this.renderer.removeChild(this.listRef.nativeElement, child);
    } 
    //console.log(123456);
    
    this.event.emit('call_disconnected');
    //this.router.navigate(['/appointments']);
  //this.participants.clear();
}

remove(participant: RemoteParticipant) {
  const childElements = this.listRef.nativeElement.childNodes;
  for (let child of childElements) {
    this.renderer.removeChild(this.listRef.nativeElement, child);
  }
  this.event.emit('call_disconnected');
}

loudest(participant: RemoteParticipant) {
  this.dominantSpeaker = participant;
}

onParticipantsChanged(_: boolean) {
  this.videoChatService.nudge();
}

close(){
  this.modalRef.hide();
}



}
