
            <div class="modal-header a_modal">
                <h4 class="modal-title" id="myModalLabel1">Edit Permission Group</h4>
                <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
            </div>

            <form #f="ngForm" novalidate class="form" (ngSubmit)="onSubmit(f)" >
                <div class="modal-body dallecort_modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-body">
                                
                                <div class="row add_agent_ga" style="margin-top: 5px;">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="projectinput1" class="c_n_fs">Group Name *</label>
                                            <input autocomplete="off" type="text" maxlength="100" autocomplete="none" required class="form-control" id="name" name="name" placeholder="Group name" [(ngModel)]="roleAddModel.name" #name = "ngModel" disabled>
                                            <div class="form-group">
                                              <div *ngIf="name.invalid && (name.dirty || name.touched)" class="form_alert">
                                                <span  class="ft-alert-circle"></span>
                                                    <div *ngIf="name.errors.required">
                                                      Group Name is required.
                                                    </div>                                                  
                                              </div>
                                            </div>
                                        </div>
                                    </div> 
                                    
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="projectinput1" class="c_n_fs">Group Description *</label>
                                            <input autocomplete="off" type="text" maxlength="100" autocomplete="none" required class="form-control" id="description" name="description" placeholder="Group Description" [(ngModel)]="roleAddModel.description" #description = "ngModel">
                                            <div class="form-group">
                                              <div *ngIf="description.invalid && (description.dirty || description.touched)" class="form_alert">
                                                <span  class="ft-alert-circle"></span>
                                                    <div *ngIf="description.errors.required">
                                                      Group Description is required.
                                                    </div>                                                  
                                              </div>
                                            </div>
                                          </div>
                                    </div>

                                    <!-- <span class="add_agent_g ml-1 mb-1 mt-2">Group Permissions</span>                                          
                                    
                                    <ng-container *ngFor="let permission of permissionList; let i = index">
                                        <div class="col-md-12">
                                            <div class="form-group mb-0" style="padding-top: 4px;">
                                                <label for="permission{{permission.id}}" class="c_n_fs"></label>
                                                <label class="container"> 
                                                    <input type="checkbox" class="check_mt" id="permission{{permission.id}}" name="permission{{permission.id}}" [(ngModel)]="permission.checked" #id = "ngModel" (click)="openChangeModal(roleAddModel.id, permission.id, permission)"/>{{permission.description }}
                                                    <span class="checkmark" style="top: 4px; left: 15px;"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </ng-container> -->
                                    <!-- <div class="row">
                                        <table class="table table-striped table-bordered">
                                                <tr>
                                                <td width=20%>SKU:</td>
                                                <td width=80%>PM432523</td>
                                                </tr>
                                                <tr>
                                                <td>Supplier:</td>
                                                <td>Acme Meat Inc</td>
                                                </tr>
                                            </table>
                                    </div> -->

                                    <div class="col-md-12">
                                        <table class="table tabele-responsive" cellspacing="0">
                                            <thead>
                                                <tr>
                                                    <th class="pl-0 bordercolor" style="width: 40%">Permissions</th>
                                                    <th class="pl-0 bordercolor " style="width: 60%">Permission Allow To</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let permission of permissionList; let i = index">
                                                <tr *ngIf="isLoaded">
                                                    <td class="pl-0 bordercolor padd_topbot" style="font-size: 13px;">{{permission.description}}</td>
                                                    <td class="pl-0 bordercolor padd_topbot">
                                                            <div class="form-group" style="margin-bottom: 0px !important;">
                                                                    <!-- <label for="name">Skills</label> -->
                                                                <app-permission-list [roleId]="roleAddModel.id" [permission]="permission" [selectedPermissions]="getPermissionByRole" ></app-permission-list>    
                                                                    
                                                                </div>
                                                        
                                                    </td>
                                                </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer a-footer">
                    <button type="submit" class="btn popup_save_b">Save</button>
                </div>
            </form>

            <ngx-ui-loader ></ngx-ui-loader>
        