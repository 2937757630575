import { Injectable } from '@angular/core';
import { Response, XFacPersonalInfoResponse, FactFinderNewResponse, ClentProfileResponse, XFacContactResponse, XFacAddressResponse, XFacDependentResponse, XFacEmploymentResponse, XFacInsuranceLifeResponse, XFacGoalResponse, XFacIncomeResponse, XFacExpensesResponse, XFacEstatePlanningResponse, XFacAssetResponse, XFacLiabilityResponse, XplanChoicesResponse, XPlanCoverResponse, XFacRetirementIncomeResponse, XFacInsuranceMedicalResponse, XFacVersionResponse, XFacStatusListResponse} from '../shared/models/response.model';
//import { Response, XFacPersonalInfoResponse, FactFinderNewResponse, ClentProfileResponse, XFacContactResponse, XFacAddressResponse, XFacDependentResponse, XFacEmploymentResponse, XFacEstatePlanningResponse} from '../shared/models/response.model';
import { HttpClient, HttpParams, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppConstants } from '../utility/app-constants';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class FacFinderService {
  baseUrl: String;
  authUrl: String;
  clientAuthUrl: String;

  constructor(
    private http:HttpClient,
    private cookieService: CookieService
    ) { 
    this.baseUrl = AppConstants.getBaseURL();
    this.authUrl = AppConstants.getAuthURL();
    this.clientAuthUrl = AppConstants.getClientAuthURL();
  }

  public handleError(error) {
    return throwError(error);
  }

  public getFacFinder(clientId){
    //const params = new HttpParams().set('id', clientId).set('goalType', '3');
    return this.http.get<FactFinderNewResponse>(this.baseUrl+'/FacFinder/GetByUserId?UserId='+clientId).pipe(
      catchError(this.handleError)
    );   
  }

  public getChoiceList(){    
    return this.http.get<XplanChoicesResponse>(this.baseUrl+'/XFacChoices').pipe(
      catchError(this.handleError)
    );
  }

  public getClient(id:string)
  {
    //console.log("client service")
    //console.log(id);
    const params = new HttpParams().set('id',id);
    return this.http.get<ClentProfileResponse>(this.clientAuthUrl+'/User',{params});
  }

  public getPersonalInfo(finderId :string)
  {
    //console.log("client service")
    //console.log(id);
    const params = new HttpParams().set('finderId',finderId );
    return this.http.get<XFacPersonalInfoResponse>(this.baseUrl+'/XFacPersonalInfo/getByFacFinderId',{params});
  }

  public savePersonalInfo(personalInfo,finderId :string){
    //console.log(personalInfo);
    const params = new HttpParams().set('id',personalInfo.id );
    personalInfo.facFinderId = finderId;
    return this.http.put<XFacPersonalInfoResponse>(this.baseUrl +'/XFacPersonalInfo', personalInfo,{params}).pipe(
      catchError(this.handleError)
    ); 
  }

  public getContact(finderId :string)
  {
    //console.log("client service")
    //console.log(id);
    const params = new HttpParams().set('facfinderId',finderId );
    return this.http.get<XFacContactResponse>(this.baseUrl+'/XFacContact/getByFacFinderId',{params});
  }

  public getAddress(finderId :string)
  {
    //console.log("client service")
    //console.log(id);
    const params = new HttpParams().set('finderId',finderId );
    return this.http.get<XFacAddressResponse>(this.baseUrl+'/XFacAddress/getByFacFinderId',{params});
  }
  
  public getDependent(finderId :string)
  {
    //console.log("client service")
    //console.log(id);
    const params = new HttpParams().set('finderId',finderId );
    return this.http.get<XFacDependentResponse>(this.baseUrl+'/XFacDependent/getByFacFinderId',{params});
  }

  public getEmploymen(finderId :string)
  {
    //console.log("client service")
    //console.log(id);
    const params = new HttpParams().set('finderId',finderId );
    return this.http.get<XFacEmploymentResponse>(this.baseUrl+'/XFacEmployment/getByFacFinderId',{params});
  }

  public getEstatePlanning(finderId :string)
  {
    //console.log("client service")
    //console.log(id);
    const params = new HttpParams().set('FacFinderId',finderId );
    return this.http.get<XFacEstatePlanningResponse>(this.baseUrl+'/XFacEstatePlanning/getByFacFinderId',{params});
  }

  public getAsset(finderId :string)
  {
    //console.log("client service")
    //console.log(id);
    const params = new HttpParams().set('FacFinderId',finderId );
    return this.http.get<XFacAssetResponse>(this.baseUrl+'/XFacAsset/getByFacFinderId',{params});
  }

  public getLiability(finderId :string)
  {
    //console.log("client service")
    //console.log(id);
    const params = new HttpParams().set('facFinderId',finderId );
    return this.http.get<XFacLiabilityResponse>(this.baseUrl+'/XFacLiability/getByFacFinderId',{params});
  }

  public getIncome(finderId :string)
  {
    const params = new HttpParams().set('FacFinderId',finderId );
    return this.http.get<XFacIncomeResponse>(this.baseUrl+'/XFacIncome/getByFacFinderId',{params});
  }

  public getExpenses(finderId :string)
  {
    const params = new HttpParams().set('id',finderId );
    return this.http.get<XFacExpensesResponse>(this.baseUrl+'/XFacExpenses/getByFacFinderId',{params});
  }

  public getGoals(finderId :string)
  {
    const params = new HttpParams().set('id',finderId );
    return this.http.get<XFacGoalResponse>(this.baseUrl+'/XFacGoal/getByFacFinderId',{params});
  }

  public getInsurance(finderId :string)
  {
    const params = new HttpParams().set('facFinderId',finderId );
    return this.http.get<XFacInsuranceLifeResponse>(this.baseUrl+'/XFacInsurance/getByFacFinderId',{params});
  }

  public getRetirementIncome(finderId :string)
  {
    const params = new HttpParams().set('facfinderId',finderId );
    return this.http.get<XFacRetirementIncomeResponse>(this.baseUrl+'/XFacRetirementIncome/getByFacFinderId',{params});
  }

  public getInsuranceMedical(finderId :string)
  {
    const params = new HttpParams().set('facFinderId',finderId );
    return this.http.get<XFacInsuranceMedicalResponse>(this.baseUrl+'/XFacInsuranceMedical/getByFacFinderId',{params});
  }

  public addContact(contactAddModel, facFinderId, clientCode){
    const params = new HttpParams().set('id',facFinderId ); 
    contactAddModel.facFinderId = facFinderId;
    contactAddModel.clientCode = clientCode;
    return this.http.post<XFacContactResponse>(this.baseUrl +'/XFacContact', contactAddModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public editContact(contactAddModel, facFinderId, clientCode){
    const params = new HttpParams().set('id',contactAddModel.id ); 
    contactAddModel.facFinderId = facFinderId;
    contactAddModel.clientCode = clientCode;
    return this.http.put<XFacContactResponse>(this.baseUrl +'/XFacContact', contactAddModel,{params}).pipe(
      catchError(this.handleError)
    ); 
  }

  public editAddress(addressAddModel, facFinderId){
    const params = new HttpParams().set('xFacAddressId', addressAddModel.id ); 
    addressAddModel.facFinderId = facFinderId;
    //addressAddModel.clientCode = clientCode;
    return this.http.put<XFacAddressResponse>(this.baseUrl +'/XFacAddress', addressAddModel,{params}).pipe(
      catchError(this.handleError)
    ); 
  }

  public addAddress(addressAddModel, facFinderId){
    //const params = new HttpParams().set('id',facFinderId ); 
    addressAddModel.facFinderId = facFinderId;
    //contactAddModel.clientCode = clientCode;
    return this.http.post<XFacAddressResponse>(this.baseUrl +'/XFacAddress', addressAddModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public addDependant(dependantAddModel, facFinderId){
    //const params = new HttpParams().set('id',facFinderId ); 
    dependantAddModel.facFinderId = facFinderId;
    //contactAddModel.clientCode = clientCode;
    return this.http.post<XFacDependentResponse>(this.baseUrl +'/XFacDependent', dependantAddModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public editDependant(dependantAddModel, facFinderId){
    const params = new HttpParams().set('id', dependantAddModel.id );
    
    let depEdit = {
      firstName : dependantAddModel.firstName,
      surname : dependantAddModel.surname,
      gender : dependantAddModel.gender,
      dateOfBirth : dependantAddModel.dateOfBirth
    }
    dependantAddModel.facFinderId = facFinderId;
    return this.http.put<XFacDependentResponse>(this.baseUrl +'/XFacDependent', depEdit,{params}).pipe(
      catchError(this.handleError)
    ); 
  }

  public addEmployment(employmentAddModel, facFinderId){
    //const params = new HttpParams().set('id',facFinderId ); 
    employmentAddModel.facFinderId = facFinderId;
    //employmentAddModel.clientCode = clientCode;
    return this.http.post<XFacEmploymentResponse>(this.baseUrl +'/XFacEmployment', employmentAddModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public editEmployment(employmentAddModel, facFinderId){
    const params = new HttpParams().set('id',employmentAddModel.id ); 
    employmentAddModel.facFinderId = facFinderId;
    //employmentAddModel.clientCode = clientCode;
    return this.http.put<XFacEmploymentResponse>(this.baseUrl +'/XFacEmployment', employmentAddModel, {params}).pipe(
      catchError(this.handleError)
    ); 
  }

  public addEstatePlanning(estatePlanningModel, facFinderId){
    estatePlanningModel.facFinderId = facFinderId;
    //estatePlanningModel.clientCode = clientCode;
    return this.http.post<XFacEstatePlanningResponse>(this.baseUrl +'/XFacEstatePlanning', estatePlanningModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public editEstatePlanning(estatePlanningModel, facFinderId){
    const params = new HttpParams().set('id',estatePlanningModel.id ); 
    estatePlanningModel.facFinderId = facFinderId;
    //employmentAddModel.clientCode = clientCode;
    return this.http.put<XFacEstatePlanningResponse>(this.baseUrl +'/XFacEstatePlanning', estatePlanningModel, {params}).pipe(
      catchError(this.handleError)
    ); 
  }

  public addGoal(goalAddModel, facFinderId){
    goalAddModel.facFinderId = facFinderId;
    //estatePlanningModel.clientCode = clientCode;
    return this.http.post<XFacGoalResponse>(this.baseUrl +'/XFacGoal', goalAddModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public editGoal(goalAddModel, facFinderId){
    const params = new HttpParams().set('id',goalAddModel.id ); 
    goalAddModel.facFinderId = facFinderId;
    //employmentAddModel.clientCode = clientCode;
    return this.http.put<XFacGoalResponse>(this.baseUrl +'/XFacGoal', goalAddModel, {params}).pipe(
      catchError(this.handleError)
    ); 
  }

  public submitToXplan(facId){
    //let CurrentUserId = this.cookieService.get('CurrentUserId');
    const params = new HttpParams().set('facFinderId',facId );
    return this.http.post<XFacGoalResponse>(this.baseUrl +'/XFac/SubmitToXPlan',{}, {params}).pipe(
      catchError(this.handleError)
    );
  }

  public addIncome(incomeAddModel, facFinderId){
    incomeAddModel.facFinderId = facFinderId;
    //estatePlanningModel.clientCode = clientCode;
    return this.http.post<XFacIncomeResponse>(this.baseUrl +'/XFacIncome', incomeAddModel).pipe(
      catchError(this.handleError)
    );
  }

  public editIncome(incomeAddModel, facFinderId){
    const params = new HttpParams().set('id',incomeAddModel.id ); 
    incomeAddModel.facFinderId = facFinderId;
    //employmentAddModel.clientCode = clientCode;
    return this.http.put<XFacIncomeResponse>(this.baseUrl +'/XFacIncome', incomeAddModel, {params}).pipe(
      catchError(this.handleError)
    ); 
  }

  public addExpense(expenseAddModel, facFinderId){
    expenseAddModel.facFinderId = facFinderId;
    //estatePlanningModel.clientCode = clientCode;
    return this.http.post<XFacExpensesResponse>(this.baseUrl +'/XFacExpenses', expenseAddModel).pipe(
      catchError(this.handleError)
    );
  }

  public editExpense(expenseAddModel, facFinderId){
    const params = new HttpParams().set('id',expenseAddModel.id ); 
    expenseAddModel.facFinderId = facFinderId;
    //employmentAddModel.clientCode = clientCode;
    return this.http.put<XFacExpensesResponse>(this.baseUrl +'/XFacExpenses', expenseAddModel, {params}).pipe(
      catchError(this.handleError)
      ); 
  }

  public addAsseet(assetAddModel, facFinderId){
    //const params = new HttpParams().set('id',facFinderId ); 
    assetAddModel.facFinderId = facFinderId;
    //contactAddModel.clientCode = clientCode;
    return this.http.post<XFacAssetResponse>(this.baseUrl +'/XFacAsset', assetAddModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public editAsseet(assetAddModel, facFinderId){
    const params = new HttpParams().set('id', assetAddModel.id ); 
    assetAddModel.facFinderId = facFinderId;
    //assetAddModel.clientCode = clientCode;
    return this.http.put<XFacAssetResponse>(this.baseUrl +'/XFacAsset', assetAddModel,{params}).pipe(
      catchError(this.handleError)
    ); 
  }

  addInsurance(insuranceAddModel, facFinderId){
    //const params = new HttpParams().set('id',facFinderId ); 
    insuranceAddModel.facFinderId = facFinderId;
    //contactAddModel.clientCode = clientCode;
    return this.http.post<XFacInsuranceLifeResponse>(this.baseUrl +'/XFacInsurance', insuranceAddModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public editInsurance(insuranceAddModel, facFinderId){
    const params = new HttpParams().set('id',insuranceAddModel.id ); 
    insuranceAddModel.facFinderId = facFinderId;
    //employmentAddModel.clientCode = clientCode;
    return this.http.put<XFacInsuranceLifeResponse>(this.baseUrl +'/XFacInsurance', insuranceAddModel, {params}).pipe(
      catchError(this.handleError)
    ); 
  }

  public addCover(coverAddModel, facFinderId){
    coverAddModel.facFinderId = facFinderId;
    return this.http.post<XPlanCoverResponse>(this.baseUrl +'/XFacInsuranceCover', coverAddModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public editLiability(liabilityAddModel, facFinderId){
    const params = new HttpParams().set('id', liabilityAddModel.id ); 
    liabilityAddModel.facFinderId = facFinderId;
    //assetAddModel.clientCode = clientCode;
    return this.http.put<XFacLiabilityResponse>(this.baseUrl +'/XFacLiability', liabilityAddModel,{params}).pipe(
      catchError(this.handleError)
    ); 
  }

  public editCover(coverAddModel, facFinderId){
    const params = new HttpParams().set('id',coverAddModel.id ); 
    coverAddModel.facFinderId = facFinderId;
    return this.http.put<XPlanCoverResponse>(this.baseUrl +'/XFacInsuranceCover', coverAddModel, {params}).pipe(
      catchError(this.handleError)
    ); 
  }
  
  public addLiability(liabilityAddModel, facFinderId){
    //const params = new HttpParams().set('id',facFinderId ); 
    liabilityAddModel.facFinderId = facFinderId;
    //contactAddModel.clientCode = clientCode;
    return this.http.post<XFacLiabilityResponse>(this.baseUrl +'/XFacLiability', liabilityAddModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public editRetirementIncome(retirementIncomeAddModel, facFinderId){
    const params = new HttpParams().set('id', retirementIncomeAddModel.id ); 
    retirementIncomeAddModel.facFinderId = facFinderId;
    return this.http.put<XFacRetirementIncomeResponse>(this.baseUrl +'/XFacRetirementIncome', retirementIncomeAddModel,{params}).pipe(
      catchError(this.handleError)
    ); 
  }

  public addRetirementIncome(retirementIncomeAddModel, facFinderId){
    retirementIncomeAddModel.facFinderId = facFinderId;
    //contactAddModel.clientCode = clientCode;
    return this.http.post<XFacRetirementIncomeResponse>(this.baseUrl +'/XFacRetirementIncome', retirementIncomeAddModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public addMedicalInsurance(insuranceMedicalAddModel, facFinderId){
    //const params = new HttpParams().set('id',facFinderId ); 
    insuranceMedicalAddModel.facFinderId = facFinderId;
    return this.http.post<XFacInsuranceMedicalResponse>(this.baseUrl +'/XFacInsuranceMedical', insuranceMedicalAddModel).pipe(
      catchError(this.handleError)
    ); 
  }

  public editMedicalInsurance(insuranceMedicalAddModel, facFinderId){
    const params = new HttpParams().set('id', insuranceMedicalAddModel.id ); 
    insuranceMedicalAddModel.facFinderId = facFinderId;
    return this.http.put<XFacInsuranceMedicalResponse>(this.baseUrl +'/XFacInsuranceMedical', insuranceMedicalAddModel,{params}).pipe(
      catchError(this.handleError)
    ); 
  }

  public deleteContactDetail(id:string){
    const params = new HttpParams().set('id', id); 
    return this.http.delete<Response>(this.baseUrl +'/XFacContact',{params}).pipe(
      catchError(this.handleError)
      );     
  }

  public deleteAddressDetail(id:string){
    const params = new HttpParams().set('id', id); 
    return this.http.delete<Response>(this.baseUrl +'/XFacAddress',{params}).pipe(
      catchError(this.handleError)
      );     
  }

  public deleteDependentDetail(id:string){
    const params = new HttpParams().set('id', id); 
    return this.http.delete<Response>(this.baseUrl +'/XFacDependent',{params}).pipe(
      catchError(this.handleError)
      );     
  }

  public deleteEmploymentDetail(id:string){
    const params = new HttpParams().set('id', id); 
    return this.http.delete<Response>(this.baseUrl +'/XFacEmployment',{params}).pipe(
      catchError(this.handleError)
      );     
  }

  public deleteAssetDetail(id:string){
    const params = new HttpParams().set('id', id); 
    return this.http.delete<Response>(this.baseUrl +'/XFacAsset',{params}).pipe(
      catchError(this.handleError)
      );     
  }

  public deleteLiabilityDetail(id:string){
    const params = new HttpParams().set('id', id); 
    return this.http.delete<Response>(this.baseUrl +'/XFacLiability',{params}).pipe(
      catchError(this.handleError)
      );     
  }

  public deleteIncomeDetail(id:string){
    const params = new HttpParams().set('id', id); 
    return this.http.delete<Response>(this.baseUrl +'/XFacIncome',{params}).pipe(
      catchError(this.handleError)
      );     
  }

  public deleteExpenseDetail(id:string){
    const params = new HttpParams().set('id', id); 
    return this.http.delete<Response>(this.baseUrl +'/XFacExpenses',{params}).pipe(
      catchError(this.handleError)
      );     
  }

  public deleteGoalDetail(id:string){
    const params = new HttpParams().set('id', id); 
    return this.http.delete<Response>(this.baseUrl +'/XFacGoal',{params}).pipe(
      catchError(this.handleError)
      );     
  }

  public deleteInsuranceDetail(id:string){
    const params = new HttpParams().set('id', id); 
    return this.http.delete<Response>(this.baseUrl +'/XFacInsurance',{params}).pipe(
      catchError(this.handleError)
      );     
  }

  public deleteRetirementIncomeDetail(id:string){
    const params = new HttpParams().set('id', id); 
    return this.http.delete<Response>(this.baseUrl +'/XFacRetirementIncome',{params}).pipe(
      catchError(this.handleError)
      );     
  }

  public addNewFact(versionAddModel){
    //let userId = this.cookieService.get('CurrentUserId'); 
    //versionAddModel.userId = userId;
    return this.http.post<XFacVersionResponse>(this.baseUrl +'/FacFinder', versionAddModel).pipe(
      catchError(this.handleError)
    ); 
  }
  public deleteMedicalInsuranceDetail(id:string){
    const params = new HttpParams().set('id', id); 
    return this.http.delete<Response>(this.baseUrl +'/XFacInsuranceMedical',{params}).pipe(
      catchError(this.handleError)
      );     
  }

  public markAsReviewed(finderId :string){
    //console.log(personalInfo);
    const params = new HttpParams().set('facFinderId', finderId ).set('recordType', "3" );
    //personalInfo.facFinderId = finderId;
    return this.http.patch<Response>(this.baseUrl +'/FacFinder/FacFinderStatusChange', {},{params}).pipe(
      catchError(this.handleError)
    ); 
  }

  public getStatusChangeList(facFinderId){
    const params = new HttpParams().set('facFinderId', facFinderId );
    
    return this.http.get<XFacStatusListResponse>(this.baseUrl+'/FacFinderStatusChangeHistory/GetByFacfinderId?facFinderId='+facFinderId).pipe(
      catchError(this.handleError)
    );

  }

}
