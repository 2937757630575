<app-header></app-header>
<app-sidebar></app-sidebar>


<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                <h3 class="content-header-title mb-0 d-inline-block">ALL APPOINTMENTS</h3>
                <div class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/dashboard">Home</a> </li>
                            <li class="breadcrumb-item active">All Appointments </li>  
                                                      
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12">
                <div class="btn-group float-md-right" style="display: none">
                    <button class="btn appointment_btn btn-sm border_all_radius" data-toggle="modal" (click)="openModal()" >Create New</button>
                </div>


            </div>

        </div>
        <div class="content-body">

        <ng-container *ngIf="pendingAppointmentOne != null">
            <ng-container *ngIf="pendingAppointmentOne.client != null">
                <ng-container >
                        <div class="row"> 
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-content">
                                            <div class="card-body appointment_card_body">
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-6 col-12 appoint_name">
                                                        <div class="card-header appointment_card_header">
                                                            <h4 class="card-title appointment_title">Next Appointment</h4>
                                                        </div>
                                                        <div class="float-left pl-21  border-right-blue-grey border-right-lighten-5 appointment_border">
                                                            <div class="media-body">
                                                                <h2 style="font-size: 40px;">{{ formatLocalTime(pendingAppointmentOne.sheduledDate) | date: 'hh:mm a'}}</h2> <span class="text-muted">{{ formatLocalTime(pendingAppointmentOne.sheduledDate) | date: 'dd/MM/yyyy'}}</span>  <!-- | date: 'dd MMM yyyy' -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-6 col-12 appoint_pro_image">
                                                        <div class="float-left pl-2">
                                                            <div class="media media_appointment">
                                                                <div class="media-left mr-1">
                                                                    <a href="/client/view?id={{ pendingAppointmentOne.clientId }}"> <span class="avatar avatar-md avatar-busy avatar-pr appoint_avatar-md">
                                                                            <img src="assets/img/default_user.png" alt="avatar">
                                                                        </span> </a>
                                                                </div>
                                                                <div class="media-body">
                                                                    <h5 class="card-title appointment_title">
                                                                        <a href="#">

                                                                            <ng-container *ngIf="pendingAppointmentOne.client != null">
                                                                                <ng-container *ngIf="pendingAppointmentOne.client.client != null">

                                                                                    <a href="#" class="text-truncate" data-toggle="tooltip" title=" {{pendingAppointmentOne.client.client.firstName}} {{pendingAppointmentOne.client.client.lastName}}">
                                                                                        {{pendingAppointmentOne.client.client.firstName | limitTo : 15}} {{pendingAppointmentOne.client.client.lastName | limitTo : 15}}
                                                                                    </a>

                                                                                </ng-container>
                                                                                <ng-container *ngIf="pendingAppointmentOne.client.client == null">
                                                                                    {{pendingAppointmentOne.client.userName}}
                                                                                </ng-container>
                                                                            </ng-container>

                                                                             
                                                                        </a>
                                                                    </h5>
                                                                    <h6 class="card-sub"><span>Client Advisor : 

                                                                        <ng-container *ngIf="pendingAppointmentOne.appoinmentWith_ != null">
                                                                                <ng-container *ngIf="pendingAppointmentOne.appoinmentWith_.fortressAdmin != null">
                                                                                    {{pendingAppointmentOne.appoinmentWith_.fortressAdmin.firstName}} {{pendingAppointmentOne.appoinmentWith_.fortressAdmin.lastName}}
                                                                                </ng-container>
                                                                                <ng-container *ngIf="pendingAppointmentOne.appoinmentWith_.fortressAdmin == null">
                                                                                    {{pendingAppointmentOne.appoinmentWith_.userName}}
                                                                                </ng-container>
                                                                            </ng-container>

                                                                        
                                                                    </span></h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-5 col-md-12 col-12 appoint_ubox">
                                                        <div class="row">
                                                            <div class="col-md-6 appointment_r_box">
                                                                <a (click)="openEditModal(pendingAppointmentOne)" href="javascript:void(0)">  
                                                                    <div class="p-2 media-middle appoint_box_c">
                                                                        <div class="card-body text-center"> <i class="icon-pencil font-large-2 text-white"></i> </div> EDIT
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div class="col-md-6 appointment_r_box_last">
                                                                <a (click)="openCallModal(pendingAppointmentOne) "  href="javascript:void(0)">
                                                                    <div class="p-2 media-middle appoint_box_c">
                                                                        <div class="card-body text-center"> <i class="icon-call-end font-large-2 text-white"></i> </div> START NOW
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </ng-container>                            
            </ng-container>
        </ng-container>

            


            <section id="configuration">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Appointments</h4>
                            </div>
                            <div class="card-content collapse show">
                                <div *ngIf="isLoaded" class="card-body1 card-dashboard">
                                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="trigger" class="table table-striped table-bordered zero-configuration t_dt" style="margin-top: 20px !important;">
                                                <thead>
                                            <tr>
                                                <th class="border-top-0">Client</th>
                                                <th class="border-top-0">Client Type</th>
                                                <th class="border-top-0"></th>
                                                <th class="border-top-0">Appointment</th>
                                                <th class="border-top-0">Appointment Status</th>
                                                <th class="border-top-0"></th>
                                            </tr>

                                        </thead>
                                        <tbody *ngIf="appointmentList.length > 0">

                                            <tr *ngFor="let appointment of appointmentList; let i = index">
                                                <td class="text-truncate">                                                     
                                                    <ng-container *ngIf="appointment.client != null">
                                                        <ng-container *ngIf="appointment.client.client != null && appointment.client.client.firstName">
                                                            {{appointment.client.client.firstName | limitTo : 20}} {{appointment.client.client.lastName | limitTo : 20}}
                                                        </ng-container>
                                                        <ng-container *ngIf="appointment.client.client == null">
                                                            <ng-container *ngIf="appointment.client.client == null && appointment.client.userName">
                                                                {{appointment.client.userName}}
                                                            </ng-container>
                                                            <ng-container *ngIf="appointment.client.client == null && appointment.client.userName==null">
                                                                -
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>

                                                </td>
                                                <td class="text-truncate" style="color: #1b90c3;">Individual</td>
                                                <td class="text-truncate p-1">
                                                    <ul class="list-unstyled users-list m-0">
                                                        <li data-toggle="tooltip" data-popup="tooltip-custom" data-original-title="Kimberly Simmons" class="avatar avatar-sm pull-up"> <img class="media-object rounded-circle" src="assets/img/profile_image.png" alt="Avatar"> </li>
                                                    </ul>
                                                </td>
                                                <td class="text-truncate"><span class="bord_line" style="padding-right: 10px;"> {{ formatLocalTime(appointment.sheduledDate) | date: 'hh:mm a'}} </span><span style="padding-left: 10px;"> {{ formatLocalTime(appointment.sheduledDate) | date: 'dd/MM/yyyy'}} </span></td>
                                                <td> 
                                                    <ng-container *ngIf="appointment.appoinmentStatus == 1">
                                                        Scheduled
                                                    </ng-container>
                                                    <ng-container *ngIf="appointment.appoinmentStatus == 3">
                                                        Cancel
                                                    </ng-container>
                                                    <ng-container *ngIf="appointment.appoinmentStatus == 4">
                                                        Complete
                                                    </ng-container>
                                                    <ng-container *ngIf="appointment.appoinmentStatus == 5">
                                                        Expire
                                                    </ng-container>
                                                    <ng-container *ngIf="appointment.appoinmentStatus == 6">
                                                        Delete
                                                    </ng-container>
                                                </td>
                                                <td>
                                                    <div class="btn-group float-right">    
                                                        <button (click)="openEditModal(appointment)"  type="button" class="btn btn-icon btn-success mr-1 btntable"><i class="icon-pencil"></i></button>
                                                        <button (click)="openCallModal(appointment) "   type="button" class="btn btn-icon btn-success mr-1 btntable"><i class="icon-call-end"></i></button>
                                                    </div>
                                                             


                                                </td>
                                            </tr>

                                        </tbody>
                                        <tbody *ngIf="appointmentList.length == 0" style=" width: 100% !important; text-align: center;">
                                            <tr>
                                                <td colspan="5" >No records to show.</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                        
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-body text-center">
      <p>{{message}}</p>
      <button type="button" class="btn btn-default" (click)="openCallModal(appointment)" >Yes</button>
      <button type="button" class="btn btn-primary" (click)="decline()">No</button>
    </div>
  </ng-template>

  <ng-container *ngIf="!isLoadedPendingAppointments && !isLoaded && !isLoadedAgents">
    <ngx-ui-loader  ></ngx-ui-loader>
</ng-container>





<!-- <div class="app-content content h-100">
  <div class="row h-100 justify-content-center align-items-center">
   <img src="../../assets/img/coming_soon.png" class="cs_img" style="height: 260px; margin-bottom: 108px;">
   <h2 style="position: absolute; margin-top: 100px; color: #3d3d3d" class="soon_feature">This feature</h2>
   <h2 style="position: absolute; margin-top: 135px; color: #3d3d3d" class="soon_will">will be here soon.</h2>
  </div>
</div> -->




<app-footer ></app-footer>