<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="app-content content">
   <div class="content-wrapper">
      <div class="content-header row mb-1">
         <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
            <h3 class="content-header-title mb-0 d-inline-block">Clients</h3>
            <div class="row breadcrumbs-top d-inline-block">
               <div class="breadcrumb-wrapper col-12">
                  <ol class="breadcrumb">
                     <li class="breadcrumb-item"><a href="/dashboard">Fortress</a> </li>
                     <li class="breadcrumb-item"><a href="/clients">Clients</a> </li>
                     <li class="breadcrumb-item"><a href="/client/view?id={{clientId}}&clientId={{clientClientId}}">Clients Profile</a> </li>
                     <li class="breadcrumb-item active">Net Worth History</li>
                  </ol>
               </div>
            </div>
         </div>
      </div>
      <div class="content-body">
         <div class="card">
            <div class="card-header cleartfix">
               <div class="d-flex flex-row justify-content-between align-items-center">
                  <div class="d-flex flex-row" [ngClass]="isShowChartDateRange ? 'checkedClass' : 'uncheckedClass'">
                     <div class="dropdown mr-2">
                        <select id="chartType" (change)="onReportTypeChange($event.target.value)"
                           name="chartType" class="btn btn-dropdown dropdown-toggle"
                           placeholder="Please select">
                           <option value="Networth">Net worth</option>
                           <option value="Assets">Assets</option>
                           <option value="Liabilities">Liabilities</option>
                        </select>
                     </div>
                     <div class="dropdown mr-2">
                        <select id="documentType" (change)="onReportDateRangeChange($event.target.value)"
                           name="documentType" class="btn btn-dropdown dropdown-toggle"
                           placeholder="Please select">
                           <option value="12months">Last 12 months</option>
                           <option value="6months">Last 6 months</option>
                           <option value="custom">Custom Range</option>
                        </select>
                     </div>
                  </div>
                  <div class="d-flex flex-row" *ngIf="isShowChartDateRange">
                     <div class="form-group d-flex flex-row align-items-center mb-0">
                        <p class="mr-1 comparison mb-0">From</p>
                        <div class="input-group mr-2 date-picker">
                           <input class="form-control border-right-0 custom-form-control" placeholder="yyyy-mm-dd" name="dp0"
                           id="dp0" [(ngModel)]="fromDateChModel"
                           (navigate)="onChartDateChange('fromD', $event)" ngbDatepicker
                           #dp0="ngbDatepicker" datepicker-options="dateOptions" [minDate]="minDateFrom" [maxDate]="maxDateTo" [readonly]="true">
                           <div class="input-group-append">
                              <button class="btn calendar border-left-0" (click)="dp0.toggle()"
                                 type="button"></button>
                           </div>
                        </div>
                     </div>
                     <div class="form-group d-flex flex-row align-items-center mb-0">
                        <p class="mr-1 mb-0 comparison">To</p>
                        <div class="input-group date-picker">
                           <input class="form-control border-right-0 custom-form-control" placeholder="yyyy-mm-dd" name="dp1"
                           id="dp1" [(ngModel)]="toDateChModel"
                           (navigate)="onChartDateChange('toD', $event)" ngbDatepicker
                           #dp1="ngbDatepicker" [minDate]="minDateFrom" [maxDate]="maxDateTo" [readonly]="true">
                           <div class="input-group-append">
                              <button class="btn calendar border-left-0" (click)="dp1.toggle()"
                                 type="button"></button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="card-content">
               <div class="card-body tabcbody">
                  <div *ngIf="isLoadingChart">
                     <div id="loading-bar-spinner" class="spinner">
                        <div class="spinner-icon"></div>
                     </div>
                  </div>
                  <div class="chartjs mt-2">
                     <canvas height="95 !important;" baseChart [datasets]="netwealthChartData" [labels]="lable"
                     [options]="netwealthChartOptions" [legend]="netwealthChartLegend"
                     [colors]="lineColerBorder" [chartType]="netwealthChartType">
                     </canvas>
                  </div>
               </div>
            </div>
         </div>
         <div class="card">
            <div class="card-header d-flex align-items-center justify-content-between">
               <h4 class="card-title">Net Worth Comparison</h4>
               <form class="form-inline">
                  <div class="form-group  d-flex flex-row align-items-center">
                     <p class="mr-1 comparison mb-0">Comparison of</p>
                     <div class="input-group mr-2 date-picker">
                        <input class="form-control border-right-0" placeholder="yyyy-mm-dd" name="dp2" id="dp2"
                        [(ngModel)]="fromDateModel" (navigate)="onReportDateChange('fromD', $event)"
                        ngbDatepicker #dp2="ngbDatepicker" [minDate]="minDateFrom" [maxDate]="maxDateTo" [readonly]="true">
                        <div class="input-group-append">
                           <button class="btn calendar border-left-0" (click)="dp2.toggle()"
                              type="button"></button>
                        </div>
                     </div>
                  </div>
                  <div class="form-group  d-flex flex-row align-items-center">
                     <p class="mr-2 mb-0 comparison">and</p>
                     <div class="input-group date-picker">
                        <input class="form-control border-right-0" placeholder="yyyy-mm-dd" name="dp3" id="dp3"
                        [(ngModel)]="toDateModel" (navigate)="onReportDateChange('toD', $event)"
                        ngbDatepicker #dp3="ngbDatepicker" [minDate]="minDateFrom" [maxDate]="maxDateTo" [readonly]="true">
                        <div class="input-group-append">
                           <button class="btn calendar border-left-0" (click)="dp3.toggle()"
                              type="button"></button>
                        </div>
                     </div>
                  </div>
               </form>
               <!-- </div> -->
            </div>
            <div class="card-content">
               <div class="card-body tabcbody">
                  <!-- Assets -->
                  <div *ngIf="isLoading">
                     <div id="loading-bar-spinner" class="spinner">
                        <div class="spinner-icon"></div>
                     </div>
                  </div>
                  <table *ngIf="isLoaded1" class="mt-1 table main-table" data-count-fixed-columns="2">
                     <!-- Asset Start -->
                     <thead>
                        <tr>
                           <th scope="col" class="fixed-side">ASSETS</th>
                           <th scope="col" class="text-right fixed-side">Owner</th>
                           <ng-container *ngIf="dateArray.length > 0">
                              <th scope="col" class="text-right fixed-header">{{dateArray[0] | date:'MMM y'}}</th>
                              <th scope="col" class="text-right fixed-header">{{dateArray[dateArray.length-1] | date:'MMM y'}}</th>
                           </ng-container>
                           <th scope="col" class="text-right fixed-header">Variance</th>
                        </tr>
                     </thead>
                     <tbody *ngIf="assetArray.length > 0" class="body-section">
                        <tr class="main-category">
                           <th scope="row">{{assetArray[0].key}}</th>
                           <td scope="row" class="fixed-side"
                              style="background-color: white; position: relative; left: 0px;">
                           </td>
                           <ng-container *ngIf="dateArray.length > 0">
                              <td>${{assetArray[0].totalValueNWAccount[0].totalValueNWAccount | number: '1.0-0'}}</td>
                              <td>${{assetArray[0].totalValueNWAccount[assetArray[0].totalValueNWAccount.length-1].totalValueNWAccount | number: '1.0-0'}}</td>
                           </ng-container>
                           <td>${{assetArray[0].variance | number: '1.0-0'}}</td>
                        </tr>
                        <ng-container *ngFor="let netWorthAllocationValue of assetArray[0].value; let i = index" >
                           <tr class="sub-category">
                              <td scope="row">{{netWorthAllocationValue.key}}</td>
                              <td scope="row" class="fixed-side"
                                 style="background-color: white; position: relative; left: 0px;">
                              </td>
                              <ng-container *ngIf="dateArray.length > 0">
                                 <td>${{netWorthAllocationValue.totalValueNWAllocation[0].totalValueNWAllocation | number: '1.0-0'}}</td>
                                 <td>${{netWorthAllocationValue.totalValueNWAllocation[netWorthAllocationValue.totalValueNWAllocation.length-1].totalValueNWAllocation | number: '1.0-0'}}</td>
                              </ng-container>
                              <td>${{netWorthAllocationValue.variance | number: '1.0-0'}}</td>
                           </tr>
                           <ng-container
                              *ngFor="let netWorthAllocation of netWorthAllocationValue.value; let i = index" class="sub-info-border-bottom">
                              <tr class="sub-info">
                                 <td scope="row">{{netWorthAllocation.recordName}}</td>
                                 <td class="fixed-side" style="background-color: white; position: relative; left: 0px;">{{netWorthAllocation.owner}}</td>
                                 <td>
                                    <networth-update-widget
                                      (updateNetworthTable)="updateNetworthTable()"
                                      [netWorthSnapshotDataId]="netWorthAllocation.netWorthSnapshotDataID"
                                      [networthData]="netWorthAllocation.netWorthSnapshotDataWithDateSet[0]">
                                    </networth-update-widget>
                                  </td>
                                 <td>
                                    <networth-update-widget
                                      (updateNetworthTable)="updateNetworthTable()"
                                      [netWorthSnapshotDataId]="netWorthAllocation.netWorthSnapshotDataID"
                                      [networthData]="netWorthAllocation.netWorthSnapshotDataWithDateSet[netWorthAllocation.netWorthSnapshotDataWithDateSet.length-1]">
                                    </networth-update-widget>
                                  </td>
                                 <td>${{netWorthAllocation.variance | number: '1.0-0'}}</td>
                              </tr>
                           </ng-container>
                        </ng-container>
                        <tr class="main-category top-border">
                           <th scope="row">{{assetArray[assetArray.length-1].key}}</th>
                           <td scope="row" class="fixed-side"
                              style="background-color: white; position: relative; left: 0px;">
                           </td>
                           <ng-container *ngIf="dateArray.length > 0">
                              <td>${{assetArray[assetArray.length-1].totalValueNWAccount[0].totalValueNWAccount | number: '1.0-0'}}</td>
                              <td>${{assetArray[assetArray.length-1].totalValueNWAccount[assetArray[assetArray.length-1].totalValueNWAccount.length-1].totalValueNWAccount | number: '1.0-0'}}</td>
                           </ng-container>
                           <td>${{assetArray[assetArray.length-1].variance | number: '1.0-0'}}</td>
                        </tr>
                        <ng-container *ngFor="let netWorthAllocationValue of assetArray[assetArray.length-1].value; let i = index">
                           <tr class="sub-category">
                              <td scope="row">{{netWorthAllocationValue.key}}</td>
                              <td scope="row" class="fixed-side">
                              </td>
                              <ng-container *ngIf="dateArray.length > 0">
                                 <td>${{netWorthAllocationValue.totalValueNWAllocation[0].totalValueNWAllocation | number: '1.0-0'}}</td>
                                 <td>${{netWorthAllocationValue.totalValueNWAllocation[netWorthAllocationValue.totalValueNWAllocation.length-1].totalValueNWAllocation | number: '1.0-0'}}</td>
                              </ng-container>
                              <td>${{netWorthAllocationValue.variance | number: '1.0-0'}}</td>
                           </tr>
                           <ng-container
                              *ngFor="let netWorthAllocation of netWorthAllocationValue.value; let i = index">
                              <tr class="sub-info">
                                 <td scope="row">{{netWorthAllocation.recordName}}</td>
                                 <td class="fixed-side">{{netWorthAllocation.owner}}</td>
                                 <td>
                                    <networth-update-widget
                                      (updateNetworthTable)="updateNetworthTable()"
                                      [netWorthSnapshotDataId]="netWorthAllocation.netWorthSnapshotDataID"
                                      [networthData]="netWorthAllocation.netWorthSnapshotDataWithDateSet[0]">
                                    </networth-update-widget>
                                  </td>
                                 <td>
                                    <networth-update-widget
                                      (updateNetworthTable)="updateNetworthTable()"
                                      [netWorthSnapshotDataId]="netWorthAllocation.netWorthSnapshotDataID"
                                      [networthData]="netWorthAllocation.netWorthSnapshotDataWithDateSet[netWorthAllocation.netWorthSnapshotDataWithDateSet.length-1]">
                                    </networth-update-widget>
                                  </td>
                                 <td>${{netWorthAllocation.variance | number: '1.0-0'}}</td>
                              </tr>
                           </ng-container>
                        </ng-container>
                     </tbody>
                     <tbody *ngIf="assetArray.length == 0"  class="empty-set">
                        <tr>
                           <td colspan="5">
                              <!-- No Asset records to show. -->
                              <p class="mb-0 text-left my-2 empty-text">No Asset records to show.</p>
                           </td>
                        </tr>
                     </tbody>
                     <!-- Asset End -->
                     <!-- Liability Start -->
                     <div class="mt-2"></div>
                     <thead>
                        <tr>
                           <th scope="col" class="fixed-side">LIABILITIES</th>
                           <th scope="col" class="text-right fixed-side">Owner</th>
                           <ng-container *ngIf="dateArray.length > 0">
                              <th scope="col" class="text-right fixed-header">{{dateArray[0] | date:'MMM y'}}</th>
                              <th scope="col" class="text-right fixed-header">{{dateArray[dateArray.length-1] | date:'MMM y'}}</th>
                           </ng-container>
                           <th scope="col" class="text-right fixed-header">Variance</th>
                        </tr>
                     </thead>
                     <tbody *ngIf="liabilityArray.length > 0" class="body-section">
                        <tr class="main-category">
                           <th scope="row">{{liabilityArray[0].key}}</th>
                           <td scope="row" class="fixed-side">
                           </td>
                           <ng-container *ngIf="dateArray.length > 0">
                              <td>${{liabilityArray[0].totalValueNWAccount[0].totalValueNWAccount | number: '1.0-0'}}</td>
                              <td>${{liabilityArray[0].totalValueNWAccount[liabilityArray[0].totalValueNWAccount.length-1].totalValueNWAccount | number: '1.0-0'}}</td>
                           </ng-container>
                           <td>${{liabilityArray[0].variance | number: '1.0-0'}}</td>
                        </tr>
                        <ng-container *ngFor="let netWorthAllocationValue of liabilityArray[0].value; let i = index">
                           <tr class="sub-category">
                              <td scope="row">{{netWorthAllocationValue.key}}</td>
                              <td scope="row" class="fixed-side">
                              </td>
                              <ng-container *ngIf="dateArray.length > 0">
                                 <td>${{netWorthAllocationValue.totalValueNWAllocation[0].totalValueNWAllocation | number: '1.0-0'}}</td>
                                 <td>${{netWorthAllocationValue.totalValueNWAllocation[netWorthAllocationValue.totalValueNWAllocation.length-1].totalValueNWAllocation | number: '1.0-0'}}</td>
                              </ng-container>
                              <td>${{netWorthAllocationValue.variance | number: '1.0-0'}}</td>
                           </tr>
                           <ng-container
                              *ngFor="let netWorthAllocation of netWorthAllocationValue.value; let i = index">
                              <tr class="sub-info">
                                 <td scope="row">{{netWorthAllocation.recordName}}</td>
                                 <td class="fixed-side">{{netWorthAllocation.owner}}</td>
                                 <td>
                                    <networth-update-widget
                                      (updateNetworthTable)="updateNetworthTable()"
                                      [netWorthSnapshotDataId]="netWorthAllocation.netWorthSnapshotDataID"
                                      [networthData]="netWorthAllocation.netWorthSnapshotDataWithDateSet[0]">
                                    </networth-update-widget>
                                  </td>
                                 <td>
                                    <networth-update-widget
                                      (updateNetworthTable)="updateNetworthTable()"
                                      [netWorthSnapshotDataId]="netWorthAllocation.netWorthSnapshotDataID"
                                      [networthData]="netWorthAllocation.netWorthSnapshotDataWithDateSet[netWorthAllocation.netWorthSnapshotDataWithDateSet.length-1]">
                                    </networth-update-widget>
                                  </td>
                                 <td>${{netWorthAllocation.variance | number: '1.0-0'}}</td>
                              </tr>
                           </ng-container>
                        </ng-container>
                        <tr class="main-category top-border">
                           <th scope="row">{{liabilityArray[liabilityArray.length-1].key}}</th>
                           <td scope="row" class="fixed-side">
                           </td>
                           <ng-container *ngIf="dateArray.length > 0">
                              <td>${{liabilityArray[liabilityArray.length-1].totalValueNWAccount[0].totalValueNWAccount | number: '1.0-0'}}</td>
                              <td>${{liabilityArray[liabilityArray.length-1].totalValueNWAccount[liabilityArray[liabilityArray.length-1].totalValueNWAccount.length-1].totalValueNWAccount | number: '1.0-0'}}</td>
                           </ng-container>
                           <td>${{liabilityArray[liabilityArray.length-1].variance | number: '1.0-0'}}</td>
                        </tr>
                        <ng-container *ngFor="let netWorthAllocationValue of liabilityArray[liabilityArray.length-1].value; let i = index">
                           <tr class="sub-category">
                              <td scope="row">{{netWorthAllocationValue.key}}</td>
                              <td scope="row" class="fixed-side"
                                 style="background-color: white; position: relative; left: 0px;">
                              </td>
                              <ng-container *ngIf="dateArray.length > 0">
                                 <td>${{netWorthAllocationValue.totalValueNWAllocation[0].totalValueNWAllocation | number: '1.0-0'}}</td>
                                 <td>${{netWorthAllocationValue.totalValueNWAllocation[netWorthAllocationValue.totalValueNWAllocation.length-1].totalValueNWAllocation | number: '1.0-0'}}</td>
                              </ng-container>
                              <td>${{netWorthAllocationValue.variance | number: '1.0-0'}}</td>
                           </tr>
                           <ng-container
                              *ngFor="let netWorthAllocation of netWorthAllocationValue.value; let i = index">
                              <tr class="sub-info">
                                 <td scope="row">{{netWorthAllocation.recordName}}</td>
                                 <td class="fixed-side">{{netWorthAllocation.owner}}</td>
                                 <td>
                                    <networth-update-widget
                                      (updateNetworthTable)="updateNetworthTable()"
                                      [netWorthSnapshotDataId]="netWorthAllocation.netWorthSnapshotDataID"
                                      [networthData]="netWorthAllocation.netWorthSnapshotDataWithDateSet[0]">
                                    </networth-update-widget>
                                  </td>
                                 <td>
                                    <networth-update-widget
                                      (updateNetworthTable)="updateNetworthTable()"
                                      [netWorthSnapshotDataId]="netWorthAllocation.netWorthSnapshotDataID"
                                      [networthData]="netWorthAllocation.netWorthSnapshotDataWithDateSet[netWorthAllocation.netWorthSnapshotDataWithDateSet.length-1]">
                                    </networth-update-widget>
                                  </td>
                                 <td>${{netWorthAllocation.variance | number: '1.0-0'}}</td>
                              </tr>
                           </ng-container>
                        </ng-container>
                     </tbody>
                     <tbody *ngIf="liabilityArray.length == 0"  class="empty-set">
                        <tr>
                           <td colspan="5">
                              <!-- No Asset records to show. -->
                              <p class="mb-0 text-left my-2 empty-text">No Liability records to show.</p>
                           </td>
                           <td></td>
                        </tr>
                     </tbody>
                     <!-- Liability End-->
                  </table>
                  <!-- empty state -->
                  <!-- <div class="d-flex flex-column align-items-center justify-content-center my-1">
                     <img src="../../assets/img/empty_state_img.png" class="w-25 empty-image">
                     <p class="mb-0 mt-2">No records to show</p>
                     </div> -->
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<app-footer></app-footer>
