export class XFacExpensesBaseMOdel {
    public  facFinderId : string;
    public  typeGroup : string;
    public  type : string;
    public  description : string;
    public  amountValue : number;
    public  amountCode : string;
    public  frequency : number;
    public  ownerType : string;
    public  isTaxDeductable : boolean;
    public  note : string;
    public  clientCode : string;
    public  expenseTypeName : string;
    public  expenseSubTypeName : string;
    public  expenseFrequencyName : string;
}

export class XFacExpensesAddModel extends XFacExpensesBaseMOdel{    
       
}