import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { AudienceMemberAddModel } from 'src/app/shared/models/audience-member.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AudienceService } from '../../audience.service';
import { Utility } from 'src/app/utility/utility';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-import-clients',
  templateUrl: './import-clients.component.html',
  styleUrls: ['./import-clients.component.css']
})
export class ImportClientsComponent implements OnInit {

  isLoading: boolean = false;
  isLoaded2: boolean = false;
  products: any;
  file: File;
  arrayBuffer: any;
  filelist: any;
  audienceMemberList: AudienceMemberAddModel[];
  data;
  memberCount: number = 0;
  arraylist: any = [];
  errors;
  clicked: boolean = false;
  event: EventEmitter<any> = new EventEmitter();
  audienceData: any;
  
  constructor(
    public modalRef: BsModalRef,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private audienceService: AudienceService,
    
  ) { }

  ngOnInit(): void {
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

  ClosePopup(){
    this.modalRef.hide();
  }

  uploadListener(event)
  {
    this.isLoading = true;
    this.memberCount = 0;
    this.file= event.target.files[0];     
    let fileReader = new FileReader();    
    fileReader.readAsArrayBuffer(this.file);

    fileReader.onload = (e) => {    
        this.arrayBuffer = fileReader.result;    
        var data = new Uint8Array(this.arrayBuffer);    
        var arr = new Array();    
        for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
        var bstr = arr.join("");    
        var workbook = XLSX.read(bstr, {type:"binary"});    
        var first_sheet_name = workbook.SheetNames[0];  
        var worksheet = workbook.Sheets[first_sheet_name];
        // console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true})); 
        this.arraylist = [];   
        this.arraylist = XLSX.utils.sheet_to_json(worksheet,{raw:true});     
        console.log('this.arraylist == ',this.arraylist)
        this.filelist = []; 
        if(this.arraylist.length>0) {
          
          // if(this.arraylist[0].Name && this.arraylist[0].ClientCode && this.arraylist[0].XeppoCode) {
          if(this.arraylist[0].XeppoCode) {
            this.getAudienceList(this.arraylist);
            this.memberCount = this.arraylist.length;
            
          } else {
            this.toastr.error('The CSV headers do not match the approved template.');
          }
        } else {
          this.toastr.error('The CSV headers do not match the approved template or client list is not available.');
        }
    }   
  }

  formatArray(arraylist)
  {
    this.audienceMemberList = [];
    arraylist.forEach(element => {
      // if(choice && choice[0]) {
        // this.audienceMemberList.push({xeppoCode: element.XeppoCode, clientCode: element.ClientCode, audianceId: this.data.audience_id });
        this.audienceMemberList.push({xeppoCode: element.xeppoCode, clientCode: element.code, audianceId: this.data.audience_id });
      // }
    });

    this.isLoading = false;
  }

  addClients()
  {
    // this.insertedMembersCount = 0;
    // this.unInsertedMembersCount = 0;

    this.ngxService.start();
    this.audienceService.addAudienceMember(this.audienceMemberList).subscribe((data:any)=>{
      this.ngxService.stop();
      if(data.error && !data.success){
        this.errors = Utility.GetLocalErrorString(data.error.errorCode);
        //console.log(this.errors);
      }
      else{
        this.clicked=true;
        let insertedMembersCount = data.response.insertedMembers.length;
        let unInsertedMembersCount = data.response.unInsertedMembers.length;
        this.toastr.success('Inserted Client Count: '+ insertedMembersCount + ', Uninserted Client Count: '+ unInsertedMembersCount );
        this.event.emit('OK');
        this.modalRef.hide();
      }
      
    },err=>{   
      this.ngxService.stop();
      this.toastr.error('Error on saving clients.');
    });
      
  }

  getAudienceList(arraylist){
    this.audienceService.getAudienceByXeppoCode(arraylist).subscribe((data:any)=>{ 
      if(data && data.success){
        this.audienceData = data.response;
        console.log('this.audienceData == ',this.audienceData)
        this.compare2Arrays(this.arraylist, this.audienceData);
        this.isLoaded2 = true;  
      }    
          
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });     
  }

  compare2Arrays(arraylist, audienceData){
    const results = arraylist.filter(({ XeppoCode: id1 }) => !audienceData.some(({ xeppoCode: id2 }) => id2 === id1));
    console.log("666666 ==", results);
    results.forEach((element, index) => {  
      this.audienceData.push({xeppoCode:element.XeppoCode, code: null, firstName:null, lastName:null});
    });

    this.formatArray(this.audienceData);  
  }

}
