export class XplanInsuranceMedical {
    public  id : string;
    public  facFinderViewModel : [];
    public  type : string;
    public  clientCode : string;
    public  policyNumber : string;
    public  planName : string;
    public  totalPremiumValue : number;
    public  totalPremiumCode : string;
    public  owners : string;   
    public  ownerName  : string;   
    
}

export class XFacInsuranceMedicalAddModel extends XplanInsuranceMedical{    
    public  insuranceMedicalTypeName  : string;     
}