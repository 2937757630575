import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { NewsFeedAddModel } from '../../shared/models/news-feed.model' 
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UserDocumentsService } from '../user-documents.service';
import { FileUploadService } from '../../file-upload/file-upload.service';
import { InvoiceResponse, ClentResponsex, TransactionResponse, NewsFeedResponse, FileUploadResponse, UserDocumentResponse, ClentResponsexNew } from 'src/app/shared/models/response.model';
import { Utility } from 'src/app/utility/utility';
import { ClientsService } from 'src/app/clients/clients.service';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { ClientViewModel } from 'src/app/shared/models/client.model';
import { FileUploadAddModel } from 'src/app/shared/models/file-upload.model';
import { DocType } from 'src/app/shared/models/db-enums.model';
import { TransactionViewModel } from 'src/app/shared/models/transaction.model';
import { AppConstants } from '../../utility/app-constants';
import { DocumentUploadType, UserDocumentsAddModel } from 'src/app/shared/models/user-documents.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-add-user-documents',
  templateUrl: './add-user-documents.component.html',
  styleUrls: ['./add-user-documents.component.css']
})
export class AddUserDocumentsComponent implements OnInit {

  userDocumentsAddModel : UserDocumentsAddModel ;
  fileUploadAddModel : FileUploadAddModel;
  //clinics : ClinicViewModel[];
  //patientClinic:ClinicViewModel; 
  title;
  data;
  errors;
  documentLabel = "Choose file";

  patientAddForm: FormGroup;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  maxdate :any;
  //public utility: utility;
  Isvalid:boolean;
  clientList:ClientViewModel[];
  transactionList:TransactionViewModel[];
  docTypes = DocType;
  documentType1 :any;
  uploadDocumentEnable:boolean = true;
  disableDocumentType:boolean = false;
  acceptDocType : string;
  document1 : any;

  changedData: any;
  constructor(
    private http:HttpClient,
    private clientsService: ClientsService, 
    private formBuilder: FormBuilder,
    private router: Router, 
    private fileUploadService: FileUploadService, 
    private documentsService: UserDocumentsService,
    public modalRef: BsModalRef,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private cookieService: CookieService
    ) { }
 
  ngOnInit() {
    this.documentType1 = [{"id":"0","value":"Please select"},{"id":"1","value":"Jpg"},{"id":"2","value":"Png"},{"id":"3","value":"Pdf"},{"id":"4","value":"Doc"}]; 
    this.Isvalid = true;
    this.userDocumentsAddModel = new UserDocumentsAddModel();
    this.fileUploadAddModel = new FileUploadAddModel();
    //console.log(this.modalRef.content);
    if(this.userDocumentsAddModel.adminView) {
      this.userDocumentsAddModel.adminView = true;
    }
    else{
      this.userDocumentsAddModel.adminView = false;
    }

    if(this.data.userDocument !=null){
      Object.assign(this.userDocumentsAddModel, this.data.userDocument);
    }

    this.userDocumentsAddModel.documentType = "0";
    //this.getClientList();
    //this.getNewsFeedList();
    //this.ngxService.start();
    //this.ngxService.start();
  }

  onDataChanged(data: any): void {
    this.changedData = data;
  }
  onSubmit(form:NgForm){
    
    let documentName = this.userDocumentsAddModel.documentName;

  if(form.invalid || this.userDocumentsAddModel.documentType == "0" || (documentName && documentName.trim()=="") ) {
  
    if(documentName && documentName.trim()==""){
      form.controls['documentName'].setErrors({'required': true});
    }

    if(form.pristine){
    }
    this.Isvalid = false;
    //console.log(this.Isvalid);
  }
  
  else{ 

    this.ngxService.start();
    //console.log("Add"); 
    this.fileUploadAddModel.fileCategory = "4";
    this.fileUploadAddModel.fileType = this.userDocumentsAddModel.documentType;  
    this.fileUploadAddModel.adminView = this.userDocumentsAddModel.adminView;
    this.fileUploadAddModel.uploadType = DocumentUploadType.s3Bucket;
    this.fileUploadAddModel.groupCode = this.data.xeppoGroupCode;
    this.fileUploadAddModel.xeppoCode = this.data.xeppoCode;
    this.fileUploadAddModel.companyCode = this.data.companyCode;
    this.fileUploadAddModel.documentName = this.userDocumentsAddModel.documentName;
    this.fileUploadAddModel.isFds = false;
    this.fileUploadAddModel.userId = this.cookieService.get('CurrentUserId'); //JSON.parse(localStorage.getItem('CurrentUserId'));
    this.fileUploadAddModel.isPortal = true;

    this.fileUploadService.uploadDocument(this.fileUploadAddModel).subscribe((data:FileUploadResponse)=>{  
      if(data.error && !data.success){
        this.ngxService.stop();  
        this.toastr.error('Error on uploading file.');      
        //this.errors = Utility.GetLocalErrorString(data.error.errorCode);
        //console.log(this.errors);
      }
      else{
        if(data?.response?.s3Key){
        this.userDocumentsAddModel.documentURL = data.response.s3Key;
        this.userDocumentsAddModel.documentCategory = "4";    
        this.userDocumentsAddModel.documentUploadType = DocumentUploadType.s3Bucket;    
        //console.log(this.userDocumentsAddModel);
        this.documentsService.uploadDocument(this.userDocumentsAddModel).subscribe((data:UserDocumentResponse)=>{
          this.ngxService.stop();  
        if(data.error && !data.success){
          this.toastr.error('Error on uploading file.');
          //this.errors = Utility.GetLocalErrorString(data.error.errorCode);
          //console.log(this.errors);
        }
        else{
          this.toastr.success('File uploaded successfully.');

        //  setTimeout( () =>
        //   this.modalRef.hide()
        //   , 60000 );
          
          this.event.emit('OK');
          // this.modalRef.hide();
        }      
        },err=>{    
          this.ngxService.stop();
          this.toastr.error('Error on uploading file.');
        });
      }else{ 
        // this.userDocumentsAddModel.documentURL = null;
        // this.userDocumentsAddModel.documentCategory = "4";    
        // this.userDocumentsAddModel.documentUploadType = DocumentUploadType.sharepoint;    
        //console.log(this.userDocumentsAddModel);
        // this.documentsService.uploadDocument(this.userDocumentsAddModel).subscribe((data:UserDocumentResponse)=>{
        //   this.ngxService.stop();  
        // if(data.error && !data.success){
        //   this.toastr.error('Error on uploading file.');
        //   //this.errors = Utility.GetLocalErrorString(data.error.errorCode);
        //   //console.log(this.errors);
        // }
        // else{
        //   this.toastr.success('File uploaded successfully.');
        //   this.event.emit('OK');
        //   this.modalRef.hide();
        // }      
        // },err=>{    
        //   this.ngxService.stop();
        //   this.toastr.error('Error on uploading file.');
        // });
        
       
        this.event.emit('OK');
        this.modalRef.hide();
        this.toastr.success('File uploaded successfully.');
        // this.toastr.warning('The uploaded document will be added to the list shortly.');
     
      }
      }
      
      },err=>{
        this.ngxService.stop();   
        this.toastr.error('Error on uploading file.');
      });
 
  }
  }


  callme(){

    this.modalRef.hide()
     this.toastr.success('File uploaded successfully.');
          this.event.emit('OK');
  }
  
  // onFileChange(event){
  //   //this.fileUploadAddModel.file = event.target.files[0];   
    


  //   // this.fileUploadAddModel.file = <File>event.target.files[0];
  //   // this.fileUploadAddModel.file = <File>event.target.files[0];
  //   // this.documentLabel = event.target.files[0].name;
    
  //   if(event.target.files[0]){
  //     this.fileUploadAddModel.file = <File>event.target.files[0];
  //     this.documentLabel = event.target.files[0].name;
  //     this.disableDocumentType = true;
  //     // console.log('file selected');
  //   } 
  //   else{
  //     this.documentLabel = "Choose file";
  //     this.disableDocumentType = false;
  //     // console.log('no file selected');
  //   }
  //   //console.log(this.fileUploadAddModel); 
  //   // console.log(this.disableDocumentType);
  // }

  onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      const maxSizeInBytes = 25 * 1024 * 1024; // 25MB in bytes
  
      if (selectedFile.size > maxSizeInBytes) {
        // File exceeds the maximum allowed size
        this.toastr.error('The maximun file size you can upload is 25MB.');
        // You can also reset the input field if needed
        event.target.value = null;
      } else {
        // File size is within the limit
        this.fileUploadAddModel.file = selectedFile;
        this.documentLabel = selectedFile.name;
        this.disableDocumentType = true;
        console.log('File selected');
      }
    } else {
      this.documentLabel = 'Choose file';
      this.disableDocumentType = false;
      console.log('No file selected');
    }
  }
  

  onDocumentTypeChange(docType){
    this.uploadDocumentEnable = false;
    if(docType==1){
      this.acceptDocType = ".jpg,.jpeg";
    }else if(docType==2){
      this.acceptDocType = ".png";
    }else if(docType==3){
      this.acceptDocType = ".pdf";
    }else if(docType==4){
      this.acceptDocType = ".doc"; 
    }else if(docType==5){
      this.acceptDocType = ".docx"; 
    }else if(docType==6){
      this.acceptDocType = ".csv"; 
    }else if(docType==7){
      this.acceptDocType = ".xls"; 
    }else if(docType==8){
      this.acceptDocType = ".xlsx"; 
    }
    else{
      this.uploadDocumentEnable = true;
    }
  }

  getClientList(){
    this.clientsService.clientList().subscribe((data:ClentResponsexNew)=>{     
      this.clientList = data.response.data.filter(function(client){
        //console.log('Client list for add news ---------');
        //console.log(this.clientList);
       // client.name = client.client.firstName+' '+client.client.lastName;
        return client.client !== null;
      });

      //console.log('Client list for add news ---------');
      //console.log(this.clientList);
    });

    // this.clientsService.clientList().subscribe((data:ClentResponsex)=>{     
    //   this.clientList = data.response;      
    //   console.log('Client list for invoices ---------');
    //   console.log(this.clientList);
    //   },
    //   (err: HttpErrorResponse)=>{
    //     console.log("Error"+ err.message);
    //   });      
  }
  
  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }
  
  ClosePopup(){
    this.modalRef.hide();
  }

}
