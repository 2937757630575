import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NetworthSnapshotData } from 'src/app/shared/models/networth-history.model';
import { NetworthHistoryService } from '../networth-history.service';

@Component({
  selector: 'networth-update-widget',
  templateUrl: './networth-update-widget.component.html',
  styleUrls: ['./networth-update-widget.component.css']
})
export class NetworthUpdateWidgetComponent implements OnInit {

  isEditing = false;
  isLoading = false;

  @ViewChild('nwValue') nwValue: ElementRef;
  @ViewChild('nwEditor') nwEditor: ElementRef;
  @Output() updateNetworthTable = new EventEmitter<any>();
  @Input() networthData: any;
  @Input() netWorthSnapshotDataId: number;

  // Networth Snapshot Data
  updateNetworthSnapshotData = new NetworthSnapshotData();
  toggle = {};
  message: string;
  modalRef: BsModalRef;
  currentValueId: string;

  constructor(
    private networthHistoryService: NetworthHistoryService,
    private modalService: BsModalService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  toggleEdit() {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      setTimeout(()=>{ // this will make the execution after the above boolean has changed
        this.nwValue.nativeElement.focus();
      }, 0);
    }
  }

  updateNetworthValue(id: string, value: any) {
    if (value != this.networthData.value) {
      this.updateNetworthSnapshotDetails(id, value);
    } else {
      this.isEditing = false;
    }
  }

  updateNetworthSnapshotDetails(id: string, value: any) {
    this.isLoading = true;

    this.updateNetworthSnapshotData.id = id;
    this.updateNetworthSnapshotData.netWorthSnapshotDataID = this.netWorthSnapshotDataId;
    this.updateNetworthSnapshotData.value = Number(value);
    this.networthHistoryService.updateNetworthSnapshotData(this.updateNetworthSnapshotData).subscribe( res => {
      if (res.success) {
        this.networthData.value = res.data.value;
        this.updateNetworthTable.emit();
        this.toastr.success('Value updated successfully');
        this.isEditing = false;
      } else {
        this.toastr.error(res.error.message);
      }
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.toastr.error('Operaion failed.');
    })
  }

  public openDeleteConfirmation(template: TemplateRef<any>, data: any) {
    this.currentValueId = data.id;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.message = 'Are you sure that you want to delete this ( $'+data.value+' ) value ?'
  }

  public decline() {
    this.modalRef.hide();
  }

  deleteNetworthValue() {
    this.isLoading = true;
    const id = this.currentValueId;
    const isArchived = true; // Archive recorde
    this.networthHistoryService.deleteNetworthSnapshotData(id, isArchived).subscribe( res => {
      if(res.success) {
        this.updateNetworthTable.emit();
        this.toastr.success('Value deleted successfully');
      } else {
        this.toastr.error(res.error.message);
      }
      this.isLoading = false;
      this.modalRef.hide();
    }, err => {
      this.isLoading = false;
      this.toastr.error('Operaion failed.');
      this.modalRef.hide();
    })
  }

  // Close editor on out side click of the widget (component) //  <- This coudld be useful in future.
  // @HostListener('document:click', ['$event'])
  // clickOut(event) {
  //   if(!this.nwEditor?.nativeElement.contains(event.target)) {
  //     this.isEditing = false;
  //   }
  // }
}
