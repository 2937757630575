import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Globals } from 'src/app/utility/globals';
import { AuthService } from '../auth/auth.service';
import { User, VerifiedUser } from '../shared/models/user.model';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ClientsService } from '../clients/clients.service';
import { AppointmentResponse, ClentSearchResponse } from '../shared/models/response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AppointmentService } from '../appointments/appointment.service';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import { CompleterService, CompleterData } from 'ng2-completer';
import { AppConstants } from '../utility/app-constants';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  //isSidebarToggeled boolean : false;
  isSidebarToggeled: boolean = false;
  currentUser: User;
  searchVal :string;
  searchResults: {id: string, firstName: string, lastName: string}[] = [];
  searchText:string = "";
  searchStr;
  business_id:any;
  logoURL: string ='';
  logoNameURL: string ='';
  companyCode:string;

  protected dataService: CompleterData;
  

  constructor (
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private authService: AuthService, 
    private clientsService: ClientsService, 
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService
    //private appointmentService: AppointmentService,
    //private completerService: CompleterService
  ) { 
    //this.globals= Globals;
    this.currentUser = this.authService.getCurrentUserValue;
    this.logoURL = AppConstants.getLogoURL();
    this.logoNameURL = AppConstants.getLogoNameURL();
  }
  
  ngOnInit(): void {
    this.renderer.addClass(this.document.body, this.getClasses());    

    this.companyCode = this.cookieService.get('companyCode');
    this.business_id = this.route.snapshot.queryParams['business_id'];
    if(this.business_id) { 
      localStorage.setItem('businessId', JSON.stringify(this.business_id));
      this.getBusinessList();
    } 
  }

  searchClients(searchText){  
    this.searchText = searchText;
    // if(searchText.length > 2){

      // this.clientsService.clientSearch(searchText, this.companyCode).pipe(delay(1000)).subscribe((data:ClentSearchResponse)=>{    
     
      //   if(data.response){
      //     this.searchResults = data.response;
          // this.searchResults = [];
        //  data.response.forEach(client => {
        //    this.searchResults.push({
        //      id: client.id,
        //      firstName: client.firstName,   
        //      lastName: client.lastName
        //    });
           
        //   });
   
          //console.log(this.searchResults);
          //this.dataService = this.completerService.local(this.searchResults, 'name', 'name');
          
      //   }
         
      //  },
      //  (err: HttpErrorResponse)=>{
         //console.log("Error"+ err.message);
    //    });

    // }
         
  }

  viewClient(client){
    // console.log(client.id);
    // console.log(client);
    this.router.navigate(['client/view'], { queryParams: {id: client.originalObject.id }});
  }

  

  getClasses() {
    this.renderer.removeClass(this.document.body, 'bg-full-screen-image');     
    this.renderer.removeClass(this.document.body, 'blank-page');
    this.renderer.addClass(this.document.body, 'fixed-navbar');

    if(this.isSidebarToggeled){
      this.renderer.addClass(this.document.body, 'menu-collapsed');
      this.renderer.removeClass(this.document.body, 'menu-expanded');
      return 'menu-collapsed';
    }
    else{
      this.renderer.addClass(this.document.body, 'menu-expanded');
      this.renderer.removeClass(this.document.body, 'menu-collapsed');
      return 'menu-expanded';
    }
  }

  toggleSidebar() {
    this.isSidebarToggeled = !this.isSidebarToggeled;
    //console.log(this.isSidebarToggeled);
    this.getClasses();
    return this.isSidebarToggeled;
  }

  logOut(){
    //console.log(4444444444);
    this.authService.logout();
    // this.authService.logout().pipe(first())
    // .subscribe(
    //   data => {
    //     //console.log(data);
    //     if(data.success){
    //       //console.log("   kkkkkkkk   ooooooo  ");
    //       window.location = window.location;
    //     }else{          
    //     }
    //   },
    //   error => {
    //     //this.alertService.error(error);
    //     //console.log(error);
    //   }        
    // );;

  }

  getBusinessList() {
    this.authService.getBusinessList().subscribe((data:any)=>{
      //this.ngxService.stopLoader('loader-01');   
      if(!data.success){
        //this.isLoadedBusiness = true;
          //this.ngxService.stopLoader('loader-01');
      }
      else{      
      }      
    },err=>{   
      //this.ngxService.stop();
      //this.toastr.error('Error on login.');
        //this.ngxService.stop();
      //this.ngxService.stopLoader('loader-01');            
    });
   }


}
