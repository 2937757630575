//import { Component } from '@angular/core';
import { Component, Inject, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Globals } from 'src/app/utility/globals';
//import { MessagingService } from "./shared/messaging.service";
import { MessagecenterService } from "../app/message-center/messagecenter.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Fortress';
  isSidebarToggeled = false;
  globals: any;
  message;
  @ViewChild('messagecontainer', { read: ViewContainerRef }) entry: ViewContainerRef;
    //constructor(private resolver: ComponentFactoryResolver) { }

  constructor (
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    //private messagingService: MessagingService, // for Push Notification   
    private messagingService: MessagecenterService, // for Push Notification   
    private resolver: ComponentFactoryResolver 
  ) { 
    this.globals= Globals;
  }

  ngOnInit() {
    //this.renderer.addClass(this.document.body, this.getClasses());
   // const userId = 'user001';
    //this.messagingService.requestPermission(userId)
    this.messagingService.receiveMessage();
    //this.message = this.messagingService.currentMessage
  }

   

  // getClasses() {
  //   if(this.globals.isSidebarToggeled){
  //     return 'menu-collapsed';
  //   }
  //   else{
  //     return 'menu-expanded';
  //   }
  // }

  
}
