import { Component, OnInit, EventEmitter, ViewEncapsulation, AfterContentChecked } from '@angular/core';
import { NewsFeedAddModel } from '../../shared/models/news-feed.model' 
import { FormBuilder, FormGroup, Validators, NgForm,FormControl, } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NewsFeedService } from '../news-feed.service';
import { FileUploadService } from '../../file-upload/file-upload.service';
import { InvoiceResponse, ClentResponsex, TransactionResponse, NewsFeedResponse, FileUploadResponse, ClentResponsexNew, AudienceResponse } from 'src/app/shared/models/response.model';
import { Utility } from 'src/app/utility/utility';
import { ClientsService } from 'src/app/clients/clients.service';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { ClientViewModel } from 'src/app/shared/models/client.model';
import { FileUploadAddModel } from 'src/app/shared/models/file-upload.model';
import { DocType } from 'src/app/shared/models/db-enums.model';
import { TransactionViewModel } from 'src/app/shared/models/transaction.model';
import { AppConstants } from '../../utility/app-constants';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CookieService } from 'ngx-cookie-service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AudienceService } from 'src/app/audience/audience.service';
import { AudienceAddModel } from 'src/app/shared/models/audience.model';
import {NgbDateStruct, NgbTimepickerConfig} from '@ng-bootstrap/ng-bootstrap';
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

import 'quill-emoji';
import { DatePipe } from '@angular/common';
// import { QuillModule } from 'ngx-quill';
// import * as Emoji from "quill-emoji";

@Component({
  selector: 'app-add-news-feed',
  templateUrl: './add-news-feed.component.html',
  styleUrls: ['./add-news-feed.component.css'],
  providers: [NgbTimepickerConfig],
  encapsulation: ViewEncapsulation.None,
})
export class AddNewsFeedComponent implements OnInit, AfterContentChecked {

  newsFeedModel : NewsFeedAddModel ;
  fileUploadAddModel : FileUploadAddModel;
  fileUploadCoverAddModel : FileUploadAddModel;
  //clinics : ClinicViewModel[];
  //patientClinic:ClinicViewModel; 
  title;
  data;
  errors;
  documentLabel = "Choose file";
  documentLabelCover = "Choose file";
  richTextForm: FormGroup;

  patientAddForm: FormGroup;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  maxdate :any;
  //public utility: utility;
  Isvalid:boolean;
  clientList:ClientViewModel[];
  transactionList:TransactionViewModel[];
  docTypes = DocType;
  documentType1 :any;
  uploadDocumentEnable:boolean = true;
  acceptDocType : string;
  disableDocumentType:boolean = false;
  companyCode:any;
  isLoaded: boolean;
  audienceList :AudienceAddModel[];
  uploadType:string = 'NoSelect';
  docUploadSuccess: boolean = false;
  gridViewStatus : boolean;
  docUplodLable : string;
  maxChars = 120;
  chars = 0;
  isScheduleNewsfeed : boolean = false;
  // time: NgbTimeStruct = {hour: 13, minute: 30, second: 0};
  model: NgbDateStruct;
  minDate = undefined;
  isExpiredShow : boolean = false;
  public mobileW: number = 320;
  public url: string | ArrayBuffer;
  public mobileH: number;
  public mobileLink: string;
  
  constructor(
    private http:HttpClient,
    private clientsService: ClientsService, 
    private formBuilder: FormBuilder,
    private router: Router, 
    private fileUploadService: FileUploadService, 
    private newsFeedService: NewsFeedService,
    public modalRef: BsModalRef,
    private toastr: ToastrService, 
    private ngxService: NgxUiLoaderService, 
    private cookieService: CookieService,
    private audienceService: AudienceService,
    private _fb: FormBuilder,
    public datepipe: DatePipe,
    config: NgbTimepickerConfig
  ) {
    config.seconds = false;
    config.spinners = false;

      const current = new Date();
      this.minDate = {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate()
      };
    
   }
 
  ngOnInit() {

    // this.richTextForm = this._fb.group({
    //  title: ["Hello, I am Trung", Validators.required],
    //   description: [
    //     `<h2><u>This is </u>a <span style=\"color: rgb(240, 102, 102);\">RICH</span> <strong>text editor</strong> <em>for</em> - <a href=\"http://jira.trungk18.com/\" rel=\"noopener noreferrer\" target=\"_blank\">http://jira.trungk18.com/</a></h2><h3><span style=\"color: rgb(153, 51, 255);\">I hope you </span><strong style=\"color: rgb(153, 51, 255);\">like it!</strong></h3>`
    //   ]
    // });
   

    this.getAudienceList();
    // this.documentType1 = [{"id":"0","value":"Please select"},{"id":"1","value":"Jpg"},{"id":"2","value":"Png"},{"id":"3","value":"Pdf"},{"id":"4","value":"Doc"},{"id":"5","value":"Video"}]; 
    this.documentType1 = [{"id":"1","value":"No select"},{"id":"2","value":"Image"},{"id":"3","value":"Document"},{"id":"4","value":"Video"},{"id":"5","value":"Web Link"}]; 
    this.Isvalid = true;
    this.companyCode = this.cookieService.get('companyCode');

    this.newsFeedModel = new NewsFeedAddModel();
    this.fileUploadAddModel = new FileUploadAddModel();
    this.fileUploadCoverAddModel = new FileUploadAddModel();

    this.newsFeedModel.time = '01:00';
    this.newsFeedModel.amPm = 'AM';

    this.newsFeedModel.title = '';
    if(this.data.newsFeed !=null){
      Object.assign(this.newsFeedModel, this.data.newsFeed);
    }

    this.gridViewStatus = this.data.gridViewStatus;
    //this.getClientList();
    //this.getNewsFeedList();
    this.newsFeedModel.documentType = "1"
    this.newsFeedModel.audinceID = null;
    this.newsFeedModel.isExpiredInput = false;
    this.newsFeedModel.isSilentPush  = true;
    this.newsFeedModel.expiredDate = this.futureDate();

  }

  ngAfterContentChecked() {
    const calc = document.getElementById('outer-d').clientWidth - 40;
    if (calc > 400) {
      this.mobileW = 280;
    } else {
      this.mobileW = calc;
    }
    this.mobileH = (this.mobileW * 1.7) + 63;
  }

  onSubmit(form:NgForm){
    let title = this.newsFeedModel.title;
    let message = this.newsFeedModel.message;  
    let audinceID = this.newsFeedModel.audinceID;
    let date = this.newsFeedModel.date;
    let time = this.newsFeedModel.time;
    let expiredDate = this.newsFeedModel.expiredDate;
    
    if(form.invalid || audinceID==null || (title && title.trim()=="") || (message && message.trim()=="") || (date && date.length==0) || (time && time.length==0) || (expiredDate && expiredDate.length==0) ) { // || !this.newsFeedModel.documentType || this.newsFeedModel.documentType == "1"
    
      if(title && title.trim()==""){
        form.controls['title'].setErrors({'required': true});
      }
      if(message && message.trim()==""){
        form.controls['message'].setErrors({'required': true});
      }
      if(audinceID == null){
        form.controls['audinceID'].setErrors({'required': true});
      }

      if(this.isScheduleNewsfeed){
        if(date == null){
          form.controls['date'].setErrors({'required': true});
        }
        if(time == null){
          form.controls['time'].setErrors({'required': true});
        }
      }

      if(this.isExpiredShow){
        if(expiredDate == null){
          form.controls['expiredDate'].setErrors({'required': true});
        }
      }

      if(form.pristine){
      }
      this.Isvalid = false;
      // console.log(form.invalid);  
      //console.log(this.Isvalid);
    }
    
    else{ 
      this.Isvalid = true;
      if(this.isScheduleNewsfeed){
        // let year = form.value.date.year;
        // let month = form.value.date.month;
        // let day = form.value.date.day;
        // let hour = form.value.time.hour;
        // let minute = form.value.time.minute;
        // let second = form.value.time.second;
        // var fullDate = new Date(year, month-1, day, hour, minute, second);
        // // fullDate.setTime( fullDate.getTime() + fullDate.getTimezoneOffset()*60*1000 );
        // // let date = this.msqlDateFormat(this.newsFeedModel.date);
        let hour = form.value.time;
        let amPm = form.value.amPm;
        let time = hour+ ' ' +amPm;
        let time24 = this.convertTime12to24(time);
        let date = this.msqlDateFormat(form.value.date);
        let fullDate = date+' '+time24+':00.0000000';

        var date_1 = new Date(fullDate);
        this.newsFeedModel.sheduledDate= date_1.toISOString(); // fullDate; // fullDate.toISOString();
      }

      if(!this.isExpiredShow){
        let date = this.msqlDateFormat(form.value.expiredDate);
        let fullDate = date +' '+'23:50.0000000';
        var date_1 = new Date(fullDate);
        this.newsFeedModel.expiredDate= date_1.toISOString(); // fullDate; // fullDate.toISOString();
      } else {
        this.newsFeedModel.expiredDate = null;
      }

      if(this.newsFeedModel.isExpiredInput) {
        this.newsFeedModel.isExpired = false
      } else {
        this.newsFeedModel.isExpired = true
      }

      if(form.value.isSilentPush) {
        this.newsFeedModel.isApplyToBatchCount = false
      } else {
        this.newsFeedModel.isApplyToBatchCount = true
      }


      this.ngxService.start();
      // console.log(form.invalid);
      // console.log(this.newsFeedModel);  

      this.fileUploadCoverAddModel.fileCategory = "3";
      this.fileUploadCoverAddModel.fileType = this.newsFeedModel.documentType;
      this.fileUploadCoverAddModel.userId = this.cookieService.get('CurrentUserId'); //JSON.parse(localStorage.getItem('CurrentUserId'));

      if(this.uploadType == 'Doc' || this.uploadType == 'Image') {
        this.fileUploadAddModel.fileCategory = "3";
        this.fileUploadAddModel.fileType = this.newsFeedModel.documentType;
        this.fileUploadAddModel.userId = this.cookieService.get('CurrentUserId'); //JSON.parse(localStorage.getItem('CurrentUserId'));

        this.fileUploadService.uploadFile(this.fileUploadAddModel).subscribe((data:FileUploadResponse)=>{ 
        
          if(data.error && !data.success){
            this.ngxService.stop();         
            this.errors = Utility.GetLocalErrorString(data.error.errorCode);
            //console.log(this.errors);
          }
          else{
            this.newsFeedModel.documentURL = data.response.s3Key;
            this.docUploadSuccess = true;
            this.addData();
          }
        },err=>{ 
          this.ngxService.stop();  
          this.toastr.error('Document upload error.');
        });
      }
      else {
        this.addData();
      }
    }
  }

  addData(){
    this.fileUploadService.uploadFile(this.fileUploadCoverAddModel).subscribe((data:FileUploadResponse)=>{ 
        
      if(data.error && !data.success){
        this.ngxService.stop();         
        this.errors = Utility.GetLocalErrorString(data.error.errorCode);
        //console.log(this.errors);
      }
      else{
        // this.newsFeedModel.documentURL = data.response.s3Key;
        this.newsFeedModel.coverImageurl = data.response.s3Key;
        this.newsFeedModel.version = "v1";
        this.newsFeedModel.newsFeederId = this.cookieService.get('CurrentUserId'); //JSON.parse(localStorage.getItem('CurrentUserId'));
        this.newsFeedModel.newsRecipients = [];
        this.newsFeedModel.isToAll = false;
        // this.newsFeedModel.audinceID = form.value.audinceID;
        //this.toastr.success('News feed added successfully.');
        // this.event.emit('OK');
        // this.modalRef.hide();
          //console.log(this.newsFeedModel);

          this.newsFeedService.addNewsFeed(this.newsFeedModel, this.companyCode).subscribe((data:NewsFeedResponse)=>{  
          if(data.error && !data.success){
            this.ngxService.stop();
            //this.errors = Utility.GetLocalErrorString(data.error.errorCode);
            if(data.error.message) {
              this.toastr.error(data.error.message);
            } else {
              this.toastr.error('Error on saving news feed.');
            }
            
            //console.log(this.errors);
          }
          else{
            this.ngxService.stop();
            this.toastr.success('News feed added successfully.');
            this.event.emit('OK');
            // this.event.emit(this.gridViewStatus);
            this.modalRef.hide();
          }      
          },err=>{   
            this.ngxService.stop();
            this.toastr.error('Error on saving news feed.');
          });
      }
      
    },err=>{ 
      this.ngxService.stop();  
      this.toastr.error('Error on saving news feed.');
    });
  }
  
  onFileChange(event){
    //this.fileUploadAddModel.file = event.target.files[0]; 
    if(event.target.files[0]){
      this.fileUploadAddModel.file = <File>event.target.files[0];
      this.documentLabel = event.target.files[0].name;
      this.disableDocumentType = true;
    } 
    else{
      this.documentLabel = "Choose file";
      this.disableDocumentType = false;
    } 
    //console.log(this.fileUploadAddModel); 
  }

  onDocumentTypeChange(docType){
    this.uploadDocumentEnable = false; 
    

    if(docType==1){
      this.uploadType = 'NoSelect';
    }
    
    // if(docType==1){
    //   this.acceptDocType = ".jpg,.jpeg";
    // }else if(docType==2){
    //   this.acceptDocType = ".png";
    // }else if(docType==3){
    //   this.acceptDocType = ".pdf";
    // }else if(docType==4){
    //   this.acceptDocType = ".doc,.docx"; 
    // }

    if(docType==2){
      this.acceptDocType = ".jpg,.jpeg,.png";
      this.uploadType = 'Image';
      this.docUplodLable = 'Image';
      this.mobileLink = 'VIEW IMAGE'
    }else if(docType==3){
      this.acceptDocType = ".pdf,.doc,.docx";
      this.uploadType = 'Doc';
      this.docUplodLable = 'Document';
      this.mobileLink = 'READ DOCUMENT'
    }else if(docType==4 ){
      this.uploadDocumentEnable = true;
      this.uploadType = 'Video';
      this.docUplodLable = 'Video Link';
      this.mobileLink = 'VIDEO LINK'
    }else if(docType==5 ){
      this.uploadDocumentEnable = true;
      this.uploadType = 'WebLink';
      this.docUplodLable = 'Web Link';
      this.mobileLink = 'WEB LINK'
    }
  }

  onFileChangeCover(event){
    //this.fileUploadCoverAddModel.file = event.target.files[0]; 
    if(event.target.files[0]){
      const files = event.target.files;
      this.fileUploadCoverAddModel.file = <File>files[0];
      this.documentLabelCover = files[0].name;

      const reader = new FileReader();
      reader.readAsDataURL(files[0]); 
      reader.onload = (_event) => { 
          this.url = reader.result; 
      }
    } 
    else{
      this.documentLabelCover = "Choose file";
    } 
    //console.log(this.fileUploadCoverAddModel); 
  }

  getClientList(){
    this.clientsService.clientList().subscribe((data:ClentResponsexNew)=>{     
      this.clientList = data.response.data.filter(function(client){
        //console.log('Client list for add news ---------');
        //console.log(this.clientList);
       // client.name = client.client.firstName+' '+client.client.lastName;
        return client.client !== null;
      });

      // console.log('Client list for add news ---------');
      // console.log(this.clientList);
    });

    // this.clientsService.clientList().subscribe((data:ClentResponsex)=>{     
    //   this.clientList = data.response;      
    //   console.log('Client list for invoices ---------');
    //   console.log(this.clientList);
    //   },
    //   (err: HttpErrorResponse)=>{
    //     console.log("Error"+ err.message);
    //   });      
  }
  
  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

  scheduleNewsfeed(isScheduleNewsfeed){
    this.isScheduleNewsfeed = !isScheduleNewsfeed;
  }

  isExpiredClick(isExpiredShow){
    this.isExpiredShow = !isExpiredShow;
  }


  // QuillConfiguration = {
  //   toolbar: [
  //     ["bold", "italic", "underline", "strike"],
  //     ["blockquote", "code-block"],
  //     [{ list: "ordered" }, { list: "bullet" }],
  //     [{ header: [1, 2, 3, 4, 5, 6, false] }],
  //     [{ color: [] }, { background: [] }],
  //     ["link"],
  //     ["clean"]
  //   ]
  // };


  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    // height: '10rem',
    // minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  }
  
  ClosePopup(){
    this.modalRef.hide();
  }

  get descriptionRichControl() {
    return this.richTextForm.controls.description as FormControl;
  }

  getAudienceList(){
    //this.sidebarService.preloaderAction(true);
    this.audienceService.audienceList().subscribe((data:AudienceResponse)=>{   
      this.isLoaded = true;  
      this.audienceList = data.response;
      
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });      
  }

  wordCounter (str) {
    if(str){
      this.chars = str.split(" ").length;
    }
    
    return this.chars;
  }

  msqlDateFormat(inDate) {
    var month;
    var day;
    month = ("0" + (inDate.month)).slice(-2);
    day = ("0" + (inDate.day)).slice(-2);
    return inDate.year + '-' + month + '-' + day;
  }

  convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
  
    let [hours, minutes] = time.split(':');
  
    if (hours === '12') {
      hours = '00';
    }
  
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
  
    return `${hours}:${minutes}`;
  }

  futureDate() {
    var future = new Date();
    future.setDate(future.getDate() + 30);
    // let dd = new Date(this.newsFeedModel.expiredDate+'Z'); // (this.newsFeedModel.sheduledDate+'Z');
    var fromDate = this.datepipe.transform(future, 'yyyy-MM-ddTHH:mm:ss' );
    let date = this.ngbDateFormat(fromDate);
    return date;
  }

  ngbDateFormat(date) {
    if (date) {
      const [year, month, day] = date.split('-');
      const obj = { year: parseInt(year), month: parseInt(month), day: parseInt(day.split(' ')[0].trim()) };
      return obj;
    }
  }

}
