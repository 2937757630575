<form #f="ngForm" novalidate class="form" (ngSubmit)="onSubmit(f)" >

<div class="modal-body" style="padding-top: 0px;padding-bottom: 0px;">   

      <div class="row">
           <div class="col-md-5 appoint_5">
              <div class="card-body text-center appoint_5_card">
                  <!-- <h1 class="display-4 text-white">{{ [appointmentD] | date: 'dd'}}</h1>
                  <h3 class="text-white">{{ [appointmentD] | date: 'MMM'}} {{ [appointmentD] | date: 'yyyy'}}</h3> <span class="text-white">{{ hr }}:{{ min }}{{ amP }}</span> -->
                  <h1 class="display-4 text-white">{{ sheduledDate.value | date: 'dd'}}</h1>
                  <h3 class="text-white">{{ sheduledDate.value | date: 'MMM'}} {{ sheduledDate.value | date: 'yyyy'}}</h3> <span class="text-white">{{ sheduledDate.value | date: 'hh'}}:{{ sheduledDate.value | date: 'mm'}}{{ sheduledDate.value | date: 'a'}}</span>
              </div>
              <div class="card-body">
                  <table class="table table-borderless table-sm pro_tab1 appoint_pop">
                      <tbody>
                          <!-- <tr>
                              <td class="profile_td text-white">ADVISOR&nbsp;:</td>
                              <td class="text-left text-white"> 
                                    <ng-container *ngIf="appointmentModel.appoinmentWith_.fortressAdmin != null">
                                        {{appointmentModel.appoinmentWith_.fortressAdmin.firstName | limitTo : 20}} {{appointmentModel.appoinmentWith_.fortressAdmin.lastName | limitTo : 20}}
                                    </ng-container>
                                    <ng-container *ngIf="appointmentModel.appoinmentWith_.fortressAdmin == null">
                                        {{appointmentModel.appoinmentWith_.userName}}
                                    </ng-container>
                                  
                              </td> 
                          </tr> -->
                          <tr>
                              <td class="profile_td text-white" style="width: 67px !important;">CLIENT&nbsp;:</td>
                              <td class="text-left text-white" data-toggle="tooltip" title="{{ clientName }}"> 
                                    {{ clientName | limitTo : 30}}
                                    <!-- <ng-container *ngIf="appointmentModel.client.client != null">
                                        {{appointmentModel.client.client.firstName}} {{appointmentModel.client.client.lastName}}
                                    </ng-container>
                                    <ng-container *ngIf="appointmentModel.client.client == null">
                                        {{appointmentModel.client.userName}}
                                    </ng-container> -->
                              </td>
                          </tr>
                          <!-- <tr>
                              <td class="profile_td text-white">DURATION</td>
                              <td class="text-left text-white">: {{ dur }} HOUR</td>       
                          </tr> -->
                      </tbody>
                  </table>
              </div>
          </div> 

          <div class="col-md-7">
              <div class="modal-header a_modal_appoint">
                  <h2 class="modal-title" id="myModalLabel1">{{title}}</h2>
                  
                  <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
              </div>
              
                  <div class="form-body form_appoint">

                    
                      <div class="row">

                          <div class="col-md-12" style="display: none;">
                              <div class="form-group">
                                  <div class="select_com" class="c_n_fs"> Client *</div>

                                  <input type="text" id="clientId" required rows="4" class="form-control c_n_form appoint_select" name="clientId" [(ngModel)]='appointmentModel.clientId' #clientId = "ngModel">
                                  
                                  <!-- <select required class="select2 appoint_select form-control" (change)="getClientName($event)" name="clientId" [(ngModel)]='appointmentModel.clientId' #clientId = "ngModel">
                                      <ng-container *ngFor="let client of clientList">
                                          <ng-container *ngIf="client.client != null">
                                              <option   [value]="client.id">
                                                  {{client.client.firstName}} {{client.client.lastName}}
                                                </option>    
                                            </ng-container>
                                      </ng-container>            
                                  </select>  -->

                                    <!-- <option *ngFor="let client of clientList"  [value]="client.client.id">                                       
                                        <ng-container *ngIf="client.client != null">
                                            {{client.client.firstName}} {{client.client.lastName}}
                                          </ng-container>
                                          <ng-container *ngIf="client.client == null">
                                              {{client.userName}}
                                            </ng-container>
                                      </option> -->

                                 <!-- <div class="form-group">
                                    <div *ngIf="clientId.invalid && (clientId.dirty || clientId.touched)"
                                        class="alert alert-danger">
                                      <div *ngIf="clientId.errors.required">
                                        Please select a client.
                                      </div>                                        
                                    </div>
                                  </div>  -->
                                  
                              </div>
                          </div>
                          <div class="col-md-12">
                              <div class="form-group">
                                  <label for="date1" class="c_n_fs">Appointment Date *</label>
                                  <fieldset [disabled]="scheduledDateDisabled"  >
                                        <app-date-time-picker id="sheduledDate"  required [(ngModel)]="appointmentModel.sheduledDate" name="sheduledDate" #sheduledDate = "ngModel" (click)="changeInput()"></app-date-time-picker>
                                    </fieldset>

                                  <!-- <input id="sheduledDate" required  type="date"  (change) = "getAppointmentDate($event)" class="form-control datedrop_appoint" name="sheduledDate"  placeholder="" [(ngModel)]="appointmentModel.sheduledDate" #sheduledDate = "ngModel"> -->
                                </div>

                                  <div class="form-group">
                                    <div *ngIf="sheduledDate.invalid && (sheduledDate.dirty || sheduledDate.touched)"
                                    class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                      <div *ngIf="sheduledDate.errors.required">
                                          Appointment date is required.
                                      </div>  
                                      <div *ngIf="!sheduledDate.valid">
                                          Appointment date must be greater than now.
                                      </div>                                                
                                    </div>
                                  </div>

                          </div>

                          <div class="col-md-12">
                                <div class="form-group">
                                    <label for="projectinput8" class="c_n_fs">Reason *</label>
                                    <input type="text" id="reason" required rows="4" class="form-control c_n_form appoint_select" name="reason" [(ngModel)]="appointmentModel.reason" #reason = "ngModel" placeholder="Reason" (click)="changeInput()">
                                    <!-- <input id="summary" required  type="text"  class="form-control" name="summary" placeholder="" [(ngModel)]="newsFeedModel.summary" #summary = "ngModel"> -->

                                    <div class="form-group">
                                      <div *ngIf="reason.invalid && (reason.dirty || reason.touched)"
                                      class="form_alert">
                                      <span  class="ft-alert-circle"></span>
                                        <div *ngIf="reason.errors.required">
                                          Reason is required.
                                        </div>                                                  
                                      </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-12">                                   
                                <div class="" style="padding-right: 0px;"> 
                                    <div class="form-group">
                                        <label for="issueinput5" class="c_n_fs">Status *</label> 
                                            <!-- <fieldset [disabled]="appoinmentStatusDisabled"  > // comment by thilina , Ishara gave permishon -->
                                                <fieldset [disabled]="appoinmentStatusDisabled"  >
                                                <select required class="select2 appoint_select form-control" name="appoinmentStatus" [(ngModel)]='appointmentModel.appoinmentStatus' #appoinmentStatus = "ngModel" (change)="changeInput()">
                                                    <option value="1">Scheduled</option>
                                                    <option value="3">Cancel</option>
                                                    <option value="4">Complete</option>
                                                    <option value="5">Expire</option>  
                                                    <option value="6">Delete</option>     
                                                    
                                                </select>  
                                            </fieldset>      
                                    </div>
                                </div>
                            </div>

                          <!-- <div class="col-md-12">
                              <div class="form-group">
                                  <label for="issueinput5" class="c_n_fs">Appointment Time</label>
                                  <div class="appoint_drop">

                                      <select name="hour" [(ngModel)]='appointmentModel.hour' (change) = "getHour($event)" #hour = "ngModel" id="issueinput5" class="form-control com_drop col-md-4" data-toggle="tooltip" data-trigger="hover" data-placement="top" data-title="Priority" data-original-title="" title="">
                                          <option value="01">01</option>
                                          <option value="02">02</option>
                                          <option value="03">03</option>
                                          <option value="04">04</option>
                                          <option value="05">05</option>
                                          <option value="06">06</option>
                                          <option value="07">07</option>
                                          <option value="08">08</option>
                                          <option value="09">09</option>
                                          <option value="10">10</option>
                                          <option value="11">11</option>
                                          <option value="12">12</option>
                                      </select>

                                      <div class="form-group">
                                          <div *ngIf="hour.invalid && (hour.dirty || hour.touched)"  
                                              class="alert alert-danger">
                                            <div *ngIf="hour.errors.required">
                                                Please select an hour.
                                            </div>                                                  
                                          </div>
                                        </div>

                                      <select name="minute" [(ngModel)]='appointmentModel.minute' (change) = "getMinute($event)" #minute = "ngModel" id="issueinput5" class="form-control com_drop col-md-4" data-toggle="tooltip" data-trigger="hover" data-placement="top" data-title="Priority" data-original-title="" title="">
                                          <option value="00">00</option>
                                          <option value="05">05</option>
                                          <option value="10">10</option>
                                          <option value="15">15</option>
                                          <option value="20">20</option>  
                                          <option value="25">25</option>
                                          <option value="30">30</option>
                                          <option value="35">35</option>
                                          <option value="40">40</option>
                                          <option value="45">45</option>
                                          <option value="50">50</option>
                                          <option value="55">55</option>
                                      </select>
                                      
                                      <select name="amPm" [(ngModel)]='appointmentModel.amPm' (change) = "getAmPm($event)" #amPm = "ngModel" id="issueinput5" id="issueinput5" name="priority" class="form-control com_drop col-md-4" data-toggle="tooltip" data-trigger="hover" data-placement="top" data-title="Priority" data-original-title="" title="">
                                          <option value="AM">AM</option>
                                          <option value="PM">PM</option>
                                      </select>
                                  </div>
                              </div>
                          </div> -->
                          <div class="col-md-12">
                              <!-- <div class="appoint_drop"> -->
                                  <!-- <div class="col-md-6" style="padding-left: 0px;">
                                      <div class="form-group">
                                          <label for="issueinput5" class="c_n_fs">Duration</label>
                                          <div class="appoint_drop">
                                              
                                              <select name="duration" (change) = "getDuration($event)" [(ngModel)]='appointmentModel.duration' #duration = "ngModel" id="issueinput5" id="issueinput5" name="priority" class="form-control com_drop col-md-4" data-toggle="tooltip" data-trigger="hover" data-placement="top" data-title="Priority" data-original-title="" title="">
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>  
                                                  <option value="4">4</option>
                                              </select>

                                          </div>
                                      </div>
                                  </div> -->
                                  <div class="" style="padding-right: 0px;">
                                        <div class="form-group">
                                            <label for="issueinput5" class="c_n_fs">Advisor *</label>
                                            <!-- <div class="appoint_drop"> -->
  
                                                <select required class="select2 appoint_select form-control" (change) = "getAppoinmentWith($event)" name="appoinmentWith" [(ngModel)]='appointmentModel.appoinmentWith' #appoinmentWith = "ngModel">
                                                    <ng-container *ngFor="let agent of agentList">
                                                        <!-- <ng-container *ngIf="agent.fortressAdmin != null && ( (agent.fortressAdmin.firstName.trim() != null && agent.fortressAdmin.firstName.trim() != '') || (agent.fortressAdmin.lastName.trim() != null && agent.fortressAdmin.lastName.trim() != '') )"> -->
                                                        <ng-container *ngIf="agent != null && ( (agent.firstName.trim() != null && agent.firstName.trim() != '') || (agent.lastName.trim() != null && agent.lastName.trim() != '') )">
                                                            <option   [value]="agent.id">
                                                            {{agent.firstName}} {{agent.lastName}}
                                                            </option>    
                                                        </ng-container>
                                                    </ng-container>            
                                                </select> 
  
                                            <!-- </div> -->
                                        </div>
                                    </div>
                              <!-- </div> -->
                          </div>
                      </div>
                  </div>
              
              <!-- <div class="modal-footer a-footer a-footer_appoint">
                  <button type="button" class="btn btn-outline-primary btn-sm a_btn c_btn_save new_btn_width">Save</button>
              </div> -->

              <div class="modal-footer a-footer">
                  <div *ngIf="!Isvalid"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                      Please fill in all the required fields correctly.
                  </div>
                  <div *ngIf="errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                      {{errors}}
                  </div>
                  <!-- <button type="button" class="btn popup_delete_b mr-auto">Delete This Appointment</button> -->
                  <button type="submit" class="btn popup_save_b">Save</button>
              </div>
          </div>
      </div>
  
  </div>
</form>
<ngx-ui-loader></ngx-ui-loader>
