import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// import { Router } from '@angular/router';
import { AppConstants } from '../utility/app-constants';
import { JwtHelperService } from '@auth0/angular-jwt';
// import { element, defineDirective } from '@angular/core/src/render3';
import { strictEqual } from 'assert';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { User, VerifiedUser } from '../shared/models/user.model';
// import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';
import { Response,AgentResponse,AgentProfileResponse, CompanyResponse, AgentResponse2, ChangePasswordResponse } from '../shared/models/response.model';
import { AgentViewModel,AgentAddModel, UserAgentAddModel } from '../shared/models/agent.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AgentsService {

  baseUrl:String;
  authUrl: String;
  clientAuthUrl: String;
  
  
  constructor(private http:HttpClient, private cookieService: CookieService) {
    this.baseUrl = AppConstants.getBaseURL();
    this.authUrl = AppConstants.getAuthURL();
    this.clientAuthUrl = AppConstants.getClientAuthURL();
  }   

  public agentList(){       
    // return this.http.get<AgentResponse>(this.baseUrl+'/FortressAdmins');   
    // return this.http.get<AgentResponse>(this.authUrl+'/User/GetAll');

    const params = {'id': this.cookieService.get('companyId'),'isArchived': status} ;
    return this.http.get<AgentResponse>(this.authUrl+'/User/GetByCompanyId',{params});   
  }

  public getClient(id:string){
    //console.log("client service")
    //console.log(id);
    const params = new HttpParams().set('id',id);
    return this.http.get<AgentProfileResponse>(this.clientAuthUrl+'/User',{params});
  }

  public handleError(error) {
    return throwError(error);
  }

  public agentEdit(id:string,agent:UserAgentAddModel) {  
    // console.log(agent);
    //const params = new HttpParams().set('id', id); 
    const params = {
      'id': id,
      'firstName': agent.firstName,
      'lastName': agent.lastName,
      'prefferedName': agent.prefferedName,
      'designation': agent.designation,
      'contactNumber': agent.contactNumber,
      'primaryMobile': agent.primaryMobile,
      'email': agent.email,
      'userType': agent.userType,
      'companyId': agent.companyId,
      'roleId': agent.roleId,
      'isTowWayAuthActivated': false};
      // console.log(params);
    // agent.firstName = agent.fortressAdmin.firstName;
    // agent.lastName = agent.fortressAdmin.lastName;
    // agent.prefferedName = agent.fortressAdmin.prefferedName;
    // agent.contactNumber = agent.fortressAdmin.contactNumber;
    // agent.designation = agent.fortressAdmin.designation;
    // agent.email = agent.fortressAdmin.email;

    return this.http.post<AgentProfileResponse>(this.authUrl+'/User/Update', params).pipe(
      catchError(this.handleError)
      );
  }

  public agentAdd(agentModel:UserAgentAddModel){
    //const params = new HttpParams().set('userType', "1"); 

    //agentModel.userType = 1;

    //agentModel.roleId = 'Hi9XddK4N0tujQjXKgU3rQ';  
    // return this.http.post<AgentProfileResponse>(this.baseUrl +'/FortressAdmin',agentModel).pipe(
    //  catchError(this.handleError)
    //  );   
    // console.log(agentModel);
    return this.http.post<AgentProfileResponse>(this.authUrl +'/User/Register',agentModel).pipe(
      catchError(this.handleError)
      );
  }

  public enableDisable(id:string,status:string){
   // const params = new HttpParams().set('userId', id).set('isArchived', status); 
    const params = {'userId': id,'isArchived': status} ;
    // return this.http.post<Response>(this.baseUrl +'/FortressAdmin/SwitchAdminStatus?id='+id+'&isArchived='+status,{params}).pipe(
    //   catchError(this.handleError)
    //   );     

    return this.http.post<Response>(this.authUrl +'/User/SetActiveStatus',params).pipe(
      catchError(this.handleError)
    ); 
  }

  public getAllCompany(){
    return this.http.get<CompanyResponse>(this.authUrl+'/Company/GetAll');   
  }

  public getCompanyByCompanyCode(companyCode){
    const params = new HttpParams().set('companyCode',companyCode);
    return this.http.get<CompanyResponse>(this.authUrl+'/Company/GetByCompanyCode',{params});   
  }

  updatePassword(passwordChangeModel){
    // passwordChangeModel.userId = id; // this.cookieService.get('CurrentUserId'); //JSON.parse( localStorage.getItem('CurrentUserId'));
    // return this.http.post<ChangePasswordResponse>(this.authUrl +'/User/PassowrdUpdate', passwordChangeModel).pipe(
    //   catchError(this.handleError)
    // ); 
    return this.http.post<ChangePasswordResponse>(this.authUrl +'/User/AdminPassowrdUpdate', passwordChangeModel).pipe(
      catchError(this.handleError)
    ); 
  }


  restMfa(selecteduser){
  
    return this.http.patch<{data:any}>(this.authUrl +'/User/ResetMFACredentials?Id='+selecteduser," ").pipe(
      catchError(this.handleError)
    ); 
  }

}
