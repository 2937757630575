import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ClientsService } from '../clients/clients.service';
import { ClentProfileResponse } from '../shared/models/response.model';
import { AppConstants } from '../utility/app-constants';
import { ClientViewModel } from '../shared/models/client.model';
import { UnallocatedAccountsService } from './unallocated-accounts.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators'; 

@Component({
  selector: 'app-unallocated-accounts',
  templateUrl: './unallocated-accounts.component.html',
  styleUrls: ['./unallocated-accounts.component.css']
})
export class UnallocatedAccountsComponent implements OnInit {
  public dtOptions: DataTables.Settings = {};
  public client: ClientViewModel;
  public xeppoCode: number;
  public unAllocatedAccounts = [];
  public moneysoftUrl: string;
  public maxSize: number = 10
  public errorMessage: string;
  public isLoaded = false;

  public showBoundaryLinks: boolean = true;
  public showDirectionLinks: boolean = true;
  public totalRecordsCount: number;
  public currentPage: number;
  public itemsPerPage: number = 10; // Define default items per page

  public searchUnallocatedForm: FormGroup
  
  constructor(private clientsService: ClientsService, 
    private unallocatedAccountsService: UnallocatedAccountsService, 
    private toastr: ToastrService, 
    private ngxService: NgxUiLoaderService,
    private fb: FormBuilder) {
    this.moneysoftUrl = AppConstants.getMoneysoftURL();
  }

  ngOnInit(): void {
    this.dtOptions = {
      paging: false,
      info: false,
      order: [],
      processing: false,
    };
    this.initForm();
    this.serchByXeppoCode();
  }

  initForm() {
    this.searchUnallocatedForm = this.fb.group({
      xeppoGroupCode: [''],
    });
    this.isLoaded = true;
    
    this.searchUnallocatedForm.get('xeppoGroupCode').valueChanges.pipe(
      debounceTime(1000),
    ).subscribe(data => {
      if (this.searchUnallocatedForm.value.xeppoGroupCode || this.searchUnallocatedForm.value.xeppoGroupCode == "") {
        this.serchByXeppoCode()
      }
    }); 
  }

  getUnallocatedList(event?: PageChangedEvent) {
    this.isLoaded = false;
    this.currentPage = event?.page || 1;
    const skip = (event?.page - 1) * event?.itemsPerPage || 0;
    const take = event?.itemsPerPage || this.itemsPerPage; // Default records count
    this.ngxService.start();
    const xeppoGroupCode = this.searchUnallocatedForm.value.xeppoGroupCode;
    this.unallocatedAccountsService.getUnallocated(skip, take, xeppoGroupCode).subscribe((data: any) => {
      this.ngxService.stop();
      this.isLoaded = true;
      if (data.success) {
        this.unAllocatedAccounts = data.response.realTimeUnAllocatedDataList;
        this.totalRecordsCount = ((data || {}).response || {}).totalRecordsCount;
      } else {
        this.toastr.error('Something went wrong when getting unallocated data list');
      }
    }, err => {
      this.isLoaded = true;
      this.ngxService.stop();
      this.toastr.error('Something went wrong when getting unallocated data list');
    })
  }

  GetClient(clientId: string) {
    this.ngxService.start();
    this.clientsService.getClient(clientId).subscribe((clientData: ClentProfileResponse) => {
      this.client = clientData.response;
      this.ngxService.stop();
      if (this.client) {
        this.setMoneysoftLink(this.client);
      } else {
        this.toastr.error('Error on getting client details.');
      }
    }), (err: HttpErrorResponse) => {
      this.ngxService.stop();
      this.toastr.error('Error on getting client details.');
    };
  }

  setMoneysoftLink(clientData) {
    const moneysoftLink = this.moneysoftUrl + "/net-worth?&xeppoCode=" + clientData.xeppoCode + "&isMigrated=" + true + "&first_name=" + clientData.client.firstName + "&last_name=" + clientData.client.lastName + "&id=" + clientData.id;;
    window.open(moneysoftLink, "_blank")
  }

  serchByXeppoCode() {
    // if (value.length >= 2) {
      this.errorMessage = null;
      const pageEvent = {
        itemsPerPage: 10,
        page: 1
      }
      this.getUnallocatedList(pageEvent);
    // } else {
    //   this.errorMessage = value != "" ? "Xeppo code must be at least 2 characters long." : "Xeppo code is required.";
    // }
  }
}
