export class XplanCover {
    public  xFacInsuranceId : string;
    public  insuranceCoverType : string;
    public  sumInsured : string;
    public  lifeInsured : number;
    public  waitingPeriod : string;
    public  benefitPeriod : string;
    public  insuranceCoverTypeName : string;
}

export class XplanCoverAddModel extends XplanCover{    
    public  categoryList : string;         
}