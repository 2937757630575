<app-header></app-header>
<app-sidebar></app-sidebar>


<div class="app-content content">
    <div class="content-wrapper">
    <div class="content-header row mb-1">
      <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
        <h3 class="content-header-title mb-0 d-inline-block">List of Groups</h3>
        <div class="row breadcrumbs-top d-inline-block">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="index.html">Home</a>
              </li>
              <li class="breadcrumb-item active">Groups
              </li>
            </ol>
          </div>
        </div>
      </div>
      </div>
        <div class="content-body">
            <!-- Zero configuration table -->
            <section id="configuration">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Groups</h4>
                            </div>
                            <div class="card-content collapse show">
                                <div class="card-body1 card-dashboard">
                                    <table class="table table-striped table-bordered zero-configuration t_dt" style="margin-top: 20px !important;">
                                        <thead>
                                            <tr>
                                                <th>Xeppo Group Code</th>
                                                <th>Name</th>
                                                <th>Code</th>
                                                <th>Total Revenue(YTD)</th>
                                                <th>Verification Status</th>
                                                <th>Has Primary Contact</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>49954</td>
                                                <td>Nicole Lickorish Group</td>
                                                <td>0912</td>
                                                <td>$0.00</td>
                                                <td>Not Verified</td>
                                                <td class="icongroup">
                                                   <i class="icon-close"></i>
                                                </td>
                                                <td>
                                                     <button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>49955</td>
                                                <td>John Zemek Group</td>
                                                <td>0913</td>
                                                <td>$0.00</td>
                                                <td>Not Verified</td>
                                                <td class="icongroup">
                                                    <i class="icon-close"></i>
                                                </td>
                                                <td>
                                                     <button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>49956</td>
                                                <td>Elmarie Zietsman Group</td>
                                                <td>0914</td>
                                                <td>$0.00</td>
                                                <td>Not Verified</td>
                                                <td class="icongroup">
                                                    <i class="icon-close"></i>
                                                </td>
                                                <td>
                                                     <button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>49957</td>
                                                <td>Kenneth Zimmerman Group</td>
                                                <td>0915</td>
                                                <td>$0.00</td>
                                                <td>Not Verified</td>
                                                <td class="icongroup">
                                                    <i class="icon-close"></i>
                                                </td>
                                                <td>
                                                     <button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>49958</td>
                                                <td>Janie Zishke Group</td>
                                                <td>0916</td>
                                                <td>$345.45</td>
                                                <td>Not Verified</td>
                                                <td class="icongroup">
                                                   <i class="icon-close"></i>
                                                </td>
                                                <td>
                                                     <button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>49959</td>
                                                <td>Rosie Williams Group</td>
                                                <td>0917</td>
                                                <td>$0.00</td>
                                                <td>Not Verified</td>
                                                <td class="icongroup">
                                                    <i class="icon-close"></i>
                                                </td>
                                                <td>
                                                     <button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>49960</td>
                                                <td>Tammy Allwood Group</td>
                                                <td>0918</td>
                                                <td>$0.00</td>
                                                <td>Not Verified</td>
                                                <td class="icongroup">
                                                   <i class="icon-check"></i>
                                                </td>
                                                <td>
                                                     <button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>49961</td>
                                                <td>Martin Young Group</td>
                                                <td>0919</td>
                                                <td>$463.64</td>
                                                <td>Not Verified</td>
                                                <td class="icongroup">
                                                    <i class="icon-close"></i>
                                                </td>
                                                <td>
                                                     <button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>49962</td>
                                                <td>Colleen Zablocki Group</td>
                                                <td>0920</td>
                                                <td>$0.00</td>
                                                <td>Not Verified</td>
                                                <td class="icongroup">
                                                    <i class="icon-close"></i>
                                                </td>
                                                <td>
                                                     <button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>49963</td>
                                                <td>Stefano Zagami Group</td>
                                                <td>0921</td>
                                                <td>$304.55</td>
                                                <td>Not Verified</td>
                                                <td class="icongroup">
                                                    <i class="icon-close"></i>
                                                </td>
                                                <td>
                                                     <button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>49964</td>
                                                <td>Zahn Bester Group</td>
                                                <td>0922</td>
                                                <td>$345.46</td>
                                                <td>Not Verified</td>
                                                <td class="icongroup">
                                                   <i class="icon-check"></i>
                                                </td>
                                                <td>
                                                     <button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>49965</td>
                                                <td>Malita Zammit Group</td>
                                                <td>0923</td>
                                                <td>$122.73</td>
                                                <td>Not Verified</td>
                                                <td class="icongroup">
                                                   <i class="icon-close"></i>
                                                </td>
                                                <td>
                                                     <button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>49966</td>
                                                <td>Susan Zealey Group</td>
                                                <td>0924</td>
                                                <td>$245.46</td>
                                                <td>Not Verified</td>
                                                <td class="icongroup">
                                                    <i class="icon-close"></i>
                                                </td>
                                                <td>
                                                     <button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button>
                                                </td>
                                            </tr>

                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--/ Zero configuration table -->
        </div>
    </div>
</div>


<app-footer></app-footer>