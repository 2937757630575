export class XFacAddressBaseMOdel {
    public  facFinderId : string;
    public  addressType : string;
    // public  addressStatus : string;
    // public  addressPropertyNameNumber : string;
    public  street : string;
    // public  addressLine1 : string;
    // public  addressLine2 : string;
    // public  town : string;
    // public  county : string;
    public  suburb : string;
    public  postcode : string;
    //  public  country : string;
    //  public  preferred : string;
    public  clientCode : string;
    public  state : string;
    public addressTypeName : string;

}

export class XFacAddressAddModel extends XFacAddressBaseMOdel{    
       
}