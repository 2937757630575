import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  business_id: any;

  constructor(private route: ActivatedRoute, private authService: AuthService) { }

  ngOnInit() {
    //localStorage.removeItem('businessId');
    this.business_id = this.route.snapshot.queryParams['business_id'];
    if(this.business_id) { 
      localStorage.setItem('businessId', JSON.stringify(this.business_id));
      //this.getBusinessList();
    } 
  }
  isSidebarToggeled = false;

  getClasses() {
    const classes = {
      'toggeled-sidebar': this.isSidebarToggeled
    }
    return classes;
  }
  toggleSidebar() {
      return {
        isSidebarToggeled: !this.isSidebarToggeled
    }
  }

  getBusinessList() {
    this.authService.getBusinessList().subscribe((data:any)=>{
      //this.ngxService.stopLoader('loader-01');   
      if(!data.success){
        //this.isLoadedBusiness = true;
          //this.ngxService.stopLoader('loader-01');
      }
      else{      
      }      
    },err=>{   
      //this.ngxService.stop();
      //this.toastr.error('Error on login.');
        //this.ngxService.stop();
      //this.ngxService.stopLoader('loader-01');            
    });
   }

}
