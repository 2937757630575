import { Component, OnInit, EventEmitter } from '@angular/core';
import { NewsFeedAddModel } from '../../shared/models/news-feed.model' 
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NewsFeedService } from '../news-feed.service';
import { FileUploadService } from '../../file-upload/file-upload.service';
import { InvoiceResponse, ClentResponsex, TransactionResponse, NewsFeedResponse, FileUploadResponse, DocumentUrlResponse, NewsFeedResponseByID } from 'src/app/shared/models/response.model';
import { Utility } from 'src/app/utility/utility';
import { ClientsService } from 'src/app/clients/clients.service';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { ClientViewModel } from 'src/app/shared/models/client.model';
import { FileUploadAddModel } from 'src/app/shared/models/file-upload.model';
import { DocType } from 'src/app/shared/models/db-enums.model';
import { TransactionViewModel } from 'src/app/shared/models/transaction.model';
import { AppConstants } from '../../utility/app-constants';
import { ViewNewsfeeReadClientsComponent } from './view-newsfee-read-clients/view-newsfee-read-clients.component';

@Component({
  selector: 'app-view-news-feed',
  templateUrl: './view-news-feed.component.html',
  styleUrls: ['./view-news-feed.component.css']
})
export class ViewNewsFeedComponent implements OnInit {
  newsFeedModel : NewsFeedAddModel ;
  newsFeedByIdModel;
  fileUploadAddModel : FileUploadAddModel;
  title;
  data;
  errors;

  patientAddForm: FormGroup;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  maxdate :any;
  //public utility: utility;
  isLoaded:boolean;
  documentType1 :any;
  //documentUrl :string = null;
  readNotificationCount = 0;
  clientText:string;
  viewIn:string; //:'newsfeed'
  

  constructor(private http:HttpClient,private clientsService: ClientsService, private formBuilder: FormBuilder,private router: Router, private fileUploadService: FileUploadService, private newsFeedService: NewsFeedService,public modalRef: BsModalRef,private toastr: ToastrService,private modalService: BsModalService) { }

  ngOnInit() {
    //this.Isvalid = true;
    this.newsFeedModel = new NewsFeedAddModel();
    //this.fileUploadAddModel = new FileUploadAddModel();
    //console.log(this.modalRef.content);
    if(this.data.newsFeed !=null){
      //console.log(44444444);
      //console.log(this.data.newsFeed);
      //this.documentUrl = this.data.newsFeed.documentUrl;
      Object.assign(this.newsFeedModel, this.data.newsFeed);
      this.getNewsFeedById(this.data.newsFeed.id);
      
      //console.log(55555555);
      //console.log(this.newsFeedModel);
      // this.isLoaded = true;
      // if(this.newsFeedModel.documentURL != null){
      //   this.getDocumentUrl(this.newsFeedModel);
      // }
    }

    this.viewIn = this.data.viewIn;
    
  }

  getNewsFeedById(newsFeedId){
    this.newsFeedService.getByNewsFeedId(newsFeedId).subscribe((data:NewsFeedResponseByID)=>{   
      this.newsFeedByIdModel = data.response;
      this.readNotificationCount = this.countItemsTrue(this.newsFeedByIdModel.newsAudianceRecipients);
      if(this.readNotificationCount<=1) {
        this.clientText = 'client'
      } else {
        this.clientText = 'clients'
      }

      this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });      
  }
  
  viewNewsfeedreadModal() {
    this.modalRef.hide();
    this.modalRef = this.modalService.show(ViewNewsfeeReadClientsComponent,  {
      initialState: {
        title: 'Audience',      
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true,
        data: {
          newsFeed:this.newsFeedByIdModel
        }, 
      },
      class: "modal-lg_newsfeed",
      
      
    });
  
    // this.modalRef.content.event.subscribe(result => {
    //   if (result == 'OK') {
    //     //console.log("Success");
    //       //this.getNewsFeedList();
      
    //   }
    // });
  }


  // getDocumentUrl(newsFeedModel){
  //   this.newsFeedService.getDocumentUrl(newsFeedModel).subscribe((data:DocumentUrlResponse)=>{     
  //     this.documentUrl = data.response;      
  //     console.log('newsFeedList list ---------');
  //     console.log(this.documentUrl);
  //     this.isLoaded = true;
  //     },
  //     (err: HttpErrorResponse)=>{
  //       console.log("Error"+ err.message);
  //     });
  // }

  // getClientList(){
  //   this.clientsService.clientList().subscribe((data:ClentResponsex)=>{     
  //     this.clientList = data.response.filter(function(client){        
  //       return client.client !== null;
  //     });
  //     console.log('Client list for add news ---------');
  //     console.log(this.clientList);
  //   });  
  // }
  
  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }
  
  ClosePopup(){
    this.modalRef.hide();
  }

  formatLocalTime(date){
    return new Date(date+'Z');
  }

  countItemsTrue(arry){
    var result = 0;
    if(arry.length){
      for(let x = 0; x < arry.length; x++){
        if(arry[x].isRead === true){
          result++;
        }
      }
      return result;
    } else {
      return result;
    }
    
    
 
 }

}
