import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-service-agreement-update-widget',
  templateUrl: './service-agreement-update-widget.component.html',
  styleUrls: ['./service-agreement-update-widget.component.css']
})
export class ServiceAgreementUpdateWidgetComponent implements OnInit {

  isEditing = false;
  isLoading = false;

  @ViewChild('nwValue') nwValue: ElementRef;
  @Output() updateRecipientsEmail = new EventEmitter<any>();

  @Input() recipientsEmail: any;
  @Input() recipientsId: number;
  @Input() issignRec: any;
  signStatus: string;
  // Networth Snapshot Data
  // updateNetworthSnapshotData = new NetworthSnapshotData();

  constructor(
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.signStatus = this.issignRec;
    console.log("this.signStatus",this.signStatus)
  }

  

  toggleEdit() {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      setTimeout(()=>{ // this will make the execution after the above boolean has changed
        this.nwValue.nativeElement.focus();
      }, 0);
    }
  }

  updateNetworthValue(id: any, value: any) {
    if (value != this.recipientsEmail) {
      this.updateNetworthSnapshotDetails(id, value);
    } else {
      this.isEditing = false;
    }
  }

  updateNetworthSnapshotDetails(id: string, value: any) {
    this.isLoading = true;
    const pattern =  new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
    // this.updateNetworthSnapshotData.id = id;
    // this.updateNetworthSnapshotData.netWorthSnapshotDataID = this.recipientsId;
    // this.updateNetworthSnapshotData.value = Number(value);
    // this.networthHistoryService.updateNetworthSnapshotData(this.updateNetworthSnapshotData).subscribe( res => {
      if (value) {
        this.recipientsEmail = value;
        let object = {"recipientsId":this.recipientsId, "email": this.recipientsEmail}
        this.updateRecipientsEmail.emit(object);
        if(pattern.test(this.recipientsEmail)) {
          this.toastr.success('Email updated successfully');
        }
        this.isEditing = false;
      } else {
        this.toastr.error('Operaion failed.');
      }
      this.isLoading = false;
    // }, err => {
    //   this.isLoading = false;
    //   this.toastr.error('Operaion failed.');
    // })
  }

  // public openDeleteConfirmation(template: TemplateRef<any>, data: any) {
  //   this.currentValueId = data.id;
  //   this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  //   this.message = 'Are you sure that you want to delete this ( $'+data.value+' ) value ?'
  // }

  // public decline() {
  //   this.modalRef.hide();
  // }

  // deleteNetworthValue() {
  //   this.isLoading = true;
  //   const id = this.currentValueId;
  //   const isArchived = true; // Archive recorde
  //   this.networthHistoryService.deleteNetworthSnapshotData(id, isArchived).subscribe( res => {
  //     if(res.success) {
  //       this.updateRecipientsEmail.emit();
  //       this.toastr.success('Value deleted successfully');
  //     } else {
  //       this.toastr.error(res.error.message);
  //     }
  //     this.isLoading = false;
  //     this.modalRef.hide();
  //   }, err => {
  //     this.isLoading = false;
  //     this.toastr.error('Operaion failed.');
  //     this.modalRef.hide();
  //   })
  // }

}
