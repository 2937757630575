import { Component, Input, OnDestroy, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NewServiceAgreementComponent } from '../new-service-agreement/new-service-agreement.component';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { DocumentUrlResponse, ServiceAgreementProfileResponse, ServiceAgreementResponseProfile } from 'src/app/shared/models/response.model';
import { ServiceAgreementService } from 'src/app/service-agreement/service-agreement.service';
import { ServiceAgreementListModel } from 'src/app/shared/models/service-agreement.model';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ViewDocumentComponent } from 'src/app/service-agreement/view-document/view-document.component';
import { UserDocumentsService } from 'src/app/user-documents/user-documents.service';
import { ViewServiceAgreementComponent } from 'src/app/service-agreement/view-service-agreement/view-service-agreement.component';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-service-agreement-in-profile',
  templateUrl: './service-agreement-in-profile.component.html',
  styleUrls: ['./service-agreement-in-profile.component.css']
})
export class ServiceAgreementInProfileComponent implements OnInit, OnDestroy {

  modalRef: BsModalRef;
  @Input() xeppoGroupCode: string;
  @Input() xeppoCode: string;
  @Input() clientCode: string;
  @Input() keyFirmContacts: any;
  @Input() surName: string;

  isLoaded: boolean;
  serviceAgreementList: ServiceAgreementListModel[];
  serviceAgreementId: string;
  message: string;
  loaded: boolean;
  success: boolean;
  IsDraft: any;

  dtOptions = {
    pageLength: 10,
    order: [],
  };
  fdsAgreementStatus: string;
  signList : any = [];
  signRecList: any = [];

  constructor(
    private modalService: BsModalService,
    private serviceAgreementService: ServiceAgreementService,
    private toastr: ToastrService,
    private userDocumentService: UserDocumentsService,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
  ) {
    window.addEventListener('storage', () => {
      const data = sessionStorage.getItem('closeModal')
      const comp = sessionStorage.getItem('loadedComponent') === 'profSA'
      if (comp && data) {
        this.serviceAgreementId = sessionStorage.getItem('serviceAgreementId')
        if (!this.success) {
          this.confirmSendToClients(3);
          this.success = true;
        }
      }
    })
  }

  ngOnInit(): void {
    sessionStorage.setItem('loadedComponent', 'profSA')
    this.getServiceAgreementList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['xeppoCode']) {
      let xeppoCode = changes['xeppoCode'];
      let clientCode = changes['clientCode'];

      if (this.xeppoCode && this.clientCode) {
        this.getServiceAgreementList();
      }
    }
  }

  getServiceAgreementList(event?: PageChangedEvent) {
    this.isLoaded = false;
    // this.currentPage = event?.page || 1;
    // const skip = (event?.page-1) * event?.itemsPerPage || 0;
    // const take = event?.itemsPerPage || this.itemsPerPage; // Default records count

    this.serviceAgreementService.serviceAgreementByXeppoCodeClientCodeList(this.xeppoCode, this.clientCode).subscribe((data: ServiceAgreementProfileResponse) => {
      this.serviceAgreementList = data.response;
      // this.returnedNewsFeedList = this.serviceAgreementList;
      // this.totalRecordsCount = data.response.totalCount;
      this.isLoaded = true;
    }, (err: HttpErrorResponse) => {
      //console.log("Error"+ err.message);
    });
  }

  uploadnewServiceAgreement() {
    this.success = false;
    this.IsDraft = true;
    this.modalRef = this.modalService.show(NewServiceAgreementComponent, {
      initialState: {
        title: 'Documents To Action',
        data: {
          xeppoGroupCode: this.xeppoGroupCode,
          xeppoCode: this.xeppoCode,
          clientCode: this.clientCode,
          keyFirmContacts: this.keyFirmContacts,
          surName: this.surName,
          isDraft: this.IsDraft
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-xl modal-xlg",
    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        console.log("Success");
        this.getServiceAgreementList();
      }
    });
  }

  openSendToClientsDialog(template: TemplateRef<any>, newsFeedId) {
    sessionStorage.removeItem('closeModal');
    sessionStorage.removeItem('serviceAgreementId');
    sessionStorage.setItem('serviceAgreementId', newsFeedId)

    this.serviceAgreementId = newsFeedId;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    //this.idToBeDeleted = id;

    this.message = 'Are you sure that you want to send Fee Disclosure Statement to client?'
  }

  confirmSendToClients(statusId) {
    this.modalRef.hide();
    sessionStorage.removeItem('closeModal')
    this.serviceAgreementService.setFDStatus(this.serviceAgreementId, statusId).subscribe((data) => {
      sessionStorage.removeItem('serviceAgreementId')
      this.getServiceAgreementList();
      // this.router.navigate(['news-feed']);
      if (data.success) {
        this.toastr.success('Fee Disclosure Statement send to client successfully.');
      } else {
        this.toastr.error('Error on sending Fee Disclosure Statement to client.');
      }
    },
      (err: HttpErrorResponse) => {
        //console.log("Error"+ err.message);
      });
  }

  public openDeleteDialog(template: TemplateRef<any>, newsFeedId, fdsAgreementStatus: string) {

    this.serviceAgreementId = newsFeedId;
    this.fdsAgreementStatus = fdsAgreementStatus;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    //this.idToBeDeleted = id;

    this.message = 'Are you sure that you want to delete Fee Disclosure Statement ?'
  }

  decline(): void {
    this.modalRef.hide();
  }

  confirm(): void {
    this.loaded = true;
    //this.message = 'Confirmed!';
    this.modalRef.hide();
    this.ngxService.start();
    if (this.fdsAgreementStatus != "4" && this.fdsAgreementStatus != "3") {
      this.serviceAgreementService.deleteServiceAgreemen(this.serviceAgreementId).subscribe((data) => {
        this.ngxService.stop();
        this.getServiceAgreementList();
        // this.router.navigate(['news-feed']);
        if (data.success) {
          this.toastr.success('Fee Disclosure Statement deleted successfully.');
        } else {
          this.toastr.error('Error on deleting Fee Disclosure Statement.');
        }
        this.loaded = false;
      },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          this.loaded = false;
          //console.log("Error"+ err.message);
        });
    } else {
      this.serviceAgreementService.voideServiceAgreemen(this.serviceAgreementId).subscribe((data) => {
        this.getServiceAgreementList();
        this.ngxService.stop();
        // this.router.navigate(['news-feed']);
        if (data.success) {
          this.toastr.success('Fee Disclosure Statement cancelled successfully.');
        } else {
          this.toastr.error('Error on cancelling Fee Disclosure Statement.');
        }
        this.loaded = false;
      },
        (err: HttpErrorResponse) => {
          this.ngxService.stop();
          this.loaded = false;
          //console.log("Error"+ err.message);
        });
    }
    //this.delete();
  }

  viewDocumentList(serviceAgreement: any) {
    //console.log(newsFeed);
    let userId = serviceAgreement.fdsRecipients[0].userId
    let serviceDocuments = serviceAgreement.fdsServiceDocuments

    this.modalRef = this.modalService.show(ViewDocumentComponent, {
      initialState: {
        title: 'Download PDF',
        data: {
          serviceDocuments: serviceDocuments,
          userId: userId,
        },
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
      },
      class: "modal-lg_newsfeed",


    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        //this.getNewsFeedList();

      }
    });
  }

  viewDocument(agreement, envelopId) {
    this.userDocumentService.downloadDocument(agreement.id, envelopId).subscribe((data: any) => {
      const source = `data:application/pdf;base64,${data.pdf}`;
      const link = document.createElement("a");
      link.href = source;
      const today = new Date().toLocaleDateString();
      link.download = agreement.title + '_' + today + '.pdf'
      link.click();
    }, (err: any) => {
      console.log("Error" + err);
    });
  }

  viewServiceAgreement(serviceAgreement: any) {
    let userId = serviceAgreement.fdsRecipients[0].userId
    this.modalRef = this.modalService.show(ViewServiceAgreementComponent, {
      initialState: {
        title: 'Documents To Action',
        data: {
          serviceAgreement: serviceAgreement,
          userId: userId,
        },
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
      },
      class: "modal-lg"
      // class: "modal-lg_newsfeed",


    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {

      }
    });
  }

  getSignList(fdsId: any, serviceAgreement: any , signList: any){
  
    this.success = false;
    this.modalRef = this.modalService.show(NewServiceAgreementComponent, {
      initialState: {
        title: 'Edit Documents To Action',
        data: {
          fdsServiceAgreementAddModel: serviceAgreement,
          xeppoGroupCode: this.xeppoGroupCode,
          xeppoCode: this.xeppoCode,
          clientCode: this.clientCode,
          keyFirmContacts: this.keyFirmContacts,
          isDraft: this.IsDraft,
          signList: signList
        },
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
      },
      class: "modal-xl modal-xlg",

    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        this.getServiceAgreementList();
      }
    });
    this.ngxService.stop();
    this.loaded = false;
    // var filterdata = data.signers.filter(x=>x.)
    // }
    // });

  //   console.log( this.signList," this.signList");
  //     } else {
  //       console.log("check recipient details --->", data.response.signers)
  //     }
  // });
}


  //this service for use get recipient details. for check issign or not
  checksignRecipient(fdsId: any, serviceAgreement: any) {
    this.loaded = true;
    this.ngxService.start();
      //get recipient data
      this.serviceAgreementService.getRecipientsDetails(fdsId).subscribe((data: any) => {
        if (data.error && !data.success) {
  
          // this.signList = data.response.signers;
  
          // serviceAgreement.fdsRecipients.forEach(element => {
          //   this.signRecList = this.signList.filter(x => x.email == element.email);
  
          //   if (this.signRecList != null && this.signList[0].status == "sent")
          //     element.isSignByRec = true;
  
          // });
        }else{
          
          this.ngxService.stop();
          this.loaded = false;
          this.getSignList(fdsId , serviceAgreement , data.response.signers);
        }
      });
  }

  editServiceAgreement(serviceAgreement: any) {
    console.log("serviceAgreement --------",serviceAgreement)
    this.loaded = true;
    this.ngxService.start();
    this.IsDraft = false;
    sessionStorage.setItem('servicetId',serviceAgreement.id)
    if (serviceAgreement?.isDocVisibleToClient) {
      this.serviceAgreementService.GetSetLockInfo(serviceAgreement.id).subscribe((data: any) => {
        if (data.error && !data.success) {
          this.loaded = false;
          this.ngxService.stop();
          if (data.error.message) {
            this.toastr.error(data.error.message);
          } else {
            this.toastr.error('Error on creating envelope.');
          }
        } else { 
          this.loaded = false;
          this.ngxService.stop();
          console.log("getLockInfor", data.response)
          const lockInfor = data.response;
          sessionStorage.setItem('lockToken', lockInfor.lockToken);
          sessionStorage.setItem('lockedByApp', lockInfor.lockedByApp);

          const checkrecipuent = this.checksignRecipient(serviceAgreement.id, serviceAgreement)
        }
      }, err => {
        this.loaded = false;
        this.ngxService.stop();
        this.toastr.error('Error on creating envelope.');
      });

    } else {
      const checkrecipuent = this.checksignRecipient(serviceAgreement.id, serviceAgreement)
      console.log("Ok", checkrecipuent)
    }

  }

  isSignedUsers(fdsRecipients) {

    const element = fdsRecipients.find((value) => {
      return value.isSigned === true;
    });
    return typeof element === "object" ? true : false;
  }

  isExpirySign(dateExpiry) {
    const today = new Date()
    const dateExpiryD = new Date(dateExpiry)

    // console.log('dateExpiry =', dateExpiryD)
    // console.log('today =', today)
    if (dateExpiryD.setHours(0, 0, 0, 0) - today.setHours(0, 0, 0, 0) >= 0) { //first date is in future, or it is today
      return false
    }
    return true
  }

  createEnvelop(serviceAgreementData) {
    this.loaded = true;
    this.ngxService.start();
    this.serviceAgreementService.setFDStatus(serviceAgreementData.id, 2).subscribe((data: ServiceAgreementResponseProfile) => {
      if (data.error && !data.success) {
        this.loaded = false;
        this.ngxService.stop();
        if (data.error.message) {
          this.toastr.error(data.error.message);
        } else {
          this.toastr.error('Error on creating envelope.');
        }
      } else {
        this.serviceAgreementService.serviceAgreementByXeppoCodeClientCodeList(this.xeppoCode, this.clientCode).subscribe((data: ServiceAgreementProfileResponse) => {
          if (data.success) {
            this.serviceAgreementList = data.response;
            //get updated agreement data to get envelop id
            serviceAgreementData = this.serviceAgreementList.filter((a, i) => {
              if (a.id === serviceAgreementData.id) {
                return this.serviceAgreementList[i]
              }
            })
            this.sendToClients(serviceAgreementData[0]);
          } else {
            this.loaded = false;
            this.ngxService.stop();
          }
        }, (err: HttpErrorResponse) => {
        });
      }
    }, err => {
      this.loaded = false;
      this.ngxService.stop();
      this.toastr.error('Error on creating envelope.');
    });
  }

  sendToClients(serviceAgreementData) {
    this.success = false;
    sessionStorage.setItem('serviceAgreementId', serviceAgreementData.id)
    this.ngxService.start();
    this.loaded = true;
    this.serviceAgreementService.getEnvelopEditUrl(serviceAgreementData.id, serviceAgreementData.envelopeID).subscribe((res: any) => {
      if (res.success) {
        const url = res.response
        this.modalRef = this.modalService.show(NewServiceAgreementComponent, {
          initialState: {
            title: 'Documents To Action',
            data: {
              url: url
            },
            keyboard: false,
            backdrop: true,
            ignoreBackdropClick: true
          },
          class: "modal-xl modal-xlg",
        });

        this.modalRef.content.event.subscribe(result => {
          if (result == 'OK') {
            this.getServiceAgreementList();
          }
        });
      } else {
        this.modalRef.hide();
      }
      this.ngxService.stop();
      this.loaded = false;
    }, err => {
      this.loaded = false;
      this.ngxService.stop();
    })
  }

  GetEnvelopeCorrectViewByFdsId(serviceData: any) {
    this.ngxService.start();
    this.loaded = true;
    this.serviceAgreementService.setFDStatus(serviceData.id, 4).subscribe((data) => {
      if (data.success) {
        this.getServiceAgreementList();
      } else {
        this.toastr.error('Error on sending Fee Disclosure Statement to client.');
      }
    },
      (err: HttpErrorResponse) => {
        console.log("Error" + err.message);
      });
    this.serviceAgreementService.GetEnvelopeCorrectViewByFdsId(serviceData.id).subscribe((res: any) => {
      if (res.success) {
        this.loaded = false;
        const url = res.response
        this.modalRef = this.modalService.show(NewServiceAgreementComponent, {
          initialState: {
            title: 'Documents To Action',
            data: {
              url: url,
              IsCorrect: true,
              Id: serviceData.id
            },
            keyboard: false,
            backdrop: true,
            ignoreBackdropClick: true
          },
          class: "modal-xl modal-xlg",
        });

        this.modalRef.content.event.subscribe(result => {
          if (result == 'OK') {
            this.getServiceAgreementList();
          }
        });

      
      } else {
        this.modalRef.hide();
      }
      this.ngxService.stop();
      this.loaded = false;
    }, err => {
      this.loaded = false;
      this.ngxService.stop();
    });

  }


  ngOnDestroy() {
    sessionStorage.removeItem('loadedComponent')
  }
}
