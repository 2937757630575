import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CreateAudienceComponent } from './create-audience/create-audience.component';
import { ViewAudienceComponent } from './view-audience/view-audience.component';
import { AudienceResponse } from '../shared/models/response.model';
import { AudienceAddModel } from '../shared/models/audience.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AudienceService } from './audience.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.css']
})
export class AudienceComponent implements OnInit {

  dtOptions : DataTables.Settings = {}; 
  isLoaded : boolean;
  audienceList :AudienceAddModel[];
  audienceId :string;
  message : string;

  constructor(
    private router: Router,
    private modalService: BsModalService,
    private audienceService: AudienceService,
    private toastr: ToastrService,
  ) { }

  modalRef: BsModalRef;
 

  ngOnInit() {
    this.getAudienceList();

    this.dtOptions = {
      //pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      order :[],
     // responsive:true,
    };  
  }

  getAudienceList(){
    //this.sidebarService.preloaderAction(true);
    this.audienceService.audienceList().subscribe((data:AudienceResponse)=>{   
      this.isLoaded = true;  
      this.audienceList = data.response.filter(function(audience){
        return audience.isArchived == false;
      }); 
      // this.audienceList = data.response;
      this.audienceList = this.audienceList.sort((val1, val2)=> {return <any>new Date(val2.createdDate) - <any>new 
        Date(val1.createdDate)})
      
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });      
  }

  viewAudience(id){
    this.router.navigate(['/view-audience']);
  }

  createAudience() {
    this.modalRef = this.modalService.show(CreateAudienceComponent,  {
      initialState: {
        title: 'Add Audience',
        data: {},
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      
      if (result == 'OK') {
        console.log('add audience');
        this.getAudienceList();
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['audience']);
          
      }

    });
  }

  public openDeleteDialog(template: TemplateRef<any>,id) {

    this.audienceId = id;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    //this.idToBeDeleted = id;

    this.message = 'Are you sure that you want to delete this audience?'
  }

  decline(): void {
    this.modalRef.hide();
  }

  confirm(): void {
    //this.message = 'Confirmed!';
    this.modalRef.hide();
    
    this.audienceService.deleteAudience(this.audienceId).subscribe(()=>{     
      
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['audience']);
      this.toastr.success('Audience deleted successfully.');
      // this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
    
    //this.delete();
  }

}

