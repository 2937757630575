import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sign-app-update-widget',
  templateUrl: './sign-app-update-widget.html',
  styleUrls: ['./sign-app-update-widget.css']
})
export class SignAppUpdateWidget implements OnInit {
  // @Input() recipient: any;
  @Input() isMobileUser: boolean;
  @Input() recipientsId: number;
  
  @Input() issignRec: any;
  @Output() updateRecipientsSignApp = new EventEmitter<any>();
  // isMobileUserFlag: boolean;
  signStatus: string;
  constructor() { }

  ngOnInit(): void {
    console.log('isMobileUser',this.isMobileUser)
    console.log('recipientsId',this.recipientsId)
    this.signStatus = this.issignRec;
  }


  handleCheckboxChange(i: any){
    let object = {"recipientsId":this.recipientsId, "isMobileUser": i}
    this.updateRecipientsSignApp.emit(object);
  }
}
