
    <div class="modal-header border-bottom-0">
      <h5 class="modal-title factfinderheader" id="exampleModalLabel">{{ title }} Medical Insurance</h5>
      <button type="button" class="close" (click)="modalRehide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <form [formGroup]="addMedicalInsuranceForm" novalidate class="form " (ngSubmit)="onSubmit()" >
    <div class="modal-body factfinderbody">
      
      <div class="form-group row factfinderpopup">
        <label for="inputPassword" class="labelwidth">Policy Type</label>
        <div class="inputwidth"> <span>:</span>
          <!-- <input type="text" class="form-control form-control-sm" placeholder="Mobile Phone"> -->
          <select formControlName="type" required [(ngModel)]="insuranceMedicalAddModel.type" class="form-control form-control-sm" placeholder="Type" >
            <option value="000">Please select</option>
            
            <ng-container *ngFor="let insuranceMedicalTypeOne of insuranceMedicalTypeList; let i = index">
                <option value="{{insuranceMedicalTypeOne.key}}">{{insuranceMedicalTypeOne.value}}</option>
            </ng-container>
            
          </select>
              <div class="form-group">
                <div *ngIf="submitted && f.type.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.type.errors.required">Policy type is required.</div>
              </div>
              </div>
        </div>
      </div>
      <div class="form-group row factfinderpopup">
        <label for="inputPassword" class="labelwidth">Policy Number</label>
        <div class="inputwidth"> <span>:</span> 
          <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
          <input formControlName="policyNumber" required [(ngModel)]="insuranceMedicalAddModel.policyNumber" type="text" class="form-control form-control-sm" placeholder="Policy Number">
          
          <div class="form-group">
            <div *ngIf="submitted && f.policyNumber.errors"   class="form_alert">
                    <span  class="ft-alert-circle"></span>
                <div *ngIf="f.policyNumber.errors.required">Policy number is required.</div>
            </div>
          </div>  
        </div>
      </div>

      <div class="form-group row factfinderpopup">
        <label for="inputPassword" class="labelwidth">Plan Name</label>
        <div class="inputwidth"> <span>:</span> 
          <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
          <input formControlName="planName" required [(ngModel)]="insuranceMedicalAddModel.planName" type="text" class="form-control form-control-sm" placeholder="Plan Name">
          <div class="form-group">
            <div *ngIf="submitted && f.planName.errors"   class="form_alert">
                    <span  class="ft-alert-circle"></span>
                <div *ngIf="f.planName.errors.required">Plan name is required.</div>
            </div>
          </div>  
        </div>
      </div>

      <div class="form-group row factfinderpopup">
        <label for="inputPassword" class="labelwidth">Total Premium</label>
        <div class="inputwidth"> <span>:</span> 
          <div class="input-group prepend_group">
              <span class="input-group-prepend" style="top: 0px;">
                  <div class="input-group-text border-right-0 prepend_new_class"><span class="prepend_span"> $ </span></div>
              </span>
              <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
              <input formControlName="totalPremiumValue" required [(ngModel)]="insuranceMedicalAddModel.totalPremiumValue" type="number" pattern="^[1-9]\d*$" class="form-control form-control-sm" style="width: unset;" placeholder="Total Premium">
          </div>
          <div class="form-group">
            <div *ngIf="submitted && f.totalPremiumValue.errors"   class="form_alert aud_errormsg">
                    <span  class="ft-alert-circle"></span>
                <div *ngIf="f.totalPremiumValue.errors.required">Total Premium is required.</div>
                <div *ngIf="f.totalPremiumValue.errors.pattern">Only numbers.</div>
            </div>
          </div>

        </div>
      </div>

      <div class="form-group row factfinderpopup">
          <label for="postcode" class="labelwidth">Policy Owners</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            
            <select formControlName="owners" required [(ngModel)]="insuranceMedicalAddModel.owners" class="form-control form-control-sm" placeholder="Owners" >
              <option value="000">Please select</option>
              <ng-container *ngFor="let assetOwnerOne of assetOwnerList; let i = index">
                  <option value="{{assetOwnerOne.key}}">{{assetOwnerOne.value}}</option>
              </ng-container>
            </select>
            <div class="form-group">
              <div *ngIf="submitted && f.owners.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.owners.errors.required">Owners is required.</div>
              </div>
            </div>  
          </div>
        </div>
      
    </div>
    <div class="modal-footer border-top-0 pt-0">
      <button class="btn btn-primary factfinderbtn">Save</button>
    </div>
</form>
    