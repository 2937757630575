import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ServiceAgreementService } from '../service-agreement.service';

@Component({
  selector: 'app-edit-service-agreement',
  templateUrl: './edit-service-agreement.component.html',
  styleUrls: ['./edit-service-agreement.component.css']
})
export class EditServiceAgreementComponent implements OnInit {

  constructor(
    private serviceAgreementService: ServiceAgreementService,
  ) { }

  ngOnInit(): void {
    this.viewEditer();
  }

  viewEditer(){
    this.serviceAgreementService.editDocusign().subscribe((data:any)=>{
      let documentUrl = data;
      console.log('document url ---------');
      console.log(documentUrl);
      //this.isLoaded = true;
    },
      (err: HttpErrorResponse)=>{
      console.log("Error"+ err.message);
    });
  }

}
