import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-view-newsfee-read-clients',
  templateUrl: './view-newsfee-read-clients.component.html',
  styleUrls: ['./view-newsfee-read-clients.component.css']
})
export class ViewNewsfeeReadClientsComponent implements OnInit {

  data;
  newsFeedByIdModel;

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
    if(this.data.newsFeed !=null){
      this.newsFeedByIdModel = this.data.newsFeed
    }
  }

  
  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }
  
  ClosePopup(){
    this.modalRef.hide();
  }

}
