import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RichTextEditorComponent } from "./rich-text-editor.component";
import { QuillModule } from "ngx-quill";
import { ReactiveFormsModule } from "@angular/forms";


@NgModule({
  declarations: [RichTextEditorComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    QuillModule
  ],
  exports: [RichTextEditorComponent],
})
export class RichTextEditorModule { }
