<app-header></app-header>
<app-sidebar></app-sidebar>


<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1">
      <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
        <h3 class="content-header-title mb-0 d-inline-block">AUDIENCE</h3>
        <div class="row breadcrumbs-top d-inline-block">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/dashboard">Home</a> </li>
              <li class="breadcrumb-item active">Audience </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12">
        <div class="btn-group float-md-right">
          <button class="btn appointment_btn btn-sm border_all_radius" data-toggle="modal"
            (click)="createAudience()">Create New</button>
        </div>

      </div>
    </div>

    <div class="content-body">
      <div class="row">
        <div id="recent-sales" class="col-12 col-md-12 ">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Audience List</h4>
            </div>
            <div *ngIf="isLoaded" class="card-content">
              <!-- <div class="table-responsive scroll-tbl_newsfeed-"> -->
              <!-- <table id="recent-orders" class="table table-hover table-xl mb-0"> -->
              <table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered zero-configuration t_dt" style="margin-top: 20px !important;">
                <thead>
                  <tr>
                    <th class="border-top-0">Name</th>
                    <th class="border-top-0" style="width: 0px;"> No of Clients</th>
                    <th class="border-top-0"></th>
                  </tr>
                </thead>
                <tbody *ngIf="audienceList.length>0">
                  <ng-container *ngFor="let audience of audienceList; let i = index">
                    <tr *ngIf="!audience.isArchived">
                      <td class="text-truncate" data-toggle="tooltip">
                        {{audience.name}}
                      </td>
                      <td class="text-truncate">{{ audience.audienceCount}}</td>
                      <td class="sa_btns">
                        <div class="btn-group">
                          <button type="button" class="btn btn-icon mr-1 btn_trash" (click)="openDeleteDialog(template,audience.id)"  ><i class="la la-trash"></i></button>
                          <!-- <button type="button" class="btn btn-icon newsbtn" (click)="viewAudience(audience.id)"><i
                              class="la la-arrow-right"></i></button> -->
                          <a routerLink="/view-audience" [queryParams]="{audience_id:audience.id, audienceCount:audience.audienceCount}"  href="#"> <i class="la la-arrow-right la_icon"></i> </a>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
                
                <tbody *ngIf="audienceList.length == 0" style=" width: 100% !important; text-align: center;">
                  <tr>
                      <td colspan="3" >No records to show.</td>
                      <td></td>
                  </tr>
                </tbody>
              </table>
              
              <!-- </div> -->
            </div>
          </div>
        </div>




      </div>

    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-body text-center" >

      <img  src="assets/img/warning.png" alt="" class="alert_img">
      <p>{{message}}</p>
        
      <div class="form-actions center mb-1">
          <button type="button" class="btn alert_no_b mr-1" (click)="decline()"> 
              No
          </button>
          <button type="button" class="btn alert_yes_b" (click)="confirm()" >
               Yes
          </button>
      </div>
  </div>
</ng-template>

<app-footer></app-footer>