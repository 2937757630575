
<div class="modal-header a_modal">
    <h4 class="modal-title" id="myModalLabel1">{{title}}</h4>
    <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
</div>
<form #f="ngForm" novalidate class="form" (ngSubmit)="onSubmit(f)" autocomplete="false" >
<div class="modal-body dallecort_modal-body">
    <div class="row">
        <div class="col-md-12">
            
            
            
                <div class="form-body">
                    <span class="add_agent_g" >General Information</span>
                    <div class="row add_agent_gr" style="margin-top: 5px; margin-bottom: 5px;">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="firstName" class="c_n_fs">First Name *</label>
                                <input autocomplete="off" maxlength="75" id="firstName"  required minlength="2" type="text"  class="form-control" name="firstName" placeholder="First Name" [(ngModel)]="agentModel.firstName" #firstName = "ngModel">
                                <!-- <input type="text" id="projectinput1" class="form-control" placeholder="First Name" name="fname"> -->
                                <div class="form-group">
                                    <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched || f.submitted)"
                                    class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                      <div *ngIf="firstName.errors.required">
                                        First name is required.
                                      </div>
                                      <div *ngIf="firstName.errors.minlength">
                                        First name must be at least 2 characters long.
                                      </div>
                                      <div *ngIf="firstName.errors.forbiddenName">
                                        First name cannot be Bob.
                                      </div>
                                      <div *ngIf="firstName.errors.maxlength">
                                        First name must not more than 75 characters long.
                                      </div>
                                    </div>
                                  </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="lastName" class="c_n_fs">Last Name *</label>
                                <!-- <input type="text" id="projectinput1" class="form-control" placeholder="Last Name" name="lname">  -->
                                <input autocomplete="off" maxlength="75" type="text"  required minlength="2" class="form-control" id="lastName" name="lastName"  placeholder="Last Name" [(ngModel)]="agentModel.lastName" #lastName = "ngModel">
                                <div class="form-group">
                                    <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched || f.submitted)"
                                    class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                      <div *ngIf="lastName.errors.required">
                                        Last name is required.
                                      </div>  
                                      <div *ngIf="lastName.errors.minlength">
                                            Last name must be at least 2 characters long.
                                        </div>   
                                        <div *ngIf="lastName.errors.maxlength">
                                                Last name must not more than 75 characters long.
                                        </div>                                               
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="prefferedName" class="c_n_fs">Preferred Name *</label>
                                <input autocomplete="off" maxlength="75" type="text" required class="form-control" minlength="2" id="prefferedName" name="prefferedName"  placeholder="Preferred Name" [(ngModel)]="agentModel.prefferedName" #prefferedName = "ngModel">
                                <div class="form-group">
                                    <div *ngIf="prefferedName.invalid && (prefferedName.dirty || prefferedName.touched || f.submitted)"
                                    class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                      <div *ngIf="prefferedName.errors.required">
                                          Preferred name is required.
                                      </div>  
                                      <div *ngIf="prefferedName.errors.minlength">
                                            Preferred name must be at least 2 characters long.
                                        </div> 
                                        <div *ngIf="prefferedName.errors.maxlength">
                                                Preferred name must not more than 75 characters long.
                                        </div>                                                  
                                    </div>
                                </div>
                            </div>
                        </div>      
                        <!-- <div class="col-md-6">
                            <div class="form-group">
                                <label for="designation" class="c_n_fs">Designation *</label>
                                <input autocomplete="off" maxlength="75" type="text" required class="form-control" minlength="4" id="designation" name="designation"  placeholder="Designation" [(ngModel)]="agentModel.designation" #designation = "ngModel">
                                <div class="form-group">
                                    <div *ngIf="designation.invalid && (designation.dirty || designation.touched || f.submitted)"
                                    class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                        <div *ngIf="designation.errors.required">
                                            Designation is required.
                                        </div> 
                                        <div *ngIf="designation.errors.minlength">
                                                Designation must be at least 4 characters long.
                                            </div>
                                        <div *ngIf="prefferedName.errors.maxlength">
                                                Designation must not more than 75 characters long.
                                        </div>                                                     
                                    </div>
                                </div>
                            </div>
                        </div>               -->

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="designation" class="c_n_fs">Permission Group *</label>
                                <!-- <select id="issueinput5" name="priority" class="form-control com_drop" data-toggle="tooltip" data-trigger="hover"  placeholder="Please select"  data-placement="top" data-title="Priority" data-original-title="" title="">
                                    <option value="group1">Group 01</option>
                                    <option value="group2">Group 02</option>
                                </select> -->
                                <select required id="roleId" name="roleId" class="form-control com_drop" data-toggle="tooltip" data-trigger="hover" placeholder="Please select" data-placement="top" data-title="Priority" data-original-title="" title="" [(ngModel)]="agentModel.roleId" #roleId = "ngModel">
                                    <option value=null> Please select </option>
                                    <ng-container *ngFor="let facCat of roleList">                                       
                                        <option *ngIf="facCat.isArchived===false"   [value]="facCat.id">                                 
                                            {{facCat.name}}
                                        </option> 
                                    </ng-container>
                                   
                                </select>

                                <div class="form-group">
                                    <div *ngIf="roleId.invalid && (roleId.dirty || roleId.touched || f.submitted)"
                                    class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                      <div *ngIf="roleId.errors.required">
                                        Permission group is required.
                                      </div>                                                  
                                    </div>
                                  </div>

                            </div>
                        </div> 

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="userType" class="c_n_fs">Type *</label>
                                <select required id="userType" [ngModelOptions]="{standalone: true}" [(ngModel)]='agentModel.userType' #userType = "ngModel" name="userType" class="form-control com_drop" data-toggle="tooltip" data-trigger="hover" data-placement="top" data-title="Priority" data-original-title="" title=""  placeholder="Please select" >
                                    <option *ngFor="let userType of userTypeArray"  [value]="userType.id">                                      
                                        {{userType.value}}
                                    </option>                                        
                                </select>

                                <div class="form-group">
                                  <div *ngIf="userType.invalid && (userType.dirty || userType.touched || f.submitted)"
                                  class="form_alert">
                                  <span  class="ft-alert-circle"></span>
                                    <div *ngIf="userType.errors.required">
                                      User type is required.
                                    </div>                                                  
                                  </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="userType" class="c_n_fs">Company *</label>
                                <select required id="companyId" name="companyId" disabled class="form-control com_drop" data-toggle="tooltip" data-trigger="hover" placeholder="Please select" data-placement="top" data-title="Priority" data-original-title="" title="" [(ngModel)]="agentModel.companyId" #companyId = "ngModel">
                                    <option value=null> Please select </option>
                                    <ng-container *ngFor="let company of companysDropdown">                                       
                                        <option  [value]="company.id">                                 
                                            {{company.name}}
                                        </option> 
                                    </ng-container>
                                </select>
                                <div class="form-group">
                                    <div *ngIf="companyId.invalid && (companyId.dirty || companyId.touched || f.submitted)" class="form_alert">
                                        <span  class="ft-alert-circle"></span>
                                        <div *ngIf="companyId.errors.required">
                                            Company name is required.
                                        </div>                                                  
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="primaryMobile" class="c_n_fs">Primary Mobile *</label>
                                <input autocomplete="off" type="text" pattern="^(\+)?\d{11}$" required class="form-control" id="primaryMobile" name="primaryMobile"  placeholder="Primary mobile" [(ngModel)]="agentModel.primaryMobile" #primaryMobile = "ngModel">
                                <div class="form-group">
                                    <div *ngIf="primaryMobile.invalid && (primaryMobile.dirty || primaryMobile.touched || f.submitted)"
                                    class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                      <div *ngIf="primaryMobile.errors.required">
                                          Primary mobile is required.
                                      </div> 
                                      <div *ngIf="primaryMobile.errors.pattern">
                                            Primary mobile must be length 11 and type of +61445678956.
                                          </div>                                                  
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="contactNumber" class="c_n_fs">Contact Number *</label>
                                <input autocomplete="off" type="text" pattern="^(\+)?\d{11}$" required class="form-control" id="contactNumber" name="contactNumber"  placeholder="Contact Number" [(ngModel)]="agentModel.contactNumber" #contactNumber = "ngModel">
                                <div class="form-group">
                                    <div *ngIf="contactNumber.invalid && (contactNumber.dirty || contactNumber.touched || f.submitted)"
                                    class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                      <div *ngIf="contactNumber.errors.required">
                                          Contact Number is required.
                                      </div>  
                                      <div *ngIf="contactNumber.errors.pattern">
                                            Contact number must be length 11 and type of +61445678956.
                                          </div>                                                 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" -->
                    <span class="add_agent_g">Account Information</span>
                    <div class="row add_agent_ga" style="margin-top: 5px;">  
                        
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="projectinput1" class="c_n_fs">Email *</label>
                                <input autocomplete="off" type="email" autocomplete="none" required class="form-control" id="email" name="email" pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="Email" [(ngModel)]="agentModel.email" #email = "ngModel">
                                <div class="form-group">
                                    <div *ngIf="email.invalid && (email.dirty || email.touched || f.submitted )"
                                    class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                        <div *ngIf="email.errors.required">
                                        Email is required.
                                        </div>
                                        <div *ngIf="email.errors.pattern">
                                            Email must be a type of email.
                                            </div>                                                   
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-md-6">
                            <div class="form-group">
                                <label for="projectinput1" class="c_n_fs">Email *</label>
                                <input autocomplete="off" type="email" autocomplete="none" required class="form-control" id="email" name="email" [email]="true" placeholder="Email" [(ngModel)]="agentModel.email" #email = "ngModel">
                                <div class="form-group">
                                    <div *ngIf="email.invalid && (email.dirty || email.touched)"
                                    class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                      <div *ngIf="email.errors.required">
                                        Email is required.
                                      </div>
                                      <div *ngIf="email.errors.email">
                                            Email must be a type of email.
                                          </div>                                                   
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="userName" class="c_n_fs">User Name *</label>
                           <!-- <input type="text" autocomplete="none" required class="form-control" id="userName" name="userName"  placeholder="User Name" [(ngModel)]="agentModel.userName" #userName = "ngModel"> -->
                                <input autocomplete="off" maxlength="75" type="text" autocomplete="none" required minlength="4" class="form-control" id="userName" name="userName"  placeholder="User Name" [(ngModel)]="agentModel.userName" #userName = "ngModel">

                                <div class="form-group">
                                    <div *ngIf="userName.invalid && (userName.dirty || userName.touched || f.submitted)"
                                    class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                      <div *ngIf="userName.errors.required">
                                        User name is required.
                                      </div>   
                                      <div *ngIf="userName.errors.minlength">
                                            User name must be at least 4 characters long.
                                          </div>   
                                          <div *ngIf="userName.errors.maxlength">
                                                User name must not more than 75 characters long.
                                        </div>                                              
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="password" class="c_n_fs">Password *</label> 
                                <input autocomplete="off" type="password" autocomplete="none" minlength="8" required class="form-control" id="password" name="password" placeholder="Password" [(ngModel)]="agentModel.password" #password = "ngModel">
                                <!-- <input type="password" autocomplete="off"  minlength="6" required class="form-control" id="password" name="password" placeholder="Password" [(ngModel)]="agentModel.password" #password = "ngModel"> -->

                                <div class="form-group">
                                    <div *ngIf="password.invalid && (password.dirty || password.touched || f.submitted)"
                                    class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                      <div *ngIf="password.errors.required">
                                        Password is required.
                                      </div> 
                                      <div *ngIf="password.errors.minlength">
                                            Password must contain at least 8 characters.
                                      </div>                                                 
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="password_confirmation" class="c_n_fs">Password Confirmation *</label>
                                <input autocomplete="off" type="password" autocomplete="none" autocomplete="nope" required class="form-control" id="password_confirmation" name="password_confirmation"  placeholder="Password confirmation" [(ngModel)]="agentModel.password_confirmation" #password_confirmation = "ngModel">
                                <div class="form-group">
                                    <div *ngIf="password_confirmation.invalid && (password_confirmation.dirty || password_confirmation.touched || f.submitted)"
                                    class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                      <div *ngIf="password_confirmation.errors.required">
                                          Password confirmation is required.
                                      </div>                                                  
                                    </div>
                                    <div *ngIf="(password.value != password_confirmation.value) && (password_confirmation.dirty || password_confirmation.touched)"
                                    class="form_alert">
                                    <span  class="ft-alert-circle"></span>
                                        <div >
                                        Password confirmation is not matching.
                                      </div>                                                  
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                </div>
            
        </div>
    </div>
</div>

<div class="modal-footer a-footer">
    <div *ngIf="!Isvalid" class="form_alert">
            <span  class="ft-alert-circle"></span>
        Please fill in all the required fields correctly.
    </div>
    <div *ngIf="errors"class="form_alert">
            <span  class="ft-alert-circle"></span>
        {{errors}}
    </div>
    <button type="submit" class="btn popup_save_b">Save</button>
</div>
</form>
<ngx-ui-loader ></ngx-ui-loader>
