import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-view-error-log',
  templateUrl: './view-error-log.component.html',
  styleUrls: ['./view-error-log.component.css']
})
export class ViewErrorLogComponent implements OnInit {

  data;
  errorLogs:any;
  groupErrorLogs:any = [];
  event: EventEmitter<any> = new EventEmitter();

  constructor(
    public modalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    if(this.data.errorLogs) {
      this.errorLogs = this.data.errorLogs;
      this.groupErrorLogs = this.groupBy(this.errorLogs, (c) => c.tableName);
      // this.groupErrorLogs = this.groupBy(this.errorLogs, type => type.tableName);
      
      console.log(this.groupErrorLogs);
    }
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

  groupBy(xs, f) {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }

  // groupBy(list, keyGetter) {
  //   const map = new Map();
  //   list.forEach((item) => {
  //     const key = keyGetter(item);
  //     const collection = map.get(key);
  //     if (!collection) {
  //       map.set(key, [item]);
  //     } else {
  //       collection.push(item);
  //     }
  //   });
  //   return map;
  // }

}
