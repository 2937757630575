<div class="edit-wr" #nwEditor>
  <div [hidden]="networthData.value" class="net-worth-editor d-flex justify-content-end align-items-center ml-auto" [ngClass]="{'is-edit': isEditing}">

    <div *ngIf="isLoading" id="bar-spinner" class="spinner">
      <div class="spinner-icon"></div>
    </div>

    <span *ngIf="networthData.value && !isLoading" class="action-wr d-flex align-items-center">
      <svg  [ngClass]="{'d-none': isEditing}" class="cursor-pointer action-icon mb-01" (click)="toggleEdit()" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="edit_icon" transform="translate(-1066 -1266)">
          <rect id="Rectangle_598" data-name="Rectangle 598" width="20" height="20" rx="3" transform="translate(1066 1266)" fill="#f1f1f1"/>
          <g id="pencil_orange" transform="translate(1070.392 1270.392)">
            <g id="Group_3203" data-name="Group 3203" transform="translate(7.866 0)">
              <g id="Group_3202" data-name="Group 3202" transform="translate(0 0)">
                <path id="Path_3099" data-name="Path 3099" d="M357.63,1.326l-.988-.988a1.159,1.159,0,0,0-1.637,0l-.387.387,2.624,2.624.387-.387A1.157,1.157,0,0,0,357.63,1.326Z" transform="translate(-354.619 0)" fill="#6b6f82"/>
              </g>
            </g>
            <g id="Group_3205" data-name="Group 3205" transform="translate(0 8.337)">
              <g id="Group_3204" data-name="Group 3204">
                <path id="Path_3100" data-name="Path 3100" d="M.469,376.747l-.463,2.5a.319.319,0,0,0,.372.372l2.5-.463Z" transform="translate(0 -376.747)" fill="#6b6f82"/>
              </g>
            </g>
            <g id="Group_3207" data-name="Group 3207" transform="translate(0.813 1.177)">
              <g id="Group_3206" data-name="Group 3206">
                <path id="Path_3101" data-name="Path 3101" d="M44.706,55.274,38.2,61.782,40.916,64.5l6.508-6.508Z" transform="translate(-38.198 -55.274)" fill="#6b6f82"/>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <svg class="cursor-pointer action-icon" *ngIf="isEditing" (click)="updateNetworthValue(networthData.id, nwValue.value)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="done" transform="translate(-1044 -1355)">
          <rect id="Rectangle_599" data-name="Rectangle 599" width="20" height="20" rx="3" transform="translate(1044 1355)" fill="#12d89e"/>
          <path id="Path_826" data-name="Path 826" d="M-7874.8-15362.26l3.315,3.43,7.658-7.658" transform="translate(8923.316 16727.66)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"/>
        </g>
      </svg>
      <svg class="cursor-pointer action-icon trash" *ngIf="isEditing" (click)="openDeleteConfirmation(deleteConfirmationRef, networthData)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="delete_icon" transform="translate(-1067 -1355)">
          <rect id="Rectangle_600" data-name="Rectangle 600" width="20" height="20" rx="3" transform="translate(1067 1355)" fill="#ff4961"/>
          <g id="Icon_feather-trash-2" data-name="Icon feather-trash-2" transform="translate(1067.629 1356.588)">
            <path id="Path_814" data-name="Path 814" d="M4.5,9h9.742" transform="translate(0 -3.741)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.9"/>
            <path id="Path_815" data-name="Path 815" d="M14.507,5.165v7.577a1.044,1.044,0,0,1-1,1.082H8.5a1.044,1.044,0,0,1-1-1.082V5.165m1.5,0V4.082A1.044,1.044,0,0,1,10,3h2a1.044,1.044,0,0,1,1,1.082V5.165" transform="translate(-1.633 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.9"/>
            <path id="Path_816" data-name="Path 816" d="M15,16.5v4.1" transform="translate(-6.712 -9.178)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.9"/>
            <path id="Path_817" data-name="Path 817" d="M21,16.5v4.1" transform="translate(-10.547 -9.178)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.9"/>
          </g>
        </g>
      </svg>
    </span>

    <span *ngIf="!isEditing">
      {{networthData.value ? '$'+(networthData.value | number: '1.0-0') : '--'}}
    </span>

    <div class="nw-input-wr" [ngClass]="{'d-none': !isEditing}">
      <input
        #nwValue
        (keyup.enter)="updateNetworthValue(networthData.id, nwValue.value)"
        [value]="networthData.value"
        type="number"
        class="nw-input form-control text-right mr-1"
        id="netValue">
    </div>
  </div>
</div>

<ng-template #deleteConfirmationRef>
  <div class="modal-body text-center">
     <img src="assets/img/warning.png" alt="" class="alert_img">
     <p>{{message}}</p>
     <div class="form-actions center mb-1">
        <button type="button" class="btn alert_no_b mr-1" (click)="decline()">No</button>
        <button type="button" class="btn alert_yes_b" (click)="deleteNetworthValue()">Yes</button>
     </div>
  </div>
</ng-template>
