export class AudienceModel {
    public  name : string;
    public  description : string;
    public  isEnabled : boolean;
    public  createdDate : string;
    public  isArchived : boolean;
}

export class AudienceAddModel extends AudienceModel{    
       
}