import { Component, OnInit, EventEmitter, TemplateRef } from '@angular/core';

import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { RolePermissionAddModel } from '../shared/models/role-permission.model';
import { RoleAddModel } from '../shared/models/role.model';
import { PermissionAddModel } from '../shared/models/permission.model';
import { Router } from '@angular/router';
import { PermissionGroupsService } from '../permission-groups/permission-groups.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { PermissionGroup, RoleResponse } from '../shared/models/response.model';
import { Utility } from '../utility/utility';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddPermissionGroupsComponent } from './add-permission-groups/add-permission-groups.component';
import { Subject } from 'rxjs';
import { EditPermissionGroupsComponent } from './edit-permission-groups/edit-permission-groups.component';
import { AuthService } from '../auth/auth.service';


@Component({
  selector: 'app-permission-groups',
  templateUrl: './permission-groups.component.html',
  styleUrls: ['./permission-groups.component.css']
})
export class PermissionGroupsComponent implements OnInit {
  Isvalid: boolean;
  roleAddModel: RoleAddModel;
  roleFilterdList:RoleAddModel[];
  rolePermissionModel: RolePermissionAddModel;
  permissionAddModel: PermissionAddModel;
  event: EventEmitter<any> = new EventEmitter();
  errors;
  data;
  modalRef: BsModalRef;
  roleList: RoleAddModel[];
  isLoaded: boolean;
  roleData:RoleAddModel;
  status :boolean;
  message;
  dtOptions: DataTables.Settings = {}; 
  dtTrigger: Subject<any> = new Subject();
  isLoadedBusiness: boolean;

  constructor(
    private permissionGroupsService: PermissionGroupsService ,
    private toastr: ToastrService, 
    private ngxService: NgxUiLoaderService,
    private modalService: BsModalService, 
    private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    this.Isvalid = true;
    this.rolePermissionModel = new RolePermissionAddModel();
    this.roleAddModel = new RoleAddModel();
    this.permissionAddModel = new PermissionAddModel();

    this.permissionGroupsService.roleList().subscribe((data:RoleResponse)=>{  
      this.roleList = data.data;  
      this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      }); 
    
      this.dtOptions = {
        //pagingType: 'full_numbers',
        //ajax: this.baseUrl+'/FortressAdmins',
        pageLength: 10,
        processing: true,
        order : []
      }; 
      this.getBusinessList();
  }

  openModal() {
    this.modalRef = this.modalService.show(AddPermissionGroupsComponent,  {
      initialState: {
        title: 'Add Agent',
        data: {},
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      
      if (result == 'OK') {
        //console.log("Success");
        this.router.navigate(['permission-groups']);
         // this.getAgentList();
      
      }

    });

 }

 getBusinessList() {
  this.authService.getBusinessList().subscribe((data:any)=>{
    //this.ngxService.stopLoader('loader-01');   
    if(!data.success){
      this.isLoadedBusiness = true;
        //this.ngxService.stopLoader('loader-01');
    }
    else{      
    }      
  },err=>{   
    //this.ngxService.stop();
    //this.toastr.error('Error on login.');
      //this.ngxService.stop();
    //this.ngxService.stopLoader('loader-01');            
  });
 }

 public openConfirmationDialog(template: TemplateRef<any>,roleData:RoleAddModel,status:boolean) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    //this.idToBeDeleted = id;

    //let message = '';
    this.roleData = roleData;
    this.status = !status;
    if(status){
      this.message = 'Are you sure that you want to enable "'+ this.roleData.name +'" role?'
    }
    else{
      this.message = 'Are you sure that you want to disable "'+ this.roleData.name +'" role?'
    }
  }

  openEditModal(roleData:any) {  
    //console.log(agent);  
    this.modalRef = this.modalService.show(EditPermissionGroupsComponent,  {
      initialState: {
        title: 'Edit Agent',
        data: {
          roleData:roleData
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        this.router.navigate(['permission-groups']);
        //  this.getAgentList();
      
      }
    });
  }

  confirm(roleData,status): void {
    //this.message = 'Confirmed!';
    this.modalRef.hide();
    roleData.isArchived = status;
    // console.log(agent.isArchived);
    // console.log(this.status);

    this.permissionGroupsService.enableDisable(roleData.id, status).subscribe(()=>{     
      // this.agentList = data.response;
      // this.agentsCount = this.agentList.length;
      // this.assignCopy();
      // console.log('Agent list--------------------------');
      // console.log(this.agentList);

      //this.getAgentList();
      this.router.navigate(['permission-groups']);
      
      // this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
    
    //this.delete();
  }


  decline(roleData,status): void {
    //this.message = 'Declined!';
    this.modalRef.hide();
    //agent.fortressAdmin.isArchived = !status;
    //console.log(this.status);
  }

}