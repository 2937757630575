
    <div class="modal-header border-bottom-0">
        <h5 class="modal-title factfinderheader" id="exampleModalLabel">{{ title }} Goal Details</h5>
        <button type="button" class="close" (click)="modalRehide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
  
      <form [formGroup]="addGoalForm" novalidate class="form " (ngSubmit)="onSubmit()" >
      <div class="modal-body factfinderbody">
        
          <div class="form-group row factfinderpopup">
              <label for="ownerType" class="labelwidth">Owner Type</label>
              <div class="inputwidth"> <span>:</span>
                <!-- <input type="text" class="form-control form-control-sm" placeholder="Mobile Phone"> -->
                <select formControlName="ownerType" required [(ngModel)]="goalAddModel.ownerType" class="form-control form-control-sm" placeholder="Owner Type" >
                    <option value="000">Please select</option>
                    
                    <ng-container *ngFor="let goalOwner of goalOwnerList; let i = index">
                        <option value="{{goalOwner.key}}">{{goalOwner.value}}</option>
                    </ng-container>
                    
                  </select>
                    <div class="form-group">
                      <div *ngIf="submitted && f.ownerType.errors"   class="form_alert">
                              <span  class="ft-alert-circle"></span>
                          <div *ngIf="f.ownerType.errors.required">Owner type is required.</div>
                    </div>
                    </div>
              </div>
            </div>

          <div class="form-group row factfinderpopup">
              <label for="description" class="labelwidth">Description</label>
              <div class="inputwidth"> <span>:</span> 
                <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
                <input formControlName="description" required [(ngModel)]="goalAddModel.description" type="text" class="form-control form-control-sm" placeholder="Description">
                <div class="form-group">
                  <div *ngIf="submitted && f.description.errors"   class="form_alert">
                          <span  class="ft-alert-circle"></span>
                      <div *ngIf="f.description.errors.required">Description is required.</div>
                  </div>
                </div>  
              </div>
            </div>

          <div class="form-group row factfinderpopup">
              <label for="targetAmountValue" class="labelwidth">Target Amount Value</label>
              <div class="inputwidth"> <span>:</span> 
                <div class="input-group prepend_group">
                    <span class="input-group-prepend" style="top: 0px;">
                        <div class="input-group-text border-right-0 prepend_new_class"><span class="prepend_span"> $ </span></div>
                    </span>
                    <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
                    <input formControlName="targetAmountValue" required [(ngModel)]="goalAddModel.targetAmountValue" type="number" pattern="^[1-9]\d*$" class="form-control form-control-sm" style="width: unset;" placeholder="Target Amount Value">
                </div>
                <div class="form-group">
                  <div *ngIf="submitted && f.targetAmountValue.errors"   class="form_alert aud_errormsg">
                          <span  class="ft-alert-circle"></span>
                      <div *ngIf="f.targetAmountValue.errors.required">Target amount value is required.</div>
                      <div *ngIf="f.targetAmountValue.errors.pattern">Only numbers.</div>
                  </div>
                </div>  
              </div>
            </div>

        
        
        <div class="form-group row factfinderpopup">
          <label for="comments" class="labelwidth">Comments</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            <input formControlName="comments" required [(ngModel)]="goalAddModel.comments" type="text" class="form-control form-control-sm" placeholder="Comments">
            <div class="form-group">
              <div *ngIf="submitted && f.comments.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.comments.errors.required">Comment is required.</div>
              </div>
            </div>
  
          </div>
        </div>
        

        <div class="form-group row factfinderpopup">
          <label for="targetDate" class="labelwidth">Target Date</label>
          <div class="inputwidth"> <span>:</span> 

            <div class="input-group prepend_group">
                <span class="input-group-prepend" (click)="targetDate.toggle()" [attr.aria-expanded]="targetDate.isOpen" style="top: 0px;     border-color: #32a3ca;">
                    <div class="input-group-text border-right-0 prepend_new_class new_clock">
                    <span class="prepend_span_goals" > 
                  
                            <i class="ficon ft-clock" style="    position: absolute; margin-top: -11px;"></i>
    
                    </span></div>
                </span>

                <input formControlName="targetDate" required readonly="true" placeholder="Target Date"
                class="form-control form-control-sm"
                style="width: unset; background-color: #fff;"
                name="targetDate" 
                [(ngModel)]="goalAddModel.targetDate"
                #targetDate="bsDatepicker" 
                placement="top"
                
                bsDatepicker                                      
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" >

                <!-- [bsValue]="currentDate"
                [minDate]="currentDate" -->
                
                <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
                <!-- <input formControlName="targetAmountValue" required [(ngModel)]="goalAddModel.targetAmountValue" type="number" pattern="^[1-9]\d*$" class="form-control form-control-sm" style="width: unset;" placeholder="Target Amount Value"> -->
            </div>


            

            
            <div class="form-group">
              <div *ngIf="submitted && f.targetDate.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.targetDate.errors.required">Target date is required.</div>
              </div>
            </div>

          </div>
        </div>
                                        
        

      </div>
      <div class="modal-footer border-top-0 pt-0">
        <button class="btn btn-primary factfinderbtn">Save</button>
      </div>
  </form>
      