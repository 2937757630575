import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppConstants } from '../utility/app-constants';
import { JwtHelperService } from '@auth0/angular-jwt';
// import { element } from '@angular/core/src/render3';
import { strictEqual } from 'assert';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { User, VerifiedUser } from '../shared/models/user.model';
// import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';
//import { Response } from '../shared/common/response';
import { ClientAddModel, ClientViewModel, ClientNewAddModel } from '../shared/models/client.model';
import { Response,ClentResponsex,ClentProfileResponse, WebClentResponse, ClentSearchResponse, UserVisibilityResponse, ClientAddResponse, ClentResponsexNew, ClientDeleteResponse, clientMigrateResponse, clientRevertResponse } from '../shared/models/response.model';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  baseUrl:String;
  authUrl:String;
  clientAuthUrl:String;
  token :String;
  
  constructor(private http:HttpClient, private cookieService: CookieService, private authenticationService: AuthService) {
    this.baseUrl = AppConstants.getBaseURL();
    this.authUrl = AppConstants.getAuthURL();
    this.clientAuthUrl = AppConstants.getClientAuthURL();
    this.token = this.authenticationService.userToken;
  }   

  public clientList(){    
    const params = new HttpParams().set('userType','2').set('CompanyCode',this.cookieService.get('companyCode')).set('skip','0').set('take','10000');
    // return this.http.get<ClentResponsex>(this.baseUrl+'/User/GetUsersByType',{params});   
    return this.http.get<ClentResponsexNew>(this.clientAuthUrl+'/User/GetUserByType',{params});
  }

  public clientsList(skip: number, take: number){    
    const params = new HttpParams().set('userType','2').set('CompanyCode',this.cookieService.get('companyCode')).set('skip',skip.toString()).set('take',take.toString());
    return this.http.get<ClentResponsexNew>(this.clientAuthUrl+'/User/GetUserByType',{params});
  }

  public getClient(id:string)
  {
    //console.log("client service")
    //console.log(id);
    const params = new HttpParams().set('id',id);
    return this.http.get<ClentProfileResponse>(this.clientAuthUrl+'/User',{params});
  }

  public getWebClient(XeppoCode:string, companyCode:string){
    //console.log("web client service xeppoCode")
    //console.log(XeppoCode);    
    const params = new HttpParams().set('XeppoCode', XeppoCode); 
    return this.http.get<WebClentResponse>(this.baseUrl+'/Dashboard/DashboardNetWorth/WebClient?XeppoCode='+XeppoCode+'&companyCode='+companyCode);    
  }

  public handleError(error) {
    return throwError(error);
  }

  public clientSearch(reqData: any){ 
    return this.http.post<ClentSearchResponse>(this.baseUrl+'/Client/Search', reqData); 
  }

  public clientSimpleSearch(reqData: any){ 
    const params = new HttpParams().set('searchString', reqData.name ).set('companyCode', this.cookieService.get('companyCode'));    
    return this.http.get<ClentSearchResponse>(this.baseUrl+'/Dashboard/Search', {params});
  }

  public clientUpdate(visibility, id){ 
    const params = new HttpParams().set('id', id );    
    return this.http.patch<UserVisibilityResponse>(this.baseUrl +'/Client/UpdateVisibility', visibility,{params}).pipe(
      catchError(this.handleError)
    );  
  }

  public clientStatusUpdate(isActive, id){ 
    const params = new HttpParams().set('id', id ).set('isActive', isActive);    
    return this.http.patch<ClentProfileResponse>(this.clientAuthUrl +'/User/UpdateUserActiveStatus?id='+id+'&isActive='+isActive, {params}).pipe(
      catchError(this.handleError)
    );  
  }

  public clientAdd(clientModel:ClientNewAddModel){
    //const params = new HttpParams().set('userType', "1"); 

    //agentModel.userType = 1;

    //agentModel.roleId = 'Hi9XddK4N0tujQjXKgU3rQ';  
    // return this.http.post<AgentProfileResponse>(this.baseUrl +'/FortressAdmin',agentModel).pipe(
    //  catchError(this.handleError)
    //  );   
    //console.log(agentModel);
    // return this.http.post<ClientAddResponse>(this.baseUrl +'/WebUser/AddUser',clientModel).pipe( 
      return this.http.post<ClientAddResponse>(this.clientAuthUrl +'/WebUser/AddUser',clientModel).pipe(
      catchError(this.handleError)
      );
  }

  public deleteClient(id:string){
    const params = new HttpParams().set('id', id); 
    return this.http.patch<ClientDeleteResponse>(this.clientAuthUrl +'/User/DeclineSignUp?id='+id,{params}).pipe(
      catchError(this.handleError)
      );
  }

  public getNWReportSummaryDocument(userId :string, reportDate:string, file: any)
  { 
    // return this.http.post<any>(this.baseUrl+'/Report/NetWorthReportSummary?userId='+userId+'&reportDate='+reportDate+'&assetImage=null&liabilityImage=null&lVRImage=null',params, {headers: headers});
    // // return this.http.post(this.baseUrl+'/Report/NetWorthReportSummary?userId='+userId+'&reportDate='+reportDate, {responseType: "blob"});
    // // return this.http.post<any>(this.baseUrl+'/Report/NetWorthReportSummary?userId='+userId+'&reportDate='+reportDate, {headers: headers});

    let headers = new HttpHeaders({
      'Content-Type': 'text/plain'});
      const params = {"userId":userId, "reportDate":reportDate};

      var formData = new FormData();
      formData.append("file", file, file.name);
  
    return this.http.post<any>(this.baseUrl+'/Report/NetWorthReportSummary?userId='+userId+'&reportDate='+reportDate+'&assetImage=null&liabilityImage=null&lVRImage=null',formData, {headers: headers});
  }

  public getNWReporttDetailWithChartsDocument(userId :string, reportDate:string, file: any)
  { 
    // return this.http.post(this.baseUrl+'/Report/NetWorthReporttDetailWithCharts?userId='+userId+'&reportDate='+reportDate, {responseType: "blob"});
    // return this.http.post<any>(this.baseUrl +'/Report/NetWorthReporttDetailWithCharts?userId='+userId+'&reportDate='+reportDate, userId).pipe(
    //   catchError(this.handleError)
    // );

    let headers = new HttpHeaders({
      'Content-Type': 'text/plain'});

      var formData = new FormData();
      formData.append("file", file, file.name);

      const params = {"userId":userId, "reportDate":reportDate, "assetImage": file, "liabilityImage": file, "lVRImage": file};
        return this.http.post<any>(this.baseUrl+'/Report/NetWorthReporttDetailWithCharts?userId='+userId+'&reportDate='+reportDate+'&assetImage=&assetImage=null&liabilityImage=null&lVRImage=null',formData, {headers: headers});

      
  }

  public getNWReportDetailDocument(userId :string, reportDate:string)
  {
    // return this.http.post(this.baseUrl+'/Report/NetWorthReportDetail?userId='+userId+'&reportDate='+reportDate, {responseType: "blob"});
    return this.http.post<any>(this.baseUrl +'/Report/NetWorthReportDetail?userId='+userId+'&reportDate='+reportDate, userId).pipe(
      catchError(this.handleError)
    );
  }

  public getInsuranceReportDocument(userId :string, reportDate:string)
  {
    // return this.http.post(this.baseUrl+'/Report/InsuranceReport?userId='+userId+'&reportDate='+reportDate, {responseType: "blob"});
    // const params = new HttpParams().set('userId',userId).set('reportDate',reportDate);
    // return this.http.get(this.baseUrl+'/Report/InsuranceReport',{params, responseType: "blob"});
    return this.http.post<any>(this.baseUrl +'/Report/InsuranceReport?userId='+userId+'&reportDate='+reportDate, userId).pipe(
      catchError(this.handleError)
    );
  }

  public getLoginHistory(id:string)
  {
    const params = new HttpParams().set('userId',id);
    return this.http.get<any>(this.clientAuthUrl+'/LoginHistory',{params});
  }

  public MigrateNetworthData(xeppoCode, companyCode:string){ 
    const params = new HttpParams().set('xeppoCode', xeppoCode ).set('companyCode', companyCode);    
    return this.http.put<clientMigrateResponse>(this.baseUrl +'/RealTimeNetworthDataRead/MigrateNetworthData',{}, {params}).pipe(
      catchError(this.handleError)
    );  
  }

  public revertNetworthData(xeppoCode, companyCode:string){ 
    const params = new HttpParams().set('xeppoCode', xeppoCode ).set('companyCode', companyCode);    
    return this.http.put<clientRevertResponse>(this.baseUrl +'/RealTimeNetworthDataRead/RivertMigrateNetworthData',{}, {params}).pipe(
      catchError(this.handleError)
    );  
  }

  getMigrateStatus(xeppoCode, companyCode) {
    const params = new HttpParams().set('xeppoCode', xeppoCode).set('companyCode', companyCode)
    return this.http.get(this.baseUrl + '/RealTimeNetworthDataRead/ClientMigrateStatus', { params });
  }

  getVerifiedStatus(clientId: string) {
    const params = new HttpParams().set('clientId', clientId )
    return this.http.get(this.baseUrl + '/Client/Group', { params });
  }

}
