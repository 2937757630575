import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { AgentViewModel,AgentBaseMOdel,AgentAddModel, UserAgentAddModel } from '../../shared/models/agent.model';
import { Router } from '@angular/router';
import { AgentsService } from '../agents.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { AgentResponse,AgentProfileResponse, CompanyResponse, RoleResponse } from '../../shared/models/response.model';
import { Utility } from '../../utility/utility';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PermissionGroupsService } from 'src/app/permission-groups/permission-groups.service';
import { RoleAddModel } from 'src/app/shared/models/role.model';

@Component({
  selector: 'app-edit-agent',
  templateUrl: './edit-agent.component.html',
  styleUrls: ['./edit-agent.component.css']
})
export class EditAgentComponent implements OnInit {

agentModel : UserAgentAddModel ;
roleList:RoleAddModel[];
//clinics : ClinicViewModel[];
//patientClinic:ClinicViewModel;
title;
data;
errors;
companys;
isLoaded :boolean;
userTypeArray: any;
//ethnicities : any;
patientAddForm: FormGroup;
submitted = false;
event: EventEmitter<any> = new EventEmitter();
maxdate :any;
//public utility: utility;
Isvalid:boolean;

  constructor(private ngxService: NgxUiLoaderService, private formBuilder: FormBuilder,private router: Router, private agentService: AgentsService,public modalRef: BsModalRef,private toastr: ToastrService, private permissionGroupsService: PermissionGroupsService) { }
 
  ngOnInit() {
    this.Isvalid = true;
    //this.maxdate = this.utility.GetToday();
    //this.ethnicities = [{"id":"1","ethnicity":"Australian"},{"id":"2","ethnicity":"European"}];
    this.agentModel = new UserAgentAddModel();
    this.agentModel.userType = 0;
    this.agentModel.roleId = null;
    this.userTypeArray = [{"id":0,"value":"Please select"},{"id":1,"value":"Admin"},{"id":3,"value":"Staff member"}];
    //,{"id":2,"value":"Client"}
    
    //console.log("============================");
    //console.log(this.agentModel);

    // this.agentService.clinicList().subscribe((clinicData:ClinicViewModel[])=>{
   
    //   this.clinics = clinicData;
    //   },
    //   (err: HttpErrorResponse)=>{
    //     console.log("Error"+ err.message);
    //   });
    //console.log(this.agentModel);

      if(this.data.agent !=null){
        //console.log("edit agent obj assign -- ");
        //this.agentModel = Object.assign({}, this.data.agent);
        this.agentModel = JSON.parse(JSON.stringify(this.data.agent)); // Object.assign({}, this.data.agent);
        //Object.assign(this.agentModel, this.data.agent);
        //console.log(this.agentModel);
        //Object.assign(this.agentModel, this.data.agent);
      }

      this.agentService.getAllCompany().subscribe((data:CompanyResponse)=>{     
        this.companys = data;
        // console.log('---------- company ID edit aget ---------')
        // console.log(this.companys.data[0].id);
        this.agentModel.companyId = this.companys.data[0].id;
        this.isLoaded = true;
          //console.log(this.companys.data[0]);
        },
        (err: HttpErrorResponse)=>{
          //console.log("Error"+ err.message);
        });

        this.permissionGroupsService.roleList().subscribe((data:RoleResponse)=>{  
          this.roleList = data.data;  
          this.isLoaded = true;
          },
          (err: HttpErrorResponse)=>{
            //console.log("Error"+ err.message);
          });


    }

    //get f() { return this.patientAddForm.controls; }
onSubmit(form:NgForm){
  // this.submitted = true;
  // if (this.patientAddForm.invalid) {
  //   return;
  // }

  // let roleId = this.agentModel.roleId;
  // let userType = this.agentModel.userType;



if(form.invalid || this.agentModel.roleId == "null" || this.agentModel.userType == 0  || this.agentModel.firstName.trim() == "" || this.agentModel.lastName.trim() == "" || this.agentModel.prefferedName.trim() == "") {
  // console.log(form.invalid);
   //console.log(form.controls);
  // console.log(this.agentModel);

  if(this.agentModel.roleId == "null"){
    form.controls['roleId'].setErrors({'required': true});
  }

  // form.controls['lastName2'].setErrors({'required': true});

  if(this.agentModel.userType == 0){
    form.controls['userType'].setErrors({'required': true});
  }


  if(this.agentModel.firstName.trim() == ""){
    form.controls['firstName'].setErrors({'required': true});
  }

  if(this.agentModel.lastName.trim() == ""){
    form.controls['lastName'].setErrors({'required': true});
  }

  if(this.agentModel.prefferedName.trim() == ""){
    form.controls['prefferedName'].setErrors({'required': true});
  }

  if(form.pristine){
  }
  this.Isvalid = false;
}

else{
  this.ngxService.start();

//  this.patientModel.clinicId = this.clinics[0].id;
//  this.patientModel.clinicianId = JSON.parse( localStorage.getItem('CurrentUserId'));
if(this.data.agent !=null){

// console.log("Edditre ============");
// console.log(this.agentModel);
//console.log("Edditre -----------");

  this.agentService.agentEdit(this.data.agent.id,this.agentModel).pipe(first())
  .subscribe((data:AgentProfileResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){      
      this.toastr.error('Error on updating agent.');

      // if(data.error.message){
      //   this.errors = data.error.message;
      // }
      // else{
      //   this.errors = Utility.GetLocalErrorString(data.error.errorCode);
      // }
      
    }
    else{
      this.toastr.success('Agent updated successfully.');
      this.event.emit('OK');
      this.modalRef.hide();

      this.router.navigate(['agents']);
    }

    // this.event.emit('OK');
    // this.modalRef.hide();
    },err=>{   
      this.ngxService.stop();
      this.toastr.error('Error on updating agent.');
    });
    
    //this.router.navigate(['agents']);
    

}  

}
}

modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

}
