import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { AuthService } from '../auth.service';
import { first } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  
  loading: boolean;
  invalid:boolean;
  valid:boolean;
  passwordResetForm: FormGroup;
  submitted: boolean = false;


  constructor(
    private authService: AuthService, 
    private fromBuilder: FormBuilder, 
    private router: Router,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService
    ) { 
    
  }

  ngOnInit() {        
    this.renderer.removeClass(this.document.body, 'vertical-layout');
    this.renderer.removeClass(this.document.body, 'vertical-menu');
    this.renderer.removeClass(this.document.body, '2-columns');
    this.renderer.removeClass(this.document.body, 'fixed-navbar');
    this.renderer.removeClass(this.document.body, 'menu-expanded');
    this.renderer.removeClass(this.document.body, 'pace-running');
    
    this.renderer.addClass(this.document.body, 'bg-full-screen-image');
    this.renderer.addClass(this.document.body, 'vertical-layout');
    this.renderer.addClass(this.document.body, 'vertical-menu');
    this.renderer.addClass(this.document.body, '1-column');
    this.renderer.addClass(this.document.body, 'menu-expanded');
    this.renderer.addClass(this.document.body, 'blank-page');

    this.passwordResetForm = this.fromBuilder.group({
      userName: ['', Validators.required]
      // password: ['', Validators.required]
    });
  }

  get inputs() { return this.passwordResetForm.controls; }

  onSubmit() {
    this.submitted = true;
    //console.log("form data +++++++++");
    //console.log(this.inputs.userName.value);

    // stop here if form is invalid
    if (this.passwordResetForm.invalid) {
      return;
    }
    else{
      this.ngxService.start();
    }
    
    this.authService.ResetPassword(this.inputs.userName.value)
      .pipe(first())
      .subscribe(
        data => {
          if(data.success){
            this.ngxService.stop();
            //this.invalid = false;
            //this.valid = true; 
            this.toastr.success("Password successfully send. Please use new password.");
            this.router.navigate(['/login'] );
          }else{
            this.ngxService.stop();
            this.toastr.error("Username does not exist.");
            //this.loading =false;
            //this.invalid = true;
          }
        },
        error => {
          this.ngxService.stop();
          this.toastr.error("Unable reset password contact administrator.");
          //console.log(error);
        }        
      );
      this.loading =true;
  }
}
