import { Injectable } from '@angular/core';
import { Response,ClentResponsex,ClentProfileResponse,NewsFeedResponse, TransactionResponse, DocumentUrlResponse, NewsFeedResponseByID, NewsFeedResponseProfile } from '../shared/models/response.model';

import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConstants } from '../utility/app-constants';
import { NewsFeedAddModel } from '../shared/models/news-feed.model';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class NewsFeedService {

  baseUrl:String;

  constructor(private http:HttpClient, private cookieService: CookieService) {
    this.baseUrl = AppConstants.getBaseURL();
  }

  public handleError(error) {
    return throwError(error);
  }

  // public newsFeedList(){
  //   const params = new HttpParams().set('take', '500');
  //   return this.http.get<NewsFeedResponse>(this.baseUrl+'/NewsFeed',{params});
  // }

  public newsFeedList(skip, take) {
    const params = new HttpParams().set('skip', skip).set('take', take);
    return this.http.get<NewsFeedResponse>(this.baseUrl+'/NewsFeed', { params } );
  }

  public newsFeedGetByUserList(userId){
    const params = new HttpParams().set('userId', userId);
    return this.http.get<NewsFeedResponseProfile>(this.baseUrl+'/NewsFeed/GetByUser',{params});
  }

  public addNewsFeed(newsFeedModel:NewsFeedAddModel, companyCode){
    //console.log(newsFeedModel);
    return this.http.post<NewsFeedResponse>(this.baseUrl +'/NewsFeed?companyCode='+companyCode,newsFeedModel).pipe(
      catchError(this.handleError)
      );
  }

  public editNewsFeed(id:string,newsFeedModel:NewsFeedAddModel) {
    console.log('dddddd-',newsFeedModel)
    const params = new HttpParams().set('id', id);
    return this.http.put<NewsFeedResponse>(this.baseUrl+'/NewsFeed',newsFeedModel,{params}).pipe(
      catchError(this.handleError)
      );
  }

  public getDocumentUrl(newsFeedModel){
    //console.log(newsFeedModel);
    // return this.http.post<DocumentUrlResponse>(this.baseUrl +'/NewsFeed',newsFeedModel).pipe(
    //   catchError(this.handleError)
    //   );

    var userId:string = this.cookieService.get('CurrentUserId'); //JSON.parse( localStorage.getItem('CurrentUserId'));

    const params = new HttpParams().set('S3Key', newsFeedModel.documentURL).set('fileCategory', newsFeedModel.documentType).set('userId', userId);
    return this.http.get<DocumentUrlResponse>(this.baseUrl+'/FileUpload/GetDocumentUrl',{params}).pipe(
      catchError(this.handleError)
      );

  }

  public deleteNewsFeed(id:string){
    const params = new HttpParams().set('id', id);
    return this.http.delete<Response>(this.baseUrl +'/NewsFeed?id='+id).pipe(
      catchError(this.handleError)
      );
  }

  public getByNewsFeedId(newsFeedId){
    const params = new HttpParams().set('newsFeedId', newsFeedId);
    return this.http.get<NewsFeedResponseByID>(this.baseUrl+'/NewsFeed/GetByNewsFeedId',{params});
  }

}
