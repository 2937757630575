import { Component, EventEmitter, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

@Component({
  selector: 'app-family-tree-pdf',
  templateUrl: './family-tree-pdf.component.html',
  styleUrls: ['./family-tree-pdf.component.css']
})
export class FamilyTreePdfComponent implements OnInit {
  public imageURL: string;
  public name: string;
  public today: Date;
  public data;

  public downloded: EventEmitter<any> = new EventEmitter();

  constructor() {
    this.imageURL = localStorage.getItem('imageURL');
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.name = this.data.firstName + ' '+ this.data.lastName;
    this.today = new Date();
    setTimeout(() => {
      this.download();
    }, 1000);
  }

  download() {
    var data = document.getElementById('calResult');

    const options = {
      height : data.offsetHeight + 200,
      scale: 1.5,
    }
    
    html2canvas(data, options).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png', 1.0)
      let pdf = new jspdf('l', 'mm', 'a4');
      var position = 0;

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
  
      const widthRatio = pageWidth / canvas.width;
      const ratio = widthRatio;
  
      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      pdf.addImage(contentDataURL, 'PNG', pageWidth/2 - canvasWidth /2, position, canvasWidth, canvasHeight, "",'FAST')

      pdf.save('familyTree_' + this.name + '_' +this.today.toLocaleDateString() + '.pdf');
      this.downloded.emit(true);
    });    
  }
}