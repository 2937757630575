import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SyncMonitoringResponse } from '../shared/models/response.model';
import { AppConstants } from '../utility/app-constants';

@Injectable({
  providedIn: 'root'
})
export class SyncMonitoringService {

  baseUrl:String;

  constructor(
    private http:HttpClient,
  ) { 
    this.baseUrl = AppConstants.getBaseURL();
  }

  public syncMonitoringListList(skip, take) {
    const params = new HttpParams().set('skip', skip).set('take', take);
    let mod = {"skip":skip, "take":take}
    return this.http.post<SyncMonitoringResponse>(this.baseUrl+'/SyncMonitoring',mod, { params } );
  }
}
