import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SendCodeComponent } from './auth/send-code/send-code.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { Role } from './shared/models/role';
import { HomeComponent } from './video/home/home.component';
import { CallComponent } from './video/call/call.component';
import { ClientsComponent } from './clients/clients.component';
import { GroupsComponent } from './groups/groups.component';
import { NewsFeedComponent } from './news-feed/news-feed.component';
import { ProfileComponent } from './profile/profile.component';

import { ChatComponent } from './chat/chat.component';
import { MessageCenterComponent } from './message-center/message-center.component';

import { AgentsComponent } from './agents/agents.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { UserVerifyComponent } from './user-verify/user-verify.component';
import { PasswordResetComponent } from './auth/password-reset/password-reset.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { BusinessesComponent } from './businesses/businesses.component';
import { PermissionGroupsComponent } from './permission-groups/permission-groups.component';
import { CompaniesComponent } from './companies/companies.component';
import { EmailSetupComponent } from './email-setup/email-setup.component';
import { DepartmentListComponent } from './email-setup/department-list/department-list.component';
import { FactFinderV2Component } from './fact-finder-v2/fact-finder-v2.component';
import { DownloadPdfComponent } from './fact-finder-v2/download-pdf/download-pdf.component';
import { AudienceComponent } from './audience/audience.component';
import { ViewAudienceComponent } from './audience/view-audience/view-audience.component';
import { NetworthHistoryComponent } from './networth-history/networth-history.component';
import { RollbackDataComponent } from './rollback-data/rollback-data.component';
import { ServiceAgreementComponent } from './service-agreement/service-agreement.component';
import { MaintenaceModeComponent } from './maintenace-mode/maintenace-mode.component';
import { ServiceAgreementInProfileComponent } from './profile/service-agreement-in-profile/service-agreement-in-profile.component';
import { EditServiceAgreementComponent } from './service-agreement/edit-service-agreement/edit-service-agreement.component';
import { SyncMonitoringComponent } from './sync-monitoring/sync-monitoring.component';
import { CloseDocusignComponent } from './profile/new-service-agreement/close-docusign/close-docusign.component';
import { UnallocatedAccountsComponent } from './unallocated-accounts/unallocated-accounts.component';

const routes: Routes = [
  {path:'login',component:LoginComponent},
  {path:'forget-password',component:PasswordResetComponent},
  {path:'send_code',component:SendCodeComponent},
  //{path:'dashboard',component:DashboardComponent},
  {path:'video_home',component:HomeComponent},
  {path:'call',component:CallComponent},
  {path:'edit-profile', component:EditProfileComponent},
  
  { 
    path: 'dashboard', 
    component: DashboardComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  { 
    path: 'clients', 
    component: ClientsComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  { 
    path: 'groups', 
    component: GroupsComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  { 
    path: 'client/view', 
    component: ProfileComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  { 
    path: 'client/view/networth-history', 
    component: NetworthHistoryComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  { 
    path: 'news-feed', 
    component: NewsFeedComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  { 
    path: 'message-center', 
    component: MessageCenterComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  { 
    path: 'audience', 
    component: AudienceComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  { 
    path: 'view-audience', 
    component: ViewAudienceComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  { 
    path: 'message-center/:id', 
    component: MessageCenterComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  //{ path: 'te/:id', component: AComponent },
  {
    path: 'agents', 
    component: AgentsComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  { 
    path: 'invoices', 
    component: InvoicesComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  { 
    path: 'appointments', 
    component: AppointmentsComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  { 
    path: 'chat', 
    component: ChatComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  { 
    path: 'un-verified-users/view', 
    component: UserVerifyComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {  
    path:'edit-profile',
    component: EditProfileComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },

  {  
    path:'businesses',
    component: BusinessesComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },

  {  
    path:'permission-groups',
    component: PermissionGroupsComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },

  {  
    path:'companies',
    component: CompaniesComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },

  {  
    path:'email-setup',
    component: EmailSetupComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },

  {  
    path:'rollback-data',
    component: RollbackDataComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },

  {  
    path:'department-list',
    component: DepartmentListComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },

  {  
    path:'fact-finder-v2',
    component: FactFinderV2Component , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },

  {  
    path:'download-pdf',
    component: DownloadPdfComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },

  {  
    path:'service-agreement',
    component: ServiceAgreementComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },

  {  
    path:'preview-service-agreement',
    component: EditServiceAgreementComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  
  {  
    path:'agreement',
    component: ServiceAgreementInProfileComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  
  {  
    path:'maintenace-mode',
    component: MaintenaceModeComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },

  {  
    path:'sync-monitoring',
    component: SyncMonitoringComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {
    path:'docusign-close',
    component: CloseDocusignComponent
  },  
  {  
    path:'unallocated-accounts',
    component: UnallocatedAccountsComponent , 
    canActivate: [AuthGuard], 
    data: { roles: [Role.Admin] } 
  },
  {path:'**',redirectTo:'login'}  

];
 



// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  })

export class AppRoutingModule { }
