<app-header></app-header>
<app-sidebar></app-sidebar>


<div class="app-content content">
    <div class="content-wrapper">
    <div class="content-header row mb-1">
      <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
        <h3 class="content-header-title mb-0 d-inline-block">ACCOUNT APPROVALS</h3>
        <div class="row breadcrumbs-top d-inline-block">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/dashboard">Home</a>
              </li>
              <li class="breadcrumb-item active">Account Approvals
              </li>
            </ol>
          </div>
        </div>
      </div>
      </div>
        <div class="content-body">
            <!-- Zero configuration table -->
            <section id="">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Newly Registered Accounts</h4>

                                <!-- <div class="btn-group float-md-right">
                                    <button class="btn btn-info appointment_btn btn-sm border_all_radius" type="button" (click)="openModal()" >Approve</button>
                                </div> -->
                            </div>
                            <div class="card-content collapse show">
                                <div class="card-body1 card-dashboard"  >
                                    <table  datatable [dtOptions]="dtOptions" class="t_dt table table-striped table-bordered table-sm row-border hover" style="margin-top: 20px !important; width:100% !important; max-width:100% !important;">
                                        <thead>
                                            <tr>
                                                <!-- <th scope="col" class="" width="2%">#</th> -->
                                                <th>Client Name</th>
                                                <th>User Name</th>
                                                <th>Client Code</th>
                                                <th>Xeppo Code</th>
                                                <th>Entity Type</th>
                                                <th>Primary Mobile</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="unVerifiedUsersList?.length > 0">
                                            <tr *ngFor="let user of unVerifiedUsersList; let i = index" >
                                                <!-- <th scope="row" class="">{{i+1}}</th> -->
                                                <td scope="row" class="">
                                                    <ng-container *ngIf="user.client != null"> {{user.client.firstName}} {{user.client.lastName}}</ng-container>
                                                    <ng-container *ngIf="user.client == null"> - </ng-container>
                                                </td>
                                                <td scope="row" class="">
                                                    <ng-container *ngIf="user.userName != null"> {{user.userName}} </ng-container>
                                                    <ng-container *ngIf="user.clientCode == null"> - </ng-container>
                                                </td>
                                                <td scope="row" class="">
                                                    <ng-container *ngIf="user.clientCode != null"> {{user.clientCode}} </ng-container>
                                                    <ng-container *ngIf="user.clientCode == null"> - </ng-container>
                                                </td>
                                                <td scope="row" class="">
                                                    <ng-container *ngIf="user.xeppoCode != null"> {{user.xeppoCode}} </ng-container>
                                                    <ng-container *ngIf="user.xeppoCode == null"> - </ng-container>
                                                </td>
                                                <!-- <td *ngIf="user.userName" scope="row" class="">{{user.client}}</td> -->
                                                <td scope="row" class="">
                                                    <ng-container *ngIf="user.client != null"> {{user.client.entityType}}</ng-container>
                                                    <ng-container *ngIf="user.client == null"> - </ng-container>
                                                </td>
                                                <td scope="row" class="">
                                                    <ng-container *ngIf="user.primaryMobile != null"> {{user.primaryMobile}} </ng-container>
                                                    <ng-container *ngIf="user.primaryMobile == null"> - </ng-container>
                                                </td>
                                                <td  class="sa_btns">   
                                                    <ng-container *ngIf="user.id" >
                                                        <!-- <button type="button" id="{{user.id}}" (click)="openConfirmationDialog(template,user.id)" class="btn app_acc_btn border_all_radius" >Approve</button> -->
                                                        <button class="btn btn-info appointment_btn btn-sm border_all_radius" type="button" (click)="openModal(user)" >Approve</button>
                                                    </ng-container>                                                  
                                                </td>

                                            </tr>
                        
                                            <!-- <tr *ngIf="unVerifiedUsersList?.length == 0" > 
                                                <td colspan="6" > No records to show.</td>
                                            </tr> -->
                                        </tbody>

                                        <tbody *ngIf="unVerifiedUsersList?.length == 0" style=" width: 100% !important; text-align: center;">
                                            <tr>
                                                <td colspan="5" >No records to show.</td>
                                                <td></td>
                                            </tr>
                                        <tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--/ Zero configuration table -->
        </div>
    </div>
</div>


<!-- <ng-template #template>
  <div class="modal-body text-center">
    <p>{{message}}</p>
    <button type="button" class="btn btn-default" (click)="userVerifi(user_id)" >Yes</button>
    <button type="button" class="btn btn-primary" (click)="decline(user_id)" >No</button>
  </div>
</ng-template> -->

<ng-template #template>
    <div class="modal-body text-center" >
        <img src="assets/img/warning.png" alt="" class="alert_img">
        <p>{{message}}</p>

            
        <div class="form-actions center mb-1">
            <button type="button" class="btn alert_no_b mr-1" (click)="decline(user_id)"> 
                No
            </button>
            <button type="button" class="btn alert_yes_b" (click)="userVerifi(user_id)" >
                    Yes
            </button>
        </div>
    </div>
</ng-template>

<app-footer></app-footer>