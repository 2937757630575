<app-header></app-header>
<app-sidebar></app-sidebar>


<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
        <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
            <h3 class="content-header-title mb-0 d-inline-block">Clients</h3>
            <div class="row breadcrumbs-top d-inline-block">
            <div class="breadcrumb-wrapper col-12">
                <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/dashboard">Home</a>
                </li>
                <li class="breadcrumb-item active">Clients
                </li>
                </ol>
            </div>
            </div>
        </div>
        <!-- <div class="btn-group float-md-right">
            <button class="btn btn-info appointment_btn btn-sm border_all_radius" type="button" (click)="openModal()" >Create New</button>
            
        </div> -->

        <div class="content-header-right col-md-6 col-12">
            <div class="btn-group float-md-right">
                <button class="btn appointment_btn btn-sm border_all_radius" data-toggle="modal" (click)="openModal()" >Create New</button>
            </div>
            
        </div>
        
        </div>
        <div class="content-body">
            <!-- Zero configuration table -->
            <section id="">
                <div class="row">
                    <div class="col-12">
                        
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Clients</h4>
                            </div>
                            <div class="card-content collapse show">
                                <div class="card-body1 card-dashboard"  >
                                    <table datatable [dtOptions]="dtOptions2" *ngIf="isLoaded" class="t_dt table table-striped table-bordered table-sm row-border hover" style="margin-top: 20px !important;">
                                        <thead>
                                            <tr>
                                                <!-- <th scope="col" class="" width="2%">#</th> -->
                                                <th>Client Name</th>
                                                <th>Client Code</th>
                                                <th>Xeppo Code</th>
                                                <th>Entity Type</th>
                                                <th>User Name</th>
                                                <th>Primary Mobile</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="clientList.length > 0">
                                            <ng-container *ngFor="let client of clientList | paginate: { itemsPerPage: 10, currentPage: p, totalItems: totalRecords }; let i = index ">
                                                    <tr *ngIf="client.client && !client.client.isVisibleToStaff && showStaffMembers"  >
                                                            <!-- <th scope="row" class="">{{i+1}}</th> -->
                                                            <td  scope="row" class="" data-toggle="tooltip" title=" {{client.client.firstName}} {{client.client.lastName}}">
                                                                <ng-container *ngIf="client.client.firstName" >{{client.client.firstName | limitTo : 20}} &nbsp;</ng-container> <ng-container *ngIf="client.client.lastName" >{{client.client.lastName | limitTo : 20}}</ng-container>    
                                                                <ng-container *ngIf="(client.client.firstName == null || client.client.firstName =='') && (client.client.lastName == null || client.client.lastName =='')" >--</ng-container>
                                                            </td>
                                                            <td  scope="row" class="">
                                                                    {{client.client.code}}
                                                            </td>
                                                            <td  scope="row" class="">
                                                                    {{client.client.xeppoCode}}
                                                            </td>
                                                            <td  scope="row" class="">
                                                                    {{client.client.entityType}}
                                                            </td>
                                                            <td  scope="row" class="">
                                                                <ng-container *ngIf="client.userName">{{client.userName}}</ng-container>
                                                                <ng-container *ngIf="client.userName==null || client.userName==''">-</ng-container>
                                                            </td>
                                                            <td  scope="row" class="">
                                                                <ng-container *ngIf="client.primaryMobile">{{client.primaryMobile}}</ng-container>
                                                                <ng-container *ngIf="client.primaryMobile==null || client.primaryMobile==''">-</ng-container>
                                                            </td>

                                                            <td class="sa_btns">
                                                                <div class="btn-group">
                                                                    <button type="button" class="btn btn-icon mr-1 btn_trash" (click)="openDeleteDialog(template,client.id)" ><i class="la la-trash"></i></button>
                                                                    <a [routerLink]="['/client/view']" [queryParams]="{ id: client.id, clientId: client.client.id }" > <i class="la la-arrow-right la_icon"></i> </a>
                                                                </div>
                                                            </td>
            
                                                        </tr>
                                                        <tr *ngIf="client.client && client.client.isVisibleToStaff"  >
                                                            <!-- <th scope="row" class="">{{i+1}}</th> -->
                                                            <td  scope="row" class="" data-toggle="tooltip" title=" {{client.client.firstName}} {{client.client.lastName}}">
                                                                <ng-container *ngIf="client.client.firstName" >{{client.client.firstName | limitTo : 20}} &nbsp;</ng-container> <ng-container *ngIf="client.client.firstName" >{{client.client.lastName | limitTo : 20}}</ng-container>    
                                                            </td>
                                                            <td  scope="row" class="">
                                                                    {{client.client.code}}
                                                            </td>
                                                            <td  scope="row" class="">
                                                                    {{client.client.xeppoCode}}
                                                            </td>
                                                            <td  scope="row" class="">
                                                                    {{client.client.entityType}}
                                                            </td>
                                                            <td  scope="row" class="">
                                                                <ng-container *ngIf="client.userName">{{client.userName}}</ng-container>
                                                                <ng-container *ngIf="client.userName==null || client.userName==''">-</ng-container>
                                                            </td>
                                                            <td  scope="row" class="">
                                                                <ng-container *ngIf="client.primaryMobile">{{client.primaryMobile}}</ng-container>
                                                                <ng-container *ngIf="client.primaryMobile==null || client.primaryMobile==''">-</ng-container>
                                                            </td>
            
                                                            <td class="sa_btns">
                                                                <div class="btn-group">
                                                                    <button type="button" class="btn btn-icon mr-1 btn_trash" (click)="openDeleteDialog(template,client.id)" ><i class="la la-trash"></i></button>
                                                                    <a [routerLink]="['/client/view']" [queryParams]="{ id: client.id, clientId: client.client.id }" > <i class="la la-arrow-right la_icon"></i> </a>
                                                                </div>
                                                            </td>
            
                                                        </tr>
                                            </ng-container>
                                            
                        
                                            <!-- <tr *ngIf="patientFilterdList?.length == 0" >
                                                <td colspan="6" > No records to show.</td>
                                            </tr> -->

                                            <!-- <tr>
                                                <td>Cook Grazing Pty Ltd</td>
                                                <td>1953482</td>
                                                <td>113902</td>
                                                <td>Company</td>
                                                <td>0422515507</td>
                                                <td>
                                                <a href="/profile"><button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button></a>

                                                </td>
                                            </tr>
                                            <tr>                                                    
                                                <td>Tomac Pty Ltd</td>
                                                <td>1953510</td>
                                                <td>113903</td>
                                                <td>Company</td>
                                                <td>0428523507</td>
                                                <td>
                                                    <a href="/profile"><button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Aimee Santacaterina</td>
                                                <td>1953517</td>
                                                <td>113904</td>
                                                <td>Individual</td>
                                                <td>0428595507</td>
                                                <td>
                                                     <a href="/profile"><button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Scott Wakefield</td>
                                                <td>1953533</td>
                                                <td>113905</td>
                                                <td>Individual</td>
                                                <td>0413689880</td>
                                                <td>
                                                     <a href="/profile"><button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Rachael Moyses</td>
                                                <td>1953539</td>
                                                <td>113906</td>
                                                <td>Individual</td>
                                                <td>0420852726</td>
                                                <td>
                                                     <a href="/profile"><button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button></a>
                                            </tr>
                                            <tr>
                                                <td>Catherine Whittaker</td>
                                                <td>1953720</td>
                                                <td>113907</td>
                                                <td>Individual</td>
                                                <td>0413147720</td>
                                                <td>
                                                     <a href="/profile"><button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Joy Kern</td>
                                                <td>1953728</td>
                                                <td>113908</td>
                                                <td>Individual</td>
                                                <td>0401739301</td>
                                                <td>
                                                    <a href="/profile"><button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Peter Stefanos</td>
                                                <td>1953744</td>
                                                <td>113909</td>
                                                <td>Individual</td>
                                                <td>0407702583</td>
                                                <td>
                                                   <a href="/profile"><button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button></a>
                                            </tr>
                                            <tr>
                                                <td>Jason Barra</td>
                                                <td>1953758</td>
                                                <td>113910</td>
                                                <td>Individual</td>
                                                <td>0438787635</td>
                                                <td>
                                                   <a href="/profile"><button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Bell Emma</td>
                                                <td>1953761</td>
                                                <td>113911</td>
                                                <td>Company</td>
                                                <td>0407740673</td>
                                                <td>
                                                    <a href="/profile"><button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Danielle Brunow</td>
                                                <td>1953811</td>
                                                <td>113912</td>
                                                <td>Company</td>
                                                <td>04222217368</td>
                                                <td>
                                                    <a href="/profile"><button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Peter Cook</td>
                                                <td>1953992</td>                                                    
                                                <td>113913</td>
                                                <td>Individual</td>
                                                <td>0428595278</td>
                                                <td>
                                                    <a href="/profile"><button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Clara Catalano</td>
                                                <td>1953021</td>
                                                <td>113911</td>
                                                <td>Individual</td>
                                                <td>0421630352</td>
                                                <td>
                                                    <a href="/profile"><button type="button" class="btn btn-primary btn-sm float-right table_view">View <i class="la la-angle-right"></i></button></a>
                                                </td>
                                            </tr> -->

                                        </tbody>
                                        <tbody *ngIf="clientList.length == 0" style=" width: 100% !important; text-align: center;">
                                            <tr>
                                                <td colspan="5" >No records to show.</td>
                                                <td></td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                            <div [hidden]="!isLoaded || totalRecords <= 10" class="grid-pagination-wr col-12">
                                <div class="bg-white d-flex justify-content-between align-items-center px-1 rounded-bottom">
                                  <span>Showing
                                    {{ (currentPage == 1) ? currentPage : ((currentPage * itemsPerPage) -  itemsPerPage + 1)}}  to
                                    {{ (itemsPerPage == clientList?.length) ? (currentPage * itemsPerPage) :  totalRecords }} of
                                    {{ totalRecords }} entries
                                  </span>
                                  <!-- <pagination
                                    [boundaryLinks]="showBoundaryLinks"
                                    [directionLinks]="showDirectionLinks"
                                    [totalItems]="totalRecords"
                                    [itemsPerPage]="itemsPerPage"
                                    (pageChanged)="getClientList($event)"
                                    [maxSize]="15">
                                  </pagination> -->
                                    <pagination-controls (pageChange)="p = $event"
                                        directionLinks="true"
                                        autoHide="true"
                                        responsive="true"
                                        previousLabel="Previous"
                                        nextLabel="Next"
                                        screenReaderPaginationLabel="Pagination"
                                        screenReaderPageLabel="page"
                                        screenReaderCurrentLabel="You're on page"
                                        (pageChange)="getClientList($event)"
                                        (pageBoundsCorrection)="getClientList($event)">
                                    </pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--/ Zero configuration table -->
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-body text-center" >

        <img  src="assets/img/warning.png" alt="" class="alert_img">
        <p>{{message}}</p>
          
        <div class="form-actions center mb-1">
            <button type="button" class="btn alert_no_b mr-1" (click)="decline()"> 
                No
            </button>
            <button type="button" class="btn alert_yes_b" (click)="confirm()" >
                 Yes
            </button>
        </div>
    </div>
</ng-template>

<app-footer></app-footer>