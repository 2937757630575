import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FacFinderService } from '../fac-finder.service';
import { XFacContactAddModel } from 'src/app/shared/models/xfac-contact.model';
import { XFacContactResponse, XFacAddressResponse, XFacEmploymentResponse } from 'src/app/shared/models/response.model';
import { XFacAddressAddModel } from 'src/app/shared/models/xfac-address.model';
import { XFacEmploymentAddModel } from 'src/app/shared/models/xfac-employment.model';

@Component({
  selector: 'app-add-employment',
  templateUrl: './add-employment.component.html',
  styleUrls: ['./add-employment.component.css']
})
export class AddEmploymentComponent implements OnInit {

  title;
  data;
  errors;
  addEmploymentsForm: FormGroup;
  employmentAddModel : XFacEmploymentAddModel;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  buttonText: string ="Add New Contact Details";
  employmentStatusList : any = [];
  employmentOccupationList : any = [];
  facFinderId : any;
  clientCode : any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private facFinderService: FacFinderService,
    public modalRef: BsModalRef,
    private toastr: ToastrService
    ) { }
 
  ngOnInit() {
    this.Isvalid = true;    
    this.employmentAddModel = new XFacEmploymentAddModel(); 
    this.employmentAddModel.status ="000"; 
    this.employmentAddModel.occupation ="000"; 

    if(this.data.employment !=null){
      //console.log(this.data.library);
      this.buttonText = "Update Now";
      Object.assign(this.employmentAddModel, this.data.employment);
    }

    if(this.data.employment_occupation_list !=null){
      Object.assign(this.employmentOccupationList, this.data.employment_occupation_list);
    }

    if(this.data.employment_status_list !=null){
      Object.assign(this.employmentStatusList, this.data.employment_status_list);
    }

    if(this.data.facFinderId !=null){
      this.facFinderId = this.data.facFinderId;
    }

    if(this.data.clientCode !=null){
      this.clientCode = this.data.clientCode;
    }

    this.title = this.data.title;

    // console.log(this.clientCode);
    // if(this.data.business_id !=null){
    //   this.business_id = this.data.business_id;
    // }

    this.addEmploymentsForm = this.formBuilder.group({
      employer: ['', Validators.required],
      occupation: ['', Validators.required],
      status: ['', Validators.required],
      // employerStreet: ['', Validators.required],
      // employerSuburb: ['', Validators.required],
      // employerState: ['', Validators.required],
      // employerPostcode: ['', Validators.required],
      // employerCountry: ['', Validators.required],
    });

  }

  get f() { return this.addEmploymentsForm.controls; }

onSubmit(){
 this.submitted = true;

 let status = this.employmentAddModel.status;

 if (this.addEmploymentsForm.invalid || (status && status.trim()=="000") ) { //|| (title && title.trim()=="")

  if(status && status.trim()=="000"){
    this.addEmploymentsForm.controls['status'].setErrors({'required': true});
  }
  // console.log(this.f);
  // console.log(this.addEmploymentsForm.invalid);
  return;
}

else{
  //this.folderModel.name = title.trim();

if(this.data.employment !=null){
//   this.ngxService.start();
// //console.log("Edditre");

  this.ngxService.start();
  this.employmentAddModel.clientCode = this.clientCode;
  this.facFinderService.editEmployment(this.employmentAddModel, this.facFinderId)
  .subscribe((data:XFacEmploymentResponse)=>{
  this.ngxService.stop();
  if(data.error && !data.success){      
    this.toastr.error('Error on updating employment.');      
  }
  else{
    this.toastr.success('Employment updated successfully.');
    this.event.emit('OK');
   // console.log(this.event);
    this.modalRef.hide();
  }
  
  },err=>{   
    console.log(err);
    this.ngxService.stop();
    this.toastr.error('Error on updating employment.');
  });
    
    //this.router.navigate(['agents']);
    

}else{
  
  this.ngxService.start();
  this.employmentAddModel.clientCode = this.clientCode;
  this.facFinderService.addEmployment(this.employmentAddModel, this.facFinderId)
  .subscribe((data:XFacEmploymentResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){      
      this.toastr.error('Error on saving employment.');      
    }
    else{
      this.toastr.success('Employment added successfully.');
      this.event.emit('OK');
     // console.log(this.event);
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on saving employment.');
    });

  }

}

}

modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

onReset() {
  this.submitted = false;
  this.addEmploymentsForm.reset();
}

}
