import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FacFinderService } from './fac-finder.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../utility/app-constants';
import { XFacPersonalInfoResponse, FactFinderNewResponse, ClentProfileResponse, XFacAddressResponse, XFacContactResponse, XFacDependentResponse, XFacEmploymentResponse, XFacInsuranceLifeResponse, XFacGoalResponse, XFacIncomeResponse, XFacExpensesResponse, XFacEstatePlanningResponse, XFacAssetResponse, XFacLiabilityResponse, XplanChoicesResponse, XPlanSubmitResponse, XFacRetirementIncomeResponse, XFacInsuranceMedicalResponse, XFacStatusListResponse, XFacStatusUserList } from 'src/app/shared/models/response.model';
//import { XFacPersonalInfoResponse, FactFinderNewResponse, ClentProfileResponse, XFacAddressResponse, XFacContactResponse, XFacDependentResponse, XFacEmploymentResponse, XFacEstatePlanningResponse } from 'src/app/shared/models/response.model';
import { XFacPersonalInfoAddModel } from 'src/app/shared/models/xfac-personalInfo.model';
import { FactFinderNew } from '../shared/models/fact-finder-categories.model';
import { ClientViewModel } from '../shared/models/client.model';
import { XFacContactAddModel } from '../shared/models/xfac-contact.model';
import { XFacAddressAddModel } from '../shared/models/xfac-address.model';
import { XFacDependentAddModel } from '../shared/models/xfac-dependent.model';
import { XFacEmploymentAddModel } from '../shared/models/xfac-employment.model';
import { XFacInsuranceLifeAddModel } from '../shared/models/xfac-InsuranceLife.model';
import { XFacGoalAddModel } from '../shared/models/xfac-goal.model';
import { XFacExpensesAddModel } from '../shared/models/xfac-expenses.model';
import { XFacIncomeAddModel } from '../shared/models/xfac-income.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { XFacEstatePlanningAddModel } from '../shared/models/xfac-estatePlanning.model';
import { XFacAssetAddModel } from '../shared/models/xfac-asset.model';
import { XFacLiabilityAddModel } from '../shared/models/xfac-liability.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AddContactDetailsComponent } from './add-contact-details/add-contact-details.component';
import { XplanChoices } from '../shared/models/xplan-choices.model';
import { AddAddressComponent } from './add-address/add-address.component';
import { AddDependantComponent } from './add-dependant/add-dependant.component';
import { AddEmploymentComponent } from './add-employment/add-employment.component';
import { AddEstateDetailsComponent } from './add-estate-details/add-estate-details.component';
import { AddGoalComponent } from './add-goal/add-goal.component';
import { AddIncomeComponent } from './add-income/add-income.component';
import { AddExpensesComponent } from './add-expenses/add-expenses.component';
import { AddInsuranceComponent } from './add-insurance/add-insurance.component';
import { AddCoversComponent } from './add-covers/add-covers.component';
import { XplanCoverGrouped } from '../shared/models/xplan-cover-grouped.model';
// import { forEach } from '@angular/router/src/utils/collection';
import { AddAssetsComponent } from './add-assets/add-assets.component';
import { AddLiabilitiesComponent } from './add-liabilities/add-liabilities.component';
import { XFacRetirementIncomeAddModel } from '../shared/models/xfac-retirementIncome.model';
import { AddRetirementIncomeComponent } from './add-retirement-income/add-retirement-income.component';
import { XFacInsuranceMedicalAddModel } from '../shared/models/xplan-insuranceMedical.model';
import { AddMedicalInsuranceComponent } from './add-medical-insurance/add-medical-insurance.component';
import { AddFactFinderVersionComponent } from './add-fact-finder-version/add-fact-finder-version.component';
import { DownloadPdfComponent } from './download-pdf/download-pdf.component';
import { Response } from '../shared/models/response.model';
import { XplanStatusView } from '../shared/models/xplan-status.model';
import { AuthService } from '../auth/auth.service';


@Component({
  selector: 'app-fact-finder-v2',
  templateUrl: './fact-finder-v2.component.html',
  styleUrls: ['./fact-finder-v2.component.css']
})
export class FactFinderV2Component implements OnInit {

  clientId: any;
  
  factFindersNew:FactFinderNew[];
  client:ClientViewModel;
  personalDetailsForm: FormGroup;
  submitted = false;
  versionNumber: number = 0;
  recordType: number = 0;
  buttonShow: boolean = true;
  name: any = '';
  facId: any = '';
  facIdRecent: any = '';

  personalInfo:XFacPersonalInfoAddModel;
  personalData: XFacPersonalInfoAddModel;
  personalDataEdit: XFacPersonalInfoAddModel;
  assetData:XFacAssetResponse[];
  contactInfo:XFacContactAddModel[];
  addressInfo:XFacAddressAddModel[];
  dependentInfo:XFacDependentAddModel[] =[];
  employmentInfo:XFacEmploymentAddModel[];
  insuranceData:XFacInsuranceLifeAddModel[];
  goalData:XFacGoalAddModel[];
  expenseData:XFacExpensesAddModel[];
  incomeData:XFacIncomeAddModel[];
  estatePlanninInfo:XFacEstatePlanningAddModel;
  liabilityInfo:XFacLiabilityAddModel[]; 
  assetInfo:XFacAssetAddModel[];
  retirementIncomeInfo:XFacRetirementIncomeAddModel[];
  estatePlanninData:XFacEstatePlanningAddModel;
  choicesList : XplanChoices[];
  medicalInsuranceInfo : XFacInsuranceMedicalAddModel[];

  isLoaded: boolean;
  isLoaded1: boolean;
  isLoaded2: boolean;
  isLoaded3: boolean;
  isLoaded4: boolean;
  isLoaded5: boolean;
  isLoaded6: boolean;
  isLoaded7: boolean;
  isLoaded8: boolean;
  isLoaded9: boolean;
  isLoaded10: boolean;
  isLoaded11: boolean;
  isLoaded12: boolean;
  isLoaded13: boolean;
  isLoaded14: boolean;
  isLoaded15: boolean;
  draftExist: boolean = false;
  
  personalData_title_list : any;
  personalInfo_gender_list : any;
  personalInfo_marital_list : any;
  contact_type_list : any;
  contact_type_list_1 : any;
  address_type_list:any;
  address_type_list_1 : any;
  dependant_gender_list:any;
  employment_status_list: any;
  goal_owner_list: any;
  clientCode : any;

  liabilityLength: number = 0;
  contactInfoLength:number = 0;
  addressInfoLength:number = 0;
  dependentInfoLength: number = 0;
  employmentInfoLength: number =0;
  estatePlanninInfoLength: number =0;
  assetInfoLength: number =0;
  incomeDataLength: number =0;
  expenseDataLength: number =0;
  goalDataLength: number =0;
  insuranceDataLength: number =0;
  factFindersNewLength: number = 0;
  //personalInfoLength: number = 0;
  retirementIncomeInfoLength: number = 0;
  medicalInsuranceInfoLength: number = 0;

  showFactFinderCat :boolean = true;
  showPersonalDetails : boolean = false;
  showContactDetails:boolean = false;
  showDependent:boolean = false;
  showEmployement:boolean = false;
  showEstateDetails:boolean = false;
  showAssets:boolean = false;
  showLiabilities:boolean = false;
  showIncome:boolean = false;
  showExpenses:boolean = false;
  showGoals:boolean = false;
  showInsurance:boolean = false;
  showRetirementIncome:boolean = false;
  showInsuranceMedical:boolean = false;

  showSuccessModal:any = 'none';
  showErrorModal : any = 'none';
  income_cashflow_list:any;
  income_cashflow_subtype_list:any;
  income_expense_cashflow_owner_list:any;
  income_expense_cashflow_frequency_list:any;
  expense_cashflow_list: any;
  expense_cashflow_subtype_list: any;
  asset_owner_type_list: any;
  insurance_type_list: any;
  insurance_benefit_period_type_list: any;
  insurance_waiting_period_type_list: any;
  insurance_type_list_filtered: any=[];
  asset_owner_list :any;
  asset_type: any;
  asset_sub_type: any;
  liability_owner_list :any;
  liability_type_list: any;
  liability_sub_type_list: any;
  liability_repayment_frequency_list:any;
  liability_interest_rate_type_list:any;
  liability_lender_supplier_list:any;
  retirement_income_plan_provider_list:any;
  retirement_plan_selection_list:any;
  insurance_medical_type_list:any;
  employment_occupation_list:any;
  selectedRow: any = 0;
  message: string;

  contact : XFacContactAddModel;
  address : XFacAddressAddModel;
  dependent : XFacDependentAddModel;
  employment : XFacEmploymentAddModel;
  asset : XFacAssetAddModel;
  liability : XFacLiabilityAddModel;
  income : XFacIncomeAddModel;
  expense : XFacExpensesAddModel;
  goal : XFacGoalAddModel;
  insurance : XFacInsuranceLifeAddModel;
  retirementIncome : XFacRetirementIncomeAddModel;
  medicalInsurance : XFacInsuranceMedicalAddModel;
  statusList : XplanStatusView[];

  constructor(
    private router : Router ,
    private route: ActivatedRoute, 
    private facFinderService: FacFinderService,
    private ngxService: NgxUiLoaderService,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService, 
    private authService: AuthService
    ) { }

  ngOnInit() {
    this.clientId = this.route.snapshot.queryParams['id']; 
    this.name = this.route.snapshot.queryParams['name'];  
    this.clientCode = this.route.snapshot.queryParams['clientCode'];
    
    this.personalData = new XFacPersonalInfoAddModel();
    this.personalData.title = "0";  
    this.personalData.gender = "100";  
    this.personalData.maritalStatus = "0";  
    this.personalData.smoker = "0";

    this.personalDataEdit = new XFacPersonalInfoAddModel();
    this.personalDataEdit.title = "0";  
    this.personalDataEdit.gender = "100";  
    this.personalDataEdit.maritalStatus = "0";  
    this.personalDataEdit.smoker = "0";
    //this.getClient();
    this.getChoiceList();
    this.getFactFinderNewList();
    
    this.personalDetailsForm = this.formBuilder.group({  
      title: ['', [Validators.required]], // [Validators.required, Validators.minLength(2)]
      surname: ['', [Validators.required]], // [Validators.required]
      firstName: ['', [Validators.required]], // [Validators.required]
      middleName: ['', [Validators.required]], // [Validators.required]
      gender: ['', [Validators.required]], // [Validators.required]
      dateOfBirth: ['', [Validators.required]], // [Validators.required]
      maritalStatus: ['', [Validators.required]], // [Validators.required]
      taxFileNumber: ['', [Validators.required]], // [Validators.required]
      smoker: ['', [Validators.required]], // [Validators.required]
    });
  }

  getFactFinderNewList(){
    this.facFinderService.getFacFinder(this.clientId).subscribe((data:FactFinderNewResponse)=>{
      if(data.success){
          this.showFactFinderCat = true;
          this.factFindersNew = data.response;
          // console.log('sdsdsd');
          // console.log(this.factFindersNew);
          if(this.factFindersNew.length>0){
            this.facId = this.factFindersNew[0].id;
            this.facIdRecent = this.factFindersNew[0].id;
            this.versionNumber = this.factFindersNew[0].versionNumber;
            this.recordType = this.factFindersNew[0].recordType;

            console.log(this.recordType);
            let draft_exist = this.factFindersNew.find(x => x.recordType == 1, x => x.recordType == 2);
            if(draft_exist){
              this.draftExist = true
            }

            if(this.recordType == 4 || this.recordType == 3) {
              this.buttonShow = false
            }
            else{
              this.buttonShow = true
            }

            console.log(this.buttonShow);

            this.factFindersNewLength = this.factFindersNew.length;
   
            //this.getPersonalInfo();
            //this.getContact();
            //this.getAddress();
            //this.getDependent();
            //this.getEmploymen();
            //this.getEstatePlanning();
            //this.getAsset();
            //this.getLiability();
            // this.getIncome();
            // this.getExpenses();
            //this.getGoals();
            //this.getInsurance();
          } 
          this.isLoaded1 = true;   
        }        
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });
  }

  getChoiceList(){
    // this.ngxService.start();
    this.facFinderService.getChoiceList().subscribe((clientData:XplanChoicesResponse)=>{
      if(clientData.success){
        this.choicesList = clientData.response;  
        this.personalData_title_list = this.choicesList.filter(choice => choice.choiceType === 20);
        this.personalData_title_list = this.personalData_title_list.sort((n1,n2) => {
                                          if (n1.key > n2.key) {
                                              return 1;
                                          }
                                      
                                          if (n1.key < n2.key) {
                                              return -1;
                                          }                                      
                                          return 0;
                                      });

        this.personalInfo_gender_list = this.choicesList.filter(choice => choice.choiceType === 21);
        this.personalInfo_marital_list = this.choicesList.filter(choice => choice.choiceType === 22);
        this.contact_type_list_1 = this.choicesList.filter(choice => choice.choiceType === 6);        
        this.contact_type_list =this.contact_type_list_1.filter(contact_c => (contact_c.key == "m1" || contact_c.key == "m2" || contact_c.key == "m3" || contact_c.key == "p3" || contact_c.key == "p5" ));

        this.address_type_list_1 = this.choicesList.filter(choice => choice.choiceType === 1);
        this.address_type_list = this.address_type_list_1.filter(contact_c => (contact_c.key == "0" || contact_c.key == "1" ));

        this.dependant_gender_list = this.choicesList.filter(choice => choice.choiceType === 3);
        this.employment_status_list = this.choicesList.filter(choice => choice.choiceType === 4);
        this.goal_owner_list = this.choicesList.filter(choice => choice.choiceType === 7);
        this.income_cashflow_list = this.choicesList.filter(choice => choice.choiceType === 12);
        this.income_cashflow_subtype_list = this.choicesList.filter(choice => choice.choiceType === 10);
        this.income_expense_cashflow_owner_list = this.choicesList.filter(choice => choice.choiceType === 13);
        this.income_expense_cashflow_frequency_list = this.choicesList.filter(choice => choice.choiceType === 14);
        this.expense_cashflow_list = this.choicesList.filter(choice => choice.choiceType === 11);
        this.expense_cashflow_subtype_list = this.choicesList.filter(choice => choice.choiceType === 5);
        this.asset_owner_type_list = this.choicesList.filter(choice => choice.choiceType === 8);
        this.insurance_benefit_period_type_list = this.choicesList.filter(choice => choice.choiceType === 19);
        this.insurance_waiting_period_type_list = this.choicesList.filter(choice => choice.choiceType === 18);
        this.insurance_type_list = this.choicesList.filter(choice => choice.choiceType === 17);

        this.filterInsuranceTypeList(this.insurance_type_list);    
        this.asset_owner_list = this.choicesList.filter(choice => choice.choiceType === 8);
        this.asset_type = this.choicesList.filter(choice => choice.choiceType === 9);
        this.asset_sub_type = this.choicesList.filter(choice => choice.choiceType === 2);   

        this.liability_owner_list = this.choicesList.filter(choice => choice.choiceType === 8);
        this.liability_type_list = this.choicesList.filter(choice => choice.choiceType === 24);
        this.liability_sub_type_list = this.choicesList.filter(choice => choice.choiceType === 25);
        this.liability_repayment_frequency_list = this.choicesList.filter(choice => choice.choiceType === 26);
        this.liability_interest_rate_type_list = this.choicesList.filter(choice => choice.choiceType === 27);
        this.liability_lender_supplier_list = this.choicesList.filter(choice => choice.choiceType === 28);
        this.retirement_income_plan_provider_list = this.choicesList.filter(choice => choice.choiceType === 15);
        this.retirement_plan_selection_list = this.choicesList.filter(choice => choice.choiceType === 16);
        this.insurance_medical_type_list = this.choicesList.filter(choice => choice.choiceType === 23);
        this.employment_occupation_list = this.choicesList.filter(choice => choice.choiceType === 29);

        //console.log(this.contact_type_list);      
      } 
      // this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message); 
      });
  }

  getClient(){
    this.facFinderService.getClient(this.clientId).subscribe((clientData:ClentProfileResponse)=>{
        this.client = clientData.response;
        this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
        
      });
  }

  setFacId(factFinder, i) {
    //console.log(factFinder);
    this.ngxService.start();
    this.selectedRow = i;
    this.facId = factFinder.id;
    this.versionNumber = factFinder.versionNumber;
    this.recordType = factFinder.recordType;

    if(this.recordType == 4 || this.recordType == 3) {
      this.buttonShow = false
    }
    else{
      this.buttonShow = true
    }

    this.hideItems();
    this.ngxService.stop();
  }

  getPersonalInfo(){
    this.ngxService.start();
    this.facFinderService.getPersonalInfo(this.facId).subscribe((clientData:XFacPersonalInfoResponse)=>{
      if(clientData.success){
        this.personalInfo = clientData.response;
        this.showPersonalDetails = true;//!this.showPersonalDetails;
       
        //if(this.personalInfo.length>0){
          //this.personalInfoLength = this.personalInfo.length;
          // console.log(this.personalInfo);
          this.personalData = this.personalInfo;         
          
          if(this.personalData) {
            this.personalData.dateOfBirth = new Date(this.personalData.dateOfBirth+'Z');
          }
          Object.assign(this.personalDataEdit, this.personalData);
          // if(!this.personalData.dateOfBirth || this.personalData.dateOfBirth == null) {
          //   this.personalData.dateOfBirth = null;
          // }
          
        //} 
        this.isLoaded2 = true;
      } 
      this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  getContact(){
    this.ngxService.start();
    this.facFinderService.getContact(this.facId).subscribe((contactData:XFacContactResponse)=>{
      if(contactData.success){  
          this.contactInfo = contactData.response;
          this.showContactDetails = true;//!this.showContactDetails;

          this.contactInfo.forEach(element => {            
            let choice = this.contact_type_list_1.filter(choice => choice.key === element.contactType);
            if(choice && choice[0]) {
              element.contactTypeName = choice[0].value;
            }
            
          });
         
          if(this.contactInfo.length>0){
            this.contactInfoLength = this.contactInfo.length;
          }
          this.isLoaded3 = true; 
        } 
        this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  getAddress(){
    this.facFinderService.getAddress(this.facId).subscribe((addressData:XFacAddressResponse)=>{
        this.addressInfo = addressData.response;

        this.addressInfo.forEach(element => {            
          let choice = this.address_type_list_1.filter(choice => choice.key === element.addressType);
          if(choice && choice[0]) {
            element.addressTypeName = choice[0].value;
          }
        });

        if(this.addressInfo.length>0){
          this.addressInfoLength = this.addressInfo.length;
        }
        this.isLoaded4 = true; 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  getDependent(){
    this.ngxService.start();
    this.facFinderService.getDependent(this.facId).subscribe((dependentData:XFacDependentResponse)=>{
      if(dependentData.success){ 
        //Object.assign(this.dependentInfo, dependentData.response) 
          this.dependentInfo = dependentData.response;
          console.log(this.dependentInfo);
          this.showDependent = true;
          if(this.dependentInfo.length>0){
            this.dependentInfoLength = this.dependentInfo.length;
          }
          this.isLoaded5 = true; 
        } 
        this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  getEmploymen(){
    this.ngxService.start();
    this.facFinderService.getEmploymen(this.facId).subscribe((employmentData:XFacEmploymentResponse)=>{
      if(employmentData.success){
          this.employmentInfo = employmentData.response;

          this.employmentInfo.forEach(element => {            
            let choice = this.employment_occupation_list.filter(choice => choice.key === element.occupation);
            if(choice && choice[0]) {
              element.occupationName = choice[0].value;
            }
          });

          this.showEmployement = true;//!this.showEmployement;
          if(this.employmentInfo.length>0){
            this.employmentInfoLength = this.employmentInfo.length;
          }
          this.isLoaded6 = true; 
        } 
        this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  getEstatePlanning(){
    this.ngxService.start();
    this.facFinderService.getEstatePlanning(this.facId).subscribe((estatePlanninData:XFacEstatePlanningResponse)=>{
        if(estatePlanninData.success){
          this.estatePlanninInfo = estatePlanninData.response;
          this.showEstateDetails = true;//!this.showEstateDetails;
          //if(this.estatePlanninInfo.length>0){
            this.estatePlanninData = this.estatePlanninInfo;
            //this.estatePlanninInfoLength = this.estatePlanninInfo.length;
          //}  
          this.isLoaded7 = true; 
        } 
        this.ngxService.stop(); 
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  getAsset(){
    this.ngxService.start();
    this.facFinderService.getAsset(this.facId).subscribe((assetData:XFacAssetResponse)=>{
      if(assetData.success){
        this.assetInfo = assetData.response;

        this.assetInfo.forEach(element => {            
          let choice = this.asset_type.filter(choice => choice.key === element.type);
          if(choice && choice[0]) {
            element.assetTypeName = choice[0].value;
          }
        });

        this.assetInfo.forEach(element => {            
          let choice = this.asset_sub_type.filter(choice => choice.key === element.subType);
          if(choice && choice[0]) {
            element.assetSubTypeName = choice[0].value;
          }
        });

        this.showAssets = true;//!this.showAssets;
        if(this.assetInfo.length>0){
          this.assetInfoLength = this.assetInfo.length;
        }
        this.isLoaded8 = true; 
      } 
      this.ngxService.stop(); 
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });      
  }

  getLiability(){
    this.ngxService.start();
    this.facFinderService.getLiability(this.facId).subscribe((liabilityData:XFacLiabilityResponse)=>{
      if(liabilityData.success){
        this.liabilityInfo = liabilityData.response;

        this.liabilityInfo.forEach(element => {            
          let choice = this.liability_owner_list.filter(choice => choice.key === element.owner);
          if(choice && choice[0]) {
            element.ownerTypeName = choice[0].value;
          }
          
        });

        this.liabilityInfo.forEach(element => {            
          let choice = this.liability_type_list.filter(choice => choice.key === element.type);
          if(choice && choice[0]) {
            element.typeName = choice[0].value;
          }
          
        });

        this.liabilityInfo.forEach(element => {            
          let choice = this.liability_sub_type_list.filter(choice => choice.key === element.subType);
          if(choice && choice[0]) {
            element.subTypeName = choice[0].value;
          }
          
        });
        

        this.liabilityInfo.forEach(element => {            
          let choice = this.liability_repayment_frequency_list.filter(choice => choice.key === element.repaymentFrequency);
          if(choice && choice[0]) {
            element.repaymentFrequencyName = choice[0].value;
          }
          
        });

        this.liabilityInfo.forEach(element => {            
          let choice = this.liability_interest_rate_type_list.filter(choice => choice.key === element.interestRateType);
          if(choice && choice[0]) {
            element.interestRateTypeName = choice[0].value;
          }
          
        });

        this.liabilityInfo.forEach(element => {            
          let choice = this.liability_lender_supplier_list.filter(choice => choice.key === element.lenderSupplier);
          if(choice && choice[0]) {
            element.lenderSupplierName = choice[0].value;
          }
          
        });

        this.showLiabilities = true;
        if(this.liabilityInfo.length>0){
          this.liabilityLength = this.liabilityInfo.length;
        } 
        this.isLoaded9 = true; 
      }
      this.ngxService.stop(); 
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });      
  }

  getIncome(){
    this.ngxService.start();
    this.facFinderService.getIncome(this.facId).subscribe((incomeData:XFacIncomeResponse)=>{
      if(incomeData.success){
        this.incomeData = incomeData.response;

        this.incomeData.forEach(element => {            
          let choice = this.income_cashflow_list.filter(choice => choice.key === element.typeGroup);
          if(choice && choice[0]) {
            element.incomeTypeName = choice[0].value;
          }
        });

        this.incomeData.forEach(element => {            
          let choice = this.income_cashflow_subtype_list.filter(choice => choice.key === element.type);
          if(choice && choice[0]) {
            element.incomeSubTypeName = choice[0].value;
          }
        });

        this.incomeData.forEach(element => {            
          let choice = this.income_expense_cashflow_frequency_list.filter(choice => choice.key === element.frequency);
          if(choice && choice[0]) {
            element.incomeFrequencyName = choice[0].value;
          }
        });

        this.showIncome = true;//!this.showIncome;
        if(this.incomeData.length>0){
          this.incomeDataLength = this.incomeData.length;
        }
        this.isLoaded10 = true; 
      } 
      this.ngxService.stop();
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });      
  }

  getExpenses(){
    this.ngxService.start();
    this.facFinderService.getExpenses(this.facId).subscribe((expensesData:XFacExpensesResponse)=>{
      if(expensesData.success){
        this.expenseData = expensesData.response;

        this.expenseData.forEach(element => {            
          let choice = this.expense_cashflow_list.filter(choice => choice.key === element.typeGroup);
          if(choice && choice[0]) {
            element.expenseTypeName = choice[0].value;
          }
        });

        this.expenseData.forEach(element => {            
          let choice = this.expense_cashflow_subtype_list.filter(choice => choice.key === element.type);
          if(choice && choice[0]) {
            element.expenseSubTypeName = choice[0].value;
          }
        });

        this.expenseData.forEach(element => {            
          let choice = this.income_expense_cashflow_frequency_list.filter(choice => choice.key === element.frequency);
          if(choice && choice[0]) {
            element.expenseFrequencyName = choice[0].value;
          }
        });

        this.showExpenses = true;
        if(this.expenseData.length>0){
          this.expenseDataLength = this.expenseData.length;
        }
        this.isLoaded11 = true; 
      } 
      this.ngxService.stop();
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });    
  }

  getGoals(){
    this.ngxService.start();
    this.facFinderService.getGoals(this.facId).subscribe((goalData:XFacGoalResponse)=>{
      if(goalData.success){
        this.goalData = goalData.response;
        this.showGoals = true;//!this.showGoals;
        if(this.goalData.length>0){
          this.goalDataLength = this.goalData.length;
        }
        this.isLoaded12 = true; 
      } 
      this.ngxService.stop();
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });    
  }

  getInsurance(){
    this.ngxService.start();
    this.facFinderService.getInsurance(this.facId).subscribe((insuranceLifeData:XFacInsuranceLifeResponse)=>{
      if(insuranceLifeData.success){
        this.insuranceData = insuranceLifeData.response;
        this.showInsurance = true;//!this.showInsurance;
        if(this.insuranceData.length>0){
          this.insuranceDataLength = this.insuranceData.length;

          this.insuranceData.forEach(element => {            
            let choice = this.liability_owner_list.filter(choice => choice.key === element.policyOwners);
            if(choice && choice[0]) {
              element.ownerTypeName = choice[0].value;
            }
            
          });

          this.insuranceData.forEach(element => {
            if(element.xFacInsuranceCovers){
              element.groupedCovers =  this.groupCovers(element.xFacInsuranceCovers);

              // let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.groupedCovers.insuranceCoverType);
              // if(choice && choice[0]) {
              //   element.groupedCovers.insuranceCoverTypeName = choice[0].value;
              // }
              
            }
          });  

          // console.log(this.insuranceData);
          // console.log(this.insurance_type_list_filtered);

          // this.insuranceData.forEach(element => {
          //   console.log(element.groupedCovers);
          // });
          
        }
        
        this.isLoaded13 = true; 
      } 
      this.ngxService.stop();
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });    
  }

  getRetirementIncome(){
    this.ngxService.start();
    this.facFinderService.getRetirementIncome(this.facId).subscribe((retirementIncomeData:XFacRetirementIncomeResponse)=>{
      if(retirementIncomeData.success){
        this.retirementIncomeInfo = retirementIncomeData.response;

        this.retirementIncomeInfo.forEach(element => {            
          let choice = this.retirement_income_plan_provider_list.filter(choice => choice.key === element.retirementPlan);
          if(choice && choice[0]) {
            element.retirementPlanName = choice[0].value;
          }
        });

        this.retirementIncomeInfo.forEach(element => {            
          let choice = this.retirement_plan_selection_list.filter(choice => choice.key === element.retirementPlanSelection);
          if(choice && choice[0]) {
            element.retirementPlanSelectionName = choice[0].value;
          }
        });

        this.showRetirementIncome = true;//!this.showAssets;
        if(this.retirementIncomeInfo.length>0){
          this.retirementIncomeInfoLength = this.retirementIncomeInfo.length;
        }
        this.isLoaded14 = true; 
      } 
      this.ngxService.stop(); 
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });      
  }

  getInsuranceMedical(){
    this.ngxService.start();
    this.facFinderService.getInsuranceMedical(this.facId).subscribe((medicalInsuranceInfo:XFacInsuranceMedicalResponse)=>{
      if(medicalInsuranceInfo.success){
        this.medicalInsuranceInfo = medicalInsuranceInfo.response;

        this.medicalInsuranceInfo.forEach(element => {            
          let choice = this.insurance_medical_type_list.filter(choice => choice.key === element.type);
          if(choice && choice[0]) {
            element.insuranceMedicalTypeName = choice[0].value;
          }
        });

        this.medicalInsuranceInfo.forEach(element => {            
          let choice = this.asset_owner_list.filter(choice => choice.key === element.owners);
          if(choice && choice[0]) {
            element.ownerName = choice[0].value;
          }
        });

        this.showInsuranceMedical = true;//!this.showAssets;
        if(this.medicalInsuranceInfo.length>0){
          this.medicalInsuranceInfoLength = this.medicalInsuranceInfo.length;
        }
        this.isLoaded15 = true; 
      } 
      this.ngxService.stop(); 
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });      
  }

  
  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

  openPersonalDetailsModal(template: TemplateRef<any>,personalData) {
    this.personalData = personalData;
    this.modalRef = this.modalService.show(template, { 
      class: '', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
    //this.idToBeDeleted = id;
  }


  get f() { return this.personalDetailsForm.controls; }

  onSubmitPersonalDetails(){
    this.submitted = true;  
    let maritalStatus = this.personalDataEdit.maritalStatus;
    let gender = this.personalDataEdit.gender;
    let title = this.personalDataEdit.title;
    let smoker = this.personalDataEdit.smoker;
    
    if (this.personalDetailsForm.invalid || (maritalStatus && maritalStatus.trim()=="0") || (title && title.trim()=="0") || (gender && gender.trim()=="100") || (smoker && smoker=="0") ) {
  
      if(title && title.trim()=="0"){
        this.personalDetailsForm.controls['title'].setErrors({'required': true});
      }
      if(smoker && smoker=="0"){
        this.personalDetailsForm.controls['smoker'].setErrors({'required': true});
      }
  
      if(maritalStatus && maritalStatus.trim()=="0"){
        this.personalDetailsForm.controls['maritalStatus'].setErrors({'required': true});
      }
      if(gender && gender.trim()=="100"){
        this.personalDetailsForm.controls['gender'].setErrors({'required': true});
      }
  
      
      return;
      }
      else{  
        //console.log(this.personalDataEdit);
      this.ngxService.start(); 
      this.facFinderService.savePersonalInfo(this.personalDataEdit, this.facId)
      .subscribe((data:XFacPersonalInfoResponse)=>{
        this.ngxService.stop();
        if(data.error && !data.success){           
          this.toastr.error('Error on saving personal details.');      
        }
        else{
          this.getPersonalInfo();
          this.toastr.success('Personal details updated successfully.');
          this.modalRef.hide();
        }    
        },err=>{
          this.ngxService.stop();
          this.toastr.error('Error on saving personal details.');
        });
      }
    }

  openContactAddModal() {    
    this.modalRef = this.modalService.show(AddContactDetailsComponent,  {
      initialState: {
        heading_title: 'Add New Contact Details', 
        //business_id:this.business_id,      
        data: {   
          contact_type_list : this.contact_type_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode, 
          title : 'Add',    
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getContact();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openContactEdit(contact:any) {  
    //console.log(newsFeed); 
    // this.contact_type_list = this.contact_type_list.sort((n1,n2) => n1.value - n2.value) 
    this.modalRef = this.modalService.show(AddContactDetailsComponent,  {
      initialState: {
        heading_title: 'Edit Contact',
        data: {
          contact_type_list : this.contact_type_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,
          contact : contact,
          title : 'Edit',
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        this.getContact();
        // this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});      
      }
    });
  }

  openAddressAddModal() {    
    this.modalRef = this.modalService.show(AddAddressComponent,  {
      initialState: {
        heading_title: 'Add New Contact Details', 
        //business_id:this.business_id,      
        data: {   
          address_type_list : this.address_type_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode, 
          title : 'Add',     
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getAddress();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openAddressEdit(address:any) {  
    //console.log(newsFeed);  
    this.modalRef = this.modalService.show(AddAddressComponent,  {
      initialState: {
        heading_title: 'Edit Contact',
        data: {
          address_type_list : this.address_type_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,
          address : address,
          title : 'Edit', 
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        this.getAddress();
        // this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});      
      }
    });
  }

  openDependantAddModal() {    
    this.modalRef = this.modalService.show(AddDependantComponent,  {
      initialState: {
        heading_title: 'Add New Contact Details', 
        //business_id:this.business_id,      
        data: {   
          dependant_gender_list : this.dependant_gender_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,   
          title : 'Add',   
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getDependent();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openDependantEditModal(dependent){
    this.modalRef = this.modalService.show(AddDependantComponent,  {
      initialState: {
        heading_title: 'Edit Contact',
        data: {
          dependant_gender_list : this.dependant_gender_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,
          dependant : dependent,
          title : 'Edit', 
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        this.getDependent();
        // this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});      
      }
    });
  }

  openEmploymentAddModal() {    
    this.modalRef = this.modalService.show(AddEmploymentComponent,  {
      initialState: {
        heading_title: 'Add New Contact Details', 
        //business_id:this.business_id,      
        data: {
          employment_occupation_list : this.employment_occupation_list,   
          employment_status_list : this.employment_status_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,     
          title : 'Add', 
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getEmploymen();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openEmploymentEditModal(employment){
    this.modalRef = this.modalService.show(AddEmploymentComponent,  {
      initialState: {
        heading_title: 'Edit Contact',
        data: {
          employment_occupation_list : this.employment_occupation_list,
          employment_status_list : this.employment_status_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,
          employment : employment,
          title : 'Edit', 
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        this.getEmploymen();
        // this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});      
      }
    });
  }

  openAssetAddModal() {    
    this.modalRef = this.modalService.show(AddAssetsComponent,  {
      initialState: {
        heading_title: 'Add New Asset', 
        //business_id:this.business_id,      
        data: {   
          asset_owner_list : this.asset_owner_list,
          asset_type : this.asset_type,
          asset_sub_type : this.asset_sub_type,
          facFinderId : this.facId,  
          clientCode : this.clientCode,     
          title : 'Add',
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getAsset();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openAssetEdit(assets:any) {  
    //console.log(newsFeed); 
    console.log("Success getAsset");
        console.log(this.getAsset()); 
    this.modalRef = this.modalService.show(AddAssetsComponent,  {
      initialState: {
        heading_title: 'Edit Asset',
        data: {
          asset_owner_list : this.asset_owner_list,
          asset_type : this.asset_type,
          asset_sub_type : this.asset_sub_type,
          facFinderId : this.facId,  
          clientCode : this.clientCode,
          assets : assets,
          title : 'Edit',
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        this.getAsset();
        // this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});      
      }
    });
  }

  openLiabilitiesAddModal() {    
    this.modalRef = this.modalService.show(AddLiabilitiesComponent,  {
      initialState: {
        heading_title: 'Add New Liability', 
        //business_id:this.business_id,      
        data: {      
          liability_owner_list : this.liability_owner_list,
          liability_type_list : this.liability_type_list,
          liability_sub_type_list : this.liability_sub_type_list,
          liability_repayment_frequency_list : this.liability_repayment_frequency_list,
          liability_interest_rate_type_list : this.liability_interest_rate_type_list,
          liability_lender_supplier_list : this.liability_lender_supplier_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,     
          title : 'Add',
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getLiability();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openLiabilitiesEdit(liability:any) {  
    //console.log(newsFeed);  
    this.modalRef = this.modalService.show(AddLiabilitiesComponent,  {
      initialState: {
        heading_title: 'Edit Liability',
        data: {
          liability_owner_list : this.liability_owner_list,
          liability_type_list : this.liability_type_list,
          liability_sub_type_list : this.liability_sub_type_list,
          liability_repayment_frequency_list : this.liability_repayment_frequency_list,
          liability_interest_rate_type_list : this.liability_interest_rate_type_list,
          liability_lender_supplier_list : this.liability_lender_supplier_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,
          liability : liability,
          title : 'Edit',
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        this.getLiability();
        // this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});      
      }
    });
  }

  openEstateEditModal(estate) {    
    this.modalRef = this.modalService.show(AddEstateDetailsComponent,  {
      initialState: {
        heading_title: 'Add New Contact Details', 
        //business_id:this.business_id,      
        data: {   
          //employment_status_list : this.employment_status_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,
          estate : estate,    
          title : 'Edit', 
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getEstatePlanning();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openGoalAddModal(){
    this.modalRef = this.modalService.show(AddGoalComponent,  {
      initialState: {
        heading_title: 'Add New Contact Details', 
        //business_id:this.business_id,      
        data: {   
          goal_owner_list : this.goal_owner_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,     
          title : 'Add',
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getGoals();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openGoalEditModal(goal){
    this.modalRef = this.modalService.show(AddGoalComponent,  {
      initialState: {
        heading_title: 'Add New Contact Details', 
        //business_id:this.business_id,      
        data: {   
          goal_owner_list : this.goal_owner_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,     
          goal : goal,  
          title : 'Edit',   
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getGoals();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openIncomeAddModal(){
    this.modalRef = this.modalService.show(AddIncomeComponent,  {
      initialState: {
        heading_title: 'Add New Contact Details', 
        //business_id:this.business_id,      
        data: {   
          income_cashflow_list : this.income_cashflow_list,
          income_cashflow_subtype_list : this.income_cashflow_subtype_list,
          income_expense_cashflow_owner_list : this.income_expense_cashflow_owner_list,
          income_expense_cashflow_frequency_list : this.income_expense_cashflow_frequency_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,    
          title : 'Add',    
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getIncome();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openIncomeEditModal(income){
    this.modalRef = this.modalService.show(AddIncomeComponent,  {
      initialState: {
        heading_title: 'Add New Contact Details', 
        //business_id:this.business_id,      
        data: {   
          income_cashflow_list : this.income_cashflow_list,
          income_cashflow_subtype_list : this.income_cashflow_subtype_list,
          income_expense_cashflow_owner_list : this.income_expense_cashflow_owner_list,
          income_expense_cashflow_frequency_list : this.income_expense_cashflow_frequency_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,        
          income : income,    
          title : 'Edit',    
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getIncome();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openExpenseAddModal(){
    this.modalRef = this.modalService.show(AddExpensesComponent,  {
      initialState: {
        heading_title: 'Add New Contact Details', 
        //business_id:this.business_id,      
        data: {  
          expense_cashflow_list : this.expense_cashflow_list,
          expense_cashflow_subtype_list : this.expense_cashflow_subtype_list,
          income_expense_cashflow_owner_list : this.income_expense_cashflow_owner_list,
          income_expense_cashflow_frequency_list : this.income_expense_cashflow_frequency_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,    
          title : 'Add',    
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getExpenses();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openExpenseEditModal(expense){
    this.modalRef = this.modalService.show(AddExpensesComponent,  {
      initialState: {
        heading_title: 'Add New Contact Details', 
        //business_id:this.business_id,      
        data: {  
          expense_cashflow_list : this.expense_cashflow_list,
          expense_cashflow_subtype_list : this.expense_cashflow_subtype_list,
          income_expense_cashflow_owner_list : this.income_expense_cashflow_owner_list,
          income_expense_cashflow_frequency_list : this.income_expense_cashflow_frequency_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,        
          expense : expense,  
          title : 'Edit',      
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getExpenses();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openInsuranceAddModal(){
    this.modalRef = this.modalService.show(AddInsuranceComponent,  {
      initialState: {
        heading_title: 'Add New Contact Details', 
        //business_id:this.business_id,      
        data: {  
          // expense_cashflow_list : this.expense_cashflow_list,
          // expense_cashflow_subtype_list : this.expense_cashflow_subtype_list,
          // income_expense_cashflow_owner_list : this.income_expense_cashflow_owner_list,
          asset_owner_type_list : this.asset_owner_type_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,   
          title : 'Add',     
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getInsurance();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openInsuranceEditModal(insurance){
    this.modalRef = this.modalService.show(AddInsuranceComponent,  {
      initialState: {
        heading_title: 'Add New Contact Details', 
        //business_id:this.business_id,      
        data: {  
          // expense_cashflow_list : this.expense_cashflow_list,
          // expense_cashflow_subtype_list : this.expense_cashflow_subtype_list,
          // income_expense_cashflow_owner_list : this.income_expense_cashflow_owner_list,
          asset_owner_type_list : this.asset_owner_type_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,        
          insurance : insurance, 
          title : 'Edit',       
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getInsurance();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openCoverAddModal(insurance){
    this.modalRef = this.modalService.show(AddCoversComponent,  {
      initialState: {
        heading_title: 'Add New Contact Details', 
        //business_id:this.business_id,      
        data: {  
          // expense_cashflow_list : this.expense_cashflow_list,
          // expense_cashflow_subtype_list : this.expense_cashflow_subtype_list,
          // income_expense_cashflow_owner_list : this.income_expense_cashflow_owner_list,
          
          insurance_benefit_period_type_list : this.insurance_benefit_period_type_list,
          insurance_waiting_period_type_list : this.insurance_waiting_period_type_list,
          insurance_type_list : this.insurance_type_list_filtered,
          asset_owner_type_list : this.asset_owner_type_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,        
          insurance : insurance,  
          title : 'Add',      
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getInsurance();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openCoverEditModal(insurance, cover, insurance_category){
    this.modalRef = this.modalService.show(AddCoversComponent,  {
      initialState: {
        heading_title: 'Add New Contact Details', 
        //business_id:this.business_id,      
        data: {  
          // expense_cashflow_list : this.expense_cashflow_list,
          // expense_cashflow_subtype_list : this.expense_cashflow_subtype_list,
          // income_expense_cashflow_owner_list : this.income_expense_cashflow_owner_list,
          insurance_benefit_period_type_list : this.insurance_benefit_period_type_list,
          insurance_waiting_period_type_list : this.insurance_waiting_period_type_list, 
          asset_owner_type_list : this.asset_owner_type_list,
          insurance_type_list : this.insurance_type_list_filtered,
          facFinderId : this.facId,  
          clientCode : this.clientCode,        
          insurance : insurance,               
          insurance_category : insurance_category,               
          cover : cover,   
          title : 'Edit',     
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getInsurance();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openRetirementIncomeAddModal() {
    this.modalRef = this.modalService.show(AddRetirementIncomeComponent,  {
      initialState: {
        heading_title: 'Add New Retirement Income', 
        //business_id:this.business_id,      
        data: {   
          retirement_income_plan_provider_list : this.retirement_income_plan_provider_list,
          retirement_plan_selection_list : this.retirement_plan_selection_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,   
          title : 'Add',  
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getRetirementIncome();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openRetirementIncomeEdit(retirementIncome:any) {  
    //console.log(newsFeed);  
    this.modalRef = this.modalService.show(AddRetirementIncomeComponent,  {
      initialState: {
        heading_title: 'Edit Retirement Income',
        data: {
          retirement_income_plan_provider_list : this.retirement_income_plan_provider_list,
          retirement_plan_selection_list : this.retirement_plan_selection_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,
          retirementIncome : retirementIncome,
          title : 'Edit',
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        this.getRetirementIncome();
        // this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});      
      }
    });
  }

  openMedicalInsuranceAddModal() {
    this.modalRef = this.modalService.show(AddMedicalInsuranceComponent,  {
      initialState: {
        heading_title: 'Add New Medical Insurance', 
        //business_id:this.business_id,      
        data: {   
          insurance_medical_type_list : this.insurance_medical_type_list,
          asset_owner_list : this.asset_owner_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,     
          title : 'Add',
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        this.getInsuranceMedical();
        //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
      }
    });
  }

  openMedicalInsuranceEdit(medicalInsurance:any) {  
    //console.log(newsFeed);  
    this.modalRef = this.modalService.show(AddMedicalInsuranceComponent,  {
      initialState: {
        heading_title: 'Edit Medical Insurance',
        data: {
          insurance_medical_type_list : this.insurance_medical_type_list,
          asset_owner_list : this.asset_owner_list,
          facFinderId : this.facId,  
          clientCode : this.clientCode,
          medicalInsurance : medicalInsurance,
          title : 'Edit',
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        this.getInsuranceMedical();
        // this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});      
      }
    });
  }

  clickPersonalDetails(){
    if(!this.showPersonalDetails){
      this.hideItems();
      this.getPersonalInfo();
    }
    else{
      this.showPersonalDetails = !this.showPersonalDetails;
    }
  }

  clickContactDetails(){
    if(!this.showContactDetails){
      this.hideItems();
      this.getAddress();
      this.getContact();
    }
    else{
      this.showContactDetails = !this.showContactDetails;
    }
  }

  clickDependent(){
    if(!this.showDependent){
      this.hideItems();
      this.getDependent();
    }
    else{
      this.showDependent = !this.showDependent;
    }
  }

  clickEmployement(){
    if(!this.showEmployement){
      this.hideItems();
      this.getEmploymen();
    }
    else{
      this.showEmployement = !this.showEmployement;
    }
  }

  clickEstateDetails(){
    if(!this.showEstateDetails){
      this.hideItems();
      this.getEstatePlanning();
    }
    else{
      this.showEstateDetails = !this.showEstateDetails;
    }
  }

  clickAssets(){
    if(!this.showAssets){
      this.hideItems();
      //this.getLiability();
      this.getAsset();
    }
    else{
      this.showAssets = !this.showAssets;
    }
  }


  clickLiabilities(){
    if(!this.showLiabilities){
      this.hideItems();
      this.getLiability();
    }
    else{
      this.showLiabilities = !this.showLiabilities;
    }
  }


  clickIncome(){
    if(!this.showIncome){
      this.hideItems();
      //this.getExpenses();
      this.getIncome();    
    }
    else{
      this.showIncome = !this.showIncome;
    }
  }

  clickExpenses(){
    if(!this.showExpenses){
      this.hideItems();
      this.getExpenses(); 
    }
    else{
      this.showExpenses = !this.showExpenses;
    }
  }

  clickGoals(){
    if(!this.showGoals){
      this.hideItems();
      this.getGoals();   
    }
    else{
      this.showGoals = !this.showGoals;
    }
  }

  clickInsurance(){
    if(!this.showInsurance){
      this.hideItems();
      this.getInsurance();   
    }
    else{
      this.showInsurance = !this.showInsurance;
    }
  }

  clickRetirementIncome(){
    if(!this.showRetirementIncome){
      this.hideItems();
      this.getRetirementIncome();
    }
    else{
      this.showRetirementIncome = !this.showRetirementIncome;
    }
  }

  clickMedicalInsurance(){
    if(!this.showInsuranceMedical){
      this.hideItems();
      this.getInsuranceMedical();
    }
    else{
      this.showInsuranceMedical = !this.showInsuranceMedical;
    }
  }


  hideItems(){
    this.showPersonalDetails = false;
    this.showContactDetails = false;
    this.showDependent = false;
    this.showEmployement = false;
    this.showEstateDetails = false;
    this.showAssets = false;
    this.showLiabilities = false;
    this.showIncome = false;
    this.showExpenses = false;
    this.showGoals = false;
    this.showInsurance = false;
    this.showRetirementIncome = false;
    this.showInsuranceMedical = false;
  }

  // openEdit(notice:any) {  
  //   //console.log(newsFeed);  
  //   this.modalRef = this.modalService.show(AddNoticeComponent,  {
  //     initialState: {
  //       heading_title: 'Edit Post',
  //       business_id:this.business_id,
  //       data: {
  //         notice:notice
  //       }
  //     },
  //     keyboard: false,
  //     backdrop: true,
  //     ignoreBackdropClick: true,
  //     class: "modal-xl"
  //   });

  //   this.modalRef.content.event.subscribe(result => {
  //     if (result == 'OK') {
  //       //console.log("Success");
  //       //this.getNewsFeedList();
  //       this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});      
  //     }
  //   });
  // }

  showSubmitToXplan(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { 
      class: 'modal-sm', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
    //this.idToBeDeleted = id;
  }

  showSubmitProgerssXplan(template) {
    this.modalRef = this.modalService.show(template, { 
      class: '', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
    //this.idToBeDeleted = id;
  }

  submitToXplan(template2){
    //let temp = <TemplateRef>template2
    this.modalRef.hide();
    this.showSubmitProgerssXplan(template2);
    this.facFinderService.submitToXplan(this.facId )
    .subscribe((data:XPlanSubmitResponse)=>{
      //this.modalRef.hide();
      // this.ngxService.stop();
      if(data.error && !data.success){
          this.modalRef.hide();      
          this.showSuccessModal= 'none';  
          this.showErrorModal = 'block';  
        // this.toastr.error('Error on saving personal details.');      
      }
      else{
          this.modalRef.hide();
          this.showSuccessModal= 'block'; 
          this.showErrorModal = 'none';  
          this.getFactFinderNewList();         
        // this.getPersonalInfo();
        // this.toastr.success('Personal details updated successfully.');
        
      }    
      },err=>{   
        this.modalRef.hide();
          this.showSuccessModal= 'none';
          this.showErrorModal = 'block';        
        //this.modalRef.hide();
        console.log(err);
        // this.ngxService.stop();
        // this.toastr.error('Error on saving personal details.');
      });
  }

  showMarkAsReviewed(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { 
      class: 'modal-sm', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
    //this.idToBeDeleted = id;
  }

  markAsReviewed(){
    //let temp = <TemplateRef>template2
    this.modalRef.hide();
    //this.showSubmitProgerssXplan(template2);
    this.facFinderService.markAsReviewed(this.facId )
    .subscribe((data:Response)=>{
      //this.modalRef.hide();
      // this.ngxService.stop();
      if(data.error && !data.success){
         this.modalRef.hide();   
         this.toastr.error('Error on updating mark as reviewed.');      
      }
      else{
          this.modalRef.hide();          
          //this.getFactFinderNewList(); 
          this.recordType = 3;        
          this.toastr.success('Successfully updated mark as reviewed.');
        
      }    
      },err=>{   
        this.modalRef.hide();
        this.toastr.error('Error on updating mark as reviewed.');
        //this.modalRef.hide();
        console.log(err);
        // this.ngxService.stop();
        // this.toastr.error('Error on saving personal details.');
      });
  }

  hideModals(){
    this.showSuccessModal= 'none';
    this.showErrorModal = 'none';
  }


  filterInsuranceTypeList(insurance_type_list){
    insurance_type_list.forEach(element => {
      if(element.key.indexOf("l")==0){
        element.insurance_type = "life";
        element.insurance_value = "Life Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("d")==0){
        element.insurance_type = "tpd";
        element.insurance_value = "TPD Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("t")==0){
        element.insurance_type = "trauma";
        element.insurance_value = "Trauma Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("i")==0){
        element.insurance_type = "income";
        element.insurance_value = "Income Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("b")==0){
        element.insurance_type = "business";
        element.insurance_value = "Business Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("o")==0){
        element.insurance_type = "additional";
        element.insurance_value = "Additional Cover";
        this.insurance_type_list_filtered.push(element);
      }
      else if(element.key.indexOf("x")==0){
        element.insurance_type = "severity";
        element.insurance_value = "Severity Cover";
        this.insurance_type_list_filtered.push(element);
      }
      // insurance_type_list_filtered
    });
  }

  filterContactTypeList(contact_type_list){

  }

  groupCovers(xFacInsuranceCovers){

    let grouped = new XplanCoverGrouped();
    xFacInsuranceCovers.forEach(element => {
      if(element.insuranceCoverType){
        if(element.insuranceCoverType.indexOf("l")==0){

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.life.push(element);
          console.log("life-----");
          console.log(grouped.life);
        }
        else if(element.insuranceCoverType.indexOf("d")==0){
          // grouped['tpd'].push(element);

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.tpd.push(element);
          console.log("tpd-----");
          console.log(grouped.tpd);
          // element.insurance_type = "tpd";
          // element.insurance_value = "TPD Cover";
          // this.insurance_type_list_filtered.push(element);
        }
        else if(element.insuranceCoverType.indexOf("t")==0){
          //grouped['trauma'].push(element);

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.trauma.push(element);
          console.log("trauma-----");
          console.log(grouped.trauma);
          // element.insurance_type = "trauma";
          // element.insurance_value = "Trauma Cover";
          // this.insurance_type_list_filtered.push(element);
        }
        else if(element.insuranceCoverType.indexOf("i")==0){
          // grouped['income'].push(element);

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.income.push(element);
          console.log("income-----");
          console.log(grouped.income);
          // element.insurance_type = "income";
          // element.insurance_value = "Income Cover";
          // this.insurance_type_list_filtered.push(element);
        }
        else if(element.insuranceCoverType.indexOf("b")==0){
          // grouped['business'].push(element);

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.business.push(element);
          console.log("business-----");
          console.log(grouped.business);
          // element.insurance_type = "business";
          // element.insurance_value = "Business Cover";
          // this.insurance_type_list_filtered.push(element);
        }
        else if(element.insuranceCoverType.indexOf("o")==0){
          // grouped['additional'].push(element);

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.additional.push(element);
          console.log("additional-----");
          console.log(grouped.additional);
          // element.insurance_type = "additional";
          // element.insurance_value = "Additional Cover";
          // this.insurance_type_list_filtered.push(element);
        }
        else if(element.insuranceCoverType.indexOf("x")==0){
          // grouped['severity'].push(element);

          let choice = this.insurance_type_list_filtered.filter(choice => choice.key === element.insuranceCoverType);
          if(choice && choice[0]) {
            element.insuranceCoverTypeName = choice[0].value;
          }

          grouped.severity.push(element);
          console.log("severity-----");
          console.log(grouped.severity);
          // element.insurance_type = "severity";
          // element.insurance_value = "Severity Cover";
          // this.insurance_type_list_filtered.push(element);
        }
        
        //element.groupedCovers =  this.groupCovers(element.xFacInsuranceCovers);
      }
    });  
    return grouped;
  }

  decline(data): void {
    this.modalRef.hide();
  }

  public openContactConfirmationDialog(template: TemplateRef<any>,contact:XFacContactAddModel) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.contact = contact;
    this.message = 'Are you sure that you want to delete "'+ this.contact.contactTypeName +'"?';
  }

  confirmContactDelete(contact): void {
    this.modalRef.hide();
    this.facFinderService.deleteContactDetail(contact.id).subscribe(()=>{  
      this.toastr.success('Successfully deleted.'); 
      this.getContact();   
      //this.router.navigate(['permission-groups']);
      // this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
    //this.delete();
  }

  public openAddressConfirmationDialog(template: TemplateRef<any>,address:XFacAddressAddModel) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.address = address;
    this.message = 'Are you sure that you want to delete "'+ this.address.addressTypeName +'"?';
  }

  confirmAddressDelete(address): void {
    this.modalRef.hide();
    this.facFinderService.deleteAddressDetail(address.id).subscribe(()=>{  
      this.toastr.success('Successfully deleted.'); 
      this.getAddress();   
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
  }

  public openDependentConfirmationDialog(template: TemplateRef<any>,dependent:XFacDependentAddModel) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.dependent = dependent;
    this.message = 'Are you sure that you want to delete "'+ this.dependent.firstName +' '+ this.dependent.surname +'"?';
  }

  confirmDependantDelete(dependent): void {
    this.modalRef.hide();
    this.facFinderService.deleteDependentDetail(dependent.id).subscribe(()=>{  
      this.toastr.success('Successfully deleted.'); 
      this.getDependent();   
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
  }

  public openEmploymentConfirmationDialog(template: TemplateRef<any>,employment:XFacEmploymentAddModel) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.employment = employment;
    this.message = 'Are you sure that you want to delete "'+ this.employment.employer +'"?';
  }

  confirmEmploymentDelete(employment): void {
    this.modalRef.hide();
    this.facFinderService.deleteEmploymentDetail(employment.id).subscribe(()=>{  
      this.toastr.success('Successfully deleted.'); 
      this.getEmploymen();   
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
  }

  public openAssetConfirmationDialog(template: TemplateRef<any>,asset:XFacAssetAddModel) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.asset = asset;
    this.message = 'Are you sure that you want to delete "'+ this.asset.assetTypeName +'"?';
  }

  confirmAssetDelete(asset): void {
    this.modalRef.hide();
    this.facFinderService.deleteAssetDetail(asset.id).subscribe(()=>{  
      this.toastr.success('Successfully deleted.'); 
      this.getAsset();   
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
  }

  public openLiabilityConfirmationDialog(template: TemplateRef<any>,liability:XFacLiabilityAddModel) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.liability = liability;
    this.message = 'Are you sure that you want to delete "'+ this.liability.ownerTypeName +'"?';
  }

  confirmLiabilityDelete(liability): void {
    this.modalRef.hide();
    this.facFinderService.deleteLiabilityDetail(liability.id).subscribe(()=>{  
      this.toastr.success('Successfully deleted.'); 
      this.getLiability();   
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
  }

  public openIncomeConfirmationDialog(template: TemplateRef<any>,income:XFacIncomeAddModel) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.income = income;
    this.message = 'Are you sure that you want to delete "'+ this.income.incomeSubTypeName +'"?';
  }

  confirmIncomeDelete(income): void {
    this.modalRef.hide();
    this.facFinderService.deleteIncomeDetail(income.id).subscribe(()=>{  
      this.toastr.success('Successfully deleted.'); 
      this.getIncome();   
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
  }

  public openExpenseConfirmationDialog(template: TemplateRef<any>,expense:XFacExpensesAddModel) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.expense = expense;
    this.message = 'Are you sure that you want to delete "'+ this.expense.expenseSubTypeName +'"?';
  }

  confirmExpenseDelete(expense): void {
    this.modalRef.hide();
    this.facFinderService.deleteExpenseDetail(expense.id).subscribe(()=>{  
      this.toastr.success('Successfully deleted.'); 
      this.getExpenses();   
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
  }

  public openGoalConfirmationDialog(template: TemplateRef<any>,goal:XFacGoalAddModel) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.goal = goal;
    this.message = 'Are you sure that you want to delete "'+ this.goal.ownerType +'"?';
  }

  confirmGoalDelete(goal): void {
    this.modalRef.hide();
    this.facFinderService.deleteGoalDetail(goal.id).subscribe(()=>{  
      this.toastr.success('Successfully deleted.'); 
      this.getGoals();   
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
  }

  public openInsuranceConfirmationDialog(template: TemplateRef<any>,insurance:XFacInsuranceLifeAddModel) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.insurance = insurance;
    this.message = 'Are you sure that you want to delete "'+ this.insurance.policyNumber +'"?';
  }

  confirmInsuranceDelete(insurance): void {
    this.modalRef.hide();
    this.facFinderService.deleteInsuranceDetail(insurance.id).subscribe(()=>{  
      this.toastr.success('Successfully deleted.'); 
      this.getInsurance();   
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
  }

  public openRetirementIncomeConfirmationDialog(template: TemplateRef<any>,retirementIncome:XFacRetirementIncomeAddModel) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.retirementIncome = retirementIncome;
    this.message = 'Are you sure that you want to delete "'+ this.retirementIncome.referenceNumber +'"?';
  }

  confirmRetirementIncomeDelete(retirementIncome): void {
    this.modalRef.hide();
    this.facFinderService.deleteRetirementIncomeDetail(retirementIncome.id).subscribe(()=>{ 
      this.toastr.success('Successfully deleted.'); 
      this.getRetirementIncome();   
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
  }

  openCreateNewVersion(){
    this.modalRef = this.modalService.show(AddFactFinderVersionComponent,  {
      initialState: {
        heading_title: 'Create New Version', 
        //business_id:this.business_id,      
        data: {   
          //goal_owner_list : this.goal_owner_list,
          facFinderId : this.facIdRecent,  
          clientCode : this.clientCode,   
          clientID : this.clientId,  
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });

    this.modalRef.content.event.subscribe(result => {    
      // console.log(result);
      if (result == 'OK') {
        //this.getGoals();
        // this.clientId = this.route.snapshot.queryParams['id']; 
        // this.name = this.route.snapshot.queryParams['name'];  
        // this.clientCode = this.route.snapshot.queryParams['clientCode'];
        this.getFactFinderNewList();
        this.router.navigate(['fact-finder-v2'], { queryParams: { id: this.clientId, name: this.name, clientCode: this.clientCode }});
      }
    });
  }
  public openMedicalInsuranceConfirmationDialog(template: TemplateRef<any>,medicalInsurance:XFacInsuranceMedicalAddModel) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.medicalInsurance = medicalInsurance;
    this.message = 'Are you sure that you want to delete "'+ this.medicalInsurance.planName +'"?';
  }

  confirmMedicalInsuranceDelete(medicalInsurance): void {
    this.modalRef.hide();
    this.facFinderService.deleteMedicalInsuranceDetail(medicalInsurance.id).subscribe(()=>{ 
      this.toastr.success('Successfully deleted.'); 
      this.getInsuranceMedical();   
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
  }

  openDownloadConfirmModal() {  
    //console.log(this.facId);
    this.modalRef = this.modalService.show(DownloadPdfComponent,  {
      initialState: {
        heading_title: 'Download PDF', 
        //business_id:this.business_id, 
             
        data: { 
          // getPersonalInfo : this.getPersonalInfo(),
          // getContact : this.getContact(),
          // getAddress :this.getAddress(),
          // getDependent : this.getDependent(),
          // getEmploymen : this.getEmploymen(),
          // getEstatePlanning : this.getEstatePlanning(),
          // getAsset : this.getAsset(),
          // getLiability : this.getLiability(),
          // getIncome : this.getIncome(),
          // getExpenses : this.getExpenses(),
          // getGoals : this.getGoals(),
          // getInsurance : this.getInsurance(),
          // getRetirementIncome : this.getRetirementIncome(),
          // getInsuranceMedical : this.getInsuranceMedical(),
          facFinderId : this.facId,  
          clientCode : this.clientCode,   
          versionNumber : this.versionNumber,  
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    // this.modalRef.content.event.subscribe(result => {    
    //   // console.log(result);
    //   if (result == 'OK') {
    //     this.getAsset();
    //     //this.router.navigate(['dashboard'], { queryParams: { business: this.business_id }});
    //   }
    // });
  }

  openStatusList(template: TemplateRef<any>){
    this.ngxService.start();
    this.facFinderService.getStatusChangeList(this.facId).subscribe((statusData: XFacStatusListResponse)=>{
        this.statusList = statusData.response;

        this.statusList = this.statusList.sort((a, b) => new Date(b.changeAt).getTime() - new Date(a.changeAt).getTime());
        
        // console.log("---this.statusList---");
        // console.log(this.statusList);

        let userList= [];
        
        if(statusData.success){
          if(this.statusList && this.statusList.length >0){
            this.statusList.forEach(function(status){
              if(status && status.changeById){
                userList.push(status.changeById);
              }           
            });
          }
          else{
            this.showStatusList(template);
          }
        }
        else{
          this.ngxService.stop();
          this.toastr.error('Error on getting status change list.');  
        }
        
        if(userList && userList.length >0){
          //this.ngxService.start();
          this.authService.getUserList(userList).subscribe((userListEx: XFacStatusUserList)=>{
            let userListExpanded = userListEx.response;

            // console.log("---userListExpanded===");
            // console.log(userListExpanded);

            if(userListEx.success){
              this.statusList.forEach(function(status){
                if(status && status.changeById){
                   let exist = userListExpanded.find(x => x.id == status.changeById);
                  //  console.log("---exist===");
                  //  console.log(exist);
                  if(exist){
                    status.createdBy = exist;
                  } 
                }           
              });
              this.showStatusList(template);
            }
            else{
              this.toastr.error('Error on getting status change list.');  
            }
          
            this.ngxService.stop();
            // console.log(this.statusList);
  
            
            
          },
          (err: HttpErrorResponse)=>{
            this.ngxService.stop();
            this.toastr.error('Error on getting status change list.');  
            //console.log("Error"+ err.message);
          });
        }
        else{
          this.ngxService.stop();
        }
        
      },
      (err: HttpErrorResponse)=>{
        this.ngxService.stop();
        this.toastr.error('Error on getting status change list.');
        //console.log("Error"+ err.message);
      });
  }

  showStatusList(template){
    //this.isLoaded = true;
    this.modalRef = this.modalService.show(template, { 
      class: '', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }

  

}
