<div class="modal-header a_modal">
    <h4 class="modal-title" id="myModalLabel2">Import Clients</h4>
    <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span
            aria-hidden="true">&times;</span> </button>
</div>


<div class="modal-body px-2">

    <form class="form mb-2">
        <p class="mt-2 mb-1 selected-client">{{memberCount}} Clients Selected</p>
        <div class="file-wr d-flex justify-content-center align-items-center flex-column">
            <ng-container *ngIf="memberCount==0">
                <img src="../../../../assets/img/import_icon.png">
                <p class="mt-1 mb-0">Browse your files here </p> <!--Drag and drop your files here or-->
                <label for="file" class="browse">Browse</label>
                <!-- <input type="file"
                       id="file"
                       (change)="handleFileInput($event.target.files)"> -->
                <input type="file" #csvReader name="Upload CSV" id="file" (change)="uploadListener($event)" 
                accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
            </ng-container>
            
            <ng-container *ngIf="!isLoading && memberCount>0">
                <table class="table justify-content-start scrollable-content">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Client Code</th>
                            <th scope="col" class="text-right">Xeppo Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let people of audienceData; let i = index">
                            <td>
                                <ng-container *ngIf="people.firstName">{{people.firstName}} {{people.lastName}}</ng-container>
                                <ng-container *ngIf="people.firstName==null && people.lastName==null">-</ng-container>
                            </td>
                            <td>
                                <ng-container *ngIf="people.code">{{people.code}}</ng-container>
                                <ng-container *ngIf="people.code==null">-</ng-container>
                            </td>
                            <td class="text-right">
                                <ng-container *ngIf="people.xeppoCode">{{people.xeppoCode}}</ng-container>
                                <ng-container *ngIf="people.xeppoCode==null">-</ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
            
        </div>

        <div *ngIf="isLoading">
            <!-- <div id="loading-bar-spinner" class="spinner"> -->
            <div class="spinner-icon"></div>
            <p class="mt-1 mb-0 text-center">Import in progress<br>
                Please wait</p>
            <!-- </div> -->
        </div>

    </form>




</div>

<div class="modal-footer a-footer">
    <!-- <button type="button" class="btn popup_delete_b">Delete</button> -->
    <button *ngIf="memberCount!=0" type="button" [disabled]="clicked" (click)="addClients(); clicked = true;" class="btn popup_save_b">Import Now</button>
</div>