import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { AppointmentBaseModel,AppointmentAddModel } from '../../shared/models/appointment.model';
import { Router } from '@angular/router';
import { AppointmentService } from '../appointment.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { AppointmentResponse, ClentResponsex, AgentResponse, ClentProfileResponse, ClentResponsexNew } from '../../shared/models/response.model';
import { Utility } from '../../utility/utility';
import { ClientsService } from 'src/app/clients/clients.service';
import { AgentsService } from 'src/app/agents/agents.service';
import { AgentViewModel } from 'src/app/shared/models/agent.model';
import { ClientViewModel } from 'src/app/shared/models/client.model';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-add-appointment',
  templateUrl: './add-appointment.component.html',
  styleUrls: ['./add-appointment.component.css']
})
export class AddAppointmentComponent implements OnInit {

  appointmentModel : AppointmentAddModel ; 
  title;
  data;
  errors;
//ethnicities : any;
appointmentAddForm: FormGroup;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  maxdate :any;
  //public utility: utility;
  Isvalid:boolean;
  agentList:AgentViewModel[];
  clientList:ClientViewModel[];
  client:ClientViewModel;

  clientName : string ='';
  appointmentD : Date = new Date;
  hr : string = "00"; 
  min : string = "00"; 
  amP : string = "AM"; 
  dur : string = "1"; 
  advisorName : string;  
  dateModel: Date = new Date();
  currentDate = new Date();
  sheduledDate2 :any;

  stringDateModel: string = new Date().toString();
  
  constructor(private ngxService: NgxUiLoaderService, private toastrService: ToastrService, private agentService: AgentsService, private clientsService: ClientsService,private formBuilder: FormBuilder,private router: Router, private appointmentService: AppointmentService,public modalRef: BsModalRef,private toastr: ToastrService) { }
 
  ngOnInit() {
    //this.ngxService.start();
    this.Isvalid = true;
    this.appointmentModel = new AppointmentAddModel();
    this.appointmentModel.sheduledDate = this.appointmentD;
    //console.log(this.modalRef.content);
    if(this.data.appointment !=null){
      Object.assign(this.appointmentModel, this.data.appointment);
      if(this.appointmentModel.clientId){
        this.getClient(this.appointmentModel.clientId);
      }
    }

    this.appointmentModel.appoinmentWith = null;
    //this.getClientList();
    this.getAgentList();
  }


onSubmit(form:NgForm){
 
let dateValid = this.validDate(this.appointmentModel.sheduledDate);
// console.log(dateValid);

if(form.invalid || this.appointmentModel.appoinmentWith == null || !dateValid ) {

  if(!dateValid){
    form.controls['sheduledDate'].setErrors({'incorrect': true});
  }

if(form.pristine){
}
  this.Isvalid = false;
}

else{
  // console.log(this.appointmentModel.sheduledDate, '===1===');
  // console.log(this.appointmentModel, '===2===');
  this.ngxService.start();
  this.Isvalid = true;
  //this.ngxService.start();
  //console.log("Add");
  //console.log(this.appointmentModel);

  this.appointmentService.addAppointment(this.appointmentModel)
  .subscribe((data:AppointmentResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){
      this.errors = Utility.GetLocalErrorString(data.error.errorCode);
      //console.log(this.errors);
    }
    else{
      this.toastr.success('Appointment added successfully.');
      this.event.emit('OK');
      this.modalRef.hide();
    }
    
    },err=>{   
      this.ngxService.stop();
      this.toastr.error('Error on saving appointment.');
    });
  
}
}

validDate(date){
  var now = new Date();
  var d = new Date(date); 
  //console.log(now.getTime());
  //console.log(d.getTime());

  if (now.getTime() > d.getTime()) {
      return false;
  }
  else{
    return true;
  }
}

getClientList(){
  this.clientsService.clientList().subscribe((data:ClentResponsexNew)=>{     
    this.clientList = data.response.data.filter(function(client){
      return client.client !== null;
    });

    //console.log('Client list for invoices ---------');
    //console.log(this.clientList);
    },
    (err: HttpErrorResponse)=>{
      console.log("Error"+ err.message);
    });      
}

onChange(newVal){
  //console.log(123456);
  this.sheduledDate2 = newVal;
}

getClient(clientId :string){
  this.clientsService.getClient(clientId).subscribe((clientData:ClentProfileResponse)=>{
 
    this.client = clientData.response;
    //console.log("client details ==========");
    //console.log(this.client.client);
    //this.gender = this.GetPatientGender.GetGender(this.patient.Gender);
    
    if(this.client !=null && this.client.client!=null){
      if (this.client.client.firstName != null ) {
        this.clientName = this.client.client.firstName+" ";
      }
      if (this.client.client.lastName != null ) {
        this.clientName = this.clientName+this.client.client.lastName;
      }
      // this.clientName = this.client.client.firstName+" "+this.client.client.lastName;
    }
    else{
      this.clientName = this.client.userName;
    }

    //console.log(this.clientName);
    //this.isLoaded = true;
    },
    (err: HttpErrorResponse)=>{
      console.log("Error"+ err.message);
      
    });
}

getAgentList(){
  this.agentService.agentList().subscribe((data:AgentResponse)=>{ 
    //this.ngxService.stop();    
    this.agentList = data.data.filter(function(client){
      return client.fortressAdmin !== null;
    });;
    //this.agentsCount = this.agentList.length;
    //this.assignCopy();
    //console.log('Agent list--------------------------');
    //console.log(this.agentList);

    //this.isLoaded = true;
    },
    (err: HttpErrorResponse)=>{
      console.log("Error"+ err.message);
    });      
}

modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

getUserName(clientId : string){
  this.client = this.clientList.find(x => x.client.id == clientId);
  //console.log(this.client);
  return this.client;
}

getClientName(event: Event){
  this.clientName = event.target['options'][event.target['options'].selectedIndex].text;
      //console.log(this.clientName);
}

getAppointmentDate(event){
  this.appointmentD = event.target.value;
      //console.log(this.appointmentD);
}

getHour(event: Event){
  this.hr = event.target['options'][event.target['options'].selectedIndex].text;
      //console.log(this.hr);
}

getMinute(event: Event){
  this.min = event.target['options'][event.target['options'].selectedIndex].text;
      //console.log(this.min);
}

getAmPm(event: Event){
  this.amP = event.target['options'][event.target['options'].selectedIndex].text;
      //console.log(this.amP);
}

getDuration(event: Event){
  this.dur = event.target['options'][event.target['options'].selectedIndex].text;
      //console.log(this.dur);  
}

getAppoinmentWith(event: Event){
  this.advisorName = event.target['options'][event.target['options'].selectedIndex].text;
  this.Isvalid = true;
      //console.log(this.advisorName);  
}

changeInput(){
  this.Isvalid = true;
}

// hour : string = "00"; 
//   minute : string = "00"; 
//   amPm : string = "Am"; 
//   duration : string = "1"; 
//   appoinmentWith : string; 

}
