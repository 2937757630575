<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="app-content content">
  <div class="content-wrapper">
  <div class="content-header row mb-1">
    <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
      <h3 class="content-header-title mb-0 d-inline-block">Email Setup</h3>
      <div class="row breadcrumbs-top d-inline-block">
        <div class="breadcrumb-wrapper col-12">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/dashboard']" href="#">Home</a>
            </li>
            <li class="breadcrumb-item active">Email Setup (Department List)
            </li>
          </ol>
        </div>
      </div>
    </div>
    </div>
      <div class="content-body">
          <!-- Zero configuration table -->
          <section id="">
              <div class="row">
                  <div class="col-12">
                      <div class="card">
                          <div class="card-header">
                              <h4 class="card-title">Available Departments for Email Setup</h4>
                          </div>
                          <div class="card-content collapse show">
                              <div *ngIf="isLoaded" class="card-body1 card-dashboard"  >
                                  <ng-container *ngIf="departmentList && departmentList.length >0 ">
                                      <table  datatable [dtOptions]="dtOptions" class="t_dt table table-striped table-bordered table-sm row-border hover" style="margin-top: 20px !important;">
                                          <thead>
                                              <tr>
                                                  <!-- <th scope="col" class="" width="2%">#</th> -->
                                                  <th>Name</th>
                                                  <th></th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              <tr *ngFor="let department of departmentList; let i = index">
                                                <td>{{ department.name }}</td>
                                                <td class="sa_btns">
                                                  <a routerLink="/email-setup" [queryParams]="{department_id:department.id, department_name:department.name}"  href="#"> <i class="la la-arrow-right la_icon"></i> </a>
                                                </td>
                                              </tr>
                                              <!-- <tr>
                                                <td>Business Accounting</td>
                                                <td class="sa_btns">
                                                  <a href="/email-setup"> <i class="la la-arrow-right la_icon"></i> </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Tax Accounting</td>
                                                <td class="sa_btns">
                                                  <a href="/email-setup"> <i class="la la-arrow-right la_icon"></i> </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Client Service</td>
                                                <td class="sa_btns">
                                                  <a href="/email-setup"> <i class="la la-arrow-right la_icon"></i> </a>
                                                </td>
                                              </tr> -->

                                          </tbody>

                                      </table>
                                  </ng-container>
                                  <ng-container *ngIf="departmentList == null || departmentList == ''">
                                      <img class="mx-auto d-block" src="assets/img/empty_state_img.png" alt="avatar">
                                      <div class="col-12">
                                          <p class="text-center" >Department list is empty</p>   
                                      </div>
                                  </ng-container>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <!--/ Zero configuration table -->
      </div>
  </div>
</div>


<app-footer></app-footer>