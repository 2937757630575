import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConstants } from '../utility/app-constants';
import { throwError } from 'rxjs';
import { WebAdminResponse } from '../shared/models/response.model';


@Injectable({
  providedIn: 'root'
})
export class ContainerService {

  baseUrl:String;

  constructor(private http:HttpClient) {
    this.baseUrl = AppConstants.getBaseURL();
   }

  public getWebAdminDashboard(companyCode:any){
    return this.http.get<WebAdminResponse>(this.baseUrl+'/Dashboard/DashboardNetWorth/WebAdmin?companyCode='+companyCode);    
  }

  public handleError(error) {
    return throwError(error);
  }

}
