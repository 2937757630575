import { Injectable } from '@angular/core';
import { Response,ClentResponsex,ClentProfileResponse,NewsFeedResponse, FileUploadResponse } from '../shared/models/response.model';

import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConstants } from '../utility/app-constants';
import { FileUploadAddModel } from '../shared/models/file-upload.model';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  baseUrl:String;
  
  constructor(private http:HttpClient, private cookieService: CookieService) {
    this.baseUrl = AppConstants.getBaseURL();
  }

  public handleError(error) {
    return throwError(error);
  }

  public uploadFile(fileUploadModel:FileUploadAddModel){    
    //console.log(fileUploadModel);
    var formData = new FormData();
    formData.append("file", fileUploadModel.file, fileUploadModel.file.name)
    // formData.append("fileCategory", "3");
    // formData.append("fileType", fileUploadModel.fileType);
    // formData.append("userId", JSON.parse(localStorage.getItem('CurrentUserId'))); 

    let query_str = "adminView="+fileUploadModel.adminView+"&fileCategory="+fileUploadModel.fileCategory+"&fileType="+fileUploadModel.fileType+"&userId="+  this.cookieService.get('CurrentUserId')+"&uploadType="+fileUploadModel.uploadType;//JSON.parse(localStorage.getItem('CurrentUserId'));

    return this.http.post<FileUploadResponse>(this.baseUrl +'/FileUpload/Upload?'+query_str, formData).pipe(
      catchError(this.handleError)
      );    
  }

  public uploadDocument(fileUploadModel:FileUploadAddModel){    
    var formData = new FormData();
    formData.append("file", fileUploadModel.file, fileUploadModel.file.name)

    const encodedDocumentName = encodeURIComponent(fileUploadModel.documentName);

    let query_str = "GroupCode="+fileUploadModel.groupCode+"&companyCode="+fileUploadModel.companyCode+"&userId="+fileUploadModel.userId+"&fileCategory="+fileUploadModel.fileCategory+"&isPortal="+ fileUploadModel.isPortal+"&isFDS="+ fileUploadModel.isFds+"&xeppoCode="+fileUploadModel.xeppoCode+"&DocumentName="+encodedDocumentName;//JSON.parse(localStorage.getItem('CurrentUserId'));

    return this.http.post<FileUploadResponse>(this.baseUrl +'/FileUpload/UploadDocument?'+query_str, formData).pipe(
      catchError(this.handleError)
      );    
  }

  public uploadFileFDS(fileUploadModel:FileUploadAddModel){    
    //console.log(fileUploadModel);

    //old code
    // var formData = new FormData();
    // formData.append("file", fileUploadModel.file, fileUploadModel.file.name)

    // let query_str = "fileCategory="+fileUploadModel.fileCategory+"&fileType="+fileUploadModel.fileType+"&userId="+ this.cookieService.get('CurrentUserId');//JSON.parse(localStorage.getItem('CurrentUserId'));

    // return this.http.post<FileUploadResponse>(this.baseUrl +'/FileUpload/Upload?'+query_str, formData).pipe(
    //   catchError(this.handleError)
    //   );    

      //new code
      var formData = new FormData();
      formData.append("file", fileUploadModel.file, fileUploadModel.file.name)
  
      let query_str = "GroupCode="+fileUploadModel.groupCode+"&companyCode="+fileUploadModel.companyCode+"&userId="+fileUploadModel.userId+"&fileCategory="+fileUploadModel.fileCategory+"&isPortal="+ fileUploadModel.isPortal+"&isFDS="+ fileUploadModel.isFds+"&xeppoCode="+fileUploadModel.xeppoCode+"&DocumentName="+fileUploadModel.documentName;//JSON.parse(localStorage.getItem('CurrentUserId'));
  
      return this.http.post<FileUploadResponse>(this.baseUrl +'/FileUpload/UploadDocument?'+query_str, formData).pipe(
        catchError(this.handleError)
        ); 
  }


}
