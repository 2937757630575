import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FacFinderService } from '../fac-finder.service';
import { XFacLiabilityResponse } from 'src/app/shared/models/response.model';
import { XFacLiabilityAddModel } from 'src/app/shared/models/xfac-liability.model';

@Component({
  selector: 'app-add-liabilities',
  templateUrl: './add-liabilities.component.html',
  styleUrls: ['./add-liabilities.component.css']
})
export class AddLiabilitiesComponent implements OnInit {

  data;
  errors;
  addLiabilityForm: FormGroup;
  liabilityAddModel : XFacLiabilityAddModel;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  buttonText: string ="Add New Liability";
  liabilityOwnerList : any = [];
  liabilityTypeList : any = [];
  liabilitySubTypeList : any = [];
  liabilityRepaymentFrequencyList : any = [];
  liabilityInterestRateTypeList : any = [];
  liabilityLenderSupplierList : any = [];
  facFinderId : any;
  clientCode : any;
  selectedChoice: any = null;
  title: any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private facFinderService: FacFinderService,
    public modalRef: BsModalRef,
    private toastr: ToastrService
  ) { }
  
  ngOnInit() {
    this.Isvalid = true;    
    this.liabilityAddModel = new XFacLiabilityAddModel(); 
    this.liabilityAddModel.owner ="000"; 
    this.liabilityAddModel.type ="000"; 
    //this.liabilityAddModel.subType ="000"; 
    this.liabilityAddModel.interestRateType ="000"; 
    this.liabilityAddModel.lenderSupplier ="000"; 
    this.liabilityAddModel.repaymentFrequency =999; 

    if(this.data.liability !=null){
      //console.log(this.data.library);
      this.buttonText = "Update Now";
      Object.assign(this.liabilityAddModel, this.data.liability);
    }
   
    if(this.data.liability_owner_list !=null){
      Object.assign(this.liabilityOwnerList, this.data.liability_owner_list);
    }

    if(this.data.liability_type_list !=null){
      Object.assign(this.liabilityTypeList, this.data.liability_type_list);
    }

    if(this.data.liability_sub_type_list !=null){
      Object.assign(this.liabilitySubTypeList, this.data.liability_sub_type_list);

      // let choice = this.liabilitySubTypeList.filter(choice => choice.key == this.liabilityAddModel.subType);
      // if(choice && choice[0]) {
      //   this.liabilityAddModel.subType = choice[0].key;
      //   this.selectedChoice = choice[0].key;
      // }
      // else{
      //   this.liabilityAddModel.subType = "000";
      // }
      // console.log(this.selectedChoice);
      // console.log(choice);
      // console.log(this.liabilityAddModel);
    }
    else{
      this.liabilityAddModel.subType = "000";
    }

    if(this.data.liability_repayment_frequency_list !=null){
      Object.assign(this.liabilityRepaymentFrequencyList, this.data.liability_repayment_frequency_list);
    }

    if(this.data.liability_interest_rate_type_list !=null){
      Object.assign(this.liabilityInterestRateTypeList, this.data.liability_interest_rate_type_list);
    }

    if(this.data.liability_lender_supplier_list !=null){
      Object.assign(this.liabilityLenderSupplierList, this.data.liability_lender_supplier_list);
    }

    if(this.data.facFinderId !=null){
      this.facFinderId = this.data.facFinderId;
    }

    if(this.data.clientCode !=null){
      this.clientCode = this.data.clientCode;
    }

    this.title = this.data.title;

    // if(this.data.business_id !=null){
    //   this.business_id = this.data.business_id;
    // }

    this.addLiabilityForm = this.formBuilder.group({
      owner: ['', Validators.required],
      type: ['', Validators.required],
      subType: ['', Validators.required],
      repaymentAmountValue: ['', Validators.required],
      repaymentFrequency: ['', Validators.required],
      productName: ['', Validators.required],
      interestRate: ['', Validators.required],
      interestRateType: ['', Validators.required],
      lenderSupplier: ['', Validators.required],
    });
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

  get f() { return this.addLiabilityForm.controls; }

  onSubmit(){
    this.submitted = true;
   
    let owner = this.liabilityAddModel.owner;
    let type = this.liabilityAddModel.type;
    let subType = this.liabilityAddModel.subType;
    let interestRateType = this.liabilityAddModel.interestRateType;
    let lenderSupplier = this.liabilityAddModel.lenderSupplier;
    let repaymentFrequency = this.liabilityAddModel.repaymentFrequency;
   
    if (this.addLiabilityForm.invalid || (type && type.trim()=="000") || (subType && subType.trim()=="000") || (interestRateType && interestRateType.trim()=="000") || (lenderSupplier && lenderSupplier.trim()=="000") || (repaymentFrequency && repaymentFrequency==999) ) { //|| (title && title.trim()=="")
   
      if(owner && owner.trim()=="000"){
        this.addLiabilityForm.controls['owner'].setErrors({'required': true});
      }
      
      if(type && type.trim()=="000"){
        this.addLiabilityForm.controls['type'].setErrors({'required': true});
      }

      if(subType && subType.trim()=="000"){
        this.addLiabilityForm.controls['subType'].setErrors({'required': true});
      }

      if(interestRateType && interestRateType.trim()=="000"){
        this.addLiabilityForm.controls['interestRateType'].setErrors({'required': true});
      }

      if(lenderSupplier && lenderSupplier.trim()=="000"){
        this.addLiabilityForm.controls['lenderSupplier'].setErrors({'required': true});
      }
      if(repaymentFrequency && repaymentFrequency==999){
        this.addLiabilityForm.controls['repaymentFrequency'].setErrors({'required': true});
      }
      
      
      console.log(this.f);
      console.log(this.addLiabilityForm.invalid);
      return;
    }
   
    else{
      //this.folderModel.name = title.trim();
      this.liabilityAddModel.repaymentAmountCode = 'AUD';
      
      if(this.data.liability !=null){
      //   this.ngxService.start();
      // console.log("Edditre");
      // console.log(this.assetAddModel);
      
        this.ngxService.start();
        this.liabilityAddModel.clientCode = this.clientCode;
        this.facFinderService.editLiability(this.liabilityAddModel, this.facFinderId)
        .subscribe((data:XFacLiabilityResponse)=>{
        this.ngxService.stop();
        if(data.error && !data.success){      
          this.toastr.error('Error on updating liability.');      
        }
        else{
          this.toastr.success('Liability updated successfully.');
          this.event.emit('OK');
          // console.log(this.event);
          this.modalRef.hide();
        }
        
        },err=>{   
          console.log(err);
          this.ngxService.stop();
          this.toastr.error('Error on updating liability.');
        });
          
          //this.router.navigate(['agents']);
          
      
      }else{
        
        this.ngxService.start();
        this.liabilityAddModel.clientCode = this.clientCode;
        this.facFinderService.addLiability(this.liabilityAddModel, this.facFinderId)
        .subscribe((data:XFacLiabilityResponse)=>{
        this.ngxService.stop();
        if(data.error && !data.success){      
          this.toastr.error('Error on saving liability.');      
        }
        else{
          this.toastr.success('Liability added successfully.');
          this.event.emit('OK');
          // console.log(this.event);
          this.modalRef.hide();
        }
        
        },err=>{   
          console.log(err);
          this.ngxService.stop();
          this.toastr.error('Error on saving liability.');
        });
    
      }
    
    }
   
  }

  onChange(value){
    let subtypeList = [];
    // console.log(value);
    this.data.liability_sub_type_list.forEach(device => {
      if(device.key.indexOf(value)==0){
        subtypeList.push(device);
      }
    })

    this.liabilitySubTypeList = subtypeList;//this.data.asset_sub_type.filter(device => device.key === value);
    //this.liabilityAddModel.subType ="000"; 

    let choice = this.liabilitySubTypeList.filter(choice => choice.key == this.liabilityAddModel.subType);
    if(choice && choice[0]) {
      this.liabilityAddModel.subType = choice[0].key;
      this.selectedChoice = choice[0].key;
    }
    else{
      this.liabilityAddModel.subType = "000";
    }

    // console.log(this.liabilitySubTypeList);
  }

}
