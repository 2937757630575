import { connect, ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject , Observable } from 'rxjs';
import { AppConstants } from '../utility/app-constants';
// import { map } from 'rxjs/internal/operators/map';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

interface AuthToken {
    token: string;
}

export interface NamedRoom {
    id: string;
    name: string;
    maxParticipants?: number;
    participantCount: number;
}

export type Rooms = NamedRoom[];

@Injectable({
    providedIn: 'root'
})
export class VideoChatService {
    baseUrl: String;
    $roomsUpdated: Observable<boolean>;

    private roomBroadcast = new ReplaySubject<boolean>();

    constructor(private readonly http: HttpClient, private cookieService: CookieService) {
        this.baseUrl = AppConstants.getBaseURL();
        this.$roomsUpdated = this.roomBroadcast.asObservable();
    }

    
    getAuthToken( roomName: String) {

         
        var userId = this.cookieService.get('CurrentUserId'); //localStorage.getItem('CurrentUserId');
        // console.log(userId);
        return this.http.post<any>(this.baseUrl + '/VideoCall/CreatRoom', { userId, roomName }).pipe(map(data => {
      
            if (data.success) {
              if(data.response.uniqueName)              
              localStorage.setItem('token', JSON.stringify(data.response.sid));
              localStorage.setItem('room_name', JSON.stringify(data.response.uniqueName)); 
            //   console.log(data);  
              return data.response.sid;   

            } else{
              return data;
            }
          return data;
          }));

        // const auth =
        //     await this.http
        //               .get<AuthToken>(`api/video/token`)
        //               .toPromise();

        // return auth.token;
    }

    // private async getAuthToken() {
    //     const auth =
    //         await this.http
    //                   .get<AuthToken>(`api/video/token`)
    //                   .toPromise();

    //     return auth.token;
    // }

    getAllRooms() {
        // return this.http
        //            .get<Rooms>(this.baseUrl + '/VideoCall/Rooms')
        //            .toPromise();
        return this.http.get<any>(this.baseUrl + '/VideoCall/Rooms').pipe(map(data => {

        if (data.success) {
            if(data.response)            
            return data.response;   
        } else{
            return data;
        }
        return data;
        }));           
    }

    async joinOrCreateRoom(roomName: string, tracks: LocalTrack[]) {
        let room: Room = null;
        try {
            const token = await this.getAuthToken(roomName);
            room =
                await connect(
                    token, {
                        roomName,
                        tracks,
                        dominantSpeaker: true
                    } as ConnectOptions);
        } catch (error) {
            console.error(`Unable to connect to Room: ${error.message}`);
        } finally {
            if (room) {
                this.roomBroadcast.next(true);
            }
        }

        return room;
    }

    nudge() {
        this.roomBroadcast.next(true);
    }
}