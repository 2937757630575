import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FacFinderService } from '../fac-finder.service';
import { XFacContactAddModel } from 'src/app/shared/models/xfac-contact.model';
import { XFacContactResponse, XFacAddressResponse } from 'src/app/shared/models/response.model';
import { XFacAddressAddModel } from 'src/app/shared/models/xfac-address.model';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.css']
})
export class AddAddressComponent implements OnInit {

  title;
  data;
  errors;
  addAddressForm: FormGroup;
  addressAddModel : XFacAddressAddModel;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  buttonText: string ="Add New Contact Details";
  addressTypeList : any = [];
  facFinderId : any;
  clientCode : any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private facFinderService: FacFinderService,
    public modalRef: BsModalRef,
    private toastr: ToastrService
    ) { }
 
  ngOnInit() {
    this.Isvalid = true;    
    this.addressAddModel = new XFacAddressAddModel(); 
    this.addressAddModel.addressType ="000"; 

    if(this.data.address !=null){
      //console.log(this.data.library);
      this.buttonText = "Update Now";
      Object.assign(this.addressAddModel, this.data.address);
    }

    if(this.data.address_type_list !=null){
      Object.assign(this.addressTypeList, this.data.address_type_list);
    }

    if(this.data.facFinderId !=null){
      this.facFinderId = this.data.facFinderId;
    }

    if(this.data.clientCode !=null){
      this.clientCode = this.data.clientCode;
    }

    this.title = this.data.title;

    // console.log(this.clientCode);
    // if(this.data.business_id !=null){
    //   this.business_id = this.data.business_id;
    // }

    this.addAddressForm = this.formBuilder.group({
      addressType: ['', Validators.required],
      street: ['', Validators.required],
      suburb: ['', Validators.required],
      postcode: ['', Validators.required],
      state: ['', Validators.required],
    });

  }

  get f() { return this.addAddressForm.controls; }

onSubmit(){
 this.submitted = true;

 let addressType = this.addressAddModel.addressType;

 if (this.addAddressForm.invalid || (addressType && addressType.trim()=="000") ) { //|| (title && title.trim()=="")

  if(addressType && addressType.trim()=="000"){
    this.addAddressForm.controls['addressType'].setErrors({'required': true});
  }
  // console.log(this.f);
  // console.log(this.addAddressForm.invalid);
  return;
}

else{
  //this.folderModel.name = title.trim();

if(this.data.address !=null){
//   this.ngxService.start();
// //console.log("Edditre");

  this.ngxService.start();
  this.addressAddModel.clientCode = this.clientCode;
  this.facFinderService.editAddress(this.addressAddModel, this.facFinderId)
  .subscribe((data:XFacAddressResponse)=>{
  this.ngxService.stop();
  if(data.error && !data.success){      
    this.toastr.error('Error on updating address.');      
  }
  else{
    this.toastr.success('Address updated successfully.');
    this.event.emit('OK');
   // console.log(this.event);
    this.modalRef.hide();
  }
  
  },err=>{   
    console.log(err);
    this.ngxService.stop();
    this.toastr.error('Error on updating address.');
  });
    
    //this.router.navigate(['agents']);
    

}else{
  
  this.ngxService.start();
  this.addressAddModel.clientCode = this.clientCode;
  this.facFinderService.addAddress(this.addressAddModel, this.facFinderId)
  .subscribe((data:XFacAddressResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){      
      this.toastr.error('Error on saving address.');      
    }
    else{
      this.toastr.success('Address added successfully.');
      this.event.emit('OK');
     // console.log(this.event);
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on saving address.');
    });

  }

}

}

modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

onReset() {
  this.submitted = false;
  this.addAddressForm.reset();
}

}
