import { AppointmentResponse } from '../shared/models/response.model';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// import { Router } from '@angular/router';
import { AppConstants } from '../utility/app-constants';
import { JwtHelperService } from '@auth0/angular-jwt';
// import { element, defineDirective } from '@angular/core/src/render3';
import { strictEqual } from 'assert';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { User, VerifiedUser } from '../shared/models/user.model';
// import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';
import { AppointmentAddModel,AppointmentBaseModel } from '../shared/models/appointment.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  baseUrl:String;
  
  constructor(private http:HttpClient, private cookieService: CookieService) {
    this.baseUrl = AppConstants.getBaseURL();
  }   

  public appointmentList(){       
    return this.http.get<AppointmentResponse>(this.baseUrl+'/Appoinments');    
  }

  public getPendingAppointments(){
    return this.http.get<AppointmentResponse>(this.baseUrl+'/Appoinment/getPending');    
  }

  public handleError(error) {
    return throwError(error);
  }
    
  public addAppointment(appointmentModel:AppointmentAddModel){
    // console.log(AppointmentAddModel);

    //appointmentModel.sheduledDate = new Date(appointmentModel.sheduledDate+' '+appointmentModel.hour+':'+appointmentModel.minute+':'+appointmentModel.amPm);   //sheduledDate  hour  minute amPm
    //appointmentModel.createdBy = 
    appointmentModel.createdBy = this.cookieService.get('CurrentUserId'); //JSON.parse( localStorage.getItem('CurrentUserId'));
    appointmentModel.appoinmentStatus = "1";
    // console.log(appointmentModel);
    return this.http.post<AppointmentResponse>(this.baseUrl +'/Appoinment',appointmentModel).pipe(
     catchError(this.handleError)
     );    
  }

  public editAppointment(id:string,appointment:AppointmentAddModel) {  
    const params = new HttpParams().set('id', id); 
    //appointment.sheduledDate = new Date(appointment.sheduledDate+' '+appointment.hour+':'+appointment.minute+':'+appointment.amPm);   //sheduledDate  hour  minute amPm
    return this.http.put<AppointmentResponse>(this.baseUrl+'/Appoinment',appointment,{params}).pipe(
      catchError(this.handleError)
      );
  }

  public appointmentListCurrentUser(id:string) {  
    const params = new HttpParams().set('clientID', id); 
    //appointment.sheduledDate = new Date(appointment.sheduledDate+' '+appointment.hour+':'+appointment.minute+':'+appointment.amPm);   //sheduledDate  hour  minute amPm
    return this.http.get<AppointmentResponse>(this.baseUrl+'/Appoinment/getByClient',{params}).pipe(
      catchError(this.handleError)
      );
  }


}
