import { Component, OnInit, EventEmitter } from '@angular/core';
import { Utility } from 'src/app/utility/utility';
import { FileUploadResponse } from 'src/app/shared/models/response.model';
import { DocType } from 'src/app/shared/models/db-enums.model';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { FileUploadAddModel } from 'src/app/shared/models/file-upload.model';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FileUploadService } from 'src/app/file-upload/file-upload.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ChatFileModel } from 'src/app/shared/models/messagecenter.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-send-file',
  templateUrl: './send-file.component.html',
  styleUrls: ['./send-file.component.css']
})
export class SendFileComponent implements OnInit {

  fileUploadAddModel : FileUploadAddModel;
  chatFileModel : ChatFileModel;
  //clinics : ClinicViewModel[];
  //patientClinic:ClinicViewModel; 
  title;
  data;
  errors;
  documentLabel = "Choose file";

  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  maxdate :any;
  //public utility: utility;
  Isvalid:boolean;
  docTypes = DocType;
  documentType1 :any;
  uploadDocumentEnable:boolean = true;
  acceptDocType : string;
  disableDocumentType:boolean = false;

  constructor(private ngxService: NgxUiLoaderService, private http:HttpClient, private formBuilder: FormBuilder,private router: Router, private fileUploadService: FileUploadService, public modalRef: BsModalRef,private toastr: ToastrService, private cookieService: CookieService) { }
 
  ngOnInit() {
    this.documentType1 = [{"id":"0","value":"Please select"},{"id":"1","value":"Jpg"},{"id":"2","value":"Png"},{"id":"3","value":"Pdf"},{"id":"4","value":"Doc"}]; 
    //this.documentType1 = [{"id":"1","value":"Jpg"},{"id":"2","value":"Png"},{"id":"3","value":"Pdf"},{"id":"4","value":"Doc"}]; 
    this.Isvalid = true;
    this.chatFileModel = new ChatFileModel();
    this.fileUploadAddModel = new FileUploadAddModel();
    //console.log(" ==send chat file component=== ");

    // if(this.data.newsFeed !=null){
    //   Object.assign(this.newsFeedModel, this.data.newsFeed);
    // }
    this.chatFileModel.documentType = "0";
    
  }

  onSubmit(form:NgForm){
    // console.log(" ==send chat file form=== ");
    // console.log(form.invalid);

  if(form.invalid || this.chatFileModel.documentType == "0") {
    if(form.pristine){
    }
    this.Isvalid = false;
    //console.log(this.Isvalid);
  }
  
  else{ 
    //console.log("Add");  
    this.ngxService.start();
    this.Isvalid = true;

    this.fileUploadAddModel.fileCategory = "2";
    this.fileUploadAddModel.fileType = this.chatFileModel.documentType;
    this.fileUploadAddModel.userId = this.cookieService.get('CurrentUserId');//JSON.parse(localStorage.getItem('CurrentUserId'));

    this.fileUploadService.uploadFile(this.fileUploadAddModel).subscribe((data:FileUploadResponse)=>{
      this.ngxService.stop();   
      if(data.error && !data.success){
        this.toastr.error('Error on sending attachment.');        
        //this.errors = Utility.GetLocalErrorString(data.error.errorCode);
      }
      else{
        this.toastr.success('Successfully sent attachment.');  
        let resp =  {success:true, documentType:this.chatFileModel.documentType, s3Key:data.response.s3Key} ;  
        this.event.emit(resp);
        this.modalRef.hide();
      }      
      },err=>{   
        this.ngxService.stop(); 
        this.toastr.error('Error on sending attachment.');
      });

  }
  }
  
  onFileChange(event){
    if(event.target.files[0]){
      this.fileUploadAddModel.file = <File>event.target.files[0];
      this.documentLabel = event.target.files[0].name;
      this.disableDocumentType = true;
    } 
    else{
      this.documentLabel = "Choose file";
      this.disableDocumentType = false;
    } 
  }

  onDocumentTypeChange(docType){
    this.uploadDocumentEnable = false;
    if(docType==1){
      this.acceptDocType = ".jpg,.jpeg";
    }else if(docType==2){
      this.acceptDocType = ".png";
    }else if(docType==3){
      this.acceptDocType = ".pdf";
    }else if(docType==4){
      this.acceptDocType = ".doc,.docx"; 
    }
    else{
      this.uploadDocumentEnable = true;
    }
  } 
  
  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }
  
  ClosePopup(){
    this.modalRef.hide();
  }

}
