import { Component, OnInit, Input } from '@angular/core';
import { ClientsService } from '../clients/clients.service';
import { InvoicesService } from '../invoices/invoices.service';
import { ClientListModel,ClientViewModel } from '../shared/models/client.model';
import { InvoiceAddModel,InvoiceBaseMOdel } from '../shared/models/invoice.model';
import { ClentListResponse, ClentResponsex, InvoiceResponse, ClentProfileResponse, ClentResponsexNew } from '../shared/models/response.model';
import { Response } from 'selenium-webdriver/http';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddInvoiceComponent } from './add-invoice/add-invoice.component';
import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { ActivatedRoute } from '@angular/router';
import { ViewInvoiceComponent } from './view-invoice/view-invoice.component';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {

  constructor(private route: ActivatedRoute, private clientsService: ClientsService ,private invoicesService: InvoicesService,private modalService: BsModalService ) { }
  clientList:ClientViewModel[];
  invoiceList:InvoiceAddModel[];
  modalRef: BsModalRef;
  isLoaded :boolean;
  client : ClientViewModel; 
  clientId : string;
  clientName : string = null;
  clientIdfromProfile : string = null;
  dtOptions: DataTables.Settings = {}; 
  

  @Input('clientId') 
  clientIdfrom: string;
  
  ngOnInit() {
    //this.getClientList();
    this.clientId = this.route.snapshot.queryParams['id']; 
    if(this.clientIdfrom != null){
      this.clientId = this.clientIdfrom;
      this.clientIdfromProfile = this.clientIdfrom;
    }   

    if(this.clientId==null){
      // this.getInvoiceList(); // comment by thilina, request by DevT (uncomment, DevT remove the service)
    }
    else{
      // this.getClientInvoiceList(this.clientId); // comment by thilina, request by DevT (uncomment, DevT remove the service)
    }

    this.dtOptions = {
      //pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      order:[]
    };
    //console.log(this.clientIdfrom);
    //console.log(this.clientIdfromProfile);
  }

 

  getInvoiceList(){
    this.invoicesService.invoiceList().subscribe((data:InvoiceResponse)=>{     
      this.invoiceList = data.response;      
      //console.log('Invoice list for ---------');
      //console.log(this.invoiceList);
      this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });      
  }

  getClientInvoiceList(clientId){
    this.invoicesService.clientInvoiceList(clientId).subscribe((data:InvoiceResponse)=>{     
      this.invoiceList = data.response;    
      this.getClient(clientId); 

      // if(client != null){
      //   if(client.client != null){
      //     this.clientName = client.client.firstName+" "+client.client.lastName;
      //   }
      //   else{
      //     this.clientName = client.userName;
      //   }
      // }

      // console.log(client); 
      //console.log('Invoice list for client ---------');
      //console.log(this.invoiceList);
      this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
  }

  openModal() {
    let invoice = new InvoiceAddModel();
    if(this.clientId!=null){
      invoice.userId = this.clientId;
    }

    this.modalRef = this.modalService.show(AddInvoiceComponent,  {
      initialState: {
        title: 'Add Invoice',
        data: {
          invoice:invoice
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      
      if (result == 'OK') {
        //console.log("Success");
        if(this.clientId==null){
          this.getInvoiceList();
        }
        else{
          this.getClientInvoiceList(this.clientId);
        }
      
      }

    });
  }

  openEditModal(invoice:any) {  
    //console.log(invoice);  
    this.modalRef = this.modalService.show(EditInvoiceComponent,  {
      initialState: {
        title: 'Edit Invoice',
        data: {
          invoice:invoice
        }
      },      
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
          this.getInvoiceList();
      
      }
    });
  }

  openViewModal(invoice:any) {  
    //console.log(invoice);  
    this.modalRef = this.modalService.show(ViewInvoiceComponent,  {
      initialState: {
        title: 'View Invoice',
        data: {
          invoice:invoice
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
          this.getInvoiceList();
      
      }
    });
  }

  getClientList(){
    this.clientsService.clientList().subscribe((data:ClentResponsexNew)=>{     
      this.clientList = data.response.data;      
      //console.log('Client list for invoices ---------');
      //console.log(this.clientList);
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });      
  }
  
  getUserName(user_id){
     this.client = this.clientList.find(x => x.id === user_id);
     //console.log(this.client);
     return this.client;
  }

  getClient(clientId :string){
    this.clientsService.getClient(clientId).subscribe((clientData:ClentProfileResponse)=>{
   
      this.client = clientData.response;
      //console.log("client details ==========");
      //console.log(this.client.client);
      //this.gender = this.GetPatientGender.GetGender(this.patient.Gender);
      
      if(this.client !=null && this.client.client!=null){
        this.clientName = this.client.client.firstName+" "+this.client.client.lastName;
      }
      else{
        this.clientName = this.client.userName;
      }

      //return this.clientName;
  
      //console.log(this.clientName);
      //this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
        
      });
  }
  
//   filterItem(value){    
//     this.patientFilterdList = Object.assign([], this.patientlist).filter(
//        item => (item.firstName+item.lastName).toLowerCase().indexOf(value.toLowerCase()) > -1  
//     )
//  }

}
