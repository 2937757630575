import { Injectable } from '@angular/core';
import { Response,ClentResponsex,ClentProfileResponse,NewsFeedResponse, TransactionResponse, DocumentUrlResponse } from '../shared/models/response.model';

import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConstants } from '../utility/app-constants'; 
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
    providedIn: 'root'
  })
  export class NetworthRolbackService {
    baseUrl:String;
    constructor(private http:HttpClient) {
        this.baseUrl = AppConstants.getBaseURL();
      }
    
      public handleError(error) {
        return throwError(error);
      }

      public rollbackNetworth(){    
        return this.http.post<any>(this.baseUrl +'/HistoryPreviousDay/NetWorth',{}).pipe(
         
          catchError(this.handleError)
          );    
      }

      public rollbackInsurance(){    
        return this.http.post<any>(this.baseUrl +'/HistoryPreviousDay/Insurance',{}).pipe(
         
          catchError(this.handleError)
          );    
      }
  }