import { Component, OnInit, TemplateRef } from '@angular/core';
import { ClientAddModel, ClientViewModel,ClientBaseModel } from '../shared/models/client.model';
import { ClentResponsex, ClentResponsexNew } from '../shared/models/response.model';
import { ClientsService } from './clients.service';
import { HttpErrorResponse } from '@angular/common/http';
// import { ClientResponse } from 'http';
import { AuthService } from '../auth/auth.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddClientComponent } from '../clients/add-client/add-client.component';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  clientList:ClientViewModel[];
  clientsCount:number;
  clientFilterdList:ClientViewModel[];
  dtOptions2: DataTables.Settings = {}; 
  isLoaded :boolean;
  clientListView =[];
  showStaffMembers: boolean;
  modalRef: BsModalRef;
  clientId:string;
  message:string;

  totalRecords: number;
  currentPage: any;
  skip : number = 0;
  take : number = 10000;
  itemsPerPage: number = 10;
  showBoundaryLinks: boolean = true;
  showDirectionLinks: boolean = true;
  p: number = 1;


  constructor(
    private authService: AuthService,
    private clientsService: ClientsService,
    private modalService: BsModalService,
    private router: Router,
    private toastr: ToastrService,
    private cookieService: CookieService
    ) { 
      this.showStaffMembers = this.viewPortalPermission("View_Staff_Clients"); 
    }

  ngOnInit() {
    const a = sessionStorage.getItem('currentPage');
    //this.isLoaded = false;
    if (a) {
      this.getClientList(a); 
    } else {
      this.getClientList(); 
    }
    this.dtOptions2 = {
      paging: false,
      info: false,
      processing: false,
      order : [],
      // responsive:true,
    };    
  }

  openModal() {
    
    this.modalRef = this.modalService.show(AddClientComponent,  {
      initialState: {
        title: 'Add Agent',
        data: {},
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      
      if (result == 'OK') {
        //console.log("Success");
        this.router.navigate(['clients']);
        this.getClientList(); 
      
      }

    });
  }

  getClientList(event?){
    this.currentPage = event || 1;
    this.skip = (event-1) * 10 || 0;
    this.take = event?.itemsPerPage || this.itemsPerPage; // Default records count
    this.p = event
    sessionStorage.setItem('currentPage', this.currentPage);

    this.clientsService.clientsList(this.skip,this.take).subscribe((data:ClentResponsexNew)=>{     
        if(data.success && data.response.data) {
          this.clientList = data.response.data;
          this.clientsCount = this.clientList.length;
          this.totalRecords = ((data || {}).response || {}).totalRecoreds

          // console.log(this.clientList.length, '======== clientList.length =======');
          // console.log(this.clientList, '======== clientList =======');
          //console.log(this.showStaffMembers);
          
          // if(!this.showStaffMembers){
          //   this.clientList =this.clientList.filter(function(client){
          //     if(client.client && client.client.IsVisibleToStaff){
          //       return client.client.IsVisibleToStaff !== true;
          //     }
              
          //   });
          // }
          
          this.assignCopy();
          //console.log('Client list--------------------------');
          //console.log(this.clientList);

          // this.clientList.forEach(client => {
          //   if(client.client){
          //     client.client.user_id = client.id;
          //     this.clientListView.push(client.client);
          //   }
          //   console.log(client.client);
          // });
        }
        else{
          this.clientList = [];
        }
        this.isLoaded = true;
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });      
  }

  assignCopy(){
    this.clientFilterdList = Object.assign([], this.clientList);
  }

  viewPortalPermission(permission){
    return this.authService.viewPortalPermission(permission);
  }

  public openDeleteDialog(template: TemplateRef<any>,id) {

    this.clientId = id;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    //this.idToBeDeleted = id;

    this.message = 'Are you sure that you want to delete this client?'
  }

  decline(): void {
    this.modalRef.hide();
  }

  confirm(): void {
    //this.message = 'Confirmed!';
    this.modalRef.hide();
    
    this.clientsService.deleteClient(this.clientId).subscribe(()=>{     
      
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['clients']);
      this.toastr.success('Client deleted successfully.');
      // this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
    
    //this.delete();
  }

}
