export class XFacGoalBaseMOdel {
    public  facFinderId : string;
    public  ownerType : string;
    public  ownerTypeDescription : string;
    public  category : number;
    public  categoryDescription : string;
    public  description : string;
    public  targetDate : Date;
    public  targetAmountValue : number;
    public  targetAmountCode : string;
    public  note : string;
    public  clientCode : string;
    public  comments : string;
}

export class XFacGoalAddModel extends XFacGoalBaseMOdel{    
       
}