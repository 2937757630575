<app-header></app-header>
<app-sidebar></app-sidebar>


<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                <h3 class="content-header-title mb-0 d-inline-block text-uppercase">maintenace mode</h3>
                <div class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12 resbread" >
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/dashboard">Home</a> </li>
                            <li class="breadcrumb-item active">Maintenace Mode</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

      <div class="d-flex flex-wrap">
          <div class="card d-flex justify-content-center py-2 px-3 rounded mr-2">
            <p class="mb-0 text-center">Maintenance mode is</p>
            <p class="mb-1 text-center">DISABLED</p>
            <img src="../assets/img/networth.svg" class="rollback-img"/>
            <button class="text-uppercase rollback-btn rounded px-2 mt-2 cursor-pointer" type="button">ENABLE NOW</button> <!-- (click)="rollbackData()" -->
            
          </div> 

          <div class="card d-flex justify-content-center py-2 px-3 rounded mr-2">
            <p class="mb-0 text-center">Maintenance mode is</p>
            <p class="mb-1 text-center">ENABLED</p>
            <img src="../assets/img/networth.svg" class="rollback-img"/>
            <button class="text-uppercase rollback-btn rounded px-2 mt-2 cursor-pointer"  type="button">DISABLE NOW</button>
            
          </div> 
      </div>

        
    </div>
</div>




<app-footer></app-footer>