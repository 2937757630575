
<div class="modal-body"  tabindex="-1">
        <div class="d-flex justify-content-between align-items-center" style="padding-bottom: 20px;">
            <h4 class="float-left title">Available PDF to download</h4>
            <a class="edit-btn float-right" (click)="modalRehide()"><i class="la la-close close-btn"></i></a>
        </div>
    
            <ng-container *ngIf="serviceDocuments.length>0">
                <ng-container *ngFor="let document of serviceDocuments; let i = index" >
                    <div class="d-flex align-items-center mb-1 play-video">
                        <!-- <img src="assets/img/image@2x.png" class="play-video"> -->
                        <p class="mb-0 newsfeed-title ">{{document.documentName}} </p>&nbsp;&nbsp;&nbsp;
                        <a target="__blank" (click)="viewDocument(document.s3Link)"  class="mb-0 newsfeed-title text-uppercase" style="color: #00bcd4;">[ Download ]</a>
                    </div>
                </ng-container>
            </ng-container>
        
        <!-- <div class="d-flex justify-content-between align-items-center mt-2">
            <h5 class="card-link news_view_detail mb-0">Audience : {{newsFeedModel.audience.name}}</h5>
            <p *ngIf="newsFeedModel.sheduledDate!=null" class="mb-0 scheduled-txt">Scheduled on {{newsFeedModel.sheduledDate+'Z' | date: 'MMM d, y, h:mm:ss a' }}</p> 
        </div> -->
</div>