export class NewsFeedBaseMOdel {
    public  newsFeederId : string;
    public  newsRecipients: {};
    public  documentType : string;
    public  isRead : boolean ;
    public  isToAll : boolean ;
    public  message : string;
    public  title : string;
    public  documentURL : string;
    public  document : File;
    public  version : string;
    public createdDate : Date; 
    public audinceID : string;
    public coverImageurl : string;
    public newsFeeder : string;
    public sheduledDate: string;//"2021-04-01T07:42:26.681Z"
    public date: any;
    public time: any;
    public amPm: any;
    public isExpired : boolean;
    public isExpiredInput : boolean;
    public isSilentPush : boolean;
    public expiredDate : any;
    public isApplyToBatchCount : boolean;
}

export class NewsFeedAddModel extends NewsFeedBaseMOdel{    
       
}