
    <!-- <div class="modal-header border-bottom-0">
        <h5 class="modal-title factfinderheader" id="exampleModalLabel">Add New Factfinder Version</h5>
        <button type="button" class="close" (click)="modalRehide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
  
      <form [formGroup]="addVersionForm" novalidate class="form " (ngSubmit)="onSubmit()" >
      <div class="modal-body factfinderbody">
        
         


      </div>
      <div class="modal-footer border-top-0 pt-0">
        <button class="btn btn-primary factfinderbtn">Save</button>
      </div>
  </form> -->

  <div class="modal-body text-center xplanmodal" >
      <form [formGroup]="addVersionForm" novalidate class="form " (ngSubmit)="onSubmit()" >
      <p>Are you sure you want to <br><span>Create</span> Fact Finder new version?</p>
      <div class="center">
        <button class="btn alert_yes_b mr-1">
            Yes, Confirm
        </button>                          
        <button type="button" (click)="modalRehide()" class="btn alert_no_b"> 
          No
        </button>
      </div>
      </form>
  </div>
      