import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FacFinderService } from '../fac-finder.service';
import { XFacContactAddModel } from 'src/app/shared/models/xfac-contact.model';
import { XFacContactResponse, XFacAddressResponse, XFacInsuranceLifeResponse, XPlanCoverResponse } from 'src/app/shared/models/response.model';
import { XFacAddressAddModel } from 'src/app/shared/models/xfac-address.model';
import { XFacInsuranceLifeAddModel } from 'src/app/shared/models/xfac-InsuranceLife.model';
import { XplanCoverAddModel } from 'src/app/shared/models/xplan-cover.model';

@Component({
  selector: 'app-add-covers',
  templateUrl: './add-covers.component.html',
  styleUrls: ['./add-covers.component.css']
})
export class AddCoversComponent implements OnInit {

  title;
  data;
  errors;
  addCoverForm: FormGroup;
  coverAddModel : XplanCoverAddModel;
  insurance : XFacInsuranceLifeAddModel;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  buttonText: string ="Add New Contact Details";
  assetOwnerTypeList : any = [];
  facFinderId : any;
  clientCode : any;
  benefitPeriodTypeList : any = [];
  waitingPeriodTypeList : any = [];
  insuranceTypeList : any = [];
  insuranceTypeListFiltered : any = [];

  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private facFinderService: FacFinderService,
    public modalRef: BsModalRef,
    private toastr: ToastrService
    ) { }
 
  ngOnInit() {
    this.Isvalid = true;    
    this.coverAddModel = new XplanCoverAddModel();
    this.insurance = new XFacInsuranceLifeAddModel(); 
    this.coverAddModel.insuranceCoverType ="000"; 
    this.coverAddModel.waitingPeriod ="000";  
    this.coverAddModel.benefitPeriod ="000"; 
    this.coverAddModel.categoryList ="000"; 
       
    if(this.data.cover !=null){
      Object.assign(this.coverAddModel, this.data.cover);
    }

    if(this.data.asset_owner_type_list !=null){
      Object.assign(this.assetOwnerTypeList, this.data.asset_owner_type_list);
    }

    if(this.data.insurance_category !=null){
      this.coverAddModel.categoryList = this.data.insurance_category;
    }   

    if(this.data.facFinderId !=null){
      this.facFinderId = this.data.facFinderId;
    }

    if(this.data.clientCode !=null){
      this.clientCode = this.data.clientCode;
    }

    if(this.data.insurance_benefit_period_type_list !=null){
      this.benefitPeriodTypeList = this.data.insurance_benefit_period_type_list;
    }

    if(this.data.insurance_waiting_period_type_list !=null){
      this.waitingPeriodTypeList = this.data.insurance_waiting_period_type_list;
    }

    if(this.data.insurance_type_list !=null){
      this.insuranceTypeList = this.data.insurance_type_list;
    }

    if(this.data.insurance !=null){
      this.insurance = this.data.insurance;
    }

    this.title = this.data.title;

    // console.log(this.clientCode);
    // if(this.data.business_id !=null){
    //   this.business_id = this.data.business_id;
    // }

    this.addCoverForm = this.formBuilder.group({
      categoryList: ['', Validators.required],
      insuranceCoverType: ['', Validators.required],
      sumInsured: ['', Validators.required],
      //lifeInsured: ['', Validators.required],
      waitingPeriod: ['', Validators.required],
      benefitPeriod: ['', Validators.required],
      // state: ['', Validators.required],
    });

  }

  get f() { return this.addCoverForm.controls; }

onSubmit(){
 this.submitted = true;

 let insuranceCoverType = this.coverAddModel.insuranceCoverType; 
 let waitingPeriod = this.coverAddModel.waitingPeriod;
 let benefitPeriod = this.coverAddModel.benefitPeriod;
 let categoryList = this.coverAddModel.categoryList;

 if (this.addCoverForm.invalid || (categoryList && categoryList.trim()=="000") || (insuranceCoverType && insuranceCoverType.trim()=="000") || (waitingPeriod && waitingPeriod.trim()=="000") || (benefitPeriod && benefitPeriod.trim()=="000") ) { //|| (addressType && addressType.trim()=="000")

  if(insuranceCoverType && insuranceCoverType.trim()=="000"){
    this.addCoverForm.controls['insuranceCoverType'].setErrors({'required': true});
  }
  if(waitingPeriod && waitingPeriod.trim()=="000"){
    this.addCoverForm.controls['waitingPeriod'].setErrors({'required': true});
  }
  if(benefitPeriod && benefitPeriod.trim()=="000"){
    this.addCoverForm.controls['benefitPeriod'].setErrors({'required': true});
  }
  if(categoryList && categoryList.trim()=="000"){
    this.addCoverForm.controls['categoryList'].setErrors({'required': true});
  }

  // console.log(this.f);
  // console.log(this.addCoverForm.invalid);
  return;
}

else{
  //this.folderModel.name = title.trim();

if(this.data.cover !=null){
//   this.ngxService.start();
// //console.log("Edditre");

  this.ngxService.start();
  this.coverAddModel.xFacInsuranceId = this.insurance.id;
  this.coverAddModel.lifeInsured = 0;
  this.facFinderService.editCover(this.coverAddModel, this.facFinderId)
  .subscribe((data:XPlanCoverResponse)=>{
  this.ngxService.stop();
  if(data.error && !data.success){      
    this.toastr.error('Error on updating insurance cover.');      
  }
  else{
    this.toastr.success('Insurance cover updated successfully.');
    this.event.emit('OK');
   // console.log(this.event);
    this.modalRef.hide();
  }
  
  },err=>{   
    console.log(err);
    this.ngxService.stop();
    this.toastr.error('Error on updating insurance cover.');
  });
    
    //this.router.navigate(['agents']);
    

}else{
  
  this.ngxService.start();
  this.coverAddModel.xFacInsuranceId = this.insurance.id;
  this.coverAddModel.lifeInsured = 0;
  this.facFinderService.addCover(this.coverAddModel, this.facFinderId)
  .subscribe((data:XPlanCoverResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){      
      this.toastr.error('Error on saving insurance cover.');      
    }
    else{
      this.toastr.success('Insurance cover added successfully.');
      this.event.emit('OK');
     // console.log(this.event);
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on saving insurance cover.');
    });

  }

}

}

modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

onReset() {
  this.submitted = false;
  this.addCoverForm.reset();
}

onChange(value){
  this.insuranceTypeListFiltered = this.insuranceTypeList.filter(device => device.insurance_type === value);
  //this.coverAddModel.insuranceCoverType = "000";

  let choice = this.insuranceTypeListFiltered.filter(choice => choice.key == this.coverAddModel.insuranceCoverType);

  if(choice && choice[0]) {
    this.coverAddModel.insuranceCoverType = choice[0].key;
    // this.selectedChoice = choice[0].key;
  }
  else{
    this.coverAddModel.insuranceCoverType = "000";
  }

  //console.log(this.coverAddModel);
}

}
