import { Component, OnInit, EventEmitter } from '@angular/core';
import { MessagecenterService } from '../messagecenter.service';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { InvoiceResponse, ClentResponsex, TransactionResponse, NewsFeedResponse, FileUploadResponse, StartConversationResponse, ClentResponsexNew } from 'src/app/shared/models/response.model';
import { MessagecenterModel } from '../../shared/models/messagecenter.model' ;
import { Utility } from 'src/app/utility/utility';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ClientViewModel } from 'src/app/shared/models/client.model';
import { ClientsService } from 'src/app/clients/clients.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-start-conversation',
  templateUrl: './start-conversation.component.html',
  styleUrls: ['./start-conversation.component.css']
})
export class StartConversationComponent implements OnInit {

  messagecenterModel : MessagecenterModel ;
  errors;
  event: EventEmitter<any> = new EventEmitter();
  clientList:ClientViewModel[];  
  Isvalid:boolean;
  data;
  clientIds :any;

  constructor(private ngxService: NgxUiLoaderService, private clientsService: ClientsService, private messagecenterService: MessagecenterService, private toastr: ToastrService, public modalRef: BsModalRef, private cookieService: CookieService) { }

  ngOnInit() {
    this.Isvalid = true;
    this.messagecenterModel = new MessagecenterModel();
    
    if(this.data.messageCenter !=null){
      Object.assign(this.messagecenterModel, this.data.messageCenter);
    }

    if(this.data.clientIds !=null){
      this.messagecenterModel.reciverID = this.data.clientIds;
      this.clientIds = this.data.clientIds;
    }

    //console.log("  ----clientIds=====  ");
    //console.log(this.clientIds);
    //this.getClientList();
  }

  getClientList(){
    this.clientsService.clientList().subscribe((data:ClentResponsexNew)=>{     
      this.clientList = data.response.data.filter(function(client){
        
        return client.client !== null;
      });

      //console.log('Client list for add news ---------');
      //console.log(this.clientList);
    });
  }
  

  onSubmit(form:NgForm) {
    const result = this.messagecenterModel.title.replace(/^\s+/g, '');

    if(form.invalid || result == "") {

        if(form.pristine){
        }
        this.Isvalid = false;
      }
      
      else{
        this.ngxService.start();
        //console.log("Add");
        this.messagecenterModel.initiatorId = this.cookieService.get('CurrentUserId'); //JSON.parse(localStorage.getItem('CurrentUserId'));
      this.messagecenterService.startCommunication(this.messagecenterModel).subscribe((data:StartConversationResponse)=>{  
        this.ngxService.stop(); 
        if(data.error && !data.success){
          //this.errors = Utility.GetLocalErrorString(data.error.errorCode);
          this.toastr.error('Error on creating conversation.');
          console.log(data.error);
        }
        else{
          this.toastr.success('Conversation created successfully.');
          this.event.emit('OK');
          this.modalRef.hide();
        }      
        },err=>{   
          this.ngxService.stop();
          this.toastr.error('Error on creating conversation.');
        });
        
      }

  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

}
