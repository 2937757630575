import { Component, OnInit, EventEmitter } from '@angular/core';
import { CompanyAddModel } from '../../shared/models/company.model';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CompaniesService } from '../companies.service';
import { Company_Response2 } from 'src/app/shared/models/response.model';
import { Utility } from '../../utility/utility';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-companies',
  templateUrl: './add-companies.component.html',
  styleUrls: ['./add-companies.component.css']
})
export class AddCompaniesComponent implements OnInit {

  companyModel : CompanyAddModel ;
  Isvalid:boolean;
  data;
  errors;
  event: EventEmitter<any> = new EventEmitter();

  constructor(private ngxService: NgxUiLoaderService, private companiesService: CompaniesService, private toastr: ToastrService, public modalRef: BsModalRef, private router : Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
   }

  ngOnInit() {
    this.Isvalid = true;
    this.companyModel = new CompanyAddModel();
    
      if(this.data.company !=null){
        Object.assign(this.companyModel, this.data.company);
      }

  }

  onSubmit(form:NgForm){
    
    if(form.invalid) {
    
      if(form.pristine){
      }
      this.Isvalid = false;
    }
  
    else{
    
      if(this.data.company !=null){
      
      }else{
        //console.log("Add");
        this.ngxService.start();
        this.companiesService.companyAdd(this.companyModel)
        .subscribe((data:Company_Response2)=>{
          this.ngxService.stop();
          if(data.error && !data.success){
            //return data;
            //let err = Utility.GetLocalErrorString(data.error.errorCode);
            //console.log(data.error);
            //console.log(data.error.message);
            if(data.error.message){
              this.errors = data.error.message;
            }
            else{
              this.errors = Utility.GetLocalErrorString(data.error.errorCode);
            }
            //console.log(this.errors);
            //this.event.emit(data.error.message);
          }
          else{
            this.toastr.success('Company added successfully.');
            this.event.emit('OK');
            this.modalRef.hide();
            this.router.navigate(['companies']);
          }
          
          },err=>{   
            this.ngxService.stop();
            this.toastr.error('Error on saving company.');
          });
      }
    }
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

}
