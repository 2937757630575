
    <div class="modal-header border-bottom-0">
        <h5 class="modal-title factfinderheader" id="exampleModalLabel">{{ title }} Retirement Income</h5>
        <button type="button" class="close" (click)="modalRehide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
  
      <form [formGroup]="addRetirementIncomeForm" novalidate class="form " (ngSubmit)="onSubmit()" >
      <div class="modal-body factfinderbody">
        
        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Retirement Plan (Provider)</label>
          <div class="inputwidth"> <span>:</span>
            <!-- <input type="text" class="form-control form-control-sm" placeholder="Mobile Phone"> -->
            <select formControlName="retirementPlan" required [(ngModel)]="retirementIncomeAddModel.retirementPlan" class="form-control form-control-sm" placeholder="Retirement Plan" >
              <option value="000">Please select</option>
              
              <ng-container *ngFor="let retirementIncomePlanProviderOne of retirementIncomePlanProviderList; let i = index">
                  <option value="{{retirementIncomePlanProviderOne.value}}">{{retirementIncomePlanProviderOne.value}}</option>
              </ng-container>
              
            </select>
                <div class="form-group">
                  <div *ngIf="submitted && f.retirementPlan.errors"   class="form_alert">
                          <span  class="ft-alert-circle"></span>
                      <div *ngIf="f.retirementPlan.errors.required">Retirement plan is required.</div>
                </div>
                </div>
          </div>
        </div>

        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Retirement No</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            <input formControlName="referenceNumber" required [(ngModel)]="retirementIncomeAddModel.referenceNumber" type="text" class="form-control form-control-sm" placeholder="Retirement No">
            <div class="form-group">
              <div *ngIf="submitted && f.referenceNumber.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.referenceNumber.errors.required">Retirement number is required.</div>
              </div>
            </div>  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Pension Balance</label>
          <div class="inputwidth"> <span>:</span> 
            <div class="input-group prepend_group">
                <span class="input-group-prepend" style="top: 0px;">
                    <div class="input-group-text border-right-0 prepend_new_class"><span class="prepend_span"> $ </span></div>
                </span>

                <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> --> <!-- ^[1-9]\d*$ -->
                <!-- <input formControlName="pensionBalance" required [(ngModel)]="retirementIncomeAddModel.pensionBalance" type="number" pattern="^\d*\.?\d*$" class="form-control form-control-sm" style="width: unset;" placeholder="Pension Balance"> -->

                <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
                <!-- pattern="^[1-9]\d*$" -->
                <input formControlName="pensionBalance" required [(ngModel)]="retirementIncomeAddModel.pensionBalance" type="number" pattern="^\d*(\.\d{0,2})?$"  class="form-control form-control-sm" style="width: unset;" placeholder="Pension Balance">

            </div>
            <div class="form-group">
              <div *ngIf="submitted && f.pensionBalance.errors"   class="form_alert aud_errormsg">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.pensionBalance.errors.required">Pension balance is required.</div>
                  <div *ngIf="f.pensionBalance.errors.pattern">Only numbers with maximum two decimal places.</div>
              </div>
            </div>
  
          </div>
        </div>
        
        <!-- <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Type</label>
          <div class="inputwidth"> <span>:</span> 
            
            <select formControlName="retirementPlanSelection" required [(ngModel)]="retirementIncomeAddModel.retirementPlanSelection" class="form-control form-control-sm" placeholder="Type" >
              <option value="000">Please select</option>
              <ng-container *ngFor="let retirementIncomePlanSelectionOne of retirementIncomePlanSelectionList; let i = index">
                  <option value="{{retirementIncomePlanSelectionOne.key}}">{{retirementIncomePlanSelectionOne.value}}</option>
              </ng-container>
            </select>
            
            <div class="form-group">
              <div *ngIf="submitted && f.retirementPlanSelection.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.retirementPlanSelection.errors.required">Type is required.</div>
              </div>
            </div>  
          </div>
        </div> -->
        
      </div>
      <div class="modal-footer border-top-0 pt-0">
        <button class="btn btn-primary factfinderbtn">Save</button>
      </div>
  </form>
      