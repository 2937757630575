import { Component, OnInit, EventEmitter, TemplateRef } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FacFinderService } from '../fac-finder.service';
import { XFacContactAddModel } from 'src/app/shared/models/xfac-contact.model';
import { XFacContactResponse } from 'src/app/shared/models/response.model';
import { RoleAddModel } from '../../shared/models/role.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-contact-details',
  templateUrl: './add-contact-details.component.html',
  styleUrls: ['./add-contact-details.component.css']
})
export class AddContactDetailsComponent implements OnInit {
  
  title;
  data;
  errors;
  addContactForm: FormGroup;
  contactAddModel : XFacContactAddModel;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  buttonText: string ="Add New Contact Details";
  contactTypeList : any = [];
  facFinderId : any;
  clientCode : any;
  status :boolean;
  message: string;

  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private facFinderService: FacFinderService,
    public modalRef: BsModalRef,
    private toastr: ToastrService,
    private modalService: BsModalService, 
    ) { }
 
  ngOnInit() {
    this.Isvalid = true;    
    this.contactAddModel = new XFacContactAddModel(); 
    this.contactAddModel.contactType ="0"; 

    if(this.data.contact !=null){
      //console.log(this.data.library);
      this.buttonText = "Update Now";
      Object.assign(this.contactAddModel, this.data.contact);
    }

    //console.log(this.data.contact_type_list);

    if(this.data.contact_type_list !=null){
      //this.data.contact_type_list = this.data.contact_type_list.sort((n1,n2) => n1.value > n2.value) 
      // this.data.contact_type_list = this.data.contact_type_list.sort()
      Object.assign(this.contactTypeList, this.data.contact_type_list);
    }

    if(this.data.facFinderId !=null){
      this.facFinderId = this.data.facFinderId;
    }

    if(this.data.clientCode !=null){
      this.clientCode = this.data.clientCode;
    }

    this.title = this.data.title;

    //console.log(this.clientCode);
    // if(this.data.business_id !=null){
    //   this.business_id = this.data.business_id;
    // }

    this.addContactForm = this.formBuilder.group({
      contactType: ['', Validators.required],
      contactValue: ['', Validators.required],
    });

    const contactValue = this.addContactForm.get('contactValue');

    this.addContactForm.get('contactType').valueChanges
      .subscribe(contactType => {

        if (contactType === 'm1' || contactType === 'm2' || contactType === 'm3') {
          contactValue.setValidators([Validators.email, Validators.required]);
        }

        if (contactType === 'p5' || contactType === 'p3') {
          contactValue.setValidators([Validators.pattern('^(\\+)?\\d{11}$'), Validators.required]);
        }

        contactValue.updateValueAndValidity();
      });

  }

  get f() { return this.addContactForm.controls; }

onSubmit(){
 this.submitted = true;

 let contactType = this.contactAddModel.contactType;

 if (this.addContactForm.invalid || (contactType && contactType.trim()=="0") ) { //|| (title && title.trim()=="")

  if(contactType && contactType.trim()=="0"){
    this.addContactForm.controls['contactType'].setErrors({'required': true});
  }
  // console.log(this.f);
  // console.log(this.addContactForm.invalid);
  return;
}

else{
  //this.folderModel.name = title.trim();

if(this.data.contact !=null){
//   this.ngxService.start();
// //console.log("Edditre");

  this.ngxService.start();
  this.contactAddModel.clientCode = this.clientCode;
  this.facFinderService.editContact(this.contactAddModel, this.facFinderId,this.clientCode)
  .subscribe((data:XFacContactResponse)=>{
  this.ngxService.stop();
  if(data.error && !data.success){      
    this.toastr.error('Error on updating telephone & email  details.');      
  }
  else{
    this.toastr.success('Telephone & Email  details updated successfully.');
    this.event.emit('OK');
   // console.log(this.event);
    this.modalRef.hide();
  }
  
  },err=>{   
    console.log(err);
    this.ngxService.stop();
    this.toastr.error('Error on updating telephone & email  details.');
  });
    
    //this.router.navigate(['agents']);
    

}else{
  
  this.ngxService.start();
  this.contactAddModel.clientCode = this.clientCode;
  this.facFinderService.addContact(this.contactAddModel, this.facFinderId, this.clientCode)
  .subscribe((data:XFacContactResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){      
      this.toastr.error('Error on saving telephone & email  details.');      
    }
    else{
      this.toastr.success('Telephone & Email  details added successfully.');
      this.event.emit('OK');
     // console.log(this.event);
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on saving contact details.');
    });

  }

}

}

modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

onReset() {
  this.submitted = false;
  this.addContactForm.reset();
}


}
