import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service';
import { Utility } from 'src/app/utility/utility';
import { DOCUMENT } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppConstants } from 'src/app/utility/app-constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted: boolean = false;
  success: boolean = false;
  loading = false;
  invalid: boolean = false;
  logged: boolean = false;
  portalUrl:any;
  //public utility: Utility;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private fromBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    
    private renderer: Renderer2,
    private route: ActivatedRoute ,private toastr: ToastrService,private ngxService: NgxUiLoaderService) {  //this.utility = new utility();

    // redirect to home if already logged in
    if (this.authService.getCurrentUserValue && this.authService.getCurrentUserValue.userName) {
      //console.log(this.authService.getCurrentUserValue);
      // check user type
      this.logged = true;
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit() {

    this.portalUrl = AppConstants.getStaffPortalURL();
    window.location = this.portalUrl;
    

    this.renderer.removeClass(this.document.body, 'vertical-layout');
    this.renderer.removeClass(this.document.body, 'vertical-menu');
    this.renderer.removeClass(this.document.body, '2-columns');
    this.renderer.removeClass(this.document.body, 'fixed-navbar');
    this.renderer.removeClass(this.document.body, 'menu-expanded');
    this.renderer.removeClass(this.document.body, 'pace-running');
    
    this.renderer.addClass(this.document.body, 'bg-full-screen-image');
    this.renderer.addClass(this.document.body, 'vertical-layout');
    this.renderer.addClass(this.document.body, 'vertical-menu');
    this.renderer.addClass(this.document.body, '1-column');
    this.renderer.addClass(this.document.body, 'menu-expanded');
    this.renderer.addClass(this.document.body, 'blank-page');

    this.loginForm = this.fromBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });
    
  }

  // convenience getter for easy access to form fields
  get inputs() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    else{
      //this.ngxService.start();
      this.ngxService.startLoader('loader-01');
    }
    
    //this.router.navigate(['send_code']);

    //console.log(this.inputs);
    this.loading =true;
    let operatingSystem =3;
    this.authService.Login(this.inputs.userName.value, this.inputs.password.value, operatingSystem).pipe(first()).subscribe(
        data => {
          //this.ngxService.stop();
          this.ngxService.stopLoader('loader-01');          
          if(data.success){
            //this.toastr.success("Login success.");
            //this.invalid = false;
            this.router.navigate(['/send_code'] );
          }else{
            this.loading =false;
            //this.invalid = true;
            this.toastr.error("Username or Password mismatch.");
          }
        },
        error => {
          this.ngxService.stopLoader('loader-01');
          //this.ngxService.stop();
          this.toastr.error("Unable to login contact administrator.");
          //this.alertService.error(error);
          console.log(error);
        }        
      );
    
  }

}
