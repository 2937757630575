
    <div class="modal-header border-bottom-0">
        <h5 class="modal-title factfinderheader" id="exampleModalLabel">{{ title }} Liability</h5>
        <button type="button" class="close" (click)="modalRehide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
  
      <form [formGroup]="addLiabilityForm" novalidate class="form " (ngSubmit)="onSubmit()" >
      <div class="modal-body factfinderbody">
        
        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Owner</label>
          <div class="inputwidth"> <span>:</span>
            <!-- <input type="text" class="form-control form-control-sm" placeholder="Mobile Phone"> -->
            
            <select formControlName="owner" required [(ngModel)]="liabilityAddModel.owner" class="form-control form-control-sm" placeholder="Owner" >
              <option value="000">Please select</option>
              
              <ng-container *ngFor="let liabilityOwnerOne of liabilityOwnerList; let i = index">
                  <option value="{{liabilityOwnerOne.key}}">{{liabilityOwnerOne.value}}</option>
              </ng-container>
              
            </select>
            <div class="form-group">
              <div *ngIf="submitted && f.owner.errors"   class="form_alert">
                  <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.owner.errors.required">Owner is required.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Type</label>
          <div class="inputwidth"> <span>:</span>
            <!-- <input type="text" class="form-control form-control-sm" placeholder="Mobile Phone"> -->
            <select formControlName="type" required [(ngModel)]="liabilityAddModel.type" (ngModelChange)="onChange($event)" class="form-control form-control-sm" placeholder="Type" >
              <option value="000">Please select</option>
              
              <ng-container *ngFor="let liabilityTypeOne of liabilityTypeList; let i = index">
                  <option value="{{liabilityTypeOne.key}}">{{liabilityTypeOne.value}}</option>
              </ng-container>
              
            </select>
                <div class="form-group">
                  <div *ngIf="submitted && f.type.errors"   class="form_alert">
                          <span  class="ft-alert-circle"></span>
                      <div *ngIf="f.type.errors.required">Type is required.</div>
                </div>
                </div>
          </div>
        </div>
        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Sub Type</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            
            <select formControlName="subType" required [(ngModel)]="liabilityAddModel.subType" class="form-control form-control-sm" placeholder="Sub Type" >
              <option value="000">Please select</option>
              <ng-container *ngFor="let liabilitySubTypeOne of liabilitySubTypeList; let i = index">
                <option  value="{{liabilitySubTypeOne.key}}">{{liabilitySubTypeOne.value}}</option>
                  <!-- <ng-container *ngIf="selectedChoice && selectedChoice==liabilitySubTypeOne.key">
                    <option selected value="{{liabilitySubTypeOne.key}}">{{liabilitySubTypeOne.value}}</option>
                  </ng-container>
                  <ng-container *ngIf="selectedChoice!=liabilitySubTypeOne.key">
                    <option  value="{{liabilitySubTypeOne.key}}">{{liabilitySubTypeOne.value}}</option>
                  </ng-container> -->
              </ng-container>
            </select>
            
            <div class="form-group">
              <div *ngIf="submitted && f.subType.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.subType.errors.required">Sub Type is required.</div>
              </div>
            </div>  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Repayment Amount</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            <div class="input-group prepend_group">
                <span class="input-group-prepend" style="top: 0px;">
                    <div class="input-group-text border-right-0 prepend_new_class"><span class="prepend_span"> $ </span></div>
                </span>
                <input formControlName="repaymentAmountValue" required [(ngModel)]="liabilityAddModel.repaymentAmountValue" pattern="^[1-9]\d*$" type="number" class="form-control form-control-sm" style="width: unset;" placeholder="Repayment Amount">
            </div>
            <div class="form-group">
              <div *ngIf="submitted && f.repaymentAmountValue.errors"   class="form_alert aud_errormsg">
                  <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.repaymentAmountValue.errors.required">Repayment Amount is required.</div>
                  <div *ngIf="f.repaymentAmountValue.errors.pattern">Only numbers.</div>
              </div>
              
            </div>
  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Repayment Frequency</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> --> 
            <select formControlName="repaymentFrequency" required [(ngModel)]="liabilityAddModel.repaymentFrequency" class="form-control form-control-sm" placeholder="Repayment Frequency" >
              <option selected value=999>Please select</option>
              <ng-container *ngFor="let liabilityRepaymentFrequencyOne of liabilityRepaymentFrequencyList; let i = index">
                  <option value="{{liabilityRepaymentFrequencyOne.key}}">{{liabilityRepaymentFrequencyOne.value}}</option>
              </ng-container>
            </select>
            <div class="form-group">
              <div *ngIf="submitted && f.repaymentFrequency.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.repaymentFrequency.errors.required">Repayment Frequency is required.</div>
              </div>
            </div>  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Product name</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            <input formControlName="productName" required [(ngModel)]="liabilityAddModel.productName" type="text" class="form-control form-control-sm" placeholder="Product name">
            <div class="form-group">
              <div *ngIf="submitted && f.productName.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.productName.errors.required">Product name is required.</div>
              </div>
            </div>  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Interest Rate</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            <input formControlName="interestRate" required [(ngModel)]="liabilityAddModel.interestRate" pattern="^[1-9]\d*$" type="text" class="form-control form-control-sm" placeholder="Interest Rate">
            <div class="form-group">
              <div *ngIf="submitted && f.interestRate.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.interestRate.errors.required">Interest Rate is required.</div>
                  <div *ngIf="f.interestRate.errors.pattern">Only numbers.</div>
              </div>
            </div>  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
          <label for="postcode" class="labelwidth">Interest Rate Type</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            
            <select formControlName="interestRateType" required [(ngModel)]="liabilityAddModel.interestRateType" class="form-control form-control-sm" placeholder="Interest Rate Type" >
              <option value="000">Please select</option>
              <ng-container *ngFor="let liabilityInterestRateTypeOne of liabilityInterestRateTypeList; let i = index">
                  <option value="{{liabilityInterestRateTypeOne.key}}">{{liabilityInterestRateTypeOne.value}}</option>
              </ng-container>
            </select>
            <div class="form-group">
              <div *ngIf="submitted && f.interestRateType.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.interestRateType.errors.required">Interest Rate Type is required.</div>
              </div>
            </div>  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
            <label for="postcode" class="labelwidth">Lender/Supplier</label>
            <div class="inputwidth"> <span>:</span> 
              <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
              
              <select formControlName="lenderSupplier" required [(ngModel)]="liabilityAddModel.lenderSupplier" class="form-control form-control-sm" placeholder="Lender/Supplier" >
                <option value="000">Please select</option>
                <ng-container *ngFor="let liabilityLenderSupplierOne of liabilityLenderSupplierList; let i = index">
                    <option value="{{liabilityLenderSupplierOne.key}}">{{liabilityLenderSupplierOne.value}}</option>
                </ng-container>
              </select>
              <div class="form-group">
                <div *ngIf="submitted && f.lenderSupplier.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.lenderSupplier.errors.required">Lender/Supplier is required.</div>
                </div>
              </div>  
            </div>
          </div>
        
      </div>
      <div class="modal-footer border-top-0 pt-0">
        <button class="btn btn-primary factfinderbtn">Save</button>
      </div>
  </form>
      