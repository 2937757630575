import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service';
import { Utility } from 'src/app/utility/utility';
import { DOCUMENT } from '@angular/common';
import { MessagingService } from 'src/app/shared/messaging.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-send-code',
  templateUrl: './send-code.component.html',
  styleUrls: ['./send-code.component.css']
})
export class SendCodeComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,    
    private renderer: Renderer2,
    private fromBuilder: FormBuilder, 
    private authService: AuthService, 
    private router:Router,
    private route: ActivatedRoute,
    private toastr: ToastrService, 
    private messagingService: MessagingService,
    private ngxService: NgxUiLoaderService,
    private cookieService: CookieService
    ) 
    {            
      this.renderer.removeClass(this.document.body, 'vertical-layout');
      this.renderer.removeClass(this.document.body, 'vertical-menu');
      this.renderer.removeClass(this.document.body, '2-columns');
      this.renderer.removeClass(this.document.body, 'fixed-navbar');
      this.renderer.removeClass(this.document.body, 'menu-expanded');
      this.renderer.removeClass(this.document.body, 'pace-running');
      
      this.renderer.addClass(this.document.body, 'bg-full-screen-image');
      this.renderer.addClass(this.document.body, 'vertical-layout');
      this.renderer.addClass(this.document.body, 'vertical-menu');
      this.renderer.addClass(this.document.body, '1-column');
      this.renderer.addClass(this.document.body, 'menu-expanded');
      this.renderer.addClass(this.document.body, 'blank-page');    
    }

  
 

  sendCodeForm: FormGroup;
  submitted: boolean = false;
  success: boolean = false;
  invalid: boolean = false;
  UserId:String;

  

  ngOnInit() {
    this.sendCodeForm = this.fromBuilder.group({
      accessCode: ['', Validators.required]
    });
  }

  get inputs() { return this.sendCodeForm.controls; }

  onSubmit() {
    this.submitted = true;

    //this.router.navigate(['dashboard']);

    if (this.sendCodeForm.invalid) {      
      return;
    }
    else{
      this.ngxService.start();
    }    
    //console.log(this.inputs);
    //console.log(localStorage.getItem('CurrentUserId'));

    //var UserId:String = JSON.parse( localStorage.getItem('CurrentUserId'));
    var UserId:String = this.cookieService.get('CurrentUserId');
    this.UserId = UserId;
    this.authService.verifyUser(this.inputs.accessCode.value,UserId)
    .pipe(first())
    .subscribe(data=>{
      this.ngxService.stop();
      // check user type and redirect 
      // console.log(123456);
      // console.log(data);
      if(data.success){
        //this.toastr.success("Successfully validated code.");
        this.messagingService.requestPermission(this.UserId);
        this.router.navigate(['dashboard']);
        //this.invalid = false;
        // var firebase_token:String = JSON.parse( localStorage.getItem('fireBaseToken'));
        // this.authService.appInstallation(firebase_token,UserId).pipe(first()).subscribe(data=>{
        //   if(data.success){
        //       this.router.navigate(['dashboard']);
        //   }else{      
        //     this.toastr.error(Utility.GetLocalErrorString(data.errorCode));
        //   }
        // });
        
      }else{    
        this.toastr.error("The pass-code does not match.");
        //this.invalid = true;  
        //this.toastr.error(Utility.GetLocalErrorString(data.errorCode));
      }
    },
    error => {
      this.ngxService.stop();
      this.toastr.error("Unable validate code contact administrator.");
      //console.log(error);
    }
    );
    
  }

}
