import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserVerifyService } from '../user-verify.service';
import { Utility } from '../../utility/utility';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UnVerifiedUsersListModel } from '../../shared/models/un-verified-users.model';
import { UnVerifiedUsersResponse2} from '../../shared/models/response.model';
import { Response } from '../../shared/models/response.model';


@Component({
  selector: 'app-add-user-verify',
  templateUrl: './add-user-verify.component.html',
  styleUrls: ['./add-user-verify.component.css']
})
export class AddUserVerifyComponent implements OnInit {
  data;
  errors;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  showMobileNumberAdd = false;
  showUserNameAdd = false;
  addInsuranceForm: FormGroup;
  submitted1 = false;
  unVerifiedUsersAddModel: UnVerifiedUsersListModel;
  showerrorMessage= false;
  showUNErrorMessage= false;
  showSuccessMessage= false;
  showUNSuccessMessage = false;
  hideMessage = false; 
  userId:any;
  mobileNumber:any;
  userName:any;
  title;
  mobileTextBoxIsNull:boolean = false;
  userTextBoxIsNull:boolean = false;
  

  constructor(
    public modalRef: BsModalRef, 
    private ngxService: NgxUiLoaderService, 
    private UserVerifyService: UserVerifyService, 
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit() {
    this.Isvalid = true; 
    this.unVerifiedUsersAddModel = new UnVerifiedUsersListModel();

    if(this.data.user !=null){
      Object.assign(this.unVerifiedUsersAddModel, this.data.user);
    }

    this.title = 'Client Account Approval'; //this.data.title;

    this.addInsuranceForm = this.formBuilder.group({
      primaryMobile: ['', Validators.required],      
    });
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

  messageHide(type){
    setTimeout(() => {
      if(type == 1) {
        this.showSuccessMessage= false;
        this.showUNSuccessMessage =false;
      }
      else {
        this.showerrorMessage= false;
        this.showUNErrorMessage= false; 
      }
    }, 2000);
  }

  onSubmit(form:NgForm){

    if(form.invalid) {

      if(form.pristine){
      }
      this.Isvalid = false;
    }
    
    else{

      if(this.data.user !=null){
        this.userId = this.data.user.id;
        this.mobileNumber = form.value.primaryMobile.replace('+', '');
        
        //this.ngxService.start();
        this.UserVerifyService.updateMobileNumber(this.userId, this.mobileNumber)
        .subscribe((data:UnVerifiedUsersResponse2)=>{ 
        
          if(data.error || !data.success){
            //this.ngxService.stop();
            //this.toastr.error('Error on saving mobile number.');
            this.showSuccessMessage= false;
            this.showerrorMessage= true;
            this.messageHide(2);
            
          }
          else{
              //this.toastr.success('Mobile number added successfully.');
              this.event.emit('OK');
              //this.modalRef.hide();    
              
              //this.unVerifiedUsersAddModel.primaryMobile = this.mobileNumber;
              
              this.showSuccessMessage= true;
              this.showMobileNumberAdd = false; 
              this.showerrorMessage= false; 
              this.messageHide(1);
          } 
        },(err: HttpErrorResponse)=>{   
          //this.ngxService.stop();
          //this.toastr.error('Error on saving mobile number.');
          this.showSuccessMessage= false;
          this.showerrorMessage= true;
          this.messageHide(2);
        });
      }
      else{
        //this.ngxService.stop();
        //this.toastr.error('Error on saving mobile number.');
        this.showSuccessMessage= false;
        this.showerrorMessage= true;
        this.messageHide(2);
      }
      //this.FadeOutLink();
    }

  }

 
  userVerifi(user_id, unVerifiedUsersAddModel): void {
    if(unVerifiedUsersAddModel.primaryMobile.trim() == ''){
      this.toastr.error('Please add primary mobile.');
    }
    else {
      this.modalRef.hide();
      //user.fortressAdmin.isArchived = status;
      //console.log(user.fortressAdmin.isArchived);
    
      this.UserVerifyService.verifyUser(user_id).subscribe(()=>{     
        //this.getUnVerifiedUserList();
        this.toastr.success('User approved successfully.');
        this.router.navigate(['un-verified-users/view']);
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
        this.toastr.error('Error on saving approve user.');
      }); 
    } 
  }

  addMobileNumber(){
    this.showMobileNumberAdd = !this.showMobileNumberAdd;
  }

  addUserName(){
    this.showUserNameAdd = !this.showUserNameAdd;
  }


  onSubmitUserName(form:NgForm){
    let user_name = form.value.userName;
    console.log(user_name);
    if(form.invalid || (user_name.trim() == '' || user_name.trim()== null)) {

      if(user_name && user_name.trim()==""){
        form.controls['userName'].setErrors({'required': true});
      }

      if(form.pristine){

      }
      this.Isvalid = false;
    }
    
    else{

      if(this.data.user !=null){
        this.userId = this.data.user.id;
        this.userName = form.value.userName;
        
        //this.ngxService.start();
        this.UserVerifyService.updateUserName(this.userId, this.userName)
        .subscribe((data:UnVerifiedUsersResponse2)=>{ 
        
          if(data.error || !data.success){
            //this.ngxService.stop();
            //this.toastr.error('Error on saving mobile number.');
            this.showUNSuccessMessage = false;
            this.showUNErrorMessage = true;
            this.messageHide(2);
          }
          else{
              this.event.emit('OK');
              this.showUNSuccessMessage = true;
              this.showUserNameAdd = false;
              this.showUNErrorMessage = false;
              this.messageHide(1);    
          } 
        },(err: HttpErrorResponse)=>{   
          //this.ngxService.stop();
          //this.toastr.error('Error on saving mobile number.');
          this.showUNSuccessMessage = false;
          this.showUNErrorMessage = true;
          this.messageHide(2);
        });
      }
      else{
        //this.ngxService.stop();
        //this.toastr.error('Error on saving mobile number.');
        this.showUNSuccessMessage = false;
        this.showUNErrorMessage = true;
        this.messageHide(2);
      }
      //this.FadeOutLink();
    }

  }
  

  get f1() { return this.addInsuranceForm.controls; }

  onSubmitInsurance(){
    this.submitted1 = true;

    if (this.addInsuranceForm.invalid ) { //|| (addressType && addressType.trim()=="000")
     //console.log(this.f1);
     //console.log(this.addInsuranceForm.invalid);
     return;
    }
    else{
      this.ngxService.start();
        
      // this.UserVerifyService.updateMobileNumber(this.insuranceAddModel, this.facFinderId)
      // .subscribe((data:XFacInsuranceLifeCoverResponse)=>{
      //   this.ngxService.stop();
      //   if(data.error && !data.success){      
      //     this.toastr.error('Error on saving insurance.');
      //     this.showerrorMessage= true;    
      //   }
      //   else{
      //     var addedPolicy = data.response;
      //     this.unVerifiedUsersAddModel.primaryMobile = addedPolicy.mobilenumber;
          
    
      //     this.showSuccessMessage= true;
      //     this.showMobileNumberAdd = false;
      //   }
      
      // },err=>{   
      //   console.log(err);
      //   this.ngxService.stop();
      //   this.showerrorMessage= true;    
      // });
  
      //this.FadeOutLink();
    }
  }

  FadeOutLink() {
    //this.hideMessage = false;
    setTimeout( () => {
          this.hideMessage = true;
        }, 2000);
   }

  getChangeMobileNo($event: any) {
    const value = $event.target.value;
    console.log(value);

    if (value == '') {
        this.mobileTextBoxIsNull = true;
    } else {
      this.mobileTextBoxIsNull = false;
    } 
  }

  getChangeUserName($event: any) {
    const value = $event.target.value;
    console.log(value);

    if (value == '') {
        this.userTextBoxIsNull = true;
    } else {
      this.userTextBoxIsNull = false;
    } 
  }

}
