import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { UnallocatedAccountsResponse } from '../shared/models/response.model';
import { AppConstants } from '../utility/app-constants';

@Injectable({
  providedIn: 'root'
})
export class UnallocatedAccountsService {
  authUrl: String;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.authUrl = AppConstants.getBaseURL();
  }

  public getUnallocated(skip: number, take: number, xeppoGroupCode?: string) {
    let params = new HttpParams().set('CompanyCode', this.cookieService.get('companyCode')).set('skip', skip.toString()).set('take', take.toString());
    if (xeppoGroupCode) {
      params =params.append('xeppoGroupCode', xeppoGroupCode);
    }
    return this.http.get<UnallocatedAccountsResponse>(this.authUrl + '/RealTimeNetworthDataRead/GetAllUnallocatedData', { params });
  }

}
