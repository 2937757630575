import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DocumentUrlResponse } from 'src/app/shared/models/response.model';
import { UserDocumentsService } from 'src/app/user-documents/user-documents.service';
import { ViewDocumentComponent } from '../view-document/view-document.component';
import { ServiceAgreementAddModel } from 'src/app/shared/models/service-agreement.model';
import { ServiceAgreementService } from 'src/app/service-agreement/service-agreement.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import {  ServiceAgreementProfileResponse } from 'src/app/shared/models/response.model';


@Component({
  selector: 'app-view-service-agreement',
  templateUrl: './view-service-agreement.component.html',
  styleUrls: ['./view-service-agreement.component.css']
})
export class ViewServiceAgreementComponent implements OnInit {

  data;
  serviceDocuments:any;
  serviceAgreement:any;
  serviceAgreementDocument:any;
  fdsRecipientsList: any;
  userId:string;
  event: EventEmitter<any> = new EventEmitter();
  advisorData = [];
  // serviceAgreementModel : ServiceAgreementAddModel;

  constructor(
    private ngxService: NgxUiLoaderService,
    private userDocumentService: UserDocumentsService,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private serviceAgreementService: ServiceAgreementService,
  ) { }

  ngOnInit(): void {
    if(this.data.serviceAgreement) {
      if(this.data.serviceAgreement.fdsAgreementStatus == 9)
        {
          this.ngxService.start();
          this.serviceAgreementService.getSheduledEnvelopeDetails(this.data.serviceAgreement.envelopeID).subscribe((data: ServiceAgreementProfileResponse) => {
            if (data.success) {
              this.serviceAgreement = data.response;
              this.ngxService.stop();
            }});
              
        }

      this.serviceAgreement = this.data.serviceAgreement

      this.serviceAgreementDocument = this.data.serviceAgreement.fdsServiceDocuments.filter((x: { isArchived: boolean; })=>x.isArchived == false)

      this.fdsRecipientsList = this.data.serviceAgreement.fdsRecipients.filter((x: { isArchived: boolean; })=>x.isArchived == false)
    }
    if(this.data.userId) {
      this.userId = this.data.userId
    }

    this.advisorData = this.serviceAgreement.fdsRecipients.filter(a => (a.advisorName != "" && a.advisorEmail != ""))
    // console.log('ggggg =', this.serviceAgreement)
  }

  // viewDocumentList(serviceAgreement:any){
  //   //console.log(newsFeed);
  //   let userId = serviceAgreement.fdsRecipients[0].userId
  //   let serviceDocuments = serviceAgreement.fdsServiceDocuments
  //   this.modalRef = this.modalService.show(ViewDocumentComponent,  {
  //     initialState: {
  //       title: 'Download PDF',
  //       data: {
  //         serviceDocuments:serviceDocuments,
  //         userId:userId,
  //       },
  //       keyboard: false,
  //       backdrop: true,
  //       ignoreBackdropClick: true
  //     },
  //     class: "modal-lg_newsfeed",


  //   });

  //   this.modalRef.content.event.subscribe(result => {
  //     if (result == 'OK') {
  //       //console.log("Success");
  //         //this.getNewsFeedList();

  //     }
  //   });
  // }

  // viewDocument(s3Link){
  //   let userDocument = {
  //                   "documentURL":s3Link, 
  //                   "documentType": 5
  //                 }
  //   this.userDocumentService.getDocumentUrl(userDocument).subscribe((data:DocumentUrlResponse)=>{
  //     let documentUrl = data.response;
  //     //console.log('document url ---------');
  //     //console.log(documentUrl);
  //     window.open(documentUrl, "_blank");
  //     //this.isLoaded = true;
  //   },
  //     (err: HttpErrorResponse)=>{
  //     console.log("Error"+ err.message);
  //   });
  // }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

}
