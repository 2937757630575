<div class="modal-header a_modal">
    <h4 class="modal-title" id="myModalLabel1">{{title}}</h4>
    <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span
        aria-hidden="true">&times;</span> </button>
  </div>
  
  
  
    <div class="modal-body dallecort_modal-body">
      <div class="row">
        <div class="col-md-12">
  
  
  
          <div class="form-body">
  
            <div class="row add_agent_gr" style="margin-top: 5px; margin-bottom: 5px;">
              <div class="col-md-5" >
                <div class="form-group">
                  <label for="firstName" class="c_n_fs">Client Name</label>
                </div>
                <div class="form-group"> <label for="firstName" class="c_n_fs">User Name</label>
                </div>
  
                <div class="form-group"> <label for="firstName" class="c_n_fs">Mobile Number</label>
                </div>
  
  
              </div>
              <div class="col-md-7">
                <div class="form-group">
                  <label for="lastName" class="c_n_fs">: 
                    <ng-container *ngIf="unVerifiedUsersAddModel.client || unVerifiedUsersAddModel.client != null"> {{unVerifiedUsersAddModel.client.firstName}} {{unVerifiedUsersAddModel.client.lastName}}</ng-container>
                    <ng-container *ngIf="unVerifiedUsersAddModel.client == null"> - </ng-container>
                  </label>
                </div>
                <div class="form-group"> <label for="firstName" class="c_n_fs">: 
                  <ng-container *ngIf="unVerifiedUsersAddModel.userName != null"> {{unVerifiedUsersAddModel.userName}} </ng-container>
                  <ng-container *ngIf="unVerifiedUsersAddModel.clientCode == null"> - </ng-container>
                </label>
                <a class="" style="color:#2cbad3; font-weight:bold;" (click)="addUserName()">
                  <ng-container *ngIf="unVerifiedUsersAddModel.userName != null"> [Change]</ng-container>
                  <ng-container *ngIf="unVerifiedUsersAddModel.userName == null"> [Add]</ng-container>
                </a>
                </div>
  
                <div class="form-group">
                  <label for="firstName" class="c_n_fs">: 
                    <ng-container *ngIf="unVerifiedUsersAddModel.primaryMobile != null"> {{unVerifiedUsersAddModel.primaryMobile}} </ng-container>
                    <ng-container *ngIf="unVerifiedUsersAddModel.primaryMobile == null"> - </ng-container>
                  </label>
                  <a class="" style="color:#2cbad3; font-weight:bold;" (click)="addMobileNumber()">
                    <ng-container *ngIf="unVerifiedUsersAddModel.primaryMobile != null"> [Change]</ng-container>
                    <ng-container *ngIf="unVerifiedUsersAddModel.primaryMobile == null"> [Add]</ng-container>
                  </a>
                </div>

                
  
  
              </div>
            </div>
            <div  *ngIf="showMobileNumberAdd">
              <!-- <form [formGroup]="addInsuranceForm" novalidate class="form " (ngSubmit)="onSubmitInsurance()" > -->
              <form #f="ngForm" novalidate class="form" (ngSubmit)="onSubmit(f)" autocomplete="false">
                <label for="firstName" class="c_n_fs">
                  <ng-container *ngIf="unVerifiedUsersAddModel.primaryMobile != null">Change</ng-container>
                  <ng-container *ngIf="unVerifiedUsersAddModel.primaryMobile == null">New</ng-container>
                   Mobile Number
                </label>
                <input autocomplete="off" type="text" pattern="^(\+[0-9]{11})$" required [(ngModel)]="unVerifiedUsersAddModel.primaryMobile" class="form-control"  id="primaryMobile" 
                name="primaryMobile" placeholder="Mobile Number"  #primaryMobile="ngModel" maxlength="15" (change) = "getChangeMobileNo($event)">

                <div class="form-group">
                  <div *ngIf="primaryMobile.invalid && (primaryMobile.dirty || primaryMobile.touched || f.submitted)"
                  class="form_alert">
                  <span  class="ft-alert-circle"></span>
                    <div *ngIf="primaryMobile.errors.required">
                        Mobile number is required.
                    </div> 
                    <div *ngIf="primaryMobile.errors.pattern">
                      Mobile number must be length 11 and type of +61445678956.
                    </div>                                                  
                  </div>
                </div>
    
                <div class="modal-footer a-footer  " style="padding-right: 0px;">
                  <button type="submit" class="btn popup_cancel_b_small cancelbtn ff_ins_btn" (click)="addMobileNumber()">Cancel</button>
                  <button type="submit" class="btn popup_save_b_small factfinderbtn ff_ins_btn">Update</button>
                </div>
              </form>
            </div>


            <div  *ngIf="showUserNameAdd">
              <!-- <form [formGroup]="addInsuranceForm" novalidate class="form " (ngSubmit)="onSubmitInsurance()" > -->
              <form #f="ngForm" novalidate class="form" (ngSubmit)="onSubmitUserName(f)" autocomplete="false">
                <label for="firstName" class="c_n_fs">
                  <ng-container *ngIf="unVerifiedUsersAddModel.userName != null">Change</ng-container>
                  <ng-container *ngIf="unVerifiedUsersAddModel.userName == null">New</ng-container>
                   User Name
                </label>
                <input autocomplete="off" type="text" required [(ngModel)]="unVerifiedUsersAddModel.userName" class="form-control"  id="userName" 
                name="userName" placeholder="User Name"  #userName="ngModel" maxlength="15" (change) = "getChangeUserName($event)">

                <div class="form-group">
                  <div *ngIf="userName.invalid && (userName.dirty || userName.touched || f.submitted)"
                  class="form_alert">
                  <span  class="ft-alert-circle"></span>
                    <div *ngIf="userName.errors.required">
                        User name is required.
                    </div>                                               
                  </div>
                </div>
    
                <div class="modal-footer a-footer  " style="padding-right: 0px;">
                  <button type="submit" class="btn popup_cancel_b_small cancelbtn ff_ins_btn" (click)="addUserName()">Cancel</button>
                  <button type="submit" class="btn popup_save_b_small factfinderbtn ff_ins_btn">Update</button>
                </div>
              </form>
            </div>

            <div class="form-group"> 
              <div *ngIf="showSuccessMessage && !hideMessage" class="alert alert-success box-msg ins-success-added" role="alert">
                  Mobile number successfully updated.
              </div>
            </div>
            <div class="form-group"> 
              <div *ngIf="showerrorMessage && !hideMessage" class="alert alert-danger2 box-msg ins-success-added" role="alert">
                Unable to add mobile number.
              </div>
            </div>

            <div class="form-group"> 
              <div *ngIf="showUNSuccessMessage && !hideMessage" class="alert alert-success box-msg ins-success-added" role="alert">
                  User name successfully updated.
              </div>
            </div>
            <div class="form-group"> 
              <div *ngIf="showUNErrorMessage && !hideMessage" class="alert alert-danger2 box-msg ins-success-added" role="alert">
                Unable to add user name.
              </div>
            </div>

  
            <div class="row add_agent_gr" style="margin-top: 5px; margin-bottom: 5px;">
              <div class="col-md-5">
                <div class="form-group">
                  <label for="firstName" class="c_n_fs">Client Code</label>
                </div>
                <div class="form-group"> <label for="firstName" class="c_n_fs">Xeppo Code</label>
                </div>
  
                <div class="form-group"> <label for="firstName" class="c_n_fs">Entity Type</label>
                </div>
  
  
              </div>
              <div class="col-md-7">
                <div class="form-group">
                  <label for="lastName" class="c_n_fs">: 
                    <ng-container *ngIf="unVerifiedUsersAddModel.clientCode != null"> {{unVerifiedUsersAddModel.clientCode}} </ng-container>
                    <ng-container *ngIf="unVerifiedUsersAddModel.clientCode == null"> - </ng-container>
                  </label>
                </div>
                <div class="form-group"> <label for="firstName" class="c_n_fs">: 
                  <ng-container *ngIf="unVerifiedUsersAddModel.xeppoCode != null"> {{unVerifiedUsersAddModel.xeppoCode}} </ng-container>
                  <ng-container *ngIf="unVerifiedUsersAddModel.xeppoCode == null"> - </ng-container>
                </label>
                </div>
  
                <div class="form-group"> <label for="firstName" class="c_n_fs">: 
                  <ng-container *ngIf="unVerifiedUsersAddModel.client != null"> {{unVerifiedUsersAddModel.client.entityType}}</ng-container>
                  <ng-container *ngIf="unVerifiedUsersAddModel.client == null"> - </ng-container>
                </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer a-footer">
      <ng-container *ngIf="!mobileTextBoxIsNull && !userTextBoxIsNull">
        <button type="button" class="btn popup_save_b factfinderbtn" (click)="userVerifi(unVerifiedUsersAddModel.id, unVerifiedUsersAddModel)">Approve</button>
      </ng-container>
      <button type="button" class="btn popup_cancel_b cancelbtn" (click)="modalRehide()" >Cancel</button>
      
    </div>
  
  
  
  <ngx-ui-loader></ngx-ui-loader>