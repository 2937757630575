  
      <div class="modal-body text-center xplanmodal" style="margin: 0px 15px;padding: 20px;">
            <h4 class="card-title float-left pdf_h4" style="font-size: 20px; margin-bottom: 7px;">PDF Preview</h4>
                  
                  <div class="btn-group float-right">
                    <button class="btn btn-sm app_document_btn " kendo-button (click)="pdf.saveAs('demo-content.pdf')" type="button" style="margin-top: -3px;  border-radius: 100px;   padding: 10px 8px 8px; font-size: 10px; margin-right: 15px; " >Download PDF</button>
                    <button class="btn btn-sm alert_no_b" (click)="modalRehide()" type="button" style="margin-top: -3px;  border-radius: 100px;   padding: 10px 8px 8px;   font-size: 10px; " >Close PDF</button>
                  </div>

        <!-- 
        <p>Are you sure you want to <br><span>Download</span> this version to PDF?</p>
        <div class="form-actions center"> -->
          <!-- <button type="button" (click)="downloadAsPDF()" class="btn alert_yes_b mr-1">
              Yes, Confirm
          </button>                           -->
          <!-- <button kendo-button (click)="pdf.saveAs('demo-content.pdf')" class="btn alert_yes_b mr-1">
            Export As PDF
          </button>
          <button type="button" (click)="modalRehide()" class="btn alert_no_b"> 
            No
          </button>
        </div> -->
      </div>      
      
  <!-- <div id="pdfTable" #pdfTable style="display: none;" class="">
      <h2>Phone numbers</h2>
      <div class="rTable" style="display: table;width: 100%;">
          <div class="rTableRow" style="	display: table-row;">
              <div class="rTableHead" style="	display: table-cell; padding: 3px 10px;	border: 1px solid #999999;"><strong>Name</strong></div>
              <div class="rTableHead" style="	display: table-cell; padding: 3px 10px;	border: 1px solid #999999;"><span style="font-weight: bold; color: red;">Telephone</span></div>
              <div class="rTableHead" style="	display: table-cell; padding: 3px 10px;	border: 1px solid #999999;">&nbsp;</div>
          </div>
          <div class="rTableRow"  style="	display: table-row;">
              <div class="rTableCell" style="	display: table-cell; padding: 3px 10px;	border: 1px solid #999999;">John</div>
              <div class="rTableCell" style="	display: table-cell; padding: 3px 10px;	border: 1px solid #999999;"><a href="tel:0123456785">0123 456 785</a></div>
              <div class="rTableCell" style="	display: table-cell; padding: 3px 10px;	border: 1px solid #999999;"><img src="images/check.gif" alt="checked" /></div>
          </div>
          <div class="rTableRow"  style="	display: table-row;">
              <div class="rTableCell" style="	display: table-cell; padding: 3px 10px;	border: 1px solid #999999;">Cassie</div>
              <div class="rTableCell" style="	display: table-cell; padding: 3px 10px;	border: 1px solid #999999;"><a href="tel:9876532432">9876 532 432</a></div>
              <div class="rTableCell" style="	display: table-cell; padding: 3px 10px;	border: 1px solid #999999;"><img src="images/check.gif" alt="checked" /></div>
          </div>
      </div>
  </div> -->

  <div class="pageWrapper" style="border-top: 1px solid  #1d8dc2; overflow-x: auto; margin-top: 24px;">
      <div class="content" style="margin-left: 0px !important; margin-top: 20px;">
        <kendo-pdf-export #pdf paperSize="A4" margin="0cm">
            
            <div class="">
                <div class="bgcolor" style="background-image: linear-gradient(-450deg, #10bdcd, #1e89c1);background-color: #3189c2;width: 300px;height: 82px;display: inline-flex; margin-bottom: 15px;"> <img src="../../../assets/img/logo_nl.png" alt="" style=" height: 55px;  margin-left: 70px;  margin-top: 14px;"> </div>
                <div class="right-1 " style=" float: right; font-size: 14px;"> <img src="../../../assets/img/batch_nl.png" style=" height: 52px; margin-top: 20px; margin-right: 30px;"  alt=""> </div>
            </div>
            <div class="" style="border-bottom: 1px solid #c2e2ef;padding-bottom: 103px;margin-left: 30px;margin-right: 30px; margin-top: -9px; margin-bottom: 0px;position: relative;">
                <!-- <div style="float: left; margin-top: 25px;"> -->
                  <span class="com_back" style="background: #1ec194; padding: 3px 17px; color: #fff; border-radius: 5px; font-size: 9px; position: absolute;margin-top: 43px;">COMPLETED VERSION</span>
                <!-- </div> -->
                <div>  
                    <!-- <h4 style="color: #2f92c6; text-align: right;font-size: 12px;">FACT FINDER SUMMARY</h4>
                    <h2 style="color: #292929; text-align: right;font-size: 16px;">
                      <ng-container *ngIf="isLoaded2">
                          <ng-container *ngIf="personalData || personalData.firstName">{{ personalData.firstName }}</ng-container>&nbsp;<ng-container *ngIf="personalData || personalData.middleName">{{ personalData.middleName }}</ng-container>&nbsp;<ng-container *ngIf="personalData || personalData.surname">{{ personalData.surname }}</ng-container>
                      </ng-container>
                    </h2>
                    <h6 style="color: #484848; text-align: right; font-size: 9px; letter-spacing: 1px;">PDF GENERATED DATE:
                      <ng-container *ngIf="isLoaded1">
                        {{nowDate | date: 'MMM d, y' }},
                      </ng-container>
                      Version No - {{versionNumber}}
                    </h6>  -->
                    <table style="float: right;margin-top: 22px; text-align: right;">
                      <tr>
                        <td style="color: #2f92c6;font-size: 12px; padding-right: 8px;">
                          FACT FINDER SUMMARY
                        </td>
                      </tr>
                      <tr>
                        <td style="color: #292929;font-size: 16px;">
                          <ng-container *ngIf="isLoaded2">
                              <ng-container *ngIf="personalData">
                                <ng-container *ngIf="personalData.firstName">{{ personalData.firstName }}</ng-container>
                                <ng-container *ngIf="personalData.middleName">&nbsp; {{ personalData.middleName }}</ng-container>
                                <ng-container *ngIf="personalData.surname">&nbsp; {{ personalData.surname }}</ng-container>
                              </ng-container>
                          </ng-container>
                        </td>
                      </tr>
                      <tr>
                        <td style="color: #484848; font-size: 9px;">
                          GENERATED DATE
                          <ng-container *ngIf="isLoaded1">
                            {{nowDate | date: 'MMM d, y' }},
                          </ng-container>
                          Version No - {{versionNumber}}
                        </td>
                      </tr>
                    </table>
                </div>
            </div>
            
            
            
            <div class="" style=" margin-left: 40px; margin-right: 40px; padding-top: 30px; ">
                <div class="" style="border-bottom: 1px solid #c2e2ef;padding-bottom: 35px;">
                  <div class="">
                      <h4  style="color: #1c89c2; margin-top: 0px; font-size: 12px;">PERSONAL DETAILS</h4>
                  </div>
                  <ng-container *ngIf="isLoaded2">
                    <table style="width:100%; margin-top: 15px;">
                        <tr>
                            <td style="font-size: 9px; vertical-align: top; padding-top: 10px;width: 120px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span> Title</td>
                            <td style="font-size: 9px; vertical-align: top; padding-top: 10px;"><span style="padding-right: 5px;">:</span>
                              <ng-container *ngIf="personalData">
                                <ng-container *ngIf="personalData.title == 1">Mr</ng-container>
                                <ng-container *ngIf="personalData.title == 2">Mrs</ng-container>
                                <ng-container *ngIf="personalData.title == 3">Miss</ng-container>
                                <ng-container *ngIf="personalData.title == 4">Ms</ng-container>
                                <ng-container *ngIf="personalData.title == 5">Dr</ng-container>
                                <ng-container *ngIf="personalData.title == 6">Prof</ng-container>
                              </ng-container>
                              <ng-container *ngIf="personalData == null || personalData == ''">-</ng-container>
                            </td>
                        </tr>
                        <tr style="margin-top: 10px;">
                            <td style="font-size: 9px; vertical-align: top; padding-top: 5px;width: 120px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span> First Name </td>
                            <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span style="padding-right: 5px;">:</span>
                              <ng-container *ngIf="personalData || personalData.firstName">{{ personalData.firstName }}</ng-container>
                              <ng-container *ngIf="personalData==null || personalData.firstName == ''">-</ng-container>
                            </td>
                        </tr>
                        <tr style="margin-top: 10px;">
                            <td style="font-size: 9px; vertical-align: top; padding-top: 5px;width: 120px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>  Second Name </td>
                            <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span style="padding-right: 5px;">:</span>
                              <ng-container *ngIf="personalData || personalData.middleName">{{ personalData.middleName }}</ng-container>
                              <ng-container *ngIf="personalData==null || personalData.middleName == ''">-</ng-container>
                            </td>
                        </tr>
                        <tr style="margin-top: 10px;">
                            <td style="font-size: 9px; vertical-align: top; padding-top: 5px;width: 120px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>  Surname </td>
                            <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span style="padding-right: 5px;">:</span>
                              <ng-container *ngIf="personalData || personalData.surname">{{ personalData.surname }}</ng-container>
                              <ng-container *ngIf="personalData == null || personalData.surname == '' ">-</ng-container>
                            </td>
                        </tr>
                        <tr style="margin-top: 10px;">
                            <td style="font-size: 9px; vertical-align: top; padding-top: 5px;width: 120px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>  Gender </td>
                            <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span style="padding-right: 5px;">:</span>
                              <ng-container *ngIf="personalData || personalData.gender">
                                <ng-container *ngIf="personalData.gender == 1">Male</ng-container>
                                <ng-container *ngIf="personalData.gender == 0">Female</ng-container>
                              </ng-container>
                              <ng-container *ngIf="personalData == null || personalData.gender == ''">-</ng-container>
                            </td>
                        </tr>
                        <tr style="margin-top: 10px;">
                            <td style="font-size: 9px; vertical-align: top; padding-top: 5px;width: 120px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>  Date of Birth </td>
                            <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span style="padding-right: 5px;">:</span>
                              <ng-container *ngIf="personalData || personalData.dateOfBirth">{{ personalData.dateOfBirth +"Z" | date: 'MMM d, y' }}</ng-container>
                              <ng-container *ngIf="personalData==null || personalData.dateOfBirth==''">-</ng-container>
                            </td>
                        </tr>
                        <tr style="margin-top: 10px;">
                            <td style="font-size: 9px; vertical-align: top; padding-top: 5px;width: 120px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>  Marital Status </td>
                            <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span style="padding-right: 5px;">:</span>
                              <ng-container *ngIf="personalData.maritalStatus">
                                <ng-container *ngIf="personalData.maritalStatus == 1">Single</ng-container>
                                <ng-container *ngIf="personalData.maritalStatus == 2">Married</ng-container>
                                <ng-container *ngIf="personalData.maritalStatus == 5">Engaged</ng-container>
                                <ng-container *ngIf="personalData.maritalStatus == 999">De Facto</ng-container>
                                <ng-container *ngIf="personalData.maritalStatus == 3">Separated</ng-container>
                                <ng-container *ngIf="personalData.maritalStatus == 4">Divorced</ng-container>
                                <ng-container *ngIf="personalData.maritalStatus == 6">Widowed</ng-container>
                              </ng-container>
                              <ng-container *ngIf="personalData == null || personalData.maritalStatus == '' ">-</ng-container>
                            </td>
                        </tr>
                        <tr style="margin-top: 10px;">
                            <td style="font-size: 9px; vertical-align: top; padding-top: 5px;width: 120px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>  Tax File Number </td>
                            <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span style="padding-right: 5px;">:</span>
                              <ng-container *ngIf="personalData || personalData.taxFileNumber">{{ personalData.taxFileNumber }}</ng-container>
                              <ng-container *ngIf="personalData == null || personalData.taxFileNumber == '' ">-</ng-container>
                            </td>
                        </tr>
                        <tr style="margin-top: 10px;">
                            <td style="font-size: 9px; vertical-align: top; padding-top: 5px;width: 120px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>  Smoker </td>
                            <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span style="padding-right: 5px;">:</span>
                              <ng-container *ngIf="personalData.smoker">
                                <ng-container *ngIf="personalData.smoker == true">Yes</ng-container>
                                <ng-container *ngIf="personalData.smoker == false">No</ng-container>
                              </ng-container>
                              <ng-container *ngIf="personalData == null || personalData.smoker == '' ">No</ng-container>
                            </td>
                        </tr>
                    </table>
                  </ng-container>
                </div>          
            </div>
            
            
            
            <div class="" style=" margin-left: 40px; margin-right: 40px; padding-top: 30px; ">
                <div class="" style="border-bottom: 1px solid #c2e2ef;padding-bottom: 35px;">
                    <div class="">
                        <h4 style="color: #1c89c2; margin: 0px; font-size: 12px;">CONTACT DETAILS</h4>
                        <h4 style="color: #d98934; margin-top: 15px;font-size: 12px;">TELEPHONE</h4>
                    </div>
                    <ng-container *ngIf="isLoaded3"> 
                      <table style="width:100%; margin-top: 15px;">
                        <ng-container *ngIf="contactInfoLength>0">
                          <ng-container *ngFor="let contact of contactInfo; let i = index">
                            <tr>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 10px;width: 120px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>{{contact.contactTypeName}}</td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 10px;"><span style="padding-right: 5px;">:</span>{{contact.contactValue}}</td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="contactInfoLength==0">
                          <tr style="margin-top: 10px;">
                              <td style="font-size: 9px; vertical-align: top; text-align: center; background: #eee;" colspan="2">No data</td>
                          </tr>
                        </ng-container>
                      </table>
                    </ng-container>
                    
                    <div class="">
    <!--                    <h4 style="color: #1c89c2; margin: 0px;">PERSONAL DETAILS</h4>-->
                        <h4 style="color: #d98934; margin-top: 15px;font-size: 12px;">ADDRESS</h4>
                    </div>
                    <ng-container *ngIf="isLoaded4">
                      <!-- <table style="width:100%; margin-top: 15px;">
                        <tr> -->
                          
                          <!-- <td style="font-size: 9px; vertical-align: top; padding-top: 5px;width: 120px;">Residential</td>
                            <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span style="padding-right: 5px;">:</span>16/1-7 Gregory Street, North Ward, QLD, 4910</td> -->
                          
                            <!-- <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">TYPE</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">STREET</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">SUBURB</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">STATE</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">POSTCODE</td>
                        </tr>
                        <ng-container *ngIf="addressInfoLength>0">
                          <ng-container *ngFor="let address of addressInfo; let i = index">
                              <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>
                                {{address.addressTypeName}}</td>
                              <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">{{address.street}}</td>
                              <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="address.suburb">{{address.suburb}}</ng-container>
                                  <ng-container *ngIf="address.suburb == '' || address.suburb == null">-</ng-container>
                              </td>
                              <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">{{address.state}}</td>
                              <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">{{address.postcode}}</td>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="addressInfoLength==0">
                          <tr style="margin-top: 10px;">
                              <td style="font-size: 9px; vertical-align: top; text-align: center; background: #eee;" colspan="5">No data</td>
                          </tr>
                        </ng-container>
                      </table> -->
                      
                        <table style="width:100%; margin-top: 15px;">
                          <tr>
                              <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">TYPE</td>
                              <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">STREET</td>
                              <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">SUBURB</td>
                              <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">STATE</td>
                              <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">POSTCODE</td>
                          </tr>
                          <ng-container *ngIf="addressInfoLength>0">
                            <ng-container *ngFor="let address of addressInfo; let i = index">
                              <tr style="margin-top: 10px;">
                                  <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 1px;margin-right: 4px;"></span>{{address.addressTypeName}}</td>
                                  <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">{{address.street}}</td>
                                  <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                    <ng-container *ngIf="address.suburb">{{address.suburb}}</ng-container>
                                    <ng-container *ngIf="address.suburb == '' || address.suburb == null">-</ng-container>
                                  </td>
                                  <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">{{address.state}}</td>
                                  <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">{{address.postcode}}</td>
                              </tr>
                            </ng-container>
                          </ng-container>
                      </table>
                    </ng-container>
                </div>          
            </div>

            <div class=""  style=" margin-left: 40px; margin-right: 40px; padding-top: 30px; ">
                <div class="" style="border-bottom: 1px solid #c2e2ef;padding-bottom: 35px;">
                    <div class="">
                        <h4 style="color: #1c89c2; margin: 0px; font-size: 12px;">ASSETS</h4>
                    </div>
                    <ng-container *ngIf="isLoaded8">
                     <table style="width:100%; margin-top: 15px;">
                        <tr>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;width: 20px;">TYPE</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;width: 20px;">SUBTYPE</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;width: 20px;">CURRENT VALUE</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;width: 50px;">DESCRIPTION</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;width: 20px;">OWNER</td>
                        </tr>
                        <ng-container *ngIf="assetInfoLength>0">
                          <ng-container *ngFor="let asset of assetInfo; let i = index">
                            <tr style="margin-top: 10px;">
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>
                                  <ng-container *ngIf="asset.assetTypeName">{{asset.assetTypeName}}</ng-container>
                                  <ng-container *ngIf="asset.assetTypeName=='' || asset.assetTypeName==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="asset.assetSubTypeName">{{asset.assetSubTypeName}}</ng-container>
                                  <ng-container *ngIf="asset.assetSubTypeName=='' || asset.assetSubTypeName==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">$
                                  <ng-container *ngIf="asset.currentValue">{{asset.currentValue}}</ng-container>
                                  <ng-container *ngIf="asset.currentValue=='' || asset.currentValue==null">0</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;line-height: 15px; text-align: justify;">                                
                                  <ng-container *ngIf="asset.description">{{asset.description}}</ng-container>
                                  <ng-container *ngIf="asset.description=='' || asset.description==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="asset.ownerType">{{asset.ownerType}}</ng-container>
                                  <ng-container *ngIf="asset.ownerType=='' || asset.ownerType==null">-</ng-container>
                                </td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="assetInfoLength==0">
                          <tr style="margin-top: 10px;">
                              <td style="font-size: 9px; vertical-align: top; text-align: center; background: #eee;" colspan="5">No data</td>
                          </tr>
                        </ng-container>
                    </table>
                  </ng-container>
                </div>          
            </div>

            
            <div class=""  style=" margin-left: 40px; margin-right: 40px; padding-top: 30px; ">
                <div class="" style="border-bottom: 1px solid #c2e2ef;padding-bottom: 35px;">
                    <div class="">
                        <h4 style="color: #1c89c2; margin: 0px; font-size: 12px;">LIABILITIES</h4>
                    </div>
                    <ng-container *ngIf="isLoaded9">  
                      <table style="width:100%; margin-top: 15px;">
                        <tr>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">OWNER</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">TYPE</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">SUBTYPE</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">REPAYMENT AMOUNT</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">REPAYMENT FREQUENCY</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">PRODUCT NAME</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">INTEREST RATE</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">INTEREST RATE TYPE</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">LENDER/SUPPLIER</td>
                        </tr>
                        <ng-container *ngIf="liabilityLength>0">
                          <ng-container *ngFor="let liability of liabilityInfo; let i = index">
                            <tr style="margin-top: 10px;">
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>
                                  <ng-container *ngIf="liability.ownerTypeName">{{liability.ownerTypeName}}</ng-container>
                                  <ng-container *ngIf="liability.ownerTypeName=='' || liability.ownerTypeName==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="liability.typeName">{{liability.typeName}}</ng-container>
                                  <ng-container *ngIf="liability.typeName=='' || liability.typeName==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="liability.subTypeName">{{liability.subTypeName}}</ng-container>
                                  <ng-container *ngIf="liability.subTypeName=='' || liability.subTypeName==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">$
                                  <ng-container *ngIf="liability.repaymentAmountValue">{{liability.repaymentAmountValue}}</ng-container>
                                  <ng-container *ngIf="liability.repaymentAmountValue=='' || liability.repaymentAmountValue==null">0</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="liability.repaymentFrequency == '' || liability.repaymentFrequency == 'null' || liability.repaymentFrequency == '999'">-</ng-container>
                                  <ng-container *ngIf="liability.repaymentFrequencyName">{{liability.repaymentFrequencyName}}</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="liability.productName">{{liability.productName}}</ng-container>
                                  <ng-container *ngIf="liability.productName=='' || liability.productName==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="liability.interestRate">{{liability.interestRate}}</ng-container>
                                  <ng-container *ngIf="liability.interestRate=='' || liability.interestRate==null">0</ng-container>
                                %</td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="liability.interestRateTypeName">{{liability.interestRateTypeName}}</ng-container>
                                  <ng-container *ngIf="liability.interestRateTypeName=='' || liability.interestRateTypeName==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="liability.lenderSupplierName">{{liability.lenderSupplierName}}</ng-container>
                                  <ng-container *ngIf="liability.lenderSupplierName=='' || liability.lenderSupplierName==null">-</ng-container>
                                </td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="liabilityLength==0">
                          <tr style="margin-top: 10px;">
                              <td style="font-size: 9px; vertical-align: top; text-align: center; background: #eee;" colspan="9">No data</td>
                          </tr>
                        </ng-container>
                      </table>
                    </ng-container>
                </div>          
            </div>

            <div class=""  style=" margin-left: 40px; margin-right: 40px; padding-top: 30px; ">
                <div class="" style="border-bottom: 1px solid #c2e2ef;padding-bottom: 35px;">
                    <div class="">
                        <h4 style="color: #1c89c2; margin: 0px; font-size: 12px;">DEPENDENTS</h4>
                    </div>
                    <ng-container *ngIf="isLoaded5">
                     <table style="width:100%; margin-top: 15px;">
                        <tr>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">SURNAME</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">FIRST NAME</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">DATE OF BIRTH</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">GENDER</td>
                        </tr>
                        <ng-container *ngIf="dependentInfoLength>0">
                          <ng-container *ngFor="let dependent of dependentInfo; let i = index">
                            <tr style="margin-top: 10px;">
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>
                                  <ng-container *ngIf="dependent.surname">{{dependent.surname}}</ng-container>
                                  <ng-container *ngIf="dependent.surname == '' || dependent.surname == null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">{{dependent.firstName}}</td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">{{dependent.dateOfBirth +"Z" | date: 'MMM d, y' }}</td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                    <ng-container *ngIf="dependent.gender == 1">Male</ng-container>
                                    <ng-container *ngIf="dependent.gender == 0">Female</ng-container>
                                </td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="dependentInfoLength==0">
                          <tr style="margin-top: 10px;">
                              <td style="font-size: 9px; vertical-align: top; text-align: center; background: #eee;" colspan="4">No data</td>
                          </tr>
                        </ng-container>
                    </table>
                  </ng-container>
                </div>          
            </div>

            <div class=""  style=" margin-left: 40px; margin-right: 40px; padding-top: 30px; ">
                <div class="" style="border-bottom: 1px solid #c2e2ef;padding-bottom: 35px;">
                    <div class="">
                        <h4 style="color: #1c89c2; margin: 0px; font-size: 12px;">EMPLOYEMENT</h4>
                    </div>
                    <ng-container *ngIf="isLoaded6">
                     <table style="width:100%; margin-top: 15px;">
                        <tr>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">OCCUPATION</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">EMPLOYER</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">STATUS</td>
                            <!-- <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">EMPLOYER STREET</td>                            
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">EMPLOYER SUBURB</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">EMPLOYER STATE</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">EMPLOYER POSTCODE</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">EMPLOYER COUNTRY</td> -->
                        </tr>
                        <ng-container *ngIf="employmentInfoLength>0">
                          <ng-container *ngFor="let employment of employmentInfo; let i = index">
                            <tr style="margin-top: 10px;">
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>
                                  <ng-container *ngIf="employment.occupationName">{{employment.occupationName}}</ng-container>
                                  <ng-container *ngIf="employment.occupationName == '' || employment.occupationName == null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="employment.employer">{{employment.employer}}</ng-container>
                                  <ng-container *ngIf="employment.employer == '' || employment.employer == null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="employment.status">{{employment.status}}</ng-container>
                                  <ng-container *ngIf="employment.status == '' || employment.status == null">-</ng-container>
                                </td>
                                <!-- <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="employment.employerStreet">{{employment.employerStreet}}</ng-container>
                                  <ng-container *ngIf="employment.employerStreet == '' || employment.employerStreet == null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="employment.employerSuburb">{{employment.employerSuburb}}</ng-container>
                                  <ng-container *ngIf="employment.employerSuburb == '' || employment.employerSuburb == null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="employment.employerState">{{employment.employerState}}</ng-container>
                                  <ng-container *ngIf="employment.employerState == '' || employment.employerState == null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="employment.employerPostcode">{{employment.employerPostcode}}</ng-container>
                                  <ng-container *ngIf="employment.employerPostcode == '' || employment.employerPostcode == null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="employment.employerCountry">{{employment.employerCountry}}</ng-container>
                                  <ng-container *ngIf="employment.employerCountry == '' || employment.employerCountry == null">-</ng-container>
                                </td> -->
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="employmentInfoLength==0">
                          <tr style="margin-top: 10px;">
                              <td style="font-size: 9px; vertical-align: top; text-align: center; background: #eee;" colspan="8">No data</td>
                          </tr>
                        </ng-container>
                    </table>
                  </ng-container>
                </div>          
            </div>

            <div class="" style=" margin-left: 40px; margin-right: 40px; padding-top: 30px; ">
                <div class="" style="border-bottom: 1px solid #c2e2ef;padding-bottom: 35px;">
                  <div class="">
                      <h4  style="color: #1c89c2; margin-top: 0px; font-size: 12px;">ESTATE DETAILS</h4>
                  </div>
                  <ng-container *ngIf="isLoaded7">
                    <table style="width:100%; margin-top: 15px;">
                        <ng-container *ngIf="estatePlanninData">
                            <tr>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;width: 120px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>Will Exists</td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span style="padding-right: 5px;">:</span>
                                  <ng-container *ngIf="estatePlanninData.willExists == true">Yes</ng-container> 
                                  <ng-container *ngIf="estatePlanninData.willExists == false">No</ng-container>
                                </td>
                            </tr>
                            <tr style="margin-top: 10px;">
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;width: 120px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>Date of Will </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span style="padding-right: 5px;">:</span>
                                  <ng-container *ngIf="estatePlanninData.willDate"> 
                                    {{estatePlanninData.willDate +"Z" | date: 'MMM d, y'}}
                                  </ng-container>
                                  <ng-container *ngIf="estatePlanninData.willDate == '' || estatePlanninData.willDate == null"> 
                                    -
                                  </ng-container>
                                </td>
                            </tr>
                            <tr style="margin-top: 10px;">
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;width: 120px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>Location </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span style="padding-right: 5px;">:</span>
                                  <ng-container *ngIf="estatePlanninData.willDate">
                                    {{estatePlanninData.willLocation}}
                                  </ng-container>
                                  <ng-container *ngIf="estatePlanninData.willDate == '' || estatePlanninData.willDate == null"> 
                                    -
                                  </ng-container>
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                  </ng-container>
                </div>          
            </div>

            <div class=""  style=" margin-left: 40px; margin-right: 40px; padding-top: 30px; ">
                <div class="" style="padding-bottom: 35px;">
                    <div class="">
                        <h4 style="color: #1c89c2; margin: 0px; font-size: 12px;">GOALS</h4>
                    </div>
                    <ng-container *ngIf="isLoaded12"> 
                      <table style="width:100%; margin-top: 15px;">
                          <tr>
                              <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;width: 20px;">OWNER</td>
                              <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;width: 50px;">DESCRIPTION</td>
                              <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;width: 20px;">AMOUNT</td>
                              <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold; width: 50px;">Target Date</td>
                              <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold; width: 50px;">COMMENTS</td>
                          </tr>
                          <ng-container *ngIf="goalDataLength>0">
                            <ng-container *ngFor="let goal of goalData; let i = index">
                              <tr style="margin-top: 10px;">
                                  <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>
                                    <ng-container *ngIf="goal.ownerType">{{goal.ownerType}}</ng-container>
                                    <ng-container *ngIf="goal.ownerType=='' || goal.ownerType==null">-</ng-container>
                                  </td>
                                  <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                    <ng-container *ngIf="goal.description">{{goal.description}}</ng-container>
                                    <ng-container *ngIf="goal.description=='' || goal.description==null">-</ng-container>
                                  </td>
                                  <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">$
                                    <ng-container *ngIf="goal.targetAmountValue">{{goal.targetAmountValue}}</ng-container>
                                    <ng-container *ngIf="goal.targetAmountValue=='' || goal.targetAmountValue==null">0</ng-container>
                                  </td>
                                  <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                      <ng-container *ngIf="goal.targetDate"> {{goal.targetDate +"Z" | date: 'MMM d, y'}} </ng-container>
                                      <ng-container *ngIf="goal.targetDate=='' || goal.targetDate==null"> - </ng-container>
                                  </td>
                                  <td style="font-size: 9px; vertical-align: top; padding-top: 5px; line-height: 15px; text-align: justify;">
                                    <ng-container *ngIf="goal.comments">{{goal.comments}}</ng-container>
                                    <ng-container *ngIf="goal.comments=='' || goal.comments==null">-</ng-container>
                                  </td>
                              </tr>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="goalDataLength==0">
                            <tr style="margin-top: 10px;">
                                <td style="font-size: 9px; vertical-align: top; text-align: center; background: #eee;" colspan="4">No data</td>
                            </tr>
                          </ng-container>
                      </table>
                    </ng-container>
                </div>          
            </div>

            <div class=""  style=" margin-left: 40px; margin-right: 40px; padding-top: 30px; ">
                <div class="" style="border-bottom: 1px solid #c2e2ef;padding-bottom: 35px;">
                    <div class="">
                        <h4 style="color: #1c89c2; margin: 0px; font-size: 12px;">INCOME</h4>
                    </div>
                    <ng-container *ngIf="isLoaded10">  
                      <table style="width:100%; margin-top: 15px;">
                        <tr>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">TYPE</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">SUBTYPE</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">DESCRIPTION</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">OWNER</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">FREQUENCY</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">AMOUNT</td>
                        </tr>
                        <ng-container *ngIf="incomeDataLength>0">
                          <ng-container *ngFor="let income of incomeData; let i = index">
                            <tr style="margin-top: 10px;">
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>
                                  <ng-container *ngIf="income.incomeTypeName">{{income.incomeTypeName}}</ng-container>
                                  <ng-container *ngIf="income.incomeTypeName=='' || income.incomeTypeName==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="income.incomeSubTypeName">{{income.incomeSubTypeName}}</ng-container>
                                  <ng-container *ngIf="income.incomeSubTypeName=='' || income.incomeSubTypeName==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="income.description">{{income.description}}</ng-container>
                                  <ng-container *ngIf="income.description=='' || income.description==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="income.ownerType">{{income.ownerType}}</ng-container>
                                  <ng-container *ngIf="income.ownerType=='' || income.ownerType==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                    <ng-container *ngIf="income.incomeFrequencyName">{{income.incomeFrequencyName}}</ng-container>
                                    <ng-container *ngIf="income.incomeFrequencyName=='' || income.incomeFrequencyName==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">$
                                  <ng-container *ngIf="income.amountValue">{{income.amountValue}}</ng-container>
                                  <ng-container *ngIf="income.amountValue=='' || income.amountValue==null">-</ng-container>
                                </td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="incomeDataLength==0">
                          <tr style="margin-top: 10px;">
                              <td style="font-size: 9px; vertical-align: top; text-align: center; background: #eee;" colspan="6">No data</td>
                          </tr>
                        </ng-container>
                      </table>
                    </ng-container>
                </div>          
            </div>

            <div class=""  style=" margin-left: 40px; margin-right: 40px; padding-top: 30px; ">
                <div class="" style="border-bottom: 1px solid #c2e2ef;padding-bottom: 35px;">
                    <div class="">
                        <h4 style="color: #1c89c2; margin: 0px; font-size: 12px;">EXPENSES</h4>
                    </div>
                    <ng-container *ngIf="isLoaded11">  
                      <table style="width:100%; margin-top: 15px;">
                        <tr>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">TYPE</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">SUBTYPE</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">DESCRIPTION</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">OWNER</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">FREQUENCY</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">AMOUNT</td>
                        </tr>
                        <ng-container *ngIf="expenseDataLength>0">
                          <ng-container *ngFor="let expense of expenseData; let i = index">
                            <tr style="margin-top: 10px;">
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>
                                  <ng-container *ngIf="expense.expenseTypeName">{{expense.expenseTypeName}}</ng-container>
                                  <ng-container *ngIf="expense.expenseTypeName=='' || expense.expenseTypeName==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="expense.expenseSubTypeName">{{expense.expenseSubTypeName}}</ng-container>
                                  <ng-container *ngIf="expense.expenseSubTypeName=='' || expense.expenseSubTypeName==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="expense.description">{{expense.description}}</ng-container>
                                  <ng-container *ngIf="expense.description=='' || expense.description==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                  <ng-container *ngIf="expense.ownerType">{{expense.ownerType}}</ng-container>
                                  <ng-container *ngIf="expense.ownerType=='' || expense.ownerType==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                    <ng-container *ngIf="expense.expenseFrequencyName">{{expense.expenseFrequencyName}}</ng-container>
                                    <ng-container *ngIf="expense.expenseFrequencyName=='' || expense.expenseFrequencyName==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">$
                                  <ng-container *ngIf="expense.amountValue">{{expense.amountValue}}</ng-container>
                                  <ng-container *ngIf="expense.amountValue=='' || expense.amountValue==null">-</ng-container>
                                </td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="expenseDataLength==0">
                          <tr style="margin-top: 10px;">
                              <td style="font-size: 9px; vertical-align: top; text-align: center; background: #eee;" colspan="6">No data</td>
                          </tr>
                        </ng-container>
                      </table>
                    </ng-container>
                </div>          
            </div>

            <div class="" style=" margin-left: 40px; margin-right: 40px; padding-top: 30px; ">
                <div class="" style="padding-bottom: 35px;">
                    <div class="">
                        <h4 style="color: #1c89c2; margin: 0px; font-size: 12px;">INSURANCE DETAILS</h4>
                    </div>
                    <ng-container *ngIf="isLoaded13">
                      <ng-container *ngFor="let insurance of insuranceData; let i = index">
                        <div class="" style="font-size: 10px;margin-top: 15px; font-weight: bolder;">
                            <span style="border-right: 2px solid #db8934; padding-right: 10px;">{{insurance.policyNumber}} - 
                                <ng-container *ngIf="insurance.planName">{{insurance.planName}}</ng-container>
                                <ng-container *ngIf="insurance.planName=='' || insurance.planName==null">No</ng-container>
                            </span>
                            <span style="padding-left: 10px;padding-right: 10px; border-right: 2px solid #db8934;">Premium : $
                                <ng-container *ngIf="insurance.totalPremium">{{insurance.totalPremium}}</ng-container>
                                <ng-container *ngIf="insurance.totalPremium=='' || insurance.totalPremium==null">0</ng-container>
                            </span>
                            <span style="padding-left: 10px;">Policy Owner : 
                                <ng-container *ngIf="insurance.policyOwners">{{insurance.policyOwners}}</ng-container>
                                <ng-container *ngIf="insurance.policyOwners=='' || insurance.policyOwners==null">-</ng-container>
                            </span>
                        </div>
  
                        <table style="width:100%; margin-top: 10px; margin-left: 10px;">
                            <tr>
                                <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">COVER</td>
                                <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">SUM INSURED</td>
                                <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">WAITING PERIOD</td>
                                <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">BENEFIT PERIOD</td>
                            </tr>
                            <!-- <ng-container *ngIf="insurance.groupedCovers"> 
                                <ng-container *ngIf="insurance.groupedCovers.additional && insurance.groupedCovers.additional.length >0"> 
                                    <ng-container *ngFor="let cover of insurance.groupedCovers.additional; let i = index">
                                      <tr style="margin-top: 10px;">
                                          <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 1px;margin-right: 4px;"></span>Life Cover</td>
                                          <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">${{cover.sumInsured}}</td>
                                          <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">{{cover.waitingPeriod}}</td>
                                          <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">{{cover.benefitPeriod}}</td>
                                      </tr>
                                    </ng-container>
                                </ng-container>
                            </ng-container> -->
                            <ng-container *ngIf="insurance.xFacInsuranceCovers"> 
                                <ng-container *ngIf="insurance.xFacInsuranceCovers && insurance.xFacInsuranceCovers.length >0"> 
                                    <ng-container *ngFor="let cover of insurance.xFacInsuranceCovers; let i = index">
                                      <tr style="margin-top: 10px;">
                                          <td style="font-size: 9px; vertical-align: top; padding-top: 5px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 1px;margin-right: 4px;"></span>
                                            <ng-container *ngIf="cover.insuranceCoverTypeName"> {{cover.insuranceCoverTypeName}} </ng-container>
                                            <ng-container *ngIf="cover.insuranceCoverTypeName=='' || cover.insuranceCoverTypeName==null "> - </ng-container>
                                          </td>
                                          <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">$
                                              <ng-container *ngIf="cover.sumInsured">{{cover.sumInsured}}</ng-container>
                                              <ng-container *ngIf="cover.sumInsured=='' || cover.sumInsured==null">0</ng-container>
                                          </td>
                                          <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                              <ng-container *ngIf="cover.waitingPeriod">{{cover.waitingPeriod}}</ng-container>
                                              <ng-container *ngIf="cover.waitingPeriod=='' || cover.waitingPeriod==null">-</ng-container>
                                          </td>
                                          <td style="font-size: 9px; vertical-align: top; padding-top: 5px;">
                                              <ng-container *ngIf="cover.benefitPeriod">{{cover.benefitPeriod}}</ng-container>
                                              <ng-container *ngIf="cover.benefitPeriod=='' || cover.benefitPeriod==null">-</ng-container>
                                          </td>
                                      </tr>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="insurance.xFacInsuranceCovers.length ===0">
                                  <tr style="margin-top: 10px;">
                                      <td style="font-size: 9px; vertical-align: top; text-align: center; background: #eee;" colspan="4">No data</td>
                                  </tr>
                                </ng-container>
                            </ng-container>
                            
                        </table>
  
                      </ng-container>
                    </ng-container>
    
                   
                </div>
            </div>

            <div class=""  style=" margin-left: 40px; margin-right: 40px; padding-top: 30px; ">
                <div class="" style="border-bottom: 1px solid #c2e2ef;padding-bottom: 35px;">
                    <div class="">
                        <h4 style="color: #1c89c2; margin: 0px; font-size: 12px;">MEDICAL INSURANCE</h4>
                    </div>
                    <ng-container *ngIf="isLoaded15">
                      <table style="width:100%; margin-top: 15px;">
                        <tr>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">POLICY TYPE</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">POLICY NO</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">PLAN NAME</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">TOTAL PREMIUM</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">POLICY OWNER</td>
                        </tr>
                        <ng-container *ngIf="medicalInsuranceInfoLength>0">
                          <ng-container *ngFor="let medicalInsurance of medicalInsuranceInfo; let i = index">
                            <tr style="margin-top: 10px;">
                                <td style="font-size: 9px; vertical-align: top; padding-top:5px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>
                                  <ng-container *ngIf="medicalInsurance.insuranceMedicalTypeName">{{medicalInsurance.insuranceMedicalTypeName}}</ng-container>
                                  <ng-container *ngIf="medicalInsurance.insuranceMedicalTypeName=='' || medicalInsurance.insuranceMedicalTypeName==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top:5px;">
                                  <ng-container *ngIf="medicalInsurance.policyNumber">{{medicalInsurance.policyNumber}}</ng-container>
                                  <ng-container *ngIf="medicalInsurance.policyNumber=='' || medicalInsurance.policyNumber==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top:5px;">
                                  <ng-container *ngIf="medicalInsurance.planName">{{medicalInsurance.planName}}</ng-container>
                                  <ng-container *ngIf="medicalInsurance.planName=='' || medicalInsurance.planName==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top:5px;">$
                                  <ng-container *ngIf="medicalInsurance.totalPremiumValue">{{medicalInsurance.totalPremiumValue}}</ng-container>
                                  <ng-container *ngIf="medicalInsurance.totalPremiumValue=='' || medicalInsurance.totalPremiumValue==null">0</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top:5px;">
                                  <ng-container *ngIf="medicalInsurance.ownerName">{{medicalInsurance.ownerName}}</ng-container>
                                  <ng-container *ngIf="medicalInsurance.ownerName=='' || medicalInsurance.ownerName==null">-</ng-container>
                                </td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="medicalInsuranceInfoLength==0">
                          <tr style="margin-top: 10px;">
                              <td style="font-size: 9px; vertical-align: top; text-align: center; background: #eee;" colspan="5">No data</td>
                          </tr>
                        </ng-container>
                      </table>
                    </ng-container>
                </div>          
            </div>
            
            <div class=""  style=" margin-left: 40px; margin-right: 40px; padding-top: 30px; ">
                <div class="" style="border-bottom: 1px solid #c2e2ef;padding-bottom: 35px;">
                    <div class="">
                        <h4 style="color: #1c89c2; margin: 0px; font-size: 12px;">RETIREMENT INCOME</h4>
                    </div>
                    <ng-container *ngIf="isLoaded14">
                      <table style="width:100%; margin-top: 15px;">
                        <tr>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">RETIREMENT PLAN</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">RETIREMENT NO</td>
                            <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">PENSION BALANCE</td>
                            <!-- <td style="color: #d98934; font-size: 8px; text-align: left; padding-right: 10px; vertical-align: top; font-weight: bold;">TYPE</td> -->
                        </tr>
                        <ng-container *ngIf="retirementIncomeInfoLength>0">
                          <ng-container *ngFor="let retirementIncome of retirementIncomeInfo; let i = index">
                            <tr style="margin-top: 10px;">
                                <td style="font-size: 9px; vertical-align: top; padding-top:5px;"><span class="dot" style="height: 6px;width: 6px;background-color: #3189c2;border-radius: 50%;display: inline-block;margin-bottom: 0px;margin-right: 4px;"></span>
                                  <!-- <ng-container *ngIf="retirementIncome.retirementPlanName">{{retirementIncome.retirementPlanName}}</ng-container>
                                  <ng-container *ngIf="retirementIncome.retirementPlanName=='' || retirementIncome.retirementPlanName==null">-</ng-container> -->
                                  <ng-container *ngIf="retirementIncome.retirementPlan">{{retirementIncome.retirementPlan}}</ng-container>
                                  <ng-container *ngIf="retirementIncome.retirementPlan=='' || retirementIncome.retirementPlan==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top:5px;">
                                  <ng-container *ngIf="retirementIncome.referenceNumber">{{retirementIncome.referenceNumber}}</ng-container>
                                  <ng-container *ngIf="retirementIncome.referenceNumber=='' || retirementIncome.referenceNumber==null">-</ng-container>
                                </td>
                                <td style="font-size: 9px; vertical-align: top; padding-top:5px;">
                                  <ng-container *ngIf="retirementIncome.pensionBalance">$ {{retirementIncome.pensionBalance}}</ng-container>
                                  <ng-container *ngIf="retirementIncome.pensionBalance=='' || retirementIncome.pensionBalance==null">-</ng-container>
                                </td>
                                <!-- <td style="font-size: 9px; vertical-align: top; padding-top:5px;">
                                  <ng-container *ngIf="retirementIncome.retirementPlanSelectionName">{{retirementIncome.retirementPlanSelectionName}}</ng-container>
                                  <ng-container *ngIf="retirementIncome.retirementPlanSelectionName=='' || retirementIncome.retirementPlanSelectionName==null">-</ng-container>
                                </td> -->
                            </tr>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="retirementIncomeInfoLength==0">
                          <tr style="margin-top: 10px;">
                              <td style="font-size: 9px; vertical-align: top; text-align: center; background: #eee;" colspan="3">No data</td>
                          </tr>
                        </ng-container>
                      </table>
                    </ng-container>
                </div>          
            </div>
            
            
            <div style=" margin-left: 40px; margin-right: 40px; text-align: center; margin-top: 28px; color: #b5b5b5; border-top: 1px solid #1d91c0; padding-top: 20px; font-size: 10px; padding-bottom: 20px;"> Dallecort Financial Services </div>            
        </kendo-pdf-export>
        
        
      </div>
    </div>
     
  
  


