import { Component, OnInit } from '@angular/core';
import { GetFactFinderNewResponse, ClentProfileResponse } from 'src/app/shared/models/response.model';
import { GetFactFinderNewModel, FactFinderNew } from 'src/app/shared/models/fact-finder-categories.model';
import { FactFinderService } from 'src/app/fact-finder/fact-finder.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ClientsService } from 'src/app/clients/clients.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientViewModel } from 'src/app/shared/models/client.model';

@Component({
  selector: 'app-view-fact',
  templateUrl: './view-fact.component.html',
  styleUrls: ['./view-fact.component.css']
})
export class ViewFactComponent implements OnInit {
  data: any;
  getFactFinderModel : GetFactFinderNewModel ;
  factFinderNew : FactFinderNew;
  isLoaded :boolean;
  factId: any;
  factFinderServiceData : any;
  facPersonalData: any;
  clientId: any;
  client:ClientViewModel;
  isLoaded1: boolean;

  constructor(private factFinderService: FactFinderService, public modalRef: BsModalRef, private clientsService: ClientsService, private route: ActivatedRoute) { }
  

  ngOnInit() {
    this.clientId = this.route.snapshot.queryParams['id'];   

    //this.getFactFinderModel = new GetFactFinderNewModel();
    this.factFinderNew = new FactFinderNew();
    
      if(this.data.factFinderNew !=null){
        this.factFinderNew = JSON.parse(JSON.stringify(this.data.factFinderNew)); // Object.assign({}, this.data.agent);
      }
      this.facPersonalData = this.data.factFinderNew;
      this.factId = this.data.factFinderNew.id;
      // console.log(this.data.factFinderNew.id);

      this.factFinderService.getFactFinderById(this.factId).subscribe((data:GetFactFinderNewResponse)=>{     
        this.factFinderServiceData = data.response;
        this.isLoaded = true;
          // console.log(data.response);
        },
        (err: HttpErrorResponse)=>{
          //console.log("Error"+ err.message);
        });

        this.GetClient();

  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

  GetClient(){
    this.clientsService.getClient(this.clientId).subscribe((clientData:ClentProfileResponse)=>{
   
      this.client = clientData.response;
      // console.log("client details ==========");
      // console.log(this.client);

      this.isLoaded1 = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
        
      });
  }

}
