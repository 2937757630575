<!-- <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Newsfeed Item</h4>
    
</div> -->
<div class="modal-body" *ngIf="isLoaded" tabindex="-1">
    <!-- <button type="button" class="close close_newsfeed" (click)="modalRehide()" data-dismiss="modal" aria-label="Close"> -->
        <!-- <span aria-hidden="true">&times;</span> </button> -->
        <div class="d-flex justify-content-between align-items-center">
            <h4 class="float-left title">News Feed</h4>
            <a class="edit-btn float-right" (click)="modalRehide()"><i class="la la-close close-btn"></i></a>
        </div>
    <div class='com_news img-wr'> 
        <ng-container *ngIf="newsFeedModel.coverImageurl=='' || newsFeedModel.coverImageurl==null ">
            <img class='grid_img' src="../assets/img/image@3x.png" />
        </ng-container>
        <ng-container *ngIf="newsFeedModel.coverImageurl">
            <img class='grid_img' src="{{newsFeedModel.coverImageurl}}" />
        </ng-container>
        <!-- <div class="com_news_icon">
            <ng-container *ngIf="newsFeedModel.documentType=='1' || newsFeedModel.documentType=='2' ">
                <img src="{{ 'assets/img/news_image.png'}}" alt="" class="com_icon_image">
            </ng-container>
            <ng-container *ngIf="newsFeedModel.documentType=='3'">
                <img src="assets/img/news_pdf.png" alt="" class="com_icon_image">
            </ng-container>
            <ng-container *ngIf="newsFeedModel.documentType=='4'">
                <img src="assets/img/news_doc.png" alt="" class="com_icon_image">
            </ng-container> -->


            <!-- <img src="assets/img/news_image.png" class="com_icon_image">  -->
        <!-- </div> -->
    </div>
    <div class="d-flex justify-content-between my-2 align-items-center">
        <h5 class="newsfeed-title mb-0">{{newsFeedModel.title}}</h5>
        <button *ngIf="viewIn == 'newsfeed'" type="submit" class="btn popup_save_b read-btn text-underline" (click)="viewNewsfeedreadModal()">Read by {{readNotificationCount}} {{clientText}}</button>
    </div>
        
        <!-- <p class="" [innerHTML]="newsFeedModel.message"></p> -->

        <quill-view-html [content]="newsFeedModel.message" theme="snow"></quill-view-html>
        

        <div class="d-flex align-items-center mb-1 play-video">
            
            <!-- <ng-container *ngIf="newsFeedModel.documentType=='1'">
                <img src="assets/img/image@2x.png" class="play-video">
                <p class="mb-0 newsfeed-title text-uppercase">image</p>&nbsp;
                <a target="__blank" href="{{newsFeedModel.documentURL}}" class="mb-0 newsfeed-title text-uppercase">[ VIEW ]</a>
            </ng-container> -->
            <ng-container *ngIf="newsFeedModel.documentType=='2'">
                <img src="assets/img/image@2x.png" class="play-video">
                <p class="mb-0 newsfeed-title text-uppercase">Image </p>&nbsp;
                <a target="__blank" href="{{newsFeedModel.documentURL}}" class="mb-0 newsfeed-title text-uppercase">[ VIEW ]</a>
            </ng-container>
            <ng-container *ngIf="newsFeedModel.documentType=='3'">
                <img src="assets/img/attachment@2x.png" class="play-video">
                <p class="mb-0 newsfeed-title text-uppercase">Document</p>&nbsp;
                <a target="__blank" href="{{newsFeedModel.documentURL}}" class="mb-0 newsfeed-title text-uppercase">[ VIEW ]</a>
            </ng-container>
            <ng-container *ngIf="newsFeedModel.documentType=='4'">
                <img src="assets/img/video@2x.png" class="play-video">
                <p class="mb-0 newsfeed-title text-uppercase">Watch video</p>&nbsp;
                <a target="__blank" href="{{newsFeedModel.documentURL}}" class="mb-0 newsfeed-title text-uppercase">[ VIEW ]</a>
            </ng-container>
            <ng-container *ngIf="newsFeedModel.documentType=='5'">
                <img src="assets/img/link@2x.png" class="play-video">
                <p class="mb-0 newsfeed-title text-uppercase">Web link</p>&nbsp;
                <a target="__blank" href="{{newsFeedModel.documentURL}}" class="mb-0 newsfeed-title text-uppercase">[ VIEW ]</a>
            </ng-container>

        </div>
        <div class="d-flex justify-content-between align-items-center mt-2">
            <h5 class="card-link news_view_detail mb-0">Audience : {{newsFeedModel.audience.name}}</h5>
            <p *ngIf="newsFeedModel.sheduledDate!=null" class="mb-0 scheduled-txt">Scheduled on {{newsFeedModel.sheduledDate+'Z' | date: 'MMM d, y, h:mm:ss a' }}</p> <!-- 29th March 2021 03:00 PM -->
        </div>
        <div class="d-flex justify-content-between align-items-center mt-2">
            <p class="mb-0">Silent news feed : 
                <ng-container *ngIf="newsFeedModel.isSilentPush">Yes</ng-container>
                <ng-container *ngIf="!newsFeedModel.isSilentPush">No</ng-container>
            </p>
        </div>
        
    <!-- </div> -->
        
    <!-- <div class="card-body news_view_body"> -->
        <!-- <div class="row">
            <div class="col-md-6"> -->
                <!-- <h4 class="card-text news_view_i">Audience shared with</h4> -->
                
            <!-- </div>
            <div class="col-md-6">
                <h4 class="card-text news_view_i">Created date</h4>
                <h5 class="card-link news_view_detail">{{ formatLocalTime(newsFeedModel.createdDate) | date: 'dd/MM/yyyy
                    hh:mm a'}}</h5>
            </div> -->
            <!-- <div *ngIf="newsFeedModel.documentURL" class="col-md-6 m_newsfeed_r">
                <h4 class="card-text news_view_i">Attachment:</h4>
                <h5 class="card-link news_view_detail">Yes <a target="__blank" href="{{newsFeedModel.documentURL}}">[
                        VIEW ]</a></h5>
            </div> -->
        <!-- </div> -->
    <!-- </div> -->

    <!-- <div class="card-body news_view_body">
        <h4 class="card-text news_view_i">Attachment type</h4>
        <h5 class="card-link news_view_detail"> <img src="{{ 'assets/img/news_image.png'}}" alt="" class="attachment-type-icon">Image <a target="__blank" href="{{newsFeedModel.documentURL}}">[ VIEW ]</a>
        </h5>
    </div> -->



    <!-- <div class="card-body news_view_body">
        <h4 class="card-text news_view_i">Created</h4>
        <h5 class="card-link news_view_detail">{{ formatLocalTime(newsFeedModel.createdDate) | date: 'dd MM yyyy hh:mm a'}}</h5>
    </div>

    <div *ngIf="newsFeedModel.documentURL" class="card-body news_view_body">
            <h4 class="card-text news_view_i">View Document</h4>
            <h5 class="card-link news_view_detail"><a target="__blank" href="{{newsFeedModel.documentURL}}"> View </a>
                
            </h5>
        </div> -->
    <!-- <div class="card-body news_view_body">
        <div class="row">
            <div class="col-md-6">
                <h4 class="card-text news_view_i">Title</h4>
                <h5 class="card-link news_view_detail">Annual Renewal Fee Offer - 5% off</h5>
            </div>
            <div class="col-md-6">
                <h4 class="card-text news_view_i">Targeted Audience</h4>
                <h5 class="card-link news_view_detail">Annual Renewal Fee Offer - 5% off</h5>
            </div>
        </div>
    </div> -->
</div>