<!-- <div class="jumbotron">
  <h5 class="display-4"><i class="fas fa-video"></i> Rooms</h5>
  <div class="list-group">
      <div class="list-group-item d-flex justify-content-between align-items-center">
          <div class="input-group">
              <input type="text" class="form-control form-control-lg"
                     placeholder="Room Name" aria-label="Room Name"
                     [(ngModel)]="roomName" (keydown.enter)="onTryAddRoom()">
              <div class="input-group-append">
                  <button class="btn btn-lg btn-outline-secondary twitter-red"
                          type="button" [disabled]="!roomName"
                          (click)="onAddRoom(roomName)">
                      <i class="far fa-plus-square"></i> Create
                  </button>

                  <button class="btn btn-lg btn-outline-secondary twitter-red"
                          type="button" [disabled]="!roomName"
                          (click)="joinRoom(roomName)">
                      <i class="far fa-plus-square"></i> Join room
                  </button>
              </div>
          </div>
      </div>
      <div  class="list-group-item d-flex justify-content-between align-items-center">
          <p class="lead">
              Add a room to begin. Other online participants can join or create rooms.
          </p>
      </div>
         
    <button class="btn btn-lg btn-outline-secondary twitter-red"
                          type="button" 
                          (click)="disconnectCall()">
                      <i class="far fa-video"></i> Disconnect
                  </button>

    <div id="preview" #preview>
        <div >Loading preview... Please wait.</div>
      </div>
    

    
    </div>

    <div #list></div>

  </div> -->
<!-- 
  <div>
    <lottie-player  src="https://assets9.lottiefiles.com/datafiles/qm9uaAEoe13l3eQ/data.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  loop  autoplay >
    </lottie-player>
  </div> -->

  <div class="modal-body" style="padding: 0px;" data-backdrop="static" data-keyboard="false">
    <div class="video_main_img" #list >
            <img *ngIf="!roomName"  src="assets/img/3-ways-to-make-your-video-interviews-better-1000x563.jpg" class="img-fluid " alt="">
            <!-- <lottie-player *ngIf="roomName" src="https://assets9.lottiefiles.com/datafiles/qm9uaAEoe13l3eQ/data.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  loop  autoplay >
        </lottie-player> -->

        <ng-container *ngIf="roomName && !hidePreview" >
                    <lottie-animation-view
                    [options]="{
                        path: 'https://assets6.lottiefiles.com/datafiles/xIr9RBwo1C68WAX/data.json',
                        renderer: 'canvas',
                        autoplay: true,
                        loop: true
                    }"
                    [width]="100"
                    [height]="365"
                    >
            </lottie-animation-view>
        </ng-container>
        

            <!-- <img *ngIf="roomName"  src="assets/img/3-ways-to-make-your-video-interviews-better-1000x563.jpg" class="img-fluid " alt=""> -->
        </div>
        <div class="video_img1"> 
            <span *ngIf="roomName"></span>
            <span *ngIf="!roomName">Please start the call ...</span>
        </div>
    <div class="video_img" id="preview" #preview> 
        <button type="button" (click)="close()" class="close close_appoint_Call" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">×</span> </button> 
        
        <!-- <img src="assets/img/entrepreneur-593358_1920.jpg" alt=""> -->
    </div>
</div>
<div class="modal-footer video_modal_footer">   
    <div class="btn-group">
        <button *ngIf="!roomName" (click)="onAddRoom()" type="button" class="btn btn-icon mr-1 btntable_appoint">START NOW</button>
        <!-- <a href="" class="startnow_call" *ngIf="!roomName" (click)="onAddRoom()" type="button" >START NOW</a> -->
         <button *ngIf="roomName" [ngClass]="{ 'btntable_active': !muteVideo }" type="button" (click)="toggleVideo()" class="btn btn-icon  mr-1 btntable_a"><i class="ft-video-off"></i></button>
        <button *ngIf="roomName" [ngClass]="{ 'btntable_active': !mutemic }" type="button" (click)="toggleMic()" class="btn btn-icon  mr-1 btntable_a"><i class="ft-mic-off"></i></button> 
        <button *ngIf="roomName" type="button" class="btn btn-icon  mr-1 btntable_ac" (click)="disconnectCall()" ><i class="icon-call-end"></i></button>
    </div>
</div>


