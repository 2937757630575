import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AudienceAddModel } from 'src/app/shared/models/audience.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AudienceService } from '../audience.service';
import { Utility } from '../../utility/utility';
import { ToastrService } from 'ngx-toastr';
import { AudienceResponse } from 'src/app/shared/models/response.model';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-create-audience',
  templateUrl: './create-audience.component.html',
  styleUrls: ['./create-audience.component.css']
})
export class CreateAudienceComponent implements OnInit {

  Isvalid:boolean;
  audienceModel : AudienceAddModel ;
  errors;
  event: EventEmitter<any> = new EventEmitter();
  data;
  title;
  buttonTitle;
  maxChars = 300;

  constructor(
    public modalRef: BsModalRef,
    private ngxService: NgxUiLoaderService,
    private audienceService: AudienceService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.Isvalid = true;
    this.audienceModel = new AudienceAddModel();
    this.audienceModel.description = ''

    if(this.data.audienceData !=null){
      this.audienceModel = JSON.parse(JSON.stringify(this.data.audienceData)); // Object.assign({}, this.data.agent);
      this.title = 'Edit Audience';
      this.buttonTitle = 'Update Now';
    }
    else {
      this.title = 'Create New Audience';
      this.buttonTitle = 'Create Now';
    }
  }

  onSubmit(form:NgForm){
    let name = this.audienceModel.name;
    let description = this.audienceModel.description;  

    if(form.invalid || (name && name.trim()=="") || (description && description.trim()=="") ) {
  
      if(name && name.trim()==""){
        form.controls['name'].setErrors({'required': true});
      }
      if(description && description.trim()==""){
        form.controls['description'].setErrors({'required': true});
      }
  
      if(form.pristine){
      }
      this.Isvalid = false;
      // console.log(form.invalid);  
      //console.log(this.Isvalid);
    }

    else{
      this.ngxService.start();
      this.Isvalid = true;

      if(this.data.audienceData !=null){
        this.audienceService.audienceEdit(this.data.audienceData.id,this.audienceModel).pipe(first())
        .subscribe((data:AudienceResponse)=>{
          this.ngxService.stop();
          if(data.error && !data.success){      
            this.toastr.error('Error on updating audience.');
          }
          else{
            this.toastr.success('Audience updated successfully.');
            this.event.emit('OK');
            this.modalRef.hide();
      
            // this.router.navigate(['agents']);
          }
        },err=>{   
          this.ngxService.stop();
          this.toastr.error('Error on updating audience.');
        });
      }  
      else {
        this.audienceService.addAudience(this.audienceModel)
        .subscribe((data:AudienceResponse)=>{
          this.ngxService.stop();
          if(data.error && !data.success){
            this.errors = Utility.GetLocalErrorString(data.error.errorCode);
            //console.log(this.errors);
          }
          else{
            this.toastr.success('Audience added successfully.');
            this.event.emit('OK');
            this.modalRef.hide();
          }
          
        },err=>{   
          this.ngxService.stop();
          this.toastr.error('Error on saving audience.');
        });
      }      
    }
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }
  
  ClosePopup(){
    this.modalRef.hide();
  }

}
