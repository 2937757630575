export class Permission {
    public  id : string; 
    public  name : string;
    public  description : string;
}

export class PermissionAddModel extends Permission{
  checked: unknown;

}

export class ClientGetDataModel extends Permission{

    public  roleId:string;
}