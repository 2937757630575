<app-header></app-header>
<app-sidebar></app-sidebar>


<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                <h3 class="content-header-title mb-0 d-inline-block">Businesses</h3>
                <div class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/dashboard">Home</a> </li>
                            <li class="breadcrumb-item active">Businesses </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12">
                <div class="btn-group float-md-right">
                    <button class="btn appointment_btn btn-sm border_all_radius"  data-toggle="modal" data-target="#new_business">Create New</button>
                </div>
                <div class="modal fade text-left" id="new_business" tabindex="-1" role="dialog" aria-labelledby="myModalLabel1" aria-hidden="true">
                  <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                          <div class="modal-header a_modal">
                              <h4 class="modal-title" id="myModalLabel1">New Business</h4>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                          </div>
                          <div class="modal-body dallecort_modal-body">
                              <div class="row">
                                  <div class="col-md-12">
                                      <div class="form-body">
                                          
                                          <div class="row add_agent_ga" style="margin-top: 5px;">
                                              <div class="col-md-12">
                                                  <div class="form-group">
                                                      <label for="projectinput1" class="c_n_fs">Business Name *</label>
                                                      <input autocomplete="off" type="email" autocomplete="none" required class="form-control" id="email" name="email" placeholder="Business name">
                                                  </div>
                                              </div>    
                                              <span class="add_agent_g ml-1 mb-1 mt-2">Sub Categories</span>                                          
                                              <div class="col-md-12">
                                                  <div class="form-group mb-0" style="padding-top: 4px;">
                                                      <label for="status" class="c_n_fs"></label>
                                                      <label class="container"> 
                                                        <input type="checkbox" class="check_mt"/>Notice Board
                                                        <span class="checkmark" style="top: 4px; left: 15px;"></span>
                                                      </label>
                                                  </div>
                                              </div>
                                              <div class="col-md-12">
                                                  <div class="form-group mb-0" style="padding-top: 4px;">
                                                      <label for="status" class="c_n_fs"></label>
                                                      <label class="container"> 
                                                        <input type="checkbox" class="check_mt"/>Documents
                                                        <span class="checkmark" style="top: 4px; left: 15px;"></span>
                                                      </label>
                                                  </div>
                                              </div>
                                              <div class="col-md-12">
                                                  <div class="form-group mb-0" style="padding-top: 4px;">
                                                      <label for="status" class="c_n_fs"></label>
                                                      <label class="container"> 
                                                        <input type="checkbox" class="check_mt"/>List
                                                        <span class="checkmark" style="top: 4px; left: 15px;"></span>
                                                      </label>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="modal-footer a-footer">
                              <button type="submit" class="btn popup_save_b">Save</button>
                          </div>
                      </div>
                  </div>
              </div>
                
            </div>
        </div>        

        <div class="content-body">
            <div class="row">
                    <div class="col-12 col-md-12 ">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Businesses List</h4>
                                </div>
                                <div class="card-content">
                                    <div class="table-responsive scroll-tbl_newsfeed-">
                                        <!-- <table id="recent-orders" class="table table-hover table-xl mb-0"> -->
                                        <table class="table table-striped table-bordered zero-configuration t_dt" style="margin-top: 20px !important;">
                                            <thead>
                                                <tr>
                                                    <th class="border-top-0">Name</th>
                                                    <th class="border-top-0"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                  <td class="text-truncate">Dallecort Financial Services</td>
                                                  <td class="sa_btns">
                                                    <div class="btn-group">
                                                      <button type="button" class="btn btn-icon mr-1 btn_trash"><i class="la la-trash"></i></button>
                                                      <button type="button" class="btn btn-icon newsbtn"><i class="la la-pencil"></i></button>
                                                    </div>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td class="text-truncate">Austax</td>
                                                  <td class="sa_btns">
                                                    <div class="btn-group">
                                                      <button type="button" class="btn btn-icon mr-1 btn_trash"><i class="la la-trash"></i></button>
                                                      <button type="button" class="btn btn-icon newsbtn"><i class="la la-pencil"></i></button>
                                                    </div>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td class="text-truncate">My Fortress</td>
                                                  <td class="sa_btns">
                                                    <div class="btn-group">
                                                      <button type="button" class="btn btn-icon mr-1 btn_trash"><i class="la la-trash"></i></button>
                                                      <button type="button" class="btn btn-icon newsbtn"><i class="la la-pencil"></i></button>
                                                    </div>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td class="text-truncate">My Mortgage Partner</td>
                                                  <td class="sa_btns">
                                                    <div class="btn-group">
                                                      <button type="button" class="btn btn-icon mr-1 btn_trash"><i class="la la-trash"></i></button>
                                                      <button type="button" class="btn btn-icon newsbtn"><i class="la la-pencil"></i></button>
                                                    </div>
                                                  </td>
                                                </tr>
                                                
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>






                
            </div>
            
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-body text-center" >

        <img  src="assets/img/warning.png" alt="" class="alert_img">
        <p>{{message}}</p>
          
        <div class="form-actions center mb-1">
            <button type="button" class="btn alert_no_b mr-1" (click)="decline()"> 
                No
            </button>
            <button type="button" class="btn alert_yes_b" (click)="confirm()" >
                 Yes
            </button>
        </div>
    </div>
</ng-template>

<app-footer></app-footer>