import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FacFinderService } from '../fac-finder.service';
import { XFacRetirementIncomeResponse } from 'src/app/shared/models/response.model';
import { XFacRetirementIncomeAddModel } from 'src/app/shared/models/xfac-retirementIncome.model';

@Component({
  selector: 'app-add-retirement-income',
  templateUrl: './add-retirement-income.component.html',
  styleUrls: ['./add-retirement-income.component.css']
})
export class AddRetirementIncomeComponent implements OnInit {
  data;
  errors;
  addRetirementIncomeForm: FormGroup;
  retirementIncomeAddModel : XFacRetirementIncomeAddModel;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  buttonText: string ="Add New Asset";
  retirementIncomePlanProviderList : any = [];
  retirementIncomePlanSelectionList : any = [];
  assetSubTypeList : any = [];
  facFinderId : any;
  clientCode : any;
  title: any;


  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private facFinderService: FacFinderService,
    public modalRef: BsModalRef,
    private toastr: ToastrService
  ) { }
  
  ngOnInit() {
    this.Isvalid = true;    
    this.retirementIncomeAddModel = new XFacRetirementIncomeAddModel(); 
    this.retirementIncomeAddModel.retirementPlan ="000"; 
    //this.retirementIncomeAddModel.retirementPlanSelection ="000"; 

    if(this.data.retirementIncome !=null){
      //console.log(this.data.library);
      this.buttonText = "Update Now";
      Object.assign(this.retirementIncomeAddModel, this.data.retirementIncome);
    }

    // asset_owner_list : this.asset_owner_list,
    //     asset_type : this.asset_type,
    //     asset_sub_type : this.asset_sub_type,

    if(this.data.retirement_income_plan_provider_list !=null){
      Object.assign(this.retirementIncomePlanProviderList, this.data.retirement_income_plan_provider_list);
      this.retirementIncomePlanProviderList.sort((n1,n2) => {
        if (n1.value > n2.value) {
            return 1;
        }
    
        if (n1.value < n2.value) {
            return -1;
        }
    
        return 0;
    });
    }

    if(this.data.retirement_plan_selection_list !=null){
      Object.assign(this.retirementIncomePlanSelectionList, this.data.retirement_plan_selection_list);
    }

    if(this.data.facFinderId !=null){
      this.facFinderId = this.data.facFinderId;
    }

    if(this.data.clientCode !=null){
      this.clientCode = this.data.clientCode;
    }

    this.title = this.data.title;
    
    // if(this.data.business_id !=null){
    //   this.business_id = this.data.business_id;
    // }

    this.addRetirementIncomeForm = this.formBuilder.group({
      retirementPlan: ['', Validators.required],
      referenceNumber: ['', Validators.required],
      pensionBalance: ['', Validators.required],
      //retirementPlanSelection: ['', Validators.required],
    });
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

  get f() { return this.addRetirementIncomeForm.controls; }

  onSubmit(){
    this.submitted = true;
   
    let retirementPlan = this.retirementIncomeAddModel.retirementPlan;
    //let retirementPlanSelection = this.retirementIncomeAddModel.retirementPlanSelection;
   
    if (this.addRetirementIncomeForm.invalid || (retirementPlan && retirementPlan.trim()=="000") ) { //|| (title && title.trim()=="")
   
      if(retirementPlan && retirementPlan.trim()=="000"){
        this.addRetirementIncomeForm.controls['retirementPlan'].setErrors({'required': true});
      }

      // if(retirementPlanSelection && retirementPlanSelection.trim()=="000"){
      //   this.addRetirementIncomeForm.controls['retirementPlanSelection'].setErrors({'required': true});
      // }

      console.log(this.f);
      console.log(this.addRetirementIncomeForm.invalid);
      return;
    }
   
    else{
      //this.folderModel.name = title.trim();
      this.retirementIncomeAddModel.pensionBalanceCode = 'AUD';

      if(this.data.retirementIncome !=null){
      
        this.ngxService.start();
        this.retirementIncomeAddModel.clientCode = this.clientCode;
        this.facFinderService.editRetirementIncome(this.retirementIncomeAddModel, this.facFinderId)
        .subscribe((data:XFacRetirementIncomeResponse)=>{
        this.ngxService.stop();
        if(data.error && !data.success){      
          this.toastr.error('Error on updating retirement income.');      
        }
        else{
          this.toastr.success('Retirement income updated successfully.');
          this.event.emit('OK');
          // console.log(this.event);
          this.modalRef.hide();
        }
        
        },err=>{   
          console.log(err);
          this.ngxService.stop();
          this.toastr.error('Error on updating retirement income.');
        });
          
          //this.router.navigate(['agents']);
          
      
      }else{
        
        this.ngxService.start();
        this.retirementIncomeAddModel.clientCode = this.clientCode;
        this.facFinderService.addRetirementIncome(this.retirementIncomeAddModel, this.facFinderId)
        .subscribe((data:XFacRetirementIncomeResponse)=>{
        this.ngxService.stop();
        if(data.error && !data.success){      
          this.toastr.error('Error on saving retirement income.');      
        }
        else{
          this.toastr.success('Retirement income added successfully.');
          this.event.emit('OK');
          // console.log(this.event);
          this.modalRef.hide();
        }
        
        },err=>{   
          console.log(err);
          this.ngxService.stop();
          this.toastr.error('Error on saving retirement income.');
        });
    
      }
    
    }
   
  }

}
