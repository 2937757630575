export class XFacAssetBaseMOdel {
    public  facFinderId : string;
    public  type : string;
    public  description : string;
    public  status : string;
    public  policyNumber : string;
    public  currentValue : string;
    public  subType : string;
    public  clientCode : string;
    public  currentValueLastUpdatedOn : string;
    public  ownerType : string;
    public  assetTypeName : string;
    public  assetSubTypeName : string;
    public  currentValueCode : string;
}

export class XFacAssetAddModel extends XFacAssetBaseMOdel{    
       
}