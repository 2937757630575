import { Component, OnInit } from '@angular/core';
import { MessagecenterService } from '../message-center/messagecenter.service';
//import { SidebarService } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader'; 
import { AuthService } from '../auth/auth.service';
import { User } from '../shared/models/user.model';
import * as jwt_decode from 'jwt-decode';
import { AppConstants } from '../utility/app-constants';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  isCollapsed = false;
  isCollapsed1 = false;
  newMsgReceived = false;
  active :string ="";
  currentUser: User;
  permission:any;
  portalUrl:any;
  businessName: string = '';
  viewNewsFeed: boolean;
  
  //private sidebarService: SidebarService, 
  constructor(private ngxService: NgxUiLoaderService, private messagecenterService: MessagecenterService,private router: Router, private authService: AuthService, ) {
    this.currentUser = this.authService.getCurrentUserValue;
    this.businessName = AppConstants.getBusinessName();
    this.viewNewsFeed = this.viewPortalPermission('View_News_Feed');  //View_Staff_Clients
   }

  ngOnInit() {
    
    this.portalUrl = AppConstants.getStaffPortalURL();
    //this.ngxService.start();
    this.permission = this.getPermission();

    this.redirectIfNoPermission('ViewMyFortressAdmin');
    //console.log(this.router.url);
    if(this.router.url!="/message-center"){
      this.messagecenterService.getConversationNewMsg().subscribe(conv => { 
        //console.log('New message received -----');
        this.newMsgReceived = true;  
      });

      // this.sidebarService.showPreloader().subscribe(show => { 
      //   console.log('Show preloader -----'+show );
      //   if(show == true){
      //     //this.ngxService.start();
      //     this.ngxService.startLoader('loader-01');
      //     //this.spinner.show();
      //   }
      //   else{
      //     this.ngxService.stop();
      //     //this.spinner.hide();
      //   }        
      // });

      //this.sidebarService.preloaderAction(true);
      
    }
    
    if (this.router.url.indexOf('/client') > -1) {
      this.active = "open";
    }
    if (this.router.url.indexOf('/fact-finder-v2') > -1) {
      this.active = "open";
    }

  }

  clearPagination() {
    sessionStorage.removeItem('currentPage');
  }

  onEvent() {
    this.isCollapsed = !this.isCollapsed;
    return false;
  }


  getPermission() {
    let token = this.authService.userToken;
    const decoded = jwt_decode(token);
    //console.log(decoded);
    if (decoded.scope === undefined || decoded.scope=="") return null;
    return decoded.scope;
  }

  // viewPortalPermission(permission){
  //   return this.permission.find(x => x == permission)
  // }

  onEvent1() {
    this.isCollapsed1 = !this.isCollapsed1;
    return false;
  }
  
  viewPortalPermission(permission){
    return this.authService.viewPortalPermission(permission);
  }

  // redirectIfNoPermission(permission){
  //   // console.log("+++++permission sidebar----");
  //   // console.log(permission);
  //   let per = this.authService.viewPortalPermission(permission);
  //   // console.log("+++++++per----");
  //   // console.log(per);
  //   if(!per){      
  //     window.location = this.portalUrl;
  //   }
  // }

  redirectIfNoPermission(permission){
    let token = this.authService.userToken;

    let business_id = JSON.parse(localStorage.getItem('businessId'));
    const decoded = jwt_decode(token);
 
    if (decoded === undefined || decoded.scope=="") return null;
    let token_decoded = decoded;
    if (token_decoded[permission] === undefined || token_decoded[permission]=="") return null;   
    let menuList = JSON.parse(localStorage.getItem('businessList'));
    if (!menuList) return null;
    let item = menuList.find(x => x.id == business_id);
    if (!item) return null;
    if( typeof token_decoded == 'string') return null;
    let search = token_decoded[permission].split(",");
    
    let per = search.find(x => parseInt(x.trim()) == item.code);
    // console.log("+++++permission sidebar----");
    // console.log(permission);
    // console.log("+++++++per----");
    // console.log(per);
    if(!per){      
      window.location = this.portalUrl;
    }
    
  }

}
