import { Component, OnInit, Input, PipeTransform, Pipe } from '@angular/core';

// import { Subject } from 'rxjs/Subject';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators/switchMap';
import { map } from 'rxjs/operators/map';
import { windowCount } from 'rxjs/operators/windowCount';
import { scan } from 'rxjs/operators/scan';
import { take } from 'rxjs/operators/take';
import { tap } from 'rxjs/operators/tap';
import { from } from 'rxjs/observable/from';
import { merge } from 'rxjs/observable/merge';

import { ChatModule, Message, User, Action, ExecuteActionEvent, SendMessageEvent,Attachment  } from '@progress/kendo-angular-conversational-ui';
import { Observable } from 'rxjs/Observable';
import { ChatService } from 'src/app/chat.service';
import { interval } from 'rxjs/observable/interval';
import { data } from 'jquery';
import { MessagecenterModel, ChatModel, SendChatModel } from '../shared/models/messagecenter.model';
import { MessagecenterService } from '../message-center/messagecenter.service';
import { ChatListResponse, ChatResponse, DocumentUrlResponse } from '../shared/models/response.model';
//import { AuthService } from '../auth/auth.service';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import "rxjs/add/operator/catch";
import { NewsFeedService } from '../news-feed/news-feed.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {SendFileComponent} from './send-file/send-file.component';
import { CookieService } from 'ngx-cookie-service';


@Component({
  providers: [ ChatService ],
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  title = 'kendo-chat';
  @Input() conversation: MessagecenterModel;
  
  attachments: Attachment[];

  public feed: Observable<Message[]>;
  private local: Subject<Message> = new Subject<Message>();
  chatList : any;
  message : Message;
  sendChat : SendChatModel;
  conversationId :string;
  userList : any;
  transform : PipeTransform;
  modalRef: BsModalRef;
  //currentUser: User;
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  CurrentUserId = this.cookieService.get('CurrentUserId'); //JSON.parse(localStorage.getItem('CurrentUserId'));
  public responses: Subject<any> = new Subject<any>();

  public readonly user : User = {
    id: this.CurrentUserId,                      // Required field
    name: this.currentUser.userName, //this.currentUser.name,               // Optional
    avatarUrl: 'assets/profile1.png'   // Optional
  };

  public  bot: User = {
    id: 0,                      // Required field
    name: 'Jane',               // Optional
    avatarUrl: 'assets/profile2.png'   // Optional
  };

  constructor(
      private svc: ChatService,
      private messagecenterService: MessagecenterService,
      private http: HttpClient,
      private newsFeedService : NewsFeedService,
      private modalService: BsModalService,
      private cookieService: CookieService
      //private authService: AuthService
    ) {
    const hello: Message = {
      author: this.bot,
      // suggestedActions: [{
      //   type: 'reply',
      //   value: 'Neat!'
      // }, {
      //   type: 'reply',
      //   value: 'Thanks, but this is boring.'
      // }],
      timestamp: new Date(),
      text: 'aaa'
    };

    // Merge local and remote messages into a single stream
    this.feed = merge(
      //from([ hello ]),
      this.local,
      this.svc.responses.pipe(
        map((response): Message => ({
          author: this.bot,
          text: response,
          timestamp: new Date()
        })
      ))
    ).pipe(
      // ... and emit an array of all messages
      scan((acc:any, x) => acc.concat(x), [])
    );

    this.feed = merge(
      //from([ hello ]),
      this.local,
      this.messagecenterService.received_push.pipe(

        map((response): Message => ({
          author: response.author,
          text: response.text,
          timestamp: response.timestamp,
          attachments: response.attachments
        }))

        

      )
    ).pipe(
      // ... and emit an array of all messages
      scan((acc:any, x) => acc.concat(x), [])
    );

    //this.currentUser = this.authService.getCurrentUserValue;
  }

  public sendMessage(conversation, e: SendMessageEvent): void {
    let msg = e.message.text.trim();

    if(msg && msg != null){

      //console.log();

      this.local.next(e.message);

      // this.local.next({
      //   author: this.bot,
      //   typing: true
      // });
  
      let chaaaat= new SendChatModel();
      chaaaat.conversationId = conversation.id;
      chaaaat.documentURL = null;
      chaaaat.version ="v1";
      chaaaat.text = e.message.text;
      chaaaat.senderId = this.CurrentUserId;
      chaaaat.messageType = "1";
      //console.log(chaaaat);
  
      this.messagecenterService.submit(chaaaat, e.message.text).subscribe((data:ChatResponse)=>{});
      
    }
    
  }
  
  ngOnInit() {
    this.getConversationChatList(this.conversation);
  }

  getConversationChatList(conversation : any) {
    let id = this.cookieService.get('CurrentUserId');//JSON.parse(localStorage.getItem('CurrentUserId'));
    this.messagecenterService.getConversationChatList(conversation).subscribe((data:ChatListResponse)=>{     
      this.chatList = data.response;
      this.conversationId = conversation.id;
      this.userList = conversation.recivers;

      data.response.forEach(chat => {
        let send = this.userList.find(x => x.id == chat.senderId);
        //let chat_list :any;

        let profImage;
        if(send.profileImageUrl !=null && (send.profileImageUrl.match(/\.(jpeg|jpg|gif|png)$/) != null) ){
          profImage = send.profileImageUrl;
        }
        else{
          profImage = 'assets/profile2.png';
        }

       // profImage = 'assets/profile2.png';

        let sender: User = { 
          id: send.id,                      
          name: send.userName,               
          avatarUrl: profImage  //'assets/profile2.png'
        };

        if(chat.messageType == "1"){
          this.local.next({
            author: sender,
            text: chat.text,
            timestamp: new Date(chat.createdDate+'Z'),
            attachments: null  
          });
        }
        else{
          //let attachments;

          if(chat.messageType == "2"){
            this.attachments = [{
              content: chat.documentURL,
              contentType :'image'  // pdf doc
            }]; 
          }
          else if(chat.messageType == "3"){
            this.attachments = [{
              content: chat.documentURL,
              contentType :'pdf'  // pdf doc
            }]; 
          }
          else if(chat.messageType == "4"){
            this.attachments = [{
              content: chat.documentURL,
              contentType :'doc'  // pdf doc
            }]; 
          }

          this.local.next({
            author: sender,
            text: chat.text,
            timestamp: new Date(chat.createdDate+'Z'),
            attachments: this.attachments  
          });
        }
        

      });

      //console.log(this.responses);

      console.log('chat list for conv ---------');
      console.log(this.chatList);
    });
  }

  attachmentModalOpen(){
      this.modalRef = this.modalService.show(SendFileComponent,  {
        initialState: {
          title: 'Send attachment',
          data: {},
        },      
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true,
        class: "modal-lg"
      });
    
      this.modalRef.content.event.subscribe(result => {

        console.log(result);
        
        if (result.success) {
          let fileUrl = result.s3Key;

          this.attachments = [{
            content: fileUrl,
            contentType :'file'  // pdf doc
          }]; 

          console.log(this.attachments);

          let chaaaat= new SendChatModel();
          chaaaat.conversationId = this.conversation.id;
          chaaaat.documentURL = fileUrl;
          chaaaat.version ="v1";
          chaaaat.text = null;
          chaaaat.senderId = this.CurrentUserId;
          

          if(result.documentType ==1 || result.documentType ==2){
            chaaaat.messageType = "2";
          }
          else if(result.documentType ==3){
            chaaaat.messageType = "3";
          }
          else if(result.documentType ==4){
            chaaaat.messageType = "4";
          }

          console.log(chaaaat);

          this.messagecenterService.submit(chaaaat, null).subscribe((data:ChatResponse)=>{
            
          });

          this.local.next({
            author: this.user,
            text: null,
            timestamp: new Date(),
            attachments: this.attachments  
          });
          //console.log("Success");
            //this.getNewsFeedList();
        
        }
  
      });
    }

  viewAttachment(attachment : string){
    let getFileUrl;
    getFileUrl = {documentURL:attachment, documentType :2};
    this.newsFeedService.getDocumentUrl(getFileUrl).subscribe((data:DocumentUrlResponse)=>{     
      let documentUrl = data.response;      
      console.log(' documentUrl ---------');
      console.log(documentUrl);
      window.open(documentUrl, "_blank"); 
      },
      (err: HttpErrorResponse)=>{
        console.log("Error"+ err.message);
      });      
  }

}
