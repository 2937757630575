import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FacFinderService } from '../fac-finder.service';
import { XFacContactAddModel } from 'src/app/shared/models/xfac-contact.model';
import { XFacContactResponse, XFacAddressResponse, XFacInsuranceLifeResponse } from 'src/app/shared/models/response.model';
import { XFacAddressAddModel } from 'src/app/shared/models/xfac-address.model';
import { XFacInsuranceLifeAddModel } from 'src/app/shared/models/xfac-InsuranceLife.model';

@Component({
  selector: 'app-add-insurance',
  templateUrl: './add-insurance.component.html',
  styleUrls: ['./add-insurance.component.css']
})
export class AddInsuranceComponent implements OnInit {

  title;
  data;
  errors;
  addInsuranceForm: FormGroup;
  insuranceAddModel : XFacInsuranceLifeAddModel;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  buttonText: string ="Add New Contact Details";
  assetOwnerTypeList : any = [];
  facFinderId : any;
  clientCode : any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private facFinderService: FacFinderService,
    public modalRef: BsModalRef,
    private toastr: ToastrService
    ) { }
 
  ngOnInit() {
    this.Isvalid = true;    
    this.insuranceAddModel = new XFacInsuranceLifeAddModel(); 
    this.insuranceAddModel.policyOwners ="000"; 

    if(this.data.insurance !=null){
      //console.log(this.data.library);
      this.buttonText = "Update Now";
      Object.assign(this.insuranceAddModel, this.data.insurance);
    }

    if(this.data.asset_owner_type_list !=null){
      Object.assign(this.assetOwnerTypeList, this.data.asset_owner_type_list);
    }

    if(this.data.facFinderId !=null){
      this.facFinderId = this.data.facFinderId;
    }

    if(this.data.clientCode !=null){
      this.clientCode = this.data.clientCode;
    }

    this.title = this.data.title;
    
    // console.log(this.clientCode);
    // if(this.data.business_id !=null){
    //   this.business_id = this.data.business_id;
    // }

    this.addInsuranceForm = this.formBuilder.group({
      planName: ['', Validators.required],
      policyNumber: ['', Validators.required],
      totalPremium: ['', Validators.required],
      policyOwners: ['', Validators.required],
      // state: ['', Validators.required],
    });

  }

  get f() { return this.addInsuranceForm.controls; }

onSubmit(){
 this.submitted = true;

 let policyOwners = this.insuranceAddModel.policyOwners;

 if (this.addInsuranceForm.invalid || (policyOwners && policyOwners.trim()=="000") ) { //|| (addressType && addressType.trim()=="000")

  if(policyOwners && policyOwners.trim()=="000"){
    this.addInsuranceForm.controls['policyOwners'].setErrors({'required': true});
  }
  console.log(this.f);
  console.log(this.addInsuranceForm.invalid);
  return;
}

else{
  //this.folderModel.name = title.trim();
  this.insuranceAddModel.totalPremiumCode = 'AUD';

  if(this.data.insurance !=null){
  //   this.ngxService.start();
  // //console.log("Edditre");

    this.ngxService.start();
    this.insuranceAddModel.clientCode = this.clientCode;
    this.facFinderService.editInsurance(this.insuranceAddModel, this.facFinderId)
    .subscribe((data:XFacInsuranceLifeResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){      
      this.toastr.error('Error on updating insurance.');      
    }
    else{
      this.toastr.success('Insurance updated successfully.');
      this.event.emit('OK');
    // console.log(this.event);
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on updating insurance.');
    });
      
      //this.router.navigate(['agents']);
      

  }else{
    
    this.ngxService.start();
    this.insuranceAddModel.clientCode = this.clientCode;
    this.facFinderService.addInsurance(this.insuranceAddModel, this.facFinderId)
    .subscribe((data:XFacInsuranceLifeResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){      
      this.toastr.error('Error on saving insurance.');      
    }
    else{
      this.toastr.success('Insurance added successfully.');
      this.event.emit('OK');
     // console.log(this.event);
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on saving insurance.');
    });

  }

}

}

modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

onReset() {
  this.submitted = false;
  this.addInsuranceForm.reset();
}

}
