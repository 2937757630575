export class UnVerifiedUsersModel {
    public  id : string;
    public  accessCode: string;
    public  isClient : boolean;
    public isAdmin : boolean;
    public userName : string;
    public userType : number;
    public clientCode : string;
    public xeppoCode : string;
    public profileImageUrl : string;
    public primaryMobile : string;
    public isArchived : boolean;
    public client : string;
    public fortressAdmin : string;

}

export class UnVerifiedUsersListModel extends UnVerifiedUsersModel{    
       
}