import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientNewAddModel } from "../../shared/models/client.model";
import { UserVerifyService } from '../../user-verify/user-verify.service';
import { UnVerifiedUsersResponse2} from '../../shared/models/response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-add-primary-mobile',
  templateUrl: './add-primary-mobile.component.html',
  styleUrls: ['./add-primary-mobile.component.css']
})
export class AddPrimaryMobileComponent implements OnInit {
  data;
  errors;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  addInsuranceForm: FormGroup;
  submitted1 = false;
  clientModel: ClientNewAddModel;
   
  userId:any;
  mobileNumber:any;
  title;

  constructor(
    public modalRef: BsModalRef, 
    private formBuilder: FormBuilder,
    private UserVerifyService: UserVerifyService,
    private toastr: ToastrService, 
    private ngxService: NgxUiLoaderService, 
  ) { }

  ngOnInit() {
    this.Isvalid = true; 
    this.clientModel = new ClientNewAddModel();

    if(this.data.client !=null){
      Object.assign(this.clientModel, this.data.client);
    }

    this.title = 'Client Account Approve'; //this.data.title;

    this.addInsuranceForm = this.formBuilder.group({
      primaryMobile: ['', Validators.required],      
    });
  }

  onSubmit(form:NgForm){
    if(form.invalid) {

      if(form.pristine){
      }
      this.Isvalid = false;
    }
    else{
      if(this.data.client !=null){
        this.userId = this.data.client.id;
        this.mobileNumber = form.value.primaryMobile.replace('+', '');
        
        this.ngxService.start();
        this.UserVerifyService.updateMobileNumber(this.userId, this.mobileNumber).subscribe((data:UnVerifiedUsersResponse2)=>{ 
        
          if(data.error && !data.success){
            this.ngxService.stop();
            if(data.error.errorCode == 2007){
              this.toastr.error('Primary mobile number already assigned.');
            }
            else {
              this.toastr.error('Error on saving primary mobile number.');
            }
          }
          else{
              this.toastr.success('Primary mobile number saved successfully.');
              this.event.emit('OK');
              this.modalRef.hide();    
              //this.unVerifiedUsersAddModel.primaryMobile = this.mobileNumber;              
          } 
        },(err: HttpErrorResponse)=>{   
          this.ngxService.stop();
          this.toastr.error('Error on saving mobile number.');
        });
      }
      else{
        this.ngxService.stop();
        this.toastr.error('Error on saving mobile number.');
      }
    }
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

}
