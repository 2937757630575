import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ServiceAgreementAddModel, ServiceAgreementListModel } from '../shared/models/service-agreement.model';
import { ServiceAgreementService } from './service-agreement.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DocumentUrlResponse, ServiceAgreementResponse, ServiceAgreementResponseProfile } from 'src/app/shared/models/response.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UserDocumentsService } from '../user-documents/user-documents.service';
import { ViewDocumentComponent } from './view-document/view-document.component';
import { ViewServiceAgreementComponent } from './view-service-agreement/view-service-agreement.component';
import { NewServiceAgreementComponent } from '../profile/new-service-agreement/new-service-agreement.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-service-agreement',
  templateUrl: './service-agreement.component.html',
  styleUrls: ['./service-agreement.component.css']
})
export class ServiceAgreementComponent implements OnInit, OnDestroy {

  isLoaded :boolean;
  showBoundaryLinks: boolean = true;
  showDirectionLinks: boolean = true;
  totalRecordsCount: number;
  currentPage: number;
  itemsPerPage: number = 10;
  serviceAgreementList:ServiceAgreementListModel[];
  dtOptions: DataTables.Settings = {};
  serviceAgreementId:string;
  modalRef: BsModalRef;
  message:string;
  success: boolean;
  loaded: boolean;

  public skip: number;
  public take: number;
  fdsAgreementStatus: string;
  

  constructor(
    private serviceAgreementService: ServiceAgreementService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private userDocumentService: UserDocumentsService,
    private ngxService: NgxUiLoaderService
  ) { 
    window.addEventListener('storage',()=> {
      const data = sessionStorage.getItem('closeModal')
      const comp = sessionStorage.getItem('loadedComponent') === 'allSA'
      if (comp && data) {
        this.serviceAgreementId = sessionStorage.getItem('serviceAgreementId')
        if(!this.success) {
          this.confirmSendToClients(3);
          this.success = true;
        }
      }
    })
  }

  ngOnInit(): void {
    sessionStorage.setItem('loadedComponent', 'allSA')
    this.getServiceAgreementList();
    this.dtOptions = {
      paging: false,
      info: false,
      processing: false,
      order : [],
    };
  }

  getServiceAgreementList(event?: PageChangedEvent) {
    this.isLoaded = false;
    this.currentPage = event?.page || 1;
    this.skip = (event?.page-1) * event?.itemsPerPage || 0;
    this.take = event?.itemsPerPage || this.itemsPerPage; // Default records count

    this.getServiceAgreements();
  }

  public openDeleteDialog(template: TemplateRef<any>,newsFeedId, fdsAgreementStatus: string) {

    this.serviceAgreementId = newsFeedId;
    this.fdsAgreementStatus = fdsAgreementStatus;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    //this.idToBeDeleted = id;

    this.message = 'Are you sure that you want to delete Fee Disclosure Statement?'
  }

  decline(): void {
    this.modalRef.hide();
  }

  confirm(): void {
    this.loaded = true;
    //this.message = 'Confirmed!';
    this.modalRef.hide();
    this.ngxService.start();
    if(this.fdsAgreementStatus != "4" && this.fdsAgreementStatus != "3") {
      this.serviceAgreementService.deleteServiceAgreemen(this.serviceAgreementId).subscribe((data)=>{
        this.getServiceAgreementList();
        this.ngxService.stop();
        // this.router.navigate(['news-feed']);
        if(data.success) {
          this.toastr.success('Fee Disclosure Statement deleted successfully.');
        } else {
          this.toastr.error('Error on deleting Fee Disclosure Statement.');
        }
        this.loaded = false;
      },
      (err: HttpErrorResponse)=>{
        this.ngxService.stop();
        this.loaded = false;
        //console.log("Error"+ err.message);
      });
    } else {
      this.serviceAgreementService.voideServiceAgreemen(this.serviceAgreementId).subscribe((data)=>{
        this.getServiceAgreementList();
        this.ngxService.stop();
        // this.router.navigate(['news-feed']);
        if(data.success) {
          this.toastr.success('Fee Disclosure Statement cancelled successfully.');
        } else {
          this.toastr.error('Error on cancelling Fee Disclosure Statement.');
        }
        this.loaded = false;
      },
      (err: HttpErrorResponse)=>{
        this.ngxService.stop();
        this.loaded = false;
        //console.log("Error"+ err.message);
      });
    }

    //this.delete();
  }

  openSendToClientsDialog(template: TemplateRef<any>,newsFeedId) {
    sessionStorage.removeItem('closeModal');
    sessionStorage.removeItem('serviceAgreementId');
    sessionStorage.setItem('serviceAgreementId', newsFeedId)

    this.serviceAgreementId = newsFeedId;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    //this.idToBeDeleted = id;

    this.message = 'Are you sure that you want to send Fee Disclosure Statement to client?'
  }

  confirmSendToClients(statusId) {
    this.modalRef.hide();
    sessionStorage.removeItem('closeModal')
    this.serviceAgreementService.setFDStatus(this.serviceAgreementId, statusId).subscribe((data)=>{
      sessionStorage.removeItem('serviceAgreementId')
      this.getServiceAgreements();
      // this.router.navigate(['news-feed']);
      if(data.success) {
        this.toastr.success('Fee Disclosure Statement send to client successfully.');
      } else {
        this.toastr.error('Error on sending Fee Disclosure Statement to client.');
      }
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });
  }

  getServiceAgreements() {
    this.serviceAgreementService.serviceAgreementList(this.skip, this.take).subscribe( (data: ServiceAgreementResponse) => {
      this.serviceAgreementList = data.response.fdsList;
      // this.returnedNewsFeedList = this.serviceAgreementList;
      this.totalRecordsCount = data.response.totalCount;
      this.isLoaded = true;
    }, (err: HttpErrorResponse) => {
    });
  }

  sendToClients(serviceAgreementData) {
    this.success = false;
    sessionStorage.setItem('serviceAgreementId', serviceAgreementData.id)
    this.ngxService.start();
    this.loaded = true;
    this.serviceAgreementService.getEnvelopEditUrl(serviceAgreementData.id, serviceAgreementData.envelopeID).subscribe((res: any) => {
      if(res.success) {
        const url = res.response
        this.modalRef = this.modalService.show(NewServiceAgreementComponent,  {
          initialState: {
            title: 'Documents To Action',
            data: {
              url: url
            },
            keyboard: false,
            backdrop: true,
            ignoreBackdropClick: true
          },
          class: "modal-xl modal-xlg",
        });
  
        this.modalRef.content.event.subscribe(result => {
          if (result == 'OK') {      
          }
        });
      } else {
        this.modalRef.hide();
      }
      this.ngxService.stop();
      this.loaded = false;
    }, err => {
      this.loaded = false;
      this.ngxService.stop();
    })
  }


  findUser(data) {
    let fdsRecipients = data.fdsRecipients
    let fdsRecipient = fdsRecipients.find(x => x.xeppoCode ===  data.xeppoCode);
    if(fdsRecipient) {
      return fdsRecipient.name;
    } else { return '--'}
    
  }

  viewDocumentList(serviceAgreement:any){
    //console.log(newsFeed);
    let userId = serviceAgreement.fdsRecipients[0].userId
    let serviceDocuments = serviceAgreement.fdsServiceDocuments
    this.modalRef = this.modalService.show(ViewDocumentComponent,  {
      initialState: {
        title: 'Download PDF',
        data: {
          serviceDocuments:serviceDocuments,
          userId:userId,
        },
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
      },
      class: "modal-lg_newsfeed",


    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
          //this.getNewsFeedList();

      }
    });
  }

  // viewDocument(s3Link){
  //   let userDocument = {
  //                   "documentURL":s3Link, 
  //                   "documentType": 5
  //                 }
  //   this.userDocumentService.getDocumentUrl(userDocument).subscribe((data:DocumentUrlResponse)=>{
  //     let documentUrl = data.response;
  //     window.open(documentUrl, "_blank");
  //   },
  //     (err: HttpErrorResponse)=>{
  //     console.log("Error"+ err.message);
  //   });
  // }

  viewDocument(agreement, envelopId){
    this.userDocumentService.downloadDocument(agreement.id, envelopId).subscribe((data: any)=>{
      const source = `data:application/pdf;base64,${data.pdf}`;
      const link = document.createElement("a");
      link.href = source;
      const today = new Date().toLocaleDateString();
      link.download = agreement.title + '_' +today+ '.pdf'
      link.click();
    })
  }

  viewServiceAgreement(serviceAgreement:any){
    //console.log(newsFeed);
    let userId = serviceAgreement.fdsRecipients[0].userId
    this.modalRef = this.modalService.show(ViewServiceAgreementComponent,  {
      initialState: {
        title: 'Fee Disclosure Statement',
        data: {
          serviceAgreement:serviceAgreement,
          userId:userId,
        },
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
      },
      // class: "modal-lg_newsfeed",
      class: "modal-lg",
  
    });
  
    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
          //this.getNewsFeedList();
  
      }
    });
  }


  editServiceAgreement(serviceAgreement:any){
    //console.log(newsFeed);
    let userId = serviceAgreement.fdsRecipients[0].userId
    this.modalRef = this.modalService.show(NewServiceAgreementComponent,  {
      initialState: {
        title: 'Edit Documents To Action',
        data: {
          fdsServiceAgreementAddModel: serviceAgreement,
          xeppoGroupCode: serviceAgreement.xeppoGroupCode,
          xeppoCode: serviceAgreement.xeppoCode,
          clientCode: serviceAgreement.clientCode,
        },
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
      },
      class: "modal-xl modal-xlg",
  
    });
  
    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
      }
    });
  }
  
  isSignedUsers(fdsRecipients) {

    const element = fdsRecipients.find((value) => {
      return value.isSigned === false;
    });

    return typeof element === "object" ? false : true;
  }

  isExpirySign(dateExpiry) {
    const today = new Date()
    const dateExpiryD = new Date(dateExpiry)

    // console.log('dateExpiry =', dateExpiryD)
    // console.log('today =', today)
    if (dateExpiryD.setHours(0,0,0,0) - today.setHours(0,0,0,0) >= 0) { //first date is in future, or it is today
      return false
    }
    return true    
  }

  createEnvelop(serviceAgreementData) {
    this.loaded = true;
    this.ngxService.start();
    this.serviceAgreementService.setFDStatus(serviceAgreementData.id, 2).subscribe((data:ServiceAgreementResponseProfile)=>{  
      if(data.error && !data.success){
        this.loaded = false;
        this.ngxService.stop();
        if(data.error.message) {
          this.toastr.error(data.error.message);
        } else {
          this.toastr.error('Error on creating envelope.');
        }
      } else{
        this.serviceAgreementService.serviceAgreementList(this.skip, this.take).subscribe( (data: ServiceAgreementResponse) => {
          if (data.success) {
            this.serviceAgreementList = data.response.fdsList;
            //get updated agreement data to get envelop id
            serviceAgreementData = this.serviceAgreementList.filter( (a, i) => {
              if (a.id === serviceAgreementData.id) {
                return this.serviceAgreementList[i]
              }
            })
            this.sendToClients(serviceAgreementData[0]);
          } else {
            this.loaded = false;
            this.ngxService.stop();
          }
        }, (err: HttpErrorResponse) => {
        });
      }      
    },err=>{
      this.loaded = false;
      this.ngxService.stop();
      this.toastr.error('Error on creating envelope.');
    });
  }



  ngOnDestroy() {
    sessionStorage.removeItem('loadedComponent')
  }
}