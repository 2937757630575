 <!-- ////////////////////////////////////////////////////////////////////////////-->
 <div class="main-menu menu-fixed menu-light menu-accordion  menu-shadow border-0" data-scroll-to-active="true">
    <div class="main-menu-content scrollsidebarn">
        <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
            <div class="card-header">
                <div class="media">
                    <div class="media-left mr-1">
                        <a href="javascript:void(0)"> <span class="avatar avatar-md avatar-busy">
<!--
                                <div class="circle" style="background-image: url('https://img.labnol.org/di/bo.jpg')">
</div>
-->
                                <img src="assets/img/user.jpg" class="circle" alt="avatar">  
                            </span> </a>
                    </div>
                    <div class="media-body">
                        <h5 class="card-title"><a [routerLink]="['/dashboard']" style="text-transform: uppercase;" href="#">{{businessName}} AGENT</a></h5>
                        <p class="card-subtitle text-muted mb-0 pt-1" style="text-transform: uppercase;"> 
                            <span class="font-small-3">
                                <ng-container *ngIf="currentUser">
                                    {{ currentUser.userName }}
                                </ng-container>
                                <!-- info@fortress.com.au -->
                            </span> 
                        </p>
                    </div>
                </div>
                <hr> 
            </div>
            <li class=" navigation-header"> <span data-i18n="nav.category.layouts">{{businessName}} Navigation</span><i class="la la-ellipsis-h ft-minus" data-toggle="tooltip" data-placement="right" data-original-title="Layouts"></i> </li>
            <li class=" nav-item" [routerLinkActive]="['open']" ><a [routerLink]="['/dashboard']" href="#"><i class="la la-home"></i><span class="menu-title" data-i18n="nav.page_layouts.main">Dashboard</span></a> </li>
            <li class=" nav-item {{active}}" [routerLinkActive]="['open']"  ><a (click)="clearPagination()"  [routerLink]="['/clients']" href="#"><i class="la la-user"></i><span class="menu-title" data-i18n="nav.navbars.main">Clients</span></a> </li>
            <!-- <li class=" nav-item" [routerLinkActive]="['open']" ><a [routerLink]="['/groups']" href="#"><i class="la la-users"></i><span class="menu-title" data-i18n="nav.vertical_nav.main">Groups</span></a> </li> -->
            <!-- <li class=" nav-item" [routerLinkActive]="['open']" ><a [routerLink]="['/invoices']" href="#"><i class="la la-money"></i><span class="menu-title" data-i18n="nav.vertical_nav.main">All Invoices</span></a> </li> -->
            
            <li (click)="onEvent();" *ngIf="viewPortalPermission('Allow_SuperAdmin_Operations') || viewPortalPermission('View_News_Feed')" [class.open]="isCollapsed" [routerLinkActive]="['open']" class="nav-item has-sub"><a href=""><i class="la la-microphone"></i><span class="menu-title" data-i18n="nav.horz_nav.main">Communications<span [ngClass]="[newMsgReceived ? 'btn-danger' : '']"  class="msg_icon"></span></span></a>
                <ul class=""  [class.show]="isCollapsed" >
                    <li class="" [routerLinkActive]="['active']" ><a class="menu-item" [routerLink]="['/news-feed']" href="#" data-i18n="nav.c3_newsfeed">News Feed</a> </li>
                    <!-- <li [routerLinkActive]="['active']" ><a class="menu-item" [routerLink]="['/message-center']"  href="#" data-i18n="nav.c3_message_chart">Message Center<span [ngClass]="[newMsgReceived ? 'btn-danger' : '']"  class="msg_icon"></span></a> </li> -->
                    <li [routerLinkActive]="['active']" [routerLink]="['/view-audience']"><a class="menu-item" [routerLink]="['/audience']"  href="#" data-i18n="nav.c3_newsfeed">Audience</a> </li>
                </ul>
            </li>
            
            <!-- <li class=" nav-item" [routerLinkActive]="['open']" ><a [routerLink]="['/appointments']" href="#"><i class="la la-calendar-check-o"></i><span class="menu-title" data-i18n="nav.page_headers.main">All Appointments</span></a> </li> -->
            <!-- <li class=" nav-item"><a href="javascript:void(0)"><i class="la la-mobile"></i><span class="menu-title" data-i18n="nav.footers.main">Contact</span></a> </li> -->
            <!-- <li class=" nav-item " [routerLinkActive]="['open']" ><a [routerLink]="['/agents']" href="#"><i class="la la-user-secret"></i><span class="menu-title" data-i18n="nav.footers.main">Dalle Cort Users</span></a> </li> -->
            <li class=" nav-item " [routerLinkActive]="['open']" ><a [routerLink]="['/un-verified-users/view']" href="#"><i class="la la-check-square"></i><span class="menu-title" data-i18n="nav.footers.main">Account Approvals</span></a> </li>
           <li class=" nav-item" [routerLinkActive]="['open']"><a [routerLink]="['/service-agreement']" href="#"><i class="la la-user"></i><span class="menu-title" data-i18n="nav.navbars.main">Documents To Action</span></a> </li>
            <li class=" nav-item" [routerLinkActive]="['open']"><a [routerLink]="['/unallocated-accounts']" href="#"><span class="unallocated-acc-icon"></span><span class="menu-title" data-i18n="nav.navbars.main">Unallocated Accounts</span></a> </li>
            <li (click)="onEvent1();" *ngIf="viewPortalPermission('Allow_SuperAdmin_Operations')" [class.open]="isCollapsed1" [routerLinkActive]="['open']" class="nav-item has-sub"><a href=""><i class="la la-bullseye"></i><span class="menu-title" data-i18n="nav.horz_nav.main">Super User</span></a>
                <ul class=""  [class.show]="isCollapactivesed1" >
                    <li class=" nav-item " [routerLinkActive]="['active']" ><a [routerLink]="['/agents']" href="#"><span class="menu-title" data-i18n="nav.footers.main">Staff Users</span></a> </li> <!-- Dalle Cort Users -->
                    <!-- <li class="" [routerLinkActive]="['active']" ><a class="menu-item" [routerLink]="['/companies']" href="#">Companies</a> </li> -->
                    <li [routerLinkActive]="['active']"><a class="menu-item" [routerLink]="['/permission-groups']"  href="#" data-i18n="nav.c3_message_chart">Permission Groups</a> </li>
                    <li [routerLinkActive]="['active']" [routerLink]="['/email-setup']"><a class="menu-item" [routerLink]="['/department-list']"  href="#" data-i18n="nav.c3_message_chart">Email Setup</a> </li>
                    <li [routerLinkActive]="['active']" [routerLink]="['/rollback-data']"><a class="menu-item" [routerLink]="['/rollback-data']"  href="#" data-i18n="nav.c3_message_chart">Rollback Data</a> </li>
                    <!-- <li [routerLinkActive]="['active']" [routerLink]="['/maintenace-mode']"><a class="menu-item" [routerLink]="['/maintenace-mode']"  href="#" data-i18n="nav.c3_message_chart">Maintenace Mode</a> </li> -->
                    <li [routerLinkActive]="['active']" [routerLink]="['/sync-monitoring']"><a class="menu-item" [routerLink]="['/sync-monitoring']"  href="#" data-i18n="nav.c3_message_chart">Xeppo Sync Monitoring</a> </li>
                </ul>
            </li>
            

        </ul>
    </div>
</div>

