export enum InvoiceType
{
    Void = 1,
    Unpaid = 2,
    Paid = 3
}

export enum DocType
{
    PNG = 1,
    JPG = 2,
    PDF = 3,
    DOC = 4,
    DOCX = 5,
    CSV = 6,
    XLS = 7,
    XLSX = 8
}