import { XplanCoverAddModel } from './xplan-cover.model';

export class XFacInsuranceLifeBaseMOdel {
    public  id : string;
    public  xFacInsuranceId : string;
    public  type : string;
    public  policyNumber : string;
    public  policyOwners : string;
    public  underwriter : string;
    public  planName : string;
    public  status : string;
    public  totalPremiumValue : string;
    public  totalPremium : string;
    public  premiumFrequency : number;
    public  lifeSumInsuredValue : number;
    public  lifeSumInsuredCode : string;
    public  criticalIllnessSumInsuredValue : number;
    public  criticalIllnessSumInsuredCode : string;
    public  incomeProtectionBenefitAmountValue : number;
    public  incomeProtectionBenefitAmountCode : string;
    public  tpdSumInsuredValue : number;
    public  tpdSumInsuredCode : string;
    public  businessExpenseBenefitAmountValue : number;
    public  businessExpenseBenefitAmountCode : string;
    public  severityBasedInsuranceSumInsuredValue : number;
    public  severityBasedInsuranceSumInsuredCode : string;
    public  ownersClientId : string;
    public  clientCode : string; 
    public  ownerTypeName : string;
    public  totalPremiumCode : string; 
}

export class XFacInsuranceLifeAddModel extends XFacInsuranceLifeBaseMOdel{ 
    public xFacInsuranceCovers : XplanCoverAddModel[]; 
    public groupedCovers : any = []; 
}