import { Injectable } from '@angular/core';
import { CompanyResponse, Company_Response2 } from '../shared/models/response.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConstants } from '../utility/app-constants';
import { CompanyAddModel } from '../shared/models/company.model';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  authUrl: String;

  constructor(private http:HttpClient) { 
    this.authUrl = AppConstants.getAuthURL();
  }

  public handleError(error) {
    return throwError(error);
  }

  public companyList(){       
    //return this.http.get<AgentResponse>(this.baseUrl+'/FortressAdmins');   
    return this.http.get<Company_Response2>(this.authUrl+'/Company/GetAll');   
  }

  public companyEdit(id:string,company:CompanyAddModel) {  
    // console.log(company);
    //const params = new HttpParams().set('id', id); 
    // const params = {
    //   'id': id,
    //   'firstName': agent.firstName,
    //   'lastName': agent.lastName,
    //   'prefferedName': agent.prefferedName,
    //   'designation': agent.designation,
    //   'contactNumber': agent.contactNumber,
    //   'primaryMobile': agent.primaryMobile,
    //   'email': agent.email,
    //   //'userType': 1,
    //   'companyId': agent.companyId,
    //   'roleId': agent.roleId,
    //   'isTowWayAuthActivated': false};
      //console.log(params);
    

    return this.http.post<Company_Response2>(this.authUrl+'/Company/Update', company).pipe(
      catchError(this.handleError)
      );
  }

  public companyAdd(companyModel:CompanyAddModel){
    //const params = new HttpParams().set('userType', "1"); 
    //agentModel.userType = 1;
    //agentModel.roleId = 'Hi9XddK4N0tujQjXKgU3rQ';  
    // return this.http.post<AgentProfileResponse>(this.baseUrl +'/FortressAdmin',agentModel).pipe(
    //  catchError(this.handleError)
    //  );   
    //console.log(agentModel);
    return this.http.post<Company_Response2>(this.authUrl +'/Company',companyModel).pipe(
      catchError(this.handleError)
      );
  }
}
