
            <div class="modal-header a_modal">
                <h4 class="modal-title" id="myModalLabel1">New Company</h4>
                <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
              </div>
            <form #f="ngForm" novalidate class="form" (ngSubmit)="onSubmit(f)" autocomplete="false" >
            <div class="modal-body dallecort_modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-body">
                            
                            <div class="row add_agent_ga" style="margin-top: 5px;">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="projectinput1" class="c_n_fs">Company Name *</label>
                                        <input autocomplete="off" type="text" autocomplete="none" required maxlength="75" minlength="2" class="form-control" id="name" name="name" placeholder="Type here" [(ngModel)]="companyModel.name" #name = "ngModel">
                                        <div class="form-group">
                                            <div *ngIf="name.invalid && (name.dirty || name.touched || f.submitted)"
                                            class="form_alert">
                                              <span  class="ft-alert-circle"></span>
                                              <div *ngIf="name.errors.required">
                                                Name is required.
                                              </div>
                                              <div *ngIf="name.errors.minlength">
                                                Name must be at least 2 characters long.
                                              </div>
                                              <div *ngIf="name.errors.maxlength">
                                                Name must not more than 75 characters long.
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-group">
                                      <label for="projectinput1" class="c_n_fs">Logo URL *</label>
                                      <input autocomplete="off" type="text" autocomplete="none" required minlength="2" class="form-control" id="logoUrl" name="logoUrl" placeholder="Type here" [(ngModel)]="companyModel.logoUrl" #logoUrl = "ngModel" url pattern="https?://.+">
                                      <div class="form-group">
                                          <div *ngIf="logoUrl.invalid && (logoUrl.dirty || logoUrl.touched || f.submitted)"
                                          class="form_alert">
                                            <span  class="ft-alert-circle"></span>
                                            <div *ngIf="logoUrl.errors.required">
                                              Logo Url is required.
                                            </div>
                                            <div *ngIf="logoUrl.errors.minlength">
                                              Logo Url must be at least 2 characters long.
                                            </div>
                                            <div *ngIf="logoUrl.errors.pattern">
                                              Logo Url must be a valid URL.
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-group">
                                      <label for="projectinput1" class="c_n_fs">Site URL *</label>
                                      <input autocomplete="off" type="text" autocomplete="none" required minlength="2" class="form-control" id="siteUrl" name="siteUrl" placeholder="Type here" [(ngModel)]="companyModel.siteUrl" #siteUrl = "ngModel" url pattern="https?://.+">
                                      <div class="form-group">
                                          <div *ngIf="siteUrl.invalid && (siteUrl.dirty || siteUrl.touched || f.submitted)"
                                          class="form_alert">
                                            <span  class="ft-alert-circle"></span>
                                            <div *ngIf="siteUrl.errors.required">
                                              Site Url is required.
                                            </div>
                                            <div *ngIf="siteUrl.errors.minlength">
                                              Site Url must be at least 2 characters long.
                                            </div>
                                            <div *ngIf="siteUrl.errors.pattern">
                                              Site Url must be a valid URL.
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-group">
                                      <label for="projectinput1" class="c_n_fs">Address *</label>
                                      <input autocomplete="off" type="text" autocomplete="none" required minlength="2" class="form-control" id="address" name="address" placeholder="Type here" [(ngModel)]="companyModel.address" #address = "ngModel">
                                      <div class="form-group">
                                          <div *ngIf="address.invalid && (address.dirty || address.touched || f.submitted)"
                                          class="form_alert">
                                            <span  class="ft-alert-circle"></span>
                                            <div *ngIf="address.errors.required">
                                               Address is required.
                                            </div>
                                            <div *ngIf="address.errors.minlength">
                                               Address must be at least 2 characters long.
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-group">
                                      <label for="projectinput1" class="c_n_fs">Company Code *</label>
                                      <input autocomplete="off" type="text" autocomplete="none" required minlength="2" class="form-control" id="companyCode" name="companyCode" placeholder="Type here" [(ngModel)]="companyModel.companyCode" #companyCode = "ngModel">
                                      <div class="form-group">
                                          <div *ngIf="companyCode.invalid && (companyCode.dirty || companyCode.touched || f.submitted)"
                                          class="form_alert">
                                            <span  class="ft-alert-circle"></span>
                                            <div *ngIf="companyCode.errors.required">
                                               Company Code is required.
                                            </div>
                                            <div *ngIf="companyCode.errors.minlength">
                                               Company Code must be at least 2 characters long.
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer a-footer">
                <div *ngIf="!Isvalid" class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    Please fill in all the required fields correctly.
                </div>
                <div *ngIf="errors"class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    {{errors}}
                </div>
                <button type="submit" class="btn popup_save_b">Save</button>
            </div>
          </form>
          <ngx-ui-loader ></ngx-ui-loader>
       