export class CompanyBaseMOdel {
    public  id : string;
    public  name : string;
    public  logoUrl : string;
    public  siteUrl : string;
    public  address : string;
    public  companyCode : string;
}

export class CompanyAddModel extends CompanyBaseMOdel{    
       
}