import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppConstants } from '../utility/app-constants';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { NetworthSnapshotHistoryResponse } from '../shared/models/response.model';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NetworthHistoryService {

  networtHistoryUrl: String;
  token :String;

  constructor(private http: HttpClient, private authenticationService: AuthService) {
    this.networtHistoryUrl = AppConstants.getNetwortHistoryURL();
    this.token = this.authenticationService.userToken;
  }

  public handleError(error) {
    return throwError(error);
  }

  // public networthHistoryList(fromDate, toDate, xeppoCode, companyCode, ClientCode, XeppoGroupCode){
  //   const params = new HttpParams().set('FromDate', fromDate).set('ToDate', toDate).set('XeppoCode', xeppoCode).set('CompanyCode', companyCode).set('ClientCode', ClientCode).set('XeppoGroupCode', XeppoGroupCode);
  //   return this.http.get<any>(this.networtHistoryUrl + '/NetworthHistory/Web/GetNetworthSnapshotHistory',{params});
  // }

  // public networthHistoryList(fromDate, toDate, xeppoCode, companyCode, ClientCode, XeppoGroupCode){
  //     const params = {"fromDate":fromDate, "toDate":toDate, "xeppoGroupCode":XeppoGroupCode, "companyCode":companyCode};
  //     return this.http.post<NetworthSnapshotHistoryResponse>(this.networtHistoryUrl +'/NetworthHistory/Web/GetNetworthSnapshotHistory',params).pipe(
  //     catchError(this.handleError)
  //     );
  // }

  public networthHistoryList(fromDate, toDate, xeppoCode, companyCode, ClientCode, XeppoGroupCode){
    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': 'Bearer ' + this.token,
    //   'Access-Control-Allow-Origin': '*',
    //   'Access-Control-Allow-Methods': 'POST' });
    // const params = {"fromDate":fromDate, "toDate":toDate, "xeppoGroupCode":XeppoGroupCode, "companyCode":companyCode};

    // return this.http.post<any>(this.networtHistoryUrl +'/NetworthHistory/Web/GetNetworthSnapshotHistory',{params, headers: {'Accept': 'application/json','Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'POST'} , method: 'POST', mode: 'no-cors'}).pipe(
    //   catchError(this.handleError)
    //   );

    let headers = new HttpHeaders({
      'Accept': '*/*',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST' });
      const params = {"fromDate":fromDate, "toDate":toDate, "xeppoGroupCode":XeppoGroupCode, "companyCode":companyCode};

    // return this.http.post<any>(this.networtHistoryUrl+'/NetworthHistory/Web/GetNetworthSnapshotHistory',params, {headers: headers});
    return this.http.post<any>(this.networtHistoryUrl+'/NetworthHistory/web/GetRealTimeNetworthSnapshotHistory',params, {headers: headers});
  }

  public networthHistoryFormattedDataList(fromDate, toDate, xeppoCode, companyCode, ClientCode, XeppoGroupCode){
    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': 'Bearer ' + this.token,
    //   'Access-Control-Allow-Origin': '*',
    //   'Access-Control-Allow-Methods': 'POST' });
    // const params = {"fromDate":fromDate, "toDate":toDate, "xeppoGroupCode":XeppoGroupCode, "companyCode":companyCode};

    // return this.http.post<any>(this.networtHistoryUrl +'/NetworthHistory/Web/GetNetworthSnapshotHistory',{params, headers: {'Accept': 'application/json','Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'POST'} , method: 'POST', mode: 'no-cors'}).pipe(
    //   catchError(this.handleError)
    //   );

    let headers = new HttpHeaders({
      'Accept': '*/*',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST' });
      const params = {"fromDate":fromDate, "toDate":toDate, "xeppoGroupCode":XeppoGroupCode, "companyCode":companyCode};

    return this.http.post<any>(this.networtHistoryUrl+'/NetworthHistory/Web/GetNetworthSnapshotHistoryFormattedDataSet',params, {headers: headers});
  }


    // Update Networth Snapshot Data
    public updateNetworthSnapshotData(data: any): Observable<any> {
      let headers = new HttpHeaders({
        'Accept': '*/*',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'PATCH'
      });
      return this.http.patch<any>(this.networtHistoryUrl+'/NetworthSnapshotData', data, {headers: headers});
    }

    // Delete Networth Snapshot Data
    public deleteNetworthSnapshotData(id: string, isArchived: boolean) {
      return this.http.delete<any>(this.networtHistoryUrl+'/NetworthSnapshotData?Id='+id+'&isArchived='+isArchived);
    }

}
