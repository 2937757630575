<app-header></app-header>
<app-sidebar></app-sidebar>


<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                <h3 class="content-header-title mb-0 d-inline-block">Companies</h3>
                <div class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/dashboard">Home</a> </li>
                            <li class="breadcrumb-item active">Companies </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12">
                <div class="btn-group float-md-right">
                    <!-- <button class="btn appointment_btn btn-sm border_all_radius"  data-toggle="modal" data-target="#new_company">Create New</button> -->
                    <button class="btn btn-info appointment_btn btn-sm border_all_radius" type="button" (click)="openModal()" >Create New</button>
                </div>
                
                
            </div>
        </div>        

        <div class="content-body">
            <div class="row">
                    <div class="col-12 col-md-12 ">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Company List</h4>
                                </div>
                                <div class="card-content">
                                    <div *ngIf="isLoaded" class="table-responsive scroll-tbl_newsfeed-">
                                        <!-- <table id="recent-orders" class="table table-hover table-xl mb-0"> -->
                                        <table datatable [dtOptions]="dtOptions" class="t_dt table table-striped table-bordered table-sm row-border hover" style="margin-top: 20px !important;">
                                            <thead>
                                                <tr>
                                                    <th class="border-top-0">Company Name</th>
                                                    <th class="border-top-0">Logo URL</th>
                                                    <th class="border-top-0">Site URL</th>
                                                    <th class="border-top-0">Address</th>
                                                    <th class="border-top-0">Company Code</th>
                                                    <th class="border-top-0"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let company of companyList; let i = index">
                                                    <ng-container *ngIf="company" ><td class="text-truncate">{{company.name}}</td></ng-container>
                                                    <ng-container *ngIf="company" ><td class="text-truncate">{{company.logoUrl}}</td></ng-container>
                                                    <ng-container *ngIf="company" ><td class="text-truncate">{{company.siteUrl}}</td></ng-container>
                                                    <ng-container *ngIf="company" ><td class="text-truncate">{{company.address}}</td></ng-container>
                                                    <ng-container *ngIf="company" ><td class="text-truncate">{{company.companyCode}}</td></ng-container>
                                                    <ng-container *ngIf="company" >
                                                        <td class="sa_btns">
                                                            <div class="btn-group">
                                                                <!-- <button type="button" class="btn btn-icon mr-1 btn_trash"><i class="la la-trash"></i></button> -->
                                                                <button (click)="openEditModal(company)" type="button" class="btn btn-icon c_btntable" data-toggle="modal" data-target="#edit_company"><i class="la la-pencil"></i></button>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>






                
            </div>
            
        </div>
    </div>
</div>

<app-footer></app-footer>