import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { SyncMonitoringResponse } from '../shared/models/response.model';
import { SyncMonitoringListModel } from '../shared/models/sync-monitoring.model';
import { SyncMonitoringService } from './sync-monitoring.service';
import { ViewErrorLogComponent } from './view-error-log/view-error-log.component'

@Component({
  selector: 'app-sync-monitoring',
  templateUrl: './sync-monitoring.component.html',
  styleUrls: ['./sync-monitoring.component.css']
})
export class SyncMonitoringComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  isLoaded :boolean;
  currentPage: number;
  itemsPerPage: number = 50;
  syncMonitoringList:SyncMonitoringListModel[];
  totalRecordsCount: number;
  showBoundaryLinks: boolean = true;
  showDirectionLinks: boolean = true;
  modalRef: BsModalRef;

  constructor(
    private syncMonitoringService: SyncMonitoringService,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.getSyncMonitoringList();
    this.dtOptions = {
      paging: false,
      info: false,
      processing: false,
      order : [],
    };
  }

  getSyncMonitoringList(event?: PageChangedEvent) {
    this.isLoaded = false;
    this.currentPage = event?.page || 1;
    const skip = (event?.page-1) * event?.itemsPerPage || 0;
    const take = event?.itemsPerPage || this.itemsPerPage; // Default records count

    this.syncMonitoringService.syncMonitoringListList(skip, take).subscribe( (data: SyncMonitoringResponse) => {
      this.syncMonitoringList = data.response.result// data.response.fdsList;
      // this.returnedNewsFeedList = this.syncMonitoringList;
      this.totalRecordsCount = data.response.totalRecords;
      this.isLoaded = true;
    }, (err: HttpErrorResponse) => {
      //console.log("Error"+ err.message);
    });
  }

  openErrorLogModal(errorLogs:any) {  
    
    this.modalRef = this.modalService.show(ViewErrorLogComponent,  {
      initialState: {
        title: 'Sync Summary',    
        data: {
          errorLogs:errorLogs
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-dialog modal-lg modal_lg_width"
    });
  
    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        // this.router.onSameUrlNavigation = 'reload';
        // this.router.navigate(['appointments']);
      }
    });

  }

  checkErroHas(errorLogs) {
    if(errorLogs.length >0) {
      return errorLogs.some(function(el) {
        if(el.tableName === "XeppoNetWorthDataRead") {
          return true
        } else {
          return false
        }
      }); 
    }
  }

  checkWarning(errorLogs) {
    if(errorLogs.length >0) {
      return errorLogs.some(function(el) {
        if(el.httpCode != null && el.tableName != "XeppoNetWorthDataReads") {
          return true
        } else {
          return false
        }
      }); 
    }
  }

  generateDownloadJsonUri(syncData) {
    const theJSON = JSON.stringify(syncData.errorLogs);
    const a = document.createElement("a"); //Create <a>
    a.href = "data:text/json;charset=UTF-8," + encodeURIComponent(theJSON);
    const syncDate = new Date(syncData.runAt + 'z');
    a.download = syncDate.toLocaleDateString() + ".json";
    a.click();
  }
}
