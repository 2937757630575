import { User } from "./user.model";

export class AgentBaseMOdel extends User{
     public  email : string;
     public  isArchived : boolean = false;
    // public  password:  string;    
    // public  userType:  number;
    // public  primaryMobile:  string;
    // public  firstName:  string;
    // public  lastName:  string;
    // public  prefferedName:  string;
    // public  designation:  string;
    // public  contactNumber:  string;
}

export class AdminModel {
    public  id : string;
    public  firstName : string;
    public  lastName : string;
    public  prefferedName : string;
    public  designation : string;
    public  contactNumber : string;
    public  isArchived : boolean;
    public  email : string;
    public  roleId: string;
}

export class UserAdminModel {
    public  id : string;
    public  firstName : string;
    public  lastName : string;
    public  prefferedName : string;
    public  designation : string;
    public  contactNumber : string;
    public  isArchived : boolean;
    public  email : string;
    public  profileImageUrl : string;
    public  companyId: string; 
    public  password : string;   
    public password_confirmation : string;
    public  userName : string;
    
}

export class AgentViewModel extends AgentBaseMOdel{
    public fortressAdmin: AdminModel; 
}

export class UserAgentViewModel extends AgentBaseMOdel{
    //public fortressAdmin: AdminModel; 
    public fortressAdmin: UserAdminModel; 
}

export class AgentAddModel extends AgentBaseMOdel{
    public  firstName : string;
    public  lastName : string;
    public  userName : string;
    public  primaryMobile : string;
    public  prefferedName : string;
    public  designation : string;
    public  contactNumber : string;
    public  email : string;
    public  password : string;   
    public password_confirmation : string;
    public fortressAdmin: AdminModel; 
}

export class UserAgentAddModel extends AgentBaseMOdel{
    public  id : string;
    public  firstName : string;
    public  lastName : string;
    public  lastName2 : string;
    public  prefferedName : string;
    public  designation : string;
    public  contactNumber : string;
    public  userName : string;
    public  primaryMobile : string;
    public  email : string;
    public  profileImageUrl : string;
    public  password : string;   
    public  password_confirmation : string;
    public  fortressAdmin: AdminModel;  
    public  userType: number;
    public  companyId: string;
    public  roleId: string;
    public  isTowWayAuthActivated: boolean;
}
