export class XFacEstatePlanningBaseMOdel {
    public  facFinderId : string;
    public  funeralPlan : string;
    public  willCurrent : boolean;
    public  willExists : boolean;
    public  willDate : Date;
    public  willNotes : string;
    public  willLocation : string;
    public  clientCode : string;
}

export class XFacEstatePlanningAddModel extends XFacEstatePlanningBaseMOdel{    
       
}

export class XFacEstatePlanningEditModel {
    public  facFinderId : string;
    public  funeralPlan : string;
    public  willCurrent : boolean;
    public  willExists : boolean;
    public  willDate : Date;
    public  willNotes : string;
    public  willLocation : string;
    public  clientCode : string;
}