export class XFacIncomeBaseMOdel {
    public  facFinderId : string;
    public  type : string;
    public  description : string;
    public  amountValue : number;
    public  amountCode : string;
    public  frequency : number;
    public  ownerType : string;
    public  clientCode : string;
    public  typeGroup : string;
    public  incomeTypeName : string;
    public  incomeSubTypeName : string;
    public  incomeFrequencyName : string;
}

export class XFacIncomeAddModel extends XFacIncomeBaseMOdel{    
       
}