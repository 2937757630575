<!-- <div class="modal fade" id="exampleModal{{i}}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> -->
<!-- <div class="modal fade" id="exampleModal" >
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content"> -->
            <div class="modal-header border-bottom-0">
                <h5 class="modal-title" id="exampleModalLabel"><b>Fact Finder Version {{facPersonalData.versionNumber}} </b><span class="nff_title">(current version)</span></h5>
                <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
            </div>
            <div class="modal-body p-0">
                <div *ngIf="isLoaded" class="card collapse-icon panel mb-0 box-shadow-0 border-0 collapse-icon_n mt-0">
                    <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> <a data-toggle="collapse" data-parent="#accordionWrap1" href="#pd1" aria-expanded="false" aria-controls="accordion13" class="h6 blue collapsed prof_head">Personal Details</a> </div>
                    <div id="pd1" role="tabpanel" aria-labelledby="heading13" class="collapse" aria-expanded="false">
                        <div class="card-body card_body_prof_ff">
                            <table *ngIf="isLoaded1" class="table table-borderless table-sm pro_tab table-responsive w-100 d-block d-md-table">
                                <tbody>
                                    <tr>
                                        <td><span class="ffn_dot text-truncate"></span>&nbsp;User Name</td>
                                        <td class="profile_td text-left text-truncate">: <span>{{ facPersonalData.user.userName }} </span></td>
                                    </tr>
                                    <tr>
                                        <td><span class="ffn_dot text-truncate"></span>&nbsp;First Name</td>
                                        <td class="profile_td text-left text-truncate">: <span>{{ client.client.firstName }}</span><!-- {{ facPersonalData.user.userName }} --></td>
                                    </tr>
                                    <tr>
                                        <td><span class="ffn_dot text-truncate"></span>&nbsp;Last Name</td>
                                        <td class="profile_td text-left text-truncate">: <span>{{ client.client.lastName }}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span class="ffn_dot text-truncate"></span>&nbsp;Gender</td>
                                        <td class="profile_td text-left text-truncate">: <span>{{ client.client.gender }}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span class="ffn_dot text-truncate"></span>&nbsp;Date of Birth</td>
                                        <td class="profile_td text-left text-truncate">: <span>{{ client.client.dateOfBirth | date: 'dd/MM/yyyy' }} </span></td>
                                    </tr>
                                    <tr>
                                        <td><span class="ffn_dot text-truncate"></span>&nbsp;Marital Status</td>
                                        <td class="profile_td text-left text-truncate">: <span>{{ client.client.maritalStatus }}</span> </td>
                                    </tr>
                                    <tr>
                                        <td><span class="ffn_dot text-truncate"></span>&nbsp;Smoke</td> 
                                        <td class="profile_td text-left text-truncate">: <span>{{ client.client.smoker }}</span></td>
                                    </tr>
                                    <!-- <tr>
                                        <td><span class="ffn_dot text-truncate"></span>&nbsp;Primary Mobile</td>
                                        <td class="profile_td text-left text-truncate">: {{ facPersonalData.user.primaryMobile }}</td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    
                    <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> <a data-toggle="collapse" data-parent="#accordionWrap1" href="#ed1" aria-expanded="false" aria-controls="accordion13" class="h6 blue collapsed prof_head">Employment Details</a> </div>
                    <div id="ed1" role="tabpanel" aria-labelledby="heading13" class="collapse" aria-expanded="false">
                        <div class="card-body card_body_prof_ff">
                            <table class="table table-borderless table-sm pro_tab table-responsive w-100 d-block d-md-table">
                                <tbody>
                                    <tr>
                                        <td class="text-truncate"><span class="ffn_dot text-truncate"></span>&nbsp;Employment Status</td>
                                        <td class="profile_td text-left text-truncate">: Full Time</td>
                                    </tr>
                                    <tr>
                                        <td><span class="ffn_dot text-truncate"></span>&nbsp;Employer</td>
                                        <td class="text-left profile_td text-truncate">: Jetwing Hotels</td>
                                    </tr>
                                    <tr>
                                        <td><span class="ffn_dot text-truncate"></span>&nbsp;Occupation</td>
                                        <td class="text-left profile_td text-truncate">: Senior Management</td>
                                    </tr>
                                    <tr>
                                        <td><span class="ffn_dot text-truncate"></span>&nbsp;Employer Street Address</td>
                                        <td class="text-left profile_td text-truncate">: 206A Charters Towers Rd, Hermit Park QLD 4812, Australia</td>
                                    </tr>
                                    <tr>
                                        <td><span class="ffn_dot text-truncate"></span>&nbsp;Start Date</td>
                                        <td class="text-left profile_td text-truncate">: 20/20/2018</td>
                                    </tr>
                                    <tr>
                                        <td><span class="ffn_dot text-truncate"></span>&nbsp;Do you intend to change employment?</td>
                                        <td class="text-left profile_td text-truncate">: Yes</td>
                                    </tr>
                                    <tr>
                                        <td class="special_ff"><span class="ffn_dot"></span>&nbsp;No any special notes added</td>
                                        <!--                                                                                    <td class="text-left">: Yes</td>-->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    
                    <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> <a data-toggle="collapse" data-parent="#accordionWrap1" href="#goal1" aria-expanded="false" aria-controls="accordion13" class="h6 blue collapsed prof_head">Goals</a> </div>
                    <div id="goal1" role="tabpanel" aria-labelledby="heading13" class="collapse" aria-expanded="false">
                        <div class="card-body card_body_prof_ff">
                            <span class="ffn_submenu">FINANCIAL/PERSONAL GOALS</span>
                            <table class="table table-borderless table-sm pro_tab mb-2 table-responsive w-100 d-block d-md-table">
                                <tbody>
                                    <tr>
                                        <td class="font-weight-bold text-truncate">Owner&nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Description&nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Target Amount&nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Comments&nbsp;&nbsp;</td>
                                    </tr>
                                    <ng-container *ngFor="let xFacGoal of factFinderServiceData.xFacGoals; let i = index">
                                        <tr>
                                            <td class="profile_td text-left text-truncate"> 
                                                <ng-container *ngIf="xFacGoal.note"> {{ xFacGoal.note }} </ng-container>
                                                <ng-container *ngIf="xFacGoal.note == null || xFacGoal.note == ''"> - </ng-container>
                                            </td>        
                                            <td class="profile_td text-left text-truncate"> 
                                                <ng-container *ngIf="xFacGoal.description"> {{ xFacGoal.description }} </ng-container>
                                                <ng-container *ngIf="xFacGoal.description == null || xFacGoal.description == ''"> - </ng-container>
                                            </td>
                                            <td class="profile_td text-left text-truncate"> 
                                                <ng-container *ngIf="xFacGoal.targetAmountValue"> {{ xFacGoal.targetAmountValue }} </ng-container>
                                                <ng-container *ngIf="xFacGoal.targetAmountValue == null || xFacGoal.targetAmountValue == ''"> - </ng-container>
                                            </td>
                                            <td class="profile_td text-left text-truncate">
                                                <ng-container *ngIf="xFacGoal.note"> {{ xFacGoal.note }} </ng-container>
                                                <ng-container *ngIf="xFacGoal.note == null || xFacGoal.note == ''"> - </ng-container>
                                            </td>
                                        </tr>
                                        <!-- <tr>
                                            <td class="ffn_ml text-truncate">Category</td>
                                            <td class="text-left profile_td text-truncate">: {{ xFacGoal.category }}</td>
                                        </tr>
                                        <tr>
                                            <td class="ffn_ml text-truncate">Category Description</td>
                                            <td class="text-left profile_td text-truncate">: {{ xFacGoal.categoryDescription }}</td>
                                        </tr>
                                        <tr>
                                            <td class="ffn_ml text-truncate">Description</td>
                                            <td class="text-left profile_td text-truncate">: {{ xFacGoal.description }}</td>
                                        </tr>
                                        <tr>
                                            <td class="ffn_ml text-truncate">Note</td>
                                            <td class="text-left profile_td text-truncate">: {{ xFacGoal.note }}</td>
                                        </tr>
                                        <tr>
                                            <td class="ffn_ml text-truncate">Owner Type</td>
                                            <td class="text-left profile_td text-truncate">: {{ xFacGoal.ownerType }}</td>
                                        </tr>
                                        <tr>
                                            <td class="ffn_ml text-truncate">Owner Type Description</td>
                                            <td class="text-left profile_td text-truncate">: {{ xFacGoal.ownerTypeDescription }}</td>
                                        </tr>
                                        <tr>
                                            <td class="ffn_ml text-truncate">Target Amount Value</td>
                                            <td class="text-left profile_td text-truncate">: {{ xFacGoal.targetAmountValue }}</td>
                                        </tr> -->
                                    </ng-container>
                                    
                                    
                                </tbody>
                            </table>
                            
                        </div>
                    </div>
                    
                    
                    <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> <a data-toggle="collapse" data-parent="#accordionWrap1" href="#ai1" aria-expanded="false" aria-controls="accordion13" class="h6 blue collapsed prof_head">Annual Income/Expenses</a> </div>
                    <div id="ai1" role="tabpanel" aria-labelledby="heading13" class="collapse" aria-expanded="false">
                        <div class="card-body card_body_prof_ff table-responsive ">
                            <table class="table table-borderless table-sm pro_tab d-md-table">
                                <tbody>
                                        <span class="ffn_submenu">Incomes</span>
                                    <tr>
                                        <td class="font-weight-bold text-truncate">Type&nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Sub-Type&nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Description&nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Owner&nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Frequency&nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Amount&nbsp;&nbsp;</td>
                                    </tr>
                                    <ng-container *ngFor="let xFacIncome of factFinderServiceData.xFacIncomes; let i = index">
                                        <tr>
                                            <td class="profile_td text-left text-truncate">
                                                <ng-container *ngIf="xFacIncome.type"> {{ xFacIncome.type }} </ng-container>
                                                <ng-container *ngIf="xFacIncome.type == null || xFacIncome.type == ''"> - </ng-container>
                                            </td>
                                            <td class="profile_td text-left text-truncate">
                                                <ng-container *ngIf="xFacIncome.ownerType"> {{ xFacIncome.ownerType }} </ng-container>
                                                <ng-container *ngIf="xFacIncome.ownerType == null || xFacIncome.ownerType == ''"> - </ng-container>
                                            </td>
                                            <td class="profile_td text-left text-truncate">
                                                <ng-container *ngIf="xFacIncome.description"> {{ xFacIncome.description }} </ng-container>
                                                <ng-container *ngIf="xFacIncome.description == null || xFacIncome.description == ''"> - </ng-container>
                                            </td>
                                            <td class="profile_td text-left text-truncate"> - </td>
                                            <td class="profile_td text-left text-truncate">
                                                <ng-container *ngIf="xFacIncome.frequency"> {{ xFacIncome.frequency }} </ng-container>
                                                <ng-container *ngIf="xFacIncome.frequency == null || xFacIncome.frequency == ''"> - </ng-container>
                                            </td>
                                            <td class="profile_td text-left text-truncate">
                                                <ng-container *ngIf="xFacIncome.amountValue"> {{ xFacIncome.amountValue }} </ng-container>
                                                <ng-container *ngIf="xFacIncome.amountValue == null || xFacIncome.amountValue == ''"> - </ng-container>
                                            </td>
                                        </tr>
                                        <!-- <tr>
                                            <td class="special_ff text-truncate"><span class="ffn_dot"></span>&nbsp;No any special notes added</td>
                                        </tr> -->
                                    </ng-container>

                                    <span class="ffn_submenu">Expenses</span>
                                    <tr>
                                        <td class="font-weight-bold text-truncate">Type&nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Sub-Type&nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Description&nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Owner&nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Frequency&nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Amount&nbsp;&nbsp;</td>
                                    </tr>
                                    <ng-container *ngFor="let xFacExpens of factFinderServiceData.xFacExpenses; let i = index">
                                        <tr>
                                            <td class="profile_td text-left text-truncate">
                                                <ng-container *ngIf="xFacExpens.type"> {{ xFacExpens.type }} </ng-container>
                                                <ng-container *ngIf="xFacExpens.type == null || xFacExpens.type == ''"> - </ng-container>
                                            </td>
                                            <td class="profile_td text-left text-truncate">
                                                <ng-container *ngIf="xFacExpens.ownerType"> {{ xFacExpens.ownerType }} </ng-container>
                                                <ng-container *ngIf="xFacExpens.ownerType == null || xFacExpens.ownerType == ''"> - </ng-container>
                                            </td>
                                            <td class="profile_td text-left text-truncate">
                                                <ng-container *ngIf="xFacExpens.description"> {{ xFacExpens.description }} </ng-container>
                                                <ng-container *ngIf="xFacExpens.description == null || xFacExpens.description == ''"> - </ng-container>
                                            </td>
                                            <td class="profile_td text-left text-truncate"> - </td>
                                            <td class="profile_td text-left text-truncate">
                                                <ng-container *ngIf="xFacExpens.frequency"> {{ xFacExpens.frequency }} </ng-container>
                                                <ng-container *ngIf="xFacExpens.frequency == null || xFacExpens.frequency == ''"> - </ng-container>
                                            </td>
                                            <td class="profile_td text-left text-truncate">
                                                <ng-container *ngIf="xFacExpens.amountValue"> {{ xFacExpens.amountValue }} </ng-container>
                                                <ng-container *ngIf="xFacExpens.amountValue == null || xFacExpens.amountValue == ''"> - </ng-container>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    
                    <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> <a data-toggle="collapse" data-parent="#accordionWrap1" href="#assets1" aria-expanded="false" aria-controls="accordion13" class="h6 blue collapsed prof_head">Assets</a> </div>
                    <div id="assets1" role="tabpanel" aria-labelledby="heading13" class="collapse" aria-expanded="false">
                        <div class="card-body card_body_prof_ff">
                            <span class="ffn_submenu">Financial Assets</span>
                            <div class="table-responsive">  
                                <table class="table table-borderless table-sm pro_tab mb-2 d-md-table">
                                    <tbody>
                                        <!-- <tr>
                                            <td colspan="4" class="special_ff text-truncate"><span class="ffn_dot"></span>&nbsp;Assets</td>
                                        </tr> -->
                                        <tr>
                                            <td class="font-weight-bold text-truncate">Current Value&nbsp;&nbsp;</td>
                                            <td class="font-weight-bold text-truncate">Owner&nbsp;&nbsp;</td>
                                            <td class="font-weight-bold text-truncate">Type&nbsp;&nbsp;</td>
                                            <td class="font-weight-bold text-truncate">Description&nbsp;&nbsp;</td>
                                        </tr>
                                        <ng-container *ngFor="let xFacAsset of factFinderServiceData.xFacAssets; let i = index">
                                            <tr>
                                                <td class="profile_td text-left text-truncate"><span class="ffn_dot"></span>&nbsp;{{ xFacAsset.currentValue }}&nbsp;&nbsp;</td>
                                                <td class="profile_td text-left text-truncate">{{ xFacAsset.ownerName }}&nbsp;&nbsp;</td>
                                                <td class="profile_td text-left text-truncate">
                                                    <ng-container *ngIf="xFacAsset.type">{{ xFacAsset.type }}</ng-container>
                                                    <ng-container *ngIf="xFacAsset.type == null || xFacAsset.type == ''"> - </ng-container>
                                                &nbsp;&nbsp;</td>
                                                <td class="profile_td text-left text-truncate">
                                                    <ng-container *ngIf="xFacAsset.description">{{ xFacAsset.description }}</ng-container>
                                                    <ng-container *ngIf="xFacAsset.description == null || xFacAsset.description == ''"> - </ng-container>
                                                &nbsp;&nbsp;</td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                            
                            
                        </div>
                    </div>
                    
                    
                    <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> <a data-toggle="collapse" data-parent="#accordionWrap1" href="#lia1" aria-expanded="false" aria-controls="accordion13" class="h6 blue collapsed prof_head">Liabilities</a> </div>
                    <div id="lia1" role="tabpanel" aria-labelledby="heading13" class="collapse" aria-expanded="false">
                    
                    </div>


                    <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> <a data-toggle="collapse" data-parent="#accordionWrap1" href="#dependents" aria-expanded="false" aria-controls="accordion13" class="h6 blue collapsed prof_head">Dependents</a> </div>
                    <div id="dependents" role="tabpanel" aria-labelledby="heading13" class="collapse" aria-expanded="false">
                        <div class="card-body card_body_prof_ff">
                            <span class="ffn_submenu">Dependents</span>
                            <table class="table table-borderless table-sm pro_tab mb-2 table-responsive w-100 d-block d-md-table">
                                <tbody>
                                    <tr>
                                        <td class="font-weight-bold text-truncate">Surname</td>
                                        <td class="font-weight-bold text-truncate">First Name</td>
                                        <td class="font-weight-bold text-truncate">Date Of Birth</td>
                                        <td class="font-weight-bold text-truncate">Gender</td>
                                    </tr>
                                    <ng-container *ngFor="let xFacDependent of factFinderServiceData.xFacDependents; let i = index">
                                        <tr>
                                            <td class="profile_td text-left text-truncate"><span class="ffn_dot"></span>&nbsp;
                                                <ng-container *ngIf="xFacDependent.surname">{{ xFacDependent.surname }}</ng-container>
                                                <ng-container *ngIf="xFacDependent.surname == null || xFacDependent.surname == ''"> - </ng-container>
                                            &nbsp;&nbsp;</td>
                                            <td class="profile_td text-left text-truncate">
                                                <ng-container *ngIf="xFacDependent.firstName">{{ xFacDependent.firstName }}</ng-container>
                                                <ng-container *ngIf="xFacDependent.firstName == null || xFacDependent.firstName == ''"> - </ng-container>
                                            &nbsp;&nbsp;</td>
                                            <td class="profile_td text-left text-truncate">
                                                <ng-container *ngIf="xFacDependent.dateOfBirth">{{ xFacDependent.dateOfBirth  | date: 'dd/MM/yyyy'  }}</ng-container>
                                                <ng-container *ngIf="xFacDependent.dateOfBirth == null || xFacDependent.dateOfBirth == ''"> - </ng-container>
                                            &nbsp;&nbsp;</td>
                                            <td class="profile_td text-left text-truncate">
                                                <ng-container *ngIf="xFacDependent.gender">{{ xFacDependent.gender }}</ng-container>
                                                <ng-container *ngIf="xFacDependent.gender == null || xFacDependent.gender == ''"> - </ng-container>
                                            &nbsp;&nbsp;</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            
                        </div>
                    </div>


                    <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> <a data-toggle="collapse" data-parent="#accordionWrap1" href="#estatePlanning" aria-expanded="false" aria-controls="accordion13" class="h6 blue collapsed prof_head">Estate Details</a> </div>
                    <div id="estatePlanning" role="tabpanel" aria-labelledby="heading13" class="collapse" aria-expanded="false">
                        <div class="card-body card_body_prof_ff">
                            <span class="ffn_submenu">Estate Details</span>
                            <table class="table table-borderless table-sm pro_tab mb-2 table-responsive w-100 d-block d-md-table">
                                <tbody>
                                    <tr>
                                        <td class="font-weight-bold text-truncate">Will Exists</td>
                                        <td class="font-weight-bold text-truncate">Date Of Will</td>
                                        <td class="font-weight-bold text-truncate">Location</td>
                                    </tr>
                                    <ng-container *ngFor="let xFacEstatePlanning of factFinderServiceData.xFacEstatePlannings; let i = index">
                                        <tr>
                                            <td class="profile_td text-left text-truncate"><span class="ffn_dot"></span>&nbsp;
                                                <ng-container *ngIf="xFacEstatePlanning.willExists">{{ xFacEstatePlanning.willExists }}</ng-container>
                                                <ng-container *ngIf="xFacEstatePlanning.willExists == null || xFacEstatePlanning.willExists == ''"> - </ng-container>
                                            &nbsp;&nbsp;</td>
                                            <td class="profile_td text-left text-truncate">
                                                <ng-container *ngIf="xFacEstatePlanning.willDate">{{ xFacEstatePlanning.willDate  | date: 'dd/MM/yyyy'  }}</ng-container>
                                                <ng-container *ngIf="xFacEstatePlanning.willDate == null || xFacEstatePlanning.willDate == ''"> - </ng-container>
                                            &nbsp;&nbsp;</td>
                                            <td class="profile_td text-left text-truncate">
                                                <ng-container *ngIf="xFacEstatePlanning.willLocation">{{ xFacEstatePlanning.willLocation }}</ng-container>
                                                <ng-container *ngIf="xFacEstatePlanning.willLocation == null || xFacEstatePlanning.willLocation == ''"> - </ng-container>
                                            &nbsp;&nbsp;</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            
                        </div>
                    </div>
                    
                    
                    
                     <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> <a data-toggle="collapse" data-parent="#accordionWrap1" href="#sp1" aria-expanded="false" aria-controls="accordion13" class="h6 blue collapsed prof_head">Superannuation & Pensions</a> </div>
                    <div id="sp1" role="tabpanel" aria-labelledby="heading13" class="collapse" aria-expanded="false">
                    
                    </div>
                    
                    
                    
                     <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> <a data-toggle="collapse" data-parent="#accordionWrap1" href="#epla1" aria-expanded="false" aria-controls="accordion13" class="h6 blue collapsed prof_head">Estate Planning</a> </div>
                    <div id="epla1" role="tabpanel" aria-labelledby="heading13" class="collapse" aria-expanded="false">
                    
                    </div>
                    
                    
                    
                    <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> <a data-toggle="collapse" data-parent="#accordionWrap1" href="#insu1" aria-expanded="false" aria-controls="accordion13" class="h6 blue collapsed prof_head">Insurance</a> </div>
                    <div id="insu1" role="tabpanel" aria-labelledby="heading13" class="collapse" aria-expanded="false">
                    
                    </div>
                    
                </div>
            </div>
        <!-- </div>
    </div>
</div> -->