<app-header></app-header>
<app-sidebar></app-sidebar>


<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                <h3 class="content-header-title mb-0 d-inline-block"> XEPPO SYNC</h3>
                <div class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/dashboard">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Xeppo Sync
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="content-body">
            <!-- Zero configuration table -->
            <section id="">
                <div class="row">
                    <div class="col-12">

                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Sync Info</h4>
                            </div>
                            <div class="card-content collapse show">
                                <div *ngIf="isLoaded" class="card-body1 card-dashboard">
                                    <table datatable [dtOptions]="dtOptions"
                                        class="t_dt table table-striped table-bordered table-sm row-border hover"
                                        style="margin-top: 20px !important;">
                                        <thead>
                                            <tr>
                                                <th>Start Time</th>
                                                <th>Finish Time</th>
                                                <th>Status</th>
                                                <th class="text-center">Logs</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="syncMonitoringList.length>0">
                                            <tr *ngFor="let syncMonitoring of syncMonitoringList; let i = index" class="tablerow"> 
                                                <td>{{syncMonitoring.runAt+'Z' | date:'dd/MM/yyyy hh:mm a'}}</td>
                                                <td>
                                                    <ng-container *ngIf="syncMonitoring.endAt!=null">{{syncMonitoring.endAt+'Z' | date:'dd/MM/yyyy hh:mm a'}}</ng-container>
                                                    <ng-container *ngIf="syncMonitoring.endAt==null">-</ng-container>
                                                </td>
                                                <td>
                                                    <ng-container *ngIf="syncMonitoring.endAt==null">
                                                        <span class="send-to-clients">Running</span>
                                                    </ng-container>
                                                    <ng-container *ngIf="syncMonitoring.errorLogs.length > 0 && syncMonitoring.endAt!=null && checkErroHas(syncMonitoring.errorLogs)">
                                                        <span class="expired">Sync Failed</span>
                                                    </ng-container>
                                                    <ng-container *ngIf="syncMonitoring.endAt!=null && !checkErroHas(syncMonitoring.errorLogs)">
                                                        <span class="signed">Sync Completed
                                                            <i *ngIf="checkWarning(syncMonitoring.errorLogs)" class="fa fa-exclamation-triangle expired plr-1" aria-hidden="true"></i>
                                                        </span>
                                                    </ng-container>
                                                </td>
                                                <td class="">
                                                    <div class="btn-group float-right">
                                                        <ng-container *ngIf="syncMonitoring.endAt!=null && syncMonitoring.errorLogs.length > 0">
                                                            <a class="text-center pr-1" target="_blank" (click)="openErrorLogModal(syncMonitoring.errorLogs)" style="float: right;"><i class="la la-arrow-right la_icon"></i></a>
                                                        </ng-container>
                                                        <a class="d-flex align-items-center" *ngIf="syncMonitoring.endAt!=null && syncMonitoring.errorLogs.length > 0"><i class="la la-download float-right" (click)="generateDownloadJsonUri(syncMonitoring)"></i></a>
                                                        <ng-container *ngIf="syncMonitoring.errorLogs.length == 0"><p class="float-right">-</p></ng-container>
                                                    </div>                                        
                                                </td>
                                            </tr>
                                            
                                        </tbody>
                                        <tbody *ngIf="syncMonitoringList.length == 0"
                                            style=" width: 100% !important; text-align: center;">
                                            <tr>
                                                <td colspan="3">No records to show.</td>
                                                <td></td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div [hidden]="!isLoaded" class="grid-pagination-wr col-12">
                        <div class="bg-white d-flex justify-content-between align-items-center mb-3 px-2 rounded-bottom">
                          <span>Showing
                            {{ (this.currentPage == 1) ? this.currentPage : ((this.currentPage * this.itemsPerPage) -  this.itemsPerPage + 1)}}  to
                            {{ (this.itemsPerPage == this.syncMonitoringList?.length) ? (this.currentPage * this.itemsPerPage) :  this.totalRecordsCount }} of
                            {{ this.totalRecordsCount }} entries
                          </span>
                          <pagination
                            [boundaryLinks]="showBoundaryLinks"
                            [directionLinks]="showDirectionLinks"
                            [totalItems]="this.totalRecordsCount"
                            [itemsPerPage]="this.itemsPerPage"
                            (pageChanged)="getSyncMonitoringList($event)"
                            [maxSize]="10">
                          </pagination>
                        </div>
                    </div>

                </div>
            </section>
            <!--/ Zero configuration table -->
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-body text-center">

        <img src="assets/img/warning.png" alt="" class="alert_img">
        <p>{{message}}</p>

        <div class="form-actions center mb-1">
            <button type="button" class="btn alert_no_b mr-1" (click)="decline()">
                No
            </button>
            <button type="button" class="btn alert_yes_b" (click)="confirm()">
                Yes
            </button>
        </div>
    </div>
</ng-template>

<ng-template #templateSendToClients>
    <div class="modal-body text-center">

        <img src="assets/img/warning.png" alt="" class="alert_img">
        <p>{{message}}</p>

        <div class="form-actions center mb-1">
            <button type="button" class="btn alert_no_b mr-1" (click)="decline()">
                No
            </button>
            <button type="button" class="btn alert_yes_b" (click)="confirmSendToClients()">
                Yes
            </button>
        </div>
    </div>
</ng-template>

<app-footer></app-footer>