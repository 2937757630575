<!-- fixed-top-->
<nav class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-light bg-info navbar-shadow">
    <div class="navbar-wrapper">
        <div class="navbar-header">
            <ul class="nav navbar-nav flex-row">
                <li class="nav-item mobile-menu d-md-none mr-auto"><a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#"><i class="ft-menu font-large-1"></i></a></li>
                <li class="nav-item">
                    <a class="navbar-brand" href="/dashboard"> 
                        <img class="brand-logo" alt="modern admin logo" src="{{logoURL}}" style="margin-top: -4px;">
                        <img class="brand-text" alt="modern admin logo" style="width: 125px;" src="{{logoNameURL}}"> 
                    </a>
                    <!-- <img class="brand-text" alt="modern admin logo" style="width: 270px; margin-left: -20px;" src="assets/img/entity1_b2.png" > -->
                </li>
                <li class="nav-item d-md-none"> <a class="nav-link open-navbar-container"   data-toggle="collapse" data-target="#navbar-mobile"><i class="la la-ellipsis-v"></i></a> </li>
            </ul>
        </div>
        <div class="navbar-container content">
            <div class="collapse navbar-collapse" id="navbar-mobile">
                <ul class="nav navbar-nav mr-auto float-left">



                    <li class="nav-item d-none d-md-block"><a (click)="toggleSidebar()" class="nav-link nav-menu-main menu-toggle hidden-xs" ><i class="ft-menu"></i></a></li>
                    
                    
                    
                    
                    <li class="nav-item ">
                        <!-- <fieldset class="form-group position-relative">
                            <input type="text" class="form-control form-control-lg input-lg search nav_search search_in" id="iconLeft1" placeholder=", Groups">
                            <div class="form-control-position nav_i"> <i class="ft-search font-medium-4" style="color: #91929a;"></i> </div>
                        </fieldset> -->
                        <fieldset class="form-group position-relative">

                                <!-- <ng2-completer  class="form-control form-control-lg input-lg search_in nav_search" placeholder="Search Clients" (ngModelChange)="searchClients($event)" [(ngModel)]="searchStr" (selected)="viewClient($event)" [datasource]="dataService" [minSearchLength]="1"></ng2-completer> -->
                                


                                <!-- <input class="form-control form-control-lg input-lg search_in nav_search" (ngModelChange)="searchClients($event)" [(ngModel)]="searchStr" type="text"  placeholder="Search Clients"  id="userIdFirstWay" list="dynmicUserIds" />
                                <div class="form-control-position nav_i"> <i class="ft-search font-medium-4" style="color: #91929a;"></i> </div> -->

                                






                                <!-- <datalist id="dynmicUserIds">
                                   <option  (onchange)="viewClient(client.id)" *ngFor="let client of searchResults" [label]="client.name" [value]="client.name"></option>
                                </datalist> -->

                            <!-- <input type="text" class="form-control form-control-lg input-lg search_in nav_search" id="iconLeft1" placeholder="Search Clients, Groups"> -->
                        </fieldset>
                    </li>
                    <div class="search_b p-1" *ngIf="searchText.length > 0">

                        <ul>
                            <ng-container *ngIf="searchResults.length == 0"> 
                                <a href="javascript:void(0);" class="seach_a">  <li class="search_pad text-center">No result found</li></a>
                            </ng-container>
                            <ng-container *ngIf="searchResults.length >0" > 
                                <ng-container *ngFor="let client of searchResults; let j = index"> 
                                    <a href="/client/view?id={{client.id}}" class="seach_a">  <li class="search_pad">{{client.firstName+' '+client.lastName}}</li></a>
                                </ng-container>
                            </ng-container>
                         
                         <!-- <a href="" class="seach_a">  <li class="search_pad">Hello</li></a>
                         <a href="" class="seach_a">  <li class="search_pad">Hello</li></a>
                         <a href="" class="seach_a">  <li class="search_pad">Hello</li></a>
                         <a href="" class="seach_a">  <li class="search_pad">Hello</li></a> -->
                        </ul>
                        
                     </div>   
                </ul>
                <ul class="nav navbar-nav float-right"> 
                    <li *ngIf="currentUser" class="dropdown dropdown-user nav-item">
                        <a class="dropdown-toggle nav-link dropdown-user-link" href="javascript:void(0)" data-toggle="dropdown"> <span class="mr-1"><span class="head_he">Hello,</span>
                        <span class="user-name text-bold-700">{{currentUser.userName}}</span> </span> <span class="avatar avatar-online" style="border: 1px solid #2cbad3;">
                        <img src="assets/img/user.jpg" alt="avatar"><i></i></span> </a>
                        <div class="dropdown-menu dropdown-menu-right">
                              <!-- <a class="dropdown-item select_it" href="/edit-profile"><i class="ft-user"></i> My Profile</a> -->
                              <!-- <a class="dropdown-item" href="#"><i class="ft-mail"></i> My Inbox</a>
                              <a class="dropdown-item" href="#"><i class="ft-check-square"></i> Task</a>
                              <a class="dropdown-item" href="#"><i class="ft-message-square"></i> Chats</a> -->
                              <!-- <div class="dropdown-divider"></div> -->
                              <a (click)="logOut()" class="dropdown-item select_it" href="javascript:void(0)"><i class="ft-power"></i> Logout</a>
                        </div>
                    </li>
                    <!-- <li class="dropdown dropdown-notification nav-item disabled"> <a class="nav-link" href="#" data-toggle="dropdown" ><i class="ficon ft-bell"></i>
                        <span class="badge badge-pill badge-default badge-danger badge-danger_new badge-default badge-up badge-glow">5</span>
                    </a>
                    </li> -->

                    <!-- <li class="dropdown dropdown-notification nav-item"> <a class="nav-link " href="#" data-toggle="dropdown"><i class="ficon ft-bell"></i>
                        <span class="badge badge-pill badge-default badge-danger badge-default badge-up badge-glow badge-danger_new">5</span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right">
                        <li class="dropdown-menu-header">
                            <h6 class="dropdown-header m-0">
                                <span class="grey darken-2">Notifications</span>
                            </h6> <span class="notification-tag badge badge-default badge-danger float-right m-0">5 New</span>
                        </li>
                        <li class="scrollable-container media-list w-100">
                            <a href="javascript:void(0)">
                                <div class="media">
                                    <div class="media-left align-self-center"><i class="ft-plus-square icon-bg-circle bg-cyan"></i></div>
                                    <div class="media-body">
                                        <h6 class="media-heading">You have new order!</h6>
                                        <p class="notification-text font-small-3 text-muted">Lorem ipsum dolor sit amet, consectetuer elit.</p> <small>
                                            <time class="media-meta text-muted" datetime="2015-06-11T18:29:20+08:00">30 minutes ago</time>
                                        </small>
                                    </div>
                                </div>
                            </a>
                            <a href="javascript:void(0)">
                                <div class="media">
                                    <div class="media-left align-self-center"><i class="ft-download-cloud icon-bg-circle bg-red bg-darken-1"></i></div>
                                    <div class="media-body">
                                        <h6 class="media-heading red darken-1">99% Server load</h6>
                                        <p class="notification-text font-small-3 text-muted">Aliquam tincidunt mauris eu risus.</p> <small>
                                            <time class="media-meta text-muted" datetime="2015-06-11T18:29:20+08:00">Five hour ago</time>
                                        </small>
                                    </div>
                                </div>
                            </a>
                            <a href="javascript:void(0)">
                                <div class="media">
                                    <div class="media-left align-self-center"><i class="ft-alert-triangle icon-bg-circle bg-yellow bg-darken-3"></i></div>
                                    <div class="media-body">
                                        <h6 class="media-heading yellow darken-3">Warning notifixation</h6>
                                        <p class="notification-text font-small-3 text-muted">Vestibulum auctor dapibus neque.</p> <small>
                                            <time class="media-meta text-muted" datetime="2015-06-11T18:29:20+08:00">Today</time>
                                        </small>
                                    </div>
                                </div>
                            </a>
                            <a href="javascript:void(0)">
                                <div class="media">
                                    <div class="media-left align-self-center"><i class="ft-check-circle icon-bg-circle bg-cyan"></i></div>
                                    <div class="media-body">
                                        <h6 class="media-heading">Complete the task</h6> <small>
                                            <time class="media-meta text-muted" datetime="2015-06-11T18:29:20+08:00">Last week</time>
                                        </small>
                                    </div>
                                </div>
                            </a>
                            <a href="javascript:void(0)">
                                <div class="media">
                                    <div class="media-left align-self-center"><i class="ft-file icon-bg-circle bg-teal"></i></div>
                                    <div class="media-body">
                                        <h6 class="media-heading">Generate monthly report</h6> <small>
                                            <time class="media-meta text-muted" datetime="2015-06-11T18:29:20+08:00">Last month</time>
                                        </small>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="dropdown-menu-footer"><a class="dropdown-item text-muted text-center" href="javascript:void(0)">Read all notifications</a></li>
                    </ul>
                </li> -->
                
                    
                    
                </ul>
            </div>
        </div>
    </div>
</nav>
