export class XFacEmploymentBaseMOdel {
    public  facFinderId : string;
    public  clientCode : string;
    public  employer : string;
    public  status : string;
    public  occupation : string;
    public  employerStreet : string;
    public  employerSuburb : string;
    public  employerState : string;
    public  employerPostcode : string;
    public  employerCountry : string;
}

export class XFacEmploymentAddModel extends XFacEmploymentBaseMOdel{    
    public occupationName : string;
}