import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentUrlResponse } from 'src/app/shared/models/response.model';
import { UserDocumentsService } from 'src/app/user-documents/user-documents.service';

@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.css']
})
export class ViewDocumentComponent implements OnInit {

  data;
  serviceDocuments:any;
  userId:string;
  event: EventEmitter<any> = new EventEmitter();


  constructor(
    private userDocumentService: UserDocumentsService,
    public modalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    if(this.data.serviceDocuments) {
      this.serviceDocuments = this.data.serviceDocuments
    }
    if(this.data.userId) {
      this.userId = this.data.userId
    }
  }

  viewDocument(s3Link){
    let userDocument = {
                    "documentURL":s3Link, 
                    "documentType": 5
                  }
    this.userDocumentService.getDocumentUrl(userDocument).subscribe((data:DocumentUrlResponse)=>{
      let documentUrl = data.response;
      //console.log('document url ---------');
      //console.log(documentUrl);
      window.open(documentUrl, "_blank");
      //this.isLoaded = true;
    },
      (err: HttpErrorResponse)=>{
      console.log("Error"+ err.message);
    });
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

}
