import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConstants } from '../utility/app-constants';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { EmailSetupResponse } from '../shared/models/response.model';

@Injectable({
  providedIn: 'root'
})
export class EmailSetupService {

  authUrl: String;
  getStaffApiURL: String;

  constructor(private http: HttpClient) { 
    this.authUrl = AppConstants.getAuthURL();
    this.getStaffApiURL = AppConstants.getStaffApiURL();
    
  }

  public handleError(error) {
    return throwError(error);
  }

  public departmentList(){
    //GetAllDepartments
    let companyId = JSON.parse(localStorage.getItem('businessId'));
    // console.log(companyId);
    const params = new HttpParams().set('companyId', companyId); 
    //return this.http.get<any>(this.authUrl + '/Department/GetByCompanyId',{params}).pipe(map(data => {
    // return this.http.get<any>(this.authUrl + '/Department/GetAllDepartments',{params}).pipe(map(data => {
    //     localStorage.removeItem('departmentList');      
    //   if (data.isSuccess) {
    //     if(data.data) { localStorage.setItem('departmentList', JSON.stringify(data.data));}      
        
    //   } else{
    //     return data;
    //   }
    // return data;
    // }));

    return this.http.get<any>(this.authUrl + '/Department/GetAllDepartments',{params});  //GetByCompanyId , GetAllDepartments
  }

  public saveDepartmentLeaveApprover(emailSetup){      
    return this.http.post<EmailSetupResponse>(this.getStaffApiURL +'/DepartmentLeaveApprover',emailSetup).pipe(
      catchError(this.handleError)
      );
  }

  public departmentUserList(depatmentId){
    //GetAllDepartmentsUser
    console.log(depatmentId);
    const params = new HttpParams().set('departmentId', depatmentId); 
    
    return this.http.get<any>(this.getStaffApiURL + '/DepartmentLeaveApprover/GetByDepartmentId',{params}); 
  }

  public deleteNewsFeed(id:string){
    const params = new HttpParams().set('id', id); 
    return this.http.delete<Response>(this.getStaffApiURL +'/DepartmentLeaveApprover',{params}).pipe(
      catchError(this.handleError)
      );     
  }

  public changeEmailUserType(id:string, emailReferenceType){
    const params = new HttpParams().set('id', id).set('emailReferenceType', emailReferenceType); 
    let update= {emailReferenceType:emailReferenceType}
    return this.http.put<EmailSetupResponse>(this.getStaffApiURL +'/DepartmentLeaveApprover?id='+id+'&emailReferenceType='+emailReferenceType,update).pipe(
      catchError(this.handleError)
      );     

    // return this.http.put<EmailSetupResponse>(this.getStaffApiURL+'/DepartmentLeaveApprover',{params}).pipe(
    //   catchError(this.handleError)
    //   );
  }

}
