<app-header></app-header>
<app-sidebar></app-sidebar>


<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1">
      <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
        <h3 class="content-header-title mb-0 d-inline-block">Clients</h3>
        <div class="row breadcrumbs-top d-inline-block">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/dashboard">Home</a>
              </li>
              <li class="breadcrumb-item"><a href="/clients">Clients</a>
              </li>
              <li class="breadcrumb-item active"><a href="javascript:void(0);" [routerLink]="['/client/view']" [queryParams]="{ id: clientId }">Client Profile</a></li>
              <li class="breadcrumb-item active">
                Fact Finder
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="content-body responsiveff">
      <!-- Zero configuration table -->
      <!-- <section id="configuration"> -->
      <div class="row">
        <div class="col-xl-4 col-md-4">
          <div class="row">
            <div class="col-12 ">
              <div class="card">
                <div class="card-header card_head_fs" style="margin-bottom: -20px;">
                  <h4 class="card-title">{{name}}
                    <span style="display: block; font-size: 13px; margin-top: 5px;">Fact Finder Version List</span>
                  </h4>
                  <!-- <div class="btn-group float-md-right">
                    <button class="btn btn-sm app_document_btn" type="button" style="margin-top: -28px;">Remind
                      Update</button>
                  </div> -->
                </div>
                <div class="card-content collapse show">
                  <div class="card-body1 card-dashboard" style="width: 100%;">
                    <ng-container *ngIf="isLoaded1">
                      <table class="table table-striped table-bordered zero-configuration t_dt table-responsive"
                        id="example" style="margin-top: 0px !important; display: inline-table; margin-bottom: 0px;" width="100%">
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngIf="factFindersNewLength>0">
                            <ng-container *ngFor="let factFinder of factFindersNew; let i = index">
                                <!-- <tr [ngClass]="(i=='0')?'factfinderr active':'factfinderr'"> -->
                                <tr  (click)="setFacId(factFinder, i)" [ngClass]="{'factfinderr active':i === selectedRow}" class="factfinderlistsi">
                                  

                                    <td style="padding-top: 15px;">VERSION {{factFinder.versionNumber | number:'1.1-2' }} (
                                      <ng-container *ngIf="factFinder.id && factFinder.id == facId">
                                        <ng-container *ngIf="recordType == 1">Draft</ng-container>
                                        <ng-container *ngIf="recordType == 2">Completed by client</ng-container>
                                        <ng-container *ngIf="recordType == 3">Reviewed</ng-container>
                                        <ng-container *ngIf="recordType == 4">Submitted to XPLAN</ng-container>
                                      </ng-container>
                                      <ng-container *ngIf="factFinder.id && factFinder.id != facId">
                                        <ng-container *ngIf="factFinder.recordType == 1">Draft</ng-container>
                                        <ng-container *ngIf="factFinder.recordType == 2">Completed by client</ng-container>
                                        <ng-container *ngIf="factFinder.recordType == 3">Reviewed</ng-container>
                                        <ng-container *ngIf="factFinder.recordType == 4">Submitted to XPLAN</ng-container>
                                      </ng-container>
                                        
                                      )
                                      <br><span>Last Updated : {{ factFinder.lastUpdatedDate +"Z" | date: 'M/d/yy, h:mm a' }} </span> <!--12/02.2019 2:20PM -->
                                    </td>
                                    <td class="f_icn"> 
                                      <a href="javascript:void(0)" data-toggle="modal" data-target="#exampleModal" >
                                      <i [ngClass]="{'la la-angle-right float-right':i === selectedRow}"></i> 
                                      </a>
                                    </td>
                                  
                                </tr>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="factFindersNewLength==0">
                              <tr class="factfinderr active">
                                <td>VERSION LIST IS EMPTY
                                  <br><span></span>
                                </td>
                                <td class="f_icn"> 
                                  <!-- <a href="#" data-toggle="modal" data-target="#exampleModal">
                                  <i class="la la-angle-right"></i></a>  -->
                                </td>
                              </tr>
                          </ng-container>
                          <!-- <tr class="factfinderr active">
                            <td>VERSION 03
                              <br><span>Last Updated : 12/02.2019 2:20PM</span>
                            </td>
                            <td class="f_icn"> <a href="#" data-toggle="modal" data-target="#exampleModal">
                              <i class="la la-angle-right"></i></a> 
                            </td>
                          </tr>
                          <tr>
                            <td>VERSION 02
                              <br><span>Last Updated : 12/02.2019 2:20PM</span>
                            </td>
                            <td class="f_icn"> <a href="#"><i class="la la-angle-right"></i></a> </td>
                          </tr>
                          <tr>
                            <td>VERSION 01
                              <br><span>Last Updated : 12/02.2019 2:20PM</span>
                            </td>
                            <td class="f_icn"> <a href="#"><i class="la la-angle-right"></i></a> </td>
                          </tr> -->
                        </tbody>
                      </table>
                    </ng-container>
                  </div>
                  
                </div>
                <div *ngIf="!draftExist" class="version_div">
                    If you need an update from your client side, Create a draft version                      
                    <a href="javascript:void(0);" (click)="openCreateNewVersion();"> CREATE NOW </a>

                    <!-- <div class="modal fade" id="updateremind" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-body text-center xplanmodal" >
                            <p>Are you sure you want to send <br><span>"Update Reminder"</span> Request?</p>
                            <div class="form-actions center">
                              <button type="button" class="btn alert_yes_b mr-1">
                                   Yes
                              </button>                          
                              <button type="button" class="btn alert_no_b"> 
                                No
                            </button>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div> -->

                  </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8 col-md-8 col-sm-12">
          <div class="card">
            <ng-container *ngIf="isLoaded1">
              <ng-container *ngIf="factFindersNewLength>0">
                <div class="card-header card_head_fs">
                  
                    <h4 class="card-title">Version {{versionNumber}} <span class="nff_title"><!-- (Admin updated) --></span>
                      <!-- <i class="la la-info-circle status_updatecss"  (click)="openStatusList(template_status)" ></i> -->
                    </h4>
                  
                  

                  <div class="btn-group float-right responsffbtn_n">
                    <button class="btn btn-sm app_document_btn" type="button" style="margin-top: -28px; border-radius: 100px; margin-right: 15px;"  (click)="openStatusList(template_status)" >View Status History</button>
                    <!-- <button class="btn btn-sm app_document_btn" *ngIf="recordType && recordType==3" type="button" style="margin-top: -28px; border-radius: 100px; margin-right: 15px;" (click)="showSubmitToXplan(template1)" >Submit to XPLAN</button> -->

                    <!-- <input type="button" value="CPTURE" (click)="captureScreen()"/>  -->
                    <!-- <ng-container *ngIf="buttonShow"> -->
                      <button class="btn btn-sm app_document_btn" type="button" style="margin-top: -28px; border-radius: 100px;" (click)="openDownloadConfirmModal()" >View as a Document</button>
                    <!-- </ng-container> -->
                  </div>
                </div>
                <div class="card collapse-icon panel mb-0 box-shadow-0 border-0 collapse-icon_n mt-0">
                  <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff">
                      <!-- data-toggle="collapse" data-parent="#accordionWrap1" href="#pd1" -->
                    <a  href="javascript:void(0);" data-toggle="collapse" [ngClass]="{ 'collapsed': !showPersonalDetails }" (click)="clickPersonalDetails()" [attr.aria-expanded]="showPersonalDetails"
                      aria-controls="accordion13" class="h6 blue  prof_head">Personal Details</a>
                    <ng-container *ngIf="buttonShow">
                      <a href="javascript:void(0);" (click)="openPersonalDetailsModal(template, personalData)" *ngIf="showPersonalDetails && buttonShow" class="ff_editbtn"><i class="la la-pencil"></i></a> 
                    </ng-container>
                  </div>
                  <div id="pd1" role="tabpanel" aria-labelledby="heading13" [ngClass]="{ 'show': showPersonalDetails }" class="collapse " [attr.aria-expanded]="showPersonalDetails">
                    <div class="card-body card_body_prof_ff table-responsive">
                      <ng-container *ngIf="isLoaded2">
                        <!-- <ng-container *ngIf="personalInfoLength>0"> -->
                        <ng-container *ngIf="personalData">
                            <table class="table table-borderless table-sm pro_tab  d-md-table mb-0">
                                <tbody>
                                  <tr>
                                    <td><span class="ffn_dot text-truncate"></span>&nbsp;Title</td>
                                    <td class="profile_tdff text-left text-truncate">: <span class="pd_textff">
                                      <ng-container *ngIf="personalData.title">
                                        <ng-container *ngIf="personalData.title == 1">Mr</ng-container>
                                        <ng-container *ngIf="personalData.title == 2">Mrs</ng-container>
                                        <ng-container *ngIf="personalData.title == 3">Miss</ng-container>
                                        <ng-container *ngIf="personalData.title == 4">Ms</ng-container>
                                        <ng-container *ngIf="personalData.title == 5">Dr</ng-container>
                                        <ng-container *ngIf="personalData.title == 6">Prof</ng-container>
                                      </ng-container>
                                      <ng-container *ngIf="personalData.title == null || personalData.title == ''">-</ng-container>
                                    </span>                
                                    </td>
                                    <!-- <td> <a href="" class="ff_editbtn"  data-toggle="modal" data-target="#edittitle"><i class="la la-pencil"></i></a>
                                    
                                      <div class="modal fade" id="edittitle" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                          <div class="modal-content">
                                            <div class="modal-header border-bottom-0">
                                              <h5 class="modal-title factfinderheader" id="exampleModalLabel">Personal Details</h5>
                                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                              </button>
                                            </div>
                                            <div class="modal-body factfinderbody">
                                              <div class="form-group row factfinderpopup">
                                                <label for="inputPassword" class="labelwidth">Title</label>
                                                <div class="inputwidth"> <span>:</span>
                                                  <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Title">
                                                </div>
                                              </div>                               
                                            </div>
                                            <div class="modal-footer border-top-0 pt-0">
                                              <button type="button" class="btn btn-primary factfinderbtn">Save</button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      
                                    </td> -->
                                  </tr>
                                  
                                  <tr>
                                    <td><span class="ffn_dot text-truncate"></span>&nbsp;First Name</td>
                                    <td class="text-left profile_tdff text-truncate">: <span class="pd_textff">
                                      <ng-container *ngIf="personalData.firstName">{{ personalData.firstName }}</ng-container>
                                      <ng-container *ngIf="personalData.firstName==null || personalData.firstName == ''">-</ng-container></span></td>
                                    <!-- <td> <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td> -->
                                  </tr>
                                  <tr>
                                      <td><span class="ffn_dot text-truncate"></span>&nbsp;Second Name</td>
                                      <td class="text-left profile_tdff text-truncate">: <span class="pd_textff">
                                        <ng-container *ngIf="personalData.middleName">{{ personalData.middleName }}</ng-container>
                                        <ng-container *ngIf="personalData.middleName==null || personalData.middleName==''">-</ng-container></span></td>
                                      <!-- <td> <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td> -->
                                  </tr>
                                  <tr>
                                    <td><span class="ffn_dot text-truncate"></span>&nbsp;Surname</td>
                                    <td class="text-left profile_tdff text-truncate">: <span class="pd_textff">
                                        <ng-container *ngIf="personalData.surname">{{ personalData.surname }}</ng-container> <ng-container *ngIf="personalData.surname==null">-</ng-container></span>
                                    </td>
                                    <!-- <td> <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td> -->
                                  </tr>
                                  <tr>
                                    <td><span class="ffn_dot text-truncate"></span>&nbsp;Gender</td>
                                    <td class="text-left profile_tdff text-truncate">: <span class="pd_textff">
                                      <ng-container *ngIf="personalData.gender">
                                        <ng-container *ngIf="personalData.gender == 1">Male</ng-container>
                                        <ng-container *ngIf="personalData.gender == 0">Female</ng-container>
                                      </ng-container>
                                      <ng-container *ngIf="personalData.gender == null || personalData.gender == ''">-</ng-container>
                                    </span></td>
                                    <!-- <td> <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td> -->
                                  </tr>
                                  <tr>
                                      <td><span class="ffn_dot text-truncate"></span>&nbsp;Date of Birth</td>
                                      <td class="text-left profile_tdff text-truncate">: <span class="pd_textff"><ng-container *ngIf="personalData.dateOfBirth">{{ personalData.dateOfBirth +"Z" | date: 'MMM d, y' }}</ng-container><ng-container *ngIf="personalData.dateOfBirth==null">-</ng-container></span></td>
                                      <!-- <td> <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td> -->
                                  </tr>
                                  <tr>
                                    <td><span class="ffn_dot text-truncate"></span>&nbsp;Marital Status</td>
                                    <td class="text-left profile_tdff text-truncate">: <span class="pd_textff">
                                      <ng-container *ngIf="personalData.maritalStatus">
                                        <ng-container *ngIf="personalData.maritalStatus == 1">Single</ng-container>
                                        <ng-container *ngIf="personalData.maritalStatus == 2">Married</ng-container>
                                        <ng-container *ngIf="personalData.maritalStatus == 5">Engaged</ng-container>
                                        <ng-container *ngIf="personalData.maritalStatus == 999">De Facto</ng-container>
                                        <ng-container *ngIf="personalData.maritalStatus == 3">Separated</ng-container>
                                        <ng-container *ngIf="personalData.maritalStatus == 4">Divorced</ng-container>
                                        <ng-container *ngIf="personalData.maritalStatus == 6">Widowed</ng-container>
                                      </ng-container>
                                      <ng-container *ngIf="personalData.maritalStatus == null || personalData.maritalStatus == ''">-</ng-container>
                                      </span>
                                    </td>
                                    <!-- <td> <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td> -->
                                  </tr>
                                  <tr>
                                    <td><span class="ffn_dot text-truncate"></span>&nbsp;Tax File Number</td>
                                    <td class="text-left profile_tdff text-truncate">: <span class="pd_textff"><ng-container *ngIf="personalData.taxFileNumber">{{ personalData.taxFileNumber }}</ng-container><ng-container *ngIf="personalData.taxFileNumber==null">-</ng-container></span></td>
                                    <!-- <td> <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td> -->
                                  </tr>
                                  <tr>
                                    <td><span class="ffn_dot text-truncate"></span>&nbsp;Smoker</td>
                                    <td class="text-left profile_tdff text-truncate">: <span class="pd_textff">
                                      <ng-container *ngIf="personalData.smoker">
                                        <ng-container *ngIf="personalData.smoker == true">Yes</ng-container>
                                        <ng-container *ngIf="personalData.smoker == false">No</ng-container>
                                      </ng-container>
                                      <ng-container *ngIf="personalData.smoker==null || personalData.smoker== ''">No</ng-container>
                                      </span>
                                    </td>
                                    <!-- <td> <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td> -->
                                  </tr>
                                  <!-- <tr>
                                    <td class="special_ff"><span class="ffn_dot"></span>&nbsp;No any special notes added</td>
                                  </tr> -->
                                </tbody>
                            </table>
                          </ng-container>
                          <ng-container *ngIf="personalData==null">
                              <div class="">
                                <img src="assets/img/empty_icon.png" alt="" height="45px;">
                                <span style="padding: 10px 5px;">No data</span>
                              </div>
                          </ng-container>
                        <!-- </ng-container> -->
                        <!-- <ng-container *ngIf="personalInfoLength==0 || personalInfoLength ==null || personalInfoLength==''">
                          <div class="">
                            <img src="assets/img/empty_icon.png" alt="" height="45px;">
                            <span style="padding: 10px 5px;">No data</span>
                          </div>
                        </ng-container> -->
                      </ng-container>

                    <!--                   
                      <a href="#" class="new_record_sin" data-toggle="modal" data-target="#addpersonal">ADD NEW RECORD</a>
                        <div class="modal fade" id="addpersonal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header border-bottom-0">
                                <h5 class="modal-title factfinderheader" id="exampleModalLabel">Personal Details</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body factfinderbody">
                                <div class="form-group row factfinderpopup">
                                  <label for="inputPassword" class="labelwidth">Title</label>
                                  <div class="inputwidth"> <span>:</span>
                                    <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Title">
                                  </div>
                                </div>
                                <div class="form-group row factfinderpopup">
                                  <label for="inputPassword" class="labelwidth">Surname</label>
                                  <div class="inputwidth"> <span>:</span>
                                    <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Surname">
                                  </div>
                                </div>
                                <div class="form-group row factfinderpopup">
                                  <label for="inputPassword" class="labelwidth">First Name</label>
                                  <div class="inputwidth"> <span>:</span>
                                    <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="First Name">
                                  </div>
                                </div>
                                <div class="form-group row factfinderpopup">
                                  <label for="inputPassword" class="labelwidth">Second Name</label>
                                  <div class="inputwidth"> <span>:</span>
                                    <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Second Name">
                                  </div>
                                </div>
                                <div class="form-group row factfinderpopup">
                                  <label for="inputPassword" class="labelwidth">Gender</label>
                                  <div class="inputwidth"> <span>:</span>
                                    <select class="form-control form-control-sm" >
                                      <option>Gender</option>
                                      <option>Male</option>
                                      <option>Female</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="form-group row factfinderpopup">
                                  <label for="inputPassword" class="labelwidth">Date of Birth</label>
                                  <div class="inputwidth"> <span>:</span>
                                    <input type="text" class="form-control form-control-sm"  name="dueDate" 
                                    (ngModelChange)="onChange($event)"
                                    [bsValue]="currentDate"
                                    [minDate]="currentDate"
                                    placement="top"
                                    bsDatepicker                                      
                                    [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"  placeholder="Date of Birth">                               
                                  </div>
                                </div>
                                <div class="form-group row factfinderpopup">
                                  <label for="inputPassword" class="labelwidth">Marital Status</label>
                                  <div class="inputwidth"> <span>:</span>
                                    <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Marital Status">
                                  </div>
                                </div>
                                <div class="form-group row factfinderpopup">
                                  <label for="inputPassword" class="labelwidth">Tax File Number</label>
                                  <div class="inputwidth"> <span>:</span>
                                    <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Tax File Number">
                                  </div>
                                </div>
                                <div class="form-group row factfinderpopup">
                                  <label for="inputPassword" class="labelwidth">Smoker</label>
                                  <div class="inputwidth"> <span>:</span>
                                    <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Smoker">
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer border-top-0 pt-0">
                                <button type="button" class="btn btn-primary factfinderbtn">Save</button>
                              </div>
                            </div>
                          </div>
                        </div> -->


                    </div>
                  </div>




                  <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> 
                    <a href="javascript:void(0);" data-toggle="collapse" [ngClass]="{ 'collapsed': !showContactDetails }" (click)="clickContactDetails()" [attr.aria-expanded]="showContactDetails" aria-controls="accordion13" class="h6 blue collapsed prof_head">Contact Details</a> 
                    <!-- <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a> -->
                  </div>
                  <div id="goal1" role="tabpanel" aria-labelledby="heading13" [ngClass]="{ 'show': showContactDetails }" class="collapse" [attr.aria-expanded]="showContactDetails">
                    <div class="card-body card_body_prof_ff  table-responsive">   
                      <ng-container *ngIf="isLoaded3">                
                        <table class="table table-borderless table-sm pro_tab mb-0 d-md-table">
                            <tbody>
                                <tr><td colspan="3"><span class="ffn_submenu">Telephone & Email</span><td></tr>
                                <ng-container *ngIf="contactInfoLength>0">
                                  <ng-container *ngFor="let contact of contactInfo; let i = index">
                                    <tr class="">
                                      <td><span class="ffn_dot text-truncate"></span>&nbsp;{{contact.contactTypeName}}</td>
                                      <td class="text-left profile_tdff text-truncate">: <span class="pd_textff">{{contact.contactValue}}</span>
                                      </td>
                                      <td>
                                          <ng-container *ngIf="buttonShow">
                                              <a href="javascript:void(0);" (click)="openContactEdit(contact)" ><i class="la la-pencil"></i></a> 
                                          </ng-container>
                                          
                                      </td>
                                      <td>
                                        <ng-container *ngIf="buttonShow">
                                          <a href="javascript:void(0);" (click)="openContactConfirmationDialog(contactDetailsTemplate,contact)"><i class="la la-trash"></i></a>
                                        </ng-container>
                                        </td>
                                    </tr>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="contactInfoLength==0">
                                  <div class="">
                                    <img src="assets/img/empty_icon.png" alt="" height="45px;">
                                    <span style="padding: 10px 5px;">No data</span>
                                  </div>
                                </ng-container>
                                    
                                      <!-- <div class="modal fade" id="editcontact" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                          <div class="modal-content">
                                            <div class="modal-header border-bottom-0">
                                              <h5 class="modal-title factfinderheader" id="exampleModalLabel">Personal Details</h5>
                                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                              </button>
                                            </div>
                                            <div class="modal-body factfinderbody">
                                              <div class="form-group row factfinderpopup">
                                                <label for="inputPassword" class="labelwidth">Mobile Phone</label>
                                                <div class="inputwidth"> <span>:</span>
                                                  <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Mobile Phone">
                                                </div>
                                              </div>
                                              <div class="form-group row factfinderpopup">
                                                <label for="inputPassword" class="labelwidth">Home Phone</label>
                                                <div class="inputwidth"> <span>:</span>
                                                  <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Home Phone">
                                                </div>
                                              </div>
                                              <div class="form-group row factfinderpopup">
                                                <label for="inputPassword" class="labelwidth">Work Phone</label>
                                                <div class="inputwidth"> <span>:</span>
                                                  <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Work Phone">
                                                </div>
                                              </div>
                                              <div class="form-group row factfinderpopup">
                                                <label for="inputPassword" class="labelwidth">Home Email</label>
                                                <div class="inputwidth"> <span>:</span>
                                                  <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Home Email">
                                                </div>
                                              </div>
                                              <div class="form-group row factfinderpopup">
                                                <label for="inputPassword" class="labelwidth">Work Email</label>
                                                <div class="inputwidth"> <span>:</span>
                                                  <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Work Email">
                                                </div>
                                              </div>
                                            </div>
                                            <div class="modal-footer border-top-0 pt-0">
                                              <button type="button" class="btn btn-primary factfinderbtn">Save</button>
                                            </div>
                                          </div>
                                        </div>
                                      </div> -->
                                      
                                    
                                
                                <!-- <tr>
                                    <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;912-215-456&nbsp;&nbsp;</td>
                                    <td class="text-truncate">912-215-456&nbsp;&nbsp;</td>
                                    <td class="text-truncate">912-215-456&nbsp;&nbsp;</td>
                                    <td class="text-truncate">test123@email.com&nbsp;&nbsp;</td>
                                    <td class="text-truncate">test123@email.com&nbsp;&nbsp;</td>
                                    <td> <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td>
                                </tr> -->
                            </tbody>
                        </table> 
                      </ng-container>
                      <ng-container *ngIf="buttonShow">
                        <a href="javascript:void(0);" (click)="openContactAddModal();" class="new_record">ADD NEW RECORD</a>
                      </ng-container>
                      <ng-container *ngIf="isLoaded4">
                        <table class="table table-borderless table-sm pro_tab mb-0 d-md-table">
                            <tbody>
                                <tr><span class="ffn_submenu">Address</span></tr>
                                <ng-container *ngIf="addressInfoLength>0">
                                  <tr class="fftr fupper">
                                      <td class="font-weight-bold text-truncate">Type &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate">Street &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate">Suburb &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate ">State &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate ">Postcode &nbsp;&nbsp;</td>
                                      <ng-container *ngIf="buttonShow">
                                        <td></td>
                                        <td></td>
                                      </ng-container>
                                  </tr>
                                  <ng-container *ngFor="let address of addressInfo; let i = index">
                                      <tr>
                                        <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;
                                          <!-- <ng-container *ngIf="address.addressType == 0">Postal</ng-container>
                                          <ng-container *ngIf="address.addressType == 1">Residential</ng-container>
                                          <ng-container *ngIf="address.addressType == 2">Business</ng-container>
                                          <ng-container *ngIf="address.addressType == 'registered'">Registered</ng-container>
                                          <ng-container *ngIf="address.addressType == 'post_settlement'">Post Settlement</ng-container> -->
                                          {{address.addressTypeName}}
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                            <ng-container *ngIf="address.street">{{address.street}}</ng-container>
                                            <ng-container *ngIf="address.street == '' || address.street == null">-</ng-container>
                                          &nbsp;&nbsp;
                                        </td>
                                        <td class="text-truncate">
                                            <ng-container *ngIf="address.suburb">{{address.suburb}}</ng-container>
                                            <ng-container *ngIf="address.suburb == '' || address.suburb == null">-</ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                            <ng-container *ngIf="address.state">{{address.state}}</ng-container>
                                            <ng-container *ngIf="address.state == '' || address.state == null">-</ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                            <ng-container *ngIf="address.postcode">{{address.postcode}}</ng-container>
                                            <ng-container *ngIf="address.postcode == '' || address.postcode == null">-</ng-container>
                                        &nbsp;&nbsp;</td>

                                        <ng-container *ngIf="buttonShow">
                                          <td> 
                                            <a href="javascript:void(0);" (click)="openAddressEdit(address);" class="ff_editbtn"><i class="la la-pencil"></i></a>
                                          </td>
                                          <td>
                                            <a href="javascript:void(0);" (click)="openAddressConfirmationDialog(addressDetailsTemplate,address)"><i class="la la-trash"></i></a>
                                          </td>
                                        </ng-container>

                                      </tr>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="addressInfoLength==0">
                                  <div class="">
                                    <img src="assets/img/empty_icon.png" alt="" height="45px;">
                                    <span style="padding: 10px 5px;">No data</span>
                                  </div>
                                </ng-container>
                                
                                <!-- <tr>
                                    <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;912-215-456&nbsp;&nbsp;</td>
                                    <td class="text-truncate">912-215-456&nbsp;&nbsp;</td>
                                    <td class="text-truncate">912-215-456&nbsp;&nbsp;</td>
                                    <td class="text-truncate">test123@email.com&nbsp;&nbsp;</td>
                                    <td class="text-truncate">test123@email.com&nbsp;&nbsp;</td>
                                    <td> <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td>
                                </tr>
                                <tr>
                                    <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;912-215-456&nbsp;&nbsp;</td>
                                    <td class="text-truncate">912-215-456&nbsp;&nbsp;</td>
                                    <td class="text-truncate">912-215-456&nbsp;&nbsp;</td>
                                    <td class="text-truncate">test123@email.com&nbsp;&nbsp;</td>
                                    <td class="text-truncate">test123@email.com&nbsp;&nbsp;</td>
                                    <td> <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td>
                                </tr> -->
                            </tbody>
                        </table> 
                      </ng-container>
                      <ng-container *ngIf="buttonShow">
                        <a href="javascript:void(0);" (click)="openAddressAddModal();" class="new_record">ADD NEW RECORD</a>
                      </ng-container>
                    </div>
                  </div>

                  <ng-template #contactDetailsTemplate>
                      <div class="modal-body text-center" >
                  
                      <img src="assets/img/warning.png" alt="" class="alert_img">
                      <!-- <img *ngIf="status==false" src="assets/img/verified.png" alt="" class="alert_img"> -->
                      <p>{{message}}</p>
                              
                          <div class="form-actions center mb-1">
                              <button type="button" class="btn alert_no_b mr-1" (click)="decline(contact)"> 
                                  No
                              </button>
                              <button type="button" class="btn alert_yes_b" (click)="confirmContactDelete(contact)" >
                                      Yes
                              </button>
                          </div>
                      </div>
                  </ng-template>

                  <ng-template #addressDetailsTemplate>
                      <div class="modal-body text-center" >
                  
                      <img src="assets/img/warning.png" alt="" class="alert_img">
                      <!-- <img *ngIf="status==false" src="assets/img/verified.png" alt="" class="alert_img"> -->
                      <p>{{message}}</p>
                              
                          <div class="form-actions center mb-1">
                              <button type="button" class="btn alert_no_b mr-1" (click)="decline(address)"> 
                                  No
                              </button>
                              <button type="button" class="btn alert_yes_b" (click)="confirmAddressDelete(address)" >
                                      Yes
                              </button>
                          </div>
                      </div>
                  </ng-template>


                  <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> 
                      <a href="javascript:void(0);" data-toggle="collapse" [ngClass]="{ 'collapsed': !showAssets }" (click)="clickAssets()" [attr.aria-expanded]="showAssets" aria-controls="accordion13" class="h6 blue collapsed prof_head">Assets</a> 
                      <!-- <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a> -->
                    </div>
                    <div id="al1" role="tabpanel" aria-labelledby="heading13" [ngClass]="{ 'show': showAssets }" class="collapse" [attr.aria-expanded]="showAssets">
                      <div class="card-body card_body_prof_ff table-responsive"> 
                        <ng-container *ngIf="isLoaded8">                  
                          <table class="table table-borderless table-sm pro_tab mb-0 d-md-table">
                              <tbody>
                                  <tr><span class="ffn_submenu">Assets</span></tr>
                                  <ng-container *ngIf="assetInfoLength>0">
                                    <tr class="fftr fupper">
                                        <td class="font-weight-bold text-truncate">Type &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Subtype &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Current Value &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate ">Description &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate ">Owner &nbsp;&nbsp;</td>
                                        <ng-container *ngIf="buttonShow">
                                          <td></td>
                                          <td></td>
                                        </ng-container>
                                    </tr>
                                    <ng-container *ngFor="let asset of assetInfo; let i = index">
                                      <tr>
                                          <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;
                                            <!-- <ng-container *ngIf="asset.type == 1">Liquid Assets</ng-container>
                                            <ng-container *ngIf="asset.type == 2">Investments</ng-container>
                                            <ng-container *ngIf="asset.type == 3">Real Estate</ng-container>
                                            <ng-container *ngIf="asset.type == 4">Retirement</ng-container>
                                            <ng-container *ngIf="asset.type == 5">Life Style</ng-container>
                                            <ng-container *ngIf="asset.type == 7">Business Assets</ng-container> -->
                                            <ng-container *ngIf="asset.assetTypeName">{{asset.assetTypeName}}</ng-container>
                                            <ng-container *ngIf="asset.assetTypeName=='' || asset.assetTypeName==null">-</ng-container>
                                          &nbsp;&nbsp;</td>
                                          <td class="text-truncate">
                                              <!-- <ng-container *ngIf="asset.subType == '101'">Cash on Hand</ng-container>
                                              <ng-container *ngIf="asset.subType == '102'">Current Savings</ng-container>
                                              <ng-container *ngIf="asset.subType == '103'">Fixed Deposits</ng-container>
                                              <ng-container *ngIf="asset.subType == '201'">Bonds</ng-container>
                                              <ng-container *ngIf="asset.subType == '299'">Other Investments</ng-container>
                                              <ng-container *ngIf="asset.subType == '238'">Platforms</ng-container>
                                              <ng-container *ngIf="asset.subType == '202'">Stocks</ng-container>
                                              <ng-container *ngIf="asset.subType == '203'">Unit Trusts</ng-container>
                                              <ng-container *ngIf="asset.subType == '302'">Investment Property</ng-container>
                                              <ng-container *ngIf="asset.subType == '301'">Primary Residence</ng-container>
                                              <ng-container *ngIf="asset.subType == '401'">Annuity</ng-container>
                                              <ng-container *ngIf="asset.subType == '402'">Pension</ng-container>
                                              <ng-container *ngIf="asset.subType == '403'">Superannuation</ng-container>
                                              <ng-container *ngIf="asset.subType == '501'">Antiques</ng-container>
                                              <ng-container *ngIf="asset.subType == '502'">Artwork</ng-container>
                                              <ng-container *ngIf="asset.subType == '503'">Household Contents</ng-container>
                                              <ng-container *ngIf="asset.subType == '504'">Jewellery</ng-container>
                                              <ng-container *ngIf="asset.subType == '505'">Motor Vehicle</ng-container>
                                              <ng-container *ngIf="asset.subType == '507'">Caravan</ng-container>
                                              <ng-container *ngIf="asset.subType == '508'">Boat/Marine equipment</ng-container>
                                              <ng-container *ngIf="asset.subType == '506'">Other Life Style</ng-container>
                                              <ng-container *ngIf="asset.subType == '702'">Accounts Receivable</ng-container>
                                              <ng-container *ngIf="asset.subType == '707'">Franchises</ng-container>
                                              <ng-container *ngIf="asset.subType == '706'">Goodwill</ng-container>
                                              <ng-container *ngIf="asset.subType == '703'">Inventory</ng-container>
                                              <ng-container *ngIf="asset.subType == '705'">Patents and Copyrights</ng-container>
                                              <ng-container *ngIf="asset.subType == '701'">Plant and Machinery</ng-container>
                                              <ng-container *ngIf="asset.subType == '704'">Prepaid Expenses</ng-container> -->
                                              <ng-container *ngIf="asset.assetSubTypeName">{{asset.assetSubTypeName}}</ng-container>
                                              <ng-container *ngIf="asset.assetSubTypeName=='' || asset.assetSubTypeName==null">-</ng-container>
                                          &nbsp;&nbsp;</td>
                                          <td class="text-truncate">$
                                              <ng-container *ngIf="asset.currentValue">{{asset.currentValue}}</ng-container>
                                              <ng-container *ngIf="asset.currentValue=='' || asset.currentValue==null">0</ng-container>
                                          &nbsp;&nbsp;</td>
                                          <td class="">
                                              <ng-container *ngIf="asset.description">{{asset.description}}</ng-container>
                                              <ng-container *ngIf="asset.description=='' || asset.description==null">-</ng-container>
                                          &nbsp;&nbsp;</td>
                                          <td class="text-truncate">
                                            <ng-container *ngIf="asset.ownerType">{{asset.ownerType}}</ng-container>
                                            <ng-container *ngIf="asset.ownerType=='' || asset.ownerType==null">-</ng-container>
                                          &nbsp;&nbsp;</td>
  
                                          <ng-container *ngIf="buttonShow">
                                            <td>
                                              <a href="javascript:void(0);" (click)="openAssetEdit(asset);" class="ff_editbtn"><i class="la la-pencil"></i></a>
                                            </td>
                                            <td>
                                              <a href="javascript:void(0);" (click)="openAssetConfirmationDialog(assetDetailsTemplate,asset)"><i class="la la-trash"></i></a>
                                            </td>
                                          </ng-container>
  
                                      </tr>
                                    </ng-container>
                                  </ng-container>
                                  <ng-container *ngIf="assetInfoLength==0">
                                    <div class="">
                                      <img src="assets/img/empty_icon.png" alt="" height="45px;">
                                      <span style="padding: 10px 5px;">No data</span>
                                    </div>
                                  </ng-container>
                                  <!-- <tr>
                                      <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;912-215-456&nbsp;&nbsp;</td>
                                      <td class="text-truncate">912-215-456&nbsp;&nbsp;</td>
                                      <td class="text-truncate">912-215-456&nbsp;&nbsp;</td>
                                      <td class="text-truncate">test123@email.com&nbsp;&nbsp;</td>
                                      <td class="text-truncate">test123@email.com&nbsp;&nbsp;</td>
                                      <td><a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td>
                                  </tr> -->
                              </tbody>
                          </table> 
                        </ng-container>
                        <ng-container *ngIf="buttonShow">
                          <a href="javascript:void(0);" (click)="openAssetAddModal();" class="new_record">ADD NEW RECORD</a>
                        </ng-container>
                      </div>
                    </div>
  
                    <ng-template #assetDetailsTemplate>
                        <div class="modal-body text-center" >
                            <img src="assets/img/warning.png" alt="" class="alert_img">
                            <!-- <img *ngIf="status==false" src="assets/img/verified.png" alt="" class="alert_img"> -->
                            <p>{{message}}</p>   
                            <div class="form-actions center mb-1">
                                <button type="button" class="btn alert_no_b mr-1" (click)="decline(asset)"> 
                                    No
                                </button>
                                <button type="button" class="btn alert_yes_b" (click)="confirmAssetDelete(asset)" >
                                    Yes
                                </button>
                            </div>
                        </div>
                    </ng-template>
  
  
  
  
                    <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> 
                      <a href="javascript:void(0);" data-toggle="collapse" [ngClass]="{ 'collapsed': !showLiabilities }" (click)="clickLiabilities()" [attr.aria-expanded]="showLiabilities" aria-controls="accordion13" class="h6 blue collapsed prof_head">Liabilities</a> 
                      <!-- <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a> -->
                    </div>
                    <div id="al1" role="tabpanel" aria-labelledby="heading13" [ngClass]="{ 'show': showLiabilities }" class="collapse" [attr.aria-expanded]="showLiabilities">
                      <div class="card-body card_body_prof_ff table-responsive"> 
                        
                        <ng-container *ngIf="isLoaded9">  
                          <table class="table table-borderless table-sm pro_tab mb-0  d-md-table">
                              <tbody>
                                  <tr><span class="ffn_submenu">Liabilities</span></tr>
                                  <ng-container *ngIf="liabilityLength>0">
                                    <tr class="fftr fupper">
                                        <!-- <td class="font-weight-bold text-truncate">Current Value &nbsp;&nbsp;</td> -->
                                        <td class="font-weight-bold text-truncate">Owner &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Type &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate ">Subtype &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate ">Repayment Amount &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Repayment Frequency &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Product Name &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate ">Interest Rate &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate ">Interest Rate Type &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate ">Lender/Supplier &nbsp;&nbsp;</td>
                                        <ng-container *ngIf="buttonShow">
                                          <td></td>
                                          <td></td>
                                        </ng-container>
                                    </tr>
                                  
                                    <ng-container *ngFor="let liability of liabilityInfo; let i = index">
                                      <tr>
                                          <!-- <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;${{liability.repaymentAmountValue}}&nbsp;&nbsp;</td> -->
                                          <td class="text-truncate">
                                              <ng-container *ngIf="liability.ownerTypeName">{{liability.ownerTypeName}}</ng-container>
                                              <ng-container *ngIf="liability.ownerTypeName=='' || liability.ownerTypeName==null">-</ng-container>
                                          &nbsp;&nbsp;</td>
                                          <td class="text-truncate">
                                              <ng-container *ngIf="liability.typeName">{{liability.typeName}}</ng-container>
                                              <ng-container *ngIf="liability.typeName=='' || liability.typeName==null">-</ng-container>
                                          &nbsp;&nbsp;</td>
                                          <td class="text-truncate">
                                              <ng-container *ngIf="liability.subTypeName">{{liability.subTypeName}}</ng-container>
                                              <ng-container *ngIf="liability.subTypeName=='' || liability.subTypeName==null">-</ng-container>
                                          &nbsp;&nbsp;</td>
                                          <td class="text-truncate">$
                                              <ng-container *ngIf="liability.repaymentAmountValue">{{liability.repaymentAmountValue}}</ng-container>
                                              <ng-container *ngIf="liability.repaymentAmountValue=='' || liability.repaymentAmountValue==null">0</ng-container>
                                          &nbsp;&nbsp;</td>
                                          <td class="text-truncate">
                                              <ng-container *ngIf="liability.repaymentFrequency == '' || liability.repaymentFrequency == 'null' || liability.repaymentFrequency == '999'">-</ng-container>
                                              <ng-container *ngIf="liability.repaymentFrequencyName">{{liability.repaymentFrequencyName}}</ng-container>
                                          &nbsp;&nbsp;</td>
                                          <td class="text-truncate">
                                              <ng-container *ngIf="liability.productName">{{liability.productName}}</ng-container>
                                              <ng-container *ngIf="liability.productName=='' || liability.productName==null">-</ng-container>
                                          &nbsp;&nbsp;</td>
                                          <td class="text-truncate">
                                              <ng-container *ngIf="liability.interestRate">{{liability.interestRate}}</ng-container>
                                              <ng-container *ngIf="liability.interestRate=='' || liability.interestRate==null">-</ng-container>
                                          %&nbsp;&nbsp;</td>
                                          <td class="text-truncate">
                                              <ng-container *ngIf="liability.interestRateTypeName">{{liability.interestRateTypeName}}</ng-container>
                                              <ng-container *ngIf="liability.interestRateTypeName=='' || liability.interestRateTypeName==null">-</ng-container>
                                          &nbsp;&nbsp;</td>
                                          <td class="text-truncate">
                                              <ng-container *ngIf="liability.lenderSupplierName">{{liability.lenderSupplierName}}</ng-container>
                                              <ng-container *ngIf="liability.lenderSupplierName=='' || liability.lenderSupplierName==null">-</ng-container>
                                          &nbsp;&nbsp;</td>
  
                                          <ng-container *ngIf="buttonShow">
                                            <td> <a href="javascript:void(0);" (click)="openLiabilitiesEdit(liability);" class="ff_editbtn"><i class="la la-pencil"></i></a></td>
                                            <td>
                                              <a href="javascript:void(0);" (click)="openLiabilityConfirmationDialog(liabilityDetailsTemplate,liability)"><i class="la la-trash"></i></a>
                                            </td>
                                          </ng-container>
  
                                      </tr>
                                    </ng-container>
                                  </ng-container>
  
                                  <ng-container *ngIf="liabilityLength==0">
                                      <div class="">
                                        <img src="assets/img/empty_icon.png" alt="" height="45px;">
                                        <span style="padding: 10px 5px;">No data</span>
                                      </div>
                                  </ng-container>
                                  <!-- <tr>
                                      <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;$1,600&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Alan&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Residence&nbsp;&nbsp;</td>
                                      <td class="text-truncate">STub&nbsp;&nbsp;</td>
                                      <td class="text-truncate">$270&nbsp;&nbsp;</td>
                                      <td class="text-truncate">100&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Sample&nbsp;&nbsp;</td>
                                      <td class="text-truncate">10%&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Rtype&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Andre&nbsp;&nbsp;</td>
                                      <td> <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td>
                                  </tr> -->
                              </tbody>
                          </table> 
                        </ng-container>
                        <ng-container *ngIf="buttonShow">
                          <a href="javascript:void(0);" (click)="openLiabilitiesAddModal();" class="new_record">ADD NEW RECORD</a>
                        </ng-container>
                      </div>
                    </div>
  
                    <ng-template #liabilityDetailsTemplate>
                        <div class="modal-body text-center" >
                            <img src="assets/img/warning.png" alt="" class="alert_img">
                            <!-- <img *ngIf="status==false" src="assets/img/verified.png" alt="" class="alert_img"> -->
                            <p>{{message}}</p>   
                            <div class="form-actions center mb-1">
                                <button type="button" class="btn alert_no_b mr-1" (click)="decline(liability)"> 
                                    No
                                </button>
                                <button type="button" class="btn alert_yes_b" (click)="confirmLiabilityDelete(liability)" >
                                    Yes
                                </button>
                            </div>
                        </div>
                    </ng-template>

                  
                  <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> 
                    <a href="javascript:void(0);" data-toggle="collapse" [ngClass]="{ 'collapsed': !showDependent }" (click)="clickDependent()" [attr.aria-expanded]="showDependent" aria-controls="accordion13" class="h6 blue collapsed prof_head">Dependants</a> 
                      <!-- <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a> -->
                  </div>
                  <div id="ed1" role="tabpanel" aria-labelledby="heading13" [ngClass]="{ 'show': showDependent }" class="collapse" [attr.aria-expanded]="showDependent">
                    <div class="card-body card_body_prof_ff table-responsive">
                      <ng-container *ngIf="isLoaded5">
                        <ng-container *ngIf="dependentInfoLength>0">
                          <table class="table table-borderless table-sm pro_tab mb-0 d-md-table">
                              <tbody>
                                  <tr class="fftr fupper">
                                      <td class="font-weight-bold text-truncate">First Name &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate">Surname &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate">Date of Birth &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate ">Gender &nbsp;&nbsp;</td>
                                      <ng-container *ngIf="buttonShow">
                                        <td></td>
                                        <td></td>
                                      </ng-container>
                                  </tr>
                                  <ng-container *ngFor="let dependent of dependentInfo; let i = index">
                                    <tr>
                                        <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;
                                          <ng-container *ngIf="dependent.firstName">{{dependent.firstName}}</ng-container>
                                          <ng-container *ngIf="dependent.firstName == '' || dependent.firstName == null">-</ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                          <ng-container *ngIf="dependent.surname">{{dependent.surname}}</ng-container>
                                          &nbsp;&nbsp;</td>
                                        <td class="text-truncate">{{dependent.dateOfBirth +"Z" | date: 'MMM d, y' }}&nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                            <ng-container *ngIf="dependent.gender == 1">Male</ng-container>
                                            <ng-container *ngIf="dependent.gender == 0">Female</ng-container>

                                            <ng-container *ngIf="dependent.gender == '' || dependent.gender == null">-</ng-container>
                                        &nbsp;&nbsp;</td>

                                        <ng-container *ngIf="buttonShow">
                                          <td> 
                                            <a href="javascript:void(0);" (click)="openDependantEditModal(dependent)" class="ff_editbtn"><i class="la la-pencil"></i></a>
                                          </td>
                                          <td>
                                            <a href="javascript:void(0);" (click)="openDependentConfirmationDialog(dependentDetailsTemplate,dependent)"><i class="la la-trash"></i></a>
                                          </td>
                                        </ng-container>

                                    </tr>
                                  </ng-container>
                                  <!-- <tr>
                                      <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;Silva6&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Andrew&nbsp;&nbsp;</td>
                                      <td class="text-truncate">1985-02-14&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Male&nbsp;&nbsp;</td>
                                      <td> <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td>
                                  </tr> -->
                              </tbody>
                          </table>
                        </ng-container>
                        <ng-container *ngIf="dependentInfoLength==0">
                          <div class="">
                            <img src="assets/img/empty_icon.png" alt="" height="45px;">
                            <span style="padding: 10px 5px;">No data</span>
                          </div>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="buttonShow">
                        <a href="javascript:void(0);" (click)="openDependantAddModal()" class="new_record">ADD NEW RECORD</a>
                      </ng-container>
                    </div>
                  </div>

                  <ng-template #dependentDetailsTemplate>
                      <div class="modal-body text-center" >
                  
                      <img src="assets/img/warning.png" alt="" class="alert_img">
                      <!-- <img *ngIf="status==false" src="assets/img/verified.png" alt="" class="alert_img"> -->
                      <p>{{message}}</p>
                              
                          <div class="form-actions center mb-1">
                              <button type="button" class="btn alert_no_b mr-1" (click)="decline(dependent)"> 
                                  No
                              </button>
                              <button type="button" class="btn alert_yes_b" (click)="confirmDependantDelete(dependent)" >
                                      Yes
                              </button>
                          </div>
                      </div>
                  </ng-template>



                  <div id="heading14" role="tab" class="card-header border-bottom-lighten-4 bbn_ff">
                    <a href="javascript:void(0);" data-toggle="collapse" [ngClass]="{ 'collapsed': !showEmployement }" (click)="clickEmployement()" [attr.aria-expanded]="showEmployement" aria-controls="accordion14" class="h6 blue collapsed prof_head">Employment</a> 
                    <!-- <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a> -->
                  </div>
                  <div id="em1" role="tabpanel" aria-labelledby="heading13" [ngClass]="{ 'show': showEmployement }" class="collapse" [attr.aria-expanded]="showEmployement">
                    <div class="card-body card_body_prof_ff  table-responsive">
                      <ng-container *ngIf="isLoaded6">
                        <ng-container *ngIf="employmentInfoLength>0">
                          <table class="table table-borderless table-sm pro_tab mb-0 d-md-table">
                              <tbody>
                                  <tr class="fftr fupper">
                                      <td class="font-weight-bold text-truncate">Occupation &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate">Employer &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate">Status &nbsp;&nbsp;</td>
                                      <!-- <td class="font-weight-bold text-truncate ">Employer Street &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate">Employer Suburb &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate">Employer State &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate">Employer Postcode &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate ">Employer Country &nbsp;&nbsp;</td> -->
                                      <ng-container *ngIf="buttonShow">
                                        <td></td>
                                        <td></td>
                                      </ng-container>
                                  </tr>
                                  <ng-container *ngFor="let employment of employmentInfo; let i = index">
                                    <tr>
                                        <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;
                                          <ng-container *ngIf="employment.occupationName">{{employment.occupationName}}</ng-container>
                                          <ng-container *ngIf="employment.occupationName == '' || employment.occupationName == null">-</ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                            <ng-container *ngIf="employment.employer">{{employment.employer}}</ng-container>
                                            <ng-container *ngIf="employment.employer == '' || employment.employer == null">-</ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                            <ng-container *ngIf="employment.status">{{employment.status}}</ng-container>
                                            <ng-container *ngIf="employment.status == '' || employment.status == null">-</ng-container>
                                        &nbsp;&nbsp;</td>
                                        <!-- <td class="text-truncate">
                                            <ng-container *ngIf="employment.employerStreet">{{employment.employerStreet}}</ng-container>
                                            <ng-container *ngIf="employment.employerStreet == '' || employment.employerStreet == null">-</ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                            <ng-container *ngIf="employment.employerSuburb">{{employment.employerSuburb}}</ng-container>
                                            <ng-container *ngIf="employment.employerSuburb == '' || employment.employerSuburb == null">-</ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                            <ng-container *ngIf="employment.employerState">{{employment.employerState}}</ng-container>
                                            <ng-container *ngIf="employment.employerState == '' || employment.employerState == null">-</ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                            <ng-container *ngIf="employment.employerPostcode">{{employment.employerPostcode}}</ng-container>
                                            <ng-container *ngIf="employment.employerPostcode == '' || employment.employerPostcode == null">-</ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                            <ng-container *ngIf="employment.employerCountry">{{employment.employerCountry}}</ng-container>
                                            <ng-container *ngIf="employment.employerCountry == '' || employment.employerCountry == null">-</ng-container>
                                        &nbsp;&nbsp;</td> -->

                                        <ng-container *ngIf="buttonShow">
                                          <td>
                                            <a href="javascript:void(0);" (click)="openEmploymentEditModal(employment)" class="ff_editbtn"><i class="la la-pencil"></i></a>
                                          </td>
                                          <td>
                                            <a href="javascript:void(0);" (click)="openEmploymentConfirmationDialog(employmentDetailsTemplate,employment)"><i class="la la-trash"></i></a>
                                          </td>
                                        </ng-container>

                                    </tr>
                                  </ng-container>
                                  <!-- <tr>
                                      <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;Doctor&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Andrew&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Andrew&nbsp;&nbsp;</td>
                                      <td class="text-truncate">206A&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Charters Towers Rd&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Hermit Park QLD&nbsp;&nbsp;</td>
                                      <td class="text-truncate">4812&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Australia&nbsp;&nbsp;</td>
                                      <td><a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td>
                                  </tr> -->
                              </tbody>
                          </table>
                        </ng-container>
                        <ng-container *ngIf="employmentInfoLength==0">
                          <div class="">
                            <img src="assets/img/empty_icon.png" alt="" height="45px;">
                            <span style="padding: 10px 5px;">No data</span>
                          </div>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="buttonShow">
                        <a href="javascript:void(0);" (click)="openEmploymentAddModal()" class="new_record">ADD NEW RECORD</a>
                      </ng-container>
                    </div>
                  </div>

                  <ng-template #employmentDetailsTemplate>
                      <div class="modal-body text-center" >
                          <img src="assets/img/warning.png" alt="" class="alert_img">
                          <!-- <img *ngIf="status==false" src="assets/img/verified.png" alt="" class="alert_img"> -->
                          <p>{{message}}</p>   
                          <div class="form-actions center mb-1">
                              <button type="button" class="btn alert_no_b mr-1" (click)="decline(employment)"> 
                                  No
                              </button>
                              <button type="button" class="btn alert_yes_b" (click)="confirmEmploymentDelete(employment)" >
                                  Yes
                              </button>
                          </div>
                      </div>
                  </ng-template>

                  

                  <div id="heading14" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> 
                    <a href="javascript:void(0);" data-toggle="collapse" [ngClass]="{ 'collapsed': !showEstateDetails }" (click)="clickEstateDetails()" [attr.aria-expanded]="showEstateDetails" aria-controls="accordion14" class="h6 blue collapsed prof_head">Estate Details</a> 
                    <a href="javascript:void(0);" *ngIf="showEstateDetails && buttonShow" (click)="openEstateEditModal(estatePlanninData)" class="ff_editbtn"><i class="la la-pencil"></i></a> 
                  </div>
                  <div id="ed3" role="tabpanel" aria-labelledby="heading13" [ngClass]="{ 'show': showEstateDetails }" class="collapse" [attr.aria-expanded]="showEstateDetails">
                    <div class="card-body card_body_prof_ff table-responsive">
                      <ng-container *ngIf="isLoaded7">
                        <ng-container *ngIf="estatePlanninData">
                          <table class="table table-borderless table-sm pro_tab d-md-table mb-0">
                            <tbody>
                              <tr>
                                <td><span class="ffn_dot text-truncate"></span>&nbsp;Do you have Will?</td>
                                <td class="profile_tdff text-left text-truncate">: 
                                    <ng-container *ngIf="estatePlanninData.willExists == true">Yes</ng-container> 
                                    <ng-container *ngIf="estatePlanninData.willExists == false">No</ng-container>
                                </td>
                                <!-- <td><a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td> -->
                              </tr>
                              <tr>
                                <td><span class="ffn_dot text-truncate"></span>&nbsp;Date of Will</td>
                                <td class="text-left profile_tdff text-truncate">:
                                  <ng-container *ngIf="estatePlanninData.willDate"> 
                                    {{estatePlanninData.willDate +"Z" | date: 'MMM d, y'}}
                                  </ng-container>
                                  <ng-container *ngIf="estatePlanninData.willDate == '' || estatePlanninData.willDate == null"> 
                                    -
                                  </ng-container>

                                </td>
                                <!-- <td><a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td> -->
                              </tr>
                              <tr>
                                <td><span class="ffn_dot text-truncate"></span>&nbsp;Location</td>
                                <td class="text-left profile_tdff text-truncate">: 
                                  <ng-container *ngIf="estatePlanninData.willLocation">
                                    {{estatePlanninData.willLocation}}
                                  </ng-container>
                                  <ng-container *ngIf="estatePlanninData.willLocation == '' || estatePlanninData.willLocation == null"> 
                                    -
                                  </ng-container>
                                </td>
                                <!-- <td><a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td> -->
                              </tr>
                              <!-- <tr>
                                <td class="special_ff"><span class="ffn_dot"></span>&nbsp;No any special notes added</td>
                              </tr> -->
                            </tbody>
                          </table>
                        </ng-container>
                        <ng-container *ngIf="!estatePlanninData">
                          <div class="">
                            <img src="assets/img/empty_icon.png" alt="" height="45px;">
                            <span style="padding: 10px 5px;">No data</span>
                          </div>
                        </ng-container>
                      </ng-container>
                      <!-- <a href="#" class="new_record_sin">ADD NEW RECORD</a> -->
                    </div>
                  </div>

                  
                  
                  <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> 
                    <a href="javascript:void(0);" data-toggle="collapse" [ngClass]="{ 'collapsed': !showGoals }" (click)="clickGoals()" [attr.aria-expanded]="showGoals" aria-controls="accordion13" class="h6 blue collapsed prof_head">Goals</a> 
                    <!-- <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a> -->
                  </div>
                  <div id="go1" role="tabpanel" aria-labelledby="heading13" [ngClass]="{ 'show': showGoals }" class="collapse" [attr.aria-expanded]="showGoals">
                    <div class="card-body card_body_prof_ff table-responsive">
                      <ng-container *ngIf="isLoaded12"> 
                        <ng-container *ngIf="goalDataLength>0">
                          <table class="table table-borderless table-sm pro_tab mb-0 d-md-table">
                              <tbody>
                                  <tr class="fftr fupper">
                                      <td class="font-weight-bold text-truncate">Owner &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate">Description &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate ">Amount &nbsp;&nbsp;</td>   
                                      <td class="font-weight-bold text-truncate ">Target Date&nbsp;&nbsp;</td>                           
                                      <td class="font-weight-bold text-truncate ">Comments &nbsp;&nbsp;</td>
                                      <ng-container *ngIf="buttonShow">
                                        <td></td>
                                        <td></td>
                                      </ng-container>
                                  </tr>
                                  <ng-container *ngFor="let goal of goalData; let i = index">
                                    <tr>
                                        <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;
                                            <ng-container *ngIf="goal.ownerType">{{goal.ownerType}}</ng-container>
                                            <ng-container *ngIf="goal.ownerType=='' || goal.ownerType==null">-</ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="">
                                            <ng-container *ngIf="goal.description"> {{goal.description}} </ng-container>
                                            <ng-container *ngIf="goal.description=='' || goal.description==null"> - </ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">$
                                            <ng-container *ngIf="goal.targetAmountValue">{{goal.targetAmountValue}}</ng-container>
                                            <ng-container *ngIf="goal.targetAmountValue=='' || goal.targetAmountValue==null">0</ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="">
                                          <ng-container *ngIf="goal.targetDate"> {{goal.targetDate +"Z" | date: 'MMM d, y'}} </ng-container>
                                          <ng-container *ngIf="goal.targetDate=='' || goal.targetDate==null"> - </ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="">
                                          <ng-container *ngIf="goal.comments"> {{goal.comments}} </ng-container>
                                          <ng-container *ngIf="goal.comments=='' || goal.comments==null"> - </ng-container>
                                        &nbsp;&nbsp;</td>

                                        <ng-container *ngIf="buttonShow">
                                          <td> <a href="javascript:void(0);" (click)="openGoalEditModal(goal)" class="ff_editbtn"><i class="la la-pencil"></i></a></td>
                                          <td>
                                            <a href="javascript:void(0);" (click)="openGoalConfirmationDialog(goalDetailsTemplate,goal)"><i class="la la-trash"></i></a>
                                          </td>
                                        </ng-container>

                                    </tr>
                                  </ng-container>
                                  <!-- <tr>
                                      <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;Alan&nbsp;&nbsp;</td>
                                      <td class="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, minus.&nbsp;&nbsp;</td>
                                      <td class="text-truncate">$1000&nbsp;&nbsp;</td>
                                      <td class="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, minus.&nbsp;&nbsp;</td>
                                      <td> <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td>
                                  </tr> -->
                              </tbody>
                          </table>
                        </ng-container>

                        <ng-container *ngIf="goalDataLength==0">
                            <div class="">
                              <img src="assets/img/empty_icon.png" alt="" height="45px;">
                              <span style="padding: 10px 5px;">No data</span>
                            </div>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="buttonShow">
                        <a  href="javascript:void(0);" (click)="openGoalAddModal()" class="new_record">ADD NEW RECORD</a>
                      </ng-container>
                    </div>
                  </div>

                  <ng-template #goalDetailsTemplate>
                      <div class="modal-body text-center" >
                          <img src="assets/img/warning.png" alt="" class="alert_img">
                          <!-- <img *ngIf="status==false" src="assets/img/verified.png" alt="" class="alert_img"> -->
                          <p>{{message}}</p>   
                          <div class="form-actions center mb-1">
                              <button type="button" class="btn alert_no_b mr-1" (click)="decline(goal)"> 
                                  No
                              </button>
                              <button type="button" class="btn alert_yes_b" (click)="confirmGoalDelete(goal)" >
                                  Yes
                              </button>
                          </div>
                      </div>
                  </ng-template>



                  <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> 
                    <a href="javascript:void(0);" data-toggle="collapse" [ngClass]="{ 'collapsed': !showIncome }" (click)="clickIncome()" [attr.aria-expanded]="showIncome" aria-controls="accordion13" class="h6 blue collapsed prof_head">Income</a> 
                    <!-- <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a> -->
                  </div>
                  <div id="ie1" role="tabpanel" aria-labelledby="heading13" [ngClass]="{ 'show': showIncome }" class="collapse" [attr.aria-expanded]="showIncome">
                    <div class="card-body card_body_prof_ff table-responsive">
                      
                        <table class="table table-borderless table-sm pro_tab mb-0 d-md-table">
                            <tbody>
                                <ng-container *ngIf="isLoaded10">
                                  <ng-container *ngIf="incomeDataLength>0 || expenseDataLength>0">
                                    <tr class="fftr fupper">
                                        <td class="font-weight-bold text-truncate">Type &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Subtype &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Description &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Owner &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate ">Frequency &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate ">Amount &nbsp;&nbsp;</td>
                                        <ng-container *ngIf="buttonShow">
                                          <td></td>
                                          <td></td>
                                        </ng-container>
                                    </tr>
                                  </ng-container>
                                </ng-container>

                                <ng-container *ngIf="isLoaded10">
                                  <ng-container *ngFor="let income of incomeData; let i = index">
                                    <tr>
                                        <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;
                                          <!-- <ng-container *ngIf="income.type == 'i1'">Income</ng-container>
                                          <ng-container *ngIf="income.type == 'e1'">Taxes</ng-container>
                                          <ng-container *ngIf="income.type == 'e2'">Debt-Long Term</ng-container>
                                          <ng-container *ngIf="income.type == 'e3'">Debt-Short Term</ng-container>
                                          <ng-container *ngIf="income.type == 'e4'">Contributions</ng-container>
                                          <ng-container *ngIf="income.type == 'e5'">Housing</ng-container>
                                          <ng-container *ngIf="income.type == 'e6'">Utilities</ng-container>
                                          <ng-container *ngIf="income.type == 'e7'">Car</ng-container>
                                          <ng-container *ngIf="income.type == 'e8'">Food</ng-container>
                                          <ng-container *ngIf="income.type == 'e9'">Insurance</ng-container>
                                          <ng-container *ngIf="income.type == 'ea'">Health Care</ng-container>
                                          <ng-container *ngIf="income.type == 'eb'">Personal Care</ng-container>
                                          <ng-container *ngIf="income.type == 'ec'">Entertainment</ng-container>
                                          <ng-container *ngIf="income.type == 'ed'">Other Expenditure</ng-container>
                                          <ng-container *ngIf="income.type == 'ee'">Living Expenses</ng-container> -->
                                          <ng-container *ngIf="income.incomeTypeName">{{income.incomeTypeName}}</ng-container>
                                          <ng-container *ngIf="income.incomeTypeName=='' || income.incomeTypeName==null">-</ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                          <!-- <ng-container *ngIf="income.subType == 'i101'">Ordinary Wages</ng-container>
                                          <ng-container *ngIf="income.subType == 'i102'">Bonuses</ng-container>
                                          <ng-container *ngIf="income.subType == 'i103'">Commissions</ng-container>
                                          <ng-container *ngIf="income.subType == 'i104'">Investment: Interest</ng-container>
                                          <ng-container *ngIf="income.subType == 'i105'">Investment: Dividends</ng-container>
                                          <ng-container *ngIf="income.subType == 'i106'">Investment: Rental</ng-container>
                                          <ng-container *ngIf="income.subType == 'i111'">Investment: Other Income</ng-container>
                                          <ng-container *ngIf="income.subType == 'i107'">Annuity Income</ng-container>
                                          <ng-container *ngIf="income.subType == 'i108'">Pension Income</ng-container>
                                          <ng-container *ngIf="income.subType == 'i109'">Trade Income</ng-container>
                                          <ng-container *ngIf="income.subType == 'i110'">Royalties</ng-container>
                                          <ng-container *ngIf="income.subType == 'i112'">Lifestyle Income</ng-container>
                                          <ng-container *ngIf="income.subType == 'i113'">Business Income</ng-container>
                                          <ng-container *ngIf="income.subType == 'i114'">Social Security</ng-container>
                                          <ng-container *ngIf="income.subType == 'i115'">Savings</ng-container>
                                          <ng-container *ngIf="income.subType == 'i199'">Other Income</ng-container>
                                          <ng-container *ngIf="income.subType == 'i198'">Other Income - Non Taxable</ng-container>
                                          <ng-container *ngIf="income.subType == 'e101'">Income Tax</ng-container>
                                          <ng-container *ngIf="income.subType == 'e102'">Property Tax</ng-container>
                                          <ng-container *ngIf="income.subType == 'e103'">Road Tax</ng-container>
                                          <ng-container *ngIf="income.subType == 'e199'">ther Tax</ng-container>
                                          <ng-container *ngIf="income.subType == 'e201'">Mortgage Payments</ng-container>
                                          <ng-container *ngIf="income.subType == 'e202'">Rental Payments</ng-container>
                                          <ng-container *ngIf="income.subType == 'e203'">Car Loan</ng-container>
                                          <ng-container *ngIf="income.subType == 'e204'">Personal Loans</ng-container>
                                          <ng-container *ngIf="income.subType == 'e205'">Tax-Deductible Education Loans</ng-container>
                                          <ng-container *ngIf="income.subType == 'e206'">Tax-Deductible Mortgage Payments</ng-container>
                                          <ng-container *ngIf="income.subType == 'e299'">Other Long Term Loans</ng-container>
                                          <ng-container *ngIf="income.subType == 'e301'">Credit Cards</ng-container>
                                          <ng-container *ngIf="income.subType == 'e399'">Other Loans</ng-container>
                                          <ng-container *ngIf="income.subType == 'e401'">Post Tax Contributions</ng-container>
                                          <ng-container *ngIf="income.subType == 'e402'">Pre Tax Contributions</ng-container>
                                          <ng-container *ngIf="income.subType == 'e403'">Spouse Contributions</ng-container>
                                          <ng-container *ngIf="income.subType == 'e404'">Self Employed Contributions</ng-container>
                                          <ng-container *ngIf="income.subType == 'e499'">Other Contributions</ng-container>
                                          <ng-container *ngIf="income.subType == 'e501'">Housing Maintenance</ng-container>
                                          <ng-container *ngIf="income.subType == 'e599'">Other Housing</ng-container>
                                          <ng-container *ngIf="income.subType == 'e601'">Gas</ng-container>
                                          <ng-container *ngIf="income.subType == 'e602'">Water</ng-container>
                                          <ng-container *ngIf="income.subType == 'e603'">Electricity</ng-container>
                                          <ng-container *ngIf="income.subType == 'e604'">Telephone</ng-container>
                                          <ng-container *ngIf="income.subType == 'e605'">Mobile</ng-container>
                                          <ng-container *ngIf="income.subType == 'e606'">Television</ng-container>
                                          <ng-container *ngIf="income.subType == 'e699'">Other</ng-container>
                                          <ng-container *ngIf="income.subType == 'e701'">Petrol</ng-container>
                                          <ng-container *ngIf="income.subType == 'e702'">Repairs</ng-container>
                                          <ng-container *ngIf="income.subType == 'e703'">Car Maintenance</ng-container>
                                          <ng-container *ngIf="income.subType == 'e799'">Other Car Expenses</ng-container>
                                          <ng-container *ngIf="income.subType == 'e801'">Groceries</ng-container>
                                          <ng-container *ngIf="income.subType == 'e802'">Dining Out</ng-container>
                                          <ng-container *ngIf="income.subType == 'e899'">Other Food Expenses</ng-container>
                                          <ng-container *ngIf="income.subType == 'e901'">Life</ng-container>
                                          <ng-container *ngIf="income.subType == 'e902'">Disability</ng-container>
                                          <ng-container *ngIf="income.subType == 'e903'">Home</ng-container>
                                          <ng-container *ngIf="income.subType == 'e904'">Health</ng-container>
                                          <ng-container *ngIf="income.subType == 'e905'">Car</ng-container>
                                          <ng-container *ngIf="income.subType == 'e906'">Endowment</ng-container>
                                          <ng-container *ngIf="income.subType == 'e907'">Term</ng-container>
                                          <ng-container *ngIf="income.subType == 'e908'">Whole Life</ng-container>
                                          <ng-container *ngIf="income.subType == 'e999'">Other Insurance</ng-container>
                                          <ng-container *ngIf="income.subType == 'ea01'">Doctor</ng-container>
                                          <ng-container *ngIf="income.subType == 'ea02'">Dental</ng-container>
                                          <ng-container *ngIf="income.subType == 'ea03'">Optical</ng-container>
                                          <ng-container *ngIf="income.subType == 'ea04'">Pharmaceutical</ng-container>
                                          <ng-container *ngIf="income.subType == 'ea05'">Hospital</ng-container>
                                          <ng-container *ngIf="income.subType == 'ea99'">Other Health Care</ng-container>
                                          <ng-container *ngIf="income.subType == 'eb01'">Clothing</ng-container>
                                          <ng-container *ngIf="income.subType == 'eb02'">Dry Cleaning</ng-container>
                                          <ng-container *ngIf="income.subType == 'eb03'">Hairdressing</ng-container>
                                          <ng-container *ngIf="income.subType == 'eb04'">Cosmetics</ng-container>
                                          <ng-container *ngIf="income.subType == 'eb99'">Other Personal Care</ng-container>
                                          <ng-container *ngIf="income.subType == 'ec01'">Memberships</ng-container>
                                          <ng-container *ngIf="income.subType == 'ec02'">Holiday</ng-container>
                                          <ng-container *ngIf="income.subType == 'ec03'">CDs, Videos, Movies</ng-container>
                                          <ng-container *ngIf="income.subType == 'ec04'">Sports</ng-container>
                                          <ng-container *ngIf="income.subType == 'ec99'">Other Entertainment</ng-container>
                                          <ng-container *ngIf="income.subType == 'ed01'">Other Expenditure</ng-container>
                                          <ng-container *ngIf="income.subType == 'ed99'">Custom Expenditure</ng-container>
                                          <ng-container *ngIf="income.subType == 'ee01'">Living Expenses</ng-container> -->
                                          <ng-container *ngIf="income.incomeSubTypeName">{{income.incomeSubTypeName}}</ng-container>
                                          <ng-container *ngIf="income.incomeSubTypeName=='' || income.incomeSubTypeName==null">-</ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="">
                                            <ng-container *ngIf="income.description">{{income.description}}</ng-container>
                                            <ng-container *ngIf="income.description=='' || income.description==null">-</ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                            <ng-container *ngIf="income.ownerType">{{income.ownerType}}</ng-container>
                                            <ng-container *ngIf="income.ownerType=='' || income.ownerType==null">-</ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                          <!-- <ng-container *ngIf="!income.frequency"> -->
                                            <!-- <ng-container *ngIf="income.frequency == 52">Weekly</ng-container>
                                            <ng-container *ngIf="income.frequency == 26">Fortnightly</ng-container>
                                            <ng-container *ngIf="income.frequency == 24">Twice a month</ng-container>
                                            <ng-container *ngIf="income.frequency == 13">Four weekly</ng-container>
                                            <ng-container *ngIf="income.frequency == 12">Monthly</ng-container>
                                            <ng-container *ngIf="income.frequency == 6">Bi-Monthly</ng-container>
                                            <ng-container *ngIf="income.frequency == 4">Quarterly</ng-container>
                                            <ng-container *ngIf="income.frequency == 2">Half yearly</ng-container>
                                            <ng-container *ngIf="income.frequency == 1">Annually</ng-container>
                                            <ng-container *ngIf="income.frequency == 0">Single</ng-container>
                                            <ng-container *ngIf="income.frequency == -1">Custom-Monthly</ng-container> -->
                                            <ng-container *ngIf="income.incomeFrequencyName">{{income.incomeFrequencyName}}</ng-container>
                                            <ng-container *ngIf="income.incomeFrequencyName=='' || income.incomeFrequencyName==null">-</ng-container>
                                          <!-- </ng-container>
                                          <ng-container *ngIf="income.frequency=='' || income.frequency==null || income.frequency==' '">-</ng-container> -->
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">$
                                            <ng-container *ngIf="income.amountValue">{{income.amountValue}}</ng-container>
                                            <ng-container *ngIf="income.amountValue=='' || income.amountValue==null">0</ng-container>
                                        &nbsp;&nbsp;</td>
                                        <ng-container *ngIf="buttonShow">
                                          <td> <a href="javascript:void(0);" (click)="openIncomeEditModal(income)" class="ff_editbtn"><i class="la la-pencil"></i></a></td>
                                          <td>
                                            <a href="javascript:void(0);" (click)="openIncomeConfirmationDialog(incomeDetailsTemplate,income)"><i class="la la-trash"></i></a>
                                          </td>
                                        </ng-container>

                                      </tr>
                                  </ng-container>
                                </ng-container>
                                
                                <!-- <ng-container *ngIf="isLoaded10 || isLoaded11"> -->
                                  <ng-container *ngIf="incomeDataLength==0">
                                    <div class="">
                                      <img src="assets/img/empty_icon.png" alt="" height="45px;">
                                      <span style="padding: 10px 5px;">No data</span>
                                    </div>
                                  </ng-container>
                                <!-- </ng-container> -->
                            </tbody>
                        </table>
                        <ng-container *ngIf="buttonShow">
                          <a href="javascript:void(0);" (click)="openIncomeAddModal()" class="new_record">ADD NEW RECORD</a>
                        </ng-container>
                    </div>
                  </div>

                  <ng-template #incomeDetailsTemplate>
                      <div class="modal-body text-center" >
                          <img src="assets/img/warning.png" alt="" class="alert_img">
                          <!-- <img *ngIf="status==false" src="assets/img/verified.png" alt="" class="alert_img"> -->
                          <p>{{message}}</p>   
                          <div class="form-actions center mb-1">
                              <button type="button" class="btn alert_no_b mr-1" (click)="decline(income)"> 
                                  No
                              </button>
                              <button type="button" class="btn alert_yes_b" (click)="confirmIncomeDelete(income)" >
                                  Yes
                              </button>
                          </div>
                      </div>
                  </ng-template>



                  <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> 
                    <a href="javascript:void(0);" data-toggle="collapse" [ngClass]="{ 'collapsed': !showExpenses }" (click)="clickExpenses()" [attr.aria-expanded]="showExpenses" aria-controls="accordion13" class="h6 blue collapsed prof_head">Expenses</a> 
                    <!-- <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a> -->
                  </div>
                  <div id="ie1" role="tabpanel" aria-labelledby="heading13" [ngClass]="{ 'show': showExpenses }" class="collapse" [attr.aria-expanded]="showExpenses">
                    <div class="card-body card_body_prof_ff table-responsive">
                      
                        <table class="table table-borderless table-sm pro_tab mb-0 d-md-table">
                            <tbody>
                                <ng-container *ngIf="isLoaded11">
                                  <ng-container *ngIf="incomeDataLength>0 || expenseDataLength>0">
                                    <tr class="fftr fupper">
                                        <td class="font-weight-bold text-truncate">Type &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Subtype &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Description &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate">Owner &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate ">Frequency &nbsp;&nbsp;</td>
                                        <td class="font-weight-bold text-truncate ">Amount &nbsp;&nbsp;</td>
                                        <ng-container *ngIf="buttonShow">
                                          <td></td>
                                          <td></td>
                                        </ng-container>
                                    </tr>
                                  </ng-container>
                                </ng-container>

                                
                                <ng-container *ngIf="isLoaded11">
                                  <ng-container *ngFor="let expense of expenseData; let i = index">
                                    <tr>
                                        <tr>
                                            <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;
                                              <!-- <ng-container *ngIf="expense.type == 'i1'">Income</ng-container>
                                              <ng-container *ngIf="expense.type == 'e1'">Taxes</ng-container>
                                              <ng-container *ngIf="expense.type == 'e2'">Debt-Long Term</ng-container>
                                              <ng-container *ngIf="expense.type == 'e3'">Debt-Short Term</ng-container>
                                              <ng-container *ngIf="expense.type == 'e4'">Contributions</ng-container>
                                              <ng-container *ngIf="expense.type == 'e5'">Housing</ng-container>
                                              <ng-container *ngIf="expense.type == 'e6'">Utilities</ng-container>
                                              <ng-container *ngIf="expense.type == 'e7'">Car</ng-container>
                                              <ng-container *ngIf="expense.type == 'e8'">Food</ng-container>
                                              <ng-container *ngIf="expense.type == 'e9'">Insurance</ng-container>
                                              <ng-container *ngIf="expense.type == 'ea'">Health Care</ng-container>
                                              <ng-container *ngIf="expense.type == 'eb'">Personal Care</ng-container>
                                              <ng-container *ngIf="expense.type == 'ec'">Entertainment</ng-container>
                                              <ng-container *ngIf="expense.type == 'ed'">Other Expenditure</ng-container>
                                              <ng-container *ngIf="expense.type == 'ee'">Living Expenses</ng-container> -->
                                              <ng-container *ngIf="expense.expenseTypeName">{{expense.expenseTypeName}}</ng-container>
                                              <ng-container *ngIf="expense.expenseTypeName=='' || expense.expenseTypeName==null">-</ng-container>
                                            &nbsp;&nbsp;</td>
                                            <td class="text-truncate">
                                              <!-- <ng-container *ngIf="expense.subType == 'i101'">Ordinary Wages</ng-container>
                                              <ng-container *ngIf="expense.subType == 'i102'">Bonuses</ng-container>
                                              <ng-container *ngIf="expense.subType == 'i103'">Commissions</ng-container>
                                              <ng-container *ngIf="expense.subType == 'i104'">Investment: Interest</ng-container>
                                              <ng-container *ngIf="expense.subType == 'i105'">Investment: Dividends</ng-container>
                                              <ng-container *ngIf="expense.subType == 'i106'">Investment: Rental</ng-container>
                                              <ng-container *ngIf="expense.subType == 'i111'">Investment: Other Income</ng-container>
                                              <ng-container *ngIf="expense.subType == 'i107'">Annuity Income</ng-container>
                                              <ng-container *ngIf="expense.subType == 'i108'">Pension Income</ng-container>
                                              <ng-container *ngIf="expense.subType == 'i109'">Trade Income</ng-container>
                                              <ng-container *ngIf="expense.subType == 'i110'">Royalties</ng-container>
                                              <ng-container *ngIf="expense.subType == 'i112'">Lifestyle Income</ng-container>
                                              <ng-container *ngIf="expense.subType == 'i113'">Business Income</ng-container>
                                              <ng-container *ngIf="expense.subType == 'i114'">Social Security</ng-container>
                                              <ng-container *ngIf="expense.subType == 'i115'">Savings</ng-container>
                                              <ng-container *ngIf="expense.subType == 'i199'">Other Income</ng-container>
                                              <ng-container *ngIf="expense.subType == 'i198'">Other Income - Non Taxable</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e101'">Income Tax</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e102'">Property Tax</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e103'">Road Tax</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e199'">ther Tax</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e201'">Mortgage Payments</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e202'">Rental Payments</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e203'">Car Loan</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e204'">Personal Loans</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e205'">Tax-Deductible Education Loans</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e206'">Tax-Deductible Mortgage Payments</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e299'">Other Long Term Loans</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e301'">Credit Cards</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e399'">Other Loans</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e401'">Post Tax Contributions</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e402'">Pre Tax Contributions</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e403'">Spouse Contributions</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e404'">Self Employed Contributions</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e499'">Other Contributions</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e501'">Housing Maintenance</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e599'">Other Housing</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e601'">Gas</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e602'">Water</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e603'">Electricity</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e604'">Telephone</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e605'">Mobile</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e606'">Television</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e699'">Other</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e701'">Petrol</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e702'">Repairs</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e703'">Car Maintenance</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e799'">Other Car Expenses</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e801'">Groceries</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e802'">Dining Out</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e899'">Other Food Expenses</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e901'">Life</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e902'">Disability</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e903'">Home</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e904'">Health</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e905'">Car</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e906'">Endowment</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e907'">Term</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e908'">Whole Life</ng-container>
                                              <ng-container *ngIf="expense.subType == 'e999'">Other Insurance</ng-container>
                                              <ng-container *ngIf="expense.subType == 'ea01'">Doctor</ng-container>
                                              <ng-container *ngIf="expense.subType == 'ea02'">Dental</ng-container>
                                              <ng-container *ngIf="expense.subType == 'ea03'">Optical</ng-container>
                                              <ng-container *ngIf="expense.subType == 'ea04'">Pharmaceutical</ng-container>
                                              <ng-container *ngIf="expense.subType == 'ea05'">Hospital</ng-container>
                                              <ng-container *ngIf="expense.subType == 'ea99'">Other Health Care</ng-container>
                                              <ng-container *ngIf="expense.subType == 'eb01'">Clothing</ng-container>
                                              <ng-container *ngIf="expense.subType == 'eb02'">Dry Cleaning</ng-container>
                                              <ng-container *ngIf="expense.subType == 'eb03'">Hairdressing</ng-container>
                                              <ng-container *ngIf="expense.subType == 'eb04'">Cosmetics</ng-container>
                                              <ng-container *ngIf="expense.subType == 'eb99'">Other Personal Care</ng-container>
                                              <ng-container *ngIf="expense.subType == 'ec01'">Memberships</ng-container>
                                              <ng-container *ngIf="expense.subType == 'ec02'">Holiday</ng-container>
                                              <ng-container *ngIf="expense.subType == 'ec03'">CDs, Videos, Movies</ng-container>
                                              <ng-container *ngIf="expense.subType == 'ec04'">Sports</ng-container>
                                              <ng-container *ngIf="expense.subType == 'ec99'">Other Entertainment</ng-container>
                                              <ng-container *ngIf="expense.subType == 'ed01'">Other Expenditure</ng-container>
                                              <ng-container *ngIf="expense.subType == 'ed99'">Custom Expenditure</ng-container>
                                              <ng-container *ngIf="expense.subType == 'ee01'">Living Expenses</ng-container> -->
                                              <ng-container *ngIf="expense.expenseSubTypeName">{{expense.expenseSubTypeName}}</ng-container>
                                              <ng-container *ngIf="expense.expenseSubTypeName=='' || expense.expenseSubTypeName==null">-</ng-container>
                                            &nbsp;&nbsp;</td>
                                            <td class="">
                                                <ng-container *ngIf="expense.description">{{expense.description}}</ng-container>
                                                <ng-container *ngIf="expense.description=='' || expense.description==null">-</ng-container>
                                            &nbsp;&nbsp;</td>
                                            <td class="text-truncate">
                                              <ng-container *ngIf="expense.ownerType">{{expense.ownerType}}</ng-container>
                                              <ng-container *ngIf="expense.ownerType=='' || expense.ownerType==null">-</ng-container>
                                            &nbsp;&nbsp;</td>
                                            <td class="text-truncate">
                                              <!-- <ng-container *ngIf="!expense.frequency"> -->
                                                <!-- <ng-container *ngIf="expense.frequency == 52">Weekly</ng-container>
                                                <ng-container *ngIf="expense.frequency == 26">Fortnightly</ng-container>
                                                <ng-container *ngIf="expense.frequency == 24">Twice a month</ng-container>
                                                <ng-container *ngIf="expense.frequency == 13">Four weekly</ng-container>
                                                <ng-container *ngIf="expense.frequency == 12">Monthly</ng-container>
                                                <ng-container *ngIf="expense.frequency == 6">Bi-Monthly</ng-container>
                                                <ng-container *ngIf="expense.frequency == 4">Quarterly</ng-container>
                                                <ng-container *ngIf="expense.frequency == 2">Half yearly</ng-container>
                                                <ng-container *ngIf="expense.frequency == 1">Annually</ng-container>
                                                <ng-container *ngIf="expense.frequency == 0">Single</ng-container>
                                                <ng-container *ngIf="expense.frequency == '-1'">Custom-Monthly</ng-container> -->
                                                <ng-container *ngIf="expense.expenseFrequencyName">{{expense.expenseFrequencyName}}</ng-container>
                                                <ng-container *ngIf="expense.expenseFrequencyName=='' || expense.expenseFrequencyName==null">-</ng-container>
                                              <!-- </ng-container>
                                              <ng-container *ngIf="expense.frequency=='' || expense.frequency==null || expense.frequency==' '">-</ng-container> -->
                                            &nbsp;&nbsp;</td>
                                            <td class="text-truncate">$
                                                <ng-container *ngIf="expense.amountValue">{{expense.amountValue}}</ng-container>
                                                <ng-container *ngIf="expense.amountValue=='' || expense.amountValue==null">0</ng-container>
                                            &nbsp;&nbsp;</td>

                                            <ng-container *ngIf="buttonShow">
                                              <td> <a href="javascript:void(0);" (click)="openExpenseEditModal(expense)" class="ff_editbtn"><i class="la la-pencil"></i></a></td>
                                              <td>
                                                <a href="javascript:void(0);" (click)="openExpenseConfirmationDialog(expenseDetailsTemplate,expense)"><i class="la la-trash"></i></a>
                                              </td>
                                            </ng-container>

                                    </tr>
                                  </ng-container>
                                </ng-container>

                                <!-- <ng-container *ngIf="isLoaded10 || isLoaded11"> -->
                                  <ng-container *ngIf="expenseDataLength==0">
                                    <div class="">
                                      <img src="assets/img/empty_icon.png" alt="" height="45px;">
                                      <span style="padding: 10px 5px;">No data</span>
                                    </div>
                                  </ng-container>
                                <!-- </ng-container> -->
                            </tbody>
                        </table>
                        <ng-container *ngIf="buttonShow">
                          <a href="javascript:void(0);" (click)="openExpenseAddModal()" class="new_record">ADD NEW RECORD</a>
                        </ng-container>
                    </div>
                  </div>

                  <ng-template #expenseDetailsTemplate>
                      <div class="modal-body text-center" >
                          <img src="assets/img/warning.png" alt="" class="alert_img">
                          <!-- <img *ngIf="status==false" src="assets/img/verified.png" alt="" class="alert_img"> -->
                          <p>{{message}}</p>   
                          <div class="form-actions center mb-1">
                              <button type="button" class="btn alert_no_b mr-1" (click)="decline(expense)"> 
                                  No
                              </button>
                              <button type="button" class="btn alert_yes_b" (click)="confirmExpenseDelete(expense)" >
                                  Yes
                              </button>
                          </div>
                      </div>
                  </ng-template>



                  



                  <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> 
                    <a href="javascript:void(0);" data-toggle="collapse" [ngClass]="{ 'collapsed': !showInsurance }" (click)="clickInsurance()" [attr.aria-expanded]="showInsurance" aria-controls="accordion13" class="h6 blue collapsed prof_head">Insurance</a> 
                    <!-- <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a> -->
                  </div>
                  <div id="ins1" role="tabpanel" aria-labelledby="heading13" [ngClass]="{ 'show': showInsurance }" class="collapse" [attr.aria-expanded]="showInsurance">
                    <div class="card-body card_body_prof_ff table-responsive">
                      <ng-container *ngIf="isLoaded13">  
                        <ng-container *ngIf="insuranceDataLength>0">
                          <table class="table table-borderless table-sm pro_tab mb-0 d-md-table">
                              <tbody>
                                  <!-- <tr><span class="ffn_submenu">Insurance Group</span></tr> -->
                                  <tr class="fftr fupper">
                                      <td class="font-weight-bold text-truncate">Policy Number &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate">Plan Name &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate ">Total Premium &nbsp;&nbsp;</td>                              
                                      <td class="font-weight-bold text-truncate ">Policy Owners &nbsp;&nbsp;</td>
                                      <ng-container *ngIf="buttonShow">
                                        <td></td>    
                                        <td></td> 
                                      </ng-container> 
                                      <td></td>                         
                                  </tr>
                                  <ng-container *ngFor="let insurance of insuranceData; let i = index">
                                    <tr>
                                        <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;
                                          <ng-container *ngIf="insurance.policyNumber"> {{insurance.policyNumber}} </ng-container>
                                          <ng-container *ngIf="insurance.policyNumber=='' || insurance.policyNumber==null "> - </ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="textbr_ins">
                                            <ng-container *ngIf="insurance.planName"> {{insurance.planName}} </ng-container>
                                            <ng-container *ngIf="insurance.planName=='' || insurance.planName==null "> - </ng-container>
                                        &nbsp;&nbsp;&nbsp;</td>
                                        <td class="text-truncate">$
                                            <ng-container *ngIf="insurance.totalPremium"> {{insurance.totalPremium}} </ng-container>
                                            <ng-container *ngIf="insurance.totalPremium=='' || insurance.totalPremium==null "> 0 </ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                          <ng-container *ngIf="insurance.ownerTypeName"> {{insurance.ownerTypeName}} </ng-container>
                                          <ng-container *ngIf="insurance.ownerTypeName=='' || insurance.ownerTypeName==null "> - </ng-container>
                                        &nbsp;&nbsp;</td>
                                        <ng-container *ngIf="buttonShow">
                                          <td> 
                                            <a href="javascript:void(0);" (click)="openInsuranceEditModal(insurance)" class="subinsedit"><i class="la la-pencil"></i></a>
                                          </td>
                                          <td>
                                            <a href="javascript:void(0);" (click)="openInsuranceConfirmationDialog(insuranceDetailsTemplate,insurance)"><i class="la la-trash"></i></a>
                                          </td>
                                        </ng-container>
                                        <td>
                                          <!-- <div id="heading131" role="tab" class="card-header border-bottom-lighten-4 p-0">  -->
                                            <a id="heading131" data-toggle="collapse" data-parent="#accordionWrap1" href="#ed111{{insurance.id}}" aria-expanded="false" aria-controls="accordion13" class="h6 blue card-header collapsed prof_head ff_insurancecol"></a> 
                                          <!-- </div> -->
                                        </td>
                                    </tr>
                                    <tr id="ed111{{insurance.id}}" role="tabpanel" aria-labelledby="heading131" class="collapse" aria-expanded="false">
                                      <td class="text-truncate" colspan="7"  style="padding: 0px !important;">
                                        <div >
                                          <div class="card-body card_body_prof_ff table-responsive" style="background: #F4F5FA; padding-top: 15px !important;">
                                            <table class="table table-borderless table-sm pro_tab mb-0 d-md-table insurancetable">
                                            <tbody>
                                            <tr class="fftr fupper">
                                            <td class="font-weight-bold text-truncate">Cover Type &nbsp;&nbsp;</td>
                                            <td class="font-weight-bold text-truncate">Sum Insured &nbsp;&nbsp;</td>
                                            <td class="font-weight-bold text-truncate ">Waiting Period &nbsp;&nbsp;</td> 
                                            <td class="font-weight-bold text-truncate ">Benefit Period &nbsp;&nbsp;</td>
                                            <ng-container *ngIf="buttonShow">
                                              <td></td> 
                                            </ng-container>
                                            </tr>

                                            <ng-container *ngIf="insurance.groupedCovers"> 
                                                <ng-container *ngIf="insurance.groupedCovers.additional && insurance.groupedCovers.additional.length >0"> 
                                                    <tr class="fftr">
                                                        <td class="font-weight-bold text-truncate insurance_subhead" style="font-weight: bold !important; padding-bottom: 0px !important;" colspan="5">Additional Cover &nbsp;&nbsp;</td>
                                                        </tr>
                                                        <ng-container *ngFor="let cover of insurance.groupedCovers.additional; let i = index">
                                                         <tr class="ins_bottom_border">
                                                            <td class="text-truncatez" style="padding-left: 15px !important;">
                                                                <ng-container *ngIf="cover.insuranceCoverTypeName"> {{cover.insuranceCoverTypeName}} </ng-container>
                                                                <ng-container *ngIf="cover.insuranceCoverTypeName=='' || cover.insuranceCoverTypeName==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncatez" style="padding-left: 15px !important;">$
                                                                <ng-container *ngIf="cover.sumInsured"> {{cover.sumInsured}} </ng-container>
                                                                <ng-container *ngIf="cover.sumInsured=='' || cover.sumInsured==null "> 0 </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate">
                                                                <ng-container *ngIf="cover.waitingPeriod"> {{cover.waitingPeriod}} </ng-container>
                                                                <ng-container *ngIf="cover.waitingPeriod=='' || cover.waitingPeriod==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate">
                                                                <ng-container *ngIf="cover.benefitPeriod"> {{cover.benefitPeriod}} </ng-container>
                                                                <ng-container *ngIf="cover.benefitPeriod=='' || cover.benefitPeriod==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <ng-container *ngIf="buttonShow">
                                                              <td><a href="javascript:void(0);" (click)="openCoverEditModal(insurance,cover,'additional')" class="ff_editbtn" style="margin-top: -5px;"><i class="la la-pencil"></i></a></td>
                                                            </ng-container>

                                                         </tr>
                                                        </ng-container>
                                                </ng-container>
                                            </ng-container>

                                            <ng-container *ngIf="insurance.groupedCovers"> 
                                                <ng-container *ngIf="insurance.groupedCovers.business && insurance.groupedCovers.business.length >0"> 
                                                    <tr class="fftr">
                                                        <td class="font-weight-bold text-truncate insurance_subhead" style="font-weight: bold !important; padding-bottom: 0px !important;"  colspan="5">Business Cover &nbsp;&nbsp;</td>
                                                        </tr>
                                                        <ng-container *ngFor="let cover of insurance.groupedCovers.business; let i = index">
                                                         <tr class="ins_bottom_border">
                                                            <td class="text-truncatez" style="padding-left: 15px !important;">
                                                                <ng-container *ngIf="cover.insuranceCoverTypeName"> {{cover.insuranceCoverTypeName}} </ng-container>
                                                                <ng-container *ngIf="cover.insuranceCoverTypeName=='' || cover.insuranceCoverTypeName==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate" style="padding-left: 15px !important;">$
                                                                <ng-container *ngIf="cover.sumInsured"> {{cover.sumInsured}} </ng-container>
                                                                <ng-container *ngIf="cover.sumInsured=='' || cover.sumInsured==null "> 0 </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate">
                                                                <ng-container *ngIf="cover.waitingPeriod"> {{cover.waitingPeriod}} </ng-container>
                                                                <ng-container *ngIf="cover.waitingPeriod=='' || cover.waitingPeriod==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate">
                                                                <ng-container *ngIf="cover.benefitPeriod"> {{cover.benefitPeriod}} </ng-container>
                                                                <ng-container *ngIf="cover.benefitPeriod=='' || cover.benefitPeriod==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>

                                                            <ng-container *ngIf="buttonShow">
                                                              <td><a href="javascript:void(0);" (click)="openCoverEditModal(insurance,cover,'business')" class="ff_editbtn" style="margin-top: -5px;"><i class="la la-pencil"></i></a></td>
                                                            </ng-container>
                                                          </tr>

                                                        </ng-container>
                                                </ng-container>
                                            </ng-container>

                                            <ng-container *ngIf="insurance.groupedCovers"> 
                                                <ng-container *ngIf="insurance.groupedCovers.income && insurance.groupedCovers.income.length >0"> 
                                                    <tr class="fftr">
                                                        <td class="font-weight-bold text-truncate insurance_subhead" style="font-weight: bold !important; padding-bottom: 0px !important;" colspan="5">Income Cover &nbsp;&nbsp;</td>
                                                        </tr>
                                                        <ng-container *ngFor="let cover of insurance.groupedCovers.income; let i = index">
                                                         <tr class="ins_bottom_border">
                                                            <td class="text-truncatez" style="padding-left: 15px !important;">
                                                                <ng-container *ngIf="cover.insuranceCoverTypeName"> {{cover.insuranceCoverTypeName}} </ng-container>
                                                                <ng-container *ngIf="cover.insuranceCoverTypeName=='' || cover.insuranceCoverTypeName==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate" style="padding-left: 15px !important;">$
                                                                <ng-container *ngIf="cover.sumInsured"> {{cover.sumInsured}} </ng-container>
                                                                <ng-container *ngIf="cover.sumInsured=='' || cover.sumInsured==null "> 0 </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate">
                                                                <ng-container *ngIf="cover.waitingPeriod"> {{cover.waitingPeriod}} </ng-container>
                                                                <ng-container *ngIf="cover.waitingPeriod=='' || cover.waitingPeriod==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate">
                                                                <ng-container *ngIf="cover.benefitPeriod"> {{cover.benefitPeriod}} </ng-container>
                                                                <ng-container *ngIf="cover.benefitPeriod=='' || cover.benefitPeriod==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>

                                                            <ng-container *ngIf="buttonShow">
                                                              <td><a href="javascript:void(0);" (click)="openCoverEditModal(insurance,cover,'income')" class="ff_editbtn" style="margin-top: -5px;"><i class="la la-pencil"></i></a></td>
                                                            </ng-container>

                                                         </tr>
                                                        </ng-container>
                                                </ng-container>
                                            </ng-container>

                                            <ng-container *ngIf="insurance.groupedCovers"> 
                                                <ng-container *ngIf="insurance.groupedCovers.life && insurance.groupedCovers.life.length >0"> 
                                                    <tr class="fftr">
                                                        <td class="font-weight-bold text-truncate insurance_subhead" style="font-weight: bold !important; padding-bottom: 0px !important;"  colspan="5">Life Cover &nbsp;&nbsp;</td>
                                                        </tr>
                                                        <ng-container *ngFor="let cover of insurance.groupedCovers.life; let i = index">
                                                         <tr class="ins_bottom_border">
                                                            <td class="text-truncatez" style="padding-left: 15px !important;">
                                                                <ng-container *ngIf="cover.insuranceCoverTypeName"> {{cover.insuranceCoverTypeName}} </ng-container>
                                                                <ng-container *ngIf="cover.insuranceCoverTypeName=='' || cover.insuranceCoverTypeName==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate" style="padding-left: 15px !important;">$
                                                                <ng-container *ngIf="cover.sumInsured"> {{cover.sumInsured}} </ng-container>
                                                                <ng-container *ngIf="cover.sumInsured=='' || cover.sumInsured==null "> 0 </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate">
                                                                <ng-container *ngIf="cover.waitingPeriod"> {{cover.waitingPeriod}} </ng-container>
                                                                <ng-container *ngIf="cover.waitingPeriod=='' || cover.waitingPeriod==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate">
                                                                <ng-container *ngIf="cover.benefitPeriod"> {{cover.benefitPeriod}} </ng-container>
                                                                <ng-container *ngIf="cover.benefitPeriod=='' || cover.benefitPeriod==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>

                                                            <ng-container *ngIf="buttonShow">
                                                              <td><a href="javascript:void(0);" (click)="openCoverEditModal(insurance,cover,'life')" class="ff_editbtn" style="margin-top: -5px;"><i class="la la-pencil"></i></a></td>
                                                            </ng-container>

                                                         </tr>
                                                        </ng-container>
                                                </ng-container>
                                            </ng-container>

                                            <ng-container *ngIf="insurance.groupedCovers"> 
                                                <ng-container *ngIf="insurance.groupedCovers.severity && insurance.groupedCovers.severity.length >0"> 
                                                    <tr class="fftr">
                                                        <td class="font-weight-bold text-truncate insurance_subhead" style="font-weight: bold !important; padding-bottom: 0px !important;" colspan="5">Severity Cover &nbsp;&nbsp;</td>
                                                        </tr>
                                                        <ng-container *ngFor="let cover of insurance.groupedCovers.severity; let i = index">
                                                         <tr class="ins_bottom_border">
                                                            <td class="text-truncatez" style="padding-left: 15px !important;">
                                                                <ng-container *ngIf="cover.insuranceCoverTypeName"> {{cover.insuranceCoverTypeName}} </ng-container>
                                                                <ng-container *ngIf="cover.insuranceCoverTypeName=='' || cover.insuranceCoverTypeName==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate" style="padding-left: 15px !important;">$
                                                                <ng-container *ngIf="cover.sumInsured"> {{cover.sumInsured}} </ng-container>
                                                                <ng-container *ngIf="cover.sumInsured=='' || cover.sumInsured==null "> 0 </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate">
                                                                <ng-container *ngIf="cover.waitingPeriod"> {{cover.waitingPeriod}} </ng-container>
                                                                <ng-container *ngIf="cover.waitingPeriod=='' || cover.waitingPeriod==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate">
                                                                <ng-container *ngIf="cover.benefitPeriod"> {{cover.benefitPeriod}} </ng-container>
                                                                <ng-container *ngIf="cover.benefitPeriod=='' || cover.benefitPeriod==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>

                                                            <ng-container *ngIf="buttonShow">
                                                              <td><a href="javascript:void(0);" (click)="openCoverEditModal(insurance,cover,'severity')" class="ff_editbtn" style="margin-top: -5px;"><i class="la la-pencil"></i></a></td>
                                                            </ng-container>

                                                         </tr>
                                                        </ng-container>
                                                </ng-container>
                                            </ng-container>

                                            <ng-container *ngIf="insurance.groupedCovers"> 
                                                <ng-container *ngIf="insurance.groupedCovers.tpd && insurance.groupedCovers.tpd.length >0"> 
                                                    <tr class="fftr">
                                                        <td class="font-weight-bold text-truncate insurance_subhead" style="font-weight: bold !important; padding-bottom: 0px !important;" colspan="5">TDP Cover &nbsp;&nbsp;</td>
                                                        </tr>
                                                        <ng-container *ngFor="let cover of insurance.groupedCovers.tpd; let i = index">
                                                         <tr class="ins_bottom_border">
                                                            <td class="text-truncatez" style="padding-left: 15px !important;">
                                                                <ng-container *ngIf="cover.insuranceCoverTypeName"> {{cover.insuranceCoverTypeName}} </ng-container>
                                                                <ng-container *ngIf="cover.insuranceCoverTypeName=='' || cover.insuranceCoverTypeName==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate" style="padding-left: 15px !important;">$
                                                                <ng-container *ngIf="cover.sumInsured"> {{cover.sumInsured}} </ng-container>
                                                                <ng-container *ngIf="cover.sumInsured=='' || cover.sumInsured==null "> 0 </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate">
                                                                <ng-container *ngIf="cover.waitingPeriod"> {{cover.waitingPeriod}} </ng-container>
                                                                <ng-container *ngIf="cover.waitingPeriod=='' || cover.waitingPeriod==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate">
                                                                <ng-container *ngIf="cover.benefitPeriod"> {{cover.benefitPeriod}} </ng-container>
                                                                <ng-container *ngIf="cover.benefitPeriod=='' || cover.benefitPeriod==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>

                                                            <ng-container *ngIf="buttonShow">
                                                              <td><a href="javascript:void(0);" (click)="openCoverEditModal(insurance,cover,'tpd')" class="ff_editbtn" style="margin-top: -5px;"><i class="la la-pencil"></i></a></td>
                                                            </ng-container>
                                                          </tr>

                                                        </ng-container>
                                                </ng-container>
                                            </ng-container>

                                            <ng-container *ngIf="insurance.groupedCovers"> 
                                                <ng-container *ngIf="insurance.groupedCovers.trauma && insurance.groupedCovers.trauma.length >0"> 
                                                    <tr class="fftr">
                                                        <td class="font-weight-bold text-truncate insurance_subhead" style="font-weight: bold !important; padding-bottom: 0px !important;" colspan="5">Trauma Cover &nbsp;&nbsp;</td>
                                                        </tr>
                                                        <ng-container *ngFor="let cover of insurance.groupedCovers.trauma; let i = index">
                                                         <tr class="ins_bottom_border">
                                                            <td class="text-truncatez" style="padding-left: 15px !important;">
                                                                <ng-container *ngIf="cover.insuranceCoverTypeName"> {{cover.insuranceCoverTypeName}} </ng-container>
                                                                <ng-container *ngIf="cover.insuranceCoverTypeName=='' || cover.insuranceCoverTypeName==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate" style="padding-left: 15px !important;">$
                                                                <ng-container *ngIf="cover.sumInsured"> {{cover.sumInsured}} </ng-container>
                                                                <ng-container *ngIf="cover.sumInsured=='' || cover.sumInsured==null "> 0 </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate">
                                                                <ng-container *ngIf="cover.waitingPeriod"> {{cover.waitingPeriod}} </ng-container>
                                                                <ng-container *ngIf="cover.waitingPeriod=='' || cover.waitingPeriod==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>
                                                            <td class="text-truncate">
                                                                <ng-container *ngIf="cover.benefitPeriod"> {{cover.benefitPeriod}} </ng-container>
                                                                <ng-container *ngIf="cover.benefitPeriod=='' || cover.benefitPeriod==null "> - </ng-container>
                                                            &nbsp;&nbsp;</td>

                                                            <ng-container *ngIf="buttonShow">
                                                              <td><a href="javascript:void(0);" (click)="openCoverEditModal(insurance,cover,'trauma')" class="ff_editbtn" style="margin-top: -5px;"><i class="la la-pencil"></i></a></td>
                                                            </ng-container>

                                                         </tr>
                                                        </ng-container>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="insurance.groupedCovers.trauma.length == 0 && insurance.groupedCovers.tpd.length == 0 && insurance.groupedCovers.severity.length == 0 && insurance.groupedCovers.life.length == 0 && insurance.groupedCovers.income.length == 0 && insurance.groupedCovers.business.length == 0 && insurance.groupedCovers.additional.length == 0">
                                                <div class="">
                                                  <img src="assets/img/empty_icon.png" alt="" height="45px;">
                                                  <span style="padding: 10px 5px;">No data</span>
                                                </div>
                                            </ng-container>

                                            <!-- <tr class="fftr">
                                            <td class="font-weight-bold text-truncate insurance_subhead" style="font-weight: bold !important;" colspan="5">Life Cover &nbsp;&nbsp;</td>
                                            </tr>
                                            <tr>
                                            <td class="text-truncate" style="padding-left: 20px !important;">Super linked own&nbsp;&nbsp;</td>
                                            <td class="text-truncate">$400&nbsp;&nbsp;</td>
                                            <td class="text-truncate">7 Days&nbsp;&nbsp;</td>
                                            <td class="text-truncate">12 Months&nbsp;&nbsp;</td>
                                            <td><a href="" class="ff_editbtn" style="margin-top: -5px;"><i class="la la-pencil"></i></a></td>
                                            </tr>
                                            <tr>
                                            <td class="text-truncate" style="padding-left: 20px !important;">Whole of life&nbsp;&nbsp;</td>
                                            <td class="text-truncate">$800&nbsp;&nbsp;</td>
                                            <td class="text-truncate">7 Days&nbsp;&nbsp;</td>
                                            <td class="text-truncate">12 Months&nbsp;&nbsp;</td>
                                            <td><a href="" class="ff_editbtn" style="margin-top: -5px;"><i class="la la-pencil"></i></a></td>
                                            </tr>
                                            <tr class="fftr">
                                            <td class="font-weight-bold text-truncate insurance_subhead" colspan="5">Trauma Cover &nbsp;&nbsp;</td>
                                            </tr>
                                            <tr>
                                            <td class="text-truncate" style="padding-left: 20px !important;">Super linked own&nbsp;&nbsp;</td>
                                            <td class="text-truncate">$400&nbsp;&nbsp;</td>
                                            <td class="text-truncate">7 Days&nbsp;&nbsp;</td>
                                            <td class="text-truncate">12 Months&nbsp;&nbsp;</td>
                                            <td><a href="" class="ff_editbtn" style="margin-top: -5px;"><i class="la la-pencil"></i></a></td>
                                            </tr>
                                            <tr>
                                            <td class="text-truncate" style="padding-left: 20px !important;">Whole of life&nbsp;&nbsp;</td>
                                            <td class="text-truncate">$800&nbsp;&nbsp;</td>
                                            <td class="text-truncate">7 Days&nbsp;&nbsp;</td>
                                            <td class="text-truncate">12 Months&nbsp;&nbsp;</td>
                                            <td><a href="" class="ff_editbtn" style="margin-top: -5px;"><i class="la la-pencil"></i></a></td>
                                            </tr>
                                            <tr>
                                            <td class="text-truncate" style="padding-left: 20px !important;">Whole of life&nbsp;&nbsp;</td>
                                            <td class="text-truncate">$800&nbsp;&nbsp;</td>
                                            <td class="text-truncate">7 Days&nbsp;&nbsp;</td>
                                            <td class="text-truncate">12 Months&nbsp;&nbsp;</td>
                                            <td><a href="" class="ff_editbtn" style="margin-top: -5px;"><i class="la la-pencil"></i></a></td>
                                            </tr> -->
                                            </tbody>
                                            </table>
                                            <ng-container *ngIf="buttonShow"></ng-container>
                                            <a href="javascript:void(0);" (click)="openCoverAddModal(insurance)" class="new_record">ADD NEW COVER</a> 
                                          </div>
                                        </div>
                                      </td>  
                                    </tr>


                                  </ng-container>
                                  <!-- <tr>
                                      <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;0123&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Plan A.&nbsp;&nbsp;</td>
                                      <td class="text-truncate">$10,000&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Alan.&nbsp;&nbsp;</td>
                                      <td class="text-truncate">$1000&nbsp;&nbsp;</td>
                                      <td class="text-truncate">$10,000&nbsp;&nbsp;</td>
                                      <td class="text-truncate">2 months&nbsp;&nbsp;</td>
                                      <td class="text-truncate">2 months&nbsp;&nbsp;</td>
                                      <td> <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td>
                                  </tr> -->
                              </tbody>
                          </table>
                        </ng-container>

                        <ng-container *ngIf="insuranceDataLength==0">
                            <div class="">
                              <img src="assets/img/empty_icon.png" alt="" height="45px;">
                              <span style="padding: 10px 5px;">No data</span>
                            </div>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="buttonShow">
                        <a href="javascript:void(0);" (click)="openInsuranceAddModal()" class="new_record">ADD NEW RECORD</a>
                      </ng-container>
                    </div>
                  </div>

                  <ng-template #insuranceDetailsTemplate>
                      <div class="modal-body text-center" >
                          <img src="assets/img/warning.png" alt="" class="alert_img">
                          <!-- <img *ngIf="status==false" src="assets/img/verified.png" alt="" class="alert_img"> -->
                          <p>{{message}}</p>   
                          <div class="form-actions center mb-1">
                              <button type="button" class="btn alert_no_b mr-1" (click)="decline(insurance)"> 
                                  No
                              </button>
                              <button type="button" class="btn alert_yes_b" (click)="confirmInsuranceDelete(insurance)" >
                                  Yes
                              </button>
                          </div>
                      </div>
                  </ng-template>



                  <div id="heading13" role="tab" class="card-header border-bottom-lighten-4 bbn_ff"> 
                    <a href="javascript:void(0);" data-toggle="collapse" [ngClass]="{ 'collapsed': !showInsuranceMedical }" (click)="clickMedicalInsurance()" [attr.aria-expanded]="showInsuranceMedical" aria-controls="accordion13" class="h6 blue collapsed prof_head">Medical Insurance</a> 
                  </div>
                  <div id="al1" role="tabpanel" aria-labelledby="heading13" [ngClass]="{ 'show': showInsuranceMedical }" class="collapse" [attr.aria-expanded]="showInsuranceMedical">
                    <div class="card-body card_body_prof_ff table-responsive"> 
                      <ng-container *ngIf="isLoaded15">                  
                        <table class="table table-borderless table-sm pro_tab mb-0 d-md-table">
                            <tbody>
                                <!-- <tr class="text-truncate"><span class="ffn_submenu">Medical Insurance</span></tr> -->
                                <ng-container *ngIf="medicalInsuranceInfoLength>0">
                                  <tr class="fftr fupper">
                                      <td class="font-weight-bold text-truncate">Policy Type &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate">Policy Number &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate">Plan Name &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate ">Total Premium &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate ">Policy Owner &nbsp;&nbsp;</td>
                                      <ng-container *ngIf="buttonShow">
                                        <td></td>
                                        <td></td>
                                      </ng-container>
                                  </tr>
                                  <ng-container *ngFor="let medicalInsurance of medicalInsuranceInfo; let i = index">
                                    <tr>
                                        <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;
                                          <ng-container *ngIf="medicalInsurance.insuranceMedicalTypeName"> {{medicalInsurance.insuranceMedicalTypeName}} </ng-container>
                                          <ng-container *ngIf="medicalInsurance.insuranceMedicalTypeName=='' || medicalInsurance.insuranceMedicalTypeName==null "> - </ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                            <ng-container *ngIf="medicalInsurance.policyNumber"> {{medicalInsurance.policyNumber}} </ng-container>
                                            <ng-container *ngIf="medicalInsurance.policyNumber=='' || medicalInsurance.policyNumber==null "> - </ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                            <ng-container *ngIf="medicalInsurance.planName"> {{medicalInsurance.planName}} </ng-container>
                                            <ng-container *ngIf="medicalInsurance.planName=='' || medicalInsurance.planName==null "> - </ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="">$
                                            <ng-container *ngIf="medicalInsurance.totalPremiumValue"> {{medicalInsurance.totalPremiumValue}} </ng-container>
                                            <ng-container *ngIf="medicalInsurance.totalPremiumValue=='' || medicalInsurance.totalPremiumValue==null "> - </ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                            <ng-container *ngIf="medicalInsurance.ownerName"> {{medicalInsurance.ownerName}}</ng-container>
                                            <ng-container *ngIf="medicalInsurance.ownerName=='' || medicalInsurance.ownerName==null"> - </ng-container>
                                        &nbsp;&nbsp;</td>

                                        <ng-container *ngIf="buttonShow">
                                          <td>
                                            <a href="javascript:void(0);" (click)="openMedicalInsuranceEdit(medicalInsurance);" class="ff_editbtn"><i class="la la-pencil"></i></a>
                                          </td>
                                          <td>
                                            <a href="javascript:void(0);" (click)="openMedicalInsuranceConfirmationDialog(medicalInsuranceTemplate,medicalInsurance)"><i class="la la-trash"></i></a>
                                          </td>
                                        </ng-container>

                                    </tr>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="medicalInsuranceInfoLength==0">
                                  <div class="">
                                    <img src="assets/img/empty_icon.png" alt="" height="45px;">
                                    <span style="padding: 10px 5px;">No data</span>
                                  </div>
                                </ng-container>
                                
                            </tbody>
                        </table> 
                      </ng-container>
                      <ng-container *ngIf="buttonShow">
                        <a href="javascript:void(0);" (click)="openMedicalInsuranceAddModal();" class="new_record">ADD NEW RECORD</a>
                      </ng-container>
                    </div>
                  </div>

                  <ng-template #medicalInsuranceTemplate>
                      <div class="modal-body text-center" >
                          <img src="assets/img/warning.png" alt="" class="alert_img">
                          <p>{{message}}</p>   
                          <div class="form-actions center mb-1">
                              <button type="button" class="btn alert_no_b mr-1" (click)="decline(medicalInsurance)"> 
                                  No
                              </button>
                              <button type="button" class="btn alert_yes_b" (click)="confirmMedicalInsuranceDelete(medicalInsurance)" >
                                  Yes
                              </button>
                          </div>
                      </div>
                  </ng-template>



                  <div id="heading14" role="tab" class="card-header border-bottom-lighten-4 bbn_ff">
                    <a href="javascript:void(0);" data-toggle="collapse" [ngClass]="{ 'collapsed': !showRetirementIncome }" (click)="clickRetirementIncome()" [attr.aria-expanded]="showRetirementIncome" aria-controls="accordion14" class="h6 blue collapsed prof_head">Retirement Income</a> 
                    <!-- <a href="" class="ff_editbtn"><i class="la la-pencil"></i></a> -->
                  </div>
                  <div id="em1" role="tabpanel" aria-labelledby="heading13" [ngClass]="{ 'show': showRetirementIncome }" class="collapse" [attr.aria-expanded]="showRetirementIncome">
                    <div class="card-body card_body_prof_ff  table-responsive">
                      <ng-container *ngIf="isLoaded14">
                        <ng-container *ngIf="retirementIncomeInfoLength>0">
                          <table class="table table-borderless table-sm pro_tab mb-0 d-md-table">
                              <tbody>
                                  <tr class="fftr fupper">
                                      <td class="font-weight-bold text-truncate">Retirement Plan &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate">Retirement No &nbsp;&nbsp;</td>
                                      <td class="font-weight-bold text-truncate">Pension Balance &nbsp;&nbsp;</td>
                                      <!-- <td class="font-weight-bold text-truncate ">Type &nbsp;&nbsp;</td> -->
                                      <ng-container *ngIf="buttonShow">
                                        <td></td>
                                        <td></td>
                                      </ng-container>
                                  </tr>
                                  <ng-container *ngFor="let retirementIncome of retirementIncomeInfo; let i = index">
                                    <tr>
                                        <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;
                                          <!-- <ng-container *ngIf="retirementIncome.retirementPlanName"> {{retirementIncome.retirementPlanName}} </ng-container>
                                          <ng-container *ngIf="retirementIncome.retirementPlanName=='' || retirementIncome.retirementPlanName==null "> - </ng-container> -->
                                          <ng-container *ngIf="retirementIncome.retirementPlan"> {{retirementIncome.retirementPlan | limitTo : 50}} </ng-container>
                                          <ng-container *ngIf="retirementIncome.retirementPlan=='' || retirementIncome.retirementPlan==null "> - </ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                            <ng-container *ngIf="retirementIncome.referenceNumber"> {{retirementIncome.referenceNumber}} </ng-container>
                                            <ng-container *ngIf="retirementIncome.referenceNumber=='' || retirementIncome.referenceNumber==null "> - </ng-container>
                                        &nbsp;&nbsp;</td>
                                        <td class="text-truncate">
                                            <ng-container *ngIf="retirementIncome.pensionBalance"> ${{retirementIncome.pensionBalance}} </ng-container>
                                            <ng-container *ngIf="retirementIncome.pensionBalance=='' || retirementIncome.pensionBalance==null "> 0 </ng-container>
                                        &nbsp;&nbsp;</td>
                                        <!-- <td class="text-truncate">
                                            <ng-container *ngIf="retirementIncome.retirementPlanSelectionName"> {{retirementIncome.retirementPlanSelectionName}} </ng-container>
                                            <ng-container *ngIf="retirementIncome.retirementPlanSelectionName=='' || retirementIncome.retirementPlanSelectionName==null "> - </ng-container>
                                        &nbsp;&nbsp;</td> -->

                                        <ng-container *ngIf="buttonShow">
                                          <td>
                                            <a href="javascript:void(0);" (click)="openRetirementIncomeEdit(retirementIncome)" class="ff_editbtn"><i class="la la-pencil"></i></a>
                                          </td>
                                          <td>
                                            <a href="javascript:void(0);" (click)="openRetirementIncomeConfirmationDialog(retirementIncomeTemplate,retirementIncome)"><i class="la la-trash"></i></a>
                                          </td>
                                        </ng-container>

                                    </tr>
                                  </ng-container>
                                  <!-- <tr>
                                      <td class="text-truncate"><span class="ffn_dot"></span>&nbsp;Doctor&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Andrew&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Andrew&nbsp;&nbsp;</td>
                                      <td class="text-truncate">206A&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Charters Towers Rd&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Hermit Park QLD&nbsp;&nbsp;</td>
                                      <td class="text-truncate">4812&nbsp;&nbsp;</td>
                                      <td class="text-truncate">Australia&nbsp;&nbsp;</td>
                                      <td><a href="" class="ff_editbtn"><i class="la la-pencil"></i></a></td>
                                  </tr> -->
                              </tbody>
                          </table>
                        </ng-container>
                        <ng-container *ngIf="retirementIncomeInfoLength==0">
                          <div class="">
                            <img src="assets/img/empty_icon.png" alt="" height="45px;">
                            <span style="padding: 10px 5px;">No data</span>
                          </div>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="buttonShow">
                        <a href="javascript:void(0);" (click)="openRetirementIncomeAddModal()" class="new_record">ADD NEW RECORD</a>
                      </ng-container>
                    </div>
                  </div>

                  <ng-template #retirementIncomeTemplate>
                      <div class="modal-body text-center" >
                          <img src="assets/img/warning.png" alt="" class="alert_img">
                          <!-- <img *ngIf="status==false" src="assets/img/verified.png" alt="" class="alert_img"> -->
                          <p>{{message}}</p>   
                          <div class="form-actions center mb-1">
                              <button type="button" class="btn alert_no_b mr-1" (click)="decline(retirementIncome)"> 
                                  No
                              </button>
                              <button type="button" class="btn alert_yes_b" (click)="confirmRetirementIncomeDelete(retirementIncome)" >
                                  Yes
                              </button>
                          </div>
                      </div>
                  </ng-template>








                  






                  <ngx-ui-loader  ></ngx-ui-loader>
                </div> 
              </ng-container>
              <ng-container *ngIf="factFindersNewLength==0">
                <div class="" style="display: block; margin-left: auto; margin-right: auto; padding: 85px 20px;">
                  <img src="assets/img/empty_state_img.png" class="img-fluid" alt="" style="width: 360px; ">
                  <span style="display: block; text-align: center; margin-top: 11px; font-size: 16px;">No data</span>
                </div>
              </ng-container>
            </ng-container>

            <!-- reviewed -->
            <div class="card-header card_head_fs" style="border-top: 1px solid #e8e8e8;">    
              <!-- <button class="btn btn-sm app_document_btn" *ngIf="recordType && recordType==2" type="button" style="margin-top: -28px; border-radius: 100px; margin-right: 15px;" (click)="showMarkAsReviewed(template12)" >Mark as Reviewed</button> -->
   
              <span class="float-left">
                <a (click)="showMarkAsReviewed(template12)" href="javascript:void(0);" *ngIf="recordType && recordType==2" ><img src="assets/img/unchecked.png" alt="" style="height: 16px; margin-top: -2px;">  Reviewed by admin</a>
                <a href="javascript:void(0);" *ngIf="recordType && recordType==3" ><img src="assets/img/checked.png" alt="" style="height: 16px; margin-top: -2px;">  Reviewed by admin</a>
              </span>

              <span class="float-right">
                <button class="btn btn-sm app_document_btn" *ngIf="recordType && recordType==3" type="button" style="border-radius: 100px;  position: absolute;   margin-left: -135px;" (click)="showSubmitToXplan(template1)" >Submit to XPLAN</button>
              </span>

                  <!-- <img src="assets/img/checked.png" alt="" style="height: 16px; margin-top: -2px;"> -->
            </div>

          </div>
        </div>
        <!-- </section> -->
      </div>

    </div>
  </div>

  <ng-template #template>
    <div class="modal-header border-bottom-0">
      <h5 class="modal-title factfinderheader" id="exampleModalLabel">Edit Personal Details</h5>
      <button type="button" class="close"  (click)="modalRehide()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form [formGroup]="personalDetailsForm" novalidate class="form " (ngSubmit)="onSubmitPersonalDetails()" >

    <div class="modal-body factfinderbody">
      <div class="form-group row factfinderpopup">
        <label for="inputPassword" class="labelwidth">Title</label>
        <div class="inputwidth"> <span>:</span> 
          <!-- <input type="text" maxlength="75" formControlName="title" [(ngModel)]="personalDataEdit.title" class="form-control form-control-sm" placeholder="Title"> -->
          <select formControlName="title" [(ngModel)]="personalDataEdit.title" class="form-control form-control-sm" placeholder="Title" >
              <option value="0">Please select</option>
              
              <ng-container *ngFor="let personalData_title of personalData_title_list; let i = index">
                  <option value="{{personalData_title.key}}">{{personalData_title.value}}</option>
              </ng-container>
              
              <!-- <option value="2">Mrs</option>
              <option value="3">Miss</option>
              <option value="4">Ms</option>
              <option value="5">Dr</option>
              <option value="6">Prof</option> -->
            </select>
              <div class="form-group">
                <div *ngIf="submitted && f.title.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.title.errors.required">Title is required.</div>
              </div>
              </div>
        </div>
      </div>
      
      <div class="form-group row factfinderpopup">
        <label for="inputPassword" class="labelwidth">First Name</label> 
        <div class="inputwidth"> <span>:</span> 
          <input formControlName="firstName" [(ngModel)]="personalDataEdit.firstName" type="text" class="form-control form-control-sm" placeholder="First Name">
          <div class="form-group">
              <div *ngIf="submitted && f.firstName.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.firstName.errors.required">First name is required.</div>
              </div>
            </div>
        </div>
      </div>
      <div class="form-group row factfinderpopup">
        <label for="inputPassword" class="labelwidth">Second Name</label>
        <div class="inputwidth"> <span>:</span>
          <input formControlName="middleName" [(ngModel)]="personalDataEdit.middleName" type="text" class="form-control form-control-sm" placeholder="Second Name">
          <div class="form-group">
              <div *ngIf="submitted && f.middleName.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.middleName.errors.required">Middle name is required.</div>
              </div>
            </div>
        </div>
      </div>
      <div class="form-group row factfinderpopup">
        <label for="inputPassword" class="labelwidth">Surname</label>
        <div class="inputwidth"> <span>:</span>
          <input formControlName="surname" [(ngModel)]="personalDataEdit.surname" type="text" class="form-control form-control-sm" placeholder="Surname">
          <div class="form-group">
            <div *ngIf="submitted && f.surname.errors"   class="form_alert">
                    <span  class="ft-alert-circle"></span>
                <div *ngIf="f.surname.errors.required">Surname is required.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row factfinderpopup">
        <label for="inputPassword" class="labelwidth">Gender</label>
        <div class="inputwidth"> <span>:</span>
          <select formControlName="gender" [(ngModel)]="personalDataEdit.gender" class="form-control form-control-sm" placeholder="Gender" >
              <option value="100">Please select</option>
              
              <ng-container *ngFor="let personalInfo_gender of personalInfo_gender_list; let i = index">
                  <option value="{{personalInfo_gender.key}}">{{personalInfo_gender.value}}</option>
              </ng-container>
              <!-- <option value="1">Male</option>
              <option value="0">Female</option> -->
            </select>
            <div class="form-group">
                <div *ngIf="submitted && f.gender.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.gender.errors.required">Gender is required.</div>
                </div>
              </div>
        </div>
      </div>
      <div class="form-group row factfinderpopup">
        <label for="inputPassword" class="labelwidth">Date of Birth</label>
        <div class="inputwidth"> <span>:</span>
          <!-- (ngModelChange)="onChange($event)" -->
          <div class="input-group prepend_group">
            <span class="input-group-prepend" style="top: 0px;     border-color: #32a3ca;">
                <div class="input-group-text border-right-0 prepend_new_class new_clock">
                <span class="prepend_span_goals" > 
              
                        <i class="ficon ft-clock" style="    position: absolute; margin-top: -11px;"></i>

                </span></div>
            </span>

            <input type="text" formControlName="dateOfBirth" [(ngModel)]="personalDataEdit.dateOfBirth" class="form-control form-control-sm"  
            [bsValue]="currentDate"
            [minDate]="currentDate"
            placement="top"
            bsDatepicker                                      
            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"  placeholder="Date of Birth" readonly style="width: unset; background-color: #fff;" >    
          </div>
          <div class="form-group">
              <div *ngIf="submitted && f.dateOfBirth.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.dateOfBirth.errors.required">Date of birth is required.</div>
              </div>
            </div>                           
        </div>
      </div>
      <div class="form-group row factfinderpopup">
        <label for="inputPassword" class="labelwidth">Marital Status</label>
        <div class="inputwidth"> <span>:</span>
          <!-- <input type="text" class="form-control form-control-sm" placeholder="Marital Status"> -->

          <select formControlName="maritalStatus" [(ngModel)]="personalDataEdit.maritalStatus" class="form-control form-control-sm" placeholder="Marital Status" >
              <option value="0">Please select</option>

              <ng-container *ngFor="let personalInfo_marital of personalInfo_marital_list; let i = index">
                  <option value="{{personalInfo_marital.key}}">{{personalInfo_marital.value}}</option>
              </ng-container>

              <!-- <option value="1">Single</option>
              <option value="2">Married</option>
              <option value="3">Separated</option>
              <option value="4">Divorced</option>
              <option value="5">Engaged</option>
              <option value="6">Widowed</option>
              <option value="9999">De Facto</option> -->
            </select>
            <div class="form-group">
                <div *ngIf="submitted && f.maritalStatus.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.maritalStatus.errors.required">Marital status is required.</div>
                </div>
              </div> 
        </div>
      </div>
      <div class="form-group row factfinderpopup">
        <label for="inputPassword" class="labelwidth">Tax File Number</label>
        <div class="inputwidth"> <span>:</span>
          <input formControlName="taxFileNumber" [(ngModel)]="personalDataEdit.taxFileNumber" type="text" class="form-control form-control-sm" placeholder="Tax File Number">
          <div class="form-group">
              <div *ngIf="submitted && f.taxFileNumber.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.taxFileNumber.errors.required">Tax file number is required.</div>
              </div>
            </div> 
        </div>
      </div>

      <div class="form-group row factfinderpopup">
        <label for="inputPassword" class="labelwidth">Smoker</label>
        <div class="inputwidth"> <span>:</span>
          <select formControlName="smoker" [(ngModel)]="personalDataEdit.smoker" class="form-control form-control-sm" placeholder="Smoker" >
            <option value="0">Please select</option>          
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>

          <div class="form-group">
            <div *ngIf="submitted && f.smoker.errors"   class="form_alert">
              <span  class="ft-alert-circle"></span>
              <div *ngIf="f.smoker.errors.required">Smoking status is required.</div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row factfinderpopup">
        <label for="inputPassword" class="labelwidth">Smoker</label>

        <select formControlName="title" [(ngModel)]="personalDataEdit.smoker" class="form-control form-control-sm" placeholder="Title" >
          <option value="0">Please select</option>          
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>

        <div class="custom-control custom-radio custom-control-inline radioinputss" style="margin-left: 17px;">
            <input type="radio" id="status1" [value]="true" formControlName="smoker" [(ngModel)]="personalDataEdit.smoker"  class="custom-control-input radioinputss-input">
            <label class="custom-control-label radiolabels" for="status1">Yes</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline radioinputss">
            <input type="radio" id="status2" [value]="false" formControlName="smoker" [(ngModel)]="personalDataEdit.smoker" class="custom-control-input radioinputss-input">
            <label class="custom-control-label radiolabels" for="status2">No</label>
          </div>


        <div class="form-group">
            <div *ngIf="submitted && f.smoker.errors"   class="form_alert">
                    <span  class="ft-alert-circle"></span>
                <div *ngIf="f.smoker.errors.required">Smoking status is required.</div>
            </div>
          </div> 
      </div> -->

    </div>
    <div class="modal-footer border-top-0 pt-0">
      <button  class="btn btn-primary factfinderbtn">Save</button>
    </div>
    </form>
  </ng-template>

  <ng-template #template1>
    <div class="modal-body text-center xplanmodal" >
      <p>Are you sure you want to <br><span>Submit</span> this version to XPLAN?</p>
      <div class="form-actions center">
        <button type="button" (click)="submitToXplan(template2)" class="btn alert_yes_b mr-1">
            Yes, Confirm
        </button>                          
        <button type="button" (click)="modalRehide()" class="btn alert_no_b"> 
          No
        </button>
    </div>
    </div>      
  </ng-template>

  <ng-template #template12> 
    <div class="modal-body text-center xplanmodal" >
      <p>Are you sure you want to <br><span>mark as reviewed</span> this version?</p>
      <div class="form-actions center">
        <button type="button" (click)="markAsReviewed()" class="btn alert_yes_b mr-1">
            Yes, Confirm
        </button>                          
        <button type="button" (click)="modalRehide()" class="btn alert_no_b"> 
          No
        </button>
    </div>
    </div>      
  </ng-template>

  <ng-template #template2>  
      <div class="modal-content">
          <div class="modal-body text-center">
          <div class="loader">SUBMITTING</div>
          <div class="textsubmit">
          <h4>XPLAN submission is at progress..</h4>
          <span></span>
          </div>
          </div>
      </div>    
    </ng-template>

  <!-- status modal -->
  <ng-template #template_status> 
      <div class="modal-content">
        <div class="modal-header border-0">
          <h5 class="modal-title status_title" id="exampleModalLabel">Status update history</h5>
          <button type="button" (click)="modalRehide()" class="close" >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body status_body">
          <ul class="timeline">
            <ng-container *ngIf="statusList.length == 0">
              <li>
                <h4 class="timeline_h4">No data.</h4>
              </li>
            </ng-container>
            <ng-container *ngFor="let status of statusList; let i = index">
              <li>
                <h4 class="timeline_h4">                  
                  <ng-container *ngIf="status.recordType == 1">Draft - </ng-container>
                  <ng-container *ngIf="status.recordType == 2">Completed by Client - </ng-container>
                  <ng-container *ngIf="status.recordType == 3">Reviewed - </ng-container>
                  <ng-container *ngIf="status.recordType == 4">Submitted to XPLAN - </ng-container>

                  <ng-container *ngIf="status.createdBy && status.createdBy != null">
                    
                    <ng-container *ngIf="status.createdBy && status.createdBy.firstName">
                      {{status.createdBy.firstName}}
                    </ng-container>    
                    <ng-container *ngIf="status.createdBy && status.createdBy.lastName">
                        {{status.createdBy.lastName}}
                    </ng-container>
                  </ng-container>

                  <span class="timeline_span">-                   
                  {{ status.changeAt +"Z" | date: 'M/d/yy, h:mm a' }}
                </span></h4>
              </li>
            </ng-container>

            <!-- <li>
                <h4 class="timeline_h4">Admin reviewed <span class="timeline_span">- 12/23/2019 09:09AM</span></h4>
            </li>
            <li>
              <h4 class="timeline_h4">Admin reviewed <span class="timeline_span">- 12/23/2019 09:09AM</span></h4>
            </li>
            <li>
              <h4 class="timeline_h4">Admin reviewed <span class="timeline_span">- 12/23/2019 09:09AM</span></h4>
            </li>
            <li>
              <h4 class="timeline_h4">Admin reviewed <span class="timeline_span">- 12/23/2019 09:09AM</span></h4>
            </li><li>
              <h4 class="timeline_h4">Admin reviewed <span class="timeline_span">- 12/23/2019 09:09AM</span></h4>
            </li> -->

          </ul>
        </div>
      </div>
    </ng-template> 
  
    <!-- <ng-template #template_success>
        <div class="modal-content">
            <div class="modal-body text-center" style="padding: 3rem;">
            <div class="success-icon">
            <div class="success-icon__tip"></div>
            <div class="success-icon__long"></div>
            </div>
            <div class="text-center pt-3">
            <h4 style="color: #636363;">Successfully Submitted</h4>
            <button (click)="modalRehide()" class="btn alert_yes_b mt-3">Done</button>
            </div>
            </div>
        </div> 
      </ng-template>   -->

      <div class="modal " data-backdrop="true" [ngStyle]="{'display':showSuccessModal}" id="successmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="background-color: rgba(0, 0, 0, 0.5);">
          <div class="modal-dialog" role="document">
          <div class="modal-content">
          <div class="modal-body text-center" style="padding: 3rem;">
          <div class="success-icon">
          <div class="success-icon__tip"></div>
          <div class="success-icon__long"></div>
          </div>
          <div class="text-center pt-3 subsuc">
          <h4 style="color: #636363;">Successfully Submitted</h4>
          <button (click)="hideModals()" class="btn alert_yes_b mt-3">Done</button>
          </div>
          </div>
          </div>
          </div>
          </div>

          <div class="modal " data-backdrop="true" [ngStyle]="{'display':showErrorModal}" id="cancelmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true" style="background-color: rgba(0, 0, 0, 0.5);">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-body text-center" style="padding: 3rem;">
                      <img src="../assets/submittion_failed.png" alt="">
                      <div class="text-center pt-3 subfaild">
                          <h4 style="color: #636363;">Submission Failed</h4>
                          <button (click)="hideModals()" class="btn alert_no_b  mt-3">Close</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>    

      <!-- <ng-template #template_failed>
          <div class="modal-content">
              <div class="modal-body text-center" style="padding: 3rem;">
              <img src="../assets/submittion_failed.png" alt="">
              <div class="text-center pt-3">
              <h4 style="color: #636363;">Submission Failed</h4>
              <button (click)="modalRehide()" class="btn alert_no_b mt-3">Close</button>
              </div>
              </div>
          </div> 
        </ng-template>      -->

        <!-- <table id="contentToConvert">  
          <tr>  
            <th>Column1</th>  
            <th>Column2</th>  
            <th>Column3</th>  
          </tr>  
          <tr>  
            <td>Row 1</td>  
            <td>Row 1</td>  
            <td>Row 1</td>  
          </tr>  
          <tr>  
            <td>Row 2</td>  
            <td>Row 2</td>  
            <td>Row 2</td>  
          </tr>  
          <tr>  
            <td>Row 3</td>  
            <td>Row 3</td>  
            <td>Row 3</td>  
          </tr>  
          <tr>  
            <td>Row 4</td>  
            <td>Row 4</td>  
            <td>Row 4</td>  
          </tr>  
          <tr>  
            <td>Row 5</td>  
            <td>Row 5</td>  
            <td>Row 5</td>  
          </tr>  
          <tr>  
            <td>Row 6</td>  
            <td>Row 6</td>  
            <td>Row 6</td>  
          </tr>  
        </table>  -->

  <app-footer></app-footer>
