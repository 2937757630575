import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FamilyNodePipe } from './family-node.pipe';
import { FamilyTreeService } from './family-tree.service';
import * as d3 from "d3";
import { hierarchy, HierarchyPointNode, TreeLayout, tree } from "d3-hierarchy";
import { RelationType, GenderType, GraphType } from './distribution-enums';
import { GetTreeModel } from './get-tree.model';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-family-tree',
  templateUrl: './family-tree.component.html',
  styleUrls: ['./family-tree.component.css'],
  providers: [FamilyNodePipe]
})
export class FamilyTreeComponent implements OnInit {

  @Input() clientCode: string;
  @Input() clientId: string;


  private root: HierarchyPointNode;
  private tree: TreeLayout;
  private svg: any;
  public selectedGraphType: number;
  public selectedGraphNumber: number;
  public graphCountOfSelectedType: number;

  siblings = [];
  data = {};
  isFullScreen = false;
  members: GetTreeModel[]

  // Assign Enum Types
  relationType = RelationType;
  genderType = GenderType;
  graphType = GraphType;

  // Grapy Types List
  graphTypesList = [
    {
      value: this.graphType.StandardWill,
      name: "Standard Will Distribution",
      count: 7,
    },
    {
      value: this.graphType.NewDeFacto,
      name: "New De Facto Distribution",
      count: 6,
    },
    {
      value: this.graphType.AgeBased,
      name: "Age Based Dependencies",
      count: 7,
    },
    {
      value: this.graphType.Testamentary,
      name: "Testamentary Discretionary Trust",
      count: 7,
    },
    {
      value: this.graphType.PersonalSuccession,
      name: "Personal Succession Plan",
      count: 4,
    },
    {
      value: this.graphType.ProtectedWealth,
      name: "Protected Wealth",
      count: 2,
    },
  ];

  defaultParentWidth: number;
  defaultParentHeight: number;

  mainUserFinanceTotal: number = 0;
  partnerUserFinanceTotal: number = 0;
  jointUserFinanceTotal: number = 0;
  financeMainUserTotal: number = 0;
  financePartnerUserTotal: number = 0;
  financeJointUserTotal: number = 0;
  svgData: any;
  mainNodeAge: number = 0;
  mainNodeGender: any;
  viewBoxVal = 2.05;
  transDVal = 7.2;
  capturedImage;

  maleIcon = environment.icons.maleIcon;
  femaleIcon = environment.icons.femaleIcon;
  maleDeceasedIcon = environment.icons.maleDeceasedIcon;
  femaleDeceasedIcon = environment.icons.femaleDeceasedIcon;
  blueCircleEmpty = environment.icons.blueCircleEmpty;
  blueCircleFull = environment.icons.blueCircleFull;
  blueReqFilled = environment.icons.blueReqFilled;
  blueTriangleFull = environment.icons.blueTriangleFull;
  grayCircleEmpty = environment.icons.grayCircleEmpty;
  redCircleFull = environment.icons.redCircleFull;
  slashGray = environment.icons.slashGray;
  slashRed = environment.icons.slashRed;
  containerHeight;
  ownerAge: any;
  owner: GetTreeModel;
  spouse: GetTreeModel;
  spouseAge: number;
  hasTree: boolean;


  constructor(private familyTreeService: FamilyTreeService,
    private preloaderService: NgxUiLoaderService,
    private familyNodePipe: FamilyNodePipe,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit(): void {
    this.defaultParentWidth = parseInt(d3.select(".svg-wr").style("width"));
    this.defaultParentHeight = parseInt(d3.select(".svg-wr").style("height"));
    this.containerHeight = this.defaultParentHeight;
    this.getFamilyTreeExist(this.clientId, this.clientCode)
  }

  private getFamilyTreeExist(clientId: string, clientCode: string) {
    this.preloaderService.start()
    localStorage.removeItem("imageURL");
    this.familyTreeService.getFamilyTreeExist(clientId, clientCode).subscribe(
      (res: any) => {
        const treeExistData = res.response
        if (res.success && treeExistData.id) {
          this.hasTree = true;
          this.getFamilyTreeFromServer(treeExistData.id)
        } else {
          this.preloaderService.stop();
          this.hasTree = false;
        }
      }, err => { 
        this.preloaderService.stop();
      })
  }

  private getFamilyTreeFromServer(familyTreeId: string) {
    this.familyTreeService.getFamilyData(familyTreeId).subscribe(
      (res: any) => {
        if (res.success) {
          const membersList = this.familyNodePipe.sortMembers(res.response.members)
          this.updateSharedMemberList(membersList);

          this.d3Start(membersList);
        }
        this.preloaderService.stop()
      }, err => {
        this.preloaderService.stop()
      })
  }

  updateSharedMemberList(memberList: any) {
    this.familyTreeService.changFamilyTree(memberList)
  }

  d3Start(value) {
    console.log('svg > *')
    d3.selectAll("svg > *").remove();
    this.siblings = [];
    this.createSVG(this.viewBoxVal, this.transDVal);
    this.members = value
    this.distributionTree()
  }


  private createSVG(viewBoxVal: number, transDVal: number) {
    let parentWidth = this.defaultParentWidth;
    let parentHeight = this.defaultParentHeight;
    let transVal = parentWidth / transDVal;
    let viewBoxWidth = parentWidth * viewBoxVal;
    let viewBoxHeight = parentHeight * viewBoxVal;

    this.svg = d3
      .select("#svg-container")
      .append("svg")
      .attr("class", "graph-svg")
      .attr("id", "graph-svg-id")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", "0 0 " + (viewBoxWidth) + " " + (viewBoxHeight))
      .append("g")
      .attr("class", "g")
      .attr(
        "transform",
        "translate(" + transVal + "," + parentHeight / 10 + ")"
      );

    d3.select("svg g.g").append("g").attr("class", "links");

    d3.select("svg g.g").append("g").attr("class", "nodes");
    d3.select("svg g").attr("id", "wrapper-g");

    this.tree = tree();
    this.tree.size([parentHeight, parentWidth]);
  }

  distributionTree() {
    if (this.members.length > 0) {
      this.owner = this.familyNodePipe.getOwner(this.members)
      this.ownerAge = this.calcAge(this.owner.dateOfBirth);
      this.spouse = this.familyNodePipe.getMainSpouse(this.members)
      this.spouseAge = this.calcAge((this.spouse || {}).dateOfBirth);

      this.siblings = []
      let values = this.familyNodePipe.generateDistributionTree(this.members)

      values.sibilings.forEach((element) => {
        if (element != null) {
          this.siblings.push(element);
        }
      });

      if (this.tree != null) {
        this.root = this.tree(hierarchy(values.nodes));
        this.draw(this.root);
      }
    } else {
      localStorage.removeItem("imageURL");
    }
  }

  calcAge(dob: string) { // Calculate Age by DOB-- Add this to a pipe [TEMP]
    if (dob) {
      let timeDiff = Math.abs(Date.now() - new Date(dob).getTime());
      const calAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      return calAge;
    } else {
      return 0;
    }
  }

  // Draw Tree
  private draw(root) {
    const xValue = 3.1;
    const yValue = 120;

    // Nodes
    var nodes = this.svg.selectAll(".node").data(root.descendants()).enter();

    var links = this.svg
      .select("svg g.links")
      .selectAll("line.link")
      .data(root.links())
      .enter();

    links
      .append("path")
      .attr("class", "link")
      .attr("fill", "none")
      .attr("d", this.elbow)
      .attr("style", function (d) {
        console.log("d.data.colour", d.target.data);
        return (
          "stroke:" +
          (d.target.data.colour ? d.target.data.colour : "#ccc") +
          ";stroke-width: 2px;"
        );
      });

    this.svg
      .selectAll(".sibling")
      .data(this.siblings)
      .enter()
      .append("path")
      .attr("class", "sibling")
      .attr("fill", "none")
      .attr("style", function (d) {
        return "stroke:" + d.colour + ";stroke-width: 2px;";
      })
      .attr("d", sblingLine);

    nodes
      .append("image")
      .attr("xlink:href", (d) => {
        return d.data.icon;
      })
      .attr("x", (d) => {
        return d.x * xValue - 40;
      })
      .attr("y", (d) => {
        return d.y - 40 - yValue;
      })
      .attr("width", 80)
      .attr("height", 80)
      .attr("display", (d) => {
        if (d.data.hidden) {
          return "none";
        } else {
          return "";
        }
      });

    // Add white box on line
    nodes
      .append("rect")
      .attr("x", (d) => {
        return d.x * xValue - 2;
      })
      .attr("y", (d) => {
        return d.y + 34 - yValue;
      })
      .attr("width", 4)
      .attr("height", 40)
      .style("fill", "#fff")
      .attr("display", (d) => {
        if (d.data.hidden) {
          return "none";
        } else {
          return "";
        }
      });

    nodes
      .append("text")
      .text((d) => {
        let age = d.data.age != null ? " (" + d.data.age + ")" : "";
        let name = d.data.name != null ? d.data.name : "";
        return name + "" + age;
      })
      .attr("x", (d) => {
        return d.x * xValue - 0;
      })
      .attr("y", (d) => {
        return d.y + 66 - yValue;
      })
      .attr("display", (d) => {
        if (d.data.hidden) {
          return "none";
        } else {
          return "";
        }
      })
      .style("text-anchor", "middle")
      .style("font-size", "1.5rem")
      .call(this.wrap, 110);

    function sblingLine(d, i) {

      const yValue = 120;
      var allNodes = flatten(root);


      var start = allNodes.filter(function (v) {

        if (d.source.id == v.data.id) {
          return true;
        } else {
          return false;
        }
      });

      //end point
      var end = allNodes.filter(function (v) {
        if (d.target.id == v.data.id) {
          return true;
        } else {
          return false;
        }
      });

      //define teh start coordinate and end co-ordinate

      if (start.length > 0 && end.length > 0) {
        var linedata = [
          {
            x: start[0].x,
            y: start[0].y,
          },
          {
            x: end[0].x,
            y: end[0].y,
          },
        ];

        var fun = d3
          .line()
          .x(function (d) {
            return d.x * xValue;
          })
          .y(function (d) {
            return d.y - yValue;
          })
          .curve(d3.curveNatural);
        return fun(linedata);
      } else {
        return [];
      }
    }

    function flatten(r) {
      var n = [],
        i = 0;

      function recurse(node) {
        if (node.children) node.children.forEach(recurse);
        if (!node.id) node.id = ++i;
        n.push(node);
      }
      recurse(r);
      return n;
    }
    this.openDocumentImage();
  }

  private elbow(d, i) {
    const xValue = 3.1;
    const yValue = 120;

    if (d.target.data.no_parent) {
      return "M0,0L0,0";
    }

    var diff = d.source.y - d.target.y;
    //0.40 defines the point from where you need the line to break out change is as per your choice.
    var ny = d.target.y + diff * 0.4;

    var linedata = [
      {
        x: d.target.x,
        y: d.target.y,
      },
      {
        x: d.target.x,
        y: ny,
      },
      {
        x: d.source.x,
        y: d.source.y,
      },
    ];

    var fun = d3
      .line()
      .x(function (d) {
        return d.x * xValue;
      })
      .y(function (d) {
        return d.y - yValue;
      })
      .curve(d3.curveStepAfter);

    return fun(linedata);
  }

  wrap(text, width) {
    text.each(function () {
      var text = d3.select(this);
      var words = text.text().split(/\s+/).reverse();
      var lineHeight = 22;
      var y = parseFloat(text.attr('y'));
      var x = text.attr('x');
      var anchor = text.attr('text-anchor');
      var tspan = text.text(null).append('tspan').attr('x', x).attr('y', y).attr('text-anchor', anchor);
      var lineNumber = 0;
      var line = [];
      var word = words.pop();
      let arrayA = []

      while (word) {
        line.push(word);
        tspan.text(line.join(' '));
        if (tspan.node().getComputedTextLength() > 105) {
          lineNumber += 1;
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text.append('tspan').attr('x', x).attr('y', y + lineNumber * lineHeight).attr('anchor', anchor).text(word).attr('lengthAdjust', "spacingAndGlyphs");
        }
        word = words.pop();
      }

    });
  }

  openDocumentImage() {
    var width = 875,
      height = 515;
    var circleSizeMax = 15;
    var rMax = Math.min(width, height) / 2 - circleSizeMax;

    var radius = d3.scaleLinear().range([0, rMax]);
    var angle = d3.scaleLinear().range([0, 2 * Math.PI]);
    var size = d3.scaleLinear().range([0, circleSizeMax]);
    var color = d3
      .scaleOrdinal()
      .range(["#fcfb3c", "#fcf900", "#ff825a", "#ffd2cb", "#71d362", "#ffd16f", "#ff3d5d", "#ff7218", "#04b3f3", "#bce5ac", "#6e0215", "#69D2E7", "#A7DBDB", "#E0E4CC", "#F38630", "#E94C6F", "#542733", "#5A6A62", "#C6D5CD", "#DB3340", "#E8B71A", "#F7EAC8", "#1FDA9A", "#588C73", "#F2E394", "#F2AE72", "#D96459", "#D0C91F", "#85C4B9", "#008BBA", "#DF514C", "#00C8F8", "#59C4C5", "#FFC33C", "#FBE2B4", "#5E412F", "#FCEBB6", "#78C0A8", "#F07818", "#DE4D4E", "#DA4624", "#DE593A", "#FFD041", "#B1EB00", "#53BBF4", "#FF85CB", "#FF432E", "#354458", "#3A9AD9", "#29ABA4", "#E9E0D6", "#4298B5", "#ADC4CC", "#92B06A", "#E19D29", "#BCCF02", "#5BB12F", "#73C5E1", "#9B539C", "#FFA200", "#00A03E", "#24A8AC", "#0087CB", "#260126", "#59323C", "#F2EEB3", "#BFAF80", "#BFF073", "#0DC9F7", "#7F7F7F", "#F05B47", "#3B3A35", "#20457C", "#5E3448", "#FB6648", "#E45F56", "#A3D39C", "#7ACCC8", "#4AAAA5", "#DC2742", "#AFA577", "#ABA918", "#8BAD39", "#F2671F", "#C91B26", "#9C0F5F", "#60047A", "#0F5959", "#17A697", "#638CA6", "#8FD4D9", "#83AA30", "#1499D3", "#4D6684", "#3D3D3D", "#333333", "#424242", "#00CCD6", "#EFEFEF", "#CCC51C", "#FFE600", "#F05A28", "#B9006E", "#F17D80", "#737495", "#68A8AD", "#C4D4AF",
      ]);
    var x = function (d) {
      return radius(d.r) * Math.cos(angle(d.angle));
    };
    var y = function (d) {
      return radius(d.r) * Math.sin(angle(d.angle));
    };

    var wrapper = document.getElementById("svg-container");
    if (wrapper) {
      var svg = wrapper.querySelector("svg");
      if ((window.navigator.userAgent.indexOf('Firefox') > -1)) {
        width = wrapper.offsetWidth;
        height = wrapper.offsetHeight;
        svg.setAttribute("width", (width).toString() + 'px');
        svg.setAttribute("height", (height).toString() + 'px');
      }

      var wrapper = document.getElementById("svg-container");
      var svg = wrapper.querySelector("svg");
      this.containerHeight = ((svg.getBBox().height) - 200) + 'px';

      if (this.spouse != undefined) {
        d3.select("svg").attr("viewBox", "0 0 " + (svg.getBBox().width + 100) + " " + (svg.viewBox.baseVal.height))
      }
      d3.select("svg g").attr("transform", "translate(" + 10 + "," + 25 + ")");
      }

      var svgString = getSVGString(svg);
      svgString = svgString.replace(
        "../assets/icons/boy.svg",
        "http://localhost:4202//assets/icons/women.svg"
      );

      if ((window.navigator.userAgent.indexOf('Firefox') > -1)) {
        svgString2Image(svgString, width , height, "png", save); // passes Blob and filesize String to the callback
      } else {
        svgString2Image(svgString, 2 * width, 2 * height, "png", save); // passes Blob and filesize String to the callback
      }

      function save(dataBlob, filesize) {
        saveAs(dataBlob, "D3 vis exported to PNG.png"); // FileSaver.js function
      }

      function getSVGString(svgNode) {
        svgNode.setAttribute("xlink", "http://www.w3.org/1999/xlink");
        var cssStyleText = getCSSStyles(svgNode);
        appendCSS(cssStyleText, svgNode);
  
        var serializer = new XMLSerializer();
        var svgString = serializer.serializeToString(svgNode);
        svgString = svgString.replace(/(\w+)?:?xlink=/g, "xmlns:xlink="); // Fix root xlink without namespace
        svgString = svgString.replace(/NS\d+:href/g, "xlink:href"); // Safari NS namespace fix
  
        return svgString;
  
        function getCSSStyles(parentElement) {
          var selectorTextArr = [];
  
          // Add Parent element Id and Classes to the list
          selectorTextArr.push("#" + parentElement.id);
         
  
          // Add Children element Ids and Classes to the list
          var nodes = parentElement.getElementsByTagName("*");
      
          // Extract CSS Rules
          var extractedCSSText = "";
        
          return extractedCSSText;
  
          function contains(str, arr) {
            return arr.indexOf(str) === -1 ? false : true;
          }
        }

        function appendCSS(cssText, element) {
          var styleElement = document.createElement("style");
          styleElement.setAttribute("type", "text/css");
          styleElement.innerHTML = cssText;
          var refNode = element.hasChildNodes() ? element.children[0] : null;
          element.insertBefore(styleElement, refNode);
        }
      }
  
      function svgString2Image(svgString, width, height, format, callback) {
        var format = format ? format : "png";
        var imgsrc =
          "data:image/svg+xml;charset=utf8;base64," +
          btoa(unescape(encodeURIComponent(svgString))); // Convert SVG string to data URL

        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");
  
        canvas.width = width*3;
        canvas.height = height*3;
  
        var image = new Image();
        image.onload = function () {
          context.clearRect(0, 0, width, height);

          if ((window.navigator.userAgent.indexOf('Firefox') > -1)) {
            context.drawImage(image, 0, 0, width, height, canvas.width/2 - image.width/2, canvas.height/2 - image.height/2,  width, height); // <-- Scale up graph
          } else {
            context.drawImage(image, 80, 0, width*4, height*4, 0, 0,  width*4 + 180, height*4); // <-- Scale up graph
          }
          var imgsrc = canvas.toDataURL("image/png", 1.0);

          localStorage.removeItem("imageURL");
          localStorage.setItem("imageURL", imgsrc);
  
          canvas.toBlob(function (blob) {
            var filesize = Math.round(blob.size) + " KB";
          });
        };
  
        image.src = imgsrc;
      }
  }

  // -- Toggle graph - FULL SCREEN -- //
  public toggleScreen() {
    d3.selectAll("svg").remove();
    this.isFullScreen = !this.isFullScreen;

    if (this.isFullScreen) {
      this.viewBoxVal = 3;
      this.transDVal = 1.6;
      this.document.body.classList.add("full-screen");
    } else {
      this.viewBoxVal = 2;
      this.transDVal = 7.3;
      this.document.body.classList.remove("full-screen");
    }
    this.createSVG(this.viewBoxVal, this.transDVal); // Re create svg
    this.distributionTree();
  }

}
