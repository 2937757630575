<app-header></app-header>
<app-sidebar></app-sidebar>


<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1">
      <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
        <h3 class="content-header-title mb-0 d-inline-block">AUDIENCE</h3>
        <div class="row breadcrumbs-top d-inline-block">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/dashboard">Home</a> </li>
              <li class="breadcrumb-item"><a href="/audience">Audience</a> </li>
              <li class="breadcrumb-item active" *ngIf="isLoaded2" data-toggle="tooltip"
                title="{{ audienceData.name }}">{{ audienceData.name | limitTo : 50 }}</li>
            </ol>
          </div>
        </div>
      </div>

    </div>

    <div class="content-body">
      <div class="row">
        <div id="recent-sales" class="col-12 col-md-4">
          <div class="card" *ngIf="isLoaded2">
            <div class="card-header ">
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="card-title">About Audience</h4>
                <a class="edit-btn" (click)="openEditModal(audienceData)"><i class="la la-pencil"></i></a>
              </div>

              <h3 class="audience-type mt-1">{{ audienceData.name }}</h3>
              <p class="mt-1">{{ audienceData.description }}</p>
              <h1 class="mt-1">{{audienceCount}}<span class="customer">Clients</span></h1>
            </div>

            <div [ngClass]="[!audienceData.isEnabled ? 'card-header-client-disabled' : 'card-header-client-enabled']"
              class="d-flex justify-content-between align-items-center px-2 py-1">
              <p class="mb-0 audience">
                <ng-container *ngIf="audienceData.isEnabled">
                  AUDIENCE ENABLED
                </ng-container>
                <ng-container *ngIf="!audienceData.isEnabled">
                  AUDIENCE DISABLED
                </ng-container>
              </p>
              <label class="switch">
                <!-- <input type="checkbox"> -->
                <!-- <span class="slider round"></span> -->
                <i class="la" (click)="openConfirmationDialog(template103,audienceData.isEnabled)"
                  [ngClass]="[audienceData.isEnabled ? 'la-toggle-on abouticonon' : 'la-toggle-off abouticonoff']"></i>
              </label>
            </div>
          </div>
        </div>

        <div id="recent-sales" class="col-12 col-md-8">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <h4 class="card-title">Clients in this audience</h4>
              <div class="">
                <button class="btn appointment_btn btn-sm border_all_radius mr-1" data-toggle="modal"
                  (click)="importClients()">Import</button>
                <button class="btn appointment_btn btn-sm border_all_radius mr-1" (click)="exportCSV()" data-toggle="modal">Export</button>
                <button class="btn appointment_btn btn-sm border_all_radius" data-toggle="modal"
                  (click)="addPeople()">Add
                  Clients to this Audience</button>
              </div>

            </div>

            <div class="card-content" *ngIf="isLoaded">
              <!-- <div class="table-responsive scroll-tbl_newsfeed-"> -->
              <!-- <table id="recent-orders" class="table table-hover table-xl mb-0"> -->
              <table datatable [dtOptions]="dtOptions"
                class="table table-striped table-bordered zero-configuration t_dt">

                <thead>
                  <tr>
                    <th class="border-top-0" style="width: 20%;">Name</th>
                    <th class="border-top-0" style="width: 15%;">Client code</th>
                    <th class="border-top-0" style="width: 15%;">Xeppo code</th>
                    <th class="border-top-0"></th>
                  </tr>
                </thead>
                <tbody *ngIf="audienceMemberList && audienceMemberList.length > 0">
                  <tr *ngFor="let audienceMember of audienceMemberList; let i = index">
                    <td class="text-truncate small-col">
                      {{audienceMember.firstName}} {{audienceMember.lastName}}
                    </td>
                    <td class="text-truncate small-col">{{ audienceMember.clientCode}}</td>
                    <td class="text-truncate small-col">{{ audienceMember.xeppoCode}}</td>
                    <td class="sa_btns">
                      <!-- <div class="btn-group"> -->
                      <button type="button" class="btn btn-icon mr-1 btn_trash"
                        (click)="openDeleteDialog(template,audienceMember.id)"><i class="la la-trash"></i></button>
                      <!-- </div> -->
                    </td>
                  </tr>
                </tbody>
                <!-- <tbody *ngIf="audienceMemberList && audienceMemberList.length == 0" style=" width: 100% !important; text-align: center;">
                  <tr>
                    <td colspan="3">No records to show.</td>
                    <td></td>
                  </tr>
                </tbody> -->
                <!-- <div *ngIf="audienceMemberList && audienceMemberList.length == 0" class="d-flex justify-content-center">
                  <p class="my-2">No records to show.</p>
                </div> -->
                <!-- <tbody *ngIf="audience.length == 0" style=" width: 100% !important; text-align: center;">
                                                <tr>
                                                    <td colspan="3" >No records to show.</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>  -->
              </table>

              <!-- empty state -->
              <!-- <div class="d-flex flex-column align-items-center justify-content-center my-2">
                <img src="../../assets/img/empty_state_img.png" class="w-25 empty-image">
                <p class="mt-2">No records to show</p>
              </div> -->

              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-body text-center">

    <img src="assets/img/warning.png" alt="" class="alert_img">
    <p>{{message}}</p>

    <div class="form-actions center mb-1">
      <button type="button" class="btn alert_no_b mr-1" (click)="decline()">
        No
      </button>
      <button type="button" class="btn alert_yes_b" (click)="confirm()">
        Yes
      </button>
    </div>
  </div>
</ng-template>

<ng-template #template103>
  <div class="modal-body text-center">
    <img src="assets/img/warning.png" alt="" class="alert_img">
    <p>{{message}}</p>


    <div class="form-actions center mb-1">
      <button type="button" class="btn alert_no_b mr-1" (click)="decline(isActive)">
        No
      </button>
      <button type="button" class="btn alert_yes_b" (click)="confirmAudienceStatus(isActive)">
        Yes
      </button>
    </div>
  </div>
</ng-template>

<app-footer></app-footer>