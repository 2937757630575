import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { UnVerifiedUsersResponse2} from '../../shared/models/response.model';
import { UserVerifyService } from '../../user-verify/user-verify.service';
import { ClientNewAddModel } from "../../shared/models/client.model";

@Component({
  selector: 'app-add-user-name',
  templateUrl: './add-user-name.component.html',
  styleUrls: ['./add-user-name.component.css']
})
export class AddUserNameComponent implements OnInit {

  Isvalid:boolean; 
  data:any;
  errors;
  userId;
  userName:any;
  event: EventEmitter<any> = new EventEmitter();
  clientModel: ClientNewAddModel;
  addUserNameForm: FormGroup;

  constructor(
    public modalRef: BsModalRef, 
    private ngxService: NgxUiLoaderService, 
    private toastr: ToastrService, 
    private UserVerifyService: UserVerifyService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.Isvalid = true; 
    this.clientModel = new ClientNewAddModel();

    if(this.data.client !=null){
      Object.assign(this.clientModel, this.data.client);
    }

    this.addUserNameForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.minLength(3)]],      
    });
  }

  onSubmit(form:NgForm){
    if(form.invalid) {

      if(form.pristine){
      }
      this.Isvalid = false;
    }
    else{
      if(this.data.client !=null){
        this.userId = this.data.client.id;
        this.userName = form.value.userName;
        
        this.ngxService.start();
        this.UserVerifyService.updateUserName(this.userId, this.userName).subscribe((data:UnVerifiedUsersResponse2)=>{ 
        
          if(data.error && !data.success){
            this.ngxService.stop();
            if(data.error.errorCode == 2007){
              this.toastr.error('User name already assigned.');
            }
            else {
              this.toastr.error('Error on saving user name.');
            }
          }
          else{
              this.toastr.success('User name saved successfully.');
              this.event.emit('OK');
              this.modalRef.hide();    
              //this.unVerifiedUsersAddModel.primaryMobile = this.mobileNumber;              
          } 
        },(err: HttpErrorResponse)=>{   
          this.ngxService.stop();
          this.toastr.error('Error on saving user name.');
        });
      }
      else{
        this.ngxService.stop();
        this.toastr.error('Error on saving user name.');
      }
    }
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

}
