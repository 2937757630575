
    <div class="modal-header border-bottom-0">
        <h5 class="modal-title factfinderheader" id="exampleModalLabel">{{ title }} Income Details</h5>
        <button type="button" class="close" (click)="modalRehide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
  
      <form [formGroup]="addIncomeForm" novalidate class="form " (ngSubmit)="onSubmit()" >
      <div class="modal-body factfinderbody">
        
        <div class="form-group row factfinderpopup">
          <label for="typeGroup" class="labelwidth">Type</label> <!-- Type Group -->
          <div class="inputwidth"> <span>:</span>
            <!-- <input type="text" class="form-control form-control-sm" placeholder="Mobile Phone"> -->
            <select formControlName="typeGroup" required [(ngModel)]="incomeAddModel.typeGroup" class="form-control form-control-sm" (ngModelChange)="onChange($event)" placeholder="Type" >
                <option value="000">Please select</option>
                
                <ng-container *ngFor="let incomeCashflow of incomeCashflowList; let i = index">
                    <option value="{{incomeCashflow.key}}">{{incomeCashflow.value}}</option>
                </ng-container>
                
              </select>
                <div class="form-group">
                  <div *ngIf="submitted && f.typeGroup.errors"   class="form_alert">
                          <span  class="ft-alert-circle"></span>
                      <div *ngIf="f.typeGroup.errors.required">Type is required.</div>
                </div>
                </div>
          </div>
        </div>

          <div class="form-group row factfinderpopup">
              <label for="type" class="labelwidth">Sub Type</label>
              <div class="inputwidth"> <span>:</span>
                <!-- <input type="text" class="form-control form-control-sm" placeholder="Mobile Phone"> -->
                <select formControlName="type" required [(ngModel)]="incomeAddModel.type" class="form-control form-control-sm" placeholder="Sub Type" >
                    <option value="000">Please select</option>
                    
                    <ng-container *ngFor="let incomeCashflowSubtype of incomeCashflowSubtypeList; let i = index">
                        <option value="{{incomeCashflowSubtype.key}}">{{incomeCashflowSubtype.value}}</option>
                    </ng-container>
                    
                  </select>
                    <div class="form-group">
                      <div *ngIf="submitted && f.type.errors"   class="form_alert">
                              <span  class="ft-alert-circle"></span>
                          <div *ngIf="f.type.errors.required">Sub type is required.</div>
                    </div>
                    </div>
              </div>
            </div>

          <div class="form-group row factfinderpopup">
              <label for="description" class="labelwidth">Description</label>
              <div class="inputwidth"> <span>:</span> 
                <input formControlName="description" required [(ngModel)]="incomeAddModel.description" type="text" class="form-control form-control-sm" placeholder="Description">
                <div class="form-group">
                  <div *ngIf="submitted && f.description.errors"   class="form_alert">
                          <span  class="ft-alert-circle"></span>
                      <div *ngIf="f.description.errors.required">Description is required.</div>
                  </div>
                </div>  
              </div>
            </div>

          <div class="form-group row factfinderpopup">
              <label for="ownerType" class="labelwidth">Owner Type</label>
              <div class="inputwidth"> <span>:</span> 
              <select formControlName="ownerType" required [(ngModel)]="incomeAddModel.ownerType" class="form-control form-control-sm" placeholder="Owner Type" >
                  <option value="000">Please select</option>
                <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
                <ng-container *ngFor="let incomeExpenseCashflowOwner of incomeExpenseCashflowOwnerList; let i = index">
                    <option value="{{incomeExpenseCashflowOwner.key}}">{{incomeExpenseCashflowOwner.value}}</option>
                </ng-container>
                
              </select>

              <div class="form-group">
                <div *ngIf="submitted && f.ownerType.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.ownerType.errors.required">Owner type is required.</div>
                </div>
              </div> 
             
              </div>
            </div>

        <div class="form-group row factfinderpopup">
              <label for="frequency" class="labelwidth">Frequency</label>
              <div class="inputwidth"> <span>:</span> 
                <select formControlName="frequency" required [(ngModel)]="incomeAddModel.frequency" class="form-control form-control-sm" placeholder="Frequency" >
                    <option value="-99">Please select</option>
                <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
                <ng-container *ngFor="let incomeExpenseCashflowFrequency of incomeExpenseCashflowFrequencyList; let i = index">
                    <option value="{{incomeExpenseCashflowFrequency.key}}">{{incomeExpenseCashflowFrequency.value}}</option>
                </ng-container>
                
              </select>
              
              <div class="form-group">
                <div *ngIf="submitted && f.frequency.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.frequency.errors.required">Frequency is required.</div>
                </div>
              </div> 
             
              </div>
            </div>
        
        <div class="form-group row factfinderpopup">
          <label for="comments" class="labelwidth">Amount Value</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            <div class="input-group prepend_group">
                <span class="input-group-prepend" style="top: 0px;">
                    <div class="input-group-text border-right-0 prepend_new_class"><span class="prepend_span"> $ </span></div>
                </span>
                <input formControlName="amountValue" required [(ngModel)]="incomeAddModel.amountValue" type="number" pattern="^[1-9]\d*$" class="form-control form-control-sm" style="width: unset;" placeholder="Amount Value">
            </div>
            <!-- <input formControlName="amountValue" required [(ngModel)]="incomeAddModel.amountValue" type="text" class="form-control " placeholder="Amount Value"> -->
            <div class="form-group">
              <div *ngIf="submitted && f.amountValue.errors"   class="form_alert aud_errormsg">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.amountValue.errors.required">Amount value value is required.</div>
                  <div *ngIf="f.amountValue.errors.pattern">Only numbers.</div>
              </div>
            </div>  
          </div>
        </div>

      </div>
      <div class="modal-footer border-top-0 pt-0">
        <button class="btn btn-primary factfinderbtn">Save</button>
      </div>
  </form>
      