import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {}
  
  public transform(value: any): any {
     return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
