<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1">
      <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
        <h3 class="content-header-title mb-0 d-inline-block">My Profile</h3>
        <div class="row breadcrumbs-top d-inline-block">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
              <li class="breadcrumb-item active">My Profile</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
        <section id="">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Profile Details</h4>
                </div>
                <div class="card-content collapse show">
                  <div class="card-body1 card-dashboard">
                    <div class="avatar-upload">
                        <div class="avatar-edit">
                            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" />
                            <label for="imageUpload"><i class="la la-pencil ep_icon"></i></label>
                        </div>
                        <div class="avatar-preview">
                            <div id="imagePreview" style="background-image: url('../../assets/img/user.jpg');">
                            </div>
                        </div>
                    </div>
                    <hr class="ep_hr">
                    <div class="card-body editp_body">                     
                      <h5 class="pb-2 font-weight-bold">General Information</h5>
                      <div class="row">
                        <div class="col-md-6">
                                <h4 class="card-text news_view_i">First Name</h4>
                                <h5 class="card-link news_view_detail">Lorem, ipsum.</h5>
                        </div>
                        <div class="col-md-6">
                            <h4 class="card-text news_view_i">Last Name</h4>
                            <h5 class="card-link news_view_detail">Yes</h5>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-md-6">
                          <h4 class="card-text news_view_i">Preferred Name</h4>
                          <h5 class="card-link news_view_detail">Lorem, ipsum.</h5>
                        </div>
                        <div class="col-md-6">
                          <h4 class="card-text news_view_i">Designation</h4>
                          <h5 class="card-link news_view_detail">Yes</h5>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col-md-6">
                          <h4 class="card-text news_view_i">Primary Mobile</h4>
                          <h5 class="card-link news_view_detail">Lorem, ipsum.</h5>
                        </div>
                        <div class="col-md-6">
                          <h4 class="card-text news_view_i">Contact Number</h4>
                          <h5 class="card-link news_view_detail">Yes</h5>
                        </div>
                      </div>
                    </div>
                    <hr class="ep_hr">
                    <div class="card-body editp_body">
                      <h5 class="pb-2 font-weight-bold">Account Information</h5>
                      <div class="row">
                        <div class="col-md-6">
                          <h4 class="card-text news_view_i">Email</h4>
                          <h5 class="card-link news_view_detail">Lorem, ipsum.</h5>
                        </div>
                        <div class="col-md-6">
                          <h4 class="card-text news_view_i">User Name</h4>
                          <h5 class="card-link news_view_detail">Yes</h5>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer a-footer">
                        <button type="submit" class="btn popup_save_b" data-toggle="modal" data-target="#edit_profile">Edit Details</button>
                        <div class="modal fade text-left" id="edit_profile" tabindex="-1" role="dialog" aria-labelledby="myModalLabel1" aria-hidden="true">
                            <div class="modal-dialog modal-lg" role="document">
                                <div class="modal-content">
                                    <div class="modal-header a_modal">
                                        <h4 class="modal-title" id="myModalLabel1">Edit Profile</h4>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                                    </div>
                                    <div class="modal-body dallecort_modal-body">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-body">
                                                    <span class="add_agent_g">General Information</span>
                                                    <div class="row add_agent_gr" style="margin-top: 5px; margin-bottom: 5px;">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="firstName" class="c_n_fs">First Name *</label>
                                                                <input autocomplete="off" maxlength="75" id="firstName" required minlength="2" type="text" class="form-control" name="firstName" placeholder="First Name">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="lastName" class="c_n_fs">Last Name *</label>
                                                                <input autocomplete="off" maxlength="75" type="text" required minlength="2" class="form-control" id="lastName" name="lastName" placeholder="Last Name">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="prefferedName" class="c_n_fs">Preferred Name *</label>
                                                                <input autocomplete="off" maxlength="75" type="text" required class="form-control" minlength="2" id="prefferedName" name="prefferedName" placeholder="Preferred Name">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="designation" class="c_n_fs">Designation *</label>
                                                                <input autocomplete="off" maxlength="75" type="text" required class="form-control" minlength="4" id="designation" name="designation" placeholder="Designation">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="primaryMobile" class="c_n_fs">Primary mobile *</label>
                                                                <input autocomplete="off" type="text" pattern="^(\+)?\d{11}$" required class="form-control" id="primaryMobile" name="primaryMobile" placeholder="Primary mobile">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="contactNumber" class="c_n_fs">Contact Number *</label>
                                                                <input autocomplete="off" type="text" pattern="^(\+)?\d{11}$" required class="form-control" id="contactNumber" name="contactNumber" placeholder="Contact Number">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span class="add_agent_g">Account Information</span>
                                                    <div class="row add_agent_ga" style="margin-top: 5px;">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="projectinput1" class="c_n_fs">Email *</label>
                                                                <input autocomplete="off" type="email" autocomplete="none" required class="form-control" id="email" name="email" placeholder="Email">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="userName" class="c_n_fs">User Name *</label>
                                                                <input autocomplete="off" maxlength="75" type="text" autocomplete="none" required minlength="4" class="form-control" id="userName" name="userName" placeholder="User Name">
                                                            </div>
                                                        </div>

                                                        
                                                        <div class="col-md-6">
                                                            <div class="form-group" style="padding-top: 4px;">
                                                                <label for="status" class="c_n_fs"></label>
                                                                <label class="container"> 
                                                                  <input type="checkbox" class="check_mt"/>Change Password
                                                                  <span class="checkmark" style="top: 4px; left: 15px;"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="password" class="c_n_fs">Password *</label>
                                                                <input autocomplete="off" type="password" autocomplete="none" minlength="8" required class="form-control" id="password" name="password" placeholder="Password">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="password_confirmation" class="c_n_fs">Password confirmation *</label>
                                                                <input autocomplete="off" type="password" autocomplete="none" autocomplete="nope" required class="form-control" id="password_confirmation" name="password_confirmation" placeholder="Password confirmation">
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer a-footer">
                                        <button type="submit" class="btn popup_save_b">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>        
    </div>
  </div>
<app-footer></app-footer>