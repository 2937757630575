<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1">
      <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
        <h3 class="content-header-title mb-0 d-inline-block">Email Setup</h3>
        <div class="row breadcrumbs-top d-inline-block">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a [routerLink]="['/dashboard']" href="#">Home</a>
              </li>
              <li class="breadcrumb-item"><a [routerLink]="['/department-list']" href="#">Email Setup (Department List)</a>
              </li>
              <li class="breadcrumb-item active">{{ department_name }}
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 resc_btn">
        <div class="btn-group float-md-right">
            <button class="btn btn-info appointment_btn btn-sm border_all_radius" *ngIf="isLoaded" data-toggle="modal" (click)="openViewdModal(template)" data-target="#create_new" type="button" >Create New</button>
            <!-- data-toggle="modal"  data-target="#create_new" -->
        </div>
        <!-- <div class="modal fade text-left" id="create_new" tabindex="-1" role="dialog" aria-labelledby="myModalLabel1" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    
                </div>
            </div>
        </div> -->
    </div>
    </div>
    <div class="content-body">
      <!-- Zero configuration table -->
      <section id="">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Users in <span>{{ department_name }}</span></h4>
              </div>
              <div class="card-content collapse show">
                <div *ngIf="isLoaded" class="card-body1 card-dashboard">
                  <ng-container *ngIf="departmentUserList && departmentUserList.length >0 ">
                      <table datatable [dtOptions]="dtOptions"
                        class="t_dt table table-striped table-bordered table-sm row-border hover"
                        style="margin-top: 20px !important;">
                        <thead>
                          <tr>
                            <!-- <th scope="col" class="" width="2%">#</th> -->
                            <th>Name</th>
                            <th>To</th>
                            <th>CC</th>
                            <th>BCC</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let departmentUser of departmentUserList; let i = index">
                            <td>{{ departmentUser.user.firstName }} {{ departmentUser.user.lastName }}</td>
                            <td><label class="radio"><input type="radio" [checked]="departmentUser.emailReferenceType === 1" name="emailReferenceType_{{ departmentUser.id }}" (click)="openChangeDialog(departmentUser.id, 1)"><span class="checkround"></span></label></td>
                            <td><label class="radio"><input type="radio" [checked]="departmentUser.emailReferenceType === 2" name="emailReferenceType_{{ departmentUser.id }}" (click)="openChangeDialog(departmentUser.id, 2)"><span class="checkround"></span></label> </td>
                            <td><label class="radio"><input type="radio" [checked]="departmentUser.emailReferenceType === 3" name="emailReferenceType_{{ departmentUser.id }}" (click)="openChangeDialog(departmentUser.id, 3)"><span class="checkround"></span></label></td>
                            <td class="sa_btns">
                              <button type="button" class="btn btn-icon btn_trash" (click)="openDeleteDialog(template2,departmentUser.id)"><i class="la la-trash"></i></button>
                            </td>
                          </tr>
                          

                        </tbody>

                      </table>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--/ Zero configuration table -->
    </div>
  </div>
</div>


<app-footer></app-footer>

<ng-template #template>
    
    <div class="modal-header a_modal">
        <h4 class="modal-title" id="myModalLabel1">New User</h4>
        <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
    </div>
    <form class="form" #f="ngForm" novalidate class="form" (ngSubmit)="onSubmit(f)" autocomplete="false">
        <div class="modal-body dallecort_modal-body">
            <div class="row">
                <div class="col-md-12">
                    
                        <div class="form-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="email" class="c_n_fs">User Name</label>
                                        <!-- <input type="text" id="email" class="form-control" placeholder="Select User Name" name="email">  -->
                                        <select required id="userId" name="userId" class="form-control " data-toggle="tooltip" data-trigger="hover" placeholder="Please select" data-placement="top" data-title="Priority" data-original-title="" title="" [(ngModel)]="emailSetup.userId" #userId = "ngModel">
                                          <option  [value]="0">Please select</option>   
                                          <ng-container *ngFor="let agent of agentFilterdList" >
                                                <option *ngIf="agent" [value]="agent.id">                                      
                                                    {{agent.firstName}} {{agent.lastName}}
                                                </option> 
                                            </ng-container>                                 
                                        </select>
                                        <div class="form-group">
                                          <div *ngIf="userId.invalid && (userId.dirty || userId.touched || f.submitted)"
                                          class="form_alert">
                                          <span  class="ft-alert-circle"></span>
                                            <div *ngIf="userId.errors.required">
                                              User name is required.
                                            </div>                                                  
                                          </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="emailType" class="c_n_fs">Email type</label>
                                        <select required id="emailReferenceType" name="emailReferenceType" class="form-control " data-toggle="tooltip" data-trigger="hover" placeholder="Please select" data-placement="top" data-title="Priority" data-original-title="" title="" [(ngModel)]="emailSetup.emailReferenceType" #emailReferenceType = "ngModel">
                                            <option  [value]="0">Please select</option>   
                                            <option  [value]="1">To</option> 
                                            <option  [value]="2">CC</option> 
                                            <option  [value]="3">Bcc</option> 
                                        </select>
                                        <div class="form-group">
                                          <div *ngIf="emailReferenceType.invalid && (emailReferenceType.dirty || emailReferenceType.touched || f.submitted)"
                                          class="form_alert">
                                          <span  class="ft-alert-circle"></span>
                                            <div *ngIf="emailReferenceType.errors.required">
                                                Email reference type is required.
                                            </div>                                                  
                                          </div>
                                        </div>
                                    </div>
                                </div>
                              
                            </div>
                        </div>
                  
                </div>
            </div>
        </div>
        <div class="modal-footer a-footer">
            <button type="submit" class="btn btn-outline-primary btn-sm a_btn c_btn_save new_btn_width">Save</button>
        </div>       
    </form>
</ng-template>

<ng-template #template2>
    <div class="modal-body text-center" >

        <img  src="assets/img/warning.png" alt="" class="alert_img">
        <p>{{message}}</p>
          
        <div class="form-actions center mb-1">
            <button type="button" class="btn alert_no_b mr-1" (click)="decline()"> 
                No
            </button>
            <button type="button" class="btn alert_yes_b" (click)="confirm()" >
                 Yes
            </button>
        </div>
    </div>
</ng-template>
