import { Component, OnInit, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CompaniesService } from '../companies.service';
import { CompanyAddModel } from '../../shared/models/company.model';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { Company_Response2 } from '../../shared/models/response.model';
import { Utility } from '../../utility/utility';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PermissionGroupsService } from 'src/app/permission-groups/permission-groups.service';
import { RoleAddModel } from 'src/app/shared/models/role.model';


@Component({
  selector: 'app-edit-companies',
  templateUrl: './edit-companies.component.html',
  styleUrls: ['./edit-companies.component.css']
})
export class EditCompaniesComponent implements OnInit {

  companyModel: CompanyAddModel;
  Isvalid: boolean;
  data;
  event: EventEmitter<any> = new EventEmitter();
  errors:any;

  constructor(private companiesService: CompaniesService ,public modalRef: BsModalRef, private ngxService: NgxUiLoaderService, private toastr: ToastrService, private router : Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit() {
    this.Isvalid = true;
    this.companyModel = new CompanyAddModel();

    if(this.data.company !=null){
      //Object.assign(this.companyModel, this.data.company);
      this.companyModel = JSON.parse(JSON.stringify(this.data.company));
    }

  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

  onSubmit(form:NgForm){
    // this.submitted = true;
    // if (this.patientAddForm.invalid) {
    //   return;
    // }
  
  if(form.invalid) {
    // console.log(form.invalid);
    // console.log(form.errors);
    if(form.pristine){
    }
    this.Isvalid = false;
  }
  
  else{
    this.ngxService.start();
    
  //  this.patientModel.clinicId = this.clinics[0].id;
  //  this.patientModel.clinicianId = JSON.parse( localStorage.getItem('CurrentUserId'));
  if(this.data.company !=null){
  //console.log("Edditre -----------");
  
    this.companiesService.companyEdit(this.data.company.id,this.companyModel).pipe(first())
    .subscribe((data:Company_Response2)=>{
      this.ngxService.stop();
      if(data.error && !data.success){      
        this.toastr.error('Error on updating company.');
      }
      else{
        this.toastr.success('Company updated successfully.');
        this.event.emit('OK');
        this.modalRef.hide();
        this.router.navigate(['companies']);
      }
  
      // this.event.emit('OK');
      // this.modalRef.hide();
      },err=>{   
        this.ngxService.stop();
        this.toastr.error('Error on updating company.');
      });
      
  
  }
  }
  } 

}

