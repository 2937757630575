import { Injectable } from '@angular/core';
import { RoleAddModel } from '../shared/models/role.model';
import { RoleResponse, PermissionResponse, RolePermissionResponse, PermissionGetResponse } from '../shared/models/response.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AppConstants } from '../utility/app-constants';
import { RolePermissionAddModel } from '../shared/models/role-permission.model';

@Injectable({
  providedIn: 'root'
})
export class PermissionGroupsService {
  authUrl: String;

  constructor(private http:HttpClient) { 
    this.authUrl = AppConstants.getAuthURL();
  }

  public handleError(error) {
    return throwError(error);
  }

  public addRole(roleAddModel:RoleAddModel){    
    return this.http.post<RoleResponse>(this.authUrl +'/Role/Create',roleAddModel).pipe(
      catchError(this.handleError)
      );    
  }

  public editRole(id:string,roleAddModel:RoleAddModel) {  
    // console.log(roleAddModel);
    const params = {
      'id': id,
      'name': roleAddModel.name,
      'description': roleAddModel.description,
      'companyId': roleAddModel.companyId
    };
      // console.log(params);

    return this.http.post<RoleResponse>(this.authUrl+'/Role/Update', params).pipe(
      catchError(this.handleError)
      );
  }

  public permissionList(){    
    return this.http.get<PermissionResponse>(this.authUrl+'/Permission/GetAll');    
  }

  public addRolePermission(rolePermissionAddModel:RolePermissionAddModel){    
    return this.http.post<RolePermissionResponse>(this.authUrl +'/RolePermission/Create',rolePermissionAddModel).pipe(
      catchError(this.handleError)
      );    
  }

  public updateRolePermission(rolePermissionAddModel:RolePermissionAddModel){    
    return this.http.post<RolePermissionResponse>(this.authUrl +'/RolePermission/Update',rolePermissionAddModel).pipe(
      catchError(this.handleError)
      );    
  }

  public roleList(){       
    return this.http.get<RoleResponse>(this.authUrl+'/Role/GetAll');   
  }

  public getPermissionByRole(id:string){
    //console.log("client service")
    //console.log(id);
    const params = new HttpParams().set('roleId',id);
    return this.http.get<PermissionGetResponse>(this.authUrl+'/Permission/GetByRoleId',{params});
  }

  public enableDisable(id:string,status:string){
    // const params = new HttpParams().set('userId', id).set('isArchived', status); 
     const params = {'roleId': id,'isArchived': status} ;
     // return this.http.post<Response>(this.baseUrl +'/FortressAdmin/SwitchAdminStatus?id='+id+'&isArchived='+status,{params}).pipe(
     //   catchError(this.handleError)
     //   );     
 
     return this.http.post<Response>(this.authUrl +'/Role/SetActiveStatus',params).pipe(
       catchError(this.handleError)
     ); 
   }

   public changePermission(roleId, permission, status, biz){
    // const params = new HttpParams().set('userId', id).set('isArchived', status); 
     const params = {'roleId': roleId,'permissionId': permission.id,'businessId':biz.id,'businessCode':biz.business_code} ;
    //  console.log(status);
     // return this.http.post<Response>(this.baseUrl +'/FortressAdmin/SwitchAdminStatus?id='+id+'&isArchived='+status,{params}).pipe(
     //   catchError(this.handleError)
     //   );     

     if ( status ) {
      return this.http.post<RolePermissionResponse>(this.authUrl +'/RolePermission/Create',params).pipe(
        catchError(this.handleError)
        );  
      }
      else {
        return this.http.post<RolePermissionResponse>(this.authUrl +'/RolePermission/Remove',params).pipe(
          catchError(this.handleError)
        ); 
      }

      
     
   }
}
