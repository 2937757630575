import { Component, OnInit, TemplateRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { AgentsService } from '../agents/agents.service';
import { AgentResponse, EmailSetupResponse, DepartmentUserEmailResponse } from '../shared/models/response.model';
import { AgentViewModel } from '../shared/models/agent.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { EmailSetupAddModel } from '../shared/models/email-setup.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgForm } from '@angular/forms';

import { EmailSetupService } from './email-setup.service';
import { Utility } from 'src/app/utility/utility';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-setup',
  templateUrl: './email-setup.component.html',
  styleUrls: ['./email-setup.component.css']
})
export class EmailSetupComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  agentList: AgentViewModel[];
  departmentUserList: EmailSetupAddModel[];
  emailSetup : EmailSetupAddModel;
  isLoaded :boolean;
  agentFilterdList:AgentViewModel[];

  department_id: string;
  department_name: string;

  Isvalid:boolean;
  data;
  errors;
  event: any;
  emailUserID: any;
  message;

  
  constructor(
    private ngxService: NgxUiLoaderService,
    private agentService: AgentsService,
    public modalRef: BsModalRef,
    private route: ActivatedRoute,

    private emailSetupService: EmailSetupService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private router : Router) { 
      this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
      };
    }

  ngOnInit() {
    this.department_id = this.route.snapshot.queryParams['department_id'];
    this.department_name = this.route.snapshot.queryParams['department_name'];

    // console.log(this.department_name);
    this.emailSetup = new EmailSetupAddModel();
    
    this.emailSetup.userId = '0';
    this.emailSetup.emailReferenceType = 0;

    this.dtOptions = {
      //pagingType: 'full_numbers',
      //ajax: this.baseUrl+'/FortressAdmins',
      pageLength: 10,
      processing: true,
      order : []
    }; 
    
    
    this.getAgentList(); 
    this.getDepartmentUserList(this.department_id);
  }

  getAgentList(){
    this.agentService.agentList().subscribe((data:AgentResponse)=>{ 
      if(data && data.isSuccess){
        this.agentList = data.data;
        this.assignCopy();
        // console.log(this.agentList);
        this.isLoaded = true;  
      }    
          
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });      
  }

  assignCopy(){
    this.agentFilterdList = Object.assign([], this.agentList);
  }

  onSubmit(form:NgForm){
    
    if(form.invalid || !this.emailSetup.userId || this.emailSetup.userId == "0" || !this.emailSetup.emailReferenceType || this.emailSetup.emailReferenceType == 0) {
  
      if(!this.emailSetup.userId || this.emailSetup.userId == "0"){
        form.controls['userId'].setErrors({'required': true});
      }
      if(!this.emailSetup.emailReferenceType || this.emailSetup.emailReferenceType){
        form.controls['emailReferenceType'].setErrors({'required': true});
      }
  
      if(form.pristine){
      }
      this.Isvalid = false;
    }

    else{
        this.emailSetup.departmentId = this.department_id;
        // console.log("Add email Setup");
        // console.log(this.emailSetup);
        
        this.ngxService.start();
        this.emailSetupService.saveDepartmentLeaveApprover(this.emailSetup)
        .subscribe((data:EmailSetupResponse)=>{
          this.ngxService.stop();
          if(data.error && !data.success){
            //return data;
            //let err = Utility.GetLocalErrorString(data.error.errorCode);
            //console.log(data.error);
            //console.log(data.error.message);
            if(data.error.message){
              this.errors = data.error.message;
              if(data.error.errorCode == 2007) {
                this.toastr.error('User already exists in the list.');
              }
            }
            else{
              this.errors = Utility.GetLocalErrorString(data.error.errorCode);
              if(data.error.errorCode == 2007) {
                this.toastr.error('User already exists in the list.');
              }
            }
            //console.log(this.errors);
            //this.event.emit(data.error.message);
          }
          else{
            this.toastr.success('User added successfully.');
            //this.event.emit('OK');
            this.router.navigate(['email-setup'], { queryParams: {department_id: this.department_id, department_name: this.department_name }});
            this.modalRef.hide();
            
          }
          
          },err=>{   
            this.ngxService.stop();
            this.toastr.error('Error on saving user.');
          });
     
    }
  }

  openViewdModal(template: TemplateRef<any>){
    //console.log(notice);
    //console.log(this.viewNotice);
    this.modalRef = this.modalService.show(template, { 
      //class: 'modal-lg', 
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    });
  }


  getDepartmentUserList(depatmentId){
    this.emailSetupService.departmentUserList(depatmentId).subscribe((data:DepartmentUserEmailResponse)=>{ 
      if(data && data.success){
        this.departmentUserList = data.response;
        this.assignCopy();
        // console.log(this.departmentUserList);
        this.isLoaded = true;  
      }    
          
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });      
  }


  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

  public openDeleteDialog(template: TemplateRef<any>,emailUserID) {

    this.emailUserID = emailUserID;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    //this.idToBeDeleted = id;

    this.message = 'Are you sure that you want to delete user?'
  }

  decline(): void {
    this.modalRef.hide();
  }

  confirm(): void {
    //this.message = 'Confirmed!';
    this.modalRef.hide();
    
    this.emailSetupService.deleteNewsFeed(this.emailUserID).subscribe(()=>{     
      
      this.router.navigate(['email-setup'], { queryParams: {department_id: this.department_id, department_name: this.department_name }});
      this.toastr.success('User deleted successfully.');
      // this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
    
    //this.delete();
  }

  public openChangeDialog(emailUserID, value) {
    // console.log(emailUserID);
    // console.log(value);
    this.emailSetupService.changeEmailUserType(emailUserID, value).subscribe(()=>{     
      
      //this.router.navigate(['email-setup'], { queryParams: {department_id: this.department_id }});
      this.toastr.success('Email type updated successfully.');
      // this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
  }

}
