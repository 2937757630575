
    <div class="modal-header border-bottom-0">
      <h5 class="modal-title factfinderheader" id="exampleModalLabel">{{ title }} Contact Details</h5>
      <button type="button" class="close" (click)="modalRehide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <form [formGroup]="addContactForm" novalidate class="form " (ngSubmit)="onSubmit()" >
    <div class="modal-body factfinderbody">
      
      <div class="form-group row factfinderpopup">
        <label for="inputPassword" class="labelwidth">Contact Type</label>
        <div class="inputwidth"> <span>:</span>
          <!-- <input type="text" class="form-control form-control-sm" placeholder="Mobile Phone"> -->
          <select formControlName="contactType" required [(ngModel)]="contactAddModel.contactType" class="form-control form-control-sm" placeholder="Contact Type" >
              <option value="0">Please select</option>
              
              <ng-container *ngFor="let contactType of contactTypeList; let i = index">
                  <option value="{{contactType.key}}">{{contactType.value}}</option>
              </ng-container>
              
            </select>
              <div class="form-group">
                <div *ngIf="submitted && f.contactType.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.contactType.errors.required">Contact type is required.</div>
              </div>
              </div>
        </div>
      </div>
      <div class="form-group row factfinderpopup">
        <label for="inputPassword" class="labelwidth">Contact value</label>
        <div class="inputwidth"> <span>:</span> 
          <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
          <input formControlName="contactValue" required [(ngModel)]="contactAddModel.contactValue" type="text" class="form-control form-control-sm" placeholder="Contact Value">
          <div class="form-group">
            <div *ngIf="submitted && f.contactValue.errors"   class="form_alert">
                    <span  class="ft-alert-circle"></span>
                <div *ngIf="f.contactValue.errors.required">Contact value is required.</div>
                <div *ngIf="f.contactValue.errors.email">Contact email type is invalid.</div>
                <div *ngIf="f.contactValue.errors.pattern">Contact number must be length 11 and type of +61445678956.</div>
            </div>
          </div>

        </div>
      </div>
      
    </div>
    <div class="modal-footer border-top-0 pt-0">
      <button class="btn btn-primary factfinderbtn">Save</button>
    </div>
</form>
    