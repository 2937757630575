import { Component, OnInit } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { EmailSetupService } from '../email-setup.service';
import { DepartmentResponsex } from 'src/app/shared/models/response.model';
import { DepartmentViewModel } from '../../shared/models/department.model';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-department-list',
  templateUrl: './department-list.component.html',
  styleUrls: ['./department-list.component.css']
})
export class DepartmentListComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  departmentList:DepartmentViewModel[];
  isLoaded: boolean;

  constructor(private emailSetupService: EmailSetupService) { }

  ngOnInit() {
    this.dtOptions = {
      //pagingType: 'full_numbers',
      //ajax: this.baseUrl+'/FortressAdmins',
      pageLength: 10,
      processing: true,
      order : []
    }; 

    this.getDepartmentList(); 
  }

  getDepartmentList(){
    this.emailSetupService.departmentList().subscribe((data:DepartmentResponsex)=>{     
      this.departmentList = data.data;
      //this.departmentsCount = this.departmentList.length;
      //this.assignCopy();
      // console.log('department list--------------------------');
      //  console.log(this.departmentList);

      this.isLoaded = true;
    
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });      
  }

}
