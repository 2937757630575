import { User } from "./user.model";

export class RolePermissionBaseModel extends User{
    public  roleId : string;
    public  permissionId : string;
    //public  isArchived : boolean = false;
}

export class RolePermissionAddModel extends RolePermissionBaseModel{
    
}
