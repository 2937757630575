import { Component, EventEmitter,OnInit  } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AgentsService } from '../agents.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-agent-reset-popup',
  templateUrl: './agent-reset-popup.component.html',
  styleUrls: ['./agent-reset-popup.component.css']
})
export class AgentResetPopupComponent implements OnInit{
data;
  event: EventEmitter<any> = new EventEmitter();
   isUnderstand =false;
  constructor(  private toastr: ToastrService,private agentService: AgentsService,public modalRef: BsModalRef,private router: Router,) { }
  ngOnInit() {
  
   console.log(this.data);
  }

  modalRehide(){ 
    if (!this.modalRef) {
      return;
    } 
   
    this.modalRef.hide();
    this.modalRef = null;
  }
  resetConfirmed(){ 
    if(this.data.userid !=null){
     
      // this.passwordChangeModel.loggedInUserType =  this.userType; // this.data.agent.userType; 
      this.agentService.restMfa(this.data.userid).subscribe((data:any)=>{
        console.log(data);
        if(data.validationIssues || !data.isSuccess){      
          this.toastr.error('Error on Reset Multi Factor Authentication.');
    
        }
        else{
          this.toastr.success('Multi Factor Authentication setting updated successfully.');
          this.event.emit('OK');
          this.modalRef.hide();
    
          this.router.navigate(['agents']);
        }
    
        // this.event.emit('OK');
        // this.modalRef.hide();
        },err=>{   
       
          this.toastr.error('Error on Reset Multi Factor Authentication.');
        });
        
    } 
    };
  
}
