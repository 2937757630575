<angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems" 
                                                        [settings]="dropdownSettings" 
                                                        (onSelect)="onItemSelect($event)" 
                                                        (onDeSelect)="OnItemDeSelect($event)"
                                                        (onSelectAll)="onSelectAll($event)"
                                                        (onDeSelectAll)="onDeSelectAll($event)" name="skills"></angular2-multiselect>
                                                        <div *ngIf="saving" id="saving">  
                                                            <span class="text-success save">Saving...</span>
                                                            <span class="spinnerr"></span>
                                                        </div>
                                                        <div *ngIf="saved" id="saved">  
                                                            <span class="text-success save">Saved</span>
                                                        </div>
                                                        
                                                        
