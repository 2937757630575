import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConstants } from '../utility/app-constants';
import { throwError } from 'rxjs';
import { WebAdminResponse, FactFinderCategoriesResponse, FactFinderResponse, FactFinderNewResponse, GetFactFinderNewResponse } from '../shared/models/response.model';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class FactFinderService {

  baseUrl:String;

  constructor(private http:HttpClient) {
    this.baseUrl = AppConstants.getBaseURL();
   }

  public getFactFinderCategories(){
    //return this.http.get<FactFinderCategoriesResponse>(this.baseUrl+'/QuestionCategorys'); 
    return this.http.get<FactFinderCategoriesResponse>(this.baseUrl+'/QuestionCategorys').pipe(
      catchError(this.handleError)
      );    
  }

  public getFactFinderList(clientId){
    const params = new HttpParams().set('userId', clientId);
    return this.http.get<FactFinderResponse>(this.baseUrl+'/FacFinder/GetQuestionsAndAnswers_View',{params}).pipe(
      catchError(this.handleError)
    );   
  }

  public getFactGoalShort(clientId){
    const params = new HttpParams().set('id', clientId).set('goalType', '1');
    return this.http.get<FactFinderResponse>(this.baseUrl+'/FacGoal/getByUserId',{params}).pipe(
      catchError(this.handleError)
    );   
  }
  
  public getFactGoalMedium(clientId){
    const params = new HttpParams().set('id', clientId).set('goalType', '2');
    return this.http.get<FactFinderResponse>(this.baseUrl+'/FacGoal/getByUserId',{params}).pipe(
      catchError(this.handleError)
    );   
  }

  public getFactGoalLong(clientId){
    const params = new HttpParams().set('id', clientId).set('goalType', '3');
    return this.http.get<FactFinderResponse>(this.baseUrl+'/FacGoal/getByUserId',{params}).pipe(
      catchError(this.handleError)
    );   
  }

  public getFactDependent(clientId){
    const params = new HttpParams().set('id', clientId);
    return this.http.get<FactFinderResponse>(this.baseUrl+'/FacDependent/getByUserId',{params}).pipe(
      catchError(this.handleError)
    );   
  }

  public getFactExpenses(clientId){
    //const params = new HttpParams().set('id', clientId).set('goalType', '3');
    return this.http.get<FactFinderResponse>(this.baseUrl+'/FacExpenses/getByUserId?id='+clientId).pipe(
      catchError(this.handleError)
    );   
  }

  public getFacEntity(clientId){
    //const params = new HttpParams().set('id', clientId).set('goalType', '3');
    return this.http.get<FactFinderResponse>(this.baseUrl+'/FacEntity/getByUserId?id='+clientId).pipe(
      catchError(this.handleError)
    );   
  }

  public getFacInsurance(clientId){
    //const params = new HttpParams().set('id', clientId).set('goalType', '3');
    return this.http.get<FactFinderResponse>(this.baseUrl+'/FacInsurance/getByUserId?id='+clientId+'&insuranceType=1').pipe(
      catchError(this.handleError)
    );   
  }

  public handleError(error) {
    return throwError(error);
  }

  public getFacFinder(clientId){
    //const params = new HttpParams().set('id', clientId).set('goalType', '3');
    return this.http.get<FactFinderNewResponse>(this.baseUrl+'/FacFinder/GetByUserId?UserId='+clientId).pipe(
      catchError(this.handleError)
    );   
  }

  public getFactFinderById(facId){
    //const params = new HttpParams().set('id', clientId).set('goalType', '3');
    return this.http.get<GetFactFinderNewResponse>(this.baseUrl+'/FacFinder/GetByFacFinderId?FinderId='+facId).pipe(
      catchError(this.handleError)
    );   
  }
  
}
