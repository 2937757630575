<app-header></app-header>
<app-sidebar></app-sidebar>


<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                <h3 class="content-header-title mb-0 d-inline-block">NEWS FEEDS</h3>
                <div class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/dashboard">Home</a> </li>
                            <li class="breadcrumb-item active">News feeds </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12">
                <div class="float-md-right">
                    <button type="button" *ngIf="gridView" (click)="changeView(false)"
                        class="btn appointment_btn btn-sm border_all_radius mr-2"><i class="fa fa-th-list fa-1x"
                            aria-hidden="true"></i>&nbsp; List View</button>
                    <button type="button" *ngIf="!gridView" (click)="changeView(true)"
                        class="btn appointment_btn btn-sm border_all_radius mr-2"><i class="fas fa-th"></i>&nbsp; Grid
                        View</button>
                    <!-- <button type="button" class="btn appointment_btn btn-sm border_all_radius mr-2"> <i class="fa fa-th-list fa-1x" aria-hidden="true"></i>
                        &nbsp; List View</button> -->
                    <button class="btn appointment_btn btn-sm border_all_radius" data-toggle="modal"
                        (click)="openModal()">Create New</button>
                </div>

            </div>
        </div>

        <div class="content-body">
            <div class="row">
                <div id="recent-sales" class="col-12 col-md-12" *ngIf="!gridView">
                    <div class="card rounded-0 rounded-top mb-0">
                        <div class="card-header">
                            <h4 class="card-title">News feed List</h4>
                        </div>
                        <div class="card-content">
                            <div *ngIf="isLoaded" class="table-responsive scroll-tbl_newsfeed-">
                                <!-- <table id="recent-orders" class="table table-hover table-xl mb-0"> -->
                                <table datatable [dtOptions]="dtOptions"
                                    class="table table-striped table-bordered zero-configuration t_dt"
                                    style="margin-top: 20px !important;">
                                    <thead>
                                        <tr>
                                            <th class="border-top-0">Title</th>
                                            <th class="border-top-0" style="width: 0px; "> Type</th>
                                            <th class="border-top-0" style="width: 0px; "> Audience</th>
                                            <th class="border-top-0">Created</th>
                                            <th class="border-top-0">Expires</th>
                                            <th class="border-top-0"></th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="newsFeedList.length>0">
                                        <tr *ngFor="let newsFeed of newsFeedList; let i = index">
                                            <td class="text-truncate" data-toggle="tooltip" title="{{ newsFeed.title }}">
                                                <ng-container *ngIf="newsFeed.title">
                                                    {{newsFeed.title | limitTo : 20}}
                                                </ng-container>
                                                <ng-container *ngIf="newsFeed.title.trim() == ''">
                                                    --
                                                </ng-container>
                                            </td>
                                            <td class="text-truncate">
                                                <ng-container *ngIf="newsFeed.documentType=='1'">
                                                    <p class="mb-0 newsfeed-title text-uppercase">--</p>
                                                    <!-- <img src="{{ 'assets/img/news_image.png'}}" alt=""
                                                        class="news_img_wid"> -->
                                                </ng-container>
                                                <ng-container *ngIf="newsFeed.documentType=='2'">
                                                    <p class="mb-0 newsfeed-title text-uppercase">Image</p>
                                                    <!-- <img src="assets/img/news_pdf.png" alt="" class="news_img_wid"> -->
                                                </ng-container>
                                                <ng-container *ngIf="newsFeed.documentType=='3'">
                                                    <p class="mb-0 newsfeed-title text-uppercase">Document</p>
                                                    <!-- <img src="assets/img/news_doc.png" alt="" class="news_img_wid"> -->
                                                </ng-container>
                                                <ng-container *ngIf="newsFeed.documentType=='4'">
                                                    <p class="mb-0 newsfeed-title text-uppercase">Video</p>
                                                    <!-- <img src="assets/img/news_doc.png" alt="" class="news_img_wid"> -->
                                                </ng-container>
                                                <ng-container *ngIf="newsFeed.documentType=='5'">
                                                    <p class="mb-0 newsfeed-title text-uppercase">Web Link </p>
                                                    <!-- <img src="assets/img/news_doc.png" alt="" class="news_img_wid"> -->
                                                </ng-container>
                                            </td>
                                            <td class="text-truncate" data-toggle="tooltip"
                                                title="">
                                                    {{newsFeed.audience.name}}
                                            </td>
                                            <td class="text-truncate">{{ formatLocalTime(newsFeed.createdDate) | date:
                                                'dd/MM/yyyy'}}</td> <!-- | date: 'dd MMM yyyy' -->
                                            <td class="text-truncate">
                                                <ng-container *ngIf="newsFeed.expiredDate">
                                                    <ng-container *ngIf="checkIsExpired(newsFeed.expiredDate)">
                                                        <p class="mb-0 expire" *ngIf="newsFeed.expiredDate">
                                                                {{formatLocalTime(newsFeed.expiredDate) | date:'dd/MM/yyyy'}}
                                                        </p>
                                                    </ng-container>
                                                    <ng-container *ngIf="!checkIsExpired(newsFeed.expiredDate)">
                                                        <p class="mb-0 expired" *ngIf="newsFeed.expiredDate">
                                                                {{formatLocalTime(newsFeed.expiredDate) | date:'dd/MM/yyyy'}}
                                                        </p>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="newsFeed.expiredDate == null">
                                                    <p class="mb-0 expiry">Not set </p>
                                                </ng-container>
                                            </td>
                                            <td class="sa_btns">
                                                <div class="btn-group">
                                                    <button type="button" class="btn btn-icon mr-1 btn_trash" (click)="openDeleteDialog(template,newsFeed.id)"><i class="la la-trash"></i></button>
                                                    <!-- <button type="button" class="btn btn-icon mr-1 newsbtn"><i class="la la-pencil"></i></button> -->
                                                    <button (click)="openEditModal(newsFeed)"  type="button" class="btn btn-icon btn-success mr-1 btntable"><i class="icon-pencil"></i></button>
                                                    <button type="button" (click)="viewNewsFeed(newsFeed)" class="btn btn-icon btn-success mr-1 btntable"><i class="la la-arrow-right"></i></button>
                                                </div>
                                                <!-- <button type="button" (click)="viewNewsFeed(newsFeed)" class="btn btn-icon btn-success c_btntable" data-toggle="modal" data-target="#default"><i class="la la-arrow-right c_icon_s"></i></button> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="newsFeedList.length == 0"
                                        style=" width: 100% !important; text-align: center;">
                                        <tr>
                                            <td colspan="3">No records to show.</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="isLoaded && gridView">
                    <!-- <div class="row"> -->
                        <div *ngFor="let newsFeed of returnedNewsFeedList; let i = index" class="col-xs-6 col-md-3 d-flex align-items-stretch">
                            <div class="card custom-card-wr mx-auto" >
                                <div class="cursor-pointer" (click)="viewNewsFeed(newsFeed)">
                                    <!-- <div class=""> -->
                                    <ng-container *ngIf="newsFeed.coverImageurl=='' || newsFeed.coverImageurl==null ">

                                        <img class="card-img-top grid_img" src="../assets/img/image@3x.png" alt=""  />

                                        <!-- <img class="card-img-top grid_img" src="../assets/img/woman-2773007_1920.jpg" alt="" /> -->

                                    </ng-container>
                                    <ng-container *ngIf="newsFeed.coverImageurl">
                                        <img class="card-img-top grid_img" src="{{newsFeed.coverImageurl}}" alt="" />
                                    </ng-container>
                                    <!-- </div> -->
                                    <div class="card-content p-1">
                                        <!-- <div class="media align-items-stretch"> -->


                                        <!-- <div class="media-body pad_tobo"> -->
                                        <h4 class="newsfeed-title" data-toggle="tooltip" title="{{ newsFeed.title }}">{{newsFeed.title | limitTo : 20}}</h4>
                                        <p class="mb-0">Audience : {{newsFeed.audience.name}}</p>
                                        <p class="mb-0">Created : {{ formatLocalTime(newsFeed.createdDate) | date:
                                            'dd/MM/yyyy'}}</p>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <ng-container *ngIf="newsFeed.expiredDate">
                                                <ng-container *ngIf="checkIsExpired(newsFeed.expiredDate)">
                                                    <p class="mb-0 expire" *ngIf="newsFeed.expiredDate">Expires :
                                                            {{formatLocalTime(newsFeed.expiredDate) | date:'dd/MM/yyyy'}}
                                                    </p>
                                                </ng-container>
                                                <ng-container *ngIf="!checkIsExpired(newsFeed.expiredDate)">
                                                    <p class="mb-0 expired" *ngIf="newsFeed.expiredDate">Expired :
                                                            {{formatLocalTime(newsFeed.expiredDate) | date:'dd/MM/yyyy'}}
                                                    </p>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="newsFeed.expiredDate == null">
                                                <p class="mb-0 expiry">Expires : Not set </p>
                                            </ng-container>


                                            <!-- <p class="mb-0 no-expiry">No Expiry</p>
                                            <p class="mb-0 expired">Expired  23/07/2021</p> -->
                                            <p class="mb-0 newsfeed-title text-uppercase">
                                                <ng-container *ngIf="newsFeed.documentType=='1'">
                                                    <p class="mb-0 newsfeed-title text-uppercase">--</p>
                                                    <!-- <img src="{{ 'assets/img/news_image.png'}}" alt=""
                                                        class="news_img_wid"> -->
                                                </ng-container>
                                                <ng-container *ngIf="newsFeed.documentType=='2'">
                                                    <p class="mb-0 newsfeed-title text-uppercase">Image</p>
                                                    <!-- <img src="assets/img/news_pdf.png" alt="" class="news_img_wid"> -->
                                                </ng-container>
                                                <ng-container *ngIf="newsFeed.documentType=='3'">
                                                    <p class="mb-0 newsfeed-title text-uppercase">Document</p>
                                                    <!-- <img src="assets/img/news_doc.png" alt="" class="news_img_wid"> -->
                                                </ng-container>
                                                <ng-container *ngIf="newsFeed.documentType=='4'">
                                                    <p class="mb-0 newsfeed-title text-uppercase">Video</p>
                                                    <!-- <img src="assets/img/news_doc.png" alt="" class="news_img_wid"> -->
                                                </ng-container>
                                                <ng-container *ngIf="newsFeed.documentType=='5'">
                                                    <p class="mb-0 newsfeed-title text-uppercase">Web Link </p>
                                                    <!-- <img src="assets/img/news_doc.png" alt="" class="news_img_wid"> -->
                                                </ng-container>
                                            </p>
                                        </div>

                                        <!-- </div> -->
                                        <!-- </div> -->
                                    </div>
                                </div>
                                <hr class="mx-1 mt-0 border-top-blue-grey border-top-lighten-5 ">
                                <div class=" text-muted card_fnews card_fnews">

                                    <a class="action-btn" (click)="openDeleteDialog(template,newsFeed.id)"><i class="la la-trash float-left"></i></a>
                                    <a class="action-btn" (click)="openEditModal(newsFeed)"><i class="la la-pencil float-right"></i></a>
                                </div>
                            </div>

                        </div>
                    <!-- </div> -->
                </ng-container>

                <ng-container *ngIf="isLoaded && gridView && newsFeedList.length==0">
                    <div class="col-xs-6 col-md-3">
                        <div class="card">
                            <!-- <div class=""> -->
                            <img class="card-img-top grid_img" src="../assets/img/no_data_cover.png" alt="" class="" />
                            <!-- </div> -->
                            <div class="card-content p-1">
                                <p class="mb-0 text-center">News feed list is empty</p>
                            </div>
                            <a class="d-flex justify-content-center mb-2" (click)="openModal()">
                                <img src="../assets/img/add.svg">
                            </a>
                        </div>
                    </div>

                </ng-container>

                <div [hidden]="!isLoaded" class="grid-pagination-wr col-12">
                  <div class="bg-white d-flex justify-content-between align-items-center mb-3 px-2 rounded-bottom">
                    <span>Showing
                      {{ (this.currentPage == 1) ? this.currentPage : ((this.currentPage * this.itemsPerPage) -  this.itemsPerPage + 1)}}  to
                      {{ (this.itemsPerPage == this.newsFeedList?.length) ? (this.currentPage * this.itemsPerPage) :  this.totalRecordsCount }} of
                      {{ this.totalRecordsCount }} entries
                    </span>
                    <pagination
                      [boundaryLinks]="showBoundaryLinks"
                      [directionLinks]="showDirectionLinks"
                      [totalItems]="this.totalRecordsCount"
                      [itemsPerPage]="this.itemsPerPage"
                      (pageChanged)="getNewsFeedList($event)"
                      [maxSize]="10">
                    </pagination>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-body text-center">

        <img src="assets/img/warning.png" alt="" class="alert_img">
        <p>{{message}}</p>

        <div class="form-actions center mb-1">
            <button type="button" class="btn alert_no_b mr-1" (click)="decline()">
                No
            </button>
            <button type="button" class="btn alert_yes_b" (click)="confirm()">
                Yes
            </button>
        </div>
    </div>
</ng-template>

<app-footer></app-footer>
