 
import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NetworthRolbackService } from '../networth-rollback.service'   //'./networth-rollbak.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
 


@Component({
  selector: 'app-rollback-popup',
  templateUrl: './rollback-popup.component.html',
  styleUrls: ['./rollback-popup.component.css']
})
export class RollbackPopupComponent {
 
  event: EventEmitter<any> = new EventEmitter();
   isUnderstand =false;
  constructor( private networthRolbackService :NetworthRolbackService ,private toastr: ToastrService, private ngxService: NgxUiLoaderService,public modalRef: BsModalRef) { }


  modalRehide(){ 
    if (!this.modalRef) {
      return;
    } 
    //console.log('ccccccccc');
    this.modalRef.hide();
    this.modalRef = null;
  }
  rollbackConfirmed(){ 
    this.networthRolbackService.rollbackNetworth().subscribe((data :any)=>{
      if(data.error && !data.success){ 
       
        //this.errors = Utility.GetLocalErrorString(data.error.errorCode);
        this.toastr.error('Error on saving news feed.');
        //console.log(this.errors);
      }
      else{ 
        this.toastr.success('Net worth updated successfully.');
      
       this.event.emit('OK');
        this.modalRef.hide();
      }   

    });
    };
    
  }

 
