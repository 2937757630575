
    <div class="modal-header border-bottom-0">
        <h5 class="modal-title factfinderheader" id="exampleModalLabel">{{ title }} Assets</h5>
        <button type="button" class="close" (click)="modalRehide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
  
      <form [formGroup]="addAssetForm" novalidate class="form " (ngSubmit)="onSubmit()" >
      <div class="modal-body factfinderbody">
        
        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Type</label>
          <div class="inputwidth"> <span>:</span>
            <!-- <input type="text" class="form-control form-control-sm" placeholder="Mobile Phone"> -->
            <select formControlName="type" required [(ngModel)]="assetAddModel.type" (ngModelChange)="onChange($event)" class="form-control form-control-sm" placeholder="Type" >
              <option value="000">Please select</option>
              
              <ng-container *ngFor="let assetTypeOne of assetTypeList; let i = index">
                  <option value="{{assetTypeOne.key}}">{{assetTypeOne.value}}</option>
              </ng-container>
              
            </select>
                <div class="form-group">
                  <div *ngIf="submitted && f.type.errors"   class="form_alert">
                          <span  class="ft-alert-circle"></span>
                      <div *ngIf="f.type.errors.required">Type is required.</div>
                </div>
                </div>
          </div>
        </div>
        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Sub Type</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            
            <select formControlName="subType" required [(ngModel)]="assetAddModel.subType" class="form-control form-control-sm" placeholder="Sub Type" >
              <option value="000">Please select</option>
              <ng-container *ngFor="let assetSubTypeOne of assetSubTypeList; let i = index">
                  <option value="{{assetSubTypeOne.key}}">{{assetSubTypeOne.value}}</option>
              </ng-container>
            </select>
            
            <div class="form-group">
              <div *ngIf="submitted && f.subType.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.subType.errors.required">Sub Type is required.</div>
              </div>
            </div>  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Current Value</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            <div class="input-group prepend_group">
                <span class="input-group-prepend" style="top: 0px;">
                    <div class="input-group-text border-right-0 prepend_new_class"><span class="prepend_span"> $ </span></div>
                </span>
              <input formControlName="currentValue" required [(ngModel)]="assetAddModel.currentValue" type="number" pattern="^[1-9]\d*$" class="form-control form-control-sm" style="width: unset;" placeholder="Current Value">
            </div>

            <div class="form-group">
              <div *ngIf="submitted && f.currentValue.errors"   class="form_alert aud_errormsg">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.currentValue.errors.required">Current Value is required.</div>
                  <div *ngIf="f.currentValue.errors.pattern">Only numbers.</div>
              </div>
            </div>
          </div>
        </div>


        <div class="form-group row factfinderpopup">
          <label for="inputPassword" class="labelwidth">Description</label>
          <div class="inputwidth"> <span>:</span> 
            <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
            <input formControlName="description" required [(ngModel)]="assetAddModel.description" type="text" class="form-control form-control-sm" placeholder="Description">
            <div class="form-group">
              <div *ngIf="submitted && f.description.errors"   class="form_alert">
                      <span  class="ft-alert-circle"></span>
                  <div *ngIf="f.description.errors.required">Description is required.</div>
              </div>
            </div>  
          </div>
        </div>

        <div class="form-group row factfinderpopup">
            <label for="postcode" class="labelwidth">Owner</label>
            <div class="inputwidth"> <span>:</span> 
              <!-- <input type="text" class="form-control form-control-sm" id="inputPassword" placeholder="Phone"> -->
              
              <select formControlName="ownerType" required [(ngModel)]="assetAddModel.ownerType" class="form-control form-control-sm" placeholder="Owners" >
                <option value="000">Please select</option>
                <ng-container *ngFor="let assetOwnerOne of assetOwnerList; let i = index">
                    <option value="{{assetOwnerOne.key}}">{{assetOwnerOne.value}}</option>
                </ng-container>
              </select>
              <div class="form-group">
                <div *ngIf="submitted && f.ownerType.errors"   class="form_alert">
                        <span  class="ft-alert-circle"></span>
                    <div *ngIf="f.ownerType.errors.required">Owner is required.</div>
                </div>
              </div>  
            </div>
          </div>
        
      </div>
      <div class="modal-footer border-top-0 pt-0">
        <button class="btn btn-primary factfinderbtn">Save</button>
      </div>
  </form>
      