export enum Distibution {
}

export enum RelationType{
  Main = 1,
  Parent = 2,
  Spouse = 3,
  Divorcee = 4,
  Child = 5,
  SpouseChild = 6,
  DivorceeChild = 7
}

export enum GenderType{
  Male = 1,
  Female = 0
}

export enum GraphType{
  StandardWill = 1,
  NewDeFacto = 2,
  AgeBased = 3,
  Testamentary = 4,
  PersonalSuccession = 5,
  ProtectedWealth = 6,
}


export enum TreeRole{

  MainParent = 1,
  SpouseParent = 2,
  Main = 3,
  Spouse = 4,
  MainDivorcee = 5,
  SpouseDivorcee = 6,
  Divorcee = 7,
  MainChild = 8,
  SpouseChild = 9,
  CommonChild = 10,
  ChildSpouse = 11,
  GrandChild = 12,
  Hidden = 13

}

