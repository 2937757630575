import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { AgentViewModel,AgentBaseMOdel,AgentAddModel, UserAgentAddModel } from '../../shared/models/agent.model';
import { Router } from '@angular/router';
import { AgentsService } from '../agents.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { AgentResponse,AgentProfileResponse, CompanyResponse, RoleResponse } from '../../shared/models/response.model';
import { Utility } from '../../utility/utility';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PermissionGroupsService } from 'src/app/permission-groups/permission-groups.service';
import { RoleAddModel } from 'src/app/shared/models/role.model';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-add-agent',
  templateUrl: './add-agent.component.html',
  styleUrls: ['./add-agent.component.css']
})
export class AddAgentComponent implements OnInit {

  agentModel : UserAgentAddModel ;
  roleList:RoleAddModel[];
//clinics : ClinicViewModel[];
//patientClinic:ClinicViewModel; 
title;
data;
errors;
//ethnicities : any;
patientAddForm: FormGroup;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  maxdate :any;
  //public utility: utility;
  Isvalid:boolean;
  companys: any;
  companysDropdown: any;
  isLoaded: boolean;
  userTypeArray: any;
  companyId: any;

  constructor(private ngxService: NgxUiLoaderService,private formBuilder: FormBuilder,private router: Router, private agentService: AgentsService,public modalRef: BsModalRef,private toastr: ToastrService, private permissionGroupsService: PermissionGroupsService, private cookieService: CookieService) { }
 
  ngOnInit() {
    this.Isvalid = true;
    //this.maxdate = this.utility.GetToday();
    //this.ethnicities = [{"id":"1","ethnicity":"Australian"},{"id":"2","ethnicity":"European"}];
    this.agentModel = new UserAgentAddModel();
    //console.log(this.modalRef.content);

    // this.agentService.clinicList().subscribe((clinicData:ClinicViewModel[])=>{
   
    //   this.clinics = clinicData;
    //   },
    //   (err: HttpErrorResponse)=>{
    //     console.log("Error"+ err.message);
    //   });

    if(this.data.agent !=null){
      Object.assign(this.agentModel, this.data.agent);
    }

    this.agentService.getAllCompany().subscribe((data:CompanyResponse)=>{ 
      // console.log(this.cookieService.get('companyId'), '----cookieService companyId-----');    
      this.companys = data;
      this.companysDropdown = this.companys.data;
      this.agentModel.companyId = this.cookieService.get('companyId'); // this.companys.data[0].id;
      this.companyId = this.cookieService.get('companyId');
      this.isLoaded = true;
      // console.log(this.agentModel, '-----agentModel-----')
        //console.log(this.companys.data[0]);
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    });

    this.permissionGroupsService.roleList().subscribe((data:RoleResponse)=>{  
      this.roleList = data.data;  
      //console.log(this.roleList);
      this.isLoaded = true;
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
    }); 

    this.agentModel.userType = null;
    this.agentModel.roleId = null;
    this.userTypeArray = [{"id":null,"value":"Please select"},{"id":"1","value":"Admin"},{"id":"3","value":"Staff member"}]; 
      // ,{"id":"2","value":"Client"}
  }

    //get f() { return this.patientAddForm.controls; }
onSubmit(form:NgForm){
  // this.submitted = true;
  // if (this.patientAddForm.invalid) {
  //   return;
  // }

if(form.invalid || this.agentModel.roleId == "null" || this.agentModel.userType == 0 || this.agentModel.firstName.trim() == "" || this.agentModel.lastName.trim() == "" || this.agentModel.prefferedName.trim() == "") {

  if(this.agentModel.roleId == "null"){
    form.controls['roleId'].setErrors({'required': true});
  }

  if(this.agentModel.userType == 0){
    form.controls['userType'].setErrors({'required': true});
  }

  if(this.agentModel.firstName.trim() == ""){
    form.controls['firstName'].setErrors({'required': true});
  }

  if(this.agentModel.lastName.trim() == ""){
    form.controls['lastName'].setErrors({'required': true});
  }

  if(this.agentModel.prefferedName.trim() == ""){
    form.controls['prefferedName'].setErrors({'required': true});
  }

  if(form.pristine){
  }
  this.Isvalid = false;
}

else{

  //  this.patientModel.clinicId = this.clinics[0].id;
  //  this.patientModel.clinicianId = JSON.parse( localStorage.getItem('CurrentUserId'));
  if(this.data.agent !=null){
  //   this.ngxService.start();
  // //console.log("Edditre");
  //   this.agentService.agentEdit(this.data.agent.id,this.agentModel).pipe(first())
  //   .subscribe((data:AgentProfileResponse)=>{
  //     this.ngxService.stop();
      
  //     if(data.error && !data.success){
  //       this.toastr.error('Error on adding agent.');
  //     }
  //     else{
  //       this.toastr.success('Agent added successfully.');
  //       this.modalRef.hide();
  //     }

  //     },err=>{ 
  //       this.ngxService.stop();  
  //       this.toastr.error('Error on adding agent.');
  //     });
      
      //this.router.navigate(['agents']);
      

  }else{
    //console.log("Add");
    this.ngxService.start();
    this.agentService.agentAdd(this.agentModel)
    .subscribe((data:AgentProfileResponse)=>{
      this.ngxService.stop();
      if(data.error && !data.success){
        //return data;
        //let err = Utility.GetLocalErrorString(data.error.errorCode);
        //console.log(data.error);
        //console.log(data.error.message);
        if(data.error.message){
          this.errors = data.error.message;
        }
        else{
          this.errors = Utility.GetLocalErrorString(data.error.errorCode);
        }
        //console.log(this.errors);
        //this.event.emit(data.error.message);
      }
      else{
        this.toastr.success('Agent added successfully.');
        this.event.emit('OK');
        this.modalRef.hide();
      }
      
      },err=>{   
        this.ngxService.stop();
        this.toastr.error('Error on saving agent.');
      });
    }

  

}

}

modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

}
