import { Component, OnInit, EventEmitter, AfterContentChecked } from '@angular/core';
import { InvoiceAddModel } from '../../shared/models/invoice.model'
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NewsFeedService } from '../news-feed.service';
import { InvoiceResponse, ClentResponsex, TransactionResponse, AudienceResponse, NewsFeedResponse, FileUploadResponse } from 'src/app/shared/models/response.model';
import { Utility } from 'src/app/utility/utility';
import { ClientsService } from 'src/app/clients/clients.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ClientViewModel } from 'src/app/shared/models/client.model';
import { InvoiceType } from 'src/app/shared/models/db-enums.model';
import { TransactionViewModel } from 'src/app/shared/models/transaction.model';
import { first } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { NewsFeedAddModel } from 'src/app/shared/models/news-feed.model';
import { FileUploadAddModel } from 'src/app/shared/models/file-upload.model';
import { CookieService } from 'ngx-cookie-service';
import { AudienceService } from 'src/app/audience/audience.service';
import { AudienceAddModel } from 'src/app/shared/models/audience.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FileUploadService } from 'src/app/file-upload/file-upload.service';
import { NgbDateStruct, NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-news-feed',
  templateUrl: './edit-news-feed.component.html',
  styleUrls: ['./edit-news-feed.component.css'],
  providers: [NgbTimepickerConfig]
})
export class EditNewsFeedComponent implements OnInit, AfterContentChecked {

  newsFeedModelData: NewsFeedAddModel;
  newsFeedModel: NewsFeedAddModel;
  fileUploadAddModel: FileUploadAddModel;
  //clinics : ClinicViewModel[];
  //patientClinic:ClinicViewModel; 
  title;
  data;
  errors;

  patientAddForm: FormGroup;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  maxdate: any;
  //public utility: utility;
  Isvalid: boolean;
  clientList: ClientViewModel[];
  transactionList: TransactionViewModel[];
  documentType1: any;
  companyCode: any;
  fileUploadCoverAddModel: FileUploadAddModel;
  isLoaded: boolean;
  audienceList: AudienceAddModel[];
  uploadDocumentEnable: boolean = true;
  uploadType: string = 'NoSelect';
  acceptDocType: string;
  gridViewStatus: boolean;
  docUplodLable: string;
  isShowDocumentUplade: boolean = false;
  isShowCoverImageUplade: boolean = false;
  documentLabel = "Choose file";
  documentLabelCover = "Choose file";
  disableDocumentType: boolean = false;
  documentTypeName: string;
  docUploadSuccess: boolean = false;
  isLoading: boolean = false;
  maxChars = 120;
  chars = 0;
  myInterval;
  isScheduleNewsfeed: boolean = false;
  time: NgbTimeStruct = { hour: 13, minute: 30, second: 0 };
  model: NgbDateStruct;
  minDate = undefined;
  isExpiredShow : boolean = false;
  public mobileW: number = 320;
  public url: string | ArrayBuffer;
  public mobileH: number;
  public mobileLink: string;

  constructor(
    private cookieService: CookieService,
    public modalRef: BsModalRef,
    private audienceService: AudienceService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    private newsFeedService: NewsFeedService,
    private fileUploadService: FileUploadService,
    config: NgbTimepickerConfig,
    public datepipe: DatePipe,
  ) {
    config.seconds = false;
    config.spinners = false;
      const current = new Date();
      this.minDate = {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate()
      };
  }

  ngOnInit() {
    this.getAudienceList();
    // this.documentType1 = [{"id":"0","value":"Please select"},{"id":"1","value":"Jpg"},{"id":"2","value":"Png"},{"id":"3","value":"Pdf"},{"id":"4","value":"Doc"},{"id":"5","value":"Video"}]; 
    this.documentType1 = [{ "id": "1", "value": "No select" }, { "id": "2", "value": "Image" }, { "id": "3", "value": "Document" }, { "id": "4", "value": "Video" }, { "id": "5", "value": "Web Link" }];
    this.Isvalid = true;
    this.companyCode = this.cookieService.get('companyCode');

    this.newsFeedModelData = new NewsFeedAddModel();
    this.newsFeedModel = new NewsFeedAddModel();
    this.fileUploadAddModel = new FileUploadAddModel();
    this.fileUploadCoverAddModel = new FileUploadAddModel();

    if (this.data.newsFeed != null) {
      Object.assign(this.newsFeedModelData, this.data.newsFeed);
      console.log('bbbbb',this.newsFeedModelData)
      this.newsFeedModel = this.newsFeedModelData;
      this.gridViewStatus = this.data.gridViewStatus;

      if(this.newsFeedModel.isExpired ) {
        this.isExpiredShow = false
        this.newsFeedModel.isExpiredInput = false
      } else {
        this.isExpiredShow = true
        this.newsFeedModel.isExpiredInput = true
      }
      
      if(this.newsFeedModel.sheduledDate!=null) {
        let dd = new Date(this.newsFeedModel.sheduledDate+'Z'); // (this.newsFeedModel.sheduledDate+'Z');
        var fromDate = this.datepipe.transform(dd, 'yyyy-MM-ddTHH:mm:ss' );
        let date = this.ngbDateFormat(fromDate);
        this.newsFeedModel.date = date;
        let time = this.convertTime24to12(dd)// this.ngdTimeFormat(fromDate);
        this.newsFeedModel.time = time;
        this.newsFeedModel.amPm = this.formatAMPM(dd);
      }
      else {
        this.newsFeedModel.time = '01:00';
        this.newsFeedModel.amPm = 'AM';
      }

      if(this.newsFeedModel.expiredDate!=null) {
        let dd = new Date(this.newsFeedModel.expiredDate+'Z'); // (this.newsFeedModel.sheduledDate+'Z');
        var fromDate = this.datepipe.transform(dd, 'yyyy-MM-ddTHH:mm:ss' );
        let date = this.ngbDateFormat(fromDate);
        this.newsFeedModel.expiredDate = date;
      } else {
        this.newsFeedModel.expiredDate = this.futureDate();
      }
        
    }

    this.getDocumentTypeName(this.newsFeedModelData.documentType);
    this.onDocumentTypeChange(this.newsFeedModelData.documentType);
    //this.getClientList();
    //this.getNewsFeedList();
    // this.newsFeedModel.documentType = "0"
    // this.newsFeedModel.audinceID = null;
    // this.newsFeedModel.documentURL = '';
    
    // console.log(this.newsFeedModelData);
  }

  ngAfterContentChecked() {
    const calc = document.getElementById('outer-d').clientWidth - 40;
    if (calc > 400) {
      this.mobileW = 280;
    } else {
      this.mobileW = calc;
    }
    this.mobileH = (this.mobileW * 1.7) + 63;
  }

  onSubmit(form: NgForm) {
    let title = this.newsFeedModel.title;
    let message = this.newsFeedModel.message;
    let audinceID = this.newsFeedModel.audinceID;
    let date = this.newsFeedModel.date;
    let time = this.newsFeedModel.time;
    let expiredDate = this.newsFeedModel.expiredDate;

    if (form.invalid || (title && title.trim() == "") || (message && message.trim() == "") || (date && date.length==0) || (time && time.length==0) || (expiredDate && expiredDate.length==0)) {

      if (title && title.trim() == "") {
        form.controls['title'].setErrors({ 'required': true });
      }
      if (message && message.trim() == "") {
        form.controls['message'].setErrors({ 'required': true });
      }
      if(this.isScheduleNewsfeed){
        if(date == null){
          form.controls['date'].setErrors({'required': true});
        }
        if(time == null){
          form.controls['time'].setErrors({'required': true});
        }
      }

      if(this.isExpiredShow){
        if(expiredDate == null){
          form.controls['expiredDate'].setErrors({'required': true});
        }
      }
      // if(audinceID == null){
      //   form.controls['audinceID'].setErrors({'required': true});
      // }

      if (form.pristine) {
      }
      this.Isvalid = false;
      // console.log(form.invalid);  
      //console.log(this.Isvalid);
    }

    else {
      this.ngxService.start();
      this.Isvalid = true;

      if (this.data.newsFeed != null) {
        // console.log('ddd = newsFeedModelData ==',this.newsFeedModelData);
        this.newsFeedModel.title = this.newsFeedModel.title;
        this.newsFeedModel.audinceID = this.newsFeedModelData.audinceID;
        this.newsFeedModel.isExpired = form.value.isExpired;
        if(this.isScheduleNewsfeed){
          // let year = this.newsFeedModel.date.year;
          // let month = this.newsFeedModel.date.month;
          // let day = this.newsFeedModel.date.day;
          // let hour = this.newsFeedModel.time.hour;
          // let minute = this.newsFeedModel.time.minute;
          // let second = 0;
          // var d = new Date(year, month-1, day, hour, minute, second);
          // // d.setTime( d.getTime() + d.getTimezoneOffset()*60*1000 );
          // // let date = this.msqlDateFormat(this.newsFeedModel.date);
          let hour = form.value.time;
          let amPm = form.value.amPm;
          let time = hour+ ' ' +amPm;
          let time24 = this.convertTime12to24(time);
          let date = this.msqlDateFormat(form.value.date);
          let fullDate = date+' '+time24+':00.0000000';

          var date_1 = new Date(fullDate);
          this.newsFeedModel.sheduledDate= date_1.toISOString(); // d.toISOString();
        }

        if(!this.isExpiredShow){
          let date = this.msqlDateFormat(form.value.expiredDate);
          let fullDate = date +' '+'23:50.0000000';
  
          var date_1 = new Date(fullDate);
          this.newsFeedModel.expiredDate= date_1.toISOString(); // fullDate; // fullDate.toISOString();
        } else {
          // if(this.newsFeedModel.expiredDate!=null) {
          //   let date = this.msqlDateFormat(this.newsFeedModel.expiredDate);
          //   let fullDate = date // +' '+'00:00.0000000';
    
          //   var date_1 = new Date(fullDate);
          //   this.newsFeedModel.expiredDate= date_1.toISOString(); // fullDate; // fullDate.toISOString();
          // }
          this.newsFeedModel.expiredDate = null;
        }

        if(this.newsFeedModel.isExpiredInput) {
          this.newsFeedModel.isExpired = false
        } else {
          this.newsFeedModel.isExpired = true
        }
        
        if (this.isShowDocumentUplade) {
          this.upladeDoc();
        }

        if (this.isShowCoverImageUplade) {
          this.upladeCoverImage();
        }

        if (!this.isShowDocumentUplade && !this.isShowCoverImageUplade) {
          this.editData();
        }

        // if(!this.isLoading){

        // }

        // this.myInterval = setInterval(function(){
        //   console.log("mmk");

        //   console.log('eee= newsFeedModel ==',this.newsFeedModel);
        //   console.log('this.isLoading == ', this.isLoading);
        // }, 3 * 1000);

      }
    }
  }

  upladeDoc() {
    this.isLoading = true;
    this.newsFeedModel.documentURL = this.newsFeedModel.documentURL;

    if (this.uploadType == 'Doc' || this.uploadType == 'Image') {
      // console.log('doc, image type ===', this.newsFeedModel.documentType)
      this.fileUploadAddModel.fileCategory = "3";
      this.fileUploadAddModel.fileType = this.newsFeedModel.documentType;
      this.fileUploadAddModel.userId = this.cookieService.get('CurrentUserId'); //JSON.parse(localStorage.getItem('CurrentUserId'));

      this.fileUploadService.uploadFile(this.fileUploadAddModel).subscribe((data: FileUploadResponse) => {

        if (data.error && !data.success) {
          this.ngxService.stop();
          this.errors = Utility.GetLocalErrorString(data.error.errorCode);
          //console.log(this.errors);
        }
        else {
          this.newsFeedModel.documentURL = data.response.s3Key;
          this.docUploadSuccess = true;
          this.isLoading = false;
          this.editData();
        }
      }, err => {
        this.ngxService.stop();
        this.toastr.error('Document upload error.');
      });
    }
    else {
      this.docUploadSuccess = true;
      this.isLoading = false;
      this.editData();
    }

  }

  upladeCoverImage() {
    this.isLoading = true;
    // console.log('doc, image type ===', this.newsFeedModel.documentType)
    this.fileUploadCoverAddModel.fileCategory = "3";
    this.fileUploadCoverAddModel.fileType = this.newsFeedModel.documentType;
    this.fileUploadCoverAddModel.userId = this.cookieService.get('CurrentUserId');

    this.fileUploadService.uploadFile(this.fileUploadCoverAddModel).subscribe((data: FileUploadResponse) => {

      if (data.error && !data.success) {
        this.ngxService.stop();
        this.errors = Utility.GetLocalErrorString(data.error.errorCode);
        //console.log(this.errors);
      }
      else {
        this.newsFeedModel.coverImageurl = data.response.s3Key;
        this.isLoading = false;
        this.editData();
      }
    }, err => {
      this.ngxService.stop();
      this.toastr.error('Document upload error.');
    });
  }

  editData() {
    // console.log('eee= newsFeedModel ==', this.newsFeedModel);
    // console.log('this.isLoading == ', this.isLoading);

    this.newsFeedService.editNewsFeed(this.data.newsFeed.id, this.newsFeedModel).pipe(first()).subscribe((data: NewsFeedResponse) => {
      this.ngxService.stop();
      if (data.error && !data.success) {
        this.toastr.error('Error on updating news feed.');
      }
      else {
        let successMssage = 'News feed updated successfully.'
        if (this.isShowDocumentUplade && !this.isShowCoverImageUplade) {
          this.toastr.success(successMssage);
        }
        else if (!this.isShowDocumentUplade && this.isShowCoverImageUplade) {
          this.toastr.success(successMssage);
        }
        else if (this.isShowDocumentUplade && this.isShowCoverImageUplade) {
          this.toastr.success(successMssage);
        }
        else if (!this.isShowDocumentUplade && !this.isShowCoverImageUplade) {
          this.toastr.success(successMssage);
        }

        this.event.emit('OK');
        // this.event.emit(this.gridViewStatus);
        this.modalRef.hide();
        // this.router.navigate(['agents']);
      }
    }, err => {
      this.ngxService.stop();
      this.toastr.error('Error on updating news feed.');
    });

  }


  modalRehide() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  getAudienceList() {
    //this.sidebarService.preloaderAction(true);
    this.audienceService.audienceList().subscribe((data: AudienceResponse) => {
      this.isLoaded = true;
      this.audienceList = data.response;

    },
      (err: HttpErrorResponse) => {
        console.log("Error" + err.message);
      });
  }

  onDocumentTypeChange(docType) {
    this.uploadDocumentEnable = false;

    if (docType == 1) {
      this.uploadType = 'NoSelect';
      this.newsFeedModel.documentURL = '';
    }

    // if(docType==1){
    //   this.acceptDocType = ".jpg,.jpeg";
    // }else if(docType==2){
    //   this.acceptDocType = ".png";
    // }else if(docType==3){
    //   this.acceptDocType = ".pdf";
    // }else if(docType==4){
    //   this.acceptDocType = ".doc,.docx"; 
    // }

    if (docType == 2) {
      this.acceptDocType = ".jpg,.jpeg,.png";
      this.uploadType = 'Image';
      this.docUplodLable = 'Image';
      this.mobileLink = 'VIEW IMAGE'
    } else if (docType == 3) {
      this.acceptDocType = ".pdf,.doc,.docx";
      this.uploadType = 'Doc';
      this.docUplodLable = 'Document';
      this.mobileLink = 'READ DOCUMENT'
    } else if (docType == 4) {
      this.uploadDocumentEnable = true;
      this.uploadType = 'Video';
      this.docUplodLable = 'Video Link';
      this.mobileLink = 'VIDEO LINK'
    } else if (docType == 5) {
      this.uploadDocumentEnable = true;
      this.uploadType = 'WebLink';
      this.docUplodLable = 'Web Link';
      this.mobileLink = 'WEB LINK'
    }
  }

  onFileChange(event) {
    //this.fileUploadAddModel.file = event.target.files[0]; 
    if (event.target.files[0]) {
      this.fileUploadAddModel.file = <File>event.target.files[0];
      this.documentLabel = event.target.files[0].name;
      this.disableDocumentType = true;
    }
    else {
      this.documentLabel = "Choose file";
      this.disableDocumentType = false;
    }

    //console.log(this.fileUploadAddModel); 
  }

  onFileChangeCover(event) {
    //this.fileUploadCoverAddModel.file = event.target.files[0]; 
    if (event.target.files[0]) {
      this.fileUploadCoverAddModel.file = <File>event.target.files[0];
      this.documentLabelCover = event.target.files[0].name;
    }
    else {
      this.documentLabelCover = "Choose file";
    }
    //console.log(this.fileUploadCoverAddModel); 
  }

  showDocumentUplade(isShowDocumentUplade) {
    this.isShowDocumentUplade = !isShowDocumentUplade;
    // if(this.isShowDocumentUplade) {
    //   this.disableDocumentType = false;
    // }else {
    //   this.disableDocumentType = true;
    // }

  }

  scheduleNewsfeed(isScheduleNewsfeed) {
    this.isScheduleNewsfeed = !isScheduleNewsfeed;
    // if(this.isShowDocumentUplade) {
    //   this.disableDocumentType = false;
    // }else {
    //   this.disableDocumentType = true;
    // }

  }

  isExpiredClick(isExpiredShow){
    this.isExpiredShow = !isExpiredShow;
  }

  showCoverImageUplade(isShowCoverImageUplade) {
    this.isShowCoverImageUplade = !isShowCoverImageUplade;
  }
  
  getDocumentTypeName(docType){
    if(docType==1){
      this.documentTypeName = 'No Select';
    }
    if (docType == 2) {
      this.documentTypeName = 'Image';
    } else if (docType == 3) {
      this.documentTypeName = 'Document';
    } else if (docType == 4) {
      this.documentTypeName = 'Video Link';
    } else if (docType == 5) {
      this.documentTypeName = 'Web Link';
    }
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    // height: '10rem',
    // minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  }

  ngbDateFormat(date) {
    if (date) {
      const [year, month, day] = date.split('-');
      const obj = { year: parseInt(year), month: parseInt(month), day: parseInt(day.split(' ')[0].trim()) };
      return obj;
    }
  }

  ngdTimeFormat(date) {
    if (date) {
      var a = date.split('T');
      var stime = a[1];
      const timesArray = stime.split(":").map((time) => +time);
      const model = {
        hour: timesArray[0],
        minute: timesArray[1],
        second: timesArray[2]
      }
      return model;
    }
  }

  msqlDateFormat(inDate) {
    var month;
    var day;
    month = ("0" + (inDate.month)).slice(-2);
    day = ("0" + (inDate.day)).slice(-2);
    return inDate.year + '-' + month + '-' + day;
  }

  convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
  
    let [hours, minutes] = time.split(':');
  
    if (hours === '12') {
      hours = '00';
    }
  
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
  
    return `${hours}:${minutes}`;
  }

  convertTime24to12(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    // var ampm = hours < 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = (hours < 10)?("0"+hours):hours;  // leading 0 at the left for 1 digit hours
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes; // var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours < 12 ? "AM" : "PM";
    return ampm;
  }

  futureDate() {
    var future = new Date();
    future.setDate(future.getDate() + 30);
    // let dd = new Date(this.newsFeedModel.expiredDate+'Z'); // (this.newsFeedModel.sheduledDate+'Z');
    var fromDate = this.datepipe.transform(future, 'yyyy-MM-ddTHH:mm:ss' );
    let date = this.ngbDateFormat(fromDate);
    return date;
  }

}
