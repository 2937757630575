import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NetworthRolbackService } from '../networth-rollback.service';

@Component({
  selector: 'app-rollback-insurance-popup',
  templateUrl: './rollback-insurance-popup.component.html',
  styleUrls: ['./rollback-insurance-popup.component.css']
})
export class RollbackInsurancePopupComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  isUnderstand =false;

  constructor(
    private networthRolbackService :NetworthRolbackService ,
    private toastr: ToastrService, 
    public modalRef: BsModalRef
  ) { }

  ngOnInit(): void {
  }

  modalRehide(){ 
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

  rollbackConfirmed(){ 
    this.networthRolbackService.rollbackInsurance().subscribe((data :any)=>{
      if(data.error && !data.success){ 
       
        //this.errors = Utility.GetLocalErrorString(data.error.errorCode);
        this.toastr.error('Error on rollback insurance.');
        //console.log(this.errors);
      }
      else{ 
        this.toastr.success('Insurance updated successfully.');
      
       this.event.emit('OK');
        this.modalRef.hide();
      }   

    });
  };

}
