import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { AgentViewModel,AgentBaseMOdel, UserAgentViewModel } from '../shared/models/agent.model';
import { AgentResponse, AgentResponse2 } from '../shared/models/response.model';
import { AgentsService } from './agents.service';
import { HttpErrorResponse } from '@angular/common/http';
// import { ClientResponse } from 'http';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddAgentComponent } from '../agents/add-agent/add-agent.component';
import { EditAgentComponent } from './edit-agent/edit-agent.component';
import { ConfirmationDialogService } from '../confirmation/confirmation.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AppConstants } from '../utility/app-constants';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AgentResetPopupComponent } from './agent-reset-popup/agent-reset-popup.component';
@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.css']
})
export class AgentsComponent implements OnInit {
  modalRef: BsModalRef;
  agentList: AgentViewModel[];  // UserAgentViewModel
  agent:AgentViewModel;
  agentsCount:number;
  agentFilterdList:AgentViewModel[];
  dtOptions: DataTables.Settings = {}; 
  isLoaded :boolean;
  errors;
  message;
  status :boolean;
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  //dtTrigger = new Subject();
  dtTrigger: Subject<any> = new Subject();
  baseUrl:String;


  constructor(private router: Router,private agentService: AgentsService,private modalService: BsModalService) { 
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
    this.baseUrl = AppConstants.getBaseURL();
  }

  ngOnInit() {
    this.getAgentList(); 
    this.dtOptions = {
      //pagingType: 'full_numbers',
      //ajax: this.baseUrl+'/FortressAdmins',
      pageLength: 10,
      processing: true,
      order : []
    };   
  }

  ngAfterViewInit(): void {
    //this.rerender();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  getAgentList(){
    this.agentService.agentList().subscribe((data:AgentResponse)=>{     
      this.agentList = data.data;
      //this.agentsCount = this.agentList.length;
      console.log(this.agentList);
      this.assignCopy();
      // console.log('Agent list--------------------------');
       console.log(this.agentList);

      this.isLoaded = true;
      

      // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //   // first destroy table
      //     dtInstance.destroy();
      //     this.dtTrigger.next();
      //    // get your data function call here
      // });

      //this.rerender();
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });      
  }

  assignCopy(){
    this.agentFilterdList = Object.assign([], this.agentList);
  }

  openModal() {
    
    this.modalRef = this.modalService.show(AddAgentComponent,  {
      initialState: {
        title: 'Add Agent',
        data: {},
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      
      if (result == 'OK') {
        //console.log("Success");
        this.router.navigate(['agents']);
         // this.getAgentList();
      
      }

    });
  }
    
  openEditModal(agent:any) {  
    //console.log(agent);  
    this.modalRef = this.modalService.show(EditAgentComponent,  {
      initialState: {
        title: 'Edit Agent',
        data: {
          agent:agent
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        this.router.navigate(['agents']);
        //  this.getAgentList();
      
      }
    });
  }

  openChangePasswordModal(agent:any) {
    
    this.modalRef = this.modalService.show(ChangePasswordComponent,  {
      initialState: {
        title: 'Change Password',
        data: {
          agent:agent
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      
      if (result == 'OK') {
        //console.log("Success");
        this.router.navigate(['agents']);
         // this.getAgentList();
      
      }

    });
  }

  onCheckboxChange(id:string){
    //console.log(id);
    // this.agentService.agentStatusChange().subscribe((data:AgentResponse)=>{     
      
    //   },
    //   (err: HttpErrorResponse)=>{
    //     console.log("Error"+ err.message);
    //   }); 
  }

  public openConfirmationDialog(template: TemplateRef<any>,agent:AgentViewModel,status:boolean) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    //this.idToBeDeleted = id;

    //let message = '';
    this.agent = agent;
    this.status = !status;
    if(status){
      this.message = 'Are you sure that you want to enable user?'
    }
    else{
      this.message = 'Are you sure that you want to disable user?'
    }

    //console.log(this.message);
    //console.log(this.status);
    //console.log(agent);

    // this.confirmationDialogService.confirm('Please confirm', message)
    // .then((confirmed) => {
    //   console.log('User confirmed:', confirmed)
    // })
    // .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  confirm(agent,status): void {
    //this.message = 'Confirmed!';
    this.modalRef.hide();
    agent.isArchived = status;
    // console.log(agent.isArchived);
    // console.log(this.status);

    this.agentService.enableDisable(agent.id, status).subscribe(()=>{     
      // this.agentList = data.response;
      // this.agentsCount = this.agentList.length;
      // this.assignCopy();
      // console.log('Agent list--------------------------');
      // console.log(this.agentList);

      //this.getAgentList();
      this.router.navigate(['agents']);
      
      // this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
    
    //this.delete();
  }


  decline(agent,status): void {
    //this.message = 'Declined!';
    this.modalRef.hide();
    //agent.fortressAdmin.isArchived = !status;
    //console.log(this.status);
  }

  ResetUser(selectedid){   
  
    this.modalRef = this.modalService.show(AgentResetPopupComponent,  {
      initialState: {
        title: 'View Pop up',
        data: {
         userid:selectedid
        },      
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
      },
      class: "modal-s"
      
    });
  
    // this.modalRef.content.event.subscribe(result => {
    //   if (result == 'OK') {
      
    //   }
    // });
  }
  
}
