import { Component, OnInit, TemplateRef } from '@angular/core';
import { UserVerifyService } from './user-verify.service';
import { UnVerifiedUsersResponse } from '../shared/models/response.model';
import { UnVerifiedUsersListModel } from '../shared/models/un-verified-users.model';
// import { HttpErrorResponse } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { AppConstants } from '../utility/app-constants';
import { UserVerify } from '../shared/models/user-verify.model';
import { CookieService } from 'ngx-cookie-service';
import { AddUserVerifyComponent } from './add-user-verify/add-user-verify.component';

@Component({
  selector: 'app-user-verify',
  templateUrl: './user-verify.component.html',
  styleUrls: ['./user-verify.component.css']
})
export class UserVerifyComponent implements OnInit {
  unVerifiedUsersList: UnVerifiedUsersListModel[];
  isLoaded :boolean;
  userVerify:UserVerify;
  modalRef: BsModalRef;
  message;
  user_id: string;
  status: boolean;
  dtOptions: DataTables.Settings = {}; 
  baseUrl:String;

  constructor(
    private router: Router,
    private UserVerifyService: UserVerifyService, 
    private modalService: BsModalService, 
    private toastr: ToastrService,
    private http:HttpClient,
    private cookieService: CookieService
    ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
    this.baseUrl = AppConstants.getBaseURL();
   }

  ngOnInit() {
    //this.getUnVerifiedUserList();
    // this.dtOptions = {
    //   //pagingType: 'full_numbers',
    //   pageLength: 10,
    //   processing: true,
    //   order : []
    // };   
    this.userVerify = new UserVerify();
    const that = this;

   // console.log(2589632147852);

    this.dtOptions = {
      //pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {

        //console.log(78945612);
        //console.log(dataTablesParameters);
        this.userVerify.userId = this.cookieService.get('CurrentUserId'); //JSON.parse(localStorage.getItem('CurrentUserId'));
        this.userVerify.companyCode = this.cookieService.get('companyCode');

        if(dataTablesParameters.start){
          //console.log(dataTablesParameters.start);
          this.userVerify.skip = dataTablesParameters.start;
          //params = params.set('skip',dataTablesParameters.start);
        }
        if(!dataTablesParameters.start){
          //console.log(dataTablesParameters.start);
          this.userVerify.skip = 0;
        }
    
        if(dataTablesParameters.length){
          //console.log(dataTablesParameters.length);
          this.userVerify.take = dataTablesParameters.length;
          //params = params.set('take',dataTablesParameters.length);
        }
        if(dataTablesParameters.search && dataTablesParameters.search.value){
          //console.log(dataTablesParameters.search.value);
          this.userVerify.searchValue = dataTablesParameters.search.value;
          //params = params.set('searchValue',dataTablesParameters.search.value);
        }
        else{
          this.userVerify.searchValue = "";
        }

        let id = this.cookieService.get('CurrentUserId'); //JSON.parse(localStorage.getItem('CurrentUserId'));
        this.UserVerifyService.unVerifiedUserList(this.userVerify).subscribe((data:UnVerifiedUsersResponse)=>{    
          if(data.success) {
            this.unVerifiedUsersList = data.response.data;   
            this.isLoaded = true;
          }
          if(!data.success){
            this.unVerifiedUsersList = [];
            this.isLoaded = true;
          }
             
          //console.log('Un Verified Users list ---------');
          //console.log(this.unVerifiedUsersList);
          
          callback({
            //recordsTotal: resp.recordsTotal,
            //recordsFiltered: resp.recordsFiltered,
            recordsTotal: (data.success)?data.response.totalRecoreds:0,
            recordsFiltered: (data.success)?data.response.data.length:0,
            data: []
          });

          //this.isLoaded = true;      

          },
          (err: HttpErrorResponse)=>{
            //console.log("Error"+ err.message);
          });

        // that.http.post<UnVerifiedUsersResponse>(
        //     this.baseUrl+'/User/UnVerifiedUsers',
        //     dataTablesParameters, {}
        //   ).subscribe(resp => {
        //     //that.persons = resp.data;
        //     this.unVerifiedUsersList = resp.response; 
        //     callback({
        //       //recordsTotal: resp.recordsTotal,
        //       //recordsFiltered: resp.recordsFiltered,
        //       recordsTotal: 100,
        //       recordsFiltered: 30,
        //       data: []
        //     });
        //   });

      },
      columns: [],
      order : []
    };

  }

  // getUnVerifiedUserList(){
  //   let id = JSON.parse(localStorage.getItem('CurrentUserId'));
    
  //   this.UserVerifyService.unVerifiedUserList(id).subscribe((data:UnVerifiedUsersResponse)=>{     
  //     this.unVerifiedUsersList = data.response;      
  //     //console.log('Un Verified Users list ---------');
  //     //console.log(this.unVerifiedUsersList);
  //     this.isLoaded = true;
  //     },
  //     (err: HttpErrorResponse)=>{
  //       //console.log("Error"+ err.message);
  //     });      
  // }

  userVerifi(user_id): void {
    this.modalRef.hide();
    //user.fortressAdmin.isArchived = status;
    //console.log(user.fortressAdmin.isArchived);
  
    this.UserVerifyService.verifyUser(user_id).subscribe(()=>{     
      //this.getUnVerifiedUserList();
      this.toastr.success('User approved successfully.');
      this.router.navigate(['un-verified-users/view']);
    },
    (err: HttpErrorResponse)=>{
      //console.log("Error"+ err.message);
      this.toastr.error('Error on saving approve user.');
    });  
  }

  public openConfirmationDialog(template: TemplateRef<any>,id:string) {

    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    
    this.user_id = id;
    this.message = 'Are you sure that you want to approve user?'
    
    //console.log(this.message);
    //console.log(id);
  }

  decline(user_id): void {
    this.modalRef.hide();
    
  }

  openModal(user) {
    console.log(user);
    this.modalRef = this.modalService.show(AddUserVerifyComponent,  {
      initialState: {
        title: 'Client Account Approval',
        data: {
          user : user,   
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-md"
    });
  
    this.modalRef.content.event.subscribe(result => {
      
      if (result == 'OK') {
        //console.log("Success");
        this.router.navigate(['un-verified-users/view']);
         // this.getAgentList();
      
      }

    });
  }
 
}


