<div class="modal-header a_modal">
    <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span
            aria-hidden="true">&times;</span> </button>
</div>

 
    <div class="modal-body d-flex justify-content-center flex-column">
        <img src="../../../assets/warning.png" class="w-30 mx-auto" />
        <p class="text-center mt-1">You are about to <b>reset </b>Multi-Factor Authentication for this user. Do
            you really want to
            <span class="continue-txt">continue</span>?
        </p>
        <!-- <div class="d-flex flex-row align-items-center justify-content-center">
            <input type="checkbox" class="form-check-input" id="exampleCheck1">
            <p class="mb-0">Yes, I understand the risk</p>
        </div> -->

        <div class="form-check text-center">
            <input class="form-check-input" type="checkbox"  id="defaultCheck1" [(ngModel)]="isUnderstand">
            <label class="form-check-label" for="defaultCheck1">
                Yes, I understand the risk
            </label>
           
            <!-- <input type="checkbox" [(ngModel)]="isUnderstand">I agree


  <input type="button" [disabled]="!isUnderstand" text="go" value="go" (click)="btnClick()"> -->
        </div>
    </div>

    <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="btn btn-outline-dark px-3"  (click)="modalRehide()">No</button>
        <button type="button" class="btn btn-outline-dark red-outline px-3"   [disabled]="!isUnderstand"  (click)="resetConfirmed()">Yes </button>
    </div>
 

<!-- <ngx-ui-loader></ngx-ui-loader> -->