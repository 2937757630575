import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ViewChild, ViewContainerRef, ComponentRef, ComponentFactory, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { Globals } from './utility/globals';
import { ContainerComponent } from './container/container.component';
import { ChartsModule } from 'ng2-charts';
import { SendCodeComponent } from './auth/send-code/send-code.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CameraComponent } from './video/camera/camera.component';
import { HomeComponent } from './video/home/home.component';
import { ParticipantsComponent } from './video/participants/participants.component';
import { RoomsComponent } from './video/rooms/rooms.component';
import { SettingsComponent } from './video/settings/settings.component';
import { DeviceSelectComponent } from './video/settings/device-select.component';
import { CallComponent } from './video/call/call.component';
import { ClientsComponent } from './clients/clients.component';
import { GroupsComponent } from './groups/groups.component';
import { NewsFeedComponent } from './news-feed/news-feed.component';
import { ProfileComponent } from './profile/profile.component';
//import { AccessverifyComponent } from './auth/accessverify/accessverify/accessverify.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { AgentsComponent } from './agents/agents.component';
import { AddAgentComponent } from './agents/add-agent/add-agent.component';
import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EditAgentComponent } from './agents/edit-agent/edit-agent.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { AddInvoiceComponent } from './invoices/add-invoice/add-invoice.component';
import { EditInvoiceComponent } from './invoices/edit-invoice/edit-invoice.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { AddAppointmentComponent } from './appointments/add-appointment/add-appointment.component';
import { EditAppointmentComponent } from './appointments/edit-appointment/edit-appointment.component';
// import * as moment from "moment";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AddNewsFeedComponent } from './news-feed/add-news-feed/add-news-feed.component';
import { EditNewsFeedComponent } from './news-feed/edit-news-feed/edit-news-feed.component';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './shared/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { NotificationComponent } from './notification/notification.component';
import { MessageCenterComponent } from './message-center/message-center.component';
import { ChatComponent } from './chat/chat.component';
import { ChatModule } from '@progress/kendo-angular-conversational-ui';

// import { Component } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { StartConversationComponent } from './message-center/start-conversation/start-conversation.component';
// import { ComponentFactoryResolver } from '@angular/core/src/render3';
import { ViewNewsFeedComponent } from './news-feed/view-news-feed/view-news-feed.component';
import { UserVerifyComponent } from './user-verify/user-verify.component';
import { FilterPipe } from './shared/filter.pipe';
import { PasswordResetComponent } from './auth/password-reset/password-reset.component';
import { FilterdataPipe } from './filterdata.pipe';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { UserDocumentsComponent } from './user-documents/user-documents.component';
import { AddUserDocumentsComponent } from './user-documents/add-user-documents/add-user-documents.component';
import { LottieAnimationViewModule } from 'ng-lottie';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { ViewInvoiceComponent } from './invoices/view-invoice/view-invoice.component';
import { FactFinderComponent } from './fact-finder/fact-finder.component';
import { TruncatePipe } from './utility/limitTo';
import { SendFileComponent } from './chat/send-file/send-file.component';
//import { JwtModule } from '@auth0/angular-jwt';
//import { AppConstants } from './utility/app-constants';

import { JwtInterceptorService } from './shared/jwt-interceptor.service';
import { ErrorInterceptor } from './shared/error.interceptor';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { CookieService } from 'ngx-cookie-service';
import { Ng2CompleterModule } from "ng2-completer";
import { BusinessesComponent } from './businesses/businesses.component';
import { PermissionGroupsComponent } from './permission-groups/permission-groups.component';
import { CompaniesComponent } from './companies/companies.component';
import { AddPermissionGroupsComponent } from './permission-groups/add-permission-groups/add-permission-groups.component';
import { EditPermissionGroupsComponent } from './permission-groups/edit-permission-groups/edit-permission-groups.component';
import { EditCompaniesComponent } from './companies/edit-companies/edit-companies.component';
import { AddCompaniesComponent } from './companies/add-companies/add-companies.component';
import { ViewFactComponent } from './profile/view-fact/view-fact.component';
import { EmailSetupComponent } from './email-setup/email-setup.component';
import { DepartmentListComponent } from './email-setup/department-list/department-list.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { PermissionListComponent } from './permission-groups/permission-list/permission-list.component';
import { FactFinderV2Component } from './fact-finder-v2/fact-finder-v2.component';
import { AddContactDetailsComponent } from './fact-finder-v2/add-contact-details/add-contact-details.component';
import { AddAddressComponent } from './fact-finder-v2/add-address/add-address.component';
import { AddDependantComponent } from './fact-finder-v2/add-dependant/add-dependant.component';
import { AddEmploymentComponent } from './fact-finder-v2/add-employment/add-employment.component';
import { AddEstateDetailsComponent } from './fact-finder-v2/add-estate-details/add-estate-details.component';
import { AddGoalComponent } from './fact-finder-v2/add-goal/add-goal.component';
import { AddIncomeComponent } from './fact-finder-v2/add-income/add-income.component';
import { AddExpensesComponent } from './fact-finder-v2/add-expenses/add-expenses.component';
import { AddInsuranceComponent } from './fact-finder-v2/add-insurance/add-insurance.component';
import { AddCoversComponent } from './fact-finder-v2/add-covers/add-covers.component';
import { AddAssetsComponent } from './fact-finder-v2/add-assets/add-assets.component';
import { AddLiabilitiesComponent } from './fact-finder-v2/add-liabilities/add-liabilities.component';
import { AddRetirementIncomeComponent } from './fact-finder-v2/add-retirement-income/add-retirement-income.component';
import { AddMedicalInsuranceComponent } from './fact-finder-v2/add-medical-insurance/add-medical-insurance.component';
import { AddFactFinderVersionComponent } from './fact-finder-v2/add-fact-finder-version/add-fact-finder-version.component';
import { DownloadPdfComponent } from './fact-finder-v2/download-pdf/download-pdf.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { AddUserVerifyComponent } from './user-verify/add-user-verify/add-user-verify.component';
import { AddClientComponent } from './clients/add-client/add-client.component';
import { AddPrimaryMobileComponent } from './profile/add-primary-mobile/add-primary-mobile.component';
import { AddUserNameComponent } from './profile/add-user-name/add-user-name.component';
import { ChangePasswordComponent } from './agents/change-password/change-password.component';
import { AudienceComponent } from './audience/audience.component';
import { ViewAudienceComponent } from './audience/view-audience/view-audience.component';
import { CreateAudienceComponent } from './audience/create-audience/create-audience.component';
import { AddPeopleComponent } from './audience/view-audience/add-people/add-people.component';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { QuillModule } from 'ngx-quill';
import { RichTextEditorModule } from "./rich-text-editor/rich-text-editor.module";
import * as Emoji from "quill-emoji";
import { NetworthHistoryComponent } from './networth-history/networth-history.component';
import { ImportClientsComponent } from './audience/view-audience/import-clients/import-clients.component';
import { DatePipe } from '@angular/common';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { RollbackDataComponent } from './rollback-data/rollback-data.component';
import { RollbackPopupComponent } from './rollback-data/rollback-popup/rollback-popup.component';
import { ViewNewsfeeReadClientsComponent } from './news-feed/view-news-feed/view-newsfee-read-clients/view-newsfee-read-clients.component';
import { PaginationModule, PaginationConfig } from 'ngx-bootstrap/pagination';
import { NewServiceAgreementComponent } from './profile/new-service-agreement/new-service-agreement.component';
import { ServiceAgreementComponent } from './service-agreement/service-agreement.component';
import { NetworthUpdateWidgetComponent } from './networth-history/networth-update-widget/networth-update-widget.component';
import { ServiceAgreementInProfileComponent } from './profile/service-agreement-in-profile/service-agreement-in-profile.component';
import { MaintenaceModeComponent } from './maintenace-mode/maintenace-mode.component';
import { ViewDocumentComponent } from './service-agreement/view-document/view-document.component';
import { ServiceAgreementUpdateWidgetComponent } from './profile/new-service-agreement/service-agreement-update-widget/service-agreement-update-widget.component';
import { ViewServiceAgreementComponent } from './service-agreement/view-service-agreement/view-service-agreement.component';

import { AdvisorNameUpdateWidgetComponent } from './profile/new-service-agreement/advisor-name-update-widget/advisor-name-update-widget.component';
import { AdvisorEmailUpdateWidgetComponent } from './profile/new-service-agreement/advisor-email-update-widget/advisor-email-update-widget.component'; // Pagination

import { SafeHtmlPipe } from './shared/safe-html.pipe';
import { PreviewComponent } from './news-feed/preview/preview.component';
import { UserHistoryComponent } from './profile/user-history/user-history.component';
import { EditServiceAgreementComponent } from './service-agreement/edit-service-agreement/edit-service-agreement.component';
import { FamilyTreeComponent } from './profile/family-tree/family-tree.component';
import { FamilyNodePipe } from './profile/family-tree/family-node.pipe'; // Pagination
import { SyncMonitoringComponent } from './sync-monitoring/sync-monitoring.component'; // Pagination
import { ViewErrorLogComponent } from './sync-monitoring/view-error-log/view-error-log.component';
import { SafeUrlPipe } from './shared/safe-url.pipe';
import { CloseDocusignComponent } from './profile/new-service-agreement/close-docusign/close-docusign.component';
import { RollbackInsurancePopupComponent } from './rollback-data/rollback-insurance-popup/rollback-insurance-popup.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FamilyTreePdfComponent } from './profile/family-tree/family-tree-pdf/family-tree-pdf.component';
import { UnallocatedAccountsComponent } from './unallocated-accounts/unallocated-accounts.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { AgentResetPopupComponent } from './agents/agent-reset-popup/agent-reset-popup.component';
import { SignAppUpdateWidget } from './profile/new-service-agreement/sign-app-update-widget/sign-app-update-widget';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  overlayColor: 'rgba(255,255,255,.66)'
};
//const baseUrl = AppConstants.getBaseURL().toString();

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    ContainerComponent,
    SendCodeComponent,
    CameraComponent,
    HomeComponent,
    ParticipantsComponent,
    RoomsComponent,
    SettingsComponent,
    DeviceSelectComponent,
    CallComponent,
    ClientsComponent,
    GroupsComponent,
    NewsFeedComponent,
    ProfileComponent,

    NotificationComponent,
    MessageCenterComponent,
    ChatComponent,

    AgentsComponent,
    AddAgentComponent,
    EditAgentComponent,
    ConfirmationComponent,
    InvoicesComponent,
    AddInvoiceComponent,
    EditInvoiceComponent,
    AppointmentsComponent,
    AddAppointmentComponent,

    EditAppointmentComponent,
    DateTimePickerComponent,
    AddNewsFeedComponent,
    EditNewsFeedComponent,
    FileUploadComponent,
    StartConversationComponent,
    ViewNewsFeedComponent,
    UserVerifyComponent,
    FilterPipe,
    PasswordResetComponent,
    FilterdataPipe,
    UserDocumentsComponent,
    AddUserDocumentsComponent,
    ViewInvoiceComponent,
    FactFinderComponent,
    TruncatePipe,
    SendFileComponent,
    EditProfileComponent,
    BusinessesComponent,
    PermissionGroupsComponent,
    CompaniesComponent,
    AddPermissionGroupsComponent,
    EditPermissionGroupsComponent,
    EditCompaniesComponent,
    AddCompaniesComponent,
    ViewFactComponent,
    EmailSetupComponent,
    DepartmentListComponent,
    PermissionListComponent,
    FactFinderV2Component,
    AddContactDetailsComponent,
    AddAddressComponent,
    AddDependantComponent,
    AddEmploymentComponent,
    AddEstateDetailsComponent,
    AddGoalComponent,
    AddIncomeComponent,
    AddExpensesComponent,
    AddInsuranceComponent,
    AddCoversComponent,
    AddAssetsComponent,
    AddLiabilitiesComponent,
    AddRetirementIncomeComponent,
    AddMedicalInsuranceComponent,
    AddFactFinderVersionComponent,
    DownloadPdfComponent,
    AddUserVerifyComponent,
    AddClientComponent,
    AddPrimaryMobileComponent,
    AddUserNameComponent,
    ChangePasswordComponent,
    AudienceComponent,
    ViewAudienceComponent,
    CreateAudienceComponent,
    AddPeopleComponent,
    NetworthHistoryComponent,
    ImportClientsComponent,
    RollbackDataComponent,
    RollbackPopupComponent,
    ViewNewsfeeReadClientsComponent,
    NewServiceAgreementComponent,
    ServiceAgreementComponent,
    NetworthUpdateWidgetComponent,
    ServiceAgreementInProfileComponent,
    MaintenaceModeComponent,
    ViewDocumentComponent,
    ServiceAgreementUpdateWidgetComponent,
    ViewServiceAgreementComponent,

    AdvisorNameUpdateWidgetComponent,
    AdvisorEmailUpdateWidgetComponent,
    SafeHtmlPipe,
    PreviewComponent,
    UserHistoryComponent,
    EditServiceAgreementComponent,
    FamilyTreeComponent,
    FamilyNodePipe,
    SyncMonitoringComponent,
    ViewErrorLogComponent,
    SafeUrlPipe,
    CloseDocusignComponent,
    SignAppUpdateWidget,
    RollbackInsurancePopupComponent,
    FamilyTreePdfComponent,
    UnallocatedAccountsComponent,
    AgentResetPopupComponent
    //AccessverifyComponent
  ],
  imports: [
    BrowserModule,
    NgxPaginationModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    ChartsModule,
    BrowserAnimationsModule,
    PaginationModule, // Pagination

    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    ChatModule,

    NgbModule,
    DataTablesModule,

    ModalModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,  //ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentRef, ComponentFactory
    FontAwesomeModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    LottieAnimationViewModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    Ng2CompleterModule,
    AngularEditorModule,
    DragDropModule,
    AngularMultiSelectModule,


    PDFExportModule,
    // QuillModule.forRoot(),
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],

          [{ list: "ordered" }, { list: "bullet" }],


          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],
          ['link', 'image'],
          ['emoji']
        ],
        "emoji-toolbar": true,
        "emoji-textarea": true,
        "emoji-shortname": true,
      }
    }),

    RichTextEditorModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule
    //NgxUiLoaderRouterModule
    // JwtModule.forRoot({
    //   config: {
    //     tokenGetter: function  tokenGetter() {
    //          return localStorage.getItem('currentUserToken');
    //         },
    //     whitelistedDomains: [baseUrl],
    //     blacklistedRoutes: [
    //       baseUrl + '/Token/Login',
    //       baseUrl + '/Token/VeryfyAccessCode',
    //       baseUrl + '/AppInstallation',
    //       baseUrl + '/Token/Logout',
    //       baseUrl + '/User/ForgetPassword'
    //     ]
    //   }
    // }),
  ],
  exports: [ RollbackPopupComponent ],
  providers: [
    Globals, MessagingService, AsyncPipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    BsModalService, BsModalRef,
    CookieService, DatePipe,
    PaginationConfig
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddAgentComponent,
    EditAgentComponent,
    AddInvoiceComponent,
    EditInvoiceComponent,
    AddAppointmentComponent,
    EditAppointmentComponent,
    CallComponent,
    AddNewsFeedComponent,
    StartConversationComponent,
    ViewNewsFeedComponent,
    ChatComponent,
    AddUserDocumentsComponent,
    SendFileComponent,
    AddPermissionGroupsComponent,
    EditPermissionGroupsComponent,
    EditCompaniesComponent,
    AddCompaniesComponent,
    ViewFactComponent,
    EmailSetupComponent,
    AddContactDetailsComponent,
    AddAddressComponent,
    AddDependantComponent,
    AddEmploymentComponent,
    AddEstateDetailsComponent,
    AddGoalComponent,
    AddIncomeComponent,
    AddExpensesComponent,
    AddInsuranceComponent,
    AddCoversComponent,
    AddAssetsComponent,
    AddLiabilitiesComponent,
    AddRetirementIncomeComponent,
    AddMedicalInsuranceComponent,
    AddFactFinderVersionComponent,
    DownloadPdfComponent,
    AddUserVerifyComponent,
    AddClientComponent,
    AddPrimaryMobileComponent,
    AddUserNameComponent,
    ChangePasswordComponent,
    CreateAudienceComponent,
    AddPeopleComponent,
    EditNewsFeedComponent,
    ImportClientsComponent,
    RollbackPopupComponent,
    ViewNewsfeeReadClientsComponent,
    NewServiceAgreementComponent,
    ViewDocumentComponent,
    ViewServiceAgreementComponent,
    UserHistoryComponent,
    ViewErrorLogComponent,
    RollbackInsurancePopupComponent,
    FamilyTreePdfComponent,
    AgentResetPopupComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
