<!-- <div class="modal-header a_modal">
          <h4 class="modal-title" id="myModalLabel1">{{title}}</h4>
          <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
      </div> -->
<div class="modal-header a_modal">
  <h4 class="modal-title" id="myModalLabel1">New News Feed</h4>
  <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span
      aria-hidden="true">&times;</span> </button>
</div>

<form #f="ngForm" novalidate class="form" enctype="multipart/form-data" (ngSubmit)="onSubmit(f)">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-8 form-scroll">

        <div class="form-body">
          <div class="row">

            <!-- <div class="col-md-12">
                                        <div class="form-group">                                      
                                            <label for="projectinput1" class="c_n_fs">Clients *</label>
                                            <select multiple="true" required class="form-control" name="newsRecipients" [(ngModel)]='newsFeedModel.newsRecipients' #newsRecipients = "ngModel">
                                                <option *ngFor="let client of clientList"  [value]="client.id">                                       
                                                  <ng-container *ngIf="client.client != null">
                                                      {{client.client.firstName}} {{client.client.lastName}}
                                                    </ng-container>
                                                    <ng-container *ngIf="client.client == null">
                                                        {{client.userName}}
                                                      </ng-container>
                                                </option>
                                            </select>
      
                                            <div class="form-group">
                                                <div *ngIf="newsRecipients.invalid && (newsRecipients.dirty || newsRecipients.touched)"
                                                    class="alert alert-danger">
                                                  <div *ngIf="newsRecipients.errors.required">
                                                    Please select a user.
                                                  </div>
                                                  <div *ngIf="newsRecipients.errors.minlength">
                                                    First name must be at least 4 characters long.
                                                  </div>
                                                  <div *ngIf="newsRecipients.errors.forbiddenName">
                                                    First name cannot be Bob.
                                                  </div>
                                                </div>
                                              </div>
      
                                        </div>
                                    </div> -->

            <div class="col-md-12">
              <div class="form-group">
                <!-- <label for="projectinput8" class="c_n_fs">Title *</label> -->
                <label for="projectinput8" class="c_n_fs">Title *</label><span
                  class="float-right word-count">{{newsFeedModel.title.length}}/{{maxChars}}</span>
                <input type="text" [maxlength]="maxChars" id="title" required class="form-control" name="title"
                  [(ngModel)]="newsFeedModel.title" #title="ngModel" placeholder="Title">
                <!-- <input id="summary" required  type="text"  class="form-control" name="summary" placeholder="" [(ngModel)]="newsFeedModel.summary" #summary = "ngModel"> -->

                <div class="form-group">
                  <div *ngIf="title.invalid && (title.dirty || title.touched)" class="form_alert">
                    <span class="ft-alert-circle"></span>
                    <div *ngIf="title.errors.required">
                      Title is required.
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="projectinput8" class="c_n_fs">Description *</label>
                <!-- <angular-editor [config]="editorConfig"></angular-editor> -->

                <quill-editor required [placeholder]="'Description...'" [(ngModel)]="newsFeedModel.message"
                  #message="ngModel" id="message" name="message"></quill-editor>



                <!-- <angular-editor required [placeholder]="'Description...'" [(ngModel)]="newsFeedModel.message"
                        #message="ngModel" [config]="editorConfig" id="message" name="message"></angular-editor> -->

                <!-- <textarea style="resize: none;" id="message" required rows="4" maxlength="200" class="form-control c_n_form" name="message" [(ngModel)]="newsFeedModel.message" #message = "ngModel" placeholder="Description"></textarea> -->
                <!-- <input id="summary" required  type="text"  class="form-control" name="summary" placeholder="" [(ngModel)]="newsFeedModel.summary" #summary = "ngModel"> -->

                <div class="form-group">
                  <div *ngIf="message.invalid && (message.dirty || message.touched)" class="form_alert">
                    <span class="ft-alert-circle"></span>
                    <div *ngIf="message.errors.required">
                      Description is required.
                    </div>
                  </div>
                </div>

              </div>
            </div>


            <div class="col-md-6">
              <div class="form-group">
                <label for="documentType" class="c_n_fs">Attachment Type *</label>
                <select [disabled]="disableDocumentType" id="documentType" required
                  (change)="onDocumentTypeChange($event.target.value)" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]='newsFeedModel.documentType' #documentType="ngModel" name="documentType"
                  class="form-control com_drop" data-toggle="tooltip" data-trigger="hover" data-placement="top"
                  data-title="Priority" data-original-title="" title="" placeholder="Please select">
                  <option *ngFor="let doct of documentType1" [value]="doct.id">
                    {{doct.value}}
                  </option>
                </select>

                <div class="form-group">
                  <div *ngIf="documentType.invalid && (documentType.dirty || documentType.touched)" class="form_alert">
                    <span class="ft-alert-circle"></span>
                    <div *ngIf="documentType.errors.required">
                      Document type is required.
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group" *ngIf="uploadType == 'Video' || uploadType == 'WebLink'">
                <ng-container *ngIf="uploadType=='Video'">
                  <label for="projectinput8" class="c_n_fs">Enter Video Link*</label>
                </ng-container>
                <ng-container *ngIf="uploadType=='WebLink'">
                  <label for="projectinput8" class="c_n_fs">Enter Web Link*</label>
                </ng-container>

                <input type="url" maxlength="100" id="documentURL" name="documentURL"
                  [(ngModel)]="newsFeedModel.documentURL" #documentURL="ngModel" required
                  pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
                  class="form-control" placeholder="Type link">
                <!-- pattern="https?://.+" -->
                <!-- <input id="summary" required  type="text"  class="form-control" name="summary" placeholder="" [(ngModel)]="newsFeedModel.summary" #summary = "ngModel"> -->

                <div class="form-group">
                  <div *ngIf="documentURL.invalid && (documentURL.dirty || documentURL.touched)" class="form_alert">
                    <span class="ft-alert-circle"></span>
                    <div *ngIf="documentURL.errors.required">
                      {{docUplodLable}} is required.
                    </div>
                    <div *ngIf="documentURL.errors.pattern">
                      Please enter valid url.
                    </div>
                  </div>
                </div>
              </div>

              <fieldset class="form-group" *ngIf="uploadType == 'Doc' || uploadType == 'Image'">
                <ng-container *ngIf="uploadType=='Doc'">
                  <label for="projectinput8" class="c_n_fs">Upload Document*</label>
                </ng-container>
                <ng-container *ngIf="uploadType=='Image'">
                  <label for="projectinput8" class="c_n_fs">Upload Image*</label>
                </ng-container>

                <div class="custom-file">
                  <input required name="document" [disabled]="uploadDocumentEnable" accept="{{acceptDocType}}"
                    (change)="onFileChange($event)" type="file" [(ngModel)]="newsFeedModel.document" #document="ngModel"
                    id="inputGroupFile01" class="custom-file-input">
                  <label class="custom-file-label" for="inputGroupFile01">{{documentLabel}}</label>
                </div>

                <div class="form-group">
                  <div *ngIf="document.invalid && (document.dirty || document.touched)" class="form_alert">
                    <span class="ft-alert-circle"></span>
                    <div *ngIf="document.errors.required">
                      {{docUplodLable}} is required.
                    </div>
                  </div>
                </div>
                <!-- <p>Perfect cover size 1000&times;500 pixels</p> -->
              </fieldset>

            </div>

            <div class="col-md-6">
              <fieldset class="form-group">
                <label for="projectinput8" class="c_n_fs">Feed Cover Image *</label>
                <div class="custom-file">
                  <input required name="coverImageurl" accept="image/jpg,image/jpeg,image/x-png" type="file"
                    (change)="onFileChangeCover($event)" [(ngModel)]="newsFeedModel.coverImageurl"
                    #coverImageurl="ngModel" id="inputGroupFile02" class="custom-file-input">
                  <label class="custom-file-label" for="inputGroupFile02">{{documentLabelCover}}</label>
                </div>

                <div class="form-group">
                  <div *ngIf="coverImageurl.invalid && (coverImageurl.dirty || coverImageurl.touched)"
                    class="form_alert">
                    <span class="ft-alert-circle"></span>
                    <div *ngIf="coverImageurl.errors.required">
                      Cover image is required.
                    </div>
                  </div>
                </div>
                <p class="mb-0">Perfect cover size 1000&times;500 pixels</p>
              </fieldset>

            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="audinceID" class="c_n_fs">Audience *</label>
                <select id="audinceID" name="audinceID" [(ngModel)]="newsFeedModel.audinceID" #audinceID="ngModel"
                  required class="form-control com_drop" data-toggle="tooltip" data-trigger="hover" data-placement="top"
                  data-title="Priority" data-original-title="" title="" placeholder="Please select">
                  <option value=null>please select</option>
                  <ng-container *ngFor="let audience of audienceList">
                    <option *ngIf="!audience.isArchived && audience.isEnabled" [value]="audience.id">
                      {{audience.name}}
                    </option>
                  </ng-container>
                </select>

                <div class="form-group">
                  <div *ngIf="audinceID.invalid && (audinceID.dirty || audinceID.touched)" class="form_alert">
                    <span class="ft-alert-circle"></span>
                    <div *ngIf="audinceID.errors.required">
                      Audience is required.
                    </div>
                  </div>
                </div>

              </div>
            </div>


            <div class="col-md-6 d-flex flex-row justify-content-between align-items-center mt-1">
              <p class="schedule-text mb-0">Do you want to schedule this News feed? </p>

              <i class="la" (click)="scheduleNewsfeed(isScheduleNewsfeed)"
                [ngClass]="[isScheduleNewsfeed ? 'la-toggle-on abouticonon' : 'la-toggle-off abouticonoff']"></i>
            </div>
            <div class="col-md-6"></div>

            <div class="col-md-6" *ngIf="isScheduleNewsfeed">
              <div class="form-group">
                <label for="" class="c_n_fs">Schedule Date *</label>
                <div class="input-group date-picker">
                  <input required class="form-control border-right-0" placeholder="yyyy-mm-dd" name="date" id="date"
                    [(ngModel)]="newsFeedModel.date" ngbDatepicker #date="ngbDatepicker" readonly [minDate]="minDate">
                  <div class="input-group-append">
                    <button class="btn calendar border-left-0" (click)="date.toggle()" type="button"></button>
                  </div>
                  <div class="form-group">
                    <div *ngIf="date.invalid && (date.dirty || date.touched)" class="form_alert">
                      <span class="ft-alert-circle"></span>
                      <div *ngIf="date.errors.required">
                        Date is required.
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-md-6" *ngIf="isScheduleNewsfeed">
              <div class="form-group">
                <label for="" class="c_n_fs">Time *</label>
                <!-- <ngb-timepicker required name="time" id="time" [(ngModel)]="newsFeedModel.time" #time="ngModel">
                </ngb-timepicker> -->
                <!-- <div class="form-group">
                  <div *ngIf="time.invalid && (time.dirty || time.touched)" class="form_alert">
                    <span class="ft-alert-circle"></span>
                    <div *ngIf="time.errors.required">
                      Time is required.
                    </div>
                  </div>
                </div> -->

                <div class="d-flex flex-row">
                  <select class="form-control com_drop time-change" data-toggle="tooltip" data-trigger="hover"
                    data-placement="top" data-title="Priority" data-original-title="" title="" placeholder="01:00"
                    name="time" id="time" [(ngModel)]="newsFeedModel.time" #time="ngModel">
                    <option value="01:00">01:00</option>
                    <option value="02:00">02:00</option>
                    <option value="03:00">03:00</option>
                    <option value="04:00">04:00</option>
                    <option value="05:00">05:00</option>
                    <option value="06:00">06:00</option>
                    <option value="07:00">07:00</option>
                    <option value="08:00">08:00</option>
                    <option value="09:00">09:00</option>
                    <option value="010:00">10:00</option>
                    <option value="11:00">11:00</option>
                    <option value="12:00">12:00</option>
                  </select>
                  <div class="form-group">
                    <div *ngIf="time.invalid && (time.dirty || time.touched)" class="form_alert">
                      <span class="ft-alert-circle"></span>
                      <div *ngIf="time.errors.required">
                        Time is required.
                      </div>
                    </div>
                  </div>

                  <select class="form-control com_drop ml-2 time" data-toggle="tooltip" data-trigger="hover"
                    data-placement="top" data-title="Priority" data-original-title="" title="" placeholder="PM"
                    name="amPm" id="amPm" [(ngModel)]="newsFeedModel.amPm" #amPm="ngModel">
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>

                </div>


              </div>

            </div>

            <div class="col-md-6 mt-1" *ngIf="!isExpiredShow">
              <div class="form-group">
                <label for="" class="c_n_fs">Expires on</label>
                <div class="input-group date-picker">
                  <input required class="form-control border-right-0" placeholder="yyyy-mm-dd" name="expiredDate"
                    id="expiredDate" [(ngModel)]="newsFeedModel.expiredDate" ngbDatepicker #expiredDate="ngbDatepicker"
                    readonly [minDate]="minDate">
                  <div class="input-group-append">
                    <button class="btn calendar border-left-0" (click)="expiredDate.toggle()" type="button"></button>
                  </div>
                  <div class="form-group">
                    <div *ngIf="expiredDate.invalid && (expiredDate.dirty || expiredDate.touched)" class="form_alert">
                      <span class="ft-alert-circle"></span>
                      <div *ngIf="expiredDate.errors.required">
                        Date is required.
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

            <div class="col-md-6 expiry-checkbox">
              <div class="d-flex align-items-center">
                <input class="custom-checkbox" type="checkbox" id="isExpiredInput" name="isExpiredInput"
                  [(ngModel)]="newsFeedModel.isExpiredInput" #isExpiredInput="ngModel"
                  (click)="isExpiredClick(isExpiredShow)" value="option1">
                <label class="form-check-label schedule-text" for="isExpiredInput"> Set no expiry</label>
              </div>
            </div>

            <div class="col-md-12 my-1">

              <div class="d-flex align-items-center">
                <input class="custom-checkbox" type="checkbox" id="isSilentPush" name="isSilentPush"
                  [(ngModel)]="newsFeedModel.isSilentPush" #isSilentPush="ngModel" value="option1">
                <label class="form-check-label schedule-text silent-checkbox-text" for="isSilentPush">Silent News feed
                  (Publish without sending push notification)</label>
              </div>

            </div>

          </div>
        </div>

      </div>
      <div class="col-md-4 text-center" id="outer-d">
        <!-- tabs start -->
        <ul class="nav nav-tabs tab-title mobile-header-wrapper" id="myTab" role="tablist">
          <li><a href="#test1" class="active" id="content-preview" data-toggle="tab" role="tab" aria-selected="true"
              aria-controls="test1">PREVIEW POST</a></li>
          <li><a href="#test2" id="device-preview" data-toggle="tab" role="tab" aria-selected="false"
              aria-controls="test2">PREVIEW PUSH NOTIFICATION</a></li>
        </ul>
        <!-- tabs ends -->
        <div class="tab-content pt-2" id="myTabContent">
          <div id="test1" role="tabpanel" class="tab-pane fade show active" aria-labelledby="content-preview">
            <app-preview [title]="newsFeedModel.title" [message]="newsFeedModel.message" [mobileW]="mobileW"
              [mobileH]="mobileH" [post]="true" [imgUrl]="url" [uploadType]="uploadType"
              [documentType]="newsFeedModel.documentType" [mobileLink]="mobileLink"></app-preview>
          </div>

          <div id="test2" role="tabpanel" aria-labelledby="device-preview" class="tab-pane fade">
            <!-- Android Devices -->
            <app-preview [title]="newsFeedModel.title" [mobileW]="mobileW" [mobileH]="mobileH"
              [deviceTitle]="'Android Devices'" [post]="false" [type]="'A'" [silentPush]="newsFeedModel.isSilentPush"></app-preview>
            <!-- iOS Devices -->
            <div class="mt-3">
              <app-preview [title]="newsFeedModel.title" [mobileW]="mobileW" [mobileH]="mobileH"
                [deviceTitle]="'iOS Devices'" [post]="false" [type]="'I'" [silentPush]="newsFeedModel.isSilentPush"></app-preview>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--                                    <hr class="c_hr">-->
  </div>
  <!-- <div class="modal-footer a-footer">
                <button type="button" class="btn grey btn-outline-secondary btn-sm a_btn c_btn_delete">Delete</button>
                <button type="button" class="btn btn-outline-primary btn-sm a_btn c_btn_save">Save</button>
            </div> -->
  <div class="modal-footer a-footer">
    <div *ngIf="!Isvalid" class="form_alert">
      <span class="ft-alert-circle"></span>
      Please fill in all the required fields correctly.
    </div>
    <div *ngIf="errors" class="form_alert">
      <span class="ft-alert-circle"></span>
      {{errors}}
    </div>
    <!-- <button type="button" class="btn popup_delete_b">Delete</button> -->
    <button type="submit" class="btn popup_save_b">Publish</button>
  </div>
</form>
<ngx-ui-loader></ngx-ui-loader>