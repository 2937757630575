import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddPeopleComponent } from './add-people/add-people.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AudienceService } from '../audience.service';
import { AudienceDataResponse, AudienceMemberResponse, AudienceResponse } from 'src/app/shared/models/response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AudienceAddModel } from 'src/app/shared/models/audience.model';
import { ToastrService } from 'ngx-toastr';
import { AudienceMemberAddModel } from 'src/app/shared/models/audience-member.model';
import { CreateAudienceComponent } from '../create-audience/create-audience.component';
import { ImportClientsComponent } from './import-clients/import-clients.component';
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-view-audience',
  templateUrl: './view-audience.component.html',
  styleUrls: ['./view-audience.component.css']
})
export class ViewAudienceComponent implements OnInit {

  audience: any;
  dtOptions: DataTables.Settings = {};
  audience_id: string;
  audienceCount:number;
  audienceMemberList: AudienceMemberAddModel[];
  audienceData: AudienceAddModel;
  isLoaded: boolean;
  isLoaded2: boolean;
  modalRef: BsModalRef;
  audienceMemberId: string;
  message: string;
  isActive: boolean;
  newAudienceMemberList: any;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private audienceService: AudienceService,
    private router: Router,
    private toastr: ToastrService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
   }


  ngOnInit() {
    this.audience_id = this.route.snapshot.queryParams['audience_id'];
    // this.audienceCount = this.route.snapshot.queryParams['audienceCount'];

    this.getAudience(this.audience_id);
    this.getAudienceMemberList(this.audience_id);

    this.dtOptions = {
      pagingType: 'numbers', // 'full_numbers',
      pageLength: 10,
      processing: true,
      order: [],
      // responsive:true,
    };
  }

  getAudienceMemberList(audienceId){
    this.isLoaded = false; 
    this.audienceService.audienceMemberList(audienceId).subscribe((data:AudienceMemberResponse)=>{ 
      if(data && data.success){
        this.audienceMemberList = data.response;
        this.audienceCount = this.audienceMemberList.length;
        this.isLoaded = true; 
        this.newAudienceMemberList = this.audienceMemberList;
      }    
          
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });      
  }

  getAudience(audienceId){
    this.isLoaded2 = false
    this.audienceService.getAudience(audienceId).subscribe((data:AudienceDataResponse)=>{ 
      if(data && data.success){
        this.audienceData = data.response;
        this.isActive = this.audienceData.isEnabled;
        this.isLoaded2 = true;  
      }    
          
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });      
  }

  public openDeleteDialog(template: TemplateRef<any>,id) {
console.log('xxxx--', id);
    this.audienceMemberId = id;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    //this.idToBeDeleted = id;

    this.message = 'Are you sure that you want to delete this member?'
  }

  decline(): void {
    this.modalRef.hide();
  }

  confirm(): void {
    //this.message = 'Confirmed!';
    this.modalRef.hide();
    
    this.audienceService.deleteAudienceMember(this.audienceMemberId).subscribe(()=>{     
      
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      // this.router.navigate(['clients']);
      this.router.navigate(['view-audience'], { queryParams: { audience_id: this.audience_id, audienceCount: this.audienceCount }});
      this.toastr.success('Audience member deleted successfully.');
      // this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
    
    //this.delete();
  }

  public openConfirmationDialog(template: TemplateRef<any>, isActive) {
    // console.log(this.clientId);   
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    
    // this.isActive = !isActive;
    //this.message = 'Are you sure that you want to delete?'
    
    if(isActive){
      this.message = 'Are you sure that you want to disable audience?'
    }
    else{
      this.message = 'Are you sure that you want to enable audience?'
    }
    
    //console.log(this.message);
    //console.log(id);
  }

  confirmAudienceStatus(isActive): void {
    this.modalRef.hide();   
    this.isActive = !isActive; 
    this.updateAudienceStatus(); 
  }

  updateAudienceStatus(){
    // let visibility = {
    //   "isStaffMember": this.isStaffMember,
    //   "isActive": this.isActive
    // }

    this.audienceService.updateAudienceStatus(this.audience_id, this.isActive).subscribe((data:AudienceResponse)=>{     
      if(data && data.success){
        //this.router.navigate(['/client/view?id?'+this.clientId]); 
        console.log('xxxxxx == ', this.isActive)
        this.audienceData.isEnabled = this.isActive;
        // this.router.navigate(['view-audience'], { queryParams: { audience_id: this.audience_id }});
      } 
      
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });  
  }

  openEditModal(audienceData:any) {  
    //console.log(agent);  
    this.modalRef = this.modalService.show(CreateAudienceComponent,  {
      initialState: {
        title: 'Edit Agent',
        data: {
          audienceData:audienceData
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });
  
    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        // this.router.navigate(['agents']);
        this.router.navigate(['view-audience'], { queryParams: { audience_id: this.audience_id, audienceCount: this.audienceCount}});
        //  this.getAgentList();
      
      }
    });
  }

  addPeople() {
    this.modalRef = this.modalService.show(AddPeopleComponent,  {
      initialState: {
        title: 'Add People',
        data: {audience_id:this.audience_id},
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg modal_lg_width"
    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
        // this.router.navigate(['agents']);
        this.router.navigate(['view-audience'], { queryParams: { audience_id: this.audience_id, audienceCount: this.audienceCount }});
        //  this.getAgentList();
      
      }
    });
  }

  importClients() {
    this.modalRef = this.modalService.show(ImportClientsComponent,  {
      initialState: {
        title: 'Import Clients',
        data: {audience_id:this.audience_id},
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg"
    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        this.router.navigate(['view-audience'], { queryParams: { audience_id: this.audience_id, audienceCount: this.audienceCount }});
      }
    });
  }

  exportCSV() {
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      // title: 'My Awesome CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    const csvExporter = new ExportToCsv(options);
    let formatedArray = [];

    if(this.newAudienceMemberList.length>0) {
      this.newAudienceMemberList.forEach((element, index) => {
        formatedArray.push({Name:element.firstName+' '+element.lastName, ClientCode:element.clientCode, XeppoCode:element.xeppoCode});
      });
    } else {
      formatedArray = [{Name:'', ClientCode:'', XeppoCode:''}]; 
    }

    csvExporter.generateCsv(formatedArray);
  }

}