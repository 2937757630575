<app-header></app-header>
<app-sidebar></app-sidebar>


<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                <h3 class="content-header-title mb-0 d-inline-block">DALLECORT USERS</h3>
                <div class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12 resbread" >
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/dashboard">Home</a> </li>
                            <li class="breadcrumb-item active">Dalle Cort Users </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="content-header-right col-md-6 col-12">
                <div class="btn-group float-md-right">
                    <button class="btn btn-info appointment_btn btn-sm border_all_radius" type="button" (click)="openModal()" >Create New</button>
                    <!-- data-toggle="modal"  data-target="#create_new" -->
                </div>
                <div class="modal fade text-left" id="create_new" tabindex="-1" role="dialog" aria-labelledby="myModalLabel1" aria-hidden="true">
                    <div class="modal-dialog modal-lg appointment_modal" role="document">
                        <div class="modal-content">
                            <div class="modal-header a_modal">
                                <h4 class="modal-title" id="myModalLabel1">New Agent</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                            </div>
                            <div class="modal-body dallecort_modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <form class="form">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="fname" class="c_n_fs">First Name</label>
                                                            <input type="text" id="fname" class="form-control" placeholder="First Name" name="fname"> </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="lname" class="c_n_fs">Last Name</label>
                                                            <input type="text" id="lname" class="form-control" placeholder="Last Name" name="lname"> </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="email" class="c_n_fs">Email</label>
                                                            <input type="text" id="email" class="form-control" placeholder="Email" name="email"> </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="mobile_number" class="c_n_fs">Mobile Number</label>
                                                            <input type="text" id="mobile_number" class="form-control" placeholder="Mobile Number" name="mobile_number"> </div>
                                                    </div>
                                                    <!-- <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="projectinput8" class="c_n_fs">Agent Permissions</label>
                                                            <br>
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <input type="checkbox" id="audience" name="fruit-1" value="groups" class="com_check">
                                                                    <label for="audience">Permission level 1</label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <input type="checkbox" id="audience2" name="fruit-2" value="individuals" class="com_check cpad_check">
                                                                    <label for="audience2">Permission level 2</label>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <input type="checkbox" id="audience3" name="fruit-1" value="groups" class="com_check">
                                                                    <label for="audience3">Permission level 3</label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <input type="checkbox" id="audience4" name="fruit-2" value="individuals" class="com_check cpad_check">
                                                                    <label for="audience4">Permission level 4</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer a-footer">
                                <button type="button" class="btn btn-outline-primary btn-sm a_btn c_btn_save new_btn_width">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-body">
            <section id="configuration">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Users</h4> </div>
                            <div class="card-content collapse show">
                                <div *ngIf="isLoaded" class="card-body1 card-dashboard">
                                    <table  datatable [dtOptions]="dtOptions" [dtTrigger]="trigger" class="table table-striped table-bordered zero-configuration t_dt" style="margin-top: 20px !important;">
                                        <thead>
                                            <tr>
                                                <th>User Name</th>
                                                <th>Email</th>
                                                <th>Multi-Factor Authentication</th>
                                                <th>Status</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr *ngFor="let agent of agentList; let i = index" >
                                                <!-- <th scope="row" class="">{{i+1}}</th> -->
                                                <td *ngIf="agent">{{agent.firstName}} {{agent.lastName}}</td>
                                                <td *ngIf="agent">{{agent.email}} <ng-container *ngIf="agent.email =='' || agent.email == null">-</ng-container></td>
                                                <td *ngIf="agent">{{agent.isTowWayAuthActivated===true?"Enabled":"Not Configured"}} &nbsp; <a  (click)=" ResetUser(agent.id)" *ngIf="agent.isTowWayAuthActivated===true" ><span class="resetlink">[Reset]</span></a> <ng-container *ngIf="agent.isTowWayAuthActivated =='' || agent.isTowWayAuthActivated == null " ></ng-container> </td>
                                                <td *ngIf="agent" >
                                                    <!-- <ng-container *ngIf="agent.isArchived">Inactive</ng-container>
                                                    <ng-container *ngIf="!agent.isArchived">Active</ng-container> -->
                                                    {{agent.isArchived?"Inactive":"Active" }}
                                                </td>
                                                <td> 
                                                    <div class="btn-group float-right">
                                                        <button type="button" (click)="openConfirmationDialog(template,agent,agent.isArchived)" [ngClass]="[agent.isArchived ? 'd_tog_btn_r' : 'd_tog_btn_rr']" class="btn d_tog_btn"><i class="la la-toggle-on c_icon_s"></i></button>
                                                    </div>
                                                </td>

                                                <!-- <td> 
                                                    <div class="btn-group float-right">
                                                        <button type="button" class="btn d_tog_btn_r"><i class="la la-toggle-on c_icon_s"></i></button>
                                                    </div>
                                                </td> -->

                                                <td _ngcontent-svx-c10="" class="sa_btns"> 
                                                    <ng-container *ngIf="agent">
                                                        <div _ngcontent-svx-c10="" class="btn-group">
                                                            <button (click)="openEditModal(agent)" type="button" class="btn btn-icon btn-success mr-1  c_btntable" data-toggle="modal" data-target="#edit1"><i class="la la-pencil c_icon_s"></i></button>
                                                            <button (click)="openChangePasswordModal(agent)" type="button" class="btn btn-icon btn-success mr-1  c_btntable" data-toggle="modal" data-target="#edit1"><i class="la la-unlock-alt"></i></button>
                                                        </div>
                                                        <!-- <div class="btn-group float-right"></div> -->
                                                    </ng-container>
                                                </td>
                                            </tr>

                                            <!-- <tr>
                                                <td>Jonathan Cooper</td>
                                                <td>jonathan@fortress.com.au</td>
                                                <td>active</td>
                                                <td>
                                                    <input type="checkbox" id="switcherySize2" class="switchery-sm" checked/> </td>
                                                <td> 
                                                    <div class="btn-group float-right">
                                                        <button type="button" class="btn btn-icon btn-success mr-1  c_btntable" data-toggle="modal" data-target="#edit1"><i class="la la-pencil c_icon_s"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Neville Jason</td>
                                                <td>njason@fortress.com.au</td>
                                                <td>active</td>
                                                <td>
                                                    <input type="checkbox" id="switcherySize2" class="switchery-sm" checked/> </td>
                                                <td> 
                                                    <div class="btn-group float-right">
                                                        <button type="button" class="btn btn-icon btn-success mr-1  c_btntable" data-toggle="modal" data-target="#edit1"><i class="la la-pencil c_icon_s"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Shermainie Louie</td>
                                                <td>louie@fortress.com.au</td>
                                                <td>active</td>
                                                <td>
                                                    <input type="checkbox" id="switcherySize2" class="switchery-sm" checked/> </td>
                                                <td> 
                                                    <div class="btn-group float-right">
                                                        <button type="button" class="btn btn-icon btn-success mr-1 c_btntable"><i class="la la-trash c_icon_s"></i></button>
                                                        <button type="button" class="btn btn-icon btn-success mr-1  c_btntable" data-toggle="modal" data-target="#edit1"><i class="la la-pencil c_icon_s"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Nathan James</td>
                                                <td>james@fortress.com.au</td>
                                                <td>active</td>
                                                <td>
                                                    <input type="checkbox" id="switcherySize2" class="switchery-sm" checked/> </td>
                                                <td> 
                                                    <div class="btn-group float-right">
                                                        <button type="button" class="btn btn-icon btn-success mr-1 c_btntable"><i class="la la-trash c_icon_s"></i></button>
                                                        <button type="button" class="btn btn-icon btn-success mr-1  c_btntable" data-toggle="modal" data-target="#edit1"><i class="la la-pencil c_icon_s"></i></button>
                                                    </div>
                                                </td>
                                            </tr> -->
                                        </tbody>
                                        
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

<ng-template #template>
    <div class="modal-body text-center" >

    <img *ngIf="status==true" src="assets/img/warning.png" alt="" class="alert_img">
    <img *ngIf="status==false" src="assets/img/verified.png" alt="" class="alert_img">
    <p>{{message}}</p>
          
      <div class="form-actions center mb-1">
            <button type="button" class="btn alert_no_b mr-1" (click)="decline(agent,status)"> 
                No
            </button>
            <button type="button" class="btn alert_yes_b" (click)="confirm(agent,status)" >
                 Yes
            </button>
        </div>
    </div>
  </ng-template>


<app-footer></app-footer>