import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {
  @Input() title ?: string;
  @Input() deviceTitle ?: string;
  @Input() message ?: string;
  @Input() imgUrl ?: string;
  @Input() documentType ?: number;
  @Input() uploadType ?: string;
  @Input() mobileLink?: string;
  @Input() type?: string;
  @Input() mobileW: number;
  @Input() mobileH: number;
  @Input() post: boolean;
  @Input() silentPush?: boolean;

  public showFull: boolean = true;
  public halfMH: number;

  constructor() { 
  }

  ngOnInit(): void {
    if(this.type === 'I') {
      this.showFull = true;
    } else {
      this.showFull = false;
    }
    
    if(this.mobileH/2 < 250) {
      this.halfMH = 230;
    } else {
      this.halfMH = this.mobileH/2 - 50
    }
  }
  
  showNotify() {
    if(this.showFull) {
      this.showFull = false
    } else {
      this.showFull = true
    }
  }
}
