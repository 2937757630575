<kendo-chat
        [messages]="feed | async"
        [user]="user"
        (sendMessage)="sendMessage(conversation,$event)"
      > 

      <ng-template kendoChatAttachmentTemplate let-attachment>
        <div class="k-card chat_attachment">
             <!-- <a (click)="viewAttachment(attachment.content )">View attachment</a> -->
             <h4 class="card-text news_view_i"><img src="assets/img/chat_attach.png" alt=""><a (click)="viewAttachment(attachment.content )" class="chat_attachment_view">View Attachment</a></h4>
                <!-- <h5 class="card-link news_view_detail">
                  <a (click)="viewAttachment(attachment.content )" class="chat_attachment_view"> [ VIEW ]</a>
                </h5> -->

        </div>
      </ng-template>


</kendo-chat>

<div class="k-message-box chatbox_icon">
  <button (click)="attachmentModalOpen()" class="chatbbb"><i class="icon-paper-clip chat_attach_icon"></i> </button>
</div>