import { Injectable } from '@angular/core';
import { UnVerifiedUsersResponse, UnVerifiedUsersResponse2 } from '../shared/models/response.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConstants } from '../utility/app-constants';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserVerifyService {
  baseUrl:String;
  clientAuthUrl:String;

  constructor(private http:HttpClient) { 
    this.baseUrl = AppConstants.getBaseURL();
    this.clientAuthUrl = AppConstants.getClientAuthURL();
  }

  // public unVerifiedUserList(id:string){    
  //   const params = new HttpParams().set('skip','0').set('take','1000').set('id', id);
  //   return this.http.get<UnVerifiedUsersResponse>(this.baseUrl+'/User/UnVerifiedUsers',{params}); //,{params}     
  //  // return this.http.get<ClentResponsex>(this.baseUrl+'/User/GetUsersByType',{params});     
  // }

  public unVerifiedUserList(userVerify){    
    //const params = new HttpParams().set('id', id);     
    return this.http.post<UnVerifiedUsersResponse>(this.clientAuthUrl+'/User/UnVerifiedUsers',userVerify).pipe(
      catchError(this.handleError)
      ); //,{params}   , {dataTablesParameters}
  }

  public verifyUser(user_id:string){
    const params = new HttpParams().set('id', user_id); 
    return this.http.get<Response>(this.clientAuthUrl +'/User/UserVerify?id='+user_id,{params}).pipe(
      catchError(this.handleError)
      );     
  }

  public updateMobileNumber(user_id:string, mobileNumber:string){
    const params = new HttpParams().set('id', user_id).set('mobileNumber', mobileNumber);  
    return this.http.put<UnVerifiedUsersResponse2>(this.clientAuthUrl +'/User/UpdateMobileNumber?id='+user_id+'&mobileNumber=%2B'+mobileNumber,{}).pipe(
      catchError(this.handleError)
      );     
  }

  public updateUserName(user_id:string, userName:string){
    const params = new HttpParams().set('id', user_id).set('userName', userName);  
    return this.http.put<UnVerifiedUsersResponse2>(this.clientAuthUrl +'/User/UpdateUserName?id='+user_id+'&userName='+userName,{}).pipe(
      catchError(this.handleError)
      );     
  }

  public handleError(error) {
    return throwError(error);
  }
}
