<app-header></app-header>
<app-sidebar></app-sidebar>



<!-- 
<div class="content-left-sidebar chat-application">
<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <div class="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                <h3 class="content-header-title mb-0 d-inline-block">Message Center</h3>
                <div *ngIf="!clientId" class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/dashboard">Home</a> </li>
                            <li class="breadcrumb-item active">Message Center</li>
                        </ol>
                    </div>
                </div>

                <div *ngIf="clientId" class="row breadcrumbs-top d-inline-block">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/dashboard">Fortress</a> </li>
                            <li class="breadcrumb-item"><a href="/clients">Clients</a> </li>
                            <li class="breadcrumb-item"><a href="/client/view?id={{ clientId }}">Client Profile</a> </li>
                            <li class="breadcrumb-item active">Messages </li>
                        </ol>
                    </div>
                </div>

            </div>
            <div class="content-header-right col-md-6 col-12">
                <div *ngIf="clientId" class="btn-group float-md-right">
                    <button class="btn btn-info appointment_btn border_all_radius pt-1" type="button" (click)="openModal()">New Conversation</button>
                </div>
            </div>
        </div>
        <div class="content-wrapper1 chat_wrapper scroll_minwidth">
            <div class="sidebar-left sidebar-fixed chat_fix  scroll_chat chat_shadow1 respn_slide">
                <div class="sidebar">
                    <div class="sidebar-content card d-none d-lg-block">
                        <div class="card-body chat-fixed-search chat_bord">
                            <fieldset class="form-group position-relative m-0 chat_inp">
                                <input type="text" class="form-control chat_ico" (ngModelChange)="search($event)" id="iconLeft4" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Conversations"> </fieldset>
                            <ul class="nav nav-tabs nav-underline no-hover-bg tabpane_chat">
                                <li class="nav-item"> <a class="nav-link active chat_nav text-left" id="base-tab32" data-toggle="tab" aria-controls="tab32" href="#tab31" aria-expanded="false">Conversations</a> </li>
                             
                            </ul>
                        </div>
                        <div id="users-list" class="list-group position-relative">
                            <div class="card-content">
                                <div class="tab_chat">
                                    <div class="tab-content">
                                        <div role="tabpanel" class="tab-pane active" id="tab31" aria-expanded="true" aria-labelledby="base-tab31">
                                            <div class="users-list-padding media-list chat_list_padding">






                                                <a [id]="conversation.id"  [ngClass]="[conversation.id===clickedConvId ? 'bg-blue-grey bg-lighten-5' : '']" (click)="getChatList(conversation,conversation.id)" *ngFor="let conversation of conversationList ; let i=index;" href="javascript:void(0)" class="media border-0 ch_media">
                                                    <div class=""> 
                                                        <span class="avatar avatar-md avatar-online chat_avatar">
                                                            <img class="media-object rounded-circle" src="assets/img/avatar-s-3.png" alt="Generic placeholder image">
                                                        </span> 
                                                    </div>
                                                    
                                                    <div class="media-body w-100 chat_media-body">
                                                        <h6 class="list-group-item-heading chat_h" data-toggle="tooltip" title="{{ conversation.title }}"> {{conversation.title | limitTo : 22}}<i class="la la-circle msg_hide" [ngClass]="[conversation.id===newMsgConvId || !conversation.isRead ? 'msg_icon1' : 'hidden']"></i>
                                                            <span class="font-small-3 float-right info c_arrow"><i class="la la-arrow-right"></i></span>
                                                            <p class="list-group-item-text text-muted mb-0 chat_p">
                                                                <ng-container *ngIf="conversation.recivers != null">
                                                                    <ng-container *ngFor="let reciver of conversation.recivers; let j = index"> 
                                                                        {{reciver.userName | limitTo : 22}}
                                                                        <ng-container *ngIf=" (j+1) != conversation.recivers.length"> 
                                                                        ,
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </p>
                                                        </h6> 
                                                    </div>
                                                </a>
                                                
                                                <a *ngIf="conversationList==null || listLength==0" href="javascript:void(0)" class="media border-0 ch_media">
                                                    <div class=""> <span class="empty_chat_m empty_avatar">
                                                        <img class="media-object" src="assets/img/messages_empty.png"
                                                        alt="Generic placeholder image">
                                                        </span> </div>
                                                    <div class="media-body w-100">
                                                        <h6 class="list-group-item-heading empty_chat_h">No Conversations
                                                            <span class="font-small-3 float-right"></span>
                                                        </h6> </div>
                                                </a>

                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-right ch_right_he1 chat_shadow">
                               <div class="content-wrapper new-wrap" style="padding: 0px !important;">
                <div class="content-header row"> </div>
                <div class="content-body">                    
                    <section *ngIf="!showEmptyChat" >
                        <div class="card-body chat_side">
                            <div class="media-body w-100">
                                <h3 class="list-group-item-heading">Subject : {{ clickedConversation.title }}
                                </h3>
                                <p class="list-group-item-text text-muted mb-0 chat_icon_head"><i class="ft-user place_iconc"></i> 
                                    <ng-container *ngIf="clickedConversation.recivers != null">
                                        <ng-container *ngFor="let reciver of clickedConversation.recivers; let j = index">                                             
                                            <ng-container *ngIf=" j < 2  && reciver.id != userId"> 
                                                {{reciver.userName}}
                                            </ng-container>                                            
                                        </ng-container>
                                        <ng-container *ngIf="clickedConversation.recivers.length > 2"> 
                                            ...
                                        </ng-container>
                                    </ng-container>
                                   
                                </p>
                            </div>
                        </div>
                    </section>
                    <section *ngIf="showEmptyChat" >
                        <div class="card-body chat_side empty_btc">
                            <div class="media-body w-100">
                                <h3 class="list-group-item-heading">Welcome to the message center
                                </h3>
                                <p class="list-group-item-text text-muted mb-0 chat_icon_head">Please select a conversation</p>
                            </div>
                        </div>
                    </section>
                    <section class="chat-app-window chat_height scroll_chat">
                        <div *ngIf="!showEmptyChat" class="chats">
                            <div class="chats">
                                
                                    <template #chatcontainer>
                                    </template>

                            </div>
                        </div>

                        <div *ngIf="showEmptyChat" class="container d-flex h-100 chat_empty_pad">
                            <div class="row align-self-center w-100">
                                <div class="mx-auto"> <img src="assets/img/empt_messages.png" alt="">
                                    <h6 class="mt-1">Please Select a Conversation</h6> 
                                    </div>
                            </div>
                        </div>
                                
                    </section>
                 
                </div>
                               </div>
            </div>
        </div>
    </div>
</div>
</div> -->

<div class="app-content content h-100">
    <div class="row h-100 justify-content-center align-items-center">
     <img src="../../assets/img/coming_soon.png" class="cs_img">
     <h2 class="cs_1">This feature</h2>
     <h2 class="cs_2">will be here soon.</h2>
    </div>
  </div>

  
<app-footer></app-footer>