import { Component, OnInit, ViewEncapsulation, ViewChild, TemplateRef } from '@angular/core';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NetworthHistoryService } from './networth-history.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DepartmentUserEmailResponse, NetworthSnapshotHistoryResponse } from '../shared/models/response.model';
import { NetworthHistoryModel, NetworthSnapshotData } from '../shared/models/networth-history.model';
import { NewsFeedService } from '../news-feed/news-feed.service';
import * as $ from 'jquery';
import { thLocale } from 'ngx-bootstrap/chronos/i18n/th';
import { ClientsService } from '../clients/clients.service';
import { GroupVerifiedStatus } from '../shared/models/client.model';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-networth-history',
  templateUrl: './networth-history.component.html',
  styleUrls: ['./networth-history.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class NetworthHistoryComponent implements OnInit {

  fromDateModel: NgbDateStruct;
  toDateModel: NgbDateStruct;
  fromDateChModel: NgbDateStruct;
  toDateChModel: NgbDateStruct;

  dtOptions: DataTables.Settings = {};
  isLoaded: boolean;
  isLoaded1: boolean;
  networthHistoryTableList: NetworthHistoryModel[];
  networthHistoryChatList: NetworthHistoryModel[];
  companyCode: string;
  xeppoCode: string;
  clientCode: string;
  xeppoGroupCode: number;
  clientId: string;
  clientClientId: string;
  fromDate: string;
  toDate: string;
  isEdit:boolean;

  lable = [];
  assetArrayData = [];
  liabilityArrayData = [];
  netwealthArrayData = [];
  netwealthChartData = [];
  isShowChartDateRange: boolean = false;
  chartType: string;
  dateArray: any= [];

  assetArray = [];
  liabilityArray = [];

  dateOptions:any;
  isLoading: boolean = false;
  isLoadingChart: boolean = false;
  minDateFrom:any;
  maxDateTo:any;
  lineColerBorder:any;
  @ViewChild('dp0') dp0: NgbInputDatepicker;
  @ViewChild('dp1') dp1: NgbInputDatepicker;
  @ViewChild('dp2') dp2: NgbInputDatepicker;
  @ViewChild('dp3') dp3: NgbInputDatepicker;
  isVerified: boolean;

  constructor(
    private networthHistoryService: NetworthHistoryService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    public datepipe: DatePipe,
    private clientsService: ClientsService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.companyCode = this.cookieService.get('companyCode');
    this.xeppoCode = this.route.snapshot.queryParams['xeppoCode'];
    this.clientCode = this.route.snapshot.queryParams['clientCode'];
    this.xeppoGroupCode = this.route.snapshot.queryParams['xeppoGroupCode'];
    this.clientId  = this.route.snapshot.queryParams['clientId'];;
    this.clientClientId  = this.route.snapshot.queryParams['clientClientId'];;

    var date = new Date();
    // console.log('date ==', date)
    var firstDay = new Date(date.getFullYear(), date.getMonth() - 12, 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() - 1 + 1, 0);
    if((firstDay.getFullYear() == 2020 && firstDay.getMonth() >= 6) || firstDay.getFullYear() > 2020){
      this.fromDate = this.datepipe.transform(firstDay, 'yyyy-MM-dd'); // "2020-03-22T11:42:48.896Z";
    } else {
      this.fromDate = '2020-06-01'
    }

    this.toDate = this.datepipe.transform(lastDay, 'yyyy-MM-dd'); // "2021-03-22T11:42:48.896Z";
    this.fromDateModel = this.ngbDateFormat(this.fromDate);
    this.toDateModel = this.ngbDateFormat(this.toDate);
    this.fromDateChModel = this.ngbDateFormat(this.fromDate);
    this.toDateChModel = this.ngbDateFormat(this.toDate);

    this.chartType = 'Networth';
    this.minDateFrom = {year: 2020, month: 6, day: 1};
    this.maxDateTo = this.ngbDateFormat(this.toDate);
    this.clientsService.getVerifiedStatus(this.clientClientId).subscribe((res: any)=>{
      if(res.success) {
        const item = res.response.find((item: any)=> item.id === this.clientClientId);
        this.isVerified = item.groupVerifiedStatus === GroupVerifiedStatus.VERIFIED;

        if(this.isVerified) {
          this.getNetworthHistoryListOINIT(this.fromDate, this.toDate, this.xeppoCode, this.companyCode, this.clientCode, this.xeppoGroupCode);
        }  else {
          this.toastr.error('Unable to access Net Worth History. Please try again later.');
        }
      }
    })


    this.dtOptions = {
      //pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      order: []
    };

    this.dateOptions = {
      formatYear: 'yyyy',
      startingDay: 1,
      minMode: 'month',
      maxMode: 'year'
    };
  }

  getNetworthHistoryListOINIT(fromDate, toDate, xeppoCode, companyCode, clientCode, xeppoGroupCode) {

    this.isLoading = true;
    this.isLoadingChart = true;
    if(this.isVerified) {
      this.networthHistoryService.networthHistoryList(fromDate, toDate, xeppoCode, companyCode, clientCode, xeppoGroupCode).subscribe((data: NetworthSnapshotHistoryResponse) => {
        this.networthHistoryChatList = data.data;
        // this.networthHistoryTableList = data.data;
        this.formatNetWorthSnapshotChartData(this.networthHistoryChatList);
        // this.groupNetWorthSnapshotDataSet(this.networthHistoryTableList);
  
        this.isLoaded = true;
        this.isLoadingChart = false;
      },
      (err: HttpErrorResponse) => {
        //console.log("Error"+ err.message);
      });
    
      this.networthHistoryService.networthHistoryFormattedDataList(fromDate, toDate, xeppoCode, companyCode, clientCode, xeppoGroupCode).subscribe((data: NetworthSnapshotHistoryResponse) => {
        // this.networthHistoryChatList = data.data;
        this.networthHistoryTableList = data.data;
        // this.formatNetWorthSnapshotChartData(this.networthHistoryChatList);
        this.dateArray = this.dateRange(fromDate, toDate);
        this.groupNetWorthSnapshotDataSet(this.networthHistoryTableList);
  
  
        this.isLoaded = true;
        this.isLoading = false;
      },
      (err: HttpErrorResponse) => {
        //console.log("Error"+ err.message);
      });
    } else {
      this.toastr.error('Unable to access Net Worth History. Please try again later.');
    }
  }

  onReportDateRangeChange(type) {
    var date = new Date();
    var lastDay = new Date(date.getFullYear(), date.getMonth() - 1 + 1, 0);
    var toDate = this.datepipe.transform(lastDay, 'yyyy-MM-dd'); // "2021-03-22T11:42:48.896Z";

    if (type == '12months') {
      this.isShowChartDateRange = false;
      var firstDay = new Date(date.getFullYear(), date.getMonth() - 12, 1);
      var fromDate = this.datepipe.transform(firstDay, 'yyyy-MM-dd');

      if((firstDay.getFullYear() === 2020 && firstDay.getMonth() >= 6) || firstDay.getFullYear() > 2020) {
        fromDate = this.datepipe.transform(firstDay, 'yyyy-MM-dd'); // "2020-03-22T11:42:48.896Z";
      } else {
        fromDate = '2020-06-01'
      }

      this.getNetworthHistoryChatList(fromDate, toDate, this.xeppoCode, this.companyCode, this.clientCode, this.xeppoGroupCode);
    }
    if (type == '6months') {
      this.isShowChartDateRange = false;
      var firstDay = new Date(date.getFullYear(), date.getMonth() - 6, 1);
      var fromDate = this.datepipe.transform(firstDay, 'yyyy-MM-dd');

      this.getNetworthHistoryChatList(fromDate, toDate, this.xeppoCode, this.companyCode, this.clientCode, this.xeppoGroupCode);
    }
    if (type == '3months') {
      this.isShowChartDateRange = false;
      var firstDay = new Date(date.getFullYear(), date.getMonth() - 3, 1);
      var fromDate = this.datepipe.transform(firstDay, 'yyyy-MM-dd');

      this.getNetworthHistoryChatList(fromDate, toDate, this.xeppoCode, this.companyCode, this.clientCode, this.xeppoGroupCode);
    }
    if (type == 'custom') {
      this.isShowChartDateRange = true;
      this.getNetworthHistoryChatList(this.fromDate, this.toDate, this.xeppoCode, this.companyCode, this.clientCode, this.xeppoGroupCode);
      // this.uploadType = 'NoSelect';
    }
  }

  onReportTypeChange(chartType) {
    if (chartType == 'Networth') {
      this.chartType = 'Networth';
    }

    if (chartType == 'Assets') {
      this.chartType = 'Assets';
    }

    if (chartType == 'Liabilities') {
      this.chartType = 'Liabilities';
    }

    this.setNetwealthChartData();
  }

  onChartDateChange(type, inDate) {
    var fromDate;
    var toDate;
    var date; // = this.msqlDateFormat(inDate);
    var dd = inDate.next;

    if (inDate.current != null) {

      if (type == 'fromD') {
        dd['day'] = 1;
        date = this.msqlDateFormat(dd);
        this.fromDateChModel = dd;
        this.fromDate = date;

        this.toDate = ((document.getElementById("dp1") as HTMLInputElement).value);
        this.getNetworthHistoryChatList(this.fromDate, this.toDate, this.xeppoCode, this.companyCode, this.clientCode, this.xeppoGroupCode);
      }
      if (type == 'toD') {
        var d = this.daysInMonth(inDate.next.month, inDate.next.year)
        dd['day'] = d;
        date = this.msqlDateFormat(dd);
        this.toDateChModel = dd;

        this.fromDate = ((document.getElementById("dp0") as HTMLInputElement).value);
        this.toDate = date;
        this.getNetworthHistoryChatList(this.fromDate, this.toDate, this.xeppoCode, this.companyCode, this.clientCode, this.xeppoGroupCode);
      }

      this.dp0.close();
      this.dp1.close();
    }
  }

  onReportDateChange(type, inDate) {
    var fromDate;
    var toDate;
    var date; // = this.msqlDateFormat(inDate);
    var dd = inDate.next;

    if (inDate.current != null) {
      if (type == 'fromD') {
        dd['day'] = 1;
        date = this.msqlDateFormat(dd);
        this.fromDateModel = dd;
        this.fromDate = date;

        toDate = ((document.getElementById("dp3") as HTMLInputElement).value);
        this.getNetworthHistoryTableList(date, toDate, this.xeppoCode, this.companyCode, this.clientCode, this.xeppoGroupCode);
      }
      if (type == 'toD') {
        var d = this.daysInMonth(inDate.next.month, inDate.next.year)
        dd['day'] = d;
        date = this.msqlDateFormat(dd);
        this.toDateModel = dd;

        fromDate = ((document.getElementById("dp2") as HTMLInputElement).value);
        this.getNetworthHistoryTableList(fromDate, date, this.xeppoCode, this.companyCode, this.clientCode, this.xeppoGroupCode);
      }

      this.dp2.close();
      this.dp3.close();
    }
  }

  getNetworthHistoryChatList(fromDate, toDate, xeppoCode, companyCode, clientCode, xeppoGroupCode) {
    this.isLoadingChart = true;
    if(this.isVerified) {
      this.networthHistoryService.networthHistoryList(fromDate, toDate, xeppoCode, companyCode, clientCode, xeppoGroupCode).subscribe((data: NetworthSnapshotHistoryResponse) => {
      this.networthHistoryChatList = data.data;
      this.formatNetWorthSnapshotChartData(this.networthHistoryChatList)
      this.isLoaded = true;
      this.isLoadingChart = false;
    },
      (err: HttpErrorResponse) => {
        //console.log("Error"+ err.message);
      });
    } else {
      this.toastr.error('Unable to access Net Worth History. Please try again later.');
    }
  }

  getNetworthHistoryTableList(fromDate, toDate, xeppoCode, companyCode, clientCode, xeppoGroupCode) {
    this.isLoading = true;
    this.dateArray = this.dateRange(fromDate, toDate);
    let toDate31 = this.dateArray[this.dateArray.length-1];
    if(this.isVerified) {
      this.networthHistoryService.networthHistoryFormattedDataList(fromDate, toDate31, xeppoCode, companyCode, clientCode, xeppoGroupCode).subscribe((data: NetworthSnapshotHistoryResponse) => {
      this.networthHistoryTableList = data.data;
      this.groupNetWorthSnapshotDataSet(this.networthHistoryTableList);
      this.isLoaded = true;
      this.isLoading = false;
    },
      (err: HttpErrorResponse) => {
        //console.log("Error"+ err.message);
      });
    } else {
      this.toastr.error('Unable to access Net Worth History. Please try again later.');
    }
  }

  groupNetWorthSnapshotDataSet(networthHistoryTableList) {
    var netWorthSnapshotDataSet = [];
    var asset = [];
    var liability = [];
    this.assetArray = [];
    this.liabilityArray = [];

    if(networthHistoryTableList.length>0){

      const groupByType = this.groupBy(networthHistoryTableList, type => type.type);

      asset = groupByType.get("Asset");
      liability = groupByType.get("Liability");
      const groupByAssetNWAccount = this.groupBy(asset, asset => asset.netWorthAccount);
      const groupByLiabilityNWAccount = this.groupBy(liability, liability => liability.netWorthAccount);

      // Group By Asset
      groupByAssetNWAccount.forEach((element, index) => {

        if (element.length > 0 && index !=null) {
          let totalValueNWAccountArray = [];
          let totalValueNWAllocationArray = [];
          let netWorthAllocation = [];

          const groupByAssetNWAllocation = this.groupBy(element, assetData => assetData.netWorthAllocation);

          groupByAssetNWAllocation.forEach((element, index) => {
            // console.log('NWAlocation element ==',element)
            element.forEach((nWSData, index) => {

              let rootArray = [];
              let value = 0;
              let periodEndDate = '';
              let id = '';

              totalValueNWAllocationArray = [];

              this.dateArray.forEach((elementDate, index) => {
                value = 0;
                periodEndDate = elementDate;
                let currentRecordName = '';

                nWSData.netWorthSnapshotDataWithDateSet.forEach((element, index) => {
                  var date = element.periodEndDate.split('T')[0];
                  var date1 = new Date(elementDate);
                  var date2 = new Date(date);

                  if(date1.getTime() == date2.getTime()) {
                    value = element.value;
                    id = element.id;
                    periodEndDate = date;
                    currentRecordName = element.currentRecordName;
                    return;
                  }
                });
                rootArray.push({value:value, periodEndDate:periodEndDate, id: id});
              });

              // Variance in root value
              let varianceInRoot = 0;
              if(rootArray.length>1){
                let lastMonthValue = rootArray[rootArray.length - 1].value;
                let beforLastMonthValue = rootArray[0].value; // rootArray[rootArray.length - 2].value;
                varianceInRoot = lastMonthValue - beforLastMonthValue;
              }

              nWSData.netWorthSnapshotDataWithDateSet = rootArray;
              nWSData['variance']= varianceInRoot;
            });

            // NWAllocation total calculation
            this.dateArray.forEach((elementDate, index) => {
              let totalValueNWAllocation = 0;
              element.forEach((nWSData, index) => {
                let nWSDataSet=nWSData.netWorthSnapshotDataWithDateSet;

                for(let j=0;j<nWSDataSet.length;j++){
                  if(nWSDataSet[j].periodEndDate == elementDate) {
                    totalValueNWAllocation+= nWSDataSet[j].value
                  }
                }
              });
              totalValueNWAllocationArray.push({elementDate:elementDate, totalValueNWAllocation:totalValueNWAllocation});
            });

            // Variance in NW Allocation total
            let varianceInNWAllocationTotal = 0;
            if(totalValueNWAllocationArray.length>1){
              let lastMonthValue = totalValueNWAllocationArray[totalValueNWAllocationArray.length - 1].totalValueNWAllocation;
              let beforLastMonthValue = totalValueNWAllocationArray[0].totalValueNWAllocation; // totalValueNWAllocationArray[totalValueNWAllocationArray.length - 2].totalValueNWAllocation;
              varianceInNWAllocationTotal = lastMonthValue - beforLastMonthValue;
            }

            netWorthAllocation.push({key:index, value:element, totalValueNWAllocation:totalValueNWAllocationArray, variance: varianceInNWAllocationTotal});
          });

          // NW Account total calculation
          this.dateArray.forEach((elementDate, index) => {
            let totalValueNWAccount = 0;
            element.forEach((elementAccount, index) => {
                let nWSDataSet=elementAccount.netWorthSnapshotDataWithDateSet;

                for(let j=0;j<nWSDataSet.length;j++){
                  if(nWSDataSet[j].periodEndDate == elementDate) {
                    totalValueNWAccount+= nWSDataSet[j].value
                  }
                }
            });
            totalValueNWAccountArray.push({totalValueNWAccount:totalValueNWAccount});
          });

          // Variance in NW Account total
          let varianceInNWAccountTotal = 0;
          if(totalValueNWAccountArray.length>1){
            let lastMonthValue = totalValueNWAccountArray[totalValueNWAccountArray.length - 1].totalValueNWAccount;
            let beforLastMonthValue = totalValueNWAccountArray[0].totalValueNWAccount; // totalValueNWAccountArray[totalValueNWAccountArray.length - 2].totalValueNWAccount;
            varianceInNWAccountTotal = lastMonthValue - beforLastMonthValue;
          }

          this.assetArray.push({key: index, value:netWorthAllocation, totalValueNWAccount: totalValueNWAccountArray, variance: varianceInNWAccountTotal});
        }
        // console.log('index == ', index)
        // console.log('element == ',element)
      });

      // Group By Liability
      groupByLiabilityNWAccount.forEach((element, index) => {

        if (element.length > 0 && index !=null) {
          let totalValueNWAccountArray = [];
          let totalValueNWAllocationArray = [];
          let netWorthAllocation = [];

          const groupByLiabilityNWAllocation = this.groupBy(element, liabilityData => liabilityData.netWorthAllocation);

          groupByLiabilityNWAllocation.forEach((element, index) => {
            // console.log('NWAlocation element ==',element)
            element.forEach((nWSData, index) => {

              let rootArray = [];
              let value = 0;
              let periodEndDate = '';

              totalValueNWAllocationArray = [];

              this.dateArray.forEach((elementDate, index) => {
                value = 0;
                periodEndDate = elementDate;
                let currentRecordName = '';
                let id = '';

                nWSData.netWorthSnapshotDataWithDateSet.forEach((element, index) => {
                  var date = element.periodEndDate.split('T')[0];
                  var date1 = new Date(elementDate);
                  var date2 = new Date(date);

                  if(date1.getTime() == date2.getTime()) {
                    value = element.value;
                    id = element.id;
                    periodEndDate = date;
                    currentRecordName = element.currentRecordName;
                    return;
                  }
                });
                rootArray.push({value:value, periodEndDate:periodEndDate, id});
              });

              // Variance in root value
              let varianceInRoot = 0;
              if(rootArray.length>1){
                let lastMonthValue = rootArray[rootArray.length - 1].value;
                let beforLastMonthValue = rootArray[0].value; // rootArray[rootArray.length - 2].value;
                varianceInRoot = lastMonthValue - beforLastMonthValue;
              }

              nWSData.netWorthSnapshotDataWithDateSet = rootArray;
              nWSData['variance']= varianceInRoot;
            });

            // NWAllocation total calculation
            this.dateArray.forEach((elementDate, index) => {
              let totalValueNWAllocation = 0;
              element.forEach((nWSData, index) => {
                let nWSDataSet=nWSData.netWorthSnapshotDataWithDateSet;

                for(let j=0;j<nWSDataSet.length;j++){
                  if(nWSDataSet[j].periodEndDate == elementDate) {
                    totalValueNWAllocation+= nWSDataSet[j].value
                  }
                }
              });
              totalValueNWAllocationArray.push({elementDate:elementDate, totalValueNWAllocation:totalValueNWAllocation});
            });

            // Variance in NW Allocation total
            let varianceInNWAllocationTotal = 0;
            if(totalValueNWAllocationArray.length>1){
              let lastMonthValue = totalValueNWAllocationArray[totalValueNWAllocationArray.length - 1].totalValueNWAllocation;
              let beforLastMonthValue = totalValueNWAllocationArray[0].totalValueNWAllocation; // totalValueNWAllocationArray[totalValueNWAllocationArray.length - 2].totalValueNWAllocation;
              varianceInNWAllocationTotal = lastMonthValue - beforLastMonthValue;
            }

            netWorthAllocation.push({key:index, value:element, totalValueNWAllocation:totalValueNWAllocationArray, variance: varianceInNWAllocationTotal});
          });

          // NW Account total calculation
          this.dateArray.forEach((elementDate, index) => {
            let totalValueNWAccount = 0;
            element.forEach((elementAccount, index) => {
                let nWSDataSet=elementAccount.netWorthSnapshotDataWithDateSet;

                for(let j=0;j<nWSDataSet.length;j++){
                  if(nWSDataSet[j].periodEndDate == elementDate) {
                    totalValueNWAccount+= nWSDataSet[j].value
                  }
                }
            });
            totalValueNWAccountArray.push({totalValueNWAccount:totalValueNWAccount});
          });

          // Variance in NW Account total
          let varianceInNWAccountTotal = 0;
          if(totalValueNWAccountArray.length>1){
            let lastMonthValue = totalValueNWAccountArray[totalValueNWAccountArray.length - 1].totalValueNWAccount;
            let beforLastMonthValue = totalValueNWAccountArray[0].totalValueNWAccount;// totalValueNWAccountArray[totalValueNWAccountArray.length - 2].totalValueNWAccount;
            varianceInNWAccountTotal = lastMonthValue - beforLastMonthValue;
          }

          this.liabilityArray.push({key: index, value:netWorthAllocation, totalValueNWAccount: totalValueNWAccountArray, variance: varianceInNWAccountTotal});
        }
        // console.log('index == ', index)
        // console.log('element == ',element)
      });
      console.log('assetArrayFainel == ',this.assetArray)
      console.log('liabilityArrayFainel == ',this.liabilityArray)
    }

    this.isLoaded1 = true;
  }

  formatNetWorthSnapshotChartData(networthHistoryTableList) {
    console.log('networthHistoryTableList ==== ', networthHistoryTableList)
    var netWorthSnapshotDataSet = [];
    var asset = [];
    var liability = [];

    this.lable = [];
    this.assetArrayData = [];
    this.liabilityArrayData = [];
    this.netwealthArrayData  = [];

    networthHistoryTableList.forEach((element, index) => {
      asset = [];
      liability = [];
      var objDate = new Date(element.periodEndDate);
      var year = objDate.getFullYear();
      var shortMonth = objDate.toLocaleString('en-us', { month: 'short' }); /* Jun */
      this.lable.push([shortMonth, year]);
      netWorthSnapshotDataSet = element.netWorthSnapshotDataSet;

      if (netWorthSnapshotDataSet.length > 0) {

        const grouped = this.groupBy(netWorthSnapshotDataSet, netWorthSnapshotData => netWorthSnapshotData.type);
        asset = grouped.get("Asset");
        liability = grouped.get("Liability");

        var totalAssetValue = 0;
        var totalLiabilityValue = 0;
        var netwealthValue = 0;

        asset.forEach(element => {
          if (element.netWorthAccount != null && element.isIgnored == false) {
            totalAssetValue = totalAssetValue + element.value;
          }
        });

        liability.forEach(element => {
          if (element.netWorthAccount != null && element.isIgnored == false) {
            totalLiabilityValue = totalLiabilityValue + element.value;
          }
        });

        netwealthValue = totalAssetValue - totalLiabilityValue;

        this.assetArrayData.push(totalAssetValue.toFixed(0));
        this.liabilityArrayData.push(totalLiabilityValue.toFixed(0));
        this.netwealthArrayData.push(netwealthValue.toFixed(0));

      } else {
        this.assetArrayData.push(0);
        this.liabilityArrayData.push(0);
        this.netwealthArrayData.push(0)
      }

    });

    console.log('this.lable 111 ==', this.lable)
    // Remove duplicates in data array and label array
    var label = this.lable; //['05/Feb/2019', '05/Feb/2019', '05/Feb/2019', '01/Feb/2019'];
    var valueAsset = this.assetArrayData; //['2', '5', '7', '4'];
    var valueLiability = this.liabilityArrayData;
    var valueNetwealth = this.netwealthArrayData;

    // remove duplicates from a two-dimensional array
    this.lable = this.normalize(this.lable)

    var obj = label.reduce(function(a, b, i) {
        if(!a.hasOwnProperty(b)) {
            a[b] = 0;
        }
        a[b] = Number(valueAsset[i]); // a[b] += Number(valueAsset[i]); // to add two numbers
         return a;
    },{});

    var objLiability = label.reduce(function(a, b, i) {
        if(!a.hasOwnProperty(b)) {
            a[b] = 0;
        }
        a[b] = Number(valueLiability[i]); // a[b] += Number(valueAsset[i]); // to add two numbers
        return a;
    },{});

    var objNetwealth = label.reduce(function(a, b, i) {
        if(!a.hasOwnProperty(b)) {
            a[b] = 0;
        }
        a[b] = Number(valueNetwealth[i]); // a[b] += Number(valueAsset[i]); // to add two numbers
        return a;
    },{});

    // this.lable = Object.keys(obj);
    this.assetArrayData = Object.values(obj);
    this.liabilityArrayData = Object.values(objLiability);
    this.netwealthArrayData = Object.values(objNetwealth);
    console.log('netwealt == ' ,this.netwealthArrayData)

    this.netwealthChartData = [];
    this.setNetwealthChartData();

    this.isLoaded1 = true;
    // console.log('lable == ', this.lable);
    // console.log('assetArrayData == ', this.assetArrayData);
    // console.log('liabilityArrayData == ', this.liabilityArrayData);
    // console.log('netwealthArrayData == ', this.netwealthArrayData);
  }

  ngbDateFormat(date) {
    if (date) {
      const [year, month, day] = date.split('-');
      const obj = { year: parseInt(year), month: parseInt(month), day: parseInt(day.split(' ')[0].trim()) };
      return obj;
    }
  }

  msqlDateFormat(inDate) {
    var month;
    var day;
    month = ("0" + (inDate.month)).slice(-2);
    day = ("0" + (inDate.day)).slice(-2);
    return inDate.year + '-' + month + '-' + day;
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  dateRange(startDate, endDate) {
    var start      = startDate.split('-');
    var end        = endDate.split('-');
    var startYear  = parseInt(start[0]);
    var endYear    = parseInt(end[0]);
    var dates      = [];

    for(var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1])-1 : 0;
      for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
        var month = j+1;
        var d = new Date(i, month , 0);
        var n = d.getDate();
        var displayMonth = month < 10 ? '0'+month : month;


        dates.push([i, displayMonth, n].join('-'));
      }
    }
    return dates;
  }

  setNetwealthChartData() {
    var data;
    var lineColer;
    this.lineColerBorder =[];

    if (this.chartType == 'Networth') {
      data = this.netwealthArrayData;
      lineColer = '#16ADCC';
      this.lineColerBorder = [{ borderColor: "#16ADCC"}, ];
    }

    if (this.chartType == 'Assets') {
      data = this.assetArrayData;
      lineColer = '#1DC122';
      this.lineColerBorder = [{ borderColor: "#1DC122"}, ];
    }

    if (this.chartType == 'Liabilities') {
      data = this.liabilityArrayData;
      lineColer = '#D2CC1D';
      this.lineColerBorder = [{ borderColor: "#D2CC1D"}, ];
    }

    this.netwealthChartData = [
      {
        data: data,//[2000, 5000, 3000, 2570, 3500, 2400, 5000, 4278,3200,1270,4100,3520],
        borderWidth: 4,
        borderColor: lineColer,
        pointBackgroundColor: "#FFF",
        pointBorderColor: lineColer,
        pointHoverBackgroundColor: "#FFF",
        pointHoverBorderColor: lineColer,
        pointRadius: 0,
        pointHoverRadius: 6,
        fill: false,

      }
      // {
      //   data: liabilityArrayData,//[3000, 6000, 2000, 4570, 3500, 3400, 6000, 4278,3200,2270,5100,4520],
      //   borderWidth: 4,
      //   borderColor: '#28D094',
      //   pointBackgroundColor: "#FFF",
      //   pointBorderColor: "#28D094",
      //   pointHoverBackgroundColor: "#FFF",
      //   pointHoverBorderColor: "#28D094",
      //   pointRadius: 0,
      //   pointHoverRadius: 6,
      //   fill: false,
      //   lable: 'Saaaaaa',
      // }
    ];
  }

  // Remove duplicates from a two-dimensional array --- Start
  arrayEqual(a, b) {
      if (a.length !== b.length) { return false; }
      for (var i = 0; i < a.length; ++i) {
          if (a[i] !== b[i]) {
              return false;
          }
      }
      return true;
  }

  contains(array, item) {
      for (var i = 0; i < array.length; ++i) {
          if (this.arrayEqual(array[i], item)) {
              return true;
          }
      }
      return false;
  }

  normalize(array) {
      var result = [];
      for (var i = 0; i < array.length; ++i) {
          if (!this.contains(result, array[i])) {
              result.push(array[i]);
          }
      }
      return result;
  }
  // Remove duplicates from a two-dimensional array --- End

  // Last day of the month OR number of the days
  daysInMonth(iMonth, iYear)
  {
      return new Date(iYear, iMonth, 0).getDate();
  }

  public netwealthChartOptions = {
    responsive: true,
    tooltips: {
      displayColors: false,
      callbacks: {
        label: function (e, d) {
        },
        title: function () {
          return;
        }
      }
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
        },
      }],
      yAxes: [{

        ticks: {
          padding: 10,
          // stepSize: 1000,
          // max: 900000000,
          min: 0,
          // suggestedMin: 10000000,
          // suggestedMax: 900000000000,
          bezierCurve: false,
          callback: function (value, index, values) {
            var ranges = [
              { divider: 1000000, suffix: 'M' },
              { divider: 1000, suffix: 'k' }
           ];
           function formatNumber(n) {
              for (var i = 0; i < ranges.length; i++) {
                 if (n >= ranges[i].divider) {
                    return (n / ranges[i].divider).toString() + ranges[i].suffix;
                 }
              }
              return n;
           }
           return '$' + formatNumber(value);
            // return '$' + value;
          }
        },
        gridLines: {
          display: true,
          drawBorder: false,
          lineWidth: 1,
          zeroLineColor: '#e5e5e5',
        }
      }]
    },
    elements: {
      line: {
        tension: 0
      }
    }
  };
  //  public yLabels = {
  //     0 : '100K',
  //       2 : '200K',
  //       4 : '300K',
  //       6 : '400K',
  //       8 : '500K',
  //       10 : '600K'
  //   }
  public netwealthChartLabels = this.lable;//[["feb","2020"],["Mar", "2020"],["Apr", "2020"], ["May" ,"2020"], ["Jun" ,"2020"], ["Jul" ,"2020"], ["Aug", "2020"], ["Sep" ,"2020"], ["Oct" ,"2020"], ["Nov", "2020"]];
  public netwealthChartType = 'line';
  public netwealthChartLegend = true;
  public lineChartColors = [
    {
      borderColor: "#D2CC1D"
    },
  ];

  // -- Update Table If Newtworth Value Chnaged -- //
  updateNetworthTable() {
    this.getNetworthHistoryTableList(this.fromDate, this.toDate, this.xeppoCode, this.companyCode, this.clientCode, this.xeppoGroupCode);
  }
}
