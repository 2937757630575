export class NetworthHistoryOldModel {
    public accountType : string;
    public subType : string;
    public netWorthAccount : string;
    public netWorthAllocation : string;
    public name : string;
    public enteredOwner : string;
    public clientName : string;
    public summary : Summary[];
    public variance : number;
    public isJoint : boolean ;
    public category : any;
    public isIgnored : boolean ;
    public current : number;
    public clientCode : string;
    public companyCode : string;
    public createdDate : string;

}

export class NetworthSnapshotHistoryModel {
    public netWorthSnapshotID : string;
    public practiceID : string;
    public xeppoGroupCode : string;
    public createdDateUTC : string;
    public isBackdated : boolean ;
    public dataLevel : string;
    public groupName : string;
    public isEnabled : boolean ;
    public periodEndDate : string;
    public netWorthSnapshotDataSet: [];
    public netWorthSnapshotTotalSet: [];
    public assetArray: [];
    public liabilityArray: [];
}

export class NetworthHistoryModel extends NetworthSnapshotHistoryModel{

}

export class Summary {
    public date : string;
    public amount : number;
}

export class NetworthSnapshotData {
  id: string
  netWorthSnapshotDataID: number
  value: number
}
