
<div class="modal-header a_modal">
  <h4 class="modal-title" id="myModalLabel1">Add Clients</h4>
  <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span
      aria-hidden="true">&times;</span> </button>
</div>


  <div class="modal-body">
    <form #f="ngForm" novalidate class="form" enctype="multipart/form-data" (ngSubmit)="onSubmit(f)">
      <div class="row">
        <div class="col-md-12">
          <div class="form-body filter-bg">
            <div class="row align-items-stretch py-1 px-2">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="projectinput8" class="c_n_fs">Search by name</label>
                  <input type="text" id="searchName" name="searchName" #searchName = "ngModel" [(ngModel)]="audienceMemberSearchModel.searchName" required maxlength="100" placeholder="Type here" class="form-control">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="c_n_fs">Search by Xeppo code</label>
                  <input type="text" id="xeppoCode" name="xeppoCode" #xeppoCode = "ngModel" [(ngModel)]="audienceMemberSearchModel.xeppoCode" required maxlength="100" placeholder="Type here" class="form-control">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="c_n_fs">Gender</label>
                  <select required id="gender" name="gender" #gender = "ngModel" [(ngModel)]="audienceMemberSearchModel.gender"
                    class="form-control com_drop" data-toggle="tooltip" data-trigger="hover" data-placement="top"
                    data-title="Priority" data-original-title="" title="" placeholder="Please select">
                    <option value=null selected>Please select</option>
                    <option *ngFor="let gender of genderRole" [value]="gender.id">
                      {{gender.value}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label class="c_n_fs">Postcode</label>
                  <input type="text" id="postalCode" name="postalCode" #postalCode = "ngModel" [(ngModel)]="audienceMemberSearchModel.postalCode" required maxlength="100" placeholder="Please type" class="form-control">
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label class="c_n_fs">Staff member ?</label>
                  <select required id="isStaffMember" name="isStaffMember" #isStaffMember = "ngModel" [(ngModel)]="audienceMemberSearchModel.isStaffMember"
                    class="form-control com_drop" data-toggle="tooltip" data-trigger="hover" data-placement="top"
                    data-title="Priority" data-original-title="" title="" placeholder="Please select">
                    <option value=null selected>Please select</option>
                    <option *ngFor="let gender of staffMember" [value]="gender.id">
                      {{gender.value}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="c_n_fs">Set Age (Minimum)</label>
                  <select required id="ageMin" name="ageMin" #ageMin = "ngModel" [(ngModel)]="audienceMemberSearchModel.ageMin"
                    class="form-control com_drop" data-toggle="tooltip" data-trigger="hover" data-placement="top"
                    data-title="Priority" data-original-title="" title="" placeholder="Please select">
                    <option value=null selected>Please select</option>
                    <option *ngFor="let i of numbers" [value]="i">
                      {{i}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="c_n_fs">Set Age (Maximum)</label>
                  <select required id="ageMax" name="ageMax" #ageMax = "ngModel" [(ngModel)]="audienceMemberSearchModel.ageMax"
                    class="form-control com_drop" data-toggle="tooltip" data-trigger="hover" data-placement="top"
                    data-title="Priority" data-original-title="" title="" placeholder="Please select">
                    <option value=null selected>Please select</option>
                    <option *ngFor="let i of numbers" [value]="i">
                      {{i}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <button type="submit" class="btn popup_save_b filter-btn">Filter</button> &nbsp;
                <button type="button" (click)="clearSearch()" class="btn popup_save_b filter-btn" style="padding: 0.7rem 0.75rem .5rem !important; width: 57px !important;">Clear</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- <form #f2="ngForm" novalidate class="form" enctype="multipart/form-data" (ngSubmit)="onSubmitAddClients(f2)"> -->
      <table *ngIf="!isLoading" class="table table-striped table-bordered zero-configuration t_dt">
        <thead>
          <tr>
            <th class="border-top-0" style="width: 60%;">Name</th>
            <th class="border-top-0" style="width: 20%;">Client code</th>
            <th class="border-top-0" style="width: 20%;">Xeppo code</th>
          </tr>
        </thead>
        <tbody *ngIf="audienceMemberSearchListData && audienceMemberSearchListData.length > 0" >
          <tr *ngFor="let people of audienceMemberSearchListData; let i = index">
            <td class="text-truncate">
              {{people.firstName}} {{people.lastName}}
            </td>
            <td class="text-truncate">{{ people.clientCode}}</td>
            <td class="text-truncate">{{ people.xeppoCode}}</td>

          </tr>
        </tbody>
        
        <tbody *ngIf="memberCount==0 || (audienceMemberSearchListData && audienceMemberSearchListData.length == 0)" style=" width: 100% !important; text-align: center;">
          <tr>
              <td colspan="3" >No records to show.</td>
              <!-- <td></td> -->
          </tr>
        </tbody>
      </table>

      <div *ngIf="isLoading"><div id="loading-bar-spinner" class="spinner"><div class="spinner-icon"></div></div></div>

      <div class="d-flex justify-content-between p-2">
        <p class="mb-0 ml-1 selected-client">{{memberCount}} Clients Selected</p>
        <button *ngIf="memberCount!=0" type="button" [disabled]="clicked" (click)="addClients(); clicked = true;" class="btn popup_save_b">Done</button>
      </div>
    <!-- </form> -->
  </div>



