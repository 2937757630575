
<div class="modal-header a_modal">
  <h4 class="modal-title" id="myModalLabel1">{{title}}</h4>
  <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
</div>
<form #f="ngForm" novalidate class="form" (ngSubmit)="onSubmit(f)" autocomplete="false" >
<div class="modal-body dallecort_modal-body">
  <div class="row">
      <div class="col-md-12">
          
          
          
              <div class="form-body">
                  <div class="row add_agent_gr" style="margin-top: 5px; margin-bottom: 5px;">
                     
                      
                      
                      <!-- <div class="col-md-12">
                          <div class="form-group">
                              <label for="oldPassword" class="c_n_fs">Current Password *</label> 
                              <input autocomplete="off" type="password" autocomplete="none"  required minlength="8" maxlength="25"  class="form-control" id="oldPassword" name="oldPassword" placeholder="Password" [(ngModel)]="passwordChangeModel.oldPassword" #oldPassword = "ngModel">
                              < !- - <input type="password" autocomplete="off"  minlength="6" required class="form-control" id="password" name="password" placeholder="Password" [(ngModel)]="agentModel.password" #password = "ngModel"> - - >

                              <div class="form-group">
                                  <div *ngIf="oldPassword.invalid && (oldPassword.dirty || oldPassword.touched || f.submitted)"
                                  class="form_alert">
                                  <span  class="ft-alert-circle"></span>
                                    <div *ngIf="oldPassword.errors.required">
                                      Old password is required.
                                    </div>  
                                    <div *ngIf="oldPassword.errors.minlength">
                                      Old password must contain at least 8 characters.
                                    </div> 
                                    <div *ngIf="oldPassword.errors.maxlength">
                                      Old password must be below 25 characters.
                                    </div>                                              
                                  </div>                                    
                              </div>
                          </div>
                      </div> -->
                      <div class="col-md-12">
                        <div class="form-group">
                            <label for="newPassword" class="c_n_fs">New Password *</label> 
                            <div _ngcontent-jen-c9="" class="input-group mr-2">
                              <input autocomplete="off" [type]="hide2 ? 'password' : 'text'" autocomplete="none"  required minlength="8" maxlength="25"  class="form-control ng-valid ng-untouched ng-pristine" id="newPassword" name="newPassword" placeholder="Password" [(ngModel)]="passwordChangeModel.newPassword" #newPassword = "ngModel">
                              <div _ngcontent-fun-c13="" class="input-group-append" (click)="hide2 = !hide2">
                                <button _ngcontent-fun-c13="" class="btn btn-outline-secondary" type="button" ng-reflect-ngb-popover="[object Object]">
                                  <i _ngcontent-fun-c13="" [class]="hide2 ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                                </button>
                              </div>
                            </div>
                            
                            <div class="form-group">
                                <div *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched || f.submitted)"
                                class="form_alert">
                                <span  class="ft-alert-circle"></span>
                                  <div *ngIf="newPassword.errors.required">
                                    New password is required.
                                  </div>  
                                  <div *ngIf="newPassword.errors.minlength">
                                    New password must contain at least 8 characters.
                                  </div> 
                                  <div *ngIf="newPassword.errors.maxlength">
                                    New password must be below 25 characters.
                                  </div>                                               
                                </div>                                    
                            </div>
                        </div>
                    </div>
                      

                  </div>
              </div>
          
      </div>
  </div>
</div>

<div class="modal-footer a-footer">
  <!-- <div *ngIf="!Isvalid" class="form_alert">
          <span  class="ft-alert-circle"></span>
      Please fill in all the required fields correctly.
  </div> -->
  <div *ngIf="errors"class="form_alert">
          <span  class="ft-alert-circle"></span>
      {{errors}}
  </div>
  <button type="submit" class="btn popup_save_b">Save</button>
</div>
</form>
<ngx-ui-loader ></ngx-ui-loader>
