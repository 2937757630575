import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { AppointmentBaseModel,AppointmentAddModel } from '../../shared/models/appointment.model';
import { Router } from '@angular/router';
import { AppointmentService } from '../appointment.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { AppointmentResponse, ClentResponsex, AgentResponse, ClentProfileResponse, ClentResponsexNew } from '../../shared/models/response.model';
import { Utility } from '../../utility/utility';
import { ClientsService } from 'src/app/clients/clients.service';
import { AgentsService } from 'src/app/agents/agents.service';
import { AgentViewModel } from 'src/app/shared/models/agent.model';
import { ClientViewModel } from 'src/app/shared/models/client.model';
import * as moment from 'moment'
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-edit-appointment',
  templateUrl: './edit-appointment.component.html',
  styleUrls: ['./edit-appointment.component.css']
})
export class EditAppointmentComponent implements OnInit {

  appointmentModel : AppointmentAddModel ; 
  title;
  data;
  errors;
//ethnicities : any;
appointmentAddForm: FormGroup;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  maxdate :any;
  //public utility: utility;
  Isvalid:boolean;
  agentList:AgentViewModel[];
  clientList:ClientViewModel[];
  client:ClientViewModel;

  clientName : string = "";
  appointmentD : Date = new Date;
  hr : string = "00"; 
  min : string = "00"; 
  amP : string = "AM"; 
  dur : string = "1"; 
  advisorName : string;  
  scheduledDateDisabled:boolean = false;
  appoinmentStatusDisabled:boolean = false;
  public href: string = "";
  
  constructor(private ngxService: NgxUiLoaderService, private toastrService: ToastrService, private agentService: AgentsService, private clientsService: ClientsService,private formBuilder: FormBuilder,private router: Router, private appointmentService: AppointmentService,public modalRef: BsModalRef,private toastr: ToastrService) { }
 
  ngOnInit() {
    // this.ngxService.start();
    this.Isvalid = true;
    this.appointmentModel = new AppointmentAddModel();
    // this.href = this.router.url; 
    this.href = this.router.url.replace('/', '');
    // console.log(this.href, 'current url');
    // console.log(this.appointmentModel);
    if(this.data.appointment !=null){
      //console.log(2222222);
      //console.log(this.data.appointment); // appointmentModel.sheduledDate+' '+appointmentModel.hour+':'+appointmentModel.minute+':'+appointmentModel.amPm
      // let scheduledDate = new Date(this.data.appointment.sheduledDate);
      // this.data.appointment.sheduledDate = moment(scheduledDate).format('YYYY-MM-DD'); // hh:mm a
      // this.data.appointment.hour = moment(scheduledDate).format('hh');
      // this.data.appointment.minute = moment(scheduledDate).format('mm');
      // this.data.appointment.amPm = moment(scheduledDate).format('A');
      Object.assign(this.appointmentModel, this.data.appointment);    

      let now_time = new Date();
      this.appointmentModel.sheduledDate = this.formatLocalTime(this.appointmentModel.sheduledDate);

      let diffInMs: number = this.appointmentModel.sheduledDate.getTime() - now_time.getTime();
      let diffInHours: number = diffInMs/1000/60/60;
      let appoinmentStatus = this.appointmentModel.appoinmentStatus;

      //console.log("diffInHours --- "+diffInHours);
      if(diffInHours >= 1){
        this.scheduledDateDisabled = false;
        //console.log("scheduledDateDisabled --- "+this.scheduledDateDisabled);
      }
      else{
        this.scheduledDateDisabled = true;
       // console.log("scheduledDateDisabled --- "+this.scheduledDateDisabled);
      }

      if(this.appointmentModel.appoinmentStatus !="1"){
        this.appoinmentStatusDisabled = true;
      }

      if(this.appointmentModel.clientId){
        this.getClient(this.appointmentModel.clientId);
      }

      //console.log('test here----');
     // console.log(this.appointmentModel); //.getTime()
    } 
    //this.getClientList();
    this.getAgentList();
    //this.ngxService.start();
  }

  onSubmit(form:NgForm){
 
    let dateValid = this.validDate(this.appointmentModel.sheduledDate);
    //console.log(dateValid);

    // comment by thilina , Ishara gave permishon
    // if(!dateValid || !dateValid){
    //   form.controls['sheduledDate'].setErrors({'incorrect': true});
    // }

    if(form.invalid) {
    
      if(form.pristine){
      }
      this.Isvalid = false;
    }
    
    else{
      this.ngxService.start();
      if(this.data.appointment !=null){
      //console.log("Edit");
      //console.log(this.appointmentModel);
    
      this.appointmentService.editAppointment(this.data.appointment.id, this.appointmentModel)
      .subscribe((data:AppointmentResponse)=>{
        this.ngxService.stop(); 
        if(data.error && !data.success){
          if(data.error.message){
            if(data.error.errorCode == 2009){
              this.errors = "Can not update status.";
            }
            else{
              this.errors = data.error.message;
            }
          }
          else{
            this.errors = Utility.GetLocalErrorString(data.error.errorCode);
          }
          //console.log(this.errors);
        }
        else{
          this.toastr.success('Appointment updated successfully.');
          this.event.emit('OK');
          this.modalRef.hide();
          // this.router.navigate(['appointments']);

          if(this.href == "appointments") {
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate([this.href]);
          }
        }
        
        },err=>{   
          this.ngxService.stop();
          this.toastr.error('Error on updating appointment.');
        });
      }  
      
    }



    }

    validDate(date){
      var now = new Date();
      var d = new Date(date); 
      //console.log(now.getTime());
      //console.log(d.getTime());
    
      if (now.getTime() > d.getTime()) {
          return false;
      }
      else{
        return true;
      }
    }
    
    getClientList(){
      // this.clientsService.clientList().subscribe((data:ClentResponsex)=>{     
        this.clientsService.clientList().subscribe((data:ClentResponsexNew)=>{
        this.clientList = data.response.data.filter(function(client){
          return client.client !== null;
        });
    
        //console.log('Client list for invoices ---------');
        //console.log(this.clientList);
        },
        (err: HttpErrorResponse)=>{
          //console.log("Error"+ err.message);
        });      
    }

    getClient(clientId :string){
      this.clientsService.getClient(clientId).subscribe((clientData:ClentProfileResponse)=>{
     
        this.client = clientData.response;
        //console.log("client details ==========");
        //console.log(this.client.client);
        //this.gender = this.GetPatientGender.GetGender(this.patient.Gender);
        
        if(this.client !=null && this.client.client!=null){
          if (this.client.client.firstName) {
            this.clientName = this.client.client.firstName+" ";
          }
          if (this.client.client.lastName) {
            this.clientName = this.clientName+this.client.client.lastName;
          }
          // this.clientName = this.client.client.firstName+" "+this.client.client.lastName;
        }
        else{
          this.clientName = this.client.userName;
        }
    
        //console.log(this.clientName);
        //this.isLoaded = true;
        },
        (err: HttpErrorResponse)=>{
          //console.log("Error"+ err.message);
          
        });
    }
    
    getAgentList(){
      this.agentService.agentList().subscribe((data:AgentResponse)=>{ 
        //this.ngxService.stop();    
        this.agentList = data.data.filter(function(client){
          return client.fortressAdmin !== null;
        });;
        //this.agentsCount = this.agentList.length;
        //this.assignCopy();
        //console.log('Agent list--------------------------');
        //console.log(this.agentList);
    
        //this.isLoaded = true;
        },
        (err: HttpErrorResponse)=>{
          //console.log("Error"+ err.message);
        });      
    }
    
    modalRehide(){
      if (!this.modalRef) {
        return;
      } 
      this.modalRef.hide();
      this.modalRef = null;
    }
    
    ClosePopup(){
      this.modalRef.hide();
    }
    
    getUserName(clientId : string){
      this.client = this.clientList.find(x => x.client.id == clientId);
      //console.log(this.client);
      return this.client;
    }
    
    getClientName(event: Event){
      this.clientName = event.target['options'][event.target['options'].selectedIndex].text;
          //console.log(this.clientName);
    }
    
    getAppointmentDate(event){
      this.appointmentD = event.target.value;
          //console.log(this.appointmentD);
    }
    
    getHour(event: Event){
      this.hr = event.target['options'][event.target['options'].selectedIndex].text;
          //console.log(this.hr);
    }
    
    getMinute(event: Event){
      this.min = event.target['options'][event.target['options'].selectedIndex].text;
          //console.log(this.min);
    }
    
    getAmPm(event: Event){
      this.amP = event.target['options'][event.target['options'].selectedIndex].text;
          //console.log(this.amP);
    }
    
    getDuration(event: Event){
      this.dur = event.target['options'][event.target['options'].selectedIndex].text;
          //console.log(this.dur);  
    }
    
    getAppoinmentWith(event: Event){
      this.advisorName = event.target['options'][event.target['options'].selectedIndex].text;
      this.Isvalid = true;
          //console.log(this.advisorName);  
    }

    formatLocalTime(date){
      return new Date(date+'Z');
    }

    changeInput(){
      this.Isvalid = true;
    }

}
