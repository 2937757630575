export class XFacContactBaseMOdel {
    public  facFinderId : string;
    public  contactType : string;
    public  contactValue : string;
    public  clientCode : string;
    public  contactTypeName : string;
}

export class XFacContactAddModel extends XFacContactBaseMOdel{    
       
}