// import { Gender } from './db-enums';
// import { ClinicianViewModel } from "./clinician.model";
// import { ClinicBaseModel } from "./clinic.model";
import { User } from './user.model';

export class ClientBaseModel {
    public  user_id : string;
    public  id:  string;
    public  code:  string;
    public  fatherCode:  string;
    public  entityType:  string;
    public  name:  string;
    public  homePhone:  string;
    public  workPhone:  string;
    public  homeMobile:  string;
    public  homeEmail:  string;
    public  workEmail:  string;
    public  otherEmail:  string;
    public  preferredEmail:  string;
    public  workFax:  string;
    public  salutation:  string;
    public  mailName:  string;
    public  tradingAs:  string;
    public  preferredContactMethod:  string;
    public  dateEngagementLetterSent:  boolean;
    public  acn:  string;
    public  abn:  string;
    public  tfn:  string;
    public  sfn:  string;
    public  dateOfIncorporation:  Date;
    public  directorRelationshipStatus:  string;
    public  referrerName:  string;
    public  referrerType:  string;
    public  clientGroupName:  string;
    public  taxReturnID:  string;
    public  lastTaxReturn_NetIncome:  number;
    public  lastTaxReturn_LodgementDate:  Date;
    public  prevTaxReturn_LodgementDate:  Date;
    public  insuranceAdviser:  string;
    public  gender:  string;
    public  dateOfBirth:  Date;
    public  maritalStatus:  string;
    public  spouseName:  string;
    public  noOfChildren:  string;
    public  occupation:  string;
    public  smoker:  string;
    public  health:  string;
    public  industry:  string;
    public  firstName:  string;
    public  lastName:  string;
    public  middleName:  string;
    public  terminationDate:  Date;
    public  workMobile:  string;
    public  homeFax:  string;
    public  dateBecameClient:  Date;
    public  class_PWM_Inv:  string;
    public  class_CA:  string;
    public  class_Finance:  string;
    public  class_Audit:  string;
    public  class_PWM_Ins:  string;
    public  clientReferredBy:  string;
    public  marketingCommunication:  boolean;
    public  occupationCode:  string;
    public  manager:  string;
    public  director:  string;
    public  accountant:  string;
    public  primaryRelationshipPartner:  string;
    public  totalIncomeThisYear_TOTAL:  number;
    public  taxableIncomeThisYear_TOTAL:  number;
    public  totalIncomeLastYear_TOTAL:  number;
    public  taxableIncomeLastYear_TOTAL:  number;
    public  clientStatus_CRM:  string;
    public  clientClass_CRM:  string;
    public  clientType_CRM:  number;
    public  preferredClientContact_CRM:  string;
    public  debtorsOutstanding_TOTAL:  number;
    public  oldestUnpaidInvoice_TOTAL:  number;
    public  currentWIP_TOTAL:  number;
    public  totalLockup_TOTAL:  number;
    public  totalFUM_TOTAL:  number;
    public  totalAdviceFUA_TOTAL:  number;
    public  totalAssets_TOTAL:  number;
    public  totalLiabilities_TOTAL:  number;
    public  netWorth_TOTAL:  number;
    public  annualPremium_TOTAL:  number;
    public  lifeSumInsured_TOTAL:  number;
    public  traumaSumInsured_TOTAL:  number;
    public  tpdSumInsured_TOTAL:  number;
    public  incomeProtectionMonthlyBenefit_TOTAL:  number;
    public  businessExpenseMonthlyBenefit_TOTAL:  number;
    public  otherSumInsured_TOTAL:  number;
    public  incomeYTD_TOTAL:  number;
    public  expensesYTD_TOTAL:  number;
    public  cashAtBank_TOTAL:  number;
    public  xeroTotalAssets_TOTAL:  number;
    public  xeroTotalLiabilities_TOTAL:  number;
    public  xeroTotalDebtors_TOTAL:  number;
    public  investorType_CRM:  number;
    public  includeInCampaigns_CRM:  boolean;
    public  clientRefersClients_CRM:  boolean;
    public  lastFPReview_TOTAL:  string;
    public  fpAsAt_TOTAL:  string;
    public  smsfAsAt_TOTAL:  string;
    public  rse:  string;
    public  trusteeName:  string;
    public  trustDeedDate:  Date;
    public  auditor:  string;
    public  investmentManager:  string;
    public  isSuperFund_CHECK:  boolean;
    public  netProfitYTD_TOTAL:  number;
    public  lastInsuranceReview_TOTAL:  Date;
    public  totalDebt_TOTAL:  number;
    public  memberNumber:  string;
    public  joinFundDate:  Date;
    public  espStartDate:  Date;
    public  tfnRecorded:  boolean;
    public  beneficiaryDetailsRecorded:  boolean;
    public  nominationEffectiveDate:  Date;
    public  nominationExpiryDate:  Date;
    public  balance:  number;
    public  insuranceRecorded:  boolean;
    public  lifeCover:  number;
    public  tpdCover:  number;
    public  salaryContCover:  number;
    public  title:  string;
    public  foreignOwner_CRM:  string;
    public  creditHold_CRM:  boolean;
    public  noOfEmployees:  number;
    public  age:  number;
    public  cleanTFN:  string;
    public  cleanName:  string;
    public  cleanLastName:  string;
    public  lastUpdatedDate:  Date;
    public  costOfSalesYTD_TOTAL:  number;
    public  otherIncomeYTD_TOTAL:  number;
    public  grossProfitYTD_TOTAL:  number;
    public  xeroNetAssets_TOTAL:  number;
    public  totalEquity_TOTAL:  number;
    public  xeroCurrentAssets_TOTAL:  number;
    public  totalFeesYTD_TOTAL:  number;
    public  totalFeesPrev_TOTAL:  number;
    public  totalFeesPrev2_TOTAL:  number;
    public  totalCommissionYTD_TOTAL:  number;
    public  totalRevenueYTD_TOTAL:  number;
    public  totalNewBusinessYTD_TOTAL:  number;
    public  totalOngoingYTD_TOTAL:  number;
    public  ageBracket:  string;
    public  totalFeesRollingYear_TOTAL:  number;
    public  totalCommissionRollingYear_TOTAL:  number;
    public  totalRevenueRollingYear_TOTAL:  number;
    public  apS_CHECK:  boolean;
    public  classSuper_CHECK:  boolean;
    public  commpay_CHECK:  boolean;
    public  bglcaS_CHECK:  boolean;
    public  xplaN_CHECK:  boolean;
    public  worksorted_CHECK:  boolean;
    public  workflowMax_CHECK:  boolean;
    public  myoB_CHECK:  boolean;
    public  xero_CHECK:  boolean;
    public  moneySoft_CHECK:  boolean;
    public  loanAmountBorrowed_TOTAL:  number;
    public  loanAmountOwing_TOTAL:  number;
    public  moneySoftLoanAmountOwing_TOTAL:  number;
    public  moneySoftNetWorth_TOTAL:  number;
    public  moneySoftInvestments_TOTAL:  number;
    public  moneySoftBankAccounts_TOTAL:  number;
    public  moneySoftCreditCards_TOTAL:  number;
    public  moneySoftPropoerties_TOTAL:  number;
    public  classTotalAssets_TOTAL:  number;
    public  classTotalLiabilities_TOTAL:  number;
    public  classNetAssets_TOTAL:  number;
    public  debtorsOverdue_TOTAL:  number;
    public  totalQuote_TOTAL:  number;
    public  totalQuoteIssued_TOTAL:  number;
    public  totalQuoteDraft_TOTAL:  number;
    public  totalCommissionPrev_TOTAL:  number;
    public  keyFirmContactList:  string;
    public  statusID:  number;
    public  xeppoCodeString:  string;
    public  totalDiscrepancyCount:  number;
    public  mismatchedDataCount:  number;
    public  missingDataCount:  number;
    public  invalidDataCount:  number;
    public  numberOfMatches:  number;
    public  connectorList:  string;
    public  groupName:  string;
    public  groupVerifiedStatusID:  number;
    public  groupVerifiedStatus:  string;
    public  isPrimaryContact:  boolean;
    public  tagNames:  string;
    public  status:  string;
    public  totalFPHoldings_TOTAL:  number;
    public  totalSMSFHoldings_TOTAL:  number;
    public  sourceEntityType:  string;
    public  clientTypeID:  number;
    public  streetAddressLine1:  string;
    public  streetAddressLine2:  string;
    public  streetAddressLine3:  string;
    public  streetAddressSuburb:  string;
    public  streetAddressState:  string;
    public  streetAddressPostCode:  string;
    public  streetAddress:  string;
    public  businessAddressLine1:  string;
    public  businessAddressLine2:  string;
    public  businessAddressLine3:  string;
    public  businessAddressSuburb:  string;
    public  businessAddressState:  string;
    public  businessAddressPostCode:  string;
    public  businessAddress:  string;
    public  postalAddressLine1:  string;
    public  postalAddressLine2:  string;
    public  postalAddressLine3:  string;
    public  postalAddressSuburb:  string;
    public  postalAddressState:  string;
    public  postalAddressPostCode:  string;
    public  postalAddress:  string;
    public  registeredAddressLine1:  string;
    public  registeredAddressLine2:  string;
    public  registeredAddressLine3:  string;
    public  registeredAddressSuburb:  string;
    public  registeredAddressState:  string;
    public  registeredAddressPostCode:  string;
    public  registeredAddress:  string;
    public  hasInsurance:  boolean;
    public  hasInsuranceGeneral:  boolean;
    public  hasInsurancePersonal:  boolean;
    public  pushBackEnabled:  boolean;
    public  totalWages:  number;
    public  otherDeductionsThisYear_TOTAL:  number;
    public  otherDeductionsDescriptionThisYear:  string;
    public  rentalIncomeThisYear_TOTAL:  number;
    public  dividendsThisYear_TOTAL:  number;
    public  dividendDeductionsThisYear_TOTAL:  number;
    public  interestDeductionsThisYear_TOTAL:  number;
    public  otherDeductionsLastYear_TOTAL:  number;
    public  otherDeductionsDescriptionLastYear:  string;
    public  rentalIncomeLastYear_TOTAL:  number;
    public  dividendsLastYear_TOTAL:  number;
    public  dividendDeductionsLastYear_TOTAL:  number;
    public  interestDeductionsLastYear_TOTAL:  number;
    public  incomeProtectionDeductionsThisYear_TOTAL:  number;
    public  incomeProtectionDeductionsLastYear_TOTAL:  number;
    public  partnerXeppoCode:  number;
    public  preferredAddressLine1:  string;
    public  preferredAddressLine2:  string;
    public  preferredAddressLine3:  string;
    public  preferredAddressSuburb:  string;
    public  preferredAddressState:  string;
    public  preferredAddressPostCode:  string;
    public  preferredAddress:  string;
    public  xeppoCode:  number;
    public  xeppoGroupCode:  number;
    public  practiceID:  number;
    public  cardNumber:  string;
    public  userNextAppoinment : any;
    public  IsVisibleToStaff:  boolean;
    public  IsStaffMember:  boolean;
    public  isStaffMember: boolean=false;
    public  isVisibleToStaff: boolean=false;
    public isVisibleDocument: boolean=false;
    public isMigrate ?: boolean;


}

export class ClientViewModel extends User{
    // public  clinicians: ClinicianViewModel[];
    //public  User?:User;
    public  client:ClientBaseModel;
    public  name:string;
    public fristName:string;

    public lastName:string;
    public isActive:boolean;
    public primaryMobile : string;
    public mobileNumber : string;
    public moneysoftId : string;
    public isMigrate ?: boolean;
    public groupVerifiedStatus: string;
    
}

export enum GroupVerifiedStatus {
    "VERIFIED" = "Verified",
    "NOT_VERIFIED" = "Not Verified",
    "REQUIRE_RE_VERIFICATION" = "Requires re-verification"
}

export class ClientAddModel extends ClientBaseModel{

    public  emailAddress:string;
    public password:string;
    public  preferedName:string;
    public clinicianId:string;
}

export class ClientNewAddModel extends ClientBaseModel{
    public  userName:string;
    public password:string;
    public  userType:number;
    public xeppoCode:number;
    public fortressAdminId:string;
    public primaryMobile:string;
    public mobileNumber : string;
    public password_confirmation:string;
    public companyCode:string;
}

export class ClientUpdateModel{

 public fristName:string;

 public lastName:string;

 public dateOfBirth:Date;

 public contactNo:string;

}

export class ClientListModel {

    public  id:string;
    public  firstName:  string;
    public  lastName:  string;
}

export class ClientSearchModel {
    public code: string;
    public firstName: string;
    public email: string;
    public mobileNumber: string;
    public id: string;
    public lastName: string;
    public xeppoCode: number;
    public companyCode: string;
}

export class WebClientAddModel {

    public  client:ClientBaseModel;
}

