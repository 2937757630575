import { Component, OnInit, EventEmitter } from '@angular/core';
import { InvoiceAddModel } from '../../shared/models/invoice.model' 
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { InvoicesService } from '../invoices.service';
import { InvoiceResponse, ClentResponsex, TransactionResponse, ClentResponsexNew } from 'src/app/shared/models/response.model';
import { Utility } from 'src/app/utility/utility';
import { ClientsService } from 'src/app/clients/clients.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ClientViewModel } from 'src/app/shared/models/client.model';
import { InvoiceType } from 'src/app/shared/models/db-enums.model';
import { TransactionViewModel } from 'src/app/shared/models/transaction.model';
// import { ConsoleService } from '@ng-select/ng-select/ng-select/console.service';
//import { NgbDateCustomParserFormatter} from '../../shared/dateformat';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-add-invoice',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.css'],
  providers: [
   // {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
   ]
})
export class AddInvoiceComponent implements OnInit {
  invoiceModel : InvoiceAddModel ;
  //clinics : ClinicViewModel[];
  //patientClinic:ClinicViewModel; 
  title;
  data;
  errors;

  patientAddForm: FormGroup;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  maxdate :any;
  //public utility: utility;
  Isvalid:boolean;
  clientList:ClientViewModel[];
  transactionList:TransactionViewModel[];
  status = InvoiceType;
  scheduledReadOnly : boolean = true;
  currentDate = new Date();
  dueDate2 :any;
  date: NgbDateStruct = { year: this.currentDate.getFullYear(), month: (this.currentDate.getMonth()+1), day: this.currentDate.getDate() };
  marked = false;
  
  constructor(private ngxService: NgxUiLoaderService,private clientsService: ClientsService, private formBuilder: FormBuilder,private router: Router, private invoiceService: InvoicesService,public modalRef: BsModalRef,private toastr: ToastrService) { }
 
  ngOnInit() {
    this.Isvalid = true;
    this.invoiceModel = new InvoiceAddModel();
    //this.invoiceModel.dueDate = this.date;
    //console.log(this.modalRef.content);
    //console.log(this.invoiceModel);
    if(this.data.invoice !=null){
      Object.assign(this.invoiceModel, this.data.invoice);
    }
    //this.getClientList();
    this.getTransactionList();
  }

  onSubmit(form:NgForm){
    this.scheduledReadOnly = false;
  if(form.invalid) {  
    if(form.pristine){
    }
    this.Isvalid = false;
    this.scheduledReadOnly = true;
    //console.log(this.invoiceModel);
  }
  else{ 
    this.ngxService.start();
    //console.log("Add");  
    this.invoiceService.addInvoice(this.invoiceModel)
    .subscribe((data:InvoiceResponse)=>{ 
      this.ngxService.stop(); 
      if(data.error && !data.success){        
        this.toastr.error('Please change the invoice number.'); 
        
        // if(data.error.message){
        //   this.errors = data.error.message;
        // }
        // else{
        //   this.errors = Utility.GetLocalErrorString(data.error.errorCode);
        // }
       
      }
      else{
        this.toastr.success('Invoice added successfully.');
        this.event.emit('OK');
        this.modalRef.hide();
      }
      
      },err=>{   
        this.ngxService.stop();
        this.toastr.error('Error on saving invoice.');
      });
    
  }
  }

  getClientList(){
    this.clientsService.clientList().subscribe((data:ClentResponsexNew)=>{     
      this.clientList = data.response.data;      
      //console.log('Client list for invoices ---------');
      //console.log(this.clientList);
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });      
  }

  getTransactionList(){
    this.invoiceService.transactiontList().subscribe((data:TransactionResponse)=>{     
      this.transactionList = data.response;      
      //console.log('transaction list for invoices ---------');
      //console.log(this.clientList);
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });      
  }

  onChange(newVal){
    //console.log(123456);
    this.dueDate2 = newVal;
  }
  
  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }
  
  ClosePopup(){
    this.modalRef.hide();
  }
  
  validateNumber(e: any) {
    let input = String.fromCharCode(this.invoiceModel.amount);  // this.invoiceModel.amount e.charCode
    const reg = /^[0-9]*\.[0-9]{2}$/;
    // /^\d*\.?\d{0,2}$/g  /^\d*(?:[.,]\d{1,2})?$/
    //console.log(reg.test(input));
    if (!reg.test(input)) {
      e.preventDefault();
    }
  }

  toggleVisibility(e){
    this.marked= e.target.checked;
  }

}
