export class UserDocuments {
    public  userId : string;
    public  documentType : string;    
    public  documentURL : string;
    public documentName : string;
    public  documentCategory : string;
    public createdAt? : Date;
    public adminView? : boolean;
    public documentUploadType : DocumentUploadType;
}

export class UserDocumentsAddModel extends UserDocuments{    
       
}


export enum DocumentUploadType{
    s3Bucket = 1,
    sharepoint = 2,
}