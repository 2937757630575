export class AudienceMemberSearchModel {
    public  searchName : string;
    public  xeppoCode : string;
    public  gender : number;
    public  postalCode : string;
    public  isStaffMember : boolean;
    public  ageMin : number;
    public  ageMax : number;
    public  companyCode : string;
}

export class AudienceMemberSearchItemModel extends AudienceMemberSearchModel{    
       
}

export class AudienceMemberSearchListModel {
    public  xeppoCode : string;
    public  clientCode : string;
    public  audianceId : string;
    public  firstName : string;
    public  lastName : string;
}

export class AudienceMemberSearchListDataModel extends AudienceMemberSearchListModel{    
       
}

export class AudienceMemberModel {
    public  xeppoCode : string;
    public  clientCode : string;
    public  audianceId : string;
}

export class AudienceMemberAddModel extends AudienceMemberModel{    
       
}