import { ClassStmt } from '@angular/compiler';

export class FactFinderCategories {
    public  id : string;
    public  name : string;
    public  questionCount : number;
    public  description : string;
    public  isArchived : boolean;
}

export class FactFinderCategoriesView extends FactFinderCategories{

}

export class FactFinder {
    public  id : string;
    public  code : string;
    public  inputType : number;
    public  categoryId : string;
    public  questionCategory : FactFinderCategories;
    public  description : string;
    public  rangeFrom : string;
    public  rangeTo : string;
    public  questionOrder : string;
    public  offeredAnswers : {};
    public  userGivenAnswers : {};
    public  subQuestions : {};
    public  multipleControllers : {};
    public  isArchived : boolean;
}

export class User {
    public  userName : string;
    public  userType : string;
    public  xeppoCode : string;
    public  primaryMobile : string;
}

export class FactFinderNew {
    public  id : string;
    public  user : User;
    public  createdDate : Date;
    public  lastUpdatedDate : Date;
    public  recordType : number;
    public  versionNumber : number;
    public  isArchived : boolean;
}

export class GetFactFinderNewModel {
    public  id : string;
    public  xFacExpenses : xFacExpenses[];
    public  xFacDependents : xFacDependents[];
    public  xFacGoals : xFacGoals[];
    public  xFacIncomes : xFacIncomes[];
    public  xFacAssets : xFacAssets[];
    public  xFacLiabilities : xFacLiabilities[];
    public  xFacEstatePlannings : xFacEstatePlannings[];
}

export class xFacExpenses {
    public  id : string;
    public  type : string;
    public  description : string;
    public  amountValue : string;
    public  amountCode : string;
    public  frequency : string;
    public  ownerType : string;
    public  isTaxDeductable : boolean;
    public  note : string;
    public  isArchived : boolean;
    public  lastUpdatedDate : Date;
    public  facFinderViewModel : FactFinderNew;
}

export class xFacDependents {
    public  id : string;
    public  firstName : string;
    public  surname : string;
    public  gender : string;
    public  dateOfBirth : Date;
    public  relation : string;
    public  relatedTo : string;
    // public  financiallyDependent : boolean;
    // public  dependantUntilAge : number;
    public  isArchived : boolean;
    public  lastUpdatedDate : Date;
    public  facFinderViewModel : FactFinderNew;
}

export class xFacGoals {
    public  id : string;
    public  facFinderViewModel : FactFinderNew;
    public  ownerType : string;
    public  ownerTypeDescription : any;
    public  category : string;
    public  categoryDescription : any;
    public  description : string;
    public  targetDate : Date;
    public  targetAmountValue : number;
    public  targetAmountCode : string;
    public  note : string;
}

export class xFacIncomes {
    public  id : string;
    public  isArchived : boolean;
    public  lastUpdatedDate : Date;
    public  facFinderViewModel : FactFinderNew;
    public  type : string;
    public  description : string;
    public  amountValue : number;
    public  amountCode : Date;
    public  frequency : number;
    public  ownerType : string;
}

export class xFacAssets {
    public  id : string;
    public  isArchived : boolean;
    public  lastUpdatedDate : Date;
    public  facFinderViewModel : FactFinderNew;
    public  type : string;
    public  description : string;
    public  status : string;
    public  policyNumber : string;
    public  currentValue : number;
    public  currentValueCode : string;
    public  purchaseDate : Date;
    public  initialValue : number;
    public  initialValueCode : string;
    public  ownerPercentage : number;
    public  currentValueLastUpdatedOn : any;
    public  ownerName : string;
}

export class xFacLiabilities {
    public  id : string;
    public  isArchived : boolean;
    public  lastUpdatedDate : Date;
    public  facFinderViewModel : FactFinderNew;
    public  type : string;
    public  productName : string;
    public  policyNumber : string;
    public  status : string;
    public  outstandingBalanceValue : number;
    public  outstandingBalanceCode : string;
    public  repaymentAmountValue : number;
    public  repaymentAmountCode : string;
    public  repaymentFrequency : number;
    public  interestRatePerAnnum : number;
    public  interestRateType : string;
    public  startDate : Date;
    public  endDate : Date;
    public  outstandingBalanceLastUpdatedOn : string;
    public  ownerClientId : string;
    public  ownerName : string;
    
}

export class xFacEstatePlannings {
    public  id : string;
    public  facFinderViewModel : FactFinderNew;
    public  beneficiaries : beneficiaries[];
    public  powerOfAttorneys : beneficiaries[];
    public  executors : beneficiaries[];
    public  funeralPlan : string;
    public  willCurrent : boolean;
    public  willExists : boolean;
    public  willDate : Date;
    public  willNotes : string;
    public  willLocation : string;
}

export class beneficiaries {
    public id : string;
    public xFacEstatePlanningViewModel : any;
    public linkedBeneficiaryId : number;
    public firstName : string;
    public surname : string;
    public type : any;
    public ageOfEntitlement : string;
    public notes :any;
}

