import { DocumentUploadType } from "./user-documents.model";

export class FileUpload {
    public  file : File;
    public  fileType : string;
    public  userId : string ;
    public  fileCategory : string;
    public adminView : boolean;
    public uploadType : DocumentUploadType;
    public groupCode : number;
    public companyCode : string;
    public isPortal : boolean;
    public xeppoCode : any;
    public documentName : string;
    public isFds : boolean;
}

export class FileUploadAddModel extends FileUpload{

}