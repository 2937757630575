import { Component, OnInit, TemplateRef } from '@angular/core';
import { ClientsService } from '../clients/clients.service';
import { NewsFeedService } from './news-feed.service';
import { ClientListModel,ClientViewModel } from '../shared/models/client.model';
import { NewsFeedAddModel,NewsFeedBaseMOdel } from '../shared/models/news-feed.model';
import { ClentListResponse, ClentResponsex, NewsFeedResponse, ClentResponsexNew } from '../shared/models/response.model';
import { Response } from 'selenium-webdriver/http';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddNewsFeedComponent } from './add-news-feed/add-news-feed.component';
import { EditNewsFeedComponent } from './edit-news-feed/edit-news-feed.component';
import { ViewNewsFeedComponent } from './view-news-feed/view-news-feed.component';
import { Router } from '@angular/router';
import { AgentViewModel } from '../shared/models/agent.model';
import { ToastrService } from 'ngx-toastr';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.css']
})
export class NewsFeedComponent implements OnInit {

  showBoundaryLinks: boolean = true;
  showDirectionLinks: boolean = true;
  totalRecordsCount: number;
  currentPage: number;
  itemsPerPage: number = 10; // Define default items per page

  constructor(private router: Router,private clientsService: ClientsService ,private newsFeedService: NewsFeedService,private modalService: BsModalService,private toastr: ToastrService ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
   }
  clientList: ClientViewModel[];
  newsFeedList: NewsFeedAddModel[];
  returnedNewsFeedList: NewsFeedAddModel[];
  newsFeedID:string;
  modalRef: BsModalRef;
  isLoaded :boolean;
  client : ClientViewModel;
  dtOptions: DataTables.Settings = {};
  gridView :boolean = true;

  message;

  ngOnInit() {
    //this.getClientList();
    this.getNewsFeedList();
    this.dtOptions = {
      paging: false,
      info: false,
      processing: false,
      order : [],
    };
  }

  getNewsFeedList(event?: PageChangedEvent) {
    this.isLoaded = false;
    this.currentPage = event?.page || 1;
    const skip = (event?.page-1) * event?.itemsPerPage || 0;
    const take = event?.itemsPerPage || this.itemsPerPage; // Default records count

    this.newsFeedService.newsFeedList(skip, take).subscribe( (data: NewsFeedResponse) => {
      this.newsFeedList = data.response.newsFeedList;
      this.returnedNewsFeedList = this.newsFeedList;
      this.totalRecordsCount = data.response.totalRecordsCount;
      this.isLoaded = true;
    }, (err: HttpErrorResponse) => {
      //console.log("Error"+ err.message);
    });
  }

  changeView(view){
    this.gridView = view;
    // this.noticeBoardService.setGridView(view);

    // if(this.noticeList.length > 0 ){
    //   this.gridView = view;
    //   this.noticeBoardService.setGridView(view);
    // }
    // else{
    //   this.gridView = false;
    // }

  }

  openModal() {
    this.modalRef = this.modalService.show(AddNewsFeedComponent,  {
      initialState: {
        title: 'Add Invoice',
        data: {
          gridViewStatus:this.gridView
        },
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-xl"
    });

    this.modalRef.content.event.subscribe(result => {

      if (result == 'OK') {
          //console.log("Success");
          //this.getNewsFeedList();
          // this.router.navigate(['news-feed']);
          // this.gridView = result;
          this.getNewsFeedList();
      }
    });
  }

  openEditModal(newsFeed:any) {
    //console.log(newsFeed);
    this.modalRef = this.modalService.show(EditNewsFeedComponent,  {
      initialState: {
        title: 'Edit News Feed',
        data: {
          newsFeed:newsFeed,
          gridViewStatus:this.gridView
        }
      },
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-xl"
    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
          //console.log("Success");
          this.getNewsFeedList();
          // this.router.navigate(['news-feed']);
          // this.gridView = result;
      }
    });
  }

  viewNewsFeed(newsFeed:any){
    //console.log(newsFeed);
    this.modalRef = this.modalService.show(ViewNewsFeedComponent,  {
      initialState: {
        title: 'View News Feed',
        data: {
          newsFeed:newsFeed,
          viewIn:'newsfeed'
        },
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
      },
      class: "modal-lg_newsfeed",


    });

    this.modalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        //console.log("Success");
          //this.getNewsFeedList();

      }
    });
  }

  getClientList(){
    this.clientsService.clientList().subscribe((data:ClentResponsexNew)=>{
      this.clientList = data.response.data;
      //console.log('Client list for invoices ---------');
      //console.log(this.clientList);
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });
  }

  getUserName(user_id){
     this.client = this.clientList.find(x => x.id === user_id);
     //console.log(this.client);
     return this.client;
  }

  formatLocalTime(date){
    return new Date(date+'Z');
  }

  public openDeleteDialog(template: TemplateRef<any>,newsFeedId) {

    this.newsFeedID = newsFeedId;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    //this.idToBeDeleted = id;

    this.message = 'Are you sure that you want to delete news feed?'
  }

  confirm(): void {
    //this.message = 'Confirmed!';
    this.modalRef.hide();

    this.newsFeedService.deleteNewsFeed(this.newsFeedID).subscribe(()=>{

      this.getNewsFeedList();
      // this.router.navigate(['news-feed']);
      this.toastr.success('News Feed deleted successfully.');
      // this.isLoaded = true;
      },
      (err: HttpErrorResponse)=>{
        //console.log("Error"+ err.message);
      });

    //this.delete();
  }

  decline(): void {
    this.modalRef.hide();
  }

  checkIsExpired(expiredDate) {
    if(expiredDate) {
      var date_1 = new Date(expiredDate);
      var userEntered = new Date(Date.UTC(date_1.getFullYear(), date_1.getMonth(), date_1.getDate() ));

      var now = new Date();
      var today = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() ));

      // if(expiredDate == "2021-06-08T00:00:00"){
      //   console.log('===============')
      //   console.log('date_1 ==', date_1)
      //   console.log('now ==', now)
      //   console.log('userEntered 1==', userEntered)
      //   console.log('today 1==', today)
      //   console.log('userEntered 2==', userEntered.getTime())
      //   console.log('today 2==', today.getTime())
      // }
      if(userEntered.getTime() >= today.getTime()) {
          return true
      } else {
        return false
      }
    }

  }

  msqlDateFormat(inDate) {
    var month;
    var day;
    month = ("0" + (inDate.month)).slice(-2);
    day = ("0" + (inDate.day)).slice(-2);
    return inDate.year + '-' + month + '-' + day;
  }
}
