export class RoleModel {
    public  id : string;
    public  name : string;
    public  description : string;
    public  isArchived : boolean = false;
    public  companyId : string;
}

export class RoleAddModel extends RoleModel{
    public  isArchived : boolean;
    public  roleId : string;
}