import { Component, OnInit, EventEmitter } from '@angular/core';
import { PasswordChangeModel } from 'src/app/shared/models/changepassword.model';
import { UserAgentAddModel } from '../../shared/models/agent.model';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AgentsService } from '../agents.service';
import { ChangePasswordResponse } from 'src/app/shared/models/response.model';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  passwordChangeModel : PasswordChangeModel ;

  Isvalid:boolean;
  agentModel : UserAgentAddModel ;
  data;
  event: EventEmitter<any> = new EventEmitter();
  hide2:boolean = true;
  title;
  errors;
  userType:number;

  constructor(
    private ngxService: NgxUiLoaderService,
    private agentService: AgentsService,
    private toastr: ToastrService,
    public modalRef: BsModalRef,
    private router: Router,
  ) { }

  ngOnInit() {

    this.Isvalid = true;
    this.agentModel = new UserAgentAddModel();
    this.passwordChangeModel = new PasswordChangeModel();  

    if(this.data.agent !=null){
      //this.agentModel = Object.assign({}, this.data.agent);
      this.agentModel = JSON.parse(JSON.stringify(this.data.agent)); // Object.assign({}, this.data.agent);
    }

  }

  onSubmit(form:NgForm) {
   
    // let oldPassword = this.passwordChangeModel.oldPassword;
    let newPassword = this.passwordChangeModel.newPassword;

    if(form.invalid || (newPassword && newPassword.trim()=="") ) {  // ||  (oldPassword && oldPassword.trim()=="")
    
      // if(oldPassword.trim() == ""){
      //   form.controls['oldPassword'].setErrors({'required': true});
      // }
    
      if(newPassword.trim() == ""){
        form.controls['newPassword'].setErrors({'required': true});
      }
    
      if(form.pristine){
      }
      // this.Isvalid = false;
    }
    
    else{
      this.ngxService.start();
    
      //  this.patientModel.clinicId = this.clinics[0].id;
      //  this.patientModel.clinicianId = JSON.parse( localStorage.getItem('CurrentUserId'));
      if(this.data.agent !=null){
        this.passwordChangeModel.userId = this.data.agent.id; 
        // this.passwordChangeModel.loggedInUserType =  this.userType; // this.data.agent.userType; 
        this.agentService.updatePassword(this.passwordChangeModel).subscribe((data:ChangePasswordResponse)=>{
          this.ngxService.stop();
          if(data.validationIssues || !data.isSuccess){      
            this.toastr.error('Current password is mismatch.');
      
          }
          else{
            this.toastr.success('New password updated successfully.');
            this.event.emit('OK');
            this.modalRef.hide();
      
            this.router.navigate(['agents']);
          }
      
          // this.event.emit('OK');
          // this.modalRef.hide();
          },err=>{   
            this.ngxService.stop();
            this.toastr.error('Error on saving new password.');
          });
          
      }  
    
    }
  }

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }
  
  ClosePopup(){
    this.modalRef.hide();
  }

}
