
<div class="modal-header a_modal">
  <h4 class="modal-title" id="myModalLabel1">Add Client</h4>
  <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
</div>
<form #f="ngForm" novalidate class="form" (ngSubmit)="onSubmit(f)" autocomplete="false" >
<div class="modal-body dallecort_modal-body">
  <div class="row">
      <div class="col-md-12">
          
          
          
              <div class="form-body">
                  <span class="add_agent_g" >General Information</span>
                  <div class="row add_agent_gr" style="margin-top: 5px; margin-bottom: 5px;">
                      <div class="col-md-6">
                          <div class="form-group">
                              <label for="xeppoCode" class="c_n_fs">Xeppo Code *</label>
                              <input autocomplete="off" maxlength="75" id="xeppoCode"  required minlength="2" type="text"  class="form-control" name="xeppoCode" placeholder="Xeppo code" [(ngModel)]="clientModel.xeppoCode" #xeppoCode = "ngModel">
                              <!-- <input type="text" id="projectinput1" class="form-control" placeholder="First Name" name="fname"> -->
                              <div class="form-group">
                                  <div *ngIf="xeppoCode.invalid && (xeppoCode.dirty || xeppoCode.touched || f.submitted)"
                                  class="form_alert">
                                  <span  class="ft-alert-circle"></span>
                                    <div *ngIf="xeppoCode.errors.required">
                                      Xeppo code is required.
                                    </div>
                                    <div *ngIf="xeppoCode.errors.minlength">
                                      Xeppo code must be at least 2 characters long.
                                    </div>
                                    <div *ngIf="xeppoCode.errors.forbiddenName">
                                      Xeppo code cannot be Bob.
                                    </div>
                                    <div *ngIf="xeppoCode.errors.maxlength">
                                      Xeppo code must not more than 75 characters long.
                                    </div>
                                  </div>
                                </div>
                          </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                            <!-- <label for="primaryMobile" class="c_n_fs">Mobile Number*</label>
                            <input autocomplete="off" type="text" pattern="^(\+)?\d{11}$" required class="form-control" id="primaryMobile" name="primaryMobile"  placeholder="Mobile Number" [(ngModel)]="clientModel.primaryMobile" #primaryMobile = "ngModel">
                            <div class="form-group">
                                <div *ngIf="primaryMobile.invalid && (primaryMobile.dirty || primaryMobile.touched || f.submitted)"
                                class="form_alert">
                                <span  class="ft-alert-circle"></span>
                                  <div *ngIf="primaryMobile.errors.required">
                                      Mobile number is required.
                                  </div> 
                                  <div *ngIf="primaryMobile.errors.pattern">
                                        Mobile number must be length 11 and type of +61445678956.
                                      </div>                                                  
                                </div>
                            </div> -->

                            <label for="mobileNumber" class="c_n_fs">Mobile Number*</label>
                            <input autocomplete="off" type="text" pattern="^(\+)?\d{11}$" required class="form-control" id="mobileNumber" name="mobileNumber"  placeholder="Mobile Number" [(ngModel)]="clientModel.mobileNumber" #mobileNumber = "ngModel">
                            <div class="form-group">
                                <div *ngIf="mobileNumber.invalid && (mobileNumber.dirty || mobileNumber.touched || f.submitted)"
                                class="form_alert">
                                <span  class="ft-alert-circle"></span>
                                  <div *ngIf="mobileNumber.errors.required">
                                      Mobile number is required.
                                  </div> 
                                  <div *ngIf="mobileNumber.errors.pattern">
                                        Mobile number must be length 11 and type of +61445678956.
                                      </div>                                                  
                                </div>
                            </div>
                        </div>
                      </div>
                       
                      

                      

                      
                  </div>
                  <span class="add_agent_g">Account Information</span>
                  <div class="row add_agent_ga" style="margin-top: 5px;">  
                      
                      

                      <div class="col-md-12">
                          <div class="form-group">
                              <label for="userName" class="c_n_fs">User Name *</label>
                         <!-- <input type="text" autocomplete="none" required class="form-control" id="userName" name="userName"  placeholder="User Name" [(ngModel)]="agentModel.userName" #userName = "ngModel"> -->
                              <input autocomplete="off" maxlength="75" type="text" autocomplete="none" required minlength="4" class="form-control" id="userName" name="userName"  placeholder="User Name" [(ngModel)]="clientModel.userName" #userName = "ngModel">

                              <div class="form-group">
                                  <div *ngIf="userName.invalid && (userName.dirty || userName.touched || f.submitted)"
                                  class="form_alert">
                                  <span  class="ft-alert-circle"></span>
                                    <div *ngIf="userName.errors.required">
                                      User name is required.
                                    </div>   
                                    <div *ngIf="userName.errors.minlength">
                                          User name must be at least 4 characters long.
                                        </div>   
                                        <div *ngIf="userName.errors.maxlength">
                                          User name must not more than 75 characters long.
                                      </div>                                              
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="form-group">
                              <label for="password" class="c_n_fs">Password *</label> 
                              <input autocomplete="off" type="password" autocomplete="none" minlength="8" required class="form-control" id="password" name="password" placeholder="Password" [(ngModel)]="clientModel.password" #password = "ngModel">
                              <!-- <input type="password" autocomplete="off"  minlength="6" required class="form-control" id="password" name="password" placeholder="Password" [(ngModel)]="agentModel.password" #password = "ngModel"> -->

                              <div class="form-group">
                                  <div *ngIf="password.invalid && (password.dirty || password.touched || f.submitted)"
                                  class="form_alert">
                                  <span  class="ft-alert-circle"></span>
                                    <div *ngIf="password.errors.required">
                                      Password is required.
                                    </div> 
                                    <div *ngIf="password.errors.minlength">
                                          Password must contain at least 8 characters.
                                    </div>                                                 
                                  </div>                                    
                              </div>
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="form-group">
                              <label for="password_confirmation" class="c_n_fs">Password Confirmation *</label>
                              <input autocomplete="off" type="password" autocomplete="none" autocomplete="nope" required class="form-control" id="password_confirmation" name="password_confirmation"  placeholder="Password confirmation" [(ngModel)]="clientModel.password_confirmation" #password_confirmation = "ngModel">
                              <div class="form-group">
                                  <div *ngIf="password_confirmation.invalid && (password_confirmation.dirty || password_confirmation.touched || f.submitted)"
                                  class="form_alert">
                                  <span  class="ft-alert-circle"></span>
                                    <div *ngIf="password_confirmation.errors.required">
                                        Password confirmation is required.
                                    </div>                                                  
                                  </div>
                                  <div *ngIf="(password.value != password_confirmation.value) && (password_confirmation.dirty || password_confirmation.touched)"
                                  class="form_alert">
                                  <span  class="ft-alert-circle"></span>
                                      <div >
                                      Password confirmation is not matching.
                                    </div>                                                  
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-md-12"> <label class="c_n_fs" style="color: #cd5407;">*Note : Please ask client to keep these login details in a secure place , since it cannot be recovered.</label> </div>
                      

                  </div>
              </div>
          
      </div>
  </div>
</div>
<div class="modal-footer a-footer">
  <div *ngIf="!Isvalid" class="form_alert">
          <span  class="ft-alert-circle"></span>
      Please fill in all the required fields correctly.
  </div>
  <div *ngIf="errors"class="form_alert">
          <span  class="ft-alert-circle"></span>
      {{errors}}
  </div>
  <button type="submit" class="btn popup_save_b">Save</button>
</div>
</form>
<ngx-ui-loader ></ngx-ui-loader>
