export class XFacPersonalInfoBaseMOdel {
    public  facFinderId : string;
    public  title : string;
    public  firstName : string;
    public  middleName : string;
    public  surname : string;
    public  gender : string;
    public  dateOfBirth : Date;
    public  maritalStatus : string;
    public  taxFileNumber : string;
    public  smoker : string;
    public  clientCode : string;
}

export class XFacPersonalInfoAddModel extends XFacPersonalInfoBaseMOdel{   
    //public  client:XFacPersonalInfoBaseMOdel; 
       
}