import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FacFinderService } from '../fac-finder.service';
import { XFacContactAddModel } from 'src/app/shared/models/xfac-contact.model';
import { XFacContactResponse, XFacAddressResponse, XFacEmploymentResponse, XFacGoalResponse, XFacVersionResponse } from 'src/app/shared/models/response.model';
import { XFacAddressAddModel } from 'src/app/shared/models/xfac-address.model';
import { XFacEmploymentAddModel } from 'src/app/shared/models/xfac-employment.model';
import { XFacGoalAddModel } from 'src/app/shared/models/xfac-goal.model';
import { XplanVersionAddModel } from 'src/app/shared/models/xplan-version.model';

@Component({
  selector: 'app-add-fact-finder-version',
  templateUrl: './add-fact-finder-version.component.html',
  styleUrls: ['./add-fact-finder-version.component.css']
})
export class AddFactFinderVersionComponent implements OnInit {

  data;
  addVersionForm: FormGroup;
  versionAddModel : XplanVersionAddModel;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  buttonText: string ="Add New Contact Details";
  facFinderId : any;
  clientCode : any;
  clientID: any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private facFinderService: FacFinderService,
    public modalRef: BsModalRef,
    private toastr: ToastrService
    ) { }
 
  ngOnInit() {
    this.Isvalid = true;    
    this.versionAddModel = new XplanVersionAddModel(); 

    if(this.data.version !=null){
      //console.log(this.data.library);
      this.buttonText = "Update Now";
      Object.assign(this.versionAddModel, this.data.version);
    }

    if(this.data.facFinderId !=null){
      this.facFinderId = this.data.facFinderId;
    }

    if(this.data.clientCode !=null){
      this.clientCode = this.data.clientCode;
    }

    if(this.data.clientID !=null){
      this.clientID = this.data.clientID;
    }

    // console.log(this.facFinderId);
    // if(this.data.business_id !=null){
    //   this.business_id = this.data.business_id;
    // }

    this.addVersionForm = this.formBuilder.group({
      userId: [''],
      facFinderId: [''],
    });

  }

  get f() { return this.addVersionForm.controls; }

onSubmit(){
 this.submitted = true;

 if (this.addVersionForm.invalid ) { //|| (title && title.trim()=="")
  console.log(this.f);
  console.log(this.addVersionForm.invalid);
  this.toastr.error('Error on adding Fact Finder Version.');
  return;
 }

else{
  //this.folderModel.name = title.trim();

  this.ngxService.start();
  this.versionAddModel.clientCode = this.clientCode;
  this.versionAddModel.userId = this.clientID;
  //this.versionAddModel.facFinderId = this.facFinderId;
  this.facFinderService.addNewFact(this.versionAddModel)
  .subscribe((data:XFacVersionResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){      
      this.toastr.error('Error on adding Fact Finder Version.');      
    }
    else{
      this.toastr.success('Fact Finder Version added successfully.');
      this.event.emit('OK');
     // console.log(this.event);
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on adding Fact Finder Version.');
    });

}

}

modalRehide(){
  if (!this.modalRef) {
    return;
  } 
  this.modalRef.hide();
  this.modalRef = null;
}

ClosePopup(){
  this.modalRef.hide();
}

onReset() {
  this.submitted = false;
  this.addVersionForm.reset();
}

}
