    <div class="modal-header a_modal">
        <h4 class="modal-title" id="myModalLabel1">Create Conversation</h4>
        <button type="button" (click)="modalRehide()" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
    </div>

    <form #f="ngForm" novalidate class="form" enctype="multipart/form-data" (ngSubmit)="onSubmit(f)" >
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                
                    <div class="form-body">
                        <div class="row">
                            <div class="col-md-12" style="display: none">
                                <div class="form-group">
                                                                        
                                    <!-- <input id="reciverID" required  type="text" [value]="clientIds" class="form-control" name="reciverID[]" placeholder="" [(ngModel)]="messagecenterModel.reciverID" #reciverID = "ngModel">   -->

                                    <select multiple="true" required class="form-control" name="reciverID" [(ngModel)]='messagecenterModel.reciverID' #reciverID = "ngModel">
                                        <option *ngFor="let clnt of clientIds" selected [value]="clnt">                                      
                                              {{clnt}}
                                          </option>
                                    </select>

                                    <!-- <label for="projectinput1" class="c_n_fs">Receipents</label>
                                    <select multiple="true" required class="form-control" name="reciverID" [(ngModel)]='messagecenterModel.reciverID' #reciverID = "ngModel">
                                        <option *ngFor="let client of clientList"  [value]="client.id">                                       
                                          <ng-container *ngIf="client.client != null">
                                              {{client.client.firstName}} {{client.client.lastName}}
                                            </ng-container>
                                            <ng-container *ngIf="client.client == null">
                                                {{client.userName}}
                                              </ng-container>
                                        </option>
                                    </select>

                                    <div class="form-group">
                                        <div *ngIf="reciverID.invalid && (reciverID.dirty || reciverID.touched)"
                                            class="alert alert-danger">
                                          <div *ngIf="reciverID.errors.required">
                                            Please select a user.
                                          </div>
                                          <div *ngIf="reciverID.errors.minlength">
                                            First name must be at least 4 characters long.
                                          </div>
                                          <div *ngIf="reciverID.errors.forbiddenName">
                                            First name cannot be Bob.
                                          </div>
                                        </div>
                                      </div> -->

                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="projectinput8" class="c_n_fs">Title*</label>
                                    <input type="text" id="title" required  minlength="1" class="form-control c_n_form" name="title" [(ngModel)]="messagecenterModel.title" #title = "ngModel" placeholder="Title">
                                    
                                    <div class="form-group">
                                      <div *ngIf="f.submitted && title.invalid || (title.invalid && (title.dirty || title.touched))"   class="form_alert">
                                        <span  class="ft-alert-circle"></span>
                                        <div *ngIf="title.errors.required">Title is required.</div>
                                        <div *ngIf="title.errors.required">Title must be at least 1 characters long.</div>
                                      </div>
                                    </div>

                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                
            </div>
            
        </div>
        <!--                                    <hr class="c_hr">-->
    </div>
    
    <div class="modal-footer a-footer">
        <div *ngIf="!Isvalid" class="form_alert">
                <span  class="ft-alert-circle"></span>
            Please fill in the required field correctly.
        </div>
        <div *ngIf="errors" class="form_alert">
                <span  class="ft-alert-circle"></span>
            {{errors}}
        </div>
        <button type="submit" class="btn popup_save_b">Save</button>
    </div>
    </form>  

    <ngx-ui-loader ></ngx-ui-loader>

