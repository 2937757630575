import { Component, OnInit, EventEmitter } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FacFinderService } from '../fac-finder.service';
import { XFacContactAddModel } from 'src/app/shared/models/xfac-contact.model';
import { XFacContactResponse, XFacAddressResponse, XFacEmploymentResponse, XFacGoalResponse, XFacIncomeResponse, XFacExpensesResponse } from 'src/app/shared/models/response.model';
import { XFacAddressAddModel } from 'src/app/shared/models/xfac-address.model';
import { XFacEmploymentAddModel } from 'src/app/shared/models/xfac-employment.model';
import { XFacGoalAddModel } from 'src/app/shared/models/xfac-goal.model';
import { XFacIncomeAddModel } from 'src/app/shared/models/xfac-income.model';
import { XFacExpensesAddModel } from 'src/app/shared/models/xfac-expenses.model';

@Component({
  selector: 'app-add-expenses',
  templateUrl: './add-expenses.component.html',
  styleUrls: ['./add-expenses.component.css']
})
export class AddExpensesComponent implements OnInit {

  data;
  addExpenseForm: FormGroup;
  expenseAddModel : XFacExpensesAddModel;
  submitted = false;
  event: EventEmitter<any> = new EventEmitter();
  Isvalid:boolean;
  business_id : string;
  buttonText: string ="Add New Contact Details";
  facFinderId : any;
  clientCode : any;
  expenseCashflowList : any = [];
  expenseCashflowSubtypeList : any = [];
  incomeExpenseCashflowOwnerList : any = [];
  incomeExpenseCashflowFrequencyList : any = [];
  title: any;

  constructor(
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private router: Router, 
    private facFinderService: FacFinderService,
    public modalRef: BsModalRef,
    private toastr: ToastrService
    ) { }
 
  ngOnInit() {
    this.Isvalid = true;    
    this.expenseAddModel = new XFacExpensesAddModel(); 
    this.expenseAddModel.type ="000"; 
    this.expenseAddModel.typeGroup ="000"; 
    this.expenseAddModel.ownerType ="000"; 
    this.expenseAddModel.frequency = -99; 

    if(this.data.expense !=null){
      // console.log(this.data.expense);
      this.buttonText = "Update Now";
      Object.assign(this.expenseAddModel, this.data.expense);
    }

    if(this.data.expense_cashflow_list !=null){
      Object.assign(this.expenseCashflowList, this.data.expense_cashflow_list);
    }

    if(this.data.expense_cashflow_subtype_list !=null){
      Object.assign(this.expenseCashflowSubtypeList, this.data.expense_cashflow_subtype_list);
    }

    if(this.data.income_expense_cashflow_owner_list !=null){
      Object.assign(this.incomeExpenseCashflowOwnerList, this.data.income_expense_cashflow_owner_list);
    }

    if(this.data.income_expense_cashflow_frequency_list !=null){
      Object.assign(this.incomeExpenseCashflowFrequencyList, this.data.income_expense_cashflow_frequency_list);
    }

    if(this.data.facFinderId !=null){
      this.facFinderId = this.data.facFinderId;
    }

    if(this.data.clientCode !=null){
      this.clientCode = this.data.clientCode;
    }

    this.title = this.data.title;
    
    // console.log(this.clientCode);
    // if(this.data.business_id !=null){
    //   this.business_id = this.data.business_id;
    // }

    this.addExpenseForm = this.formBuilder.group({ 
      type: ['', Validators.required],
      typeGroup: ['', Validators.required],
      description: ['', Validators.required],
      ownerType: ['', Validators.required],
      frequency: ['', Validators.required],
      amountValue: ['', Validators.required],
    });

  }

  get f() { return this.addExpenseForm.controls; }

onSubmit(){
 this.submitted = true;

 let typeGroup = this.expenseAddModel.typeGroup;
 let ownerType = this.expenseAddModel.ownerType;
 let frequency = this.expenseAddModel.frequency;
 let type = this.expenseAddModel.type;

 if (this.addExpenseForm.invalid || (typeGroup && typeGroup.trim()=="000") || (type && type.trim()=="000") || (ownerType && ownerType.trim()=="000") || (frequency && frequency==-99) ) { //|| (title && title.trim()=="")
  if(typeGroup && typeGroup.trim()=="000"){
    this.addExpenseForm.controls['subType'].setErrors({'required': true});
  }

  if(ownerType && ownerType.trim()=="000"){
    this.addExpenseForm.controls['ownerType'].setErrors({'required': true});
  }
  if(type && type.trim()=="000"){
    this.addExpenseForm.controls['type'].setErrors({'required': true});
  }

  if(frequency && frequency==-99){
    this.addExpenseForm.controls['frequency'].setErrors({'required': true});
  }

  console.log(this.f);
  console.log(this.addExpenseForm.invalid);
  return;
}

else{
  //this.folderModel.name = title.trim();
  this.expenseAddModel.amountCode = 'AUD';
  if(this.data.expense !=null){
  //   this.ngxService.start();
  // //console.log("Edditre");

    this.ngxService.start();
    this.expenseAddModel.clientCode = this.clientCode;
    this.facFinderService.editExpense(this.expenseAddModel, this.facFinderId)
    .subscribe((data:XFacExpensesResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){      
      this.toastr.error('Error on updating expense.');      
    }
    else{
      this.toastr.success('Expense updated successfully.');
      this.event.emit('OK');
    // console.log(this.event);
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on updating expense.');
    });
      
    //this.router.navigate(['agents']);    

  }else{
    
    this.ngxService.start();
    this.expenseAddModel.clientCode = this.clientCode;
    this.facFinderService.addExpense(this.expenseAddModel, this.facFinderId)
    .subscribe((data:XFacExpensesResponse)=>{
    this.ngxService.stop();
    if(data.error && !data.success){      
      this.toastr.error('Error on saving expense.');      
    }
    else{
      this.toastr.success('Expense added successfully.');
      this.event.emit('OK');
     // console.log(this.event);
      this.modalRef.hide();
    }
    
    },err=>{   
      console.log(err);
      this.ngxService.stop();
      this.toastr.error('Error on saving expense.');
    });

  }

}

}

  modalRehide(){
    if (!this.modalRef) {
      return;
    } 
    this.modalRef.hide();
    this.modalRef = null;
  }

  ClosePopup(){
    this.modalRef.hide();
  }

  onReset() {
    this.submitted = false;
    this.addExpenseForm.reset();
  }

  onChange(value){
    let subtypeList = [];
    // console.log(value);
    this.data.expense_cashflow_subtype_list.forEach(device => {
      if(device.key.indexOf(value)==0){
        subtypeList.push(device);
      }
    })

    this.expenseCashflowSubtypeList = subtypeList;//this.data.asset_sub_type.filter(device => device.key === value);
    //this.expenseAddModel.type ="000"; 

    let choice = this.expenseCashflowSubtypeList.filter(choice => choice.key == this.expenseAddModel.type);
    if(choice && choice[0]) {
      this.expenseAddModel.type = choice[0].key;
      // this.selectedChoice = choice[0].key;
    }
    else{
      this.expenseAddModel.type = "000";
    }

    // console.log(this.expenseCashflowSubtypeList);
  }

}
