import { MessagecenterModel, SendChatModel } from '../shared/models/messagecenter.model';
import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppConstants } from '../utility/app-constants';
import { JwtHelperService } from '@auth0/angular-jwt';
// import { element, defineDirective } from '@angular/core/src/render3';
import { strictEqual } from 'assert';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { ChatModule, Message, User, Action, ExecuteActionEvent, SendMessageEvent,Attachment  } from '@progress/kendo-angular-conversational-ui';
// import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';
import { AppointmentAddModel,AppointmentBaseModel } from '../shared/models/appointment.model';
import { StartConversationResponse, ChatListResponse, ChatResponse } from '../shared/models/response.model';
// import { Subject } from 'rxjs/Subject';
import { Subject } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MessagecenterService {

  baseUrl:String;
  currentMessage = new BehaviorSubject(null);
  public readonly received_push: Subject<any> = new Subject<any>();
  public readonly conversation_message: Subject<any> = new Subject<any>();
  //public readonly responses: Subject<string> = new Subject<string>();
  conversationId :any;
  userList :any;
  attachments: Attachment[];

  constructor(
    private http:HttpClient,
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private authService: AuthService,
    private angularFireMessaging: AngularFireMessaging) {
    // this.angularFireMessaging.subscribe( 
    //   (_messaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }
    // );
    this.baseUrl = AppConstants.getBaseURL();
  }

    

  public handleError(error) {
    return throwError(error);
  }

  public startCommunication(startConversationModel:MessagecenterModel){    
    //console.log(startConversationModel);
    return this.http.post<StartConversationResponse>(this.baseUrl +'/Conversation',startConversationModel).pipe(
      catchError(this.handleError)
      );    
  }

  public getMyConversations(id:string,ski:string,take:string){   
    const params = new HttpParams().set('id', id).set('skip', ski).set('take', take); 
    return this.http.get<StartConversationResponse>(this.baseUrl+'/Communication/GetMyConversations',{params});    
  }

  public getClientConversations(userConversation:any){   
    //const params = new HttpParams().set('senderID', senderId).set('reciverID', receiverId); 
    return this.http.post<StartConversationResponse>(this.baseUrl+'/Communication/GetByReciver',userConversation).pipe(
      catchError(this.handleError)
      );        
  }

  public getConversationChatList(conversation){
    this.conversationId = conversation.id;
    this.userList = conversation.recivers;
    const params = new HttpParams().set('Id', conversation.id); 
    return this.http.get<ChatListResponse>(this.baseUrl+'/Communication/GetMessages',{params}); 
    
  }

  public submit(sendChat : SendChatModel, chat: string) {
    //this.responses.next(chat);
    //const params = new HttpParams().set('Id', sendChat.conversationId); 
    return this.http.post<ChatResponse>(this.baseUrl+'/Message',sendChat); 
  }
  
  // public invoiceEdit(id:string,invoice:InvoiceAddModel) {  
  //   const params = new HttpParams().set('id', id); 
  //   return this.http.put<InvoiceResponse>(this.baseUrl+'/FortressInvoice',invoice,{params}).pipe(
  //     catchError(this.handleError)
  //     );
  // }

  receiveMessage() {
    //console.log(" firebase before --- receive message prev -------------  ");
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        //console.log(" on message receive ");
        //console.log("new message received. ", payload);
       // this.currentMessage.next(payload);
       this.currentMessage.next(payload);
       //this.received_push.next(this.currentMessage.value.notification);
      //  console.log("new message received---. ", this.currentMessage.value.data.conversation);
       let currentMsgData = JSON.parse(this.currentMessage.value.data.conversation);
       //console.log(this.conversationId+"------"+currentMsgData.id);
       //console.log(currentMsgData);
       if(this.conversationId==currentMsgData.id){
         //console.log(this.conversationId+"------"+currentMsgData.id);
          if(currentMsgData.messages){
            currentMsgData.messages.forEach(chat => {
              let send = this.userList.find(x => x.id == chat.senderId);
              //let chat_list :any;
      
              let profImage;
              if(send.profileImageUrl !=null && (send.profileImageUrl.match(/\.(jpeg|jpg|gif|png)$/) != null) ){
                profImage = send.profileImageUrl;
              }
              else{
                profImage = 'assets/profile2.png';
              }

              let sender: User = { 
                id: send.id,                      
                name: send.userName,               
                avatarUrl: send.profileImageUrl  //'assets/profile2.png' 1
              };
      
              // this.received_push.next({
              //   author: sender,
              //   text: chat.text,
              //   timestamp: new Date(chat.createdDate)
      
              // });

              if(chat.messageType == "1"){
                this.received_push.next({
                  author: sender,
                  text: chat.text,
                  timestamp: new Date(chat.createdDate),
                  attachments: null  
                });
              }
              else{
                //let attachments;
      
                if(chat.messageType == "2"){
                  this.attachments = [{
                    content: chat.documentURL,
                    contentType :'image'  // pdf doc
                  }]; 
                }
                else if(chat.messageType == "3"){
                  this.attachments = [{
                    content: chat.documentURL,
                    contentType :'pdf'  // pdf doc
                  }]; 
                }
                else if(chat.messageType == "4"){
                  this.attachments = [{
                    content: chat.documentURL,
                    contentType :'doc'  // pdf doc
                  }]; 
                }
      
                this.received_push.next({
                  author: sender,
                  text: chat.text,
                  timestamp: new Date(chat.createdDate),
                  attachments: this.attachments  
                });
              }

      
            });
          }
       }
       else{
         //console.log(123456);
          this.conversation_message.next({conversationId :currentMsgData.id});
       }
       

       //console.log("currentMessage next. ", this.currentMessage.value.data.conversation);
      //  if(payload.notification){

      //  }
       //received_push
      })
  }

  getConversationNewMsg(): Observable<any> {
    return this.conversation_message.asObservable();
  }

}
